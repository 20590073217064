import { Contacts } from "../../proto/generated/Contacts";
import { UserBoard } from '../../proto/generated/UserBoard';
import { UserRelation } from '../../proto/generated/UserRelation';
import { UserContact } from '../../proto/generated/UserContact';
import { User } from "../../proto/generated/User";
import { UserPresence } from './../../api/defines';
import { mergeUserCacheObject } from "../../proto";
import { cloneObject, isArray } from "../../util";

export class UserCache {
    private _cacheUser: User;
    private _indexFields: string[];
    private _indexMap: Map<number, Object>;
    private _cacheUserPresences: Map<string, User>; // key is user id

    private _userBoardMappings: Map<string, number>; // key is board id, val is user board sequence
    private _userRelationMappings: Map<string, number>; // key is user id, val is user relation sequence
    private _userContactMappings: Map<string, number>; // key is user id, val is user contact sequence

    constructor(user: User) {
        this._cacheUser = user;

        this._userBoardMappings = new Map();
        this._userRelationMappings = new Map();
        this._userContactMappings = new Map();
        this._cacheUserPresences = new Map();

        this._indexFields = ['boards', 'relations', 'contacts'];
        this._indexMap = new Map();
        this.setupIndex();
    }

    get user(): User {
        return this._cacheUser;
    }

    setUserBoards(ubs: UserBoard[]) {
        this._cacheUser.boards = ubs;
        this.addCacheUserBoards(ubs);
    }

    private indexField(field: string, seq: number, obj: Object) {
        if (!seq || !obj) return;

        this._indexMap.set(seq, obj);

        if (field === 'boards' && obj['board'] && obj['board']['id']) {
            this._userBoardMappings.set(obj['board']['id'], seq);
        }else if (field === 'relations' && obj['user'] && obj['user']['id']) {
            this._userRelationMappings.set(obj['user']['id'], seq);
        }else if (field === 'contacts' && obj['user'] && obj['user']['id']) {
            this._userContactMappings.set(obj['user']['id'], seq);
        }
    }

    private setupIndex() {
        this._indexFields.forEach(field => {
            isArray(this._cacheUser[field]) && this._cacheUser[field].forEach((obj: Object) => {
                this.indexField(field, obj['sequence'], obj);
            });
        })
    }

    private updateIndex(updatedUser: User) {
        this._indexFields.forEach(field => {
            isArray(updatedUser[field]) && updatedUser[field].forEach((obj: Object) => {
                let seq = obj['sequence'];
                if (seq && isArray(this._cacheUser[field]) && this._cacheUser[field].length > 0) {
                    // new added items, reverse iterate for better performance
                    for (let i = this._cacheUser[field].length-1; i >= 0; i--) {
                        if (this._cacheUser[field][i]['sequence'] === seq) {
                            this.indexField(field, seq, this._cacheUser[field][i]);
                            break;
                        }
                    }
                }                    
            });
        });
    }

    addCacheUserBoards(userBoards: UserBoard[]) {
        userBoards && userBoards.forEach(ub => {
            if (ub.sequence && ub.board && ub.board.id) {
                this._indexMap.set(ub.sequence, cloneObject(ub));
                this._userBoardMappings.set(ub.board.id, ub.sequence);
            }
        })
    }

    getUserBoardByBoardId(boardId: string): UserBoard {
        if (this._userBoardMappings.has(boardId)) {
            let seq = this._userBoardMappings.get(boardId);
            if (seq) {
                return this._indexMap.get(seq) as UserBoard;
            }
        }
        return null;
    }

    getUserBoardByRoutingChannel(channelSeq: number): UserBoard {
        if (channelSeq && this._cacheUser.boards) {
            return this._cacheUser.boards.find(ub => {
                return (ub && ub.board && ub.board.routing_channel === channelSeq);
            })
        }
        return null;
    }

    getUserRelationByUserId(userId: string): UserRelation {
        if (this._userRelationMappings.has(userId)) {
            let seq = this._userRelationMappings.get(userId);
            if (seq) {
                return this._indexMap.get(seq) as UserRelation;
            }
        }
        return null;
    }

    getUserContactByUserId(userId: string): UserContact {
        if (this._userContactMappings.has(userId)) {
            let seq = this._userContactMappings.get(userId);
            if (seq) {
                return this._indexMap.get(seq) as UserContact;
            }
        }
        return null;
    }

    getUserPresenceByUserId(userId: string): UserPresence {
        if (this._cacheUserPresences.has(userId)) {
            return this._cacheUserPresences.get(userId);
        }
        return null;
    }

    basicInfo(): User {
        let excludedFields = new Set(['boards', 'group_boards', 'relations', 'contacts', 'favorites', 'mentionmes', 'broadcasts', 'resources']);
        let basicUser: User = {};
        Object.keys(this._cacheUser).forEach( key => {
            if (!excludedFields.has(key)) {
                basicUser[key] = this._cacheUser[key];
            }
        });
    
        return basicUser;
    }

    onObjectUpdate(updatedUser: User, contacts: Contacts) {
        if (updatedUser && updatedUser.revision && updatedUser.revision > this._cacheUser.revision) {
            mergeUserCacheObject(this._cacheUser, updatedUser);
            this.updateIndex(updatedUser);
        }

        if (contacts && contacts.contacts) {
            contacts.contacts.forEach(user => {
                if (user.id) {
                    if (this._cacheUserPresences.has(user.id)) {
                        Object.assign(this._cacheUserPresences.get(user.id), user);
                    }else {
                        this._cacheUserPresences.set(user.id, cloneObject(user));
                    }
                }
            })
        }
    }

}