import { CacheObject } from '../proto/generated/CacheObject';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { GroupUserRole } from '../proto/generated/GroupUserRole';
import { GroupIntegration } from '../proto/generated/GroupIntegration';
import { GroupUser } from '../proto/generated/GroupUser';
import { Board } from '../proto/generated/Board';
import { UsageStatistics } from '../proto/generated/UsageStatistics';
import { UserActivityLog } from '../proto/generated/UserActivityLog';
import { Group } from './../proto/generated/Group';
import { RoutingConfig } from '../proto/generated/RoutingConfig';
import { SystemSamlService } from '../proto/generated/SystemSamlService';
import { RoutingChannel } from '../proto/generated/RoutingChannel';
import { UserGroup } from '../proto/generated/UserGroup';
import { GroupAccessType } from '../proto/generated/GroupAccessType';
import { UserRelation } from '../proto/generated/UserRelation';
import { GroupUserRoleType } from '../proto/generated/GroupUserRoleType';
import { BoardAccessType } from '../proto/generated/BoardAccessType';
import { GroupBoard } from '../proto/generated/GroupBoard';
import { GroupCache } from './../data/cache/groupCache';
import { MxGroupSubscriber } from './../data/subscribe/groupSubscriber';
import { MxGroup } from "../api/mxGroup";
import {
    UserIdentity,
    MxCallback,
    MxSubscription,
    MxGroupMemberFilter,
    MxUpdateEmailPhoneNumParam,
    MxRoutingChannelType,
    MxGroupManagementFilter,
    User,
    MxResetPasswordChannel,
    ConnectorSFDCConfig,
    ConnectorGlobalRelayConfig,
    ConnectorHubSpotConfig
} from '../api/defines';
import { getAllTags, getTagByKey, filterDeleted } from '../data/cache/common';
import { currentOrgId } from '../data/cache/cacheMgr';
import { getByPath } from '../util';
import { getContextPath } from './config';
import * as btag from '../biz/tag';
import * as bgroup from '../biz/group';
import * as baudit from '../biz/audit';
import * as brelation from '../biz/relation';
import * as bacdsr from '../biz/acdsr';
import * as bteam from '../biz/team';
import * as bstripe from '../biz/stripe';
import * as bconnector from '../biz/connector';


export class MxGroupImpl implements MxGroup {
    private _groupCache: GroupCache;
    private _groupSubscriber: MxGroupSubscriber;

    constructor(groupCache: GroupCache) {
        this._groupCache = groupCache;
        this._groupSubscriber = new MxGroupSubscriber(this._groupCache);
    }

    get id(): string {
        return this._groupCache.group.id;
    }

    get group(): Group {
        return this._groupCache.group;
    }

    get basicInfo(): Group {
        return this._groupCache.group;
    }

    get tags(): Object {
        return getAllTags(this.group.tags);
    }

    get roles(): GroupUserRole[] {
        return (this.group.roles || []).filter(filterDeleted);
    }

    get integrations(): GroupIntegration[] {
        return (this.group.integrations || []).filter(filterDeleted);
    }

    get routingConfig(): RoutingConfig {
        return this._groupCache.group.routing_config;
    }

    get routingRequests(): Group {
        return this._groupCache.routingRequestsGroup;
    }

    subscribeBasicInfo(cb: MxCallback<Group>): MxSubscription {
        return this._groupSubscriber.subscribeBasicInfo(cb);
    }

    subscribeTeams(cb: MxCallback<UserGroup[]>): MxSubscription {
        return this._groupSubscriber.subscribeTeams(cb);
    }

    subscribeGroupBoards(cb: MxCallback<GroupBoard[]>): MxSubscription {
        return this._groupSubscriber.subscribeGroupBoards(cb);
    }

    updateGroup(info: Group): Promise<Group> {
        return bgroup.updateGroup(this.id, info).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    getMemberSequenceByUserId(userId: string): number {
        return this._groupCache.getMemberSequenceByUserId(userId);
    }

    readMember(user: UserIdentity): Promise<Group> {
        return bgroup.readMember(this.id, user).then((response: ClientResponse) => {
            let groupRes = getByPath(response, 'object.group');
            this._groupCache.updateGroupMemberCache(groupRes);
            return groupRes;
        });
    }

    readMembers(users: UserIdentity[]): Promise<Group> {
        return bgroup.readMembers(this.id, users).then((response: ClientResponse) => {
            let groupRes = getByPath(response, 'object.group');
            this._groupCache.updateGroupMemberCache(groupRes);
            return groupRes;
        });
    }

    readMemberDetail(user: UserIdentity, includeRelatedRelation?: boolean): Promise<CacheObject> {
        return bgroup.readMemberDetail(this.id, user, includeRelatedRelation).then((response: ClientResponse) => {
            return getByPath(response, 'object');
        });
    }

    readMembersByPagination(startSequence: number, size: number): Promise<Group> {
        return bgroup.readMembersByPagination(this.id, startSequence, size).then((response: ClientResponse) => {
            let groupRes = getByPath(response, 'object.group');
            this._groupCache.updateGroupMemberCache(groupRes);
            return groupRes;
        });
    }

    searchMembers(startSequence: number, size: number, filter?: MxGroupMemberFilter): Promise<Group> {
        let oldP = bgroup.searchGroupMembers(this.id, startSequence, size, filter);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                let groupRes = getByPath(response, 'object.group');
                this._groupCache.updateGroupMemberCache(groupRes);
                resolve(groupRes);
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    inviteMember(user: GroupUser | GroupUser[], updateExisting?: boolean): Promise<Group> {
        return bgroup.inviteMember(this.id, user, updateExisting).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteMember(memberSequence: number): Promise<void> {
        return bgroup.deleteMember(this.id, memberSequence).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    activateMember(memberSequence: number): Promise<void> {
        return bgroup.activateMember(this.id, memberSequence).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    deactivateMember(memberSequence: number): Promise<void> {
        return bgroup.deactivateMember(this.id, memberSequence).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    removeMemberAvatar(memberSequence: number): Promise<void> {
        return bgroup.removeMemberAvatar(this.id, memberSequence).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    join(): Promise<Group> {
        throw new Error("Method not implemented.");
    }

    joinByInvitationToken(token: string): Promise<Group> {
        throw new Error("Method not implemented.");
    }

    leave(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    resetMemberPassword(memberSequence: number, channel?: MxResetPasswordChannel): Promise<void> {
        return bgroup.resetGroupUserPassword(this.id, memberSequence, channel).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    resendInviteEmail(param: UserIdentity): Promise<void> {
        return bgroup.resendInviteEmail(this.id, param).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    resendInviteSms(param: UserIdentity): Promise<void> {
        return bgroup.resendInviteSms(this.id, param).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    updateMemberProfile(memberSequence: number, user: GroupUser): Promise<Group> {
        return bgroup.updateGroupUser(this.id, memberSequence, user).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateMemberEmailPhoneNum(userId: string, param: MxUpdateEmailPhoneNumParam): Promise<Group> {
        return bgroup.updateMemberEmailPhoneNum(this.id, userId, param).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    createRole(role: GroupUserRole): Promise<Group> {
        return bgroup.createRole(this.id, role).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateRole(roleSequence: number, role: GroupUserRole): Promise<Group> {
        return bgroup.updateRole(this.id, roleSequence, role).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteRole(roleSequence: number): Promise<Group> {
        return bgroup.deleteRole(this.id, roleSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateMemberRole(memberSequence: number, roleSequence: number): Promise<Group> {
        return bgroup.updateMemberRole(this.id, memberSequence, roleSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateMemberExtRoles(memberSequence: number, roles: number[]): Promise<Group> {
        return bgroup.updateMemberExtRoles(this.id, memberSequence, roles).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    batchUpdateMembersRole(users: UserIdentity[], roleSequence: number): Promise<Group> {
        return bgroup.batchUpdateMembersRole(this.id, users, roleSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    createRelation(rm: UserIdentity, customer: UserIdentity): Promise<Group> {
        return brelation.adminCreateRelation(rm, customer).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateRelation(rm: UserIdentity, relations: UserRelation[]): Promise<Group> {
        return brelation.adminUpdateRelation(rm, relations).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteRelation(rm: UserIdentity, customer: UserIdentity): Promise<Group> {
        return brelation.adminDeleteRelation(rm, customer).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    transferRelation(customer: UserIdentity, oldRm: UserIdentity, newRm: UserIdentity): Promise<Group> {
        return brelation.adminTransferRelation(customer, oldRm, newRm).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    createIntegration(integration: GroupIntegration): Promise<Group> {
        return bgroup.createIntegration(this.id, integration).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateIntegration(integrationSequence: number, integration: GroupIntegration): Promise<Group> {
        return bgroup.updateIntegration(this.id, integrationSequence, integration).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteIntegration(integrationSequence: number): Promise<Group> {
        return bgroup.deleteIntegration(this.id, integrationSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readSamlProviders(): Promise<SystemSamlService> {
        return bgroup.readSamlProviders(this.id).then((response: ClientResponse) => {
            return getByPath(response, 'object.saml_service');
        });
    }

    getTag(key: string): string|number {
        return getTagByKey(key, this.group.tags);
    }

    createOrUpdateTag(key: string, val: string | number): Promise<void> {
        return btag.createOrUpdateTag(key, val, this.group.tags, btag.MxTagType.GROUP, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    createOrUpdateTags(tags: Object): Promise<void> {
        return btag.createOrUpdateTags(tags, this.group.tags, btag.MxTagType.GROUP, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    deleteTag(key: string): Promise<void> {
        return btag.deleteTag(key, this.group.tags, btag.MxTagType.GROUP, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    createOrUpdateInvitationToken(): Promise<Group> {
        return bgroup.createOrUpdateInvitationToken().then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readUsage(timestamp: number): Promise<UsageStatistics> {
        return baudit.readOrgUsage(currentOrgId, timestamp).then((response: ClientResponse) => {
            return getByPath(response, 'object.usage');
        });
    }

    readCrmReport(timestamp: number): Promise<Group> {
        return baudit.readOrgCrmReport(currentOrgId, timestamp).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    searchOrgBoards(searchKey: string, fromTime: number,toTime: number, start: number, size: number, ownerIds?:string[], boardId?:string): Promise<Group> {
        return baudit.searchOrgBoards(currentOrgId, searchKey, fromTime, toTime, ownerIds, start, size, boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readUserActivites(users: UserIdentity[], fromTime: number, toTime: number, type?: string[]): Promise<UserActivityLog[]> {
        return baudit.readUserActivites(currentOrgId, users, fromTime, toTime, type).then((response: ClientResponse) => {
            return getByPath(response, 'user_activities', []);
        });
    }

    readAuditBoard(boardId: string): Promise<Board> {
        return baudit.readAuditObject(this.id, boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    onGroupUpdated(group: Group): void {
        this._groupCache.onObjectUpdate(group);
        this._groupSubscriber.onObjectUpdate(group);
    }

    onRoutingRequestsUpdated(group: Group): void {
        this._groupCache.onRoutingRequestsUpdate(group);
        this._groupSubscriber.onRoutingRequestsUpdate(group);
    }

    listRoutingRequests(fromTime: number, toTime: number, count: number): Promise<Group> {
        return bacdsr.listRoutingRequests(currentOrgId, fromTime, toTime, count).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    subscribeRoutingRequests(cb: MxCallback<Group>): MxSubscription {
        bacdsr.subscribeRoutingRequests();
        return this._groupSubscriber.subscribeRoutingRequests(cb);
    }

    updateRoutingConfig(config: RoutingConfig): Promise<Group> {
        return bacdsr.updateRoutingConfig(this.id, config).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readOfficeHour(): Promise<Board> {
        return bacdsr.readOfficeHour(this.id).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createRoutingChannel(type: MxRoutingChannelType, channel: RoutingChannel): Promise<Group> {
        return bacdsr.createRoutingChannel(this.id, type, channel).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateRoutingChannel(type: MxRoutingChannelType, channelSeq: number, channel: RoutingChannel): Promise<Group> {
        channel.sequence = channelSeq;
        return bacdsr.updateRoutingChannel(this.id, type, channel).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateRoutingChannels(type: MxRoutingChannelType, channels: RoutingChannel[]): Promise<Group> {
        return bacdsr.updateRoutingChannels(this.id, type, channels).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteRoutingChannel(type: MxRoutingChannelType, channelSeq: number): Promise<Group> {
        return bacdsr.deleteRoutingChannel(this.id, type, channelSeq).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readTeam(teamId: string): Promise<Group> {
        return bteam.readTeam(teamId).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    createTeam(name: string, description?: string): Promise<Group> {
        return bteam.createTeam(name, description).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateTeam(teamId: string, name?: string, description?: string): Promise<Group> {
        return bteam.updateTeam(teamId, name, description).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    deleteTeam(teamId: string): Promise<Group> {
        return bteam.deleteTeam(teamId).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readTeamMembers(teamId: string, searchKey?: string): Promise<Group> {
        return bgroup.searchGroupMembers(teamId, 0, 10000, {isInternal: true, searchKey: searchKey}).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readTeamMembersByPagination(teamId: string, startSequence: number, size: number, filter?: MxGroupMemberFilter): Promise<Group> {
        return bgroup.searchGroupMembers(teamId, startSequence, size, filter).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readTeamMemberDetail(teamId: string, user: UserIdentity): Promise<CacheObject> {
        return bgroup.readMemberDetail(teamId, user).then((response: ClientResponse) => {
            return getByPath(response, 'object');
        });
    }

    transferMemberBoards(fromUserId: string, boardIds: string[], toUserId: string): Promise<Group> {
        return bgroup.transferMemberBoards(this.id, fromUserId, boardIds, toUserId).then((response: ClientResponse) => {
            return getByPath(response, 'object');
        });
    }

    addTeamMembers(teamId: string, users: UserIdentity[], accessType?: GroupAccessType): Promise<Group> {
        return bteam.addTeamMembers(teamId, users, accessType).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    removeTeamMember(teamId: string, user: UserIdentity): Promise<Group> {
        return bteam.removeTeamMember(teamId, user).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    assignTeamOwner(teamId: string, user: UserIdentity): Promise<Group> {
        return bteam.assignTeamOwner(teamId, user).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    updateTeamMemberAccessType(teamId: string, user: UserIdentity, accessType?: GroupAccessType): Promise<Group> {
        return bteam.updateTeamMemberAccessType(teamId, user, accessType).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    readManagementClients(filter: MxGroupManagementFilter): Promise<ClientResponse> {
        return bteam.readManagementClients(this.id, filter);
    }

    readManagementInternalUsers(filter: MxGroupManagementFilter): Promise<ClientResponse> {
        return bteam.readManagementInternalUsers(this.id, filter);
    }

    readManagementClientDetail(filter: MxGroupManagementFilter, client: UserIdentity): Promise<ClientResponse> {
        return bteam.readManagementClientDetail(this.id, filter, client);
    }

    readManagementInternalUserDetail(filter: MxGroupManagementFilter, rm: UserIdentity): Promise<ClientResponse> {
        return bteam.readManagementInternalUserDetail(this.id, filter, rm);
    }

    readManagementTeamDetail(filter: MxGroupManagementFilter, teamId: string): Promise<ClientResponse> {
        return bteam.readManagementTeamDetail(this.id, filter, teamId);
    }

    readManagementShareUserBoards(filter: MxGroupManagementFilter, client: UserIdentity, rm: UserIdentity): Promise<ClientResponse> {
        return bteam.readManagementShareUserBoards(this.id, filter, client, rm);
    }

    readManagementUserActivities(user: UserIdentity, peerUser: UserIdentity, from: number, to: number): Promise<ClientResponse> {
        return bteam.readManagementUserActivities(this.id, user, peerUser, from, to);
    }

    subscribeBilling (token:  string, user: User): Promise<any> {
        return bstripe.subscribeStripe(token, user);
    }

    getStripePublicKey():Promise<string> {
        return bstripe.getStripePublicKey().then((response: ClientResponse) => {
            return response.data;
        });
    }

    getStripeCustomer(): Promise<any> {
        return bstripe.getStripeCustomer()
    }

    getStripeInvoice(start?: string, size?: number): Promise<any> {
        return bstripe.getStripeInvoice(start, size);
    }

    getStripeCoupon(): Promise<any> {
        return bstripe.getStripeCoupon()
    }

    getStripePrice(group: Group): Promise<any> {
        return bstripe.getStripePrice(group)
    }

    createGroupBoard(board: Board, owner: UserIdentity): Promise<Board> {
        return bgroup.createGroupBoard(this.id, board, owner).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteGroupBoard(boardId: string): Promise<void> {
        return bgroup.deleteGroupBoard(boardId).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    readGroupBoards(startSequence: number, size: number): Promise<Group> {
        return bgroup.readGroupBoards(this.id, startSequence, size).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    addGroupBoardMember(boardId: string, users: UserIdentity[], accessType?: BoardAccessType): Promise<Board> {
        return bgroup.addGroupBoardMember(this.id, boardId, users, accessType).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    addGroupBoardMemberByRole(boardId: string, roles: GroupUserRoleType[], accessType?: BoardAccessType): Promise<Board> {
        return bgroup.addGroupBoardMemberByRole(this.id, boardId, roles, accessType).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateGroupBoardMember(boardId: string, memberSeq: number, roles: GroupUserRoleType[], accessType?: BoardAccessType): Promise<Board> {
        return bgroup.updateGroupBoardMember(this.id, boardId, memberSeq, roles, accessType).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    removeGroupBoardMember(boardId: string, memberSeq: number): Promise<void> {
        return bgroup.removeGroupBoardMember(this.id, boardId, memberSeq).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    makeDownloadResourceUrl(resourceSequence: number): string {
        return `${getContextPath()}/group/${this.id}/resource/${resourceSequence}`;
    }

    makeTCDownloadUrl(): string {
        if (!this.group.tac) {
            return '';
        } else {
            return `${getContextPath()}/group/${this.id}/resource/${this.group.tac}`;
        }
    }

    makeTCUploadUrl(name: string): string {
        return `${getContextPath()}/group/${this.id}/upload?name=${name}&type=tac`;
    }

    makeExportMembersUrl(userType: string): string {
        return `${getContextPath()}/group/${this.id}/exportmembers?filter=${userType}`;
    }

    makeClientEngagementReportUrl(fromTime: number, toTime: number): string {
        let offset = -1 * new Date().getTimezoneOffset() * 60000;
        return `${getContextPath()}/group/${this.id}/exportclientengagement?from=${fromTime}&to=${toTime}&offset=${offset}`;
    }

    makeInternalEngagementReportUrl(fromTime: number, toTime: number): string {
        let offset = -1 * new Date().getTimezoneOffset() * 60000;
        return `${getContextPath()}/group/${this.id}/exportinternaluserengagement?from=${fromTime}&to=${toTime}&offset=${offset}`;
    }

    makeClientCoverageReportUrl(fromTime: number, toTime: number): string {
        let offset = -1 * new Date().getTimezoneOffset() * 60000;
        return `${getContextPath()}/group/${this.id}/exportclientcoverage?from=${fromTime}&to=${toTime}&offset=${offset}`;
    }

    makeWeChatReportUrl(fromTime: number, toTime: number): string {
        return `${getContextPath()}/group/${this.id}/exportsocialengagement?from=${fromTime}&to=${toTime}`;
    }

    makeWhatsAppReportUrl(fromTime: number, toTime: number): string {
        return `${getContextPath()}/group/${this.id}/exportsocialengagement?type=whatsapp&from=${fromTime}&to=${toTime}`;
    }

    makeChatAuditPDFUrl(fromTime: number, toTime: number, boardId: string, creator: string='', text: string=''): string {
        let offset = -1 * new Date().getTimezoneOffset() * 60000;
        return `${getContextPath()}/board/audit/${boardId}?from=${fromTime}&to=${toTime}&offset=${offset}&group_id=${this.id}&creator=${creator}&text=${text}&type=pdf`;
    }

    makeUploadRoutingChannelPictureUrl(channelSeq: number, name: string, width?: number, height?: number): string {
        let url = `${getContextPath()}/group/${this.id}/upload?type=channel&channel=${channelSeq}&name=${encodeURIComponent(name)}`;
        if (width && height) {
            url += `&width=${width}&height=${height}`;
        }
        return url;
    }

    scheduleSFDCJob(config: ConnectorSFDCConfig): Promise<ClientResponse> {
        return bconnector.scheduleSFDCJob(config);
    }

    testSFDCConnection(config: ConnectorSFDCConfig): Promise<ClientResponse> {
        return bconnector.testSFDCConnection(config);
    }

    scheduleGlobalRelayJob(config: ConnectorGlobalRelayConfig): Promise<ClientResponse> {
        return bconnector.scheduleGlobalRelayJob(config);
    }

    testGlobalRelayConnection(config: ConnectorGlobalRelayConfig): Promise<ClientResponse> {
        return bconnector.testGlobalRelayConnection(config);
    }

    scheduleHubSpotJob(config: ConnectorHubSpotConfig): Promise<ClientResponse> {
        return bconnector.scheduleHubSpotJob(config);
    }

    testHubSpotConnection(config: ConnectorHubSpotConfig): Promise<ClientResponse> {
        return bconnector.testHubSpotConnection(config);
    }

}
