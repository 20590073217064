import { ObjectUtils } from '../../commonUtils/object'
import { MxISDK } from 'isdk';

export class TransactionUtils {
  static isMyTurn (transaction: any, myId: string) {
    if (transaction.is_deleted) {
      return false;
    }
    if (transaction.status) {
      if (transaction.status !== 'TRANSACTION_STATUS_ACTIVE') {
        return false
      }
    }
    var steps = transaction.steps;
    if (!steps || !steps.length) {
      return false;
    }
    steps.sort(function (a: any, b: any) {
      return a.order_number > b.order_number ? 1 : -1;
    });
    //find first not action_logs step
    let currAssignees = [], currOrder = 0, assigneeId;
    for (let i = 0; i < steps.length; i++) {
      let step = steps[i], status = step.status;
      if (!currOrder || step.order_number === currOrder) {
        let isDone = true;
        if (status) {
          if (status === 'STEP_STATUS_INITIAL' || status === 'STEP_STATUS_PENDING') {
            isDone = false;
          }
        }
        if (!isDone) {
          assigneeId = ObjectUtils.getByPath(step.assignee, 'user.id');
          currAssignees.push(assigneeId);
          currOrder = step.order_number;
        }
      }
    }
    return currAssignees.indexOf(myId) > -1;

  }

  static filterMyTurnTransactions (transactions: any) {
    const currentUser = MxISDK.getCurrentUser()
    return transactions.filter((transaction: any) => {
      if (transaction.is_deleted) {
        return false
      }
      if (transaction.expiration_date < Date.now()) {
        return false
      }
      if (this.isMyTurn(transaction, currentUser.basicInfo.id)) {
        return true
      }
      return false
    })
  }
}