import { ClientRequestType } from './../proto/generated/ClientRequestType';
import { ClientRequest } from './../proto/generated/ClientRequest';
import { ClientResponseCode } from './../proto/generated/ClientResponseCode';
import { Connection } from './connection';
import * as superagent from 'superagent'
import {uuid} from "../util";
import { sdkConfig } from "../core/config";
import { publishError } from '../core/mxISDKImpl';
import { IRequestParam } from '../api/defines';
import { MxErr } from '../core/error';
import { currentUser, setCurrentSessionId } from '../data/cache/cacheMgr';

let loadingPromise = Promise.resolve({});

export class Ajax {
    
    static get(url: string, opts: IRequestParam={}): Promise<any> {
        opts.method = 'get';
        return Ajax.send(url, null, opts);
    }

    static post(url: string, data: any, opts: IRequestParam={}): Promise<any> {
        opts.method = 'post';
        return Ajax.send(url, data, opts);
    }

    static sendRequest(req: ClientRequest, opts: IRequestParam={}): Promise<any> {
        if(window['useTrailingSlash']){
            return Ajax.post('/board/', req, opts);
        }
        return Ajax.post('/board', req, opts);
    }

    static waiting(milliSeconds: number): Promise<any> {
        return new Promise((resolve, reject) => {
            window.setTimeout(()=> {
                resolve();
            }, milliSeconds);
        })
    }

    // for concurrent case, several http requests send to server nearly at same time
    // if the requests don't have session id in cookie, server may set different session ids with set-cookie response
    static waitingForPromise(pro: Promise<any>, timeout: number) {
        let timeoutPromise = Ajax.waiting(timeout);
        loadingPromise = Promise.race([pro, timeoutPromise]).catch(e => {});
    }

    static updateSessionId(res) {
        try {
            if (res && res.body && res.body['session_id'] && res.body['type'] === ClientRequestType.USER_REQUEST_VERIFY_TOKEN) {
                setCurrentSessionId(res.body['session_id']);
            }
        }catch(e) {

        }
    }

    static send(url: string, data: any, opts: IRequestParam={}): Promise<any> {
        let req: any;
        let reqId: string = uuid();
        let result2 = loadingPromise.then(() => {
            let result = new Promise((resolve, reject) => {
                let isGet = opts.method && opts.method === 'get';
                let isWebLink = false;
    
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    url = sdkConfig.serviceUrl + url;
                }else {
                    isWebLink = true;
                }
    
                let queryParams = opts.queryParams || {};
                queryParams['rnd'] = uuid();
    
                if (isGet) {
                    req = superagent.get(url);
                } else {
                    req = superagent.post(url);
                    req.send(data);
                }
    
                req.query(queryParams);
    
                let responeTimeout = opts.timeout ? opts.timeout : 30*1000;
                let deadlineTimeout = 2 * 60 * 1000;
    
                req.timeout({
                    response: responeTimeout,  // wait for the first byte to arrive from the server,
                    deadline: deadlineTimeout,  // deadline for the entire request (including all uploads, redirects, server processing time) to complete
                });
                req.set('Content-Type', opts.contentType || 'application/json');
    
                if (opts.accessToken) {
                    req.set('Authorization', 'Bearer ' + opts.accessToken);
                }else if ((location.protocol === 'http:' || sdkConfig['isUnitTest']) && sdkConfig.accessToken && !isWebLink) {
                    // server's cookie has secure flag, only can be accessed by https, for http need to take access_token
                    req.set('Authorization', 'Bearer ' + sdkConfig.accessToken);
                }
                
                if(sdkConfig.clientVersion){
                    req.set('Client-Version', sdkConfig.clientVersion);
                }
    
                req.then((res: any) => {
                    Ajax.removeRequest(reqId);
                    Ajax.updateSessionId(res);
                    resolve(res.body || res.text);
                }).catch((err: any) => {
                    Ajax.removeRequest(reqId);
    
                    if (err.response && err.response.body) {
                        Ajax.updateSessionId(err.response);
    
                        let r = err.response.body;
                        let e = MxErr.ServerError(r.code, r.detail_code, r.message, r);
                        reject(e);
                        // trigger global error callback
                        publishError(e);
                        if (currentUser && r.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN) {
                            Connection.getInstance().reconnect();
                        }
                    }else if (err.timeout || err.status === 504) {
                        reject(MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_TIMEOUT));
                    }else {
                        reject(MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_FAILED));
                    }
                });
            });
    
            result['id'] = reqId;
            Ajax.addRequest(reqId, req);
    
            return result;
        })

        result2['id'] = reqId;
        return result2;
    }

    static _resuestsMap: Map<string, any> = new Map();

    static addRequest(reqId: string, req: any): void {
        Ajax._resuestsMap.set(reqId, req);
    }

    static removeRequest(reqId: string): void {
        Ajax._resuestsMap.delete(reqId);
    }

    static abortRequest(reqId: string): void {
        if (Ajax._resuestsMap.has(reqId)) {
            try {
                Ajax._resuestsMap.get(reqId).abort();
            }catch(e) {
                Ajax.removeRequest(reqId);
                // ignore abort error
            }finally {
                Ajax.removeRequest(reqId);
            }
        }
    }
}

