import { UserBroadcast } from './../../proto/generated/UserBroadcast';
import { CacheMessage } from './../../proto/generated/CacheMessage';
import { UserBoard } from './../../proto/generated/UserBoard';
import { User } from '../../proto/generated/User';
import { UserRelation } from '../../proto/generated/UserRelation';
import { UserContact } from '../../proto/generated/UserContact';
import { UserFavorite } from '../../proto/generated/UserFavorite';
import { UserMentionMe } from '../../proto/generated/UserMentionMe';
import { Contacts } from '../../proto/generated/Contacts';
import { UserCache } from '../cache/userCache';
import { MxCallback, MxSubscription, UserPresence } from './../../api/defines';
import { MxObservable } from '../../core/mxObserver';

export class MxUserSubscriber {
    private _userCache: UserCache;
    private _userBasicInfoObservable : MxObservable<User>;
    private _userBoardObservable : MxObservable<UserBoard[]>;
    private _userActionItemObservable : MxObservable<UserBoard[]>;
    private _userRelationObservable : MxObservable<UserRelation[]>;
    private _userContactObservable : MxObservable<UserContact[]>;
    private _userFavoriteObservable : MxObservable<UserFavorite[]>;
    private _userMentionMeObservable : MxObservable<UserMentionMe[]>;
    private _userPresenceObservable : MxObservable<UserPresence[]>;
    private _userBroadcastObservable : MxObservable<UserBroadcast[]>;
    private _userNotificationObservable : MxObservable<CacheMessage>;

    constructor(userCache: UserCache) {
        this._userCache = userCache;

        this._userBasicInfoObservable = new MxObservable<User>('user.basicInfo');
        this._userBoardObservable = new MxObservable<UserBoard[]>('user.boards');
        this._userActionItemObservable = new MxObservable<UserBoard[]>('user.action_items');
        this._userRelationObservable = new MxObservable<UserRelation[]>('user.relations');
        this._userContactObservable = new MxObservable<UserContact[]>('user.contacts');
        this._userFavoriteObservable = new MxObservable<UserFavorite[]>('user.favorites');
        this._userMentionMeObservable = new MxObservable<UserMentionMe[]>('user.mentionmes');
        this._userPresenceObservable = new MxObservable<UserPresence[]>('user.presences');
        this._userBroadcastObservable = new MxObservable<UserBroadcast[]>('user.broadcasts');
        this._userNotificationObservable = new MxObservable<CacheMessage>('user.notification');
    }

    subscribeUserBasicInfo(cb: MxCallback<User>): MxSubscription {
        return this._userBasicInfoObservable.subscribe(cb);
    }  

    subscribeUserBoards(cb: MxCallback<UserBoard[]>): MxSubscription {
        return this._userBoardObservable.subscribe(cb);
    }

    subscribeActionItems(cb: MxCallback<UserBoard[]>): MxSubscription {
        return this._userActionItemObservable.subscribe(cb);
    }

    subscribeUserRelations(cb: MxCallback<UserRelation[]>): MxSubscription {
        return this._userRelationObservable.subscribe(cb);  
    }

    subscribeUserContacts(cb: MxCallback<UserContact[]>): MxSubscription {
        return this._userContactObservable.subscribe(cb);  
    }

    subscribeUserFavorites(cb: MxCallback<UserFavorite[]>): MxSubscription {
        return this._userFavoriteObservable.subscribe(cb);  
    }

    subscribeUserMentionMes(cb: MxCallback<UserMentionMe[]>): MxSubscription {
        return this._userMentionMeObservable.subscribe(cb);    
    }

    subscribeUserPresences(cb: MxCallback<UserPresence[]>): MxSubscription {
        return this._userPresenceObservable.subscribe(cb);
    }

    subscribeUserBroadcasts(cb: MxCallback<UserBroadcast[]>): MxSubscription {
        return this._userBroadcastObservable.subscribe(cb);
    }

    subscribeUserNotification(cb: MxCallback<CacheMessage>): MxSubscription {
        return this._userNotificationObservable.subscribe(cb);
    }

    onObjectUpdate(user?: User, contacts?: Contacts, notificationMessage?: CacheMessage): void {
        if (user) {
            if ((user.local_revision || user.tags || user.groups) && this._userBasicInfoObservable.hasObservers()) {
                this._userBasicInfoObservable.publish(this._userCache.basicInfo());
            }
    
            if (user.boards && this._userBoardObservable.hasObservers()) {
                this._userBoardObservable.publish(user.boards);
            }
        
            if (user.action_items && this._userActionItemObservable.hasObservers()) {
                this._userActionItemObservable.publish(user.action_items);
            }

            if (user.relations && this._userRelationObservable.hasObservers()) {
                this._userRelationObservable.publish(user.relations);
            }
    
            if (user.contacts && this._userContactObservable.hasObservers()) {
                this._userContactObservable.publish(user.contacts);
            }
    
            if (user.mentionmes && this._userMentionMeObservable.hasObservers()) {
                this._userMentionMeObservable.publish(user.mentionmes);
            }
    
            if (user.favorites && this._userFavoriteObservable.hasObservers()) {
                this._userFavoriteObservable.publish(user.favorites);
            }

            if (user.broadcasts && this._userBroadcastObservable.hasObservers()) {
                this._userBroadcastObservable.publish(user.broadcasts);
            }
        }

        if (contacts && contacts.contacts && this._userPresenceObservable.hasObservers()) {
            let updatedUserPresences: UserPresence[] = [];
            contacts.contacts.forEach(user => {
                if (user.id) {
                    let presence = this._userCache.getUserPresenceByUserId(user.id);
                    if (presence) {
                        updatedUserPresences.push(presence);
                    }
                }
            })

            if (updatedUserPresences.length > 0) {
                this._userPresenceObservable.publish(updatedUserPresences);
            }
        }

        if (notificationMessage && this._userNotificationObservable.hasObservers()) {
            this._userNotificationObservable.publish(notificationMessage);
        }
    }
}