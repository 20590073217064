import { ClassLogger, transformError } from "@controller/common/decorators"
import { BoardFormatter } from '../../utils/board'
import { StringUtils } from '../../../commonUtils/string'
import { Defines } from 'isdk'
import { BoardSignee, BoardSignature, BoardPage, UserIdentity, MxSignatureElement, ClientResponse, MxPageElementType, MxBaseObjectType } from "isdk/src/api/defines"
import { CBoardSignee, SignElement, SignPage } from '../../defines/CBoardSignature'
import { CSignBaseObject } from '../../defines/thread/CSignBaseObject'
import { BoardSignatureStatus } from "isdk/src/proto/generated/BoardSignatureStatus";
import { ArrayUtils } from '../../../commonUtils/array'
import { ObjectUtils } from '../../../commonUtils/object'
import { MxUser, MxISDK, MxBoard, MxAjax } from "isdk"
import { BoardPageElement } from "isdk/src/proto/generated/BoardPageElement"
import {Board} from "isdk/src/proto/generated/Board"
import { CPreviewFile } from "@controller/defines/CPreviewFile"
import { FileFormatter } from "@controller/utils/file"
import _findIndex from 'lodash/findIndex'
import _assign from 'lodash/assign'
import cloneDeep from 'lodash/cloneDeep'
import { GroupController } from '@controller/group/src/groupController'
import { UserFormatter } from "@controller/utils/user"

@ClassLogger()
export class SignatureController {

  private signature: BoardSignature
  private boardInstance: MxBoard
  private __signees__: BoardSignee[]
  private binderBasicInfo: Board
  private isAuditBoard = false

  constructor (binderId: string, signature: BoardSignature, fullBoard?: Board, isAudit?: Boolean) {
    this.signature = signature
    if (fullBoard) {
      this.isAuditBoard = true
      if (isAudit === false) {
        this.isAuditBoard = true
      }
      this.binderBasicInfo = fullBoard;
    } else {
      this.boardInstance = MxISDK.getCurrentUser().getCacheBoard(binderId)
      this.boardInstance && (this.binderBasicInfo = this.boardInstance.basicInfo);
    }

    if (this.signature.signees) {
      this.__signees__ = this.signature.signees
    }
  }


  get isInProgress() {
    return this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS
  }

  get isEnded () {
    return this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED
  }

  get isDeclined () {
    return this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_DECLINED
  }

  get signees () {
    const signature = this.signature
    const convertedSignees: CBoardSignee[] = []
    if (this.__signees__) {
      const sortedSignees: BoardSignee[] = this.getSignees()
      const isDeclined = signature.status === BoardSignatureStatus.SIGNATURE_STATUS_DECLINED
      const isCompleted = signature.status === BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED
      const signByOrder = !(signature.sign_by_order === false)
      let findCurrSigner = false
      for (let i = 0; i < sortedSignees.length; i++) {
        const signee = sortedSignees[i]
        const convertedSignee: CBoardSignee = _assign(signee, BoardFormatter.transformBoardActor(signee.actor.user, this.binderBasicInfo))
        convertedSignee.isSigned = this.isSigned(signee)
        convertedSignee.isYourTurn = false
        convertedSignee.isSigning = false
        convertedSignee.isWaiting = false
        convertedSignee.isDecline = false
        convertedSignee.isCancel = false

        if (convertedSignee.isSigned) {
          convertedSignees.push(convertedSignee)
          continue
        }

        if (isDeclined) {
          if (signee.is_submitted) {
            convertedSignee.isDecline = true;
          } else {
            convertedSignee.isCancel = true;
          }
        } else if (!isCompleted) {
          if (signByOrder) {
            if (!findCurrSigner) {
              if (convertedSignee.isMySelf) {
                convertedSignee.isYourTurn = true
              } else {
                convertedSignee.isSigning = true
              }
              findCurrSigner = true;
            } else {
              convertedSignee.isWaiting = true
            }
          } else {
            if (convertedSignee.isMySelf) {
              convertedSignee.isYourTurn = true
            } else {
              convertedSignee.isSigning = true
            }
          }
        }
        convertedSignees.push(convertedSignee)
      }
    }
    return convertedSignees
  }

  get pages () {
    const signPages = this.signature.pages
    const convertedPages: SignPage[] = []
    signPages.forEach(page => {
      if (page.page_type) {
        convertedPages.push(this.toSignatureModel(page))
      }
    })
    return convertedPages
  }

  /**
   * for thread baseObject view
   */
  getSignBaseObject(spath: string): CSignBaseObject {
    if (this.signature && this.signature.is_deleted) {
      return {
        is_deleted: this.signature.is_deleted,
        sequence: this.signature.sequence,
        type: MxBaseObjectType.SIGNATURE,
        updated_time: this.signature.updated_time,
        created_time: this.signature.created_time,
        creator: {}
      }
    } else {
      return {
        SPath: spath,
        sequence: this.signature.sequence,
        type: MxBaseObjectType.SIGNATURE,
        creator: BoardFormatter.transformBoardActor(ObjectUtils.getByPath(this.signature, 'creator.user'), this.binderBasicInfo),
        updated_time: this.signature.updated_time,
        created_time: this.signature.created_time,
        fileType: FileFormatter.getFileType(null, this.pages[0]),
        isOwner: ObjectUtils.getByPath(this.signature, 'creator.user.id') === MxISDK.getCurrentUser().user.id,
        displayName: this.signature.name,
        isCompleted: this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED,
        isEnd: this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED || this.signature.status === BoardSignatureStatus.SIGNATURE_STATUS_DECLINED,
        original: this.signature.original,
        status: this.signature.status,
        isMyTurn: this.isWaitMySign(),
        signees: this.signees || []
      }
    }


  }

  toSignatureModel (page: BoardPage) {
    const convertedPage: SignPage = cloneDeep(page)
    const pageTypes: Array<string> = convertedPage.page_type.split('_')
	  const isProperty: string = StringUtils.transformCamelCase('is', [pageTypes[2]])
	  convertedPage[isProperty] = true
    convertedPage.boardId = this.binderBasicInfo.id
    convertedPage.contents = this.transformSignElements(page)
    convertedPage.signatureSequence = this.signature.sequence
    convertedPage.source = FileFormatter.getSignaturePageSource(page, this.signature, this.binderBasicInfo.id)
    convertedPage.name = this.signature.name
    if (this.signature.signees) {
      convertedPage.signees = this.signees
    }
    return convertedPage
  }

  transformSignElements (page: CPreviewFile|BoardPage|any): SignElement[] {
    const parsedElements: SignElement[] = []
    const contents: BoardPageElement[] = page.contents
    if (contents) {
      for (let content of contents) {
        const parsedElement: SignElement = content
        if (parsedElement.is_deleted) {
          continue
        }
        parsedElement.type = ObjectUtils.getByPath(content, 'tags.0.uint64_value')
        if (!parsedElement.type || parsedElement.type < MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_SIGNATURE) {
          parsedElements.push(parsedElement)
          continue
        }
        const sequence = content.sequence
        for (let signee of this.signees) {
          if (signee.elements && signee.elements.indexOf(sequence) > -1) {
            parsedElement.isSigned = signee.isSigned
            parsedElement.signee = signee
            parsedElement.signeeName = signee.name
            break
          }
        }

        parsedElement.boardId = this.binderBasicInfo.id
        parsedElement.pageSequence = page.pageSequence || page.sequence
        parsedElement.signatureSequence = this.signature.sequence
        if (ObjectUtils.getByPath(parsedElement, 'creator.user')) {
          parsedElement.creator = BoardFormatter.transformBoardActor(parsedElement.creator.user, this.binderBasicInfo)
        }
        switch (parsedElement.type) {
          case MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_SIGNATURE:
            parsedElement.isSignature = true
            break
          case MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_INITIALS:
            parsedElement.isInitial = true
            break
          case MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_DATE:
            parsedElement.isDate = true
            break
          case MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_TEXT:
            parsedElement.isText = true
            break
          case MxPageElementType.PAGE_ELEMENT_TYPE_SIGN_CHECKBOX:
            parsedElement.isCheckbox = true
            break
        }
        if (parsedElement.isSigned && (parsedElement.isSignature || parsedElement.isInitial || parsedElement.isCheckbox)) {
          const matchedStr = parsedElement.svg_tag.match(/xlink:href="(\S*)"/)
          if (matchedStr) {
            const prefix = this.isAuditBoard ? '/board/audit' : '/board'
            parsedElement.source = `${prefix}/${parsedElement.boardId}/signature/${parsedElement.signatureSequence}/${parsedElement.pageSequence}/${matchedStr[1]}`
          }
        }

        parsedElements.push(parsedElement)
      }
    }
    return parsedElements
  }

  isWaitMySign (user?: any) {
    const signature = this.signature
    if (signature.status !== BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS) {
      return false
    }
    const MxUser: MxUser = MxISDK.getCurrentUser()
    let signees = this.getSignees()
    let count = signees.length
    let curUser = user ? user : MxUser.basicInfo
    let signee;
    if (signature.sign_by_order === false) {
      let me = ArrayUtils.getFromArray(signees, curUser.id, 'actor.user.id')
      if (me && !this.isSigned(me)) {
        return true
      }
    }
    for (let i=0 ; i < count; i++) {
      signee = signees[i]
      if (!this.isSigned(signee)) {
        const signeeUser = ObjectUtils.getByPath(signee, 'actor.user', {})
        if (UserFormatter.isSameUser(signeeUser, curUser)) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  }

  private getSignees () {
    return this.__signees__.filter(signee => !signee.is_deleted).sort(this.sortSignees)
  }

  sortSignees (a: BoardSignee, b: BoardSignee) {
    const aon = parseInt(a.order_number, 10)
    const bon = parseInt(b.order_number, 10)
    if (aon < bon) {
      return -1
    } else if (aon === bon) {
      if (a.sequence < b.sequence) {
        return -1
      } else {
        return 1
      }
    } else {
      return 1
    }
  }

  isSigned (signee: BoardSignee) {
    if (!signee.is_submitted) {
      return false
    }
    if (signee.elements && signee.submitted_elements && signee.elements.length === signee.submitted_elements.length) {
      return true
    } else {
      return false
    }
  }

  @transformError()
  updateSignature (params: BoardSignature): Promise<Defines.Board> {
    return this.boardInstance.updateSignature(this.signature.sequence, params)
  }

  @transformError()
  deleteSignature (): Promise<Defines.Board> {
    return this.boardInstance.deleteSignature(this.signature.sequence)
  }

  @transformError()
  addSignatureSignee (signees: UserIdentity | UserIdentity[]): Promise<ClientResponse> {
    return new Promise((resolve, reject) => {
      this.boardInstance.addSignatureSignee(this.signature.sequence, signees)
        .then((board:Defines.Board) => {
          const createdSignees = ObjectUtils.getByPath(board, 'signatures.0.signees')
          this.__signees__ = createdSignees
          resolve()
        })
        .catch(reject)
    })
  }

  @transformError()
  startSignature (): Promise<Defines.Board> {
    return this.boardInstance.startSignature(this.signature.sequence)
  }

  @transformError()
  submitSignature (elements: MxSignatureElement[], keepStatusUnchanged = false): Promise<Defines.Board> {
    return this.boardInstance.submitSignature(this.signature.sequence, elements, keepStatusUnchanged)
  }

  @transformError()
  updateSignatureSignee (signeeSequence: number, signee: BoardSignee): Promise<Defines.Board> {
    return this.boardInstance.updateSignatureSignee(this.signature.sequence, signeeSequence, signee)
  }

  @transformError()
  createSignatureElement (pageSequence: number, content: string, type: MxPageElementType, uuid?: string): Promise<SignElement> {
    return new Promise((resolve, reject) => {
      this.boardInstance.createSignatureElement(this.signature.sequence, pageSequence, content, type, uuid)
        .then((board:Defines.Board) => {
          const responseBoard = board
          const signaturePage = ObjectUtils.getByPath(responseBoard, 'signatures.0.pages.0')
          if (signaturePage) {
            const transformedElements = this.transformSignElements(signaturePage)
            resolve(transformedElements[0])
          }
        })
        .catch(reject)
    })
  }

  @transformError()
  updateSignatureElement (pageSequence: number, client_uuid: string, content: string): Promise<Defines.Board> {
    return this.boardInstance.updateSignatureElement(this.signature.sequence, pageSequence, client_uuid, content)
  }

  @transformError()
  deleteSignatureElement (pageSequence: number, uuid: string): Promise<Defines.Board> {
    return this.boardInstance.deleteSignatureElement(this.signature.sequence, pageSequence, uuid)
  }

  @transformError()
  assignElementToSignee (signeeSequence: number, elements: number[]): Promise<object> {
    return new Promise((resolve, reject) => {
      this.boardInstance.assignElementToSignee(this.signature.sequence, signeeSequence, elements)
        .then((board:Defines.Board) => {
          const updatedSignee = ObjectUtils.getByPath(board, 'signatures.0.signees.0')
          const signeeIndex = _findIndex(this.__signees__, s => s.sequence === updatedSignee.sequence)
          let _elements_ = updatedSignee.elements
          if (_elements_.length === 1 && _elements_[0] === 0) {
            _elements_ = []
          }
          if (signeeIndex > -1) {
            this.__signees__[signeeIndex].elements = _elements_
          }
          resolve({
            sequence: updatedSignee.sequence,
            elements: _elements_
          })
        })
        .catch(reject)
    })
  }

  @transformError()
  uploadSignatureResourceToPage (pageSequence: number, resourceUrl: string, resourceName: string): Promise<Defines.Board> {
    return this.boardInstance.uploadSignatureResourceToPage(this.signature.sequence, pageSequence, resourceUrl, resourceName)
  }

  @transformError()
  updateInitialText (text: string) {
    const currentUser = MxISDK.getCurrentUser()
    return currentUser.updateProfile({
      initials_text: text
    })
  }

  @transformError()
  uploadUserSignature (name: string, sourceUrl: string) {
    const url = `/user/upload?name=${encodeURIComponent(name)}&type=signature`
    return MxAjax.post(url, sourceUrl, {
      contentType: 'application/data-uri'
    })
  }

  @transformError()
  uploadImageToSignee (signeeSequence: number, name: string, sourceUrl: string) {
    const url =  `/board/${this.boardInstance.id}/signature/${this.signature.sequence}/${signeeSequence}?type=signature&name=${encodeURIComponent(name)}`;
    return MxAjax.post(url, sourceUrl, {
      contentType: 'application/data-uri'
    })
  }

  @transformError()
  uploadImageToSignFile (pageSequence: number, name: string, sourceUrl: string) {
    const url =  `/board/${this.boardInstance.id}/signature/${this.signature.sequence}/${pageSequence}/${encodeURIComponent(name)}`;
    return MxAjax.post(url, sourceUrl, {
      contentType: 'application/data-uri'
    })
  }

  @transformError()
  updateLegalName (name: string) {
    return MxISDK.getCurrentUser().updateProfile({
      legal_name: name
    })
  }

  static isValidSignatureFile (signature: BoardSignature){
    return !signature.is_deleted && signature.status !== BoardSignatureStatus.SIGNATURE_STATUS_PREPARING
  }
}
