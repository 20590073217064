import { BoardTag, MxRoutingChannelType, MxViewBoardAsParam, User } from "../api/defines";
import { Ajax } from "../network/ajax";
import { Connection } from './../network/connection';
import { boardRequestNode, boardRequestNodeWithViewAs, groupRequestNode } from "../network/requestNode";
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { BoardRoutingStatus } from '../proto/generated/BoardRoutingStatus';
import { ClientParam } from '../proto/generated/ClientParam';
import { Board } from '../proto/generated/Board';
import { Group } from '../proto/generated/Group';
import { RoutingChannel } from '../proto/generated/RoutingChannel';
import { RoutingConfig } from '../proto/generated/RoutingConfig';
import { BoardViewToken } from "../proto/generated/BoardViewToken";

function listRoutingRequests(groupId: string, fromTime: number, toTime: number, count: number): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if (fromTime) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_FROM,
      string_value: String(fromTime)
    })
  }  
  
  if (toTime) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_TO,
      string_value: String(toTime)
    })
  } 

  if (count) {
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_READ_COUNT,
      string_value: String(count)
    })
  } 

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.ROUTING_SERVICE_REQUEST_LIST, {id: groupId}, params));
}

function subscribeRoutingRequests(): Promise<ClientResponse> {
  return Connection.getInstance().subscribeRoutingRequests();
}

function createRoutingRequest(type: MxRoutingChannelType, name: string, description: string, routingChannel: number, routingStatus?: BoardRoutingStatus, user?: User): Promise<ClientResponse> {
  let board: Board = {
    name: name,
    description: description,
    routing_status: routingStatus
  }

  if (routingChannel) {
    board.routing_channel = routingChannel;
  }

  if (user) {
    let viewToken: BoardViewToken = {
      actor_file_as: user
    }
    board.view_tokens = [ viewToken ]
    const userInfoTag: BoardTag = {
      name: 'API_Acd_User_Name',
      string_value: JSON.stringify(user)
    }
    board.tags = [userInfoTag]
  }

  let reqType: ClientRequestType;

  if (type === MxRoutingChannelType.ACD) {
    board.is_acd = true;
    reqType = ClientRequestType.ROUTING_ACD_REQUEST_CREATE;
  }else if (type === MxRoutingChannelType.SR){
    board.is_service_request = true;
    reqType = ClientRequestType.ROUTING_SERVICE_REQUEST_CREATE;
  }

  return Ajax.sendRequest(boardRequestNode(reqType, board));
}

function updateRoutingRequestStatus(boardId: string, status: BoardRoutingStatus, isACD: boolean, viewAs?: MxViewBoardAsParam): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    routing_status: status,
  }

  let reqType: ClientRequestType = isACD ? ClientRequestType.ROUTING_ACD_REQUEST_UPDATE : ClientRequestType.ROUTING_SERVICE_REQUEST_UPDATE;
  return Ajax.sendRequest(boardRequestNodeWithViewAs(reqType, board, null, viewAs));
}

function acceptRoutingRequest(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.ROUTING_ACD_REQUEST_ACCEPT, {id: boardId}));
}

function declineRoutingRequest(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.ROUTING_ACD_REQUEST_DECLINE, {id: boardId}));
}

function dispatchRoutingRequest(boardId: string, agentUserId: string): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    users: [ {
      user: {
        id: agentUserId
      }
    } ]
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.ROUTING_SERVICE_REQUEST_UPDATE, board));
}

function updateRoutingConfig(groupId: string, config: RoutingConfig): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    routing_config: config
  }
  
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE, group));
}

function createRoutingChannel(groupId: string, type: MxRoutingChannelType, channel: RoutingChannel): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    routing_config: {}
  };

  if (type === MxRoutingChannelType.ACD) {
    group.routing_config.acd_channels = [channel];
  }else if (type === MxRoutingChannelType.SR){
    group.routing_config.sr_channels = [channel];
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CREATE_ROUTING_CHANNEL, group));
}

function updateRoutingChannel(groupId: string, type: MxRoutingChannelType, channel: RoutingChannel): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    routing_config: {}
  };

  if (type === MxRoutingChannelType.ACD) {
    group.routing_config.acd_channels = [channel];
  }else if (type === MxRoutingChannelType.SR){
    group.routing_config.sr_channels = [channel];
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_ROUTING_CHANNEL, group));
}

function updateRoutingChannels(groupId: string, type: MxRoutingChannelType, channels: RoutingChannel[]): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    routing_config: {}
  };

  if (type === MxRoutingChannelType.ACD) {
    group.routing_config.acd_channels = channels;
  }else if (type === MxRoutingChannelType.SR){
    group.routing_config.sr_channels = channels;
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_ROUTING_CHANNEL, group));
}

function deleteRoutingChannel(groupId: string, type: MxRoutingChannelType, channelSeq: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    routing_config: {}
  };

  if (type === MxRoutingChannelType.ACD) {
    group.routing_config.acd_channels = [{sequence: channelSeq}];
  }else if (type === MxRoutingChannelType.SR){
    group.routing_config.sr_channels = [{sequence: channelSeq}];
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_DELETE_ROUTING_CHANNEL, group));
}

function readOfficeHour(groupId: string): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
  }
  
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.ROUTING_ACD_REQUEST_READ_OFFICE_HOUR, group));
}

export {
  listRoutingRequests,
  subscribeRoutingRequests,
  createRoutingRequest,
  updateRoutingRequestStatus,
  acceptRoutingRequest,
  declineRoutingRequest,
  dispatchRoutingRequest,
  updateRoutingConfig,
  createRoutingChannel,
  updateRoutingChannel,
  updateRoutingChannels,
  deleteRoutingChannel,
  readOfficeHour,
}