import moment from 'moment-timezone'
import { ObjectUtils } from './object'
import _difference from 'lodash/difference'
import { MxISDK } from "isdk"


let zones = [
  {
    name: 'Africa/Abidjan'
  },
  {
    name: 'Africa/Accra'
  },
  {
    name: 'Africa/Addis_Ababa'
  },
  {
    name: 'Africa/Algiers',
    display: 'Central European Time (Africa/Algiers)'
  },
  {
    name: 'Africa/Asmera'
  },
  {
    name: 'Africa/Bamako'
  },
  {
    name: 'Africa/Bangui'
  },
  {
    name: 'Africa/Banjul'
  },
  {
    name: 'Africa/Bissau'
  },
  {
    name: 'Africa/Blantyre'
  },
  {
    name: 'Africa/Brazzaville'
  },
  {
    name: 'Africa/Bujumbura'
  },
  {
    name: 'Africa/Cairo',
    display: 'Eastern European Standard Time (Africa/Cairo)',
    display_DST: 'Eastern European Summer Time (Africa/Cairo)'
  },
  {
    name: 'Africa/Casablanca',
    display: 'Western European Time (Africa/Casablanca)'
  },
  {
    name: 'Africa/Ceuta'
  },
  {
    name: 'Africa/Conakry'
  },
  {
    name: 'Africa/Dakar'
  },
  {
    name: 'Africa/Dar_es_Salaam'
  },
  {
    name: 'Africa/Djibouti'
  },
  {
    name: 'Africa/Douala'
  },
  {
    name: 'Africa/El_Aaiun'
  },
  {
    name: 'Africa/Freetown'
  },
  {
    name: 'Africa/Gaborone'
  },
  {
    name: 'Africa/Harare'
  },
  {
    name: 'Africa/Johannesburg',
    display: 'South Africa Standard Time (Africa/Johannesburg)'
  },
  {
    name: 'Africa/Kampala'
  },
  {
    name: 'Africa/Khartoum'
  },
  {
    name: 'Africa/Kigali'
  },
  {
    name: 'Africa/Kinshasa'
  },
  {
    name: 'Africa/Lagos'
  },
  {
    name: 'Africa/Libreville'
  },
  {
    name: 'Africa/Lome'
  },
  {
    name: 'Africa/Luanda'
  },
  {
    name: 'Africa/Lubumbashi'
  },
  {
    name: 'Africa/Lusaka'
  },
  {
    name: 'Africa/Malabo'
  },
  {
    name: 'Africa/Maputo'
  },
  {
    name: 'Africa/Maseru'
  },
  {
    name: 'Africa/Mbabane'
  },
  {
    name: 'Africa/Mogadishu'
  },
  {
    name: 'Africa/Monrovia'
  },
  {
    name: 'Africa/Nairobi',
    display: 'East Africa Time (Africa/Nairobi)'
  },
  {
    name: 'Africa/Ndjamena'
  },
  {
    name: 'Africa/Niamey'
  },
  {
    name: 'Africa/Nouakchott'
  },
  {
    name: 'Africa/Ouagadougou'
  },
  {
    name: 'Africa/Porto-Novo'
  },
  {
    name: 'Africa/Sao_Tome'
  },
  {
    name: 'Africa/Timbuktu'
  },
  {
    name: 'Africa/Tripoli'
  },
  {
    name: 'Africa/Tunis'
  },
  {
    name: 'Africa/Windhoek'
  },
  {
    name: 'America/Adak',
    display: 'Hawaii-Aleutian Standard Time (America/Adak)',
    display_DST: 'Hawaii-Aleutian Daylight Time (America/Adak)'
  },
  {
    name: 'America/Anchorage',
    display: 'Alaska Standard Time (America/Anchorage)',
    display_DST: 'Alaska Daylight Time (America/Anchorage)'
  },
  {
    name: 'America/Anguilla'
  },
  {
    name: 'America/Antigua'
  },
  {
    name: 'America/Araguaina'
  },
  {
    name: 'America/Aruba'
  },
  {
    name: 'America/Asuncion'
  },
  {
    name: 'America/Barbados'
  },
  {
    name: 'America/Belem'
  },
  {
    name: 'America/Belize'
  },
  {
    name: 'America/Boa_Vista'
  },
  {
    name: 'America/Bogota',
    display: 'Colombia Time (America/Bogota)'
  },
  {
    name: 'America/Boise'
  },
  {
    name: 'America/Buenos_Aires',
    display: 'Argentina Time (America/Argentina/Buenos_Aires)'
  },
  {
    name: 'America/Cambridge_Bay'
  },
  {
    name: 'America/Cancun'
  },
  {
    name: 'America/Caracas',
    display: 'Venezuela Time (America/Caracas)'
  },
  {
    name: 'America/Catamarca'
  },
  {
    name: 'America/Cayenne'
  },
  {
    name: 'America/Cayman'
  },
  {
    name: 'America/Chicago',
    display: 'Central Standard Time (America/Chicago)',
    display_DST: 'Central Daylight Time (America/Chicago)'
  },
  {
    name: 'America/Chihuahua'
  },
  {
    name: 'America/Cordoba'
  },
  {
    name: 'America/Costa_Rica'
  },
  {
    name: 'America/Cuiaba'
  },
  {
    name: 'America/Curacao'
  },
  {
    name: 'America/Danmarkshavn'
  },
  {
    name: 'America/Dawson'
  },
  {
    name: 'America/Dawson_Creek'
  },
  {
    name: 'America/Denver',
    display: 'Mountain Standard Time (America/Denver)',
    display_DST: 'Mountain Daylight Time (America/Denver)'
  },
  {
    name: 'America/Detroit'
  },
  {
    name: 'America/Dominica'
  },
  {
    name: 'America/Edmonton'
  },
  {
    name: 'America/Eirunepe'
  },
  {
    name: 'America/El_Salvador',
    display: 'Central Standard Time (America/El_Salvador)'
  },
  {
    name: 'America/Fortaleza'
  },
  {
    name: 'America/Glace_Bay'
  },
  {
    name: 'America/Godthab'
  },
  {
    name: 'America/Goose_Bay'
  },
  {
    name: 'America/Grand_Turk'
  },
  {
    name: 'America/Grenada'
  },
  {
    name: 'America/Guadeloupe'
  },
  {
    name: 'America/Guatemala'
  },
  {
    name: 'America/Guayaquil'
  },
  {
    name: 'America/Guyana'
  },
  {
    name: 'America/Halifax',
    display: 'Atlantic Standard Time (America/Halifax)',
    display_DST: 'Atlantic Daylight Time (America/Halifax)'
  },
  {
    name: 'America/Havana'
  },
  {
    name: 'America/Hermosillo'
  },
  {
    name: 'America/Indiana/Indianapolis',
    display: 'Eastern Standard Time (America/Indiana/Indianapolis)'
  },
  {
    name: 'America/Indiana/Knox'
  },
  {
    name: 'America/Indiana/Marengo'
  },
  {
    name: 'America/Indiana/Vevay'
  },
  {
    name: 'America/Indianapolis'
  },
  {
    name: 'America/Inuvik'
  },
  {
    name: 'America/Iqaluit'
  },
  {
    name: 'America/Jamaica'
  },
  {
    name: 'America/Jujuy'
  },
  {
    name: 'America/Juneau'
  },
  {
    name: 'America/Kentucky/Louisville'
  },
  {
    name: 'America/Kentucky/Monticello'
  },
  {
    name: 'America/La_Paz'
  },
  {
    name: 'America/Lima',
    display: 'Peru Time (America/Lima)'
  },
  {
    name: 'America/Los_Angeles',
    display: 'Pacific Standard Time (America/Los_Angeles)',
    display_DST: 'Pacific Daylight Time (America/Los_Angeles)'
  },
  {
    name: 'America/Louisville'
  },
  {
    name: 'America/Maceio'
  },
  {
    name: 'America/Managua'
  },
  {
    name: 'America/Manaus'
  },
  {
    name: 'America/Martinique'
  },
  {
    name: 'America/Mazatlan',
    display: 'Mountain Standard Time (America/Mazatlan)',
    display_DST: 'Mountain Daylight Time (America/Mazatlan)'
  },
  {
    name: 'America/Mendoza'
  },
  {
    name: 'America/Menominee'
  },
  {
    name: 'America/Merida'
  },
  {
    name: 'America/Mexico_City',
    display: 'Central Standard Time (America/Mexico_City)'
  },
  {
    name: 'America/Miquelon'
  },
  {
    name: 'America/Monterrey'
  },
  {
    name: 'America/Montevideo'
  },
  {
    name: 'America/Montreal'
  },
  {
    name: 'America/Montserrat'
  },
  {
    name: 'America/Nassau'
  },
  {
    name: 'America/New_York',
    display: 'Eastern Standard Time (America/New_York)',
    display_DST: 'Eastern Daylight Time (America/New_York)'
  },
  {
    name: 'America/Nipigon'
  },
  {
    name: 'America/Nome'
  },
  {
    name: 'America/Noronha'
  },
  {
    name: 'America/North_Dakota/Center'
  },
  {
    name: 'America/Panama',
    display: 'Eastern Standard Time (America/Panama)'
  },
  {
    name: 'America/Pangnirtung'
  },
  {
    name: 'America/Paramaribo'
  },
  {
    name: 'America/Phoenix',
    display: 'Mountain Standard Time (America/Phoenix)'
  },
  {
    name: 'America/Port-au-Prince'
  },
  {
    name: 'America/Port_of_Spain'
  },
  {
    name: 'America/Porto_Velho'
  },
  {
    name: 'America/Puerto_Rico',
    display: 'Atlantic Standard Time (America/Puerto_Rico)'
  },
  {
    name: 'America/Rainy_River'
  },
  {
    name: 'America/Rankin_Inlet'
  },
  {
    name: 'America/Recife'
  },
  {
    name: 'America/Regina'
  },
  {
    name: 'America/Rio_Branco'
  },
  {
    name: 'America/Rosario'
  },
  {
    name: 'America/Santiago',
    display: 'Chile Standard Time (America/Santiago)',
    display_DST: 'Chile Summer Time (America/Santiago)'
  },
  {
    name: 'America/Santo_Domingo'
  },
  {
    name: 'America/Sao_Paulo',
    display: 'Brasilia Standard Time (America/Sao_Paulo)',
    display_DST: 'Brasilia Summer Time (America/Sao_Paulo)'
  },
  {
    name: 'America/Scoresbysund',
    display: 'East Greenland Standard Time (America/Scoresbysund)',
    display_DST: 'East Greenland Summer Time (America/Scoresbysund)'
  },
  {
    name: 'America/Shiprock'
  },
  {
    name: 'America/St_Johns',
    display: 'Newfoundland Standard Time (America/St_Johns)',
    display_DST: 'Newfoundland Daylight Time (America/St_Johns)'
  },
  {
    name: 'America/St_Kitts'
  },
  {
    name: 'America/St_Lucia'
  },
  {
    name: 'America/St_Thomas'
  },
  {
    name: 'America/St_Vincent'
  },
  {
    name: 'America/Swift_Current'
  },
  {
    name: 'America/Tegucigalpa'
  },
  {
    name: 'America/Thule'
  },
  {
    name: 'America/Thunder_Bay'
  },
  {
    name: 'America/Tijuana',
    display: 'Pacific Standard Time (America/Tijuana)',
    display_DST: 'Pacific Daylight Time (America/Tijuana)'
  },
  {
    name: 'America/Tortola'
  },
  {
    name: 'America/Vancouver'
  },
  {
    name: 'America/Whitehorse'
  },
  {
    name: 'America/Winnipeg'
  },
  {
    name: 'America/Yakutat'
  },
  {
    name: 'America/Yellowknife'
  },
  {
    name: 'Antarctica/Casey'
  },
  {
    name: 'Antarctica/Davis'
  },
  {
    name: 'Antarctica/DumontDUrville'
  },
  {
    name: 'Antarctica/Mawson'
  },
  {
    name: 'Antarctica/McMurdo'
  },
  {
    name: 'Antarctica/Palmer'
  },
  {
    name: 'Antarctica/South_Pole'
  },
  {
    name: 'Antarctica/Syowa'
  },
  {
    name: 'Antarctica/Vostok'
  },
  {
    name: 'Arctic/Longyearbyen'
  },
  {
    name: 'Asia/Aden'
  },
  {
    name: 'Asia/Almaty'
  },
  {
    name: 'Asia/Amman'
  },
  {
    name: 'Asia/Anadyr'
  },
  {
    name: 'Asia/Aqtau'
  },
  {
    name: 'Asia/Aqtobe'
  },
  {
    name: 'Asia/Ashgabat'
  },
  {
    name: 'Asia/Baghdad',
    display: 'Arabian Standard Time (Asia/Baghdad)',
    display_DST: 'Arabian Daylight Time (Asia/Baghdad)'
  },
  {
    name: 'Asia/Bahrain'
  },
  {
    name: 'Asia/Baku',
    display: 'Azerbaijan Standard Time (Asia/Baku)',
    display_DST: 'Azerbaijan Summer Time (Asia/Baku)'
  },
  {
    name: 'Asia/Bangkok',
    display: 'Indochina Time (Asia/Bangkok)'
  },
  {
    name: 'Asia/Shanghai',
    display: 'China Standard Time (Asia/Beijing)'
  },
  {
    name: 'Asia/Beirut',
    display: 'Eastern European Standard Time (Asia/Beirut)',
    display_DST: 'Eastern European Summer Time (Asia/Beirut)'
  },
  {
    name: 'Asia/Bishkek'
  },
  {
    name: 'Asia/Brunei'
  },
  {
    name: 'Asia/Calcutta',
    // Kolkata, formerly Calcutta, is the capital of the Indian state of West Bengal
    display: 'Asia/Kolkata'
  },
  {
    name: 'Asia/Choibalsan'
  },
  {
    name: 'Asia/Chongqing'
  },
  {
    name: 'Asia/Colombo',
    display: 'India Standard Time (Asia/Colombo)'
  },
  {
    name: 'Asia/Damascus'
  },
  {
    name: 'Asia/Dhaka',
    display: 'Bangladesh Time (Asia/Dhaka)'
  },
  {
    name: 'Asia/Dili'
  },
  {
    name: 'Asia/Dubai',
    display: 'Gulf Standard Time (Asia/Dubai)'
  },
  {
    name: 'Asia/Dushanbe'
  },
  {
    name: 'Asia/Gaza'
  },
  {
    name: 'Asia/Harbin'
  },
  {
    name: 'Asia/Hong_Kong',
    display: 'Hong Kong Time (Asia/Hong_Kong)'
  },
  {
    name: 'Asia/Hovd'
  },
  {
    name: 'Asia/Irkutsk'
  },
  {
    name: 'Asia/Istanbul'
  },
  {
    name: 'Asia/Jakarta',
    display: 'Western Indonesia Time (Asia/Jakarta)'
  },
  {
    name: 'Asia/Jayapura'
  },
  {
    name: 'Asia/Jerusalem',
    display: 'Israel Standard Time (Asia/Jerusalem)',
    display_DST: 'Israel Daylight Time (Asia/Jerusalem)'
  },
  {
    name: 'Asia/Kabul',
    display: 'Afghanistan Time (Asia/Kabul)'
  },
  {
    name: 'Asia/Kamchatka',
    display: 'Magadan Standard Time (Asia/Kamchatka)',
    display_DST: 'Magadan Summer Time (Asia/Kamchatka)'
  },
  {
    name: 'Asia/Karachi',
    display: 'Pakistan Time (Asia/Karachi)'
  },
  {
    name: 'Asia/Kashgar'
  },
  {
    name: 'Asia/Katmandu'
  },
  {
    name: 'Asia/Krasnoyarsk'
  },
  {
    name: 'Asia/Kuala_Lumpur',
    display: 'Malaysia Time (Asia/Kuala_Lumpur)'
  },
  {
    name: 'Asia/Kuching'
  },
  {
    name: 'Asia/Kuwait',
    display: 'Arabian Standard Time (Asia/Kuwait)'
  },
  {
    name: 'Asia/Macao'
  },
  {
    name: 'Asia/Macau'
  },
  {
    name: 'Asia/Magadan'
  },
  {
    name: 'Asia/Makassar'
  },
  {
    name: 'Asia/Manila',
    display: 'Philippine Time (Asia/Manila)'
  },
  {
    name: 'Asia/Muscat'
  },
  {
    name: 'Asia/Nicosia'
  },
  {
    name: 'Asia/Novosibirsk'
  },
  {
    name: 'Asia/Omsk'
  },
  {
    name: 'Asia/Oral'
  },
  {
    name: 'Asia/Phnom_Penh'
  },
  {
    name: 'Asia/Pontianak'
  },
  {
    name: 'Asia/Pyongyang'
  },
  {
    name: 'Asia/Qyzylorda'
  },
  {
    name: 'Asia/Qatar'
  },
  {
    name: 'Asia/Rangoon',
    display: 'Myanmar Time (Asia/Rangoon)'
  },
  {
    name: 'Asia/Riyadh',
    display: 'Arabian Standard Time (Asia/Riyadh)'
  },
  {
    name: 'Asia/Saigon'
  },
  {
    name: 'Asia/Sakhalin'
  },
  {
    name: 'Asia/Samarkand'
  },
  {
    name: 'Asia/Seoul',
    display: 'Korean Standard Time (Asia/Seoul)'
  },
  /*
{
      "name": "Asia/Shanghai"
},
*/
  {
    name: 'Asia/Singapore',
    display: 'Singapore Standard Time (Asia/Singapore)'
  },
  {
    name: 'Asia/Taipei',
    display: 'Taipei Standard Time (Asia/Taipei)'
  },
  {
    name: 'Asia/Tashkent',
    display: 'Uzbekistan Time (Asia/Tashkent)'
  },
  {
    name: 'Asia/Tbilisi',
    display: 'Georgia Standard Time (Asia/Tbilisi)',
    display_DST: 'Georgia Summer Time (Asia/Tbilisi)'
  },
  {
    name: 'Asia/Tehran',
    display: 'Iran Standard Time (Asia/Tehran)'
  },
  {
    name: 'Asia/Thimphu'
  },
  {
    name: 'Asia/Tokyo',
    display: 'Japan Standard Time (Asia/Tokyo)'
  },
  {
    name: 'Asia/Ujung_Pandang'
  },
  {
    name: 'Asia/Ulaanbaatar'
  },
  {
    name: 'Asia/Urumqi'
  },
  {
    name: 'Asia/Vientiane'
  },
  {
    name: 'Asia/Vladivostok'
  },
  {
    name: 'Asia/Yakutsk'
  },
  {
    name: 'Asia/Yekaterinburg',
    display: 'Yekaterinburg Standard Time (Asia/Yekaterinburg)',
    display_DST: 'Yekaterinburg Summer Time (Asia/Yekaterinburg)'
  },
  {
    name: 'Asia/Yerevan',
    display: 'Armenia Standard Time (Asia/Yerevan)',
    display_DST: 'Armenia Summer Time (Asia/Yerevan)'
  },
  {
    name: 'Atlantic/Azores',
    display: 'Azores Standard Time (Atlantic/Azores)',
    display_DST: 'Azores Summer Time (Atlantic/Azores)'
  },
  {
    name: 'Atlantic/Bermuda',
    display: 'Atlantic Standard Time (Atlantic/Bermuda)',
    display_DST: 'Atlantic Daylight Time (Atlantic/Bermuda)'
  },
  {
    name: 'Atlantic/Canary'
  },
  {
    name: 'Atlantic/Cape_Verde',
    display: 'Cape Verde Time (Atlantic/Cape_Verde)'
  },
  {
    name: 'Atlantic/Faeroe'
  },
  {
    name: 'Atlantic/Jan_Mayen'
  },
  {
    name: 'Atlantic/Madeira'
  },
  {
    name: 'Atlantic/Reykjavik'
  },
  {
    name: 'Atlantic/South_Georgia',
    display: 'South Georgia Time (Atlantic/South_Georgia)'
  },
  {
    name: 'Atlantic/St_Helena'
  },
  {
    name: 'Atlantic/Stanley'
  },
  {
    name: 'Australia/Adelaide',
    display: 'Australian Central Standard Time (Australia/Adelaide)',
    display_DST: 'Australian Central Daylight Time (Australia/Adelaide)'
  },
  {
    name: 'Australia/Brisbane',
    display: 'Australian Eastern Standard Time (Australia/Brisbane)'
  },
  {
    name: 'Australia/Broken_Hill'
  },
  {
    name: 'Australia/Darwin',
    display: 'Australian Central Standard Time (Australia/Darwin)'
  },
  {
    name: 'Australia/Hobart'
  },
  {
    name: 'Australia/Lindeman'
  },
  {
    name: 'Australia/Lord_Howe',
    display: 'Lord Howe Standard Time (Australia/Lord_Howe)',
    display_DST: 'Lord Howe Daylight Time (Australia/Lord_Howe)'
  },
  {
    name: 'Australia/Melbourne'
  },
  {
    name: 'Australia/Perth',
    display: 'Australian Western Standard Time (Australia/Perth)'
  },
  {
    name: 'Australia/Sydney',
    display: 'Australian Eastern Standard Time (Australia/Sydney)',
    display_DST: 'Australian Eastern Daylight Time (Australia/Sydney)'
  },
  {
    name: 'Europe/Amsterdam',
    display: 'Central European Standard Time (Europe/Amsterdam)',
    display_DST: 'Central European Summer Time (Europe/Amsterdam)'
  },
  {
    name: 'Europe/Andorra'
  },
  {
    name: 'Europe/Athens',
    display: 'Eastern European Standard Time (Europe/Athens)',
    display_DST: 'Eastern European Summer Time (Europe/Athens)'
  },
  {
    name: 'Europe/Belfast'
  },
  {
    name: 'Europe/Belgrade'
  },
  {
    name: 'Europe/Berlin',
    display: 'Central European Standard Time (Europe/Berlin)',
    display_DST: 'Central European Summer Time (Europe/Berlin)'
  },
  {
    name: 'Europe/Bratislava'
  },
  {
    name: 'Europe/Brussels',
    display: 'Central European Standard Time (Europe/Brussels)',
    display_DST: 'Central European Summer Time (Europe/Brussels)'
  },
  {
    name: 'Europe/Bucharest',
    display: 'Eastern European Standard Time (Europe/Bucharest)',
    display_DST: 'Eastern European Summer Time (Europe/Bucharest)'
  },
  {
    name: 'Europe/Budapest'
  },
  {
    name: 'Europe/Chisinau'
  },
  {
    name: 'Europe/Copenhagen'
  },
  {
    name: 'Europe/Dublin',
    display: 'Irish Standard Time (Europe/Dublin)',
    display_DST: 'Irish Summer Time (Europe/Dublin)'
  },
  {
    name: 'Europe/Gibraltar'
  },
  {
    name: 'Europe/Helsinki',
    display: 'Eastern European Standard Time (Europe/Helsinki)',
    display_DST: 'Eastern European Summer Time (Europe/Helsinki)'
  },
  {
    name: 'Europe/Istanbul',
    display: 'Eastern European Standard Time (Europe/Istanbul)',
    display_DST: 'Eastern European Summer Time (Europe/Istanbul)'
  },
  {
    name: 'Europe/Kaliningrad'
  },
  {
    name: 'Europe/Kiev'
  },
  {
    name: 'Europe/Lisbon',
    display: 'Western European Standard Time (Europe/Lisbon)',
    display_DST: 'Western European Summer Time (Europe/Lisbon)'
  },
  {
    name: 'Europe/Ljubljana'
  },
  {
    name: 'Europe/London',
    display: 'British Standard Time (Europe/London)',
    display_DST: 'British Summer Time (Europe/London)'
  },
  {
    name: 'Europe/Luxembourg'
  },
  {
    name: 'Europe/Madrid'
  },
  {
    name: 'Europe/Malta'
  },
  {
    name: 'Europe/Minsk',
    display: 'Moscow Standard Time (Europe/Minsk)',
    display_DST: 'Moscow Summer Time (Europe/Minsk)'
  },
  {
    name: 'Europe/Monaco'
  },
  {
    name: 'Europe/Moscow',
    display: 'Moscow Standard Time (Europe/Moscow)',
    display_DST: 'Moscow Summer Time (Europe/Moscow)'
  },
  {
    name: 'Europe/Nicosia'
  },
  {
    name: 'Europe/Oslo'
  },
  {
    name: 'Europe/Paris',
    display: 'Central European Standard Time (Europe/Paris)',
    display_DST: 'Central European Summer Time (Europe/Paris)'
  },
  {
    name: 'Europe/Prague',
    display: 'Central European Standard Time (Europe/Prague)',
    display_DST: 'Central European Summer Time (Europe/Prague)'
  },
  {
    name: 'Europe/Riga'
  },
  {
    name: 'Europe/Rome',
    display: 'Central European Standard Time (Europe/Rome)',
    display_DST: 'Central European Summer Time (Europe/Rome)'
  },
  {
    name: 'Europe/Samara'
  },
  {
    name: 'Europe/San_Marino'
  },
  {
    name: 'Europe/Sarajevo'
  },
  {
    name: 'Europe/Simferopol'
  },
  {
    name: 'Europe/Skopje'
  },
  {
    name: 'Europe/Sofia'
  },
  {
    name: 'Europe/Stockholm'
  },
  {
    name: 'Europe/Tallinn'
  },
  {
    name: 'Europe/Tirane'
  },
  {
    name: 'Europe/Uzhgorod'
  },
  {
    name: 'Europe/Vaduz'
  },
  {
    name: 'Europe/Vatican'
  },
  {
    name: 'Europe/Vienna'
  },
  {
    name: 'Europe/Vilnius'
  },
  {
    name: 'Europe/Warsaw'
  },
  {
    name: 'Europe/Zagreb'
  },
  {
    name: 'Europe/Zaporozhye'
  },
  {
    name: 'Europe/Zurich'
  },
  {
    name: 'Indian/Antananarivo'
  },
  {
    name: 'Indian/Chagos'
  },
  {
    name: 'Indian/Christmas'
  },
  {
    name: 'Indian/Cocos'
  },
  {
    name: 'Indian/Comoro'
  },
  {
    name: 'Indian/Kerguelen'
  },
  {
    name: 'Indian/Mahe'
  },
  {
    name: 'Indian/Maldives'
  },
  {
    name: 'Indian/Mauritius'
  },
  {
    name: 'Indian/Mayotte'
  },
  {
    name: 'Indian/Reunion'
  },
  {
    name: 'Pacific/Apia'
  },
  {
    name: 'Pacific/Auckland',
    display: 'New Zealand Standard Time (Pacific/Auckland)',
    display_DST: 'New Zealand Daylight Time (Pacific/Auckland)'
  },
  {
    name: 'Pacific/Chatham',
    display: 'Chatham Standard Time (Pacific/Chatham)',
    display_DST: 'Chatham Daylight Time (Pacific/Chatham)'
  },
  {
    name: 'Pacific/Easter'
  },
  {
    name: 'Pacific/Efate'
  },
  {
    name: 'Pacific/Enderbury',
    display: 'Phoenix Islands Time (Pacific/Enderbury)'
  },
  {
    name: 'Pacific/Fakaofo'
  },
  {
    name: 'Pacific/Fiji',
    display: 'Fiji Time (Pacific/Fiji)'
  },
  {
    name: 'Pacific/Funafuti'
  },
  {
    name: 'Pacific/Galapagos'
  },
  {
    name: 'Pacific/Gambier',
    display: 'Gambier Time (Pacific/Gambier)'
  },
  {
    name: 'Pacific/Guadalcanal',
    display: 'Solomon Islands Time (Pacific/Guadalcanal)'
  },
  {
    name: 'Pacific/Guam'
  },
  {
    name: 'Pacific/Honolulu',
    display: 'Hawaii-Aleutian Standard Time (Pacific/Honolulu)'
  },
  {
    name: 'Pacific/Johnston'
  },
  {
    name: 'Pacific/Kiritimati',
    display: 'Line Islands Time (Pacific/Kiritimati)'
  },
  {
    name: 'Pacific/Kosrae'
  },
  {
    name: 'Pacific/Kwajalein'
  },
  {
    name: 'Pacific/Majuro'
  },
  {
    name: 'Pacific/Marquesas',
    display: 'Marquesas Time (Pacific/Marquesas)'
  },
  {
    name: 'Pacific/Midway'
  },
  {
    name: 'Pacific/Nauru'
  },
  {
    name: 'Pacific/Niue',
    display: 'Niue Time (Pacific/Niue)'
  },
  {
    name: 'Pacific/Norfolk',
    display: 'Norfolk Islands Time (Pacific/Norfolk)'
  },
  {
    name: 'Pacific/Noumea'
  },
  {
    name: 'Pacific/Pago_Pago',
    display: 'Samoa Standard Time (Pacific/Pago_Pago)'
  },
  {
    name: 'Pacific/Palau'
  },
  {
    name: 'Pacific/Pitcairn',
    display: 'Pitcairn Time (Pacific/Pitcairn)'
  },
  {
    name: 'Pacific/Ponape'
  },
  {
    name: 'Pacific/Port_Moresby'
  },
  {
    name: 'Pacific/Rarotonga'
  },
  {
    name: 'Pacific/Saipan'
  },
  {
    name: 'Pacific/Tahiti'
  },
  {
    name: 'Pacific/Tarawa'
  },
  {
    name: 'Pacific/Tongatapu',
    display: 'Tonga Time (Pacific/Tongatapu)'
  },
  {
    name: 'Pacific/Truk'
  },
  {
    name: 'Pacific/Wake'
  },
  {
    name: 'Pacific/Wallis'
  },
  {
    name: 'Pacific/Yap'
  }
]
let zoneAbbreviation = {
  'America/Halifax':'ADT',
  'America/Juneau':'AKST',
  'America/Argentina/Buenos_Aires':'ART',
  'Asia/Dhaka':'BDT',
  'America/Sao_Paulo':'BRT',
  'Europe/London':'BST',
  'Africa/Harare':'CAT',
  'Europe/Paris':'CEST',
  'America/Santiago':'CLST',
  'America/Bogota':'COT',
  'America/Chicago':'CST',
  'Africa/Addis_Ababa':'EAT',
  'Europe/Athens':'EET',
  'America/New_York':'EST',
  'GMT':'GMT',
  'Asia/Dubai':'GST',
  'Asia/Hong_Kong':'HKT',
  'Pacific/Honolulu':'HST',
  'Asia/Bangkok':'ICT',
  'Asia/Tehran':'IRST',
  'Asia/Kolkata':'IST',
  'Asia/Tokyo':'JST',
  'Asia/Seoul':'KST',
  'America/Denver':'MDT',
  'Europe/Moscow':'MSK',
  'America/Phoenix':'MST',
  'America/St_Johns':'NST',
  'Pacific/Auckland':'NZST',
  'America/Lima':'PET',
  'Asia/Manila':'PHT',
  'Asia/Karachi':'PKT',
  'America/Los_Angeles':'PST',
  'Asia/Singapore':'SGT',
  'Europe/Istanbul':'TRT',
  'UTC':'UTC',
  'Africa/Lagos':'WAT',
  'Europe/Lisbon':'WET',
  'Asia/Jakarta':'WIT'
}

const isTimezoneInList = function (tz) {
  return zones.findIndex((z) => z.name === tz) !== -1
}

const getMatchedZone = function (tz) {
  try {
    const matchedZones = zones.filter((z) => {
      const zA = moment.tz(z.name)
      const zB = moment.tz(tz)
      return zA.format('Z z') === zB.format('Z z')
        && zA.isDST() === zB.isDST()
    })
    return ObjectUtils.getByPath(matchedZones, '0.name', undefined)
  }
  catch (e) {
    // if tz is a timezone which is not accepted by mommentjs
    // it will produce a js error, in here we will fall back to undefined as a NO-MATCH
    return undefined
  }
}


const regex_simpleTemplate = /\${([\w\.$-]+)(?:\:([\w\.$]*)(?:\((.*?)?\))?)?\}/g;
const regex_template = /\{{([\w-]+)(?:\:([\w]*)(?:\((.*?)?\))?)?\}}/g;

export class TimeUtils {
  static getTime(ts: number, timezone: string) {
    if (timezone) {
      return ts ? moment(ts).tz(timezone) : moment().tz(timezone)
    } else {
      return ts ? moment(ts) : moment()
    }
  }

  static getTimezonedTime(ts = Date.now(), timezone: string) {
    if (!timezone) {
      timezone = moment.tz.guess()
    }
    return moment(ts).tz(timezone)
  }

  static getTimezoneOffset(timezone?: string) {
    // TODO: should we cache the offset data instead of parsing and calculating every time????
    // user should always has timezone with the default value
    // v2.8.6, @above_question: user can change his timezone, so if we save offset to cache, we should listen to timezone change.
    var offset = 0;
    if (!timezone) {
      return offset;
    }
    try {
      // offset = (moment.tz(timezone).zone() - moment.tz('').zone()) * 60000;
      // NOTE: v2.8.6, momentjs 0.3.0, utcOffset() return difference with zone(), i.e.
      // utcOffset() return 480
      // zone() return -480
      // v2.8.6, Date object also has getTimezoneOffset method, i.e. (new Date()).getTimezoneOffset(), and it returns such as -480/480.
      // Here, in order to process timestamp convenient, * 60000, so KEEP IN MIND: Don't Confuse!!
      offset = (moment().utcOffset() - moment().tz(timezone).utcOffset()) * 60000;
    } catch (e) {
      if (console) {
        console.error(e);
      }
    }
    return offset;
  }

  static format(str: string, data: any, value?: any) {
    let args: any, currArgs = arguments;
    str = str || '';
    if (currArgs.length === 2) {
      if (Array.isArray(data)) {
        args = data;
      } else if (ObjectUtils.isObject(data)) {
        let formatFn = (m: any, name: any) => {
          let val = data[name];
          if (ObjectUtils.isDefine(val)) {
            return val;
          } else {
            return m;
          }
        };
        if (str.indexOf('${') >= 0) {
          return str.replace(regex_simpleTemplate, formatFn);
        } else {
          return str.replace(regex_template, formatFn);
        }
      } else {
        args = [].slice.call(arguments, 1);
      }
    } else {
      args = [].slice.call(arguments, 1);
    }
    return str.replace(/\{(\d+)\}/g, function (m, i) {
      return args[i];
    });
  }

  static formatTime(timestamp: number, format: string, timezone?: string, noNeedTimezone?: boolean) {
    if (noNeedTimezone) {
      return moment(timestamp).format(format);
    } else {
      let _timezone = timezone || '';
      if (_timezone) {
        // server side follow https://github.com/boost-vault/date_time/blob/master/date_time_zonespec.csv
        // use Asia/Calcutta
        // but momentjs use Asia/Kolkata
        // v2.8.6, momentjs changed to 0.3.0, this exception fixed.
        // if (timezone === 'Asia/Calcutta') {
        // timezone = 'Asia/Kolkata';
        // }
        try {
          return moment(timestamp).tz(_timezone).format(format)
        } catch (e) {
          // if moment-timezone-data.js has no this timezone info
          return moment(timestamp).format(format)
        }
      } else {
        return moment(timestamp).format(format)
      }
    }
  }

  static formatShortTime(timestamp: number, timezone?: string, userLanguage?: string) {
    let _timezone = timezone
    let hoursFormat = TimeUtils.getHourMechanism()
    return TimeUtils.formatTime(timestamp, hoursFormat, _timezone);  
  }

  static formatShortDateTime(timestamp: number, timezone?: string, userLanguage?: string) {
    let _timezone = timezone
    let hoursFormat = TimeUtils.getHourMechanism()
    return TimeUtils.formatTime(timestamp, `MMM D, ${hoursFormat}`, _timezone);  
  }

  static formatShortDate(timestamp: number, timezone?: string, ignoreYear?: boolean) {
    let _timezone = timezone
    if (ignoreYear) {
      return TimeUtils.formatTime(timestamp, 'MMM DD', _timezone);
    } else {
      return TimeUtils.formatTime(timestamp, 'll', _timezone);
    }
  }

  static formatFullDateTime(timestamp: number, timezone: string) {
    let _timezone = timezone || '';
    let hoursFormat = TimeUtils.getHourMechanism()
    if (timestamp) {
      if (_timezone) {
        return moment(timestamp).tz(_timezone).format(`LL, ${hoursFormat}`);
      } else {
        return moment(timestamp).format(`LL, ${hoursFormat}`);
      }
    } else {
      return '';
    }
  }

  static formatDuration(duration: number, lang: any) {
    var d = moment.duration(duration), hours, minutes, seconds, result = [];
    hours = d.hours();
    minutes = d.minutes();
    seconds = d.seconds();
    if (seconds) {
      minutes += 1;
    }
    if (minutes >= 60) {
      hours += 1;

      minutes -= 60;
    }
    if (hours) {
      result.push(hours);
      result.push(hours > 1 ? lang.hrs : lang.hr);
    }
    if (minutes) {
      result.push(minutes);
      result.push(minutes > 1 ? lang.mins : lang.min);
    }

    return result.join(' ');
  }

  static getTimezoneList() {
    let m = moment(Date.now()), result = []
    zones.forEach(function (zone) {
      // add try/catch, in case zone.name define error
      try {
        let tz = m.tz(zone.name)
        let offset = tz.format('Z')
        let display = '(GMT' + offset + ') ' + zone.name
        display = display.replace(new RegExp(/[_]/g), ' ').replace(new RegExp(/[/]/g), ' - ')
        result.push({
          name: zone.name,
          display,
          offset: offset
        })
      } catch (e) {
        // console.error(e)
      }
    })

    // sort: offset, then display name
    result.sort(function (ra, rb) {
      let ta = parseFloat(ra.offset.replace(':', '.'))

      let tb = parseFloat(rb.offset.replace(':', '.'))

      if (ta < tb) {
        return -1
      } else if (ta > tb) {
        return 1
      } else {
        let disa = ra.display.toLowerCase()

        let disb = rb.display.toLowerCase()

        if (disa < disb) {
          return -1
        } else if (disa > disb) {
          return 1
        } else {
          return 0
        }
      }
    })
    return result
  }

  static tryMatchTimezone(timezone) {
    timezone = timezone || moment.tz.guess(true)
    return isTimezoneInList(timezone) ? timezone : getMatchedZone(timezone)
  }

  static getZoneDisplayNameByName(name) {
    let result = TimeUtils.getTimezoneList().find((z) => name === z.name)
    return result ? result.display : moment.tz.guess()
  }

  static getZoneAbbreviation (name) {
    return zoneAbbreviation[name]
  }


  static getLanguageArea(){
    let user = ObjectUtils.getByPath(MxISDK.getCurrentUser(), 'user')
    let currentLanguage = user ? user.language : null
    let browserLanguage = (navigator.languages && navigator.languages.length? navigator.languages[0] : navigator.language || navigator['userLanguage'] || "en-US").toLowerCase();
    let reg= /^(en-)/
    if(currentLanguage){
      if(currentLanguage === 'en'){
        return ( reg.test(browserLanguage) ? browserLanguage : "en-us")
      }else{
        return currentLanguage
      }
    }else{
      return browserLanguage
    }
  }
  static getHourMechanism(){
    let hour12 = ['zh', 'zh-tw', 'en-au', 'en-bz', 'en-ca', 'en-jm', 'en-nz', 'en-tt', 'en-us']
    let hour24 = ['bg', 'bs', 'de', 'es', 'fi','fr', 'hr', 'hu', 'id', 'it', 'ja', 'nl', 'pt', 'ro', 'ru', 'en-gb', 'en-ie', 'en-za', 'en-zw']
    let computedArea =TimeUtils.getLanguageArea()
    let currentHour = ''
    if (hour12.includes(computedArea.toLocaleLowerCase())){
      currentHour = 'h'
    }else if(hour24.includes(computedArea.toLocaleLowerCase())){
      currentHour = 'HH'
    }else{
      currentHour = 'h'
    }
    return currentHour === 'h' ? 'h:mm A': 'HH:mm'
  }
}
