
import { Board } from "../proto/generated/Board";
import { Ajax } from "../network/ajax";
import { boardRequestNode } from "../network/requestNode";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { BoardComment } from "../proto/generated/BoardComment";
import { ClientParam } from "../proto/generated/ClientParam";
import { ClientRequestParameter } from "../proto/generated/ClientRequestParameter";

function createComment(boardId: string, comment: BoardComment): Promise<ClientResponse> {
  let board: Board = {
    id: boardId, 
    comments: [comment]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_COMMENT, board));
}

function updateComment(boardId: string, commentSequence: number, comment: BoardComment): Promise<ClientResponse> {
  let board: Board = {
    id: boardId, 
    comments: [{
      ...comment,
      sequence: commentSequence,
      is_modified:true,
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_COMMENT, board));
}

function deleteComment(boardId: string, commentSequence: number, noFeed?: boolean): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params =[{
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    }]
  }
  
  let board: Board = {
    id: boardId, 
    comments: [{
      sequence: commentSequence,
      is_deleted: true,
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_COMMENT, board, params));
}


function createPositionComment(boardId: string, pageSeq: number, comment: BoardComment): Promise<ClientResponse> {
  let board: Board = {
    id: boardId, 
    pages: [{
      sequence: pageSeq,
      comments: [comment]
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_PAGE_POSITION_COMMENT, board));
}

function updatePositionComment(boardId: string, pageSeq: number, commentSequence: number, comment: BoardComment): Promise<ClientResponse> {
  let board: Board = {
    id: boardId, 
    pages: [{
      sequence: pageSeq,
      comments: [{
        ...comment,
        sequence: commentSequence,
      }]
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_PAGE_POSITION_COMMENT, board));
}

function deletePositionComment(boardId: string, pageSeq: number, commentSequence: number): Promise<ClientResponse> {
  let board: Board = {
    id: boardId, 
    pages: [{
      sequence: pageSeq,
      comments: [{
        sequence: commentSequence,
        is_deleted: true,
      }]
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_PAGE_POSITION_COMMENT, board));
}

export {
    createComment,
    updateComment,
    deleteComment,
    createPositionComment,
    updatePositionComment,
    deletePositionComment,
}