import {MxAjax, MxISDK} from 'isdk'
export class NetUtils {
	static ajaxGet (url, opts) {
		return MxAjax.get(url, opts)
	}
	static ajaxPost (url, data) {
		return MxAjax.post(url, data)
	}
	static getClientVersion () {
		return MxISDK.getISDKConfig().clientVersion
	}
}
