import { BoardUserActivity } from './../../proto/generated/BoardUserActivity';
import { ObjectFeedType } from './../../proto/generated/ObjectFeedType';
import { BoardResource } from './../../proto/generated/BoardResource';
import { BoardUser } from './../../proto/generated/BoardUser';
import { BoardReferenceLink } from './../../proto/generated/BoardReferenceLink';
import { BoardFolder } from './../../proto/generated/BoardFolder';
import { MxBaseObjectType, MxSPath } from './../../api/defines';
import { BoardPageGroup } from './../../proto/generated/BoardPageGroup';
import { BoardPage } from './../../proto/generated/BoardPage';
import { BoardTransaction } from './../../proto/generated/BoardTransaction';
import { BoardSignature } from './../../proto/generated/BoardSignature';
import { BoardTodo } from './../../proto/generated/BoardTodo';
import { BoardSession } from './../../proto/generated/BoardSession';
import { BoardComment } from './../../proto/generated/BoardComment';
import { Board } from "../../proto/generated/Board";
import { ObjectFeed } from "../../proto/generated/ObjectFeed";
import { mergeBoardCacheObject, mergeCacheObject } from "../../proto";
import { cloneObject, isArray, getByPath, filepath2spath, getBySPath, parseFileSequenceFromFilePath, parseFileSequenceFromSPath } from "../../util";
import { MxBaseObject } from "../../api/defines";

function extractObjectSequences(obj: Object, seqs: Set<number>) {
    obj && Object.keys(obj).forEach((key: string) => {
        if (obj[key] && isArray(obj[key])) {
            obj[key].forEach((element: Object) => {
                if (element && element['sequence']) {
                    seqs.add(element['sequence']);
                    extractObjectSequences(element, seqs);
                }
            });
        }
    })
}

// only extract first level sub objects
function extractObjectBySequences(obj: Object, seqs: Set<number>): Object {
    let result: Object = {};
    obj && Object.keys(obj).forEach((key: string) => {
        if (obj[key] && isArray(obj[key])) {
            obj[key].forEach((element: Object) => {
                if (element && element['sequence'] && seqs.has(element['sequence'])) {
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(element);
                }
            });
        }
    })
    
    return result;
}

function buildFileSPath(file: BoardPageGroup, folder: BoardFolder): MxSPath {
    let path = '';
    if (file) {
        path = `page_groups[sequence=${file.sequence}]`;
    }else if(folder) {
        path = `folders[sequence=${folder.sequence}]`;
        while (folder.folders && folder.folders.length > 0) {
            folder = folder.folders[0];
            path += `.folders[sequence=${folder.sequence}]`;
        }
        if (folder && folder.files && folder.files.length > 0) {
            path += `.files[sequence=${folder.files[0].sequence}]`;
        }
    }
    return path;
}

function getCacheObjects<T>(itemsMap: Map<number, T>, filterSequences: number[]=null, clone: boolean=false): T[] {
    // if there is no cache objects, return null
    // if there is cache objects but empty, return []
    let items: T[] = null;
    let filter: Set<number> = filterSequences ? new Set(filterSequences) : null;

    if (itemsMap) {
        items = [];
        itemsMap.forEach((obj, seq) => {
            if (filter === null) {
                items.push(clone ? cloneObject(obj) : obj);
            }else if (filter && filter.size > 0 && filter.has(seq)) {
                items.push(clone ? cloneObject(obj) : obj);
            }
        })
        return items;
    }
    return items;
}

function addCacheObjects<T>(items: T[], itemsMap: Map<number, T>) {
    itemsMap && items && items.forEach(item => {
        if (item['sequence'] > 0) {
            itemsMap.set(item['sequence'], cloneObject(item));
        }
    })
}

function mergeCacheObjects<T>(items: T[], itemsMap: Map<number, T>, itemType: string) {
    itemsMap && items && items.forEach(item => {
        if (item['sequence'] > 0) {
            if (itemsMap.has(item['sequence'])) {
                // update
                mergeCacheObject(itemsMap.get(item['sequence']), item, itemType);
            }else {
                // add
                itemsMap.set(item['sequence'], cloneObject(item));
            }
        }
    })
}


export class BoardCache {
    private _cacheBoard: Board;
    private _usersMap: Map<number, BoardUser>;
    private _flatFeedsMap: Map<number, ObjectFeed>;
    private _nonFlatFeedsMap: Map<number, ObjectFeed>;
    private _todosMap: Map<number, BoardTodo>;
    private _sessionsMap: Map<number, BoardSession>;
    private _signaturesMap: Map<number, BoardSignature>;
    private _transactionsMap: Map<number, BoardTransaction>;
    private _commentsMap: Map<number, BoardComment>;
    private _referenceLinksMap: Map<number, BoardReferenceLink>;
    private _userActivitiesMap: Map<number, BoardUserActivity>;
    private _filesMap: Map<MxSPath, Board>;     // read file detail cache
    private _foldersMap: Map<MxSPath, Board>;   // list folder cache, key is parent folder path
    private _mainStreamFeeds: Set<number>;
    private _threadsMap: Map<MxSPath, Board>;
    private _feedRelatedObjectsMap: Map<number, Set<number>>; // key: base / related object sequence, val: feed sequences
    private _hasTransactions: boolean;

    constructor(board: Board) {
        this._cacheBoard = board;
        this._mainStreamFeeds = new Set();
        this._feedRelatedObjectsMap = new Map();
        this._threadsMap = new Map();
        this._nonFlatFeedsMap = new Map();
        this._hasTransactions = board.total_transactions ? true : false;

        this.addCacheUsers(board.users);
        this.addCacheComments(board.comments);
        this.addCacheSessions(board.sessions);
    }

    get board(): Board {
        return this._cacheBoard;
    }

    cacheTodos(filterSequences: number[]=null, clone: boolean=false): BoardTodo[] {
        return getCacheObjects<BoardTodo>(this._todosMap, filterSequences, clone);
    }

    cacheReferenceLinks(filterSequences: number[]=null, clone: boolean=false): BoardReferenceLink[] {
        return getCacheObjects<BoardReferenceLink>(this._referenceLinksMap, filterSequences, clone);
    }

    cacheSessions(filterSequences: number[]=null, clone: boolean=false): BoardSession[] {
        return getCacheObjects<BoardSession>(this._sessionsMap, filterSequences, clone);
    }

    cacheSignatures(filterSequences: number[]=null, clone: boolean=false): BoardSignature[] {
        return getCacheObjects<BoardSignature>(this._signaturesMap, filterSequences, clone);
    }

    cacheUserActivities(filterSequences: number[]=null, clone: boolean=false): BoardSignature[] {
        return getCacheObjects<BoardUserActivity>(this._userActivitiesMap, filterSequences, clone);
    }

    cacheTransactions(filterSequences: number[]=null, clone: boolean=false): BoardTransaction[] {
        return getCacheObjects<BoardTransaction>(this._transactionsMap, filterSequences, clone);
    }

    hasTransactions(): boolean {
        return this._hasTransactions;
    }

    cacheFile(filePath: MxSPath, isUpToDate=false): Board {
        if (this._filesMap && this._filesMap.has(filePath)) {
            let cb = this._filesMap.get(filePath);
            if (!isUpToDate) {
                return cb;
            }else if (isUpToDate && this.isCacheObjectUpToDate(cb)) {
                return cb;
            }
        }
        return null;
    }

    cacheFolder(parentFolder: MxSPath, isUpToDate=false): Board {
        if (this._foldersMap && this._foldersMap.has(parentFolder)) {
            let cb = this._foldersMap.get(parentFolder);
            if (!isUpToDate) {
                return cb;
            }else if (isUpToDate && this.isCacheObjectUpToDate(cb)) {
                return cb;
            }
        }
        return null;
    }

    cacheThread(baseObj: MxBaseObject, isUpToDate=false): Board {
        if (this._threadsMap && this._threadsMap.has(baseObj.spath)) {
            let cb = this._threadsMap.get(baseObj.spath);
            if (!isUpToDate) {
                return cb;
            }else if (isUpToDate && this.isCacheObjectUpToDate(cb)) {
                return cb;
            }
        }
        return null;
    }

    addCacheThread(baseObj: MxBaseObject, threadBoard: Board): void {
        this._threadsMap.set(baseObj.spath, threadBoard);
        this.addCacheFeeds(threadBoard.feeds);
    }

    getReferenceLinksByTodos(todoSequences: number[]=[]): BoardReferenceLink[] {
        let todos: Set<number> = new Set(todoSequences);
        let links: number[] = [];

        this._todosMap && this._todosMap.forEach(todo => {
            if (todo.references && todos.has(todo.sequence)) {
                todo.references.forEach(ref => {
                    if (ref.board && ref.board.reference_links) {
                        ref.board.reference_links.forEach(link => {
                            links.push(link.sequence);
                        })
                    }
                })
            }
        });

        return links.length > 0 ? this.cacheReferenceLinks(links) : [];
    }


    getTodosByReferenceLinks(linkSequences: number[]=[]): BoardTodo[] {
        let todos: BoardTodo[] = [];
        let links: Set<number> = new Set(linkSequences);
        this._todosMap && this._todosMap.forEach(todo => {
            let found = false;
            for(let i = 0; !found && todo.references && i < todo.references.length; i++) {
                let refBoard: Board = todo.references[i].board;
                if (refBoard && refBoard.reference_links) {
                    for (let j = 0; !found && j < refBoard.reference_links.length; j++) {
                        if (links.has(refBoard.reference_links[j].sequence)) {
                            todos.push(todo);
                            found = true;
                            break;
                        }
                    }
                }
            }
        })

        return todos;
    }


    // if thread base object updated, return updated thread board
    updateCacheThread(baseObj: MxBaseObject, updatedBoard: Board): Board {
        let threadBoard: Board = this._threadsMap.get(baseObj.spath);
        if (threadBoard) {
            let updateObjectSeqs: Set<number> = new Set();
            let threadBoardObjectSeqs: Set<number> = new Set();
            extractObjectSequences(updatedBoard, updateObjectSeqs);
            extractObjectSequences(threadBoard, threadBoardObjectSeqs);
            let updatedThreadRelatedSeqs: Set<number> = new Set();
            updateObjectSeqs.forEach(seq => {
                if (threadBoardObjectSeqs.has(seq)) {
                    updatedThreadRelatedSeqs.add(seq);
                }
            })

            let needReloadThread: boolean = false;
            if (baseObj.type === MxBaseObjectType.TODO && updatedBoard.todos) {
                // server will reuse board reference links, if a file already has a reference link
                // attach the file second time, no new reference link will be created
                updatedBoard.todos.forEach(todo => {
                    if (todo.sequence === baseObj.sequence && todo.references) {
                        let links: BoardReferenceLink[] = threadBoard.reference_links || [];
                        let threadLinks: Set<number> = new Set();
                        links.forEach(link => threadLinks.add(link.sequence));

                        todo.references.forEach(reference => {
                            let linkSequence: number = getByPath(reference, 'board.reference_links.0.sequence');
                            if (linkSequence && !updatedThreadRelatedSeqs.has(linkSequence) && !threadLinks.has(linkSequence)) {
                                needReloadThread = true;
                            }
                        })
                    }
                })
            }
            if (needReloadThread) {
                return {'needReloadThread': true} as Board;
            }

            if (baseObj.type === MxBaseObjectType.PAGE) {
                // for file move case, need to include new file
                updatedBoard.pages && updatedBoard.pages.forEach(page => {
                    if (page.file && threadBoardObjectSeqs.has(page.sequence)) {
                        page.file.split('/').forEach(f => {
                            f && updatedThreadRelatedSeqs.add(parseInt(f));
                        });
                    }
                })
            }
            
            if ( (baseObj.type === MxBaseObjectType.FILE || baseObj.type === MxBaseObjectType.TODO || baseObj.type === MxBaseObjectType.TRANSACTION) && updatedBoard.reference_links) {
                // attachments updated
                updatedBoard.reference_links && updatedBoard.reference_links.forEach(link => {
                    updatedThreadRelatedSeqs.add(link.sequence);
                })

                updatedBoard.page_groups && updatedBoard.page_groups.forEach(file => {
                    updatedThreadRelatedSeqs.add(file.sequence);
                })

                updatedBoard.folders && updatedBoard.folders.forEach(folder => {
                    updatedThreadRelatedSeqs.add(folder.sequence);
                })
            }

            if (updatedThreadRelatedSeqs.size > 0) {
                // extract info related with thread base object
                let b: Board = extractObjectBySequences(updatedBoard, updatedThreadRelatedSeqs);
                b.revision = updatedBoard.revision;
                mergeBoardCacheObject(threadBoard, b);
                let updatedBaseBoard: Board = cloneObject(threadBoard);
                delete updatedBaseBoard.feeds; // only callback thread base object
                return updatedBaseBoard;
            }

            // todo / page comment update, do not need to update thread
        }
        return null;
    }

    // merge some fields from nonFlat feed to flat feed, such as: page_comment is_position_comment
    mergeFlatFeed(flatFeed: ObjectFeed): void {
        if (flatFeed.type === ObjectFeedType.FEED_PAGES_COMMENT || flatFeed.type === ObjectFeedType.FEED_PAGES_COMMENT_DELETE) {
            let nonFlatFeed = this._nonFlatFeedsMap.get(flatFeed.sequence);
            if (nonFlatFeed) {
                let bp: BoardPage = getByPath(nonFlatFeed.board, 'pages.0');
                let bc: BoardComment = getByPath(nonFlatFeed.board, 'pages.0.comments.0');
                if (bp && bc && bc.is_position_comment) {
                    let bc2: BoardComment = getBySPath(flatFeed.board, `pages[sequence=${bp.sequence}].comments[sequence=${bc.sequence}]`);
                    if (bc2 && bc2.is_deleted) {
                        bc2.is_position_comment = true;
                    }
                }
            }
        }
    }

    addMainStreamFeeds(feeds: ObjectFeed[], isFlatFeed: boolean = true): void {
        if (!feeds || feeds.length === 0) return;

        feeds.forEach(feed => {
            this._mainStreamFeeds.add(feed.sequence);
            if (!isFlatFeed) {
                this._nonFlatFeedsMap.set(feed.sequence, feed);
            }
        })

        if (isFlatFeed) {
            this.addCacheFeeds(feeds);
        }
    }

    filterMainStreamFeeds(feeds: ObjectFeed[]): ObjectFeed[] {
        return feeds.filter(feed => {
            return this._mainStreamFeeds.has(feed.sequence);
        })
    }

    filterThreadFeeds(feeds: ObjectFeed[], baseObj: MxBaseObject): ObjectFeed[] {
        return feeds.filter(feed => {
            let obj = this.getFeedBaseObject(feed.sequence);
            if (obj && baseObj && obj.spath === baseObj.spath) {
                return true;
            }

            // rename feed apply to first page
            if (feed.type === ObjectFeedType.FEED_PAGES_RENAME && baseObj.type === MxBaseObjectType.PAGE) {
                let refFile: BoardPageGroup = getByPath(feed, 'board.page_groups.0');
                let refFolder: BoardFolder = getByPath(feed, 'board.folders.0');
                let refFilePath = buildFileSPath(refFile, refFolder);
                let refFileSequence = parseFileSequenceFromSPath(refFilePath);

                let threadBoard = this.cacheThread(baseObj);
                if (threadBoard) {
                    let filePath = getByPath(threadBoard, 'pages.0.file');
                    let pageFileSequence = parseFileSequenceFromFilePath(filePath);
                    if (pageFileSequence === refFileSequence) {
                        let pageNum: number = getByPath(threadBoard, 'pages.0.original_page_number');
                        if (!pageNum || pageNum === 1) {
                            // first page
                            return true;
                        }
                    }
                }
            }
            return false;
        })
    }

    addCacheUsers(users: BoardUser[]): void {
        if (!this._usersMap) {
            this._usersMap = new Map<number, BoardUser>();
        }
        addCacheObjects<BoardUser>(users, this._usersMap);
    }

    addCacheFeeds(feeds: ObjectFeed[], baseObj?: MxBaseObject): void {
        if (!feeds) return;

        if (!this._flatFeedsMap) {
            this._flatFeedsMap = new Map<number, ObjectFeed>();
        }
        addCacheObjects<ObjectFeed>(feeds, this._flatFeedsMap);

        feeds.forEach(feed => {
            this.setupFeedIndex(feed);
        })
    }

    addCacheTodos(todos: BoardTodo[]): void {
        if (!this._todosMap) {
            this._todosMap = new Map<number, BoardTodo>();
        }
        addCacheObjects<BoardTodo>(todos, this._todosMap);
    }

    addCacheComments(comments: BoardComment[]): void {
        if (!this._commentsMap) {
            this._commentsMap = new Map<number, BoardComment>();
        }
        addCacheObjects<BoardComment>(comments, this._commentsMap);
    }

    addCacheSessions(sessions: BoardSession[]): void {
        if (!this._sessionsMap) {
            this._sessionsMap = new Map<number, BoardSession>();
        }
        addCacheObjects<BoardSession>(sessions, this._sessionsMap);
    }

    addCacheSignatures(signatures: BoardSignature[]): void {
        if (!this._signaturesMap) {
            this._signaturesMap = new Map<number, BoardSignature>();
        }
        addCacheObjects<BoardSignature>(signatures, this._signaturesMap);
    }

    addCacheUserActivities(activities: BoardUserActivity[]): void {
        if (!this._userActivitiesMap) {
            this._userActivitiesMap = new Map<number, BoardUserActivity>();
        }
        addCacheObjects<BoardUserActivity>(activities, this._userActivitiesMap);
    }

    addCacheTransactions(transactions: BoardTransaction[]): void {
        if (!this._transactionsMap) {
            this._transactionsMap = new Map<number, BoardTransaction>();
        }
        addCacheObjects<BoardTransaction>(transactions, this._transactionsMap);
    }

    addCacheReferenceLinks(links: BoardReferenceLink[]): void {
        if (!this._referenceLinksMap) {
            this._referenceLinksMap = new Map<number, BoardReferenceLink>();
        }
        addCacheObjects<BoardReferenceLink>(links, this._referenceLinksMap);
    }

    addCacheFile(filePath: MxSPath, fileBoard: Board): void {
        if (!this._filesMap) {
            this._filesMap = new Map();
        }
        this._filesMap.set(filePath, fileBoard);
    }

    addCacheFolder(parentFolder: MxSPath, folderBoard: Board): void {
        if (!this._foldersMap) {
            this._foldersMap = new Map();
        }
        this._foldersMap.set(parentFolder, folderBoard);
    }

    isCacheObjectUpToDate(obj: Object): boolean {
        if (obj && obj['__is_out_of_date']) {
            return false;
        }
        return true;
    }

    setCacheObjectUpToDate(obj: Object): void {
        if (obj) {
            obj['__is_out_of_date'] = false;
        }
    }

    setCacheObjectOutOfDate(obj: Object): void {
        if (obj) {
            obj['__is_out_of_date'] = true;
        }
    }

    getFeedBySequence(feedSeq: number): ObjectFeed {
        if (this._flatFeedsMap && this._flatFeedsMap.has(feedSeq)) {
            return this._flatFeedsMap.get(feedSeq);
        }
        return null;
    }

    getBaseObject(type: MxBaseObjectType, seq: number=0, parentSeq: number=0, spath: MxSPath=''): MxBaseObject {
        let obj: MxBaseObject = { 
            type: type,
            sequence: seq,
            parentSequence: parentSeq,
        };

        if (type === MxBaseObjectType.COMMENT) {
            obj.spath = `comments[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.TODO) {
            obj.spath = `todos[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.SIGNATURE) {
            obj.spath = `signatures[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.TRANSACTION) {
            obj.spath = `transactions[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.PAGE) {
            obj.spath = `pages[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.POSITION_COMMENT) {
            obj.spath = `pages[sequence=${parentSeq}].comments[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.MEET) {
            obj.spath = `sessions[sequence=${seq}]`;
        }else if (type === MxBaseObjectType.FILE) {
            obj.spath = spath;
        }

        return obj;
    }


    getFeedBaseObject(feedSeq: number): MxBaseObject {
        let obj: MxBaseObject = { type: MxBaseObjectType.NONE };
        let feed: ObjectFeed = this.getFeedBySequence(feedSeq);
        if (!feed) {
            return obj;
        }

        let comment: BoardComment = getByPath(feed, 'board.comments.0');
        let session: BoardSession = getByPath(feed, 'board.sessions.0');
        let todo: BoardTodo = getByPath(feed, 'board.todos.0');
        let signature: BoardSignature = getByPath(feed, 'board.signatures.0');
        let transaction: BoardTransaction = getByPath(feed, 'board.transactions.0');
        let page: BoardPage = getByPath(feed, 'board.pages.0');
        let file: BoardPageGroup = getByPath(feed, 'board.page_groups.0');
        let folder: BoardFolder = getByPath(feed, 'board.folders.0');
        let pageComment: BoardComment = getByPath(feed, 'board.pages.0.comments.0');
        let pageComment2: BoardComment = getByPath(feed, 'board.pages.0.comments.1');
        let referenceLink: BoardReferenceLink = getByPath(feed, 'board.reference_links.0');
        let resource: BoardResource = getByPath(feed, 'board.resources.0');

        if (feed.type === ObjectFeedType.FEED_PAGES_CREATE && !page && resource && resource.file ) {
            // special case: for move file
            return this.getBaseObject(MxBaseObjectType.FILE, 0, 0, filepath2spath(resource.file));
        }else if (feed.type === ObjectFeedType.FEED_BOARD_COMMENT && referenceLink) {
            // special case: for reply on unconverted file
            let refFile: BoardPageGroup = getByPath(referenceLink, 'board.page_groups.0');
            let refFolder: BoardFolder = getByPath(referenceLink, 'board.folders.0');
            let filePath = buildFileSPath(refFile, refFolder);
            return this.getBaseObject(MxBaseObjectType.FILE, 0, 0, filePath);
        }else if (session) {
            return this.getBaseObject(MxBaseObjectType.MEET, session.sequence);
        }else if (todo) {
            return this.getBaseObject(MxBaseObjectType.TODO, todo.sequence);
        }else if (signature) {
            return this.getBaseObject(MxBaseObjectType.SIGNATURE, signature.sequence);
        }else if (transaction) {
            return this.getBaseObject(MxBaseObjectType.TRANSACTION, transaction.sequence);
        }else if (page && pageComment && pageComment2) {
            return this.getBaseObject(MxBaseObjectType.POSITION_COMMENT, pageComment.sequence, page.sequence);
        }else if (page && pageComment) {
            if (pageComment.is_position_comment) {
                return this.getBaseObject(MxBaseObjectType.POSITION_COMMENT, pageComment.sequence, page.sequence);
            }else {
                return this.getBaseObject(MxBaseObjectType.PAGE, page.sequence);
            }
        }else if (page) {
            return this.getBaseObject(MxBaseObjectType.PAGE, page.sequence);
        }else if (file || folder) {
            let filePath = buildFileSPath(file, folder);
            return this.getBaseObject(MxBaseObjectType.FILE, 0, 0, filePath);
        }else if (comment) {
            if (comment.original_comment) {
                return this.getBaseObject(MxBaseObjectType.COMMENT, comment.original_comment);
            }else if (comment.original_signature) {
                return this.getBaseObject(MxBaseObjectType.SIGNATURE, comment.original_signature);
            }else if (comment.original_transaction) {
                return this.getBaseObject(MxBaseObjectType.TRANSACTION, comment.original_transaction);
            }else if (comment.original_session) {
                return this.getBaseObject(MxBaseObjectType.MEET, comment.original_session);
            }else if (comment.original_reference_link && referenceLink && referenceLink.board) {
                let refFile: BoardPageGroup = getByPath(referenceLink, 'board.page_groups.0');
                let refFolder: BoardFolder = getByPath(referenceLink, 'board.folders.0');
                let filePath = buildFileSPath(refFile, refFolder);
                return this.getBaseObject(MxBaseObjectType.FILE, 0, 0, filePath);
            }else if (comment.original_page_group) {
                let filePath = buildFileSPath(file, folder);
                return this.getBaseObject(MxBaseObjectType.FILE, 0, 0, filePath);
            }else {
                return this.getBaseObject(MxBaseObjectType.COMMENT, comment.sequence);
            }
        }

        return obj;
    }

    addFeedRelatedObject(relatedObjSeq: number, feedSeq: number): void {
        if (this._feedRelatedObjectsMap.has(relatedObjSeq) && this._feedRelatedObjectsMap.get(relatedObjSeq)) {
            let seqsSet: Set<number> = this._feedRelatedObjectsMap.get(relatedObjSeq);
            seqsSet.add(feedSeq);
        }else {
            let seqsSet: Set<number> = new Set([feedSeq]);
            this._feedRelatedObjectsMap.set(relatedObjSeq, seqsSet);
        }
    }

    getRelatedFeeds(objSeq: number): Set<number> {
        if (this._feedRelatedObjectsMap.has(objSeq)) {
            return this._feedRelatedObjectsMap.get(objSeq);
        }
        return null;
    }

    getBoardRelatedFeeds(board: Board): Set<number> {
        let boardRelatedFeeds: Set<number> = new Set();
        let seqsSet: Set<number> = new Set();
        extractObjectSequences(board, seqsSet);

        // board users change, do not need to update feed
        board.users && board.users.forEach(user => {
            seqsSet.delete(user.sequence);
        })

        // todo, page comments update, do not need to update all thread feeds
        board.todos && board.todos.forEach(todo => {
            if (!todo.local_revision && !todo.is_deleted) {
                seqsSet.delete(todo.sequence);
            }
        })

        board.pages && board.pages.forEach(page => {
            // todo: create position comment will cause page local_revision changed, as total_position_comments field changed
            if (!page.local_revision && !page.is_deleted) {
                seqsSet.delete(page.sequence);
            }
        })

        seqsSet.forEach(seq => {
            let relatedFeeds = this.getRelatedFeeds(seq);
            if (relatedFeeds) {
                relatedFeeds.forEach(feedSeq => {
                    boardRelatedFeeds.add(feedSeq);
                })
            }
        })

        if (board.feeds) {
            board.feeds.forEach(e => {
                boardRelatedFeeds.add(e.sequence);
            })
        }

        return boardRelatedFeeds;
    }

    setupFeedIndex(feed: ObjectFeed): void {
        let seqsSet: Set<number> = new Set();
        extractObjectSequences(feed.board, seqsSet);
        seqsSet.forEach(seq => {
            this.addFeedRelatedObject(seq, feed.sequence);
        })
    }

    getBoardUserBySequence(seq: number): BoardUser {
        if (this._usersMap) {
            return this._usersMap.get(seq);
        }
        return null;
    }

    isOnlyBoardUserAccessTimeUpdated(updatedBoard: Board): boolean {
        if (updatedBoard.revision 
            && this._cacheBoard.revision 
            && updatedBoard.revision - this._cacheBoard.revision === 1
            && updatedBoard.users
            && updatedBoard.users.length === 1
            && updatedBoard.users[0].revision === updatedBoard.revision) {
                let updatedBoardUser = updatedBoard.users[0];
                let bu = this.getBoardUserBySequence(updatedBoardUser.sequence);
                if (bu) {
                    if (bu.accessed_time !== updatedBoardUser.accessed_time 
                        || bu.type_indication_timestamp !== updatedBoardUser.type_indication_timestamp) {
                            return true;
                    }
                }
        }
        return false;
    }

    onObjectUpdate(updatedBoard: Board) {
        if (!this.isOnlyBoardUserAccessTimeUpdated(updatedBoard)) {
            // make cached thread out of date
            // filter board user access_time / indication_time update, as such subscription data may be too frequent
            this._threadsMap && this._threadsMap.forEach(cacheBoard => {
                this.setCacheObjectOutOfDate(cacheBoard);
            })
        }

        if (updatedBoard.page_groups || updatedBoard.folders || updatedBoard.pages || updatedBoard.resources) {
            // make cached file & folder out of date
            this._filesMap && this._filesMap.forEach(cacheBoard => {
                this.setCacheObjectOutOfDate(cacheBoard);
            })

            this._foldersMap && this._foldersMap.forEach(cacheBoard => {
                this.setCacheObjectOutOfDate(cacheBoard);
            })
        }

        if (updatedBoard.signatures) {
            // signature updated, make related signature cache out of date
            updatedBoard.signatures.forEach(signature => {
                let spath: MxSPath = `signatures[sequence=${signature.sequence}]`;
                if (this._filesMap && this._filesMap.has(spath)) {
                    this.setCacheObjectOutOfDate(this._filesMap.get(spath));
                }
            })
        }

        if (updatedBoard.revision && updatedBoard.revision > this._cacheBoard.revision) {
            mergeBoardCacheObject(this._cacheBoard, updatedBoard);
        }

        if (updatedBoard.users && this._usersMap) {
            mergeCacheObjects(updatedBoard.users, this._usersMap, 'BoardUser');
        }

        if (updatedBoard.todos && this._todosMap) {
            mergeCacheObjects(updatedBoard.todos, this._todosMap, 'BoardTodo');
        }

        if (updatedBoard.sessions && this._sessionsMap) {
            mergeCacheObjects(updatedBoard.sessions, this._sessionsMap, 'BoardSession');
        }

        if (updatedBoard.signatures && this._signaturesMap) {
            mergeCacheObjects(updatedBoard.signatures, this._signaturesMap, 'BoardSignature');
        }

        if (updatedBoard.transactions && this._transactionsMap) {
            this._hasTransactions = true;
            mergeCacheObjects(updatedBoard.transactions, this._transactionsMap, 'BoardTransaction');
        }

        if (updatedBoard.reference_links && this._referenceLinksMap) {
            mergeCacheObjects(updatedBoard.reference_links, this._referenceLinksMap, 'BoardReferenceLink');
        }

        if (updatedBoard.user_activities && this._userActivitiesMap) {
            mergeCacheObjects(updatedBoard.user_activities, this._userActivitiesMap, 'BoardUserActivity');
        }
    }

    // include users & tags
    basicInfo(): Board {
        let basicBoard: Board = {};
        const includedKeys: Set<string> = new Set<string>(['users', 'tags']);
        Object.keys(this._cacheBoard).forEach( key => {
            if (!isArray(this._cacheBoard[key]) || includedKeys.has(key)) {
                basicBoard[key] = this._cacheBoard[key];
            }
        });
    
        return basicBoard;
    }
}