import {ComponentOption} from "../index"
import {MxComponent} from "./MxComponent"
import {BankMsgDetailsView} from "../../../../views/cardsdk"
import {MxProp} from "../../../../views/cardsdk/src/MxProp"

export const BankMsgDetailsMeta = {
    id: "BankMsgDetails",
    showSendUI: MxProp.showSendUI // Boolean to indicate if open send message directly. The default is false.
}

export class BankMsgDetails extends MxComponent{

    private showSendUI: boolean;
    private baseDomain: string;

    constructor(componentOption: ComponentOption) {
        super(componentOption)
        this.componentId = BankMsgDetailsMeta.id
        this.showSendUI = componentOption.showSendUI
        this.baseDomain = componentOption.baseDomain
    }

    protected getTemplate(): string {
        let template
        if ( this.baseDomain )
            template =  `<BankMsgDetailsView v-if="!destroy" v-show="show" :isSendNewMsg=${this.showSendUI} :baseDomain="'${this.baseDomain}'" />`;
        else
            template =  `<BankMsgDetailsView v-if="!destroy" v-show="show" :isSendNewMsg=${this.showSendUI} />`;
        return template
    }

    protected getVueComponent(): {} {
        return {BankMsgDetailsView}
    }
}
