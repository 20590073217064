import { BoardReference } from './../proto/generated/BoardReference';
import { ClientParam } from './../proto/generated/ClientParam';
import { Board } from "../proto/generated/Board";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { BoardTodo } from "../proto/generated/BoardTodo";
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { User } from '../proto/generated/User';
import { Group } from '../proto/generated/Group';
import { getByPath, uuid, spathToObject } from "../util";
import { MxSPath } from './../api/defines';
import { MxErr } from "../core/error";
import { Ajax } from "../network/ajax";
import { boardRequestNode, requestNode } from "../network/requestNode";

function listTodos(boardId: string): Promise<ClientResponse> {
    let board: Board = {id: boardId};

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_LIST_TODOS, board));
  }

function createTodoBasic(boardId:string, name: string, todoId?: string): Promise<ClientResponse> {
    let board: Board = {id: boardId};
    board.todos = [ {
        name: name,
        client_uuid:todoId || uuid()

    } ]
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_TODO, board));
}

function deleteTodo(boardId:string, seq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        todos: [{
            sequence: seq
        }]
    };
    
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_TODO, board));
}


function updateTodoBasic(boardId:string, seq: number, name?: string, note?: string, isMarked?: boolean, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: seq,
            name: name,
            note: note,
            is_marked: isMarked
        }]
    };
    
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_TODO, board, params));
}


function createTodo(boardId: string, todo: BoardTodo): Promise<ClientResponse> {
    let p = createTodoBasic(boardId, todo.name, todo.client_uuid);
    p.then((response: ClientResponse) => {
        let responseTodo: BoardTodo =  getByPath(response, 'object.board.todos.0', {});
        let todoSeq: number = responseTodo.sequence;

        if (todo.note || todo.is_marked !== undefined) {
            updateTodoBasic(boardId, todoSeq, todo.name, todo.note, todo.is_marked, true);
        }

        if (todo.assignee_sequence) {
            setAssignee(boardId, todoSeq, todo.assignee_sequence, true);
        }
        
        if (todo.due_date) {
            setDueDate(boardId, todoSeq, todo.due_date, true);
        }
        
        if (todo.reminders && todo.reminders.length > 0) {
            setReminder(boardId, todoSeq, todo.reminders[0].reminder_time, true);
        }
    });

    return p;
}


function updateTodo(boardId: string, todoSeq: number, todo: BoardTodo): Promise<ClientResponse> {
    let ps: Promise<ClientResponse>[] = [];
    if (todo.name || todo.note !== undefined || todo.is_marked !== undefined) {
        let p1 = updateTodoBasic(boardId, todoSeq, todo.name, todo.note, todo.is_marked);
        ps.push(p1);
    }

    if (todo.assignee_sequence !== undefined) {
        let p2 = setAssignee(boardId, todoSeq, todo.assignee_sequence);
        ps.push(p2);
    }
    
    if (todo.due_date !== undefined) {
        let p3 = setDueDate(boardId, todoSeq, todo.due_date);
        ps.push(p3);
    }
    
    if (todo.reminders && todo.reminders.length > 0) {
        let p4 = setReminder(boardId, todoSeq, todo.reminders[0].reminder_time);
        ps.push(p4);
    }

    if (todo.is_completed !== undefined) {
        let p5 = setCompleted(boardId, todoSeq, todo.is_completed);
        ps.push(p5);
    }

    return Promise.all(ps).then((responses: ClientResponse[]) => {
        return (responses && responses.length > 0) ? responses[0] : {};
    }).catch(e => {
        return Promise.reject(MxErr.ServerError());
    });
}

function copyTodo(fromBoardId: string, todoSeq: number,toBoardId: string): Promise<ClientResponse> {
    let user: User = {
        boards: [{
            board: {
                id: fromBoardId,
                todos:[{
                    sequence:todoSeq
                }]
            }
        }]
    };
    
    let board: Board = {
        id: toBoardId
    };

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_COPY_TODOS, [], user, board));
}

function reply(boardId: string, seq: number, text: string, uid?:string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        todos: [{
            sequence: seq,
            comments: [ {
                text: text,
                client_uuid: uid
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_TODO_COMMENT, board));
}

function deleteReply(boardId: string, todoSeq: number, replySeq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            comments: [ {
                sequence: replySeq
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_TODO_COMMENT, board));
}


function updateReply(boardId: string, todoSeq: number, replySeq: number, text: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            comments: [ {
                sequence: replySeq,
                text: text
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_TODO_COMMENT, board));
}

function setAssignee(boardId: string, todoSeq:number, boardMemberSeq:number, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            assignee_sequence: boardMemberSeq
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_TODO_ASSIGNEE, board, params));
}


function setCompleted(boardId: string, todoSeq:number, isCompleted: boolean, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            is_completed: isCompleted
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_TODO_COMPLETED, board, params));
}


function setDueDate(boardId: string, todoSeq:number, dueDate: number, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            due_date: dueDate
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_TODO_DUE_DATE, board, params));
}


function setReminder(boardId: string, todoSeq:number, reminderTime: number, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            reminders: [ {
                reminder_time: reminderTime
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_TODO_REMINDER, board, params));
}

function deleteReminder(boardId: string ,todoSeq:number, reminderSeq:number, noFeed:boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = noFeed ? [{ name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED }] : [];

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            reminders: [ {
                sequence:reminderSeq,
                reminder_time: 0,
                is_deleted:true,
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_TODO_REMINDER, board, params));
}

function addAttachment(boardId:string, todoSeq:number, files: MxSPath[]): Promise<ClientResponse> {
    let references: BoardReference[] = [];
    files.forEach((file)=>{
        let board: Board = spathToObject(file, boardId) as Board;
        references.push({board: board});
    })

    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            references: references
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_TODO_ATTACHMENT, board));
}

function removeAttachment(boardId: string, todoSeq: number, referenceSeq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        todos: [{
            sequence: todoSeq,
            references: [ {
                sequence: referenceSeq,
                is_deleted: true
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_TODO_ATTACHMENT, board));
}
  
export {
    listTodos,
    createTodo,
    deleteTodo,
    updateTodo,
    copyTodo,
    reply,
    updateReply,
    deleteReply,
    setAssignee,
    setCompleted,
    setDueDate,
    setReminder,
    deleteReminder,
    addAttachment,
    removeAttachment,
} 