import {FunctionUtil, ObjectUtils, MxConsts} from "@commonUtils";
import {UploadController, UploadStatus} from "@controller/uploader/src/uploadController";

function generateUploaderFactory (commit, uid, item) {
  return UploadController.uploaderFactory(uid, item.url, item.file, (info) => {
    commit('UPDATE_UPLOAD_FILE', info);
    if (info.status === UploadStatus.UPLOADED) {
      if(item.file && item.file.binderId) {
        commit('REMOVE_FROM_UPLOAD_QUEUE', info.id)
      } else {
        setTimeout(() => {
          commit('REMOVE_FROM_UPLOAD_QUEUE', info.id)
        }, 3000)
      }
    }
  }, item.options || {});
}

function getErrorFile (maxFileSize, allowedFileTypes, uid, item) {
  if (maxFileSize) {
    let fileSize = ObjectUtils.getByPath(item, 'file.size')
    if (fileSize > maxFileSize) {
      return {
        id: uid,
        status: UploadStatus.ERROR,
        error: {
          code: MxConsts.ErrorCode.ExceedFileMaxSize
        }
      }
    }
  }
  if (allowedFileTypes) {
    let fileName = item.name || item.file.name
    let fileNameArr = fileName.split('.')
    let fileSuffix = ''
    if (fileNameArr.length > 1) {
      fileSuffix = fileNameArr.pop().toLowerCase()
    }
    if (allowedFileTypes.indexOf(fileSuffix) < 0) {
      return {
        id: uid,
        status: UploadStatus.ERROR,
        error: {
          code: MxConsts.ErrorCode.FileTypeNotSupport
        }
      }
    }
  }
}

export default {
  addFilesToUploadQueue({commit, rootGetters}, payload) {
    let files = payload;
    let maxFileSize = rootGetters['privileges/maxFileSize']
    let allowedFileTypes = rootGetters['privileges/allowedFileTypes']
    let hasWebsdkConfig = maxFileSize || allowedFileTypes
    let errorFiles = []

    if (!Array.isArray(payload)) {
      files = [payload];
    }
    files = files.map((item) => {
      let uid = FunctionUtil.uuid()
      let uploader
      let length = errorFiles.length
      if (hasWebsdkConfig) {
        let errorFile = getErrorFile(maxFileSize, allowedFileTypes, uid, item)
        if (errorFile) {
         errorFiles.push(errorFile)
        }
      }
      if (errorFiles.length === length || errorFiles.length === 0) {
        uploader = generateUploaderFactory(commit, uid, item)
        uploader.send();
      }
      return {
        binderId: item.file && item.file.binderId,
        size: item.file && item.file.size,
        uuid: uid,
        uploader: uploader,
        name: item.name || item.file.name,
        percentage: 0,
        status: UploadStatus.PENDING
      }
    });
    commit('ADD_TO_UPLOAD_QUEUE', files);
    if (errorFiles.length) {
      commit('UPDATE_UPLOAD_FILE', errorFiles)
    }
  },
  removeFileFromUploadQueue({commit, dispatch, state}, uuid) {
    commit('REMOVE_FROM_UPLOAD_QUEUE', uuid);
  },
  clearFailedFromQueue({commit}) {
    commit('REMOVE_FROM_UPLOAD_QUEUE', '');
  },
  resetStore({commit, dispatch, state}) {
    state.files = [];
  },
  mockAddFile ({commit}, file, binderId) {
    let fileObject = {
      ...file,
      binderId: binderId,
      uploader: {
        send: ()=>{}
      },
      percentage: 100,
      status: UploadStatus.INPROGRESS
    }
    commit('ADD_TO_UPLOAD_QUEUE', fileObject);
  },
  mockRemoveFile ({commit}, fileUUID) {
    commit('UPDATE_UPLOAD_FILE', [{
      id: fileUUID,
      status: UploadStatus.UPLOADED
    }])
    commit('REMOVE_FROM_UPLOAD_QUEUE', fileUUID)
  },
  mockUpdateError ({commit}, {id, detail}) {
    commit('UPDATE_UPLOAD_FILE', [{
      id,
      status: UploadStatus.ERROR,
      error: UploadController.transformServerUploadError(detail)
    }])
  },
  updateFileComment ({commit}, comment) {
    commit('updateComment', comment)
  },
  addComment ({state, commit}) {
    if (state.comment) {
      return UploadController.createComment(state.binderId, state.comment).then(()=>{
        commit('updateComment', '')
        return Promise.resolve
      })
    } else {
      return Promise.resolve()
    }
  }
}
