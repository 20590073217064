<template>
  <div
    class="open-request-item"
    :class="{'has-update': unReadFeedsCount}"
    @click="showDetailView"
    v-if="srBoard.routing_status === 'ROUTING_STATUS_OPEN' || srBoard.routing_status === 'ROUTING_STATUS_IN_PROGRESS'"
  >
    <div class="request-item-wrapper">
      <div class="name">
        <span
          class="request-item-title request-item-title-highlight"
        >{{srBoard.name}}</span>
        <template v-if="unReadFeedsCount">
          <img :src="CHAT_IMAGE" class="icon-space" />
          <span class="badge">{{unReadFeedsCount}}</span>
        </template>
      </div>
      <span class="request-item-content" style="white-space: pre-wrap; overflow-x:hidden">{{srBoard.description}}</span>
      <span class="request-item-footer">
        Last activity on {{displayTime}}
          <img v-if="hasAttachment" :src="ATTACHMENT_XS_IMAGE" alt="attachment" class="icon-space" />
      </span>
      <service-request-type :sr-board="srBoard" style="margin-top: 8px;" />
    </div>
  </div>

  <div class="complete-request-item" @click="showDetailView" v-else>
    <div class="request-item-wrapper">
      <div class="name">
        <span class="request-item-title">{{srBoard.name}}</span>
        <template v-if="unReadFeedsCount">
          <img :src="CHAT_IMAGE" class="icon-space" />
          <span class="badge">{{unReadFeedsCount}}</span>
        </template>
      </div>

      <span class="request-item-content" style="white-space: pre-wrap; overflow-x:hidden">{{srBoard.description}}</span>
      <span class="request-item-footer">
        Last activity on {{displayTime}}
          <img v-if="hasAttachment" :src="ATTACHMENT_XS_IMAGE" alt="attachment" class="icon-space" />
      </span>
    </div>
  </div>
</template>


<script>
import { MxEvent } from "./MxEvent";
import moment from "moment-timezone";
import ex_chat from "@views/theme/src/images/acd/ex_chat.png";
import ATTACHMENT_XS_IMAGE from "@views/theme/src/fonts/icons/source/websdk-attachment-xs.svg"
import CHAT_IMAGE from "@views/theme/src/images/acd/cardsdk-chat-xs-gray.svg"
import ServiceRequestType from './ServiceRequestType';

export default {
  name: "ServiceRequestItem",
  components: {ServiceRequestType},
  props: {
    srBoard: {}
  },
  data() {
    return {
      ex_chat,
      CHAT_IMAGE,
      ATTACHMENT_XS_IMAGE
    };
  },
  computed: {
    displayTime() {
      let  useTime = this.srBoard.timestamp || this.srBoard.updated_time
      const date = moment(useTime).format("MMM D, YYYY");
      const time = moment(useTime).format("h:mma");
      return `${date} at ${time}`
    },
    unReadFeedsCount() {
      return this.srBoard.feed_unread_count;
    },
    hasAttachment() {
      return this.srBoard.total_pages || this.srBoard.total_size;
    }
  },
  methods: {
    sendMsg: function() {
      // this.unReadMsgCount--
      this.$root.$emit(MxEvent.onSendMsgClick);
    },

    showMessages() {
      this.$root.$emit(MxEvent.onBadgeClick);
    },
    showDetailView() {
      this.$emit("show-detail", this.srBoard);
    }
  }
};
</script>

<style lang="scss" scoped>

.open-request-item {
  /*max-width: 762px;*/
  border: 1px solid #e3e3e3;
  border-radius: 8px;

  margin: 8px 0;
  display: flex;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;

  cursor: pointer;

  &.has-update {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.04),
      0 1px 14px 0 rgba(0, 0, 0, 0.04);
  }
}

.complete-request-item {
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  margin: 8px 0;

  display: flex;
  overflow: hidden;
}

.request-item-title {
  display: block;

  color: rgb(31, 33, 38);
  font-size: 14px;
  /*font-family: SFProText-Regular;*/
  font-weight: normal;
  line-height: 20px;
  flex:1
}

.request-item-wrapper {
  padding: 10px 0 8px 15px;
  max-width: inherit;
  flex: 1;
  max-width: 97%;

  .name {
    display: flex;

    img {
      color: #8d9199;
      padding-right: 5px;
      padding-top: 2px;
    }

    .badge {
      width: 22px;
      height: 20px;
      background: rgba(64, 173, 117, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #378f69;
      font-size: 11px;
      font-weight: bold;
    }
  }
}

.request-item-title-highlight {
  font-weight: 600;
}
.request-item-content {
  display: block;
  color: #444444;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  max-width: 85%;
  /*max-height: 40px;*/
  overflow-y: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.request-item-footer {
  color: #444444;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  display: flex;

  i {
      font-size: 16px;
  }
}

</style>
