import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const SUPPORTED_LANGS = ['en', 'es']

let messages = {}

SUPPORTED_LANGS.forEach( (lang) => {
        const resoureces = require(`./${lang}.js`)
        if(resoureces){
            messages[lang] = resoureces
        }
    }
)


const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: messages
})


export default i18n