import { UserFormatter } from './user'
import { ObjectUtils } from '../../commonUtils/object'
import { ArrayUtils } from '../../commonUtils/array'
import { UserController } from '../user/src/userController'
import { MxUser, MxISDK, Defines } from 'isdk'
import cloneDeep from 'lodash/cloneDeep'
// FixMe: Should use Defines.Board, wait for isdk to export it
import { Board } from 'isdk/src/proto/generated/Board'
import { CBoard } from '../defines/CBoard'
import { CBoardUser } from '../defines/CBoardUser'
import { BoardUser, User, SocialType, UserType, BoardAccessType, BoardUserStatus } from 'isdk/src/api/defines'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'
import _assign from 'lodash/assign'
import { CBaseUser } from '@controller/defines/CBaseUser'
import { OnlineStatus } from '@controller/defines/EOnlineStatus'
import { GroupController } from "@controller/group/src/groupController"
import {isAnonymousContext} from "@controller/utils/anon";
import {SR_TYPE_COLORS, SR_TYPE_FONT_COLORS} from "@commonUtils/consts";

function getWidgetInBoardName (boardUser: Defines.BoardUser) {
  if (boardUser.type === Defines.BoardAccessType.BOARD_OWNER) {
    return 1
  }
  const type = ObjectUtils.getByPath(boardUser, 'user.type')
  if (type && !UserFormatter.isInternalUser(type)) {
    return 2
  }
  return boardUser.sequence
}

function getDeletedUserTagValue () {
    if(isAnonymousContext())
      return false
    const groupController = GroupController.getInstance()
    const tags = groupController.tags
    return tags && tags['Show_Deleted_User_Name'] || false
}

export class BoardFormatter {
  static getLastOwnerBeforeInactive (board: Board) {
    if (!board.is_inactive) {
      return null
    }
    let inactiveTime = board.inactive_time
    let owner: any
    let users = board.users
    users.forEach((boardUser: Defines.BoardUser) => {
      if (boardUser.type === Defines.BoardAccessType.BOARD_OWNER && boardUser.created_time < inactiveTime) {
        if (!owner) {
          owner = boardUser
        } else if (owner.created_time < boardUser.created_time) {
          owner = boardUser
        }
      }
    })
    return owner
  }

  static getBoardThumbnail (board: Board) {
    if (board.thumbnail) {
      return `${MxISDK.getContextPath()}/board/${board.id}/${board.thumbnail}`
    } else {
      return ''
    }
  }

  static getBoardOwner (board: Board): BoardUser {
    let users = board.users
    let owner
    if (users) {
      let i = 0
      for (; i < users.length; i++) {
        if (users[i].type === 'BOARD_OWNER' && !users[i].is_deleted) {
          owner = users[i]
          break
        }
      }
    }
    return owner
  }

  // return a mocked CBoardUser object based on basic info provided in the 'API_Acd_User_Name' tag
  static getAnonymousAcdBoardUser (board: Board) {
    let tags = board.tags
    if(!tags) {
      //In case of global search, the board object return from server does not include tags, so get tag from user boards
      tags = ObjectUtils.getByPath(MxISDK.getCurrentUser().getUserBoardByBoardId(board.id),'board.tags')
    }
    if(tags && tags.length >0){
      let filterTag = tags.filter( tag => tag.name === 'API_Acd_User_Name')
      if(filterTag && filterTag.length >0){
        const userNameString = filterTag[0].string_value
        try {
          const userInfo = JSON.parse(userNameString)
          if(userInfo){
            userInfo.name = UserFormatter.getUserName(userInfo)
            userInfo.title = userInfo.email || userInfo.phone_number || '-'
            userInfo.displayName = userInfo.name
            userInfo.isOwner = true
            userInfo.type = BoardAccessType.BOARD_OWNER
            userInfo.avatar = UserFormatter.getUserAvatar(null, userInfo.first_name, userInfo.last_name)
            return userInfo
          }
        } catch (e) {}
      }

    }
    return 'Anonymous User'
  }

  static getBoardName (board: Board, isUserBoard = true, isAudit?: boolean) {
    if(isAnonymousContext())
      return 'Anonymous'

    const currentUser = MxISDK.getCurrentUser()
    const socialType = board.social_type
    let users = board.users || []
    const isSocial = !!board.social_type
    let isRelation = board.is_relation || board.is_bot_relation || isSocial
    let isAcd = board.is_acd
    let names: string[] = []
    let fullNames: string[] = []
    let loginUser = currentUser.user
    let loginId = loginUser.id
    const isOwner = !!users.find(binderUser => binderUser.type === Defines.BoardAccessType.BOARD_OWNER && binderUser.user.id === loginId)

    let hasValidSocialClient = false
    let lastSocialClient = null

    let validUsers = []
    let validUserIds = []
    for (let binderUser of users) {
      let userType = ObjectUtils.getByPath(binderUser, 'user.type')
      if (userType === UserType.USER_TYPE_BOT || userType === UserType.USER_TYPE_WEBAPP) {
        continue
      }
      const isUserDeleted = binderUser.is_deleted && ObjectUtils.getByPath(binderUser, 'user.disabled')
      const binderUserId = ObjectUtils.getByPath(binderUser, 'user.id')
      const isUnique = binderUserId && validUserIds.indexOf(binderUserId) === -1
      if (isUserDeleted && ((isOwner && isRelation) || board.isconversation || (isAcd && binderUser.type === Defines.BoardAccessType.BOARD_OWNER)) ) {
        if (isUnique) {
          validUsers.push(binderUser)
          validUserIds.push(binderUserId)
        }
      } else {
        const isCientUser = userType === UserType.USER_TYPE_LOCAL
        if (isSocial && isCientUser) {
          if (!binderUser.is_deleted) {
            hasValidSocialClient = true
            if (isUnique) {
              validUsers.push(binderUser)
              validUserIds.push(binderUserId)
            }
          } else {
            lastSocialClient = binderUser
          }
        } else if (!binderUser.is_deleted) {
          if (isUnique) {
            validUsers.push(binderUser)
            validUserIds.push(binderUserId)
          }
        }
      }
    }

    if (isSocial && !hasValidSocialClient && lastSocialClient) {
      validUsers.push(lastSocialClient)
    }

    validUsers = validUsers
      .sort((a: Defines.BoardUser, b: Defines.BoardUser) => {
        let aWidget = getWidgetInBoardName(a)
        let bWidget = getWidgetInBoardName(b)
        if (aWidget < bWidget) {
          return -1
        } else {
          return 1
        }
      })
    let boardName = (board.name || '').trim()
    let isConversation = board.isconversation
    let useUserFullName = false
    if (UserFormatter.isClientUser(currentUser.user.type) && board.is_inactive && board.inactive_time) {
      let owner = this.getLastOwnerBeforeInactive(board)
      if (owner && owner.user) {
        return UserFormatter.getUserName(owner.user)
      }
    }
    if (isConversation || isRelation) {
      useUserFullName = true
      if (boardName && !/((^conversation$)|(^new chat$)|(^chat$))/.test(boardName.toLowerCase())) {
        // hard coded names regExp test for different client versions
        return boardName
      }
    } else if(!isAcd) {
      if (boardName) {
        return boardName
      }
    }

    let onlyShowRelation = false
    if (isRelation && (isOwner || !UserFormatter.isInternalUser(currentUser.user.type))) {
      useUserFullName = true
      onlyShowRelation = true
    }

    if ( isAcd ){
      if(!UserFormatter.isInternalUser(currentUser.user.type) && boardName){
         return boardName
      }
      if( !board.users.find( user => user.type === BoardAccessType.BOARD_OWNER) ) {
        return BoardFormatter.getAnonymousAcdBoardUser(board).name
      }
      useUserFullName = true
      onlyShowRelation = true
    }

    if (isAudit)
      onlyShowRelation = false

    validUsers.forEach((boardUser: any) => {
      if (boardUser.group) {
        names.push(boardUser.group.name)
      } else {
        let userId = boardUser.user.id
        if (loginId !== userId || isAudit) {
          if (onlyShowRelation) {
            if (boardUser.user.type !== UserType.USER_TYPE_LOCAL && boardUser.type !== BoardAccessType.BOARD_OWNER) {
              return
            }
          }
          let relationUser
          let user = boardUser.user
          if (isUserBoard) {
            // user board should get the latest user info from relation
            relationUser = currentUser.getUserRelationByUserId(userId)
            if (relationUser && !relationUser.is_deleted) {
              user = relationUser.user
            } else {
              const contactUser = currentUser.getUserContactByUserId(userId)
              if (contactUser && !contactUser.is_deleted) {
                user = contactUser.user
              }
            }
          }

          names.push(user.first_name || user.email)
          fullNames.push(UserFormatter.getUserName(user))
        }
      }
    })

    let calculatedName

    if (useUserFullName) {
      calculatedName = fullNames.slice(0, 3).join(',') || UserFormatter.getUserName(loginUser)
    } else {
      calculatedName = names.slice(0, 3).join(', ') || UserFormatter.getUserName(loginUser)
    }

    if (socialType) {
      switch (socialType) {
        case SocialType.SOCIAL_TYPE_LINE:
          calculatedName += ' (LINE)'
          break
        case SocialType.SOCIAL_TYPE_WECHAT:
          calculatedName += ' (WeChat)'
          break
        case SocialType.SOCIAL_TYPE_WHATSAPP:
          calculatedName += ' (WhatsApp)'
          break
      }
    } else if (isRelation) {
      const socialUser = ArrayUtils.find(users, binderUser => {
        const user = binderUser.user
        if (user && !binderUser.is_deleted && user.type === UserType.USER_TYPE_LOCAL && user.hasOwnProperty('social')) {
          return true
        } else {
          return false
        }
      })

      if (socialUser) {
        calculatedName += ' (WeChat)'
      }
    }

    return calculatedName
  }

  // Verify is only me active in
  static getSpecialBinderStatus (board: Board) {
    const binderUsers = board.users
    const currentUser = MxISDK.getCurrentUser()
    const deletedBinderUser = binderUsers.find(binderUser => binderUser.is_deleted)
    const isUserDeleted = deletedBinderUser && (deletedBinderUser.is_deleted && deletedBinderUser.user && deletedBinderUser.user.disabled)

    let isOnlyMeActive = false
    let isDeactivatedBinder = board.is_inactive

    if (board.isconversation) {
      const activeBinderUsers = binderUsers.filter(binderUser => !binderUser.is_deleted)
      isOnlyMeActive = activeBinderUsers.length === 1 && isUserDeleted
      isDeactivatedBinder = isOnlyMeActive || isDeactivatedBinder
    } else if (board.is_relation || board.hasOwnProperty('social_type')) {

      let binderOwner
      let clientUser

      for (let binderUser of binderUsers) {
        if (binderUser.type === BoardAccessType.BOARD_OWNER) {
          if (!binderUser.is_deleted) {
            binderOwner = binderUser
          }
        } else if (binderUser.user && binderUser.user.type === UserType.USER_TYPE_LOCAL) {
          clientUser = binderUser
        }
      }

      const isPendingClientUser = clientUser.is_deleted || clientUser.status !== BoardUserStatus.BOARD_INVITED
      if (board.is_relation) {
        let deletedUserType = ''
        if (deletedBinderUser && deletedBinderUser.user) {
          deletedUserType = deletedBinderUser.user.type
        }
        const isRelationBinderInactive = deletedBinderUser ? (deletedUserType === UserType.USER_TYPE_LOCAL && isUserDeleted) : false
        isOnlyMeActive = isRelationBinderInactive && (currentUser.basicInfo.id === binderOwner.user.id)
        isDeactivatedBinder = isRelationBinderInactive || isDeactivatedBinder

        if (clientUser.hasOwnProperty('social') && isPendingClientUser) {
          isDeactivatedBinder = true
        }
      } else if (isPendingClientUser) {
        isDeactivatedBinder = true
      }
    } else if (board.is_acd) {
      const binderOwner = binderUsers.find(binderUser => binderUser.type === BoardAccessType.BOARD_OWNER)
      isOnlyMeActive = binderOwner && binderOwner.is_deleted
      isDeactivatedBinder = true
    } else {
      isOnlyMeActive = false
    }

    return {
      isOnlyMeActive: isOnlyMeActive,
      isDeactivatedBinder: isDeactivatedBinder
    }
  }

  static getTinyBoardUserInfo (board: Board, actor: User) {
    let binderUser: BoardUser
    let deletedBinderUser: BoardUser
    let userName
    let userAvatar
    let userId
    let userEmail
    let userPhone
    let isUserDeleted = false
    for (let user of board.users) {
      //FixMe: whether we need to handle the user deleted case ?
      if (UserFormatter.isSameUser(user.user, actor)) {
        if (user.is_deleted) {
          deletedBinderUser = user
        } else {
          binderUser = user
        }
      }
      if (binderUser) {
        break
      }
    }

    if (!binderUser && deletedBinderUser) {
      binderUser = deletedBinderUser
    }
    if (binderUser) {
      const binderBaseUser = binderUser.user || {}
      userId = binderBaseUser.id
      userEmail = binderBaseUser.email
      userPhone = binderBaseUser.phone_number
      if (binderBaseUser.picture) {
        userAvatar = `/board/${board.id}/user/${binderUser.sequence}/${binderBaseUser.picture}`
      } else {
        if (binderBaseUser.first_name) {
          userAvatar = UserFormatter.getInitialAvatar(binderBaseUser.first_name, binderBaseUser.last_name)
        } else if (binderBaseUser.name) {
          const names = binderBaseUser.name.split(' ')
          if (names.length > 1) {
            userAvatar = UserFormatter.getInitialAvatar(names[0], names[1])
          } else {
            userAvatar = UserFormatter.getInitialAvatar(names[0], '')
          }
        }
      }
      userName = UserFormatter.getUserName(binderBaseUser)
      isUserDeleted = binderUser.is_deleted && binderBaseUser.disabled
    } else {
      if (actor.type === UserType.USER_TYPE_SERVICE && actor.picture_url) {
        userAvatar = actor.picture_url
      } else {
        if (actor.first_name) {
          userAvatar = UserFormatter.getInitialAvatar(actor.first_name, actor.last_name)
        } else if (actor.name) {
          const names = actor.name.split(' ')
          if (names.length > 1) {
            userAvatar = UserFormatter.getInitialAvatar(names[0], names[1])
          } else {
            userAvatar = UserFormatter.getInitialAvatar(names[0], '')
          }
        }
      }
      userId = actor.id
      userEmail = actor.email
      userPhone = actor.phone_number
      userName = UserFormatter.getUserName(actor)
    }

    return {
      name: userName,
      avatar: userAvatar,
      isUserDeleted: isUserDeleted,
      id: userId,
      email: userEmail,
      phone_number: userPhone
    }
  }

  /**
	 *
	 * @param rawBoard - The board cache get from isdk
	 *
	 * @return Object - The transformed board data
	 */
  static transformBoardBasicInfo (rawBoard: Board, isMocked?: boolean, isAudit?:boolean, isNoAction?:boolean): CBoard {

    const clonedBoard: CBoard = _pick(rawBoard, ['client_uuid', 'is_inbox', 'is_inactive', 'routing_channel',
      'inactive_time', 'is_bot_relation', 'is_external', 'name', 'description', 'thumbnail', 'email_address',
      'routing_status', 'user_activities', 'total_pages', 'total_members', 'total_size', 'is_deleted',
      'total_hits', 'created_time', 'updated_time', 'users']) as CBoard

    clonedBoard.id = rawBoard.id

    if (rawBoard.is_file) {
      clonedBoard.is_file = true
    } else if (rawBoard.is_todo) {
      clonedBoard.is_todo = true
    } else if (rawBoard.is_signature) {
      clonedBoard.is_signature = true
    } else if (rawBoard.is_transaction) {
      clonedBoard.is_transaction = true
    }

    if (rawBoard.isconversation) {
      clonedBoard.isconversation = true
    } else if (rawBoard.is_relation) {
      clonedBoard.is_relation = true
    } else if (rawBoard.is_acd) {
      clonedBoard.is_acd = true
    } else if (rawBoard.is_channel_subscription) {
      clonedBoard.is_channel_subscription = true
    } else if (rawBoard.is_service_request) {
      clonedBoard.is_service_request = true
    } else if (rawBoard.islive) {
      clonedBoard.islive = true
    } else if (rawBoard.social_type) {
      clonedBoard.social_type = rawBoard.social_type
    }

    if (rawBoard.comments) {
      clonedBoard.comments = rawBoard.comments
    }

    if (rawBoard.pages) {
      clonedBoard.pages = rawBoard.pages
    }

    if (rawBoard.todos) {
      clonedBoard.todos = rawBoard.todos
    }

    if (rawBoard.sessions) {
      clonedBoard.sessions = rawBoard.sessions
    }

    if (rawBoard.signatures) {
      clonedBoard.signatures = rawBoard.signatures
    }

    if (rawBoard.transactions) {
      clonedBoard.transactions = rawBoard.transactions
    }

    if (rawBoard.resources) {
      clonedBoard.resources = rawBoard.resources
    }

    if (rawBoard.folders) {
      clonedBoard.folders = rawBoard.folders
    }

    if (rawBoard.user_rsvps) {
      clonedBoard.user_rsvps = rawBoard.user_rsvps
    }

    if(isAnonymousContext() && !rawBoard.users){
      return clonedBoard
    }
    const boardId = clonedBoard.id
    clonedBoard.isMocked = isMocked
    clonedBoard.isAudit = isAudit
    clonedBoard.name = this.getBoardName(rawBoard, true, isAudit)
    clonedBoard.boardThumbnail = this.getBoardThumbnail(rawBoard)
    clonedBoard.boardUsers = rawBoard.users.map(u => this.transformBoardUser(u, boardId, rawBoard.is_relation))
    if (isAudit) {
      clonedBoard.boardUsers = clonedBoard.boardUsers.filter(u=>!u.isBot)
    }
    clonedBoard.isNoAction = isNoAction || clonedBoard.is_inactive
    if(isAnonymousContext())
      clonedBoard.isNoAction = false

    const currentUser = MxISDK.getCurrentUser()
    const binderUsers = clonedBoard.boardUsers

    const activeBinderUsers = []
    let binderOwner
    let clientUser
    let clientUserIndex
    let deletedBinderUser
    let disabedBinderUser

    for (let i = 0; i < binderUsers.length; i++) {
      const binderUser = binderUsers[i]
      if (binderUser.isOwner) {
        binderOwner = binderUser
      }
      if (binderUser.isClient || binderUser.isDeletedClient) {
        clientUser = binderUser
        clientUserIndex = i
      }
      if (binderUser.is_deleted) {
        deletedBinderUser = binderUser
      }
      if (binderUser.disabled) {
        disabedBinderUser = binderUser
      }
      if (!binderUser.is_deleted && !binderUser.disabled) {
        activeBinderUsers.push(binderUser)
      }
    }
    if (clonedBoard.isconversation) {
      const isUserDisabled = activeBinderUsers.length === 1 && disabedBinderUser
      clonedBoard.isOnlyMeActive = activeBinderUsers.length === 1 && deletedBinderUser && deletedBinderUser.isUserDeleted
      clonedBoard.isNoAction = clonedBoard.isOnlyMeActive || clonedBoard.isNoAction || isUserDisabled
    } else if (clonedBoard.is_relation) {
      const isRelationBinderInactive = deletedBinderUser ? (deletedBinderUser.type === Defines.UserType.USER_TYPE_LOCAL && deletedBinderUser.isUserDeleted) : false
      clonedBoard.isNoAction = isRelationBinderInactive || clonedBoard.isNoAction
      clonedBoard.isOnlyMeActive = isRelationBinderInactive && (binderOwner.id === currentUser.basicInfo.id)
      let owner
      if (clonedBoard.is_inactive) {
        let owners = binderUsers.filter((item) => {
          if (clonedBoard.inactive_time) {
            return item.isOwner && item.created_time < clonedBoard.inactive_time
          } else {
            return item.isOwner
          }
        })
        owner = owners[owners.length - 1]
      } else {
        owner = binderUsers.find(binderUser => binderUser.isOwner && !binderUser.is_deleted)
      }
      clonedBoard.ownerInfo = owner
    } else if (clonedBoard.is_acd) {
       if (binderOwner && binderOwner.is_deleted) {
         clonedBoard.isNoAction = true
         clonedBoard.isOnlyMeActive = true
       }
    }

    if(clonedBoard.is_service_request){
      const routing_channel = clonedBoard.routing_channel
      const allChannels = MxISDK.getCurrentOrg().group.routing_config.sr_channels.sort( (c1,c2) => c1.sequence - c2.sequence )

      const channelIndex = allChannels.findIndex( ch => ch.sequence === routing_channel)
      if(channelIndex >= 0) {
        clonedBoard.srChannelName = allChannels[channelIndex].name
        const remainder = channelIndex % (SR_TYPE_COLORS.length)
        clonedBoard.srChannelColor = SR_TYPE_COLORS[remainder]
        clonedBoard.srChannelFontColor = SR_TYPE_FONT_COLORS[remainder]
      }
      else {
        clonedBoard.srChannelName = allChannels[0].name || "General"
        clonedBoard.srChannelColor = SR_TYPE_COLORS[0]
        clonedBoard.srChannelFontColor = SR_TYPE_FONT_COLORS[0]
      }
    }

    if (!isAnonymousContext()) {
      clonedBoard.displayDeletedUser = getDeletedUserTagValue() === false && clonedBoard.isOnlyMeActive
    }

    if (clonedBoard.is_relation || rawBoard.hasOwnProperty('social_type')) {
      if (clientUser) {
        if (clientUser.hasOwnProperty('social') || rawBoard.hasOwnProperty('social_type')) {
          clonedBoard.isSocial = true
        }
        if (clonedBoard.isSocial) {
          const socialType = rawBoard.social_type
          switch (socialType) {
            case SocialType.SOCIAL_TYPE_LINE:
              clonedBoard.isLineChannel = true
              break
            case SocialType.SOCIAL_TYPE_WECHAT:
              clonedBoard.isWechatChannel = true
              break
            case SocialType.SOCIAL_TYPE_WHATSAPP:
              clonedBoard.isWhatsappChannel = true
              break
            default:
              // For old data, we only supports WeChat
              if (clientUser.hasOwnProperty('social')) {
                clonedBoard.isWechatChannel = true
              }
              break
          }

          // below is to verify whether a social binder is in suspended status
          // Detail info: https://jira.grouphour.com/browse/MV-5800
          if (rawBoard.tags) {
            for (let tag of rawBoard.tags) {
              if (!tag.is_deleted && tag.name === 'API_MOXTRA_SOCIAL_SUSPENDED') {
                clonedBoard.isSocialSuspended = tag.string_value === '1' && !clonedBoard.is_inactive
                clonedBoard.boardUsers[clientUserIndex].isSocialSuspended = clonedBoard.isSocialSuspended
                break
              }
            }
          }
        }
        if (clonedBoard.is_relation) {
          clonedBoard.isNoAction = clonedBoard.isNoAction || binderOwner.disabled || clientUser.disabled
          clonedBoard.isPendingRelation = !clonedBoard.is_inactive && clientUser.isPendingRelation
        } else if (clonedBoard.isSocial) {
          // For social binder, if client user not joined, should treat it as a deactive binder
          const isSocialTypePending = !clientUser.isJoined || clientUser.is_deleted
          clonedBoard.isNoAction = clonedBoard.isNoAction || binderOwner.disabled || clientUser.disabled || isSocialTypePending || clonedBoard.isSocialSuspended
          clonedBoard.isPendingRelation = !clonedBoard.is_inactive && !clientUser.disabled && (isSocialTypePending || clonedBoard.isSocialSuspended)
          clonedBoard.is_inactive = clonedBoard.is_inactive || clientUser.disabled
        }
      }
    }

    if(clonedBoard.is_acd){
      let users = clonedBoard.boardUsers.filter( u => !u.isOwner && !u.is_deleted)
          .sort( (u1,u2)=>{ return (u1.created_time - u2.created_time) })
      if (users && users.length > 0){
        users[0].isPrimaryAgent = true
      }

      if( !clonedBoard.boardUsers.find( user => ( user.isOwner || user.type === BoardAccessType.BOARD_OWNER) ) ) {
        clonedBoard.boardUsers.push(BoardFormatter.getAnonymousAcdBoardUser(rawBoard))
      }
    }

    if(clonedBoard.is_channel_subscription){
      const tags = rawBoard.tags
      clonedBoard.isQuickLinkChannel = true
      if(tags){
        const qcTag = tags.find( tag => tag.name === 'API_Is_Generic_Channel')
        if(qcTag && qcTag.string_value === "1") {
          clonedBoard.isGenericChannel = true
          clonedBoard.isQuickLinkChannel = false
        }
      }
    }
    // FixMe: there are still some places use users to judge, need to modify these places.
    // delete clonedBoard['users']
    return clonedBoard
  }

  /**
	 *
	 * @param boardUser - The board user cache get from isdk
	 * @param boardId - specific board id
	 * @param relationPriority - if set to true, when get board member info from current user's relation members, if false, will directly use board user info
	 *
	 * @return Object - The transformed board user info
	 */
  static transformBoardUser (boardUser: Defines.BoardUser, boardId?: string, isRelationBinder?: boolean): CBoardUser {
    let cBoardUser: CBoardUser = _pick(boardUser, ['accessed_time', 'updated_time', 'created_time', 'sequence', 'is_deleted', 'type_indication_timestamp', 'type', 'status', 'revision']) as CBoardUser
    const baseUser = boardUser.user || {}
    cBoardUser.isOwner = cBoardUser.type === Defines.BoardAccessType.BOARD_OWNER
    cBoardUser.isMember = cBoardUser.type === Defines.BoardAccessType.BOARD_READ_WRITE
    cBoardUser.isJoined = cBoardUser.status === Defines.BoardUserStatus.BOARD_MEMBER
    cBoardUser.isUserDeleted = cBoardUser.is_deleted && ObjectUtils.getByPath(boardUser, 'user.disabled')
    cBoardUser.invited_time = boardUser.invited_time || boardUser.created_time
    cBoardUser.emailVerified = baseUser.email_verified
    const currentUser: MxUser = MxISDK.getCurrentUser()
    if (currentUser) {
      cBoardUser.displayDeletedUser = getDeletedUserTagValue() === false && cBoardUser.isUserDeleted
    }
    if (boardUser.user) {
      const _user: Defines.User & { avatar?: string } = boardUser.user
      let avatar: string = ''
      let firstName: string = ''
      let lastName: string = ''

      const groupStatus = ObjectUtils.getByPath(_user,'groups.0.status')
      cBoardUser.isPendingUser = groupStatus !== Defines.GroupUserStatus.GROUP_MEMBER
      Object.assign(cBoardUser, _user)
      if (_user.hasOwnProperty('social')) {
        cBoardUser.social = _user.social
        cBoardUser.isSocialWechat = true
        cBoardUser.socialType = SocialType.SOCIAL_TYPE_WECHAT
      }
      cBoardUser.originalTitle = baseUser.title
      cBoardUser.title = UserFormatter.getUserSubTitle(baseUser)
      if (currentUser && currentUser.id === cBoardUser.id) {
        cBoardUser.isMySelf = true
      }
      cBoardUser.disabled = _user.disabled
      cBoardUser.isBot = baseUser.type === Defines.UserType.USER_TYPE_BOT || baseUser.type === Defines.UserType.USER_TYPE_WEBAPP
      if (cBoardUser.isBot) {
        //mock user initial avatar
        _user.first_name = _user.first_name || _user.name
      }
      if (cBoardUser.isMySelf) {
        const userBasicInfo: Defines.User = currentUser.basicInfo
        const userPicture: number = userBasicInfo.picture
        if (userPicture && userPicture !== 0) {
          avatar = `${MxISDK.getContextPath()}/user/${userPicture}`
        }
        firstName = userBasicInfo.first_name
        lastName = userBasicInfo.last_name
      } else {
        const userId: string = cBoardUser.id
        const userRelation: Defines.UserRelation = currentUser && currentUser.getUserRelationByUserId(userId)
        if (userRelation && !userRelation.is_deleted) {
          avatar = UserFormatter.getUserRelationAvatar(userRelation)
          cBoardUser.relationSequence = userRelation.sequence
          cBoardUser.orderNumber = userRelation.order_number
          if (userRelation.user) {
            const relationUser: Defines.User = userRelation.user
            if (cBoardUser.disabled !== relationUser.disabled) {
              cBoardUser.disabled = relationUser.disabled
              if (!cBoardUser.disabled && cBoardUser.isUserDeleted) {
                cBoardUser.isUserDeleted = false
              }
            }
            firstName = relationUser.first_name
            lastName = relationUser.last_name
            if (relationUser.type) {
              cBoardUser.userType = relationUser.type
            }
            if ((!cBoardUser.title || (cBoardUser.title === '-' && !cBoardUser.isUserDeleted)) && relationUser.email) {
              cBoardUser.title = relationUser.email
            }
            if (relationUser.title) {
              cBoardUser.title = relationUser.title
            }
            if (relationUser.phone_number) {
              cBoardUser.phone_number = relationUser.phone_number
            }
            cBoardUser.isPendingRelation = userRelation.status === Defines.UserRelationStatus.RELATION_PENDING
          }
        } else if (!cBoardUser.isUserDeleted) {
          const userContact: Defines.UserContact = currentUser && currentUser.getUserContactByUserId(userId)
          if (userContact) {
            avatar = UserFormatter.getUserContactAvatar(userContact)
            if (userContact.user) {
              const contactUser: Defines.User = userContact.user
              if (!cBoardUser.disabled) {
                cBoardUser.disabled = contactUser.disabled
              }
              firstName = contactUser.first_name
              lastName = contactUser.last_name
              if (contactUser.type) {
                cBoardUser.userType = contactUser.type
              }
            }
          } else if (_user.avatar) {
            avatar = _user.avatar
          } else if (_user.picture) {
            let path = "board"
            if(window['useTrailingSlash']){
               path = "board_pic"
            }
            avatar = `${MxISDK.getContextPath()}/${path}/${boardId}/user/${cBoardUser.sequence}/${_user.picture}/`
          } else {
            avatar = UserFormatter.getUserAvatar(_user.picture, _user.first_name, _user.last_name)
          }
        }
      }
      cBoardUser.avatar = avatar || UserFormatter.getUserAvatar(baseUser.picture, baseUser.first_name, baseUser.last_name)
      cBoardUser.primaryKey = _user.id || _user.email || _user.unique_id
      const userType = cBoardUser.userType ? cBoardUser.userType : cBoardUser.type
      if (userType === UserType.USER_TYPE_LOCAL) {
        cBoardUser.isClient = !cBoardUser.isUserDeleted
        cBoardUser.isDeletedClient = cBoardUser.isUserDeleted
      } else {
        cBoardUser.isInternalUser = !cBoardUser.isUserDeleted
      }
      if (firstName) {
        cBoardUser.first_name = firstName
      }
      if (lastName) {
        cBoardUser.last_name = lastName
      }
      cBoardUser.name = UserFormatter.getUserName(cBoardUser)
      cBoardUser.displayName = cBoardUser.name
    }

    let cachedUserBoard = null
    if (currentUser) {
      cachedUserBoard = currentUser.getUserBoardByBoardId(boardId)
    }
    if (currentUser && (cBoardUser.isClient || cBoardUser.isDeletedClient)) {
      if (cachedUserBoard && !cachedUserBoard.is_deleted) {
        const binderSocialType = ObjectUtils.getByPath(cachedUserBoard, 'board.social_type')
        switch (binderSocialType) {
          case SocialType.SOCIAL_TYPE_WECHAT:
            cBoardUser.isSocialWechat = true
            cBoardUser.socialType = SocialType.SOCIAL_TYPE_WECHAT
            break
          case SocialType.SOCIAL_TYPE_LINE:
            cBoardUser.isSocialLine = true
            cBoardUser.socialType = SocialType.SOCIAL_TYPE_LINE
            break
          case SocialType.SOCIAL_TYPE_WHATSAPP:
            cBoardUser.isSocialWhatsapp = true
            cBoardUser.socialType = SocialType.SOCIAL_TYPE_WHATSAPP
            break
        }
      }
    }

    if (cachedUserBoard) {
      if (isRelationBinder || ObjectUtils.getByPath(cachedUserBoard, 'board.social_type')) {
        cBoardUser.isRelationGuest = cBoardUser.isInternalUser && !cBoardUser.isOwner
      }
    }

    cBoardUser.userType = ObjectUtils.getByPath(boardUser, 'user.type')
    // FixMe: get correct online status
    cBoardUser.onlineStatus = OnlineStatus.Offline
    return cBoardUser
  }

  static transformBoardActor (actor: User, binderObj: Board, isFromMeet?: boolean) {
    if (!actor) {
      return null
    }

    let result: any = {}
    let targetUser: BoardUser = {}
    let binderUsers: BoardUser[] = binderObj.users
    let isUserDeleted: boolean = false
    let isInactiveUser: boolean = false
    if (binderUsers) {
      for (let i = 0; i < binderUsers.length; i++) {
        const binderUser = binderUsers[i]
        const binderBaseUser = binderUser.user || {}
        if (UserFormatter.isSameUser(binderBaseUser, actor)) {
          if (!binderUser.is_deleted) {
            targetUser = binderUser
            break
          } else if (binderUser.is_deleted || binderBaseUser.disabled) {
            isInactiveUser = true
            if (binderUser.is_deleted && binderBaseUser.disabled) {
              isUserDeleted = true
            }
          }
        }
      }
    }

    if (!_isEmpty(targetUser)) {
      const transformedUser = BoardFormatter.transformBoardUser(targetUser, binderObj.id)
      result = _pick(transformedUser, ['id', 'first_name', 'last_name', 'avatar', 'isMySelf', 'name', 'email', 'isUserDeleted', 'disabled', 'userType', 'isBot'])
      result.isInactiveUser = result.disabled
    } else {
      let firstName = actor.first_name || ''
      let lastName = actor.last_name || ''
      if (!firstName && !lastName && actor.name) {
        let names = actor.name.split(' ')
        firstName = names[0]
        if (names.length > 1) {
          lastName = names[1]
        }
      }
      result = actor
      if (!actor.picture_url) {
        result.avatar = UserFormatter.getInitialAvatar(firstName, lastName)
      } else {
        result.avatar = actor.picture_url
      }
      if (isUserDeleted) {
        result.isUserDeleted = true
      }
      if(binderObj.is_acd && !actor.id){ // the respective feed is created by anonymous user
        result.userType = 'USER_TYPE_SERVICE'
      }
      result.isInactiveUser = isInactiveUser
    }
    result.name = UserFormatter.getUserName(result)
    if (!isFromMeet) {
      result.displayDeletedUser = result.isUserDeleted && getDeletedUserTagValue() === false
    }

    return result
  }
}
