import { ObjectFeed } from 'isdk/src/proto/generated/ObjectFeed'
import { BoardPage, BoardSignature, BoardTodo, BoardPageGroup, BoardComment } from 'isdk/src/api/defines'
import { ActionObject } from 'isdk/src/proto/generated/ActionObject';
import { BoardTransaction } from 'isdk/src/proto/generated/BoardTransaction';
import { BoardResource } from 'isdk/src/proto/generated/BoardResource';
import { BoardSession } from 'isdk/src/proto/generated/BoardSession';
import { CBoardUser } from './CBoardUser';

export enum FeedAction {
  CREATE = 'CREATE',
  NAME_CHANGE = 'NAME_CHANGE',
  COMMENT = 'COMMENT',
  VOICE_COMMENT = 'VOICE_COMMENT',
  COMMENT_DELETE = 'COMMENT_DELETE',
  CREATE_WITH_ANNOTATION = 'CREATE_WITH_ANNOTATION',
  ANNOTATION = 'ANNOTATION',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  POSITION_COMMENT = 'POSITION_COMMENT',
  RENAME = 'RENAME',
  RECYCLE = 'RECYCLE',
  MOVE = 'MOVE',
  RECEIVE = 'RECEIVE',
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
  INVITE = 'INVITE',
  DECLINE = 'DECLINE',
  CANCEL = 'CANCEL',
  REMOVE = 'REMOVE',
  CHANGE_ROLE = 'CHANGE_ROLE',
  CREATE_WITH_RESOURCE = 'CREATE_WITH_RESOURCE',
  ASSIGN = 'ASSIGN',
  ATTACHMENT = 'ATTACHMENT',
  DUE_DATE = 'DUE_DATE',
  COMPLETE = 'COMPLETE',
  REOPEN = 'REOPEN',
  DUE_DATE_ARRIVE = 'DUE_DATE_ARRIVE',
  SCHEDULE = 'SCHEDULE',
  RESCHEDULE = 'RESCHEDULE',
  START = 'START',
  END = 'END',
  RECORDING_READY = 'RECORDING_READY',
  LOG = 'LOG',
  INVITE_PENDING = 'INVITE_PENDING',
  REMOVE_PENDING = 'REMOVE_PENDING',
  STATUS_UPDATE = 'STATUS_UPDATE',
  STEP_SUBMIT = 'STEP_SUBMIT',
  EXPIRATION_DATE_ARRIVE = 'EXPIRATION_DATE_ARRIVE',
  STEP_SUBMIT_BATCH = 'STEP_SUBMIT_BATCH'
}

export enum FeedBaseType {
  BOARD = 'BOARD',
  PAGES = 'PAGES',
  FILE = 'FILE',
  EMAIL = 'EMAIL',
  RELATIONSHIP = 'RELATIONSHIP',
  TODO = 'TOTO',
  FOLDER = 'FOLDER',
  NOTE = 'NOTE',
  MEET = 'MEET',
  CALL = 'CALL',
  SIGNATURE = 'SIGNATURE',
  TRANSACTION = 'TRANSACTION',
  POSITION_COMMENT = 'POSITION_COMMENT'
}

export interface MeetBaseObject extends ActionObject, BoardSession {
  SPath: string,
  isScheduled?: boolean,
  isStarted?: boolean,
  isCanceled?: boolean,
  isRenamed?: boolean,
  isActive?: boolean,
  isEnded?: boolean
}

export interface PageBooleanType {
  isWhiteBoard?: boolean,
  isNotSupported?: boolean,
  isImage?: boolean,
  isWeb?: boolean,
  isVideo?: boolean,
  isAudio?: boolean,
  isPdf?: boolean,
  isUrl?: boolean,
  isNote?: boolean,
  isDesktopshare?: boolean,
  isGeo?: boolean,
  isAny?: boolean
}

export interface PageBaseObject extends BoardPage, PageBooleanType {
  SPath: string,
  poster?: string,
  fileType?: string
}

export interface FileBaseObject extends BoardPageGroup {
  SPath: string,
  folderSpath?: string,
  folderSequence?: number,
  isMoved?: boolean,
  fileLength?: number,
  fileType?: string,
  isPasswordProtected?: boolean,
  creator?: object,
  totalPages?: number,
  convertedPages?: number,
  isConvertable?: boolean,
  isConverted?: boolean,
  isConverting?: boolean,
  status?: string,
  poster?: string,
  geoLocation?: any,
  geoAddress?: string
}

export interface ResourceBaseObject extends BoardResource {
  SPath: string
}

export interface SignatureBaseObject extends BoardSignature {
  SPath: string
}

export interface TodoBaseObject extends BoardTodo {
  SPath: string
}

export interface RelationshipBaseObject {
  users: CBoardUser[],
  invitees?: string,
  binderName?: string,
  hasBotUser?: boolean,
  hasNonActorUser?: boolean
}

export interface TransactionBaseObject extends BoardTransaction {
  SPath: string
}

export interface CommentBaseObject extends BoardComment {
  SPath?: string
}

export interface CBoardFeed<T> extends ObjectFeed {
  Action: FeedAction,
  BaseType: FeedBaseType,
  baseObject: T,
  RelatedType?: string,
  relatedObject?: any,
  relatedPage?: Object

  isInvalid?: boolean,
  isBoardCreate?: boolean,
  isBoardNameChange?: boolean,
  isBoardComment?: boolean,
  isBoardVoiceComment?: boolean,
  isBoardCommentDelete?: boolean,
  isPagesCreate?: boolean,
  isPagesCreateWithAnnotation?: boolean,
  isPagesAnnotation?: boolean,
  isPagesUpdate?: boolean,
  isPagesDelete?: boolean,
  isPagesComment?: boolean,
  isPagesPositionComment?: boolean,
  isPagesCommentDelete?: boolean,
  isPagesRename?: boolean,
  isPagesRecycle?: boolean,
  isPagesMove?: boolean,
  isEmailReceive?: boolean,
  isRelationshipJoin?: boolean,
  isRelationshipLeave?: boolean,
  isRelationshipInvite?: boolean,
  isRelationshipDecline?: boolean,
  isRelationshipCancel?: boolean,
  isRelationshipRemove?: boolean,
  isRelationshipChangeRole?: boolean,
  isTodoCreate?: boolean,
  isTodoCreateWithResource?: boolean,
  isTodoUpdate?: boolean,
  isTodoDelete?: boolean,
  isTodoAssign?: boolean,
  isTodoComment?: boolean,
  isTodoAttachment?: boolean,
  isTodoDueDate?: boolean,
  isTodoComplete?: boolean,
  isTodoReopen?: boolean,
  isTodoDueDateArrive?: boolean,
  isFolderCreate?: boolean,
  isFolderRename?: boolean,
  isFolderRecycle?: boolean,
  isFolderDelete?: boolean,
  isSessionSchedule?: boolean,
  isSessionReschedule?: boolean,
  isSessionStart?: boolean,
  isSessionEnd?: boolean,
  isSessionRecordingReady?: boolean,
  isSessionCancel?: boolean,
  isSessionRename?: boolean,
  isNoteCreate?: boolean,
  isPin?: boolean,
  isCallLog?: boolean,
  isAudioCallLog?: boolean,
  isRelationshipInvitePending?: boolean,
  isRelationshipRemovePending?: boolean,
  isSignatureStatusUpdate?: boolean,
  isSignatureDelete?: boolean,
  isSignatureRename?: boolean,
  isViewtokenCreate?: boolean,
  isViewtokenDelete?: boolean,
  isTransactionCreate?: boolean,
  isTransactionDelete?: boolean,
  isTransactionUpdate?: boolean,
  isTransactionStepSubmit?: boolean,
  isTransactionAttachment?: boolean,
  isTransactionExpirationDateArrive?: boolean,
  isTransactionStepSubmitBatch?: boolean,
  isDescription?: boolean,
}