import { BoardTransaction } from "../proto/generated/BoardTransaction";
import { Board } from "../proto/generated/Board";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientRequestParameter } from "../proto/generated/ClientRequestParameter";
import { Ajax } from "../network/ajax";
import { boardRequestNode } from "../network/requestNode";


function readOnGoingTransactions(boardId: string): Promise<ClientResponse> {
    let board: Board = { id: boardId };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_ONGOING_SIGNATURES, board));
}

function listTransactions(boardId: string): Promise<ClientResponse> {
    let board: Board = { id: boardId, revision: 0 };
    let params = [{
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/board/transactions"
    }];
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board, params));
}

function readTransaction(boardId: string, transactionSeq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        transactions: [{
            sequence: transactionSeq,
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_READ, board));
}

function submitTransactionStep(boardId: string, transactionSequence: number, stepSequence: number, clickButtonId: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        transactions: [{
            sequence: transactionSequence,
            steps: [{
                sequence: stepSequence,
                action_logs: [{
                    click_btn_id: clickButtonId
                }]
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_STEP_SUBMIT, board));
}

function createTransaction(boardId: string, transaction: BoardTransaction, suppressFeed?: boolean): Promise<ClientResponse> {
    let params = [];
    if (suppressFeed) {
        params.push({
            name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
        })
    }

    let board: Board = {
        id: boardId,
        transactions: [{
            ...transaction
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_CREATE, board, params));
}

function updateTransaction(boardId: string, transactionSeq: number, transaction: BoardTransaction, suppressFeed?: boolean): Promise<ClientResponse> {
    let params = [];
    if (suppressFeed) {
        params.push({
            name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
        })
    }

    let board: Board = {
        id: boardId,
        transactions: [{
            sequence: transactionSeq,
            ...transaction
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_UPDATE, board, params));
}

function deleteTransaction(boardId: string, transactionSeq: number, suppressFeed?: boolean): Promise<ClientResponse> {
    let params = [];
    if (suppressFeed) {
        params.push({
            name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
        })
    }

    let board: Board = {
        id: boardId,
        transactions: [{
            sequence: transactionSeq,
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_DELETE, board, params));
}

function removeAttachment(boardId: string, transactionSeq: number, referenceSeq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        transactions: [{
            sequence: transactionSeq,
            references: [{
                sequence: referenceSeq,
                is_deleted: true
            }]
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TRANSACTION_DELETE_ATTACHMENT, board));
}

export {
    readOnGoingTransactions,
    listTransactions,
    readTransaction,
    submitTransactionStep,
    createTransaction,
    updateTransaction,
    deleteTransaction,
    removeAttachment
} 