import * as protobuf from 'protobufjs'
import {ProtoBufDefineJSON} from './protobuf-json'
import { ClientRequest } from './generated/ClientRequest';
import {ClientResponse} from "./generated/ClientResponse";
import { cloneObject } from '../util';

const root = protobuf.Root.fromJSON(ProtoBufDefineJSON);
const requestParser: any = root.lookup('ClientRequest');
const responseParser: any = root.lookup('ClientResponse');

export function encodeProtobuf(json: ClientRequest) {
  let message = requestParser.fromObject(json)
  return requestParser.encode(message).finish();
}
export function decodeProtobuf(buffer: any): ClientResponse {
  let arrayBuf = new Uint8Array(buffer)
  return responseParser.toObject(responseParser.decode(arrayBuf),{
    enums:String,
    bytes:String,
    json:true
  }) as ClientResponse;
  // return responseParser.decode(arrayBuf).toJSON();
}


const allMessages: Object = ProtoBufDefineJSON.nested;
const controlFields = new Set(['id', 'session_key', 'sequence', 'client_uuid', 'created_time', 'updated_time', 'timestamp', 'revision', 'local_revision', 'previous_revision', 'is_deleted']);
const internalTypes = new Set(['string', 'bool', 'int32', 'uint32', 'int64', 'uint64', 'float', 'double', 'bytes']);

export function mergeCacheObject(to: Object, from: Object, objType: string='CacheObject') {
  let msg: Object = allMessages[objType];
  if (!msg) {
    return;
  }
  let fields: Object = msg['fields'];
  if (!fields) {
    return;
  }

  if (!from['revision'] || from['revision'] <= to['revision']) {
    return;
  }

  if (from['is_deleted'] && fields.hasOwnProperty('is_deleted')) {
    // delete old items
    for (let p in to) {
      if (!controlFields.has(p)) {
        delete to[p];
      }
    }
    Object.assign(to, cloneObject(from));
    return;
  }

  let shouldMergeLocalField = false;
  if (from['local_revision'] && fields.hasOwnProperty('local_revision') && 
      (!to['local_revision'] || from['local_revision'] > to['local_revision'])) {
    shouldMergeLocalField = true;
  }

  Object.keys(fields).forEach(key => {
    let isControlField = controlFields.has(key);
    if (isControlField) {
      if (from.hasOwnProperty(key)) {
        to[key] = from[key];
      }
      return;
    }

    let field = fields[key];
    let isRepeatedField = (field['rule'] === 'repeated') ? true : false;
    let isInternalType = internalTypes.has(field['type']);

    if (!isRepeatedField && !from.hasOwnProperty(key)) {
      return;
    }

    if (isInternalType) {
      if (shouldMergeLocalField) {
        if (isRepeatedField) {
          to[key] = cloneObject(from[key]);
        } else {
          to[key] = from[key];
        }
      }
    } else {
      let subMsg: Object = allMessages[field['type']];
      if (!subMsg) return;

      let isSubContainer = subMsg['fields'] && subMsg['fields'].hasOwnProperty('local_revision'); 
      let isRevisionData = subMsg['fields'] && subMsg['fields'].hasOwnProperty('revision'); 
      let isBundleData = !isSubContainer && !isRevisionData;

      if (isBundleData) {
        if (shouldMergeLocalField) {
          to[key] = cloneObject(from[key]);
        }
        return;
      }

      if (!isRepeatedField) {
        if (isSubContainer && to[key]) {
          mergeCacheObject(to[key], from[key], field['type']);
        } else if (isRevisionData) {
          to[key] = cloneObject(from[key]);
        }
      } else {
        let fromArray = from[key];
        // let toArray = to[key];
        if (!Array.isArray(fromArray)) return;
        if (!Array.isArray(to[key])) to[key] = [];

        let seqMap = new Map(); // key: seq, val: index
        for (let i = 0; i < to[key].length; i++ ) {
          let seq = to[key][i]['sequence'];
          if (seq) {
            seqMap.set(seq, i);
          }
        }

        let matchedItems = [];
        for (let i = 0; i < fromArray.length; i++ ) {
          let seq = fromArray[i]['sequence'];
          if (!seq) continue;

          if (seqMap.has(seq)) {
            matchedItems.push(seqMap.get(seq));
          }else {
            matchedItems.push(-1);
          }
        }

        for (let i = 0; i < matchedItems.length; i++ ) {
          if (matchedItems[i] === -1) {
            // new item
            to[key].push(cloneObject(fromArray[i]));
          }else {
            // update item
            if (isSubContainer) {
              mergeCacheObject(to[key][matchedItems[i]], fromArray[i], field['type']);
            }else if (isRevisionData) {
              to[key][matchedItems[i]] = cloneObject(fromArray[i]);
            }
          }
        }
      }
    }
  });
}

export function mergeUserCacheObject(to: Object, from: Object) {
  mergeCacheObject(to, from, 'User');
}

export function mergeBoardCacheObject(to: Object, from: Object) {
  mergeCacheObject(to, from, 'Board');
}

export function mergeGroupCacheObject(to: Object, from: Object) {
  mergeCacheObject(to, from, 'Group');
}

export function mergeSessionCacheObject(to: Object, from: Object) {
  mergeCacheObject(to, from, 'ActionObject');
}