import { BoardPageType } from './../proto/generated/BoardPageType';
import { BoardPageGroup } from './../proto/generated/BoardPageGroup';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { BoardFolder } from './../proto/generated/BoardFolder';
import { Board } from '../proto/generated/Board';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientParam } from '../proto/generated/ClientParam';
import { BoardPage } from '../proto/generated/BoardPage';
import { BoardTag } from '../proto/generated/BoardTag';
import { User } from '../proto/generated/User';
import { BoardEditorType } from '../proto/generated/BoardEditorType';
import { spathToObject, getBySPath, mergeObject, uuid, parseFileSequenceFromSPath } from '../util';
import { MxSPath, MxPageElementType } from './../api/defines';
import { Ajax } from '../network/ajax';
import { boardRequestNode, requestNode } from '../network/requestNode';

function listFolder(boardId: string, parentFolder?: MxSPath): Promise<ClientResponse> {
    let board: Board = spathToObject(parentFolder, boardId) as Board;
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_LIST_FOLDER, board));
}

function listAllFolders(boardId: string, parentFolder?: MxSPath): Promise<ClientResponse> {
    let board: Board = spathToObject(parentFolder, boardId) as Board;
    let params: ClientParam[] = [{
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/board/folders"
    }];
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board, params));
  }

function createFolder(boardId:string, name:string, parentFolder?: MxSPath): Promise<ClientResponse> {
    let board: Board = {id: boardId};
    if (parentFolder && parentFolder.length > 0) {
        board = spathToObject(parentFolder, boardId) as Board;
        let folder: BoardFolder = getBySPath(board, parentFolder) as BoardFolder;
        if (folder) {
            folder.folders = [{name: name}];
        }
    }else {
        board.folders = [{name: name}];
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_FOLDER, board));
}

function deleteFolder(boardId:string, folderPath: MxSPath): Promise<ClientResponse> {
    let board: Board = spathToObject(folderPath, boardId);
    let params: ClientParam[] = [{ name: ClientRequestParameter.BOARD_REQUEST_DELETE_FOLDER_RECURSIVELY }];
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_FOLDER, board, params));
}

function renameFolder(boardId:string, folderPath: MxSPath, name:string): Promise<ClientResponse> {
    let board = spathToObject(folderPath, boardId) as Board;
    let folder: BoardFolder = getBySPath(board, folderPath) as BoardFolder;
    if (folder) {
        folder.name = name;
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_FOLDER, board));
}

function copyResource(fromBoardId:string, resourceSeq: number, toBoardId:string, toFolderPath?: MxSPath, newFileName?: string, suppressFeed:boolean|null = false): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if(suppressFeed === null){
        params.push({
          name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
          string_value: 'FALSE'
        });
    } else if (suppressFeed){
        params.push({
          name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
        });
    }

    if (newFileName) {
        params.push({
            name: ClientRequestParameter.BOARD_REQUEST_NEW_FILE_NAME_SEQUENCE,
            uint64_value: resourceSeq,
            string_value: newFileName
          });
    }

    let toBoard: Board = {id: toBoardId};
    if (toFolderPath && toFolderPath.length > 0) {
        toBoard = spathToObject(toFolderPath, toBoardId) as Board;
    }

    let fromBoard: Board = {
        id: fromBoardId,
        resources:[{
            sequence: resourceSeq
        }]
    };

    let user: User = {
        boards: [{board: fromBoard}]
    }

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_COPY_RESOURCES, params, user, toBoard));
}

function copyFiles(fromBoardId:string, files: MxSPath[], toBoardId:string, toFolderPath?: MxSPath, newFileNames?: string[], suppressFeed:boolean|null = false, keepOrder: boolean = false): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if(suppressFeed === null){
        params.push({
          name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
          string_value: 'FALSE'
        });
    } else if (suppressFeed){
        params.push({
          name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED,
        });
    }

    if (newFileNames && newFileNames.length === files.length) {
        for (let i = 0; i < files.length; i++) {
            newFileNames[i] && params.push({
                name:ClientRequestParameter.BOARD_REQUEST_NEW_FILE_NAME_SEQUENCE,
                uint64_value: parseFileSequenceFromSPath(files[i]),
                string_value: newFileNames[i],
              });
        }
    }

    if (keepOrder) {
        let orderNum = 10000;
        for (let i = 0; i < files.length; i++) {
            params.push({
                name:ClientRequestParameter.BOARD_REQUEST_ORDER_NUMBER_SEQUENCE,
                uint64_value: parseFileSequenceFromSPath(files[i]),
                string_value: String(orderNum),
            });
            orderNum += 100;
        }
    }

    let toBoard: Board = {id: toBoardId};
    if (toFolderPath && toFolderPath.length > 0) {
        toBoard = spathToObject(toFolderPath, toBoardId) as Board;
    }

    let fromBoard: Board = {id: fromBoardId};
    files.forEach(f => {
        let board: Board = spathToObject(f, fromBoardId);
        mergeObject(fromBoard, board);
    });

    let user: User = {
        boards: [{board: fromBoard}]
    }

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_COPY_PAGE_GROUP, params, user, toBoard));
}

function deleteFiles(boardId: string, files: MxSPath[], noFeed?: boolean): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if(noFeed){
      params.push({
        name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
      })
    }

    let board: Board = {id: boardId};
    files.forEach(f => {
        let board2: Board = spathToObject(f, boardId) as Board;
        mergeObject(board, board2);
    });

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_PAGE_GROUP, board, params));
}

function moveFiles(boardId:string, files: MxSPath[], toFolderPath?: MxSPath, newFileNames?: string[]): Promise<ClientResponse> {
    let toBoard: Board = spathToObject(toFolderPath, boardId) as Board;
    let fromBoard: Board = {id: boardId};
    files.forEach(f => {
        let board: Board = spathToObject(f, boardId);
        mergeObject(fromBoard, board);
    });

    let params: ClientParam[] = [];
    if (newFileNames && newFileNames.length === files.length) {
        for (let i = 0; i < files.length; i++) {
            newFileNames[i] && params.push({
                name:ClientRequestParameter.BOARD_REQUEST_NEW_FILE_NAME_SEQUENCE,
                uint64_value: parseFileSequenceFromSPath(files[i]),
                string_value: newFileNames[i],
              });
        }
    }

    let user: User = {
        boards: [{board: fromBoard}]
    }

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_MOVE_PAGE_GROUP, params, user, toBoard));
}

function renameFile(boardId: string, filePath: MxSPath, name: string): Promise<ClientResponse> {
    let board: Board = spathToObject(filePath, boardId) as Board;
    let file: BoardPageGroup = getBySPath(board, filePath) as BoardPageGroup;
    if (file) {
        file.name = name;
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_PAGE_GROUP, board));
}

function readFile(boardId:string, filePath: MxSPath): Promise<ClientResponse> {
    let board: Board = spathToObject(filePath, boardId) as Board;
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board));
}

function readPage(boardId:string, seq: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: seq
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board));
}

function readPages(boardId:string, seqs: number[]): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: seqs.map(seq => {
            return {sequence: seq};
        })
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board));
}

function createWhiteboard(boardId: string, name: string, width: number, height: number, folderPath?: string, fileUUID?: string): Promise<ClientResponse> {
    let board: Board = spathToObject(folderPath, boardId);
    let fileId = fileUUID || uuid();
    let file: BoardPageGroup = {
      client_uuid: fileId,
      name: name
    }

    board.pages = [{
        page_type: BoardPageType.PAGE_TYPE_WHITEBOARD,
        width: width,
        height: height,
        page_group: fileId,
        name: name
    }];

    if (folderPath){
      let folder: BoardFolder = getBySPath(board, folderPath);
      if (folder) {
          folder.files = [file];
      }
    }else{
      board.page_groups = [file]
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_PAGE, board));
}

function createWeblink(boardId: string, url:string, uid?:string): Promise<ClientResponse> {
    let fileId = uid || uuid();
    let board: Board = {
        id: boardId,
        page_groups: [{
            client_uuid: fileId
        }],
        pages: [{
            page_type: BoardPageType.PAGE_TYPE_URL,
            url: url,
            page_group: fileId,
        }]
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_PAGE, board));
}
  
function updatePage(boardId:string,pageSequence: number, pageInfo: BoardPage): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
            ...pageInfo,
        }]
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_PAGE, board));
}

function lockPage(boardId: string, pageSequence: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
        }]
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_EDITOR, board));
}

function unlockPage(boardId: string, pageSequence: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
        }]
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_REMOVE_EDITOR, board));
}

function updatePageEditorType(boardId:string, pageSequence: number, editorType: BoardEditorType): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
            editor_type: editorType,
        }]
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_EDITOR_TYPE, board));
}

function createPageElement(boardId: string, pageSequence: number, content: string, client_uuid?: string, elementType?: MxPageElementType): Promise<ClientResponse> {
    let tags: BoardTag[];
    if (elementType && elementType > 0) {
        tags = [];
        tags.push({
            name: 'TYPE',
            uint64_value: elementType
        })
    }

    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
            contents: [{
                client_uuid:client_uuid|| uuid(),
                svg_tag: content,
                tags: tags,
            }]
        }]
    }



    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_PAGE_ELEMENT, board));
}

function deletePageElement(boardId: string, pageSequence: number, elementUuid: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
            contents: [{
                client_uuid: elementUuid,
                is_deleted: true,
            }]
        }]
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_PAGE_ELEMENT, board));
}

function updatePageElement(boardId: string, pageSequence:number, elementUuid: string, content:string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        pages: [{
            sequence: pageSequence,
            contents: [{
                client_uuid: elementUuid,
                svg_tag: content,
            }]
        }]
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_PAGE_ELEMENT, board));
}

function uploadFromRemoteUrl(boardId: string, url: string, name: string, authToken: string, toFolderPath: MxSPath, contentType?: string, id?:string): Promise<ClientResponse> {
    let board: Board = spathToObject(toFolderPath, boardId) as Board;
    board.resources = [{
        name: name,
        client_uuid: id
    }];
    let params: ClientParam[] = [{
        name: ClientRequestParameter.RESOURCE_REQUEST_RESOURCE_TYPE,
        string_value: "original"
    },{
        name: ClientRequestParameter.RESOURCE_UPLOAD_RESOURCE_URL,
        string_value: url
    },{
        name: ClientRequestParameter.RESOURCE_UPLOAD_RESOURCE_AUTHORIZATION,
        string_value: authToken
    }];

    if (contentType) {
        params.push({
            name: ClientRequestParameter.RESOURCE_UPLOAD_RESOURCE_CONTENT_TYPE,
            string_value: contentType
        })
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPLOAD_RESOURCE_URL, board, params));
}

function getBoxAccessToken(boardId: string, payload: string): Promise<any> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.GROUP_REQUEST_BOX_PAYLOAD,
        string_value: payload
    }];

    return Ajax.sendRequest(requestNode(ClientRequestType.GROUP_REQUEST_BOX_ACCESS_TOKEN, params));
}

export {
    listFolder,
    listAllFolders,
    createFolder,
    deleteFolder,
    renameFolder,
    copyResource,
    copyFiles,
    deleteFiles,
    moveFiles,
    renameFile,
    readFile,
    createWhiteboard,
    readPage,
    readPages,
    updatePage,
    lockPage,
    unlockPage,
    updatePageEditorType,
    createPageElement,
    deletePageElement,
    updatePageElement,
    uploadFromRemoteUrl,
    getBoxAccessToken,
}