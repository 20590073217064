import { ClientResponseCode } from './../proto/generated/ClientResponseCode';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientRequestType } from './../proto/generated/ClientRequestType';
import { ClientRequest } from "../proto/generated/ClientRequest";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { Board } from '../proto/generated/Board';
import { ActionObject } from '../proto/generated/ActionObject';
import { UserBoard } from '../proto/generated/UserBoard';
import { MxISDK } from 'isdk';
import { Ajax } from './ajax';
import { MxCallback, MxNetworkState, MxError } from './../api/defines';
import { ISocket } from "./ISocket";
import { isInvalidTokenResponse, mxLogger } from '../util';
import { boardAndSessionRequestNode } from './requestNode';
import * as cacheMgr from '../data/cache/cacheMgr';

export class HttpLongPollingConnection implements ISocket {
    private _networkStateCallback: MxCallback<MxNetworkState>;
    private _subscriptionDataCallback: MxCallback<ClientResponse>;
    private _state: MxNetworkState;
    private _previousSubscribeRequest: Promise<any>;
    private _previousSubscribeMeetRequest: Promise<any>;
    private _subscribedBoardIds: Set<string>;
    private _isClosed: boolean;

    constructor(cb1: MxCallback<MxNetworkState>, cb2: MxCallback<ClientResponse>) {
        this._networkStateCallback = cb1;
        this._subscriptionDataCallback = cb2;
        this._state = MxNetworkState.DISCONNECTED;
        this._previousSubscribeRequest = null;
        this._previousSubscribeMeetRequest = null;
        this._subscribedBoardIds = new Set();
        this._isClosed = true;
    }

    state(): MxNetworkState {
        return MxNetworkState.CONNECTED;
    }
    
    setCallback(cb1: MxCallback<MxNetworkState>, cb2: MxCallback<ClientResponse>) {
        this._networkStateCallback = cb1;
        this._subscriptionDataCallback = cb2;
    }

    connect(): void {
        this._isClosed = false;
        this.sendMultipleSubscribeRequest();
    }    
    
    reconnect(): void {
        this.connect();
    }

    close(): void {
        this._isClosed = true;

        if (this._previousSubscribeRequest) {
            MxISDK.abortRequest(this._previousSubscribeRequest);
            this._previousSubscribeRequest = null;
        }

        if (this._previousSubscribeMeetRequest) {
            MxISDK.abortRequest(this._previousSubscribeMeetRequest);
            this._previousSubscribeMeetRequest = null;
        }
    }

    send(msg: ClientRequest): Promise<ClientResponse> {
        if (msg.type === ClientRequestType.BOARD_REQUEST_SUBSCRIBE) {
            this._subscribedBoardIds.add(msg.object.board.id);
        }else if (msg.type === ClientRequestType.BOARD_REQUEST_UNSUBSCRIBE) {
            this._subscribedBoardIds.delete(msg.object.board.id);
        }else {
            return  Ajax.sendRequest(msg);
        }

        if (!cacheMgr.currentUser && cacheMgr.currentMeet && msg.type === ClientRequestType.BOARD_REQUEST_SUBSCRIBE) {
            // anonymous join meet case
            return this.sendSubscribeMeetRequest();
        }else {
            return this.sendMultipleSubscribeRequest();
        }
    }

    private sendSubscribeMeetRequest(): Promise<ClientResponse> {
        if (!cacheMgr.currentMeet) {
            return Promise.resolve({});
        }

        if (this._previousSubscribeMeetRequest) {
            MxISDK.abortRequest(this._previousSubscribeMeetRequest);
        }

        if (this._isClosed) {
            return Promise.resolve({});
        }

        let board: Board = null;
        if (cacheMgr.currentMeet.meetBoard) {
          board = {
            id: cacheMgr.currentMeet.meetBoard.id,
            revision: cacheMgr.currentMeet.meetBoard.revision
          };
        }
        
        let session: ActionObject = null;
        if (cacheMgr.currentMeet.meetSession) {
          session = {
            session_key: cacheMgr.currentMeet.meetSession.session_key,
            revision: cacheMgr.currentMeet.meetSession.revision
          };
        }
    
        this._previousSubscribeMeetRequest = Ajax.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_SUBSCRIBE, board, session), {timeout: 45*1000})
        this._previousSubscribeMeetRequest.then(response => {
            this._subscriptionDataCallback && this._subscriptionDataCallback(response);
            // always keep a connection with server
            this.sendSubscribeMeetRequest();
            return response;
        }).catch( (e: MxError) => {
            // ignore error
        })

        return this._previousSubscribeMeetRequest;
    }

    private sendMultipleSubscribeRequest(): Promise<ClientResponse> {
        if (this._previousSubscribeRequest) {
            MxISDK.abortRequest(this._previousSubscribeRequest);
        }

        if (this._isClosed) {
            return Promise.resolve({});
        }

        let userId = MxISDK.getCurrentUser() ? MxISDK.getCurrentUser().id : '';
        let userRevision = MxISDK.getCurrentUser() ? MxISDK.getCurrentUser().user.revision : 0;
        let orgId = MxISDK.getCurrentOrg() ? MxISDK.getCurrentOrg().id : '';
        let orgRevision = MxISDK.getCurrentOrg() ? MxISDK.getCurrentOrg().group.revision : 0;
        let subscribedBoards: UserBoard[] = [];
        this._subscribedBoardIds.forEach(boardId => {
            if (cacheMgr.currentMeet && boardId === cacheMgr.currentMeet.getBoardId()) {
                subscribedBoards.push({
                    board: {
                        id: boardId,
                        revision: cacheMgr.currentMeet.meetBoard.revision,
                        sessions:[{
                            session: {
                                session_key: cacheMgr.currentMeet.getSessionKey(),
                                revision: cacheMgr.currentMeet.meetSession.revision
                            }
                        }]
                    }
                })
            }else {
                let mxBoard = cacheMgr.getBoardById(boardId);
                if (mxBoard) {
                    subscribedBoards.push({
                        board: {
                            id: mxBoard.id,
                            revision: mxBoard.board.revision,
                        }
                    })
                }
            }
        });

        let req: ClientRequest = {
            type: ClientRequestType.BOARD_REQUEST_SUBSCRIBE_MULTIPLE,
            object: {
                user: {
                    id: userId,
                    revision: userRevision,
                    boards: subscribedBoards,
                }, 
                group: {
                    id: orgId,
                    revision: orgRevision
                }
            },
            message: {
                updated_time: 0
            },
            params: [{
                name: ClientRequestParameter.USER_SUBSCRIBE_FILTER_MEET
            }]
        }

        this._previousSubscribeRequest = Ajax.sendRequest(req, {timeout: 45*1000});
        this._previousSubscribeRequest.then((response: ClientResponse) => {
            mxLogger.debug('recv: ', response);
            if (isInvalidTokenResponse(response)) {
                cacheMgr.onUserLoggedOut();
                return;
            }else if (!userId) {
                cacheMgr.onUserLoggedIn(response.object.user, response.object.group, response.object.contacts);
            }else if (response.type === ClientRequestType.USER_REQUEST_SUBSCRIBE
                || response.type === ClientRequestType.BOARD_REQUEST_SUBSCRIBE) {
                    this._subscriptionDataCallback && this._subscriptionDataCallback(response);
            }

            // always keep a connection with server
            this.sendMultipleSubscribeRequest();
        }).catch( (e: MxError) => {
            if (e && e.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN) {
                cacheMgr.onUserLoggedOut();
            }
            if ( e && e.code === ClientResponseCode.RESPONSE_ERROR_TIMEOUT) {
                this.sendMultipleSubscribeRequest()
            }
        })
        return this._previousSubscribeRequest;
    }
}
