import { Group } from './../proto/generated/Group';
import { Board } from './../proto/generated/Board';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientParam } from './../proto/generated/ClientParam';
import { ClientResponse } from './../proto/generated/ClientResponse';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { GroupIntegration } from '../proto/generated/GroupIntegration';
import { GroupUser } from '../proto/generated/GroupUser';
import { GroupUserRole } from '../proto/generated/GroupUserRole';
import { GroupUserRoleType } from '../proto/generated/GroupUserRoleType';
import { ClientRequest } from '../proto/generated/ClientRequest';
import { BoardAccessType } from '../proto/generated/BoardAccessType';
import {UserIdentity, MxGroupMemberFilter, MxUpdateEmailPhoneNumParam, MxResetPasswordChannel} from './../api/defines';
import { Ajax } from './../network/ajax';
import {groupRequestNode, boardRequestNode} from '../network/requestNode';

function readGroupInfo(groupId: string=''): Promise<ClientResponse> {
    let params: ClientParam[] = [
      {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/tags"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/group_caps"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/local"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/integrations"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/partner"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/roles"
      }, {
        name: ClientRequestParameter.OUTPUT_FILTER_STRING,
        string_value: "/group/routing_config"
      }];

    let group: Group = groupId ? {id: groupId} : null;
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ, group, params));
}

function createGroup(partnerId: string, group: Group): Promise<ClientResponse> {
  let req: ClientRequest = {
    type: ClientRequestType.PARTNER_REQUEST_CREATE_GROUP,
    object: {
        partner: {
            id: partnerId
        },
        group: group
    }
  };
  return Ajax.sendRequest(req);
}

function updateGroup(groupId: string, group: Group): Promise<ClientResponse> {
    if(!group.id){
        group.id = groupId;
    }
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE, group));
}

function inviteMember(groupId:string, user: GroupUser | GroupUser[], updateExisting?: boolean): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if (updateExisting) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_INVITE_UPDATE_EXISTING
    })
  }

  let group: Group = {
    id: groupId,
  }

  if (Array.isArray(user)) {
    group.members = user;
  }else {
    group.members = [user]
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_INVITE, group, params));
}

function deleteMember(groupId:string, memberSeq: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSeq
    }]
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_EXPEL, group));
}

function readMember(groupId: string, user: UserIdentity): Promise<ClientResponse> {
    let group: Group = {
        id: groupId,
        members: [{
            user: user
        }]
    }
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MEMBERS, group));
}

function readMembers(groupId: string, users: UserIdentity[]): Promise<ClientResponse> {
  let group: Group = {
      id: groupId,
      members: []
  }
  users.forEach(user => {
    group.members.push({user});
  })

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MEMBERS, group));
}

function readMemberDetail(groupId: string, user: UserIdentity, includeRelatedRelation?: boolean): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if (includeRelatedRelation) {
      params.push({
        name: ClientRequestParameter.GROUP_REQUEST_READ_RELATED_USER_RELATION
      })
    }

    let group: Group = {
        id: groupId,
        members: [{
            user: user
        }]
    }
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_READ, group, params));
}

function readMembersByPagination(groupId: string, startSequence: number = 0, size: number = 200): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.GROUP_REQUEST_SEARCH_START,
        string_value: String(startSequence)
    },{
        name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SIZE,
        string_value: String(size)
    }];

    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USERS_READ, {id: groupId}, params));
}

function searchGroupMembers(groupId: string, startSequence: number = 0, size: number = 20, filter?: MxGroupMemberFilter): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    let reqType: ClientRequestType = ClientRequestType.GROUP_REQUEST_READ_MEMBERS;

    params.push({
        name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SIZE,
        string_value: String(size)
      })

    params.push({
        name: ClientRequestParameter.GROUP_REQUEST_SEARCH_START,
        string_value: String(startSequence)
      })

    if (filter) {
        if (filter.searchKey) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_SEARCH_TEXT,
                string_value: filter.searchKey
            });
        }

        if (filter.isForAudit) {
          params.push({
              name: ClientRequestParameter.GROUP_REQUEST_READ_MEMBER_FOR_AUDIT
          });
        }

        if (filter.isAdmin) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_ADMIN
            });
        }

        if (filter.isInternal) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_READ_MEMBER_INTERNAL
            });
        }

        if (filter.isLocal) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_READ_MEMBER_LOCAL
            });
        }

        if (filter.isPending) {
            params.push({
              name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_INVITED
            })
        } ;

        if (filter.isNormal) {
            params.push({
              name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_MEMBER
            });
        }

        if(filter.isDeactived === true) {
          params.push({
              name: ClientRequestParameter.GROUP_REQUEST_READ_DEACTIVED_USER,
              string_value: 'TRUE'
          });
        }else if(filter.isDeactived === false) {
          params.push({
              name: ClientRequestParameter.GROUP_REQUEST_READ_DEACTIVED_USER,
              string_value: 'FALSE'
          });
        }

        if (filter.role && filter.role.length > 0) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_READ_FILTER_ROLE,
                string_value: filter.role.join()
            });
        }

        if(filter.includeRelationUser) {
            params.push({
                name: ClientRequestParameter.GROUP_REQUEST_READ_INCLUDE_RELATION_USER
            });
        }

        if(filter.includePresence) {
          params.push({
              name: ClientRequestParameter.GROUP_REQEUEST_READ_MEMBER_PRESENCE
          });
      }

      if(filter.sorter && filter.sorter.sortField && filter.sorter.sortMethod) {
        reqType = ClientRequestType.GROUP_REQUEST_READ_SORT_MEMBERS;

        params.push({
            name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SORT_FIELD,
            string_value: filter.sorter.sortField
        });
        params.push({
          name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SORT_METHOD,
          string_value: filter.sorter.sortMethod
      });
    }
    }

    return Ajax.sendRequest(groupRequestNode(reqType, {id: groupId}, params));
}

function resetGroupUserPassword(groupId: string ,memberSequence: number, channel?: MxResetPasswordChannel): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence
    }]
  }

  let reqType: ClientRequestType = channel === MxResetPasswordChannel.SMS ? ClientRequestType.GROUP_REQUEST_USER_RESET_PASSWORD_SMS: ClientRequestType.GROUP_REQUEST_USER_RESET_PASSWORD;

  return Ajax.sendRequest(groupRequestNode(reqType, group));
}

function resendInviteEmail(groupId: string, user: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      user: user
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_RESEND_INVITATION_EMAIL, group));
}

function resendInviteSms(groupId: string, user: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      user: user
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_RESEND_INVITATION_SMS, group));
}

function activateMember(groupId: string, memberSequence: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence,
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_ENABLE, group));
}

function deactivateMember(groupId: string, memberSequence: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence,
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_DISABLE, group));
}

function removeMemberAvatar(groupId: string, memberSequence: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence,
      user: {
        picture: 0,
        picture2x: 0,
        picture4x: 0
      }
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE_PICTURES, group));
}

function createRole(groupId: string, role: GroupUserRole): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    roles: [role]
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CREATE_ROLE, group));
}

function updateRole(groupId: string, roleSeq: number, role: GroupUserRole): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    roles: [{
      ...role,
      sequence: roleSeq
    }]
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_ROLE, group));
}

function deleteRole(groupId: string, roleSeq: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    roles: [{
      sequence: roleSeq
    }]
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_DELETE_ROLE, group));
}

function updateGroupUser(groupId:string, memberSequence: number, user: GroupUser): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      ...user,
      sequence: memberSequence,
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE, group));
}

function updateMemberEmailPhoneNum(groupId:string, userId: string, param: MxUpdateEmailPhoneNumParam): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      user: {
        id: userId,
        email: param.email,
        phone_number: param.phone_number
      }
    }]
  }

  let params: ClientParam[] = [];
  if (param.emailOff) {
    params.push({
        name: ClientRequestParameter.GROUP_REQUEST_EMAIL_OFF,
    });
  }

  if (param.smsOff) {
    params.push({
        name: ClientRequestParameter.GROUP_REQUEST_SMS_OFF,
    });
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE_EMAIL_PHONE_NUMBER, group, params));
}

function updateMemberRole(groupId:string, memberSequence: number, roleSequence:number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence,
      role: roleSequence
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE, group));
}

function updateMemberExtRoles(groupId:string, memberSequence: number, roles: number[]): Promise<ClientResponse> {
  if (!roles || roles.length === 0) {
    // remove ext roles
    roles = [0];
  }

  let group: Group = {
    id: groupId,
    members: [{
      sequence: memberSequence,
      roles: roles
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE, group));
}

function batchUpdateMembersRole(groupId:string, users: UserIdentity[], roleSequence:number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: []
  }
  users.forEach(user => {
    group.members.push({
      role: roleSequence,
      user: user
    })
  })

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_UPDATE, group));
}

function createIntegration(groupId: string, integration: GroupIntegration): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    integrations: [integration]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CREATE_INTEGRATION, group));
}

function updateIntegration(groupId: string, integrationSeq: number, integration: GroupIntegration): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    integrations: [{
      ...integration,
      sequence: integrationSeq
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_INTEGRATION, group));
}

function deleteIntegration(groupId: string, integrationSeq: number): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    integrations: [{
      sequence: integrationSeq,
      is_deleted:true
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_DELETE_INTEGRATION, group));
}

function createGroupBoard(groupId: string, board: Board, owner: UserIdentity): Promise<ClientResponse> {
  board.is_channel_subscription = true;
  board.users = [{
    type: BoardAccessType.BOARD_OWNER,
    user: owner
  }]

  let params: ClientParam[] = [{
    name: ClientRequestParameter.BOARD_REQUEST_CREATE_AS_SUBSCRIPTION_CHANNEL
  }];

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_CREATE, board, params));
}

function deleteGroupBoard(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_DELETE, {id: boardId}));
}

function readGroupBoards(groupId: string, startSequence: number = 0, size: number = 100): Promise<ClientResponse> {
  let params: ClientParam[] = [{
    name: ClientRequestParameter.GROUP_REQUEST_SEARCH_START,
    string_value: String(startSequence)
  },{
    name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SIZE,
    string_value: String(size)
  }];

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_SUBSCRIPTION_BOARDS, {id: groupId}, params));
}


function addGroupBoardMember(groupId: string, boardId: string, users: UserIdentity[], accessType?: BoardAccessType): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  params.push({
    name: ClientRequestParameter.BOARD_REQUEST_INVITE_ADD_DIRECTLY
  })

  let board: Board = {
    id: boardId,
    users: users.map(user => { return {type: accessType, user: user} })
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_ADD_MEMBER, board, params));
}

function addGroupBoardMemberByRole(groupId: string, boardId: string, roles: GroupUserRoleType[], accessType?: BoardAccessType): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    users: [{
      type: accessType,
      group: {
        id: groupId,
        roles: roles.map(role => { return {type: role} })
      }
    }]
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_ADD_MEMBER, board));
}

function updateGroupBoardMember(groupId: string, boardId: string, userSequence: number, roles: GroupUserRoleType[], accessType?: BoardAccessType): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    users: [{
      sequence: userSequence,
      type: accessType,
      group: {
        id: groupId,
        roles: roles.map(role => { return {type: role} })
      }
    }]
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_UPDATE_MEMBER, board));
}

function removeGroupBoardMember(groupId: string, boardId: string, memberSeq: number): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    users: [{
      sequence: memberSeq
    }]
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.GROUP_REQUEST_BOARD_REMOVE_MEMBER, board));
}

function readSamlProviders(groupId: string=''): Promise<ClientResponse> {
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.SERVER_SERVICE_PROVIDERS_READ, {}));
}

function createOrUpdateInvitationToken(): Promise<ClientResponse> {
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_USER_INVITATION_TOKEN, {}));
}

function readOrgTelephonyDomain(orgId?: string): Promise<ClientResponse> {
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_TELEPHONY_DOMAIN, {id: orgId}));
}


function transferMemberBoards(groupId: string, fromUserId: string, boardIds: string[], toUserId: string): Promise<ClientResponse> {
  let group: Group = {
    id: groupId,
    members: [{
      user: {
        id: fromUserId,
        boards: [],
      }
    }, {
      user: {
        id: toUserId
      }
    }]
  }

  boardIds && boardIds.forEach(boardId => {
    group.members[0].user.boards.push({
      board: {
        id: boardId
      }
    })
  })

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_TRANSFER, group));
}

export {
    readGroupInfo,
    createGroup,
    updateGroup,
    inviteMember,
    deleteMember,
    readMember,
    readMembers,
    readMemberDetail,
    readMembersByPagination,
    searchGroupMembers,
    resetGroupUserPassword,
    resendInviteEmail,
    resendInviteSms,
    activateMember,
    deactivateMember,
    removeMemberAvatar,
    createRole,
    updateRole,
    deleteRole,
    updateGroupUser,
    updateMemberEmailPhoneNum,
    updateMemberRole,
    updateMemberExtRoles,
    batchUpdateMembersRole,
    createIntegration,
    updateIntegration,
    deleteIntegration,
    createGroupBoard,
    deleteGroupBoard,
    readGroupBoards,
    addGroupBoardMember,
    addGroupBoardMemberByRole,
    updateGroupBoardMember,
    removeGroupBoardMember,
    readSamlProviders,
    createOrUpdateInvitationToken,
    readOrgTelephonyDomain,
    transferMemberBoards,
}
