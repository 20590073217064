
import { MxUserImpl } from './mxUserImpl';
import { UserCache } from '../data/cache/userCache';


export class MxAnonymousUserImpl extends MxUserImpl {
    constructor(userCache: UserCache){
        super(userCache);
    }

    get isAnonymousUser(): boolean {
        return true;
    }
}

export const mxAnonymousUser: MxAnonymousUserImpl = new MxAnonymousUserImpl(new UserCache({}));
