export enum UserType {
  USER_TYPE_NORMAL = 'USER_TYPE_NORMAL',
  USER_TYPE_DEVICE = 'USER_TYPE_DEVICE',
  USER_TYPE_AGENT = 'USER_TYPE_AGENT',
  USER_TYPE_SSO = 'USER_TYPE_SSO',
  USER_TYPE_SERVICE = 'USER_TYPE_SERVICE',
  USER_TYPE_BOT = 'USER_TYPE_BOT',
  USER_TYPE_WEBAPP = 'USER_TYPE_WEBAPP',
  USER_TYPE_LOCAL = 'USER_TYPE_LOCAL'
}
