import {ObjectUtils} from './object'
import _differenceBy from 'lodash/differenceBy'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'

const mapAttrName: string = '__index__'


function buildMap(arr: Array<any>, indexField: string) {

	let hasSubPath = false
	let map = getMap(arr, indexField);
	if (indexField.indexOf('.') > 0) {
		hasSubPath = true
	}
	let opts = map.opts || {};

	arr.forEach(function (item, index) {
		let key
		if (hasSubPath) {
			key = ObjectUtils.getByPath(item, indexField)
		} else {
			key = item[indexField]
		}
		if (key) {
			if (opts.needIndex) {
				if (opts.needIndex(item)) {
					map.set(key, index);
				}
			} else {
				map.set(key, index);
			}
		}
	});
}

function getIndexFields(target: any, fields: string[]) {
	let mapList = target[mapAttrName];
	fields = fields || [];
	if (mapList) {
		mapList.forEach((val: any, key: string) => {
			fields.push(key);
		});
	}
	return fields;
}

function getMap(arr: Array<any>, indexField: string = 'sequence', opts?: any) {
	let mapList = arr[mapAttrName];
	let map: any;
	if (!mapList) {
		Object.defineProperty(arr, mapAttrName, {
			value: new Map(), writable: true, enumerable: false
		});
		mapList = arr[mapAttrName];
	}
	map = mapList.get(indexField)
	if (!map) {
		map = new Map();
		if (opts) {
			map.opts = opts;
		}
		mapList.set(indexField, map);
		buildMap(arr, indexField);
	} else {
		if (opts) {
			map.opts = opts;
			map.clear();
			buildMap(arr, indexField);
		}
	}
	return map;
}


function getFromArray(arr: Array<any>, key: number | string, indexField = 'sequence') {
	if (!arr) {
		return null;
	}
	let map = getMap(arr, indexField);
	let index = map.get(key);
	if (index === undefined) {
		return null;
	}
	return arr[index];
}

function removeFromArray(arr: Array<any>, key: number | string, indexField = 'sequence') {
	if (!arr) {
		return;
	}
	let map = getMap(arr, indexField);
	let index = map.get(key);
	if (index === undefined) {
		return;
	}
	let item = arr.splice(index, 1);
	rebuildMap(arr, item);
}

function rebuildMap(arr: any, item?: any) {
	let maplist = arr[mapAttrName];
	if (maplist) {
		maplist.forEach((map: Map<string, any>, key: string) => {
			map.clear();
		})
		arr.forEach((item: any, index: number) => {
			maplist.forEach((map: Map<string, any>, key: string) => {
				let val = ObjectUtils.getByPath(item, key);
				if (ObjectUtils.isDefine(val)) {
					map.set(val, index);
				}
			})
		})
	}
}

function updateIndex(index: number, item: any, fields: string[], target: any[]) {
	if (!fields) {
		return;
	}
	fields.forEach((field: string) => {
		let fieldVal;
		let map = getMap(target, field);
		if (field.indexOf('.') >= 0) {
			fieldVal = ObjectUtils.getByPath(item, field);
		} else {
			fieldVal = item[field];
		}
		if (fieldVal) {
			map.set(fieldVal, index);
		}
	});
}

function getAttributeId(baseItem: any, mergeConfig: any) {
	if (mergeConfig.uniqueId) {
		if (ObjectUtils.isDefine(baseItem[mergeConfig.uniqueId])) {
			return mergeConfig.uniqueId;
		}
	}
	let fields = ['sequence', 'client_uuid', 'id']
	let uniqueId = fields.find((key: string) => {
		if (ObjectUtils.isDefine(baseItem[key])) {
			return true;
		}
		return false
	});
	return uniqueId;
}

function mergeArray<T>(target: T[], source: T[], mergeConfig: { uniqueId?: string, indexFields?: string[], [key: string]: any, replace?: boolean, clone?: boolean } = {}): T[] {
	let attributeId = mergeConfig.uniqueId || 'sequence';
	let fields = getIndexFields(target, mergeConfig.indexFields);
	if (!source) {
		return target;
	}
	if (target.length) {
		//some case array not sequence , it's cause performance issue. it's push to many same object to array when subscriber return the data more more than once.
		attributeId = getAttributeId(target[0], mergeConfig);
	}

	// normal array case we will overwrite
	let first = source[0]
	if (ObjectUtils.isString(first) || ObjectUtils.isNumber(first)) {
		if (fields) {
			target[mapAttrName] = null;
		}
		return source;
	} else {
		if (!attributeId) {
			target.length = 0;
			rebuildMap(target);
		}
	}
	let map = getMap(target, attributeId);
	source.forEach(function (item) {
		let sequence = item[attributeId];
		if (ObjectUtils.isUndefined(sequence)) {
			// normal array case
			target.push(item)
		} else {
			let index = map.get(sequence)
			if (ObjectUtils.isUndefined(index)) {
				target.push(item)
				index = target.length - 1;
				map.set(sequence, index);
			} else if (mergeConfig.replace) {
				target[index] = item;
			} else {
				target[index] = ObjectUtils.mergeObject(target[index], item, mergeConfig);
			}
			if (fields) {
				updateIndex(index, item, fields, target);
			}
		}
	})
	return target
}

const merge = mergeArray
const remove = removeFromArray
const differenceBy = _differenceBy
export const ArrayUtils = {
	getFromArray, getMap, buildMap, merge, remove, mergeArray, rebuildMap, differenceBy, find, findIndex
}
