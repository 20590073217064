import {ArrayUtils} from '@commonUtils'
export default {
	setGroupTags (state, tags) {
		state.tags = tags;
	},
	setBasicInfo (state, basicInfo) {
		state.basic_info = basicInfo;
	},
	setGroupCaps (state, caps) {
		state.group_caps = caps;
	},
	setGroupIntegrations (state, integrations) {
		state.integrations = integrations.filter(integration => !integration.is_deleted);
	},
	setGroupRoles (state, roles) {
		state.roles = roles;
	},
	managedMembers (state, members) {
		state.myManagedMembers = members
	},
	setIsM0App (state, isM0) {
		state.isM0App = isM0
	},
	setContextPath (state, contextPath) {
		state.contextPath = contextPath
	}
};
