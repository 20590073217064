import {Defines, MxISDK, MxUser} from 'isdk';
import {ObjectUtils} from '@commonUtils/object';
import {ArrayUtils} from '@commonUtils/array';
import {OnlineStatus} from '@controller/defines/EOnlineStatus';
import {CBaseUser} from '@controller/defines/CBaseUser';
import {User} from 'isdk/src/proto/generated/User';
import {UserRelation} from 'isdk/src/proto/generated/UserRelation';
import {CContactUser} from '@controller/defines/CContactUser';
import {CUserInfo} from '@controller/defines/CUserInfo';
import {UserRole} from 'isdk/src/proto/generated/UserRole';
import {UserGroup} from 'isdk/src/proto/generated/UserGroup';
import {GroupType} from 'isdk/src/proto/generated/GroupType';
import {GroupAccessType} from 'isdk/src/proto/generated/GroupAccessType';
import {Partner} from 'isdk/src/proto/generated/Partner';
import {CUserCap} from '@controller/defines/CUserCap';
import {CUserRelation} from '@controller/defines/CUserRelation';
import {CUserBoard} from '@controller/defines/CUserBoard';
import {CActionItem} from '@controller/defines/CActionItem';
import _pick from 'lodash/pick';
import rrule from 'rrule';
import {BoardFormatter} from '@controller/utils/board';
import {getByPath} from 'isdk/src/util';
import cloneDeep from 'lodash/cloneDeep';
import {Board, BoardUser, GroupUser, GroupUserRole, GroupUserStatus, UserBoard, UserType, BoardUserStatus, SessionStatus} from 'isdk/src/api/defines';
import {CRelationUserBoard} from '@controller/defines/CRelationUserBoard';
import {UserContactStatus} from 'isdk/src/proto/generated/UserContactStatus';
import moment from 'moment-timezone';
import {CGroupMember, GroupMemberStatues} from '@controller/defines/admin/CGroupMember'
import {GroupController} from '@controller/group/src/groupController'
import { SignatureController } from '@controller/signature/src/signatureController';

export class UserFormatter {
	static isInternalUser (type: Defines.UserType) {
		return type != Defines.UserType.USER_TYPE_LOCAL;
	}

	static isSameUser (userA: User, userB: User) {
	  const isUserIdEqual = userA.id && userB.id && userA.id === userB.id
	  if (!isUserIdEqual) {
	    const isUserUniqueIdEqual = userA.unique_id && userB.unique_id && userA.unique_id === userB.unique_id
		if (!isUserUniqueIdEqual) {
		  const isEmailEqual = userA.email && userB.email && userA.email === userB.email
		  const isPhoneEqual = userA.phone_number && userB.phone_number && userA.phone_number === userB.phone_number
		  return !!(isEmailEqual || isPhoneEqual)
		} else {
		  return true
		}
	  } else {
		return true
	  }
	}

	static transformToBaseUserForUserRelation (userRelation: UserRelation) {
		let user = this.transformToBaseUser(userRelation.user);
		user.avatar = '';
		user.avatar4x = '';
		if (userRelation.user.picture) {
			user.avatar = `${MxISDK.getContextPath()}/user/relation/${userRelation.sequence}/${userRelation.user.picture}`;
		} else {
			user.avatar = UserFormatter.getInitialAvatar(userRelation.user.first_name, userRelation.user.last_name);
		}
		if (userRelation.user.picture4x) {
			user.avatar4x = `${MxISDK.getContextPath()}/user/relation/${userRelation.sequence}/${userRelation.user.picture4x}`;
		}
		return user;
	}

	static transformUserBoardsToRelationUserBoards (userBoards: CUserBoard[]): CRelationUserBoard[] {
	  return userBoards.map(userBoard => {
		return UserFormatter.transformUserBoardToRelationUserBoard(userBoard);
	  });
	}

	static transformUserBoardToRelationUserBoard (userBoard: CUserBoard): CRelationUserBoard {
      const currentUser = MxISDK.getCurrentUser().basicInfo
      let relationUserBoard = Object.assign({}, userBoard) as CRelationUserBoard;
      // Check user in the user board and try to find the peer user
      let isMeOwner = false
      let isClientFound = false
      let binderClient = {}
      for (let boardUser of relationUserBoard.boardUsers) {
        if (boardUser.isOwner) {
          relationUserBoard.peerUser = boardUser
          if (boardUser.id !== currentUser.id) {
            break
          } else {
            isMeOwner = true
          }
        } else if (boardUser.isClient) {
          binderClient = boardUser
          isClientFound = true
        }
        if (isMeOwner && isClientFound) {
          relationUserBoard.peerUser = boardUser
          break
        }
	  }  
	  return relationUserBoard
	}

	static getUserSubTitle (user?: User) {
	  if (user) {
		return user.title || user.email || user.phone_number || user.display_email || user.display_phone_number
	  } else {
		return ''
	  }
	}
	
	static transformToBaseUser (user: User): CBaseUser {
		const group = MxISDK.getCurrentOrg();
		let userInfo = {} as CBaseUser;
		if (user) {
			userInfo.id = user.id;
			userInfo.userId = user.id;
			userInfo.email = user.email;
			userInfo.phone_number = user.phone_number || '';
			userInfo.display_id = user.display_id || '';
			userInfo.unique_id = user.unique_id || '';
			userInfo.isInternalUser = user.type !== Defines.UserType.USER_TYPE_LOCAL;

			userInfo.first_name = user.first_name;
			userInfo.last_name = user.last_name;
			userInfo.title = user.title;
			userInfo.subTitle = this.getUserSubTitle(user);
			userInfo.displayIdentifier =  user.display_id || user.email || user.phone_number || user.display_email || user.display_phone_number || user.title;
			userInfo.avatar = UserFormatter.getUserAvatar(user.picture, user.first_name, user.last_name);
			userInfo.avatar4x = UserFormatter.getUserAvatar(user.picture4x, user.first_name, user.last_name);
			userInfo.disabled = user.disabled || false;
			userInfo.has_push_notification = user.has_push_notification;
			userInfo.in_meet = user.in_meet || false;
			userInfo.language = user.language === 'zh-TW' ? user.language.toLowerCase() : user.language;
			userInfo.timezone = user.timezone;
			userInfo.outOfOffice = user.out_of_office;
			userInfo.emailVerified = user.email_verified;
			userInfo.display_email = user.display_email;
			userInfo.display_phone_number = user.display_phone_number
			if (group && group.basicInfo) {
				userInfo.companyName = group.basicInfo.name || '';
			}
			userInfo.type = user.type;
			userInfo.displayName = UserFormatter.getUserName(userInfo);
			userInfo.name = UserFormatter.getUserName(userInfo);
			userInfo.legalName = user.legal_name
			if (!userInfo.subTitle) {
				const currentUser = MxISDK.getCurrentUser()
				if (user.display_id && currentUser && currentUser.basicInfo && currentUser.basicInfo.type !== Defines.UserType.USER_TYPE_LOCAL) {
					userInfo.subTitle = user.display_id
				} else {
					userInfo.subTitle = '-'
				}
			}
		}
		return userInfo;
	}

	static transformContacts (contacts: Defines.UserContact[], needDisabledUser: boolean): CContactUser[] {
	  const contactsList = []
	  for (let contact of contacts) {
		if (!contact.is_deleted && (needDisabledUser || !contact.user.disabled)) {
		  contactsList.push(this.transformContact(contact))
		}
	  }
	  return contactsList	
	}

	static transformUserRelations (relations: Array<Defines.UserRelation>, filterOutDisabledUser: boolean = true): Array<CUserRelation> {
		if (relations) {
			const uRelations = filterOutDisabledUser ? relations.filter(relation => {
				return !ObjectUtils.getByPath(relation, 'user.disabled');
			}) : relations;
			return uRelations.map((relation) => {
				if (relation.is_deleted) return Object.assign({}, {
					sequence: relation.sequence, is_deleted: true
				}) as CUserRelation;
				const displayProperties = {
					isPending: relation.status === Defines.UserRelationStatus.RELATION_PENDING,
					isInit: relation.status === Defines.UserRelationStatus.RELATION_INIT,
					isNormal: relation.status === Defines.UserRelationStatus.RELATION_NORMAL,
					isPendingRelation: relation.status === Defines.UserRelationStatus.RELATION_PENDING, ...UserFormatter.transformToBaseUserForUserRelation(relation)
				};
				return Object.assign({}, relation, displayProperties) as CUserRelation;
			});
		}
		return [];
	}

	static transformUserInfo (user: Defines.User, userCap: CUserCap): CUserInfo {
		const userInfo = {} as CUserInfo;
		if (user.tags && user.tags.length) {
			user.tags.forEach((tag) => {
				if (tag.name === 'Mention_List_Last_Timestamp') {
					userInfo.mentionListTag = {
						string_value: tag.string_value,
						sequence: tag.sequence
					}
				}
				if (tag.name === 'invitation_message') {
					userInfo.invitationMessage = {
						value: tag.string_value, sequence: tag.sequence
					};
				}
				if (tag.name === 'pending_invitation_remind_timestamp') {
					userInfo.pending_invitation_remind_timestamp = tag.string_value;
				}
				if (tag.name === 'MXPRESENCE_TEMPLATES') {
					userInfo.presenceTemplates = JSON.parse(tag.string_value) as Array<object>;
				}
				if (tag.name === 'tooltip_view_time') {
					userInfo.tooltip_view_time = tag.uint64_value
				}
				if (tag.name === 'Ping_User_Alert_Status') {
					userInfo.Ping_User_Alert_Status = JSON.parse(tag.string_value)
				}
			});
		}
		Object.assign(userInfo, UserFormatter.transformToBaseUser(user));
		userInfo.feed_unread_total = user.feed_unread_total || 0;
		userInfo.customized_presence_message = user.customized_presence_message || '';
		userInfo.customized_presence_status = user.customized_presence_status || 0;
		if (user.signature) {
			userInfo.signature = `${MxISDK.getContextPath()}/user/${user.signature}`;
		}
		userInfo.initials_text = user.initials_text;
		userInfo.userCap = userCap;

		let adminGroup = null;
		let groups = user.groups;
		if (groups) {
			userInfo.teams = groups.filter((userGroup: UserGroup) => {
				let groupType = ObjectUtils.getByPath(userGroup, 'group.type');
				if (groupType === GroupType.GROUP_TYPE_ORG || !groupType) {
					if (userGroup.type === GroupAccessType.GROUP_ADMIN_ACCESS || userGroup.type === GroupAccessType.GROUP_OWNER_ACCESS) {
						adminGroup = userGroup;
					}
					if (userGroup.type === GroupAccessType.GROUP_OWNER_ACCESS)
						userInfo.isOwner = true
					if(userGroup.roles){
						userInfo.roles = ObjectUtils.getByPath(userGroup, 'roles', []);
					}
				} else if(groupType === GroupType.GROUP_TYPE_TEAM) {
					return true
				}
				return false
			});
		}
		if (adminGroup && userInfo.isInternalUser) {
			userInfo.isAdmin = true;
		}

		let userPartners = (user.partners || []).filter((partner) => !partner.is_deleted);
		userInfo.isPartnerAdmin = userPartners.length ? true : false;
		if(userPartners.length) {
			userPartners.filter((partner: Partner) => {
			userInfo.partnerId = ObjectUtils.getByPath(partner, 'partner.id');
			});
		}

		let userRole: UserRole = user.role || UserRole.USER_ROLE_NORMAL;

		const SUPER_ADMIN_ROLES: UserRole[] = [UserRole.USER_ROLE_SUPERADMIN, UserRole.USER_ROLE_OBJECT_READ, UserRole.USER_ROLE_OBJECT_WRITE, UserRole.USER_ROLE_SUPERADMIN_READONLY,];

		userInfo.isSuperAdmin = (SUPER_ADMIN_ROLES.indexOf(userRole) > -1) ? true : false;

		if (user.action_accessed_time) {
			userInfo.action_items_access_time = user.action_accessed_time
		}
		userInfo.enable_digest_email = user.enable_digest_email
		return userInfo;
	}

	static transformContactUserAvatar (contactUser: Defines.UserContact) {
		let avatar = '';
		let sequence = contactUser.sequence;
		let picture = contactUser.user.picture;
		if (picture) {
			avatar = `${MxISDK.getContextPath()}/user/contact/${sequence}/${picture}`;
		} else {
			avatar = this.getInitialAvatar(contactUser.user.first_name, contactUser.user.last_name);
		}
		return avatar;
	}

	static isClientDeleted (board: Board) {
		let users = board.users || [];
		let activeUsers = users.filter((u) => {
			return !(u.is_deleted);
		});
		let isOnlyMeInBinder = false;
		const currentUser: MxUser = MxISDK.getCurrentUser();
		if (activeUsers.length && activeUsers.length === 1) {
			isOnlyMeInBinder = ObjectUtils.getByPath(activeUsers[0], 'user.id') === currentUser.id;
		}
		if (board.is_relation) {
			let owner = BoardFormatter.getBoardOwner(board);
			let isMyBinder = ObjectUtils.getByPath(owner, 'user.id') === currentUser.id;
			if (!isMyBinder && UserFormatter.isInternalUser(currentUser.basicInfo.type)) {
				return false;
			}
			if (!owner && UserFormatter.isClientUser(currentUser.basicInfo.type)) {
				return true;
			}

			let client = activeUsers.find((boardUser: BoardUser) => {
				return !UserFormatter.isInternalUser(getByPath(boardUser, 'user.type'));
			});
			return !client;
		} else if (board.isconversation) {
			return isOnlyMeInBinder;
		}
		return false;
	}

	static transformUserBoards (userBoards: Array<UserBoard>, isInit: boolean = false): Array<CUserBoard> {
	  const _userBoards_ = []
	  for (let ub of userBoards) {
		const transformedUb = this.transformUserBoard(ub, isInit)
		if (Object.keys(transformedUb).length > 0) {
		  _userBoards_.push(transformedUb)
		}
	  }
	  return _userBoards_
	}
  
	static transformUserBoard (ub: UserBoard, isInit: boolean = false) {
	  const currentUser = MxISDK.getCurrentUser()
	  if (ub && ub.board) {
      const ubBoard = ub.board
      if (!ubBoard.is_transaction && !ubBoard.isnote && !this.isDefaultBoard(ubBoard) && (ub.status === BoardUserStatus.BOARD_INVITED || ub.status === BoardUserStatus.BOARD_MEMBER)) {
        let timestamp
        let weight = 0
        let session_modified_time
        if (ubBoard.islive) {
          const session = ObjectUtils.getByPath(ub, 'board.sessions.0.session')
          weight += 4
          if (session) {
            const rruleString = session.rrule
            const scheduledEndTime = session.scheduled_end_time
            const sessionStatus = session.session_status
            session_modified_time = session.last_modified_time
            if (!rruleString) {
              if (ub.status === BoardUserStatus.BOARD_INVITED) {
                if (scheduledEndTime && scheduledEndTime < Date.now()) {
                  return {
                    sequence: ub.sequence,
                    is_deleted: true
                  }
                }
			  } 
			} else {
			  let isValidRecurrentMeet = false
			  if (session.exdate && session.rrule.indexOf('UNTIL=') > -1) {
				const exdates = session.exdate.split(',')
				let rruleStr = `${session.rrule};DTSTART=${moment.utc(session.dtstart).format('YYYYMMDDTHHmmss[Z]')}`
				let ruleObj = rrule.fromString(rruleStr)
				if (exdates.length === ruleObj.count()) {
				  const allMeets = ruleObj.all()
				  for (let meetDate of allMeets) {
					const meetDateFmt = moment(meetDate).tz(session.timezone).format('YYYYMMDDTHHmmss')
					if (exdates.findIndex(ex_date => ex_date === meetDateFmt) < 0) {
					  isValidRecurrentMeet = true
					  break
					}
				  }
				  if (!isValidRecurrentMeet) {
				    return {
					  sequence: ub.sequence,
					  is_deleted: true
				    }
				  }
				}
			  }
			}
            
            timestamp = session.scheduled_start_time || session.start_time
            if (sessionStatus === SessionStatus.SESSION_STARTED) {
              weight += 5
            }
          }
      } else {
        const feed = ObjectUtils.getByPath(ub, 'board.feeds.0')
        if (feed) {
          timestamp = feed.timestamp || feed.created_time
        } else {
          // fallback to userBoard updated_time if no lastFeed found
          // not lastFeed it's should be a default binder need user created_time
          timestamp = ub.created_time
        }
      }

      // mock subscription board should be on the top
      if (ub.sequence < 0) {
        weight = 100
      }

      const result = {
        ...BoardFormatter.transformBoardBasicInfo(ubBoard),
        title: BoardFormatter.getBoardName(ubBoard),
		boardThumbnail: BoardFormatter.getBoardThumbnail(ubBoard),
		created_time: ubBoard.created_time,
		firstUnreadFeedTimestamp: ub.first_unread_feed_timestamp || 0,
        timestamp: timestamp,
        weight: weight,
        isOnlyMeInConversation: UserFormatter.isClientDeleted(ubBoard), 
        session_modified_time: session_modified_time,
        ..._pick(ub, ['sequence', 'status', 'type', 'feed_unread_count', 'first_unread_feed_sequence', 'accessed_time', 'waiting_signatures', 'client_uuid', 'revision', 'created_time', 'updated_time']), 
        ...UserFormatter.transformLastFeed(ubBoard)
      } as CUserBoard

      if (ubBoard.is_service_request && currentUser && currentUser.basicInfo.type === UserType.USER_TYPE_NORMAL) {
        //Service request binder needs to show original created_time since it could be reassigned from on RM to another
        result.created_time = ubBoard.created_time
        const updated_time = ubBoard.updated_time
        result.timestamp =  updated_time > result.timestamp ?  updated_time : result.timestamp
      }
      return result
      } else {
        return {}
      }
	  } else if (!isInit && ub && ub.is_deleted) {
      return {
        sequence: ub.sequence,
        is_deleted: true
      }
	  } else {
		  return {}
	  }
	}
  

	static transformLastFeed (board: Defines.Board): CUserBoard {
		let result = {} as CUserBoard;
		let lastFeed;
		let allFeeds = board.feeds;
		if (allFeeds && allFeeds.length > 0) {
			let k = allFeeds.length - 1;
			for (; k >= 0; k--) {
				if (allFeeds[k] && !allFeeds[k].is_deleted) break;
			}
			// lastFeed = cloneDeep(allFeeds[k]);
			lastFeed = allFeeds[k]
		}

		let feedUsers = ObjectUtils.getByPath(lastFeed, 'board.users');
		let users = board.users;
		if (feedUsers) {
			lastFeed.board.users = feedUsers.map((user: any) => {
				if (user.sequence) {
					return ArrayUtils.getFromArray(users, user.sequence);
				}
				return user;
			});
		}
		let actorId = ObjectUtils.getByPath(lastFeed, 'actor.id');
		let actor = ArrayUtils.getFromArray(users, actorId, 'user.id');
		if (actor) {
			lastFeed.actor = BoardFormatter.transformBoardActor(actor.user, board);
		}
		result.lastFeed = lastFeed;
		return result;
	}

	static transformUserMeets (userBoards: Array<Defines.UserBoard>): Array<Defines.UserBoard> {
		return userBoards.filter((ub) => {
			return !ub.is_deleted && !ub.board.is_deleted && ub.board.islive;
		});
	}

	static isClientUser (type: Defines.UserType) {
		return type === Defines.UserType.USER_TYPE_LOCAL;
	}

	static getInitialAvatar (firstName: string = '', lastName: string = '') {
		firstName = firstName || '';
		lastName = lastName || '';

		const initals = `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`;
		if (initals && (/^[a-z]+$/i).test(initals)) {
			let nameStr = initals.toLowerCase();
			return `assets/initials/${nameStr}.png`;
		}
		return '';
	}

	static getUserAvatar (pictureSequence: number, firstName: string = '', lastName: string = '') {
		if (pictureSequence) {
			return `${MxISDK.getContextPath()}/user/${pictureSequence}`;
		} else {
			return UserFormatter.getInitialAvatar(firstName, lastName);
		}
	}

	static getUserName (user: { first_name?: string, last_name?: string, name?: string, email?: string, unique_id?: string, phone_number?: string, type?: string }) {
		user = user || {};
		// if (user.type === Defines.UserType.USER_TYPE_SERVICE) {
		// 	if (user.first_name) {
		// 		return user.first_name;
		// 	}
		// }
		let nameList = [];
		if (user.first_name) {
			nameList.push(user.first_name.trim());
		}
		if (user.last_name) {
			nameList.push(user.last_name.trim());
		}
		let name: string;
		if (nameList.length) {
			name = nameList.join(' ').trim();
		}
		if (!name) {
			name = user.name || user.email || user.unique_id || user.phone_number || '';
		}
		return name;
	}

	static getUserRelationAvatar (userRelation: Defines.UserRelation, groupId?: string): string {
		const picture: string = ObjectUtils.getByPath(userRelation, 'user.picture');
		const userId: string = ObjectUtils.getByPath(userRelation, 'user.id');
		const firstName: string = ObjectUtils.getByPath(userRelation, 'user.first_name');
		const lastName: string = ObjectUtils.getByPath(userRelation, 'user.last_name');
		if (!picture) {
			return UserFormatter.getInitialAvatar(firstName, lastName);
		}
		if (groupId) {
			// means admin view user relation avatar
			return `${MxISDK.getContextPath()}/group/${groupId}/${userId}/${picture}`;
		} else {
			return `${MxISDK.getContextPath()}/user/relation/${userRelation.sequence}/${picture}`;
		}
	}

	static getUserContactAvatar (userContact: Defines.UserContact): string {
		const picture: string = ObjectUtils.getByPath(userContact, 'user.picture');
		const userSequence: number = userContact.sequence;
		if (!picture && userContact.user) {
			return UserFormatter.getInitialAvatar(userContact.user.first_name, userContact.user.last_name);
		} else {
			return `${MxISDK.getContextPath()}/user/contact/${userSequence}/${picture}`;
		}
	}

	static getUserStatus (presenceObj: Defines.User) {
		let status = OnlineStatus.Offline;
		if (presenceObj) {
			if (presenceObj.out_of_office && presenceObj.out_of_office.status) {
				status = OnlineStatus.OutOfOffice;
			} else if (presenceObj.online) {
				if (presenceObj.customized_presence_status > OnlineStatus.Online) {
					if (presenceObj.customized_presence_status === OnlineStatus.Busy) {
						status = OnlineStatus.Busy;
					}
				} else if (presenceObj.in_meet) {
					status = OnlineStatus.Busy;
				} else {
					status = OnlineStatus.Online;
				}
			} else if ((presenceObj.customized_presence_status === OnlineStatus.Busy) && presenceObj.has_push_notification) {
				status = OnlineStatus.Busy
			}
		}
		return status;
	}

	// static shouldMeetShowInTimeLine (userBoard: Defines.UserBoard): Boolean {
	// 	let session = ObjectUtils.getByPath(userBoard, 'board.sessions.0.session');
	// 	let session_status = ObjectUtils.getByPath(userBoard, 'board.sessions.0.session.session_status');

	// 	if (!session_status) {
	// 		return false;
	// 	}
	// 	const nowDate = Date.now();
	// 	if (session_status === 'SESSION_STARTED') {
	// 		// remove started more than 2 days meet
	// 		/*if ((nowDate - session.start_time) > 60 * 60 * 24 * 2 * 1000) {
	// 			return false;
	// 		}*/
	// 		return true;
	// 	}
	// 	if (session_status === 'SESSION_ENDED') {
	// 		return true;
	// 	}
	// 	if (userBoard.status === 'BOARD_INVITED') {
	// 		if (session_status === 'SESSION_SCHEDULED') {
	// 			const rruleString = session.rrule;
	// 			if (rruleString) {
	// 				const rruleObject = rrule.parseString(rruleString);
	// 				if (!rruleObject.until) {
	// 					return true;
	// 				} else {
	// 					const currentUser = MxISDK.getCurrentUser();
	// 					const transformedUser = this.transformToBaseUser(currentUser.basicInfo);
	// 					const userTimezone = transformedUser.timezone || moment.tz.guess();
	// 					const mUntil = moment(rruleObject.until).tz(userTimezone);
	// 					const mNow = moment(Date.now()).tz(userTimezone);
	// 					const isNotExpired = mUntil.isSameOrAfter(mNow);
	// 					if (!isNotExpired) {
	// 						return false;
	// 					} else {
	// 						return true;
	// 					}
	// 				}
	// 			} else {
	// 				if ((session.scheduled_end_time || 0) < nowDate) {
	// 					return false;
	// 				}
	// 			}
	// 		} else if ((session.scheduled_end_time || 0) < nowDate || (session.end_time || 0) > (session.scheduled_start_time || 0)) {
	// 			return false;
	// 		}

	// 		return true;
	// 	}
	// 	return false;
	// }

	private static transformContact (contact: Defines.UserContact): CContactUser {
		let result = Object.assign({}, contact) as CContactUser;
		Object.assign(result, UserFormatter.transformToBaseUser(contact.user));
		result.avatar = UserFormatter.transformContactUserAvatar(contact);
		result.isPendingRelation = !result.isInternalUser && contact.status !== UserContactStatus.CONTACT_NORMAL;
		let userGroup = ObjectUtils.getByPath(contact, 'user.groups.0')
		if (userGroup) {
			result.isGroupMember = userGroup.status === GroupUserStatus.GROUP_MEMBER
		}
		result.uniqueKey = 'C' + result.sequence;
		result.onlineStatus = OnlineStatus.Offline;
		return result;
	}

	private static isDefaultBoard (userBoard: Defines.UserBoard): boolean {
		const TIMESTAMP_20130801 = 1375286400000;
		let board = userBoard && userBoard.board;
		if (userBoard.is_default && ((userBoard.created_time * 1) >= TIMESTAMP_20130801)) {
			// if there is any feed in the board, we consider it is not a default binder
			if (board.feeds && board.feeds.length) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	}

	static transformToGroupUser(cGroupMember: CGroupMember): GroupUser {
		let groupUser = {} as GroupUser
		let user = {} as User
		if (cGroupMember) {
			user.first_name = cGroupMember.first_name
			user.last_name = cGroupMember.last_name
			user.display_id = cGroupMember.displayId
			user.email = cGroupMember.email
			user.phone_number = cGroupMember.phone_number || ''
			user.title = cGroupMember.title || ''
			user.type = UserType[cGroupMember.type]
			groupUser.sequence = cGroupMember.sequence
			groupUser.type = cGroupMember.isAdmin ? GroupAccessType.GROUP_ADMIN_ACCESS : GroupAccessType.GROUP_MEMBER_ACCESS
			groupUser.role = cGroupMember.role
			groupUser.user = user
		}
		return groupUser
	}


	static getMemberStatus(user: GroupUser): GroupMemberStatues {
		if (user.user && user.user.disabled) {
			return GroupMemberStatues.deactivated
		} else if (user.status === GroupUserStatus.GROUP_INVITED) {
			return GroupMemberStatues.pending
		} else {
			return GroupMemberStatues.active
		}
	}


	static transformToCGroupMemberList(members: Array<GroupUser>): Array<CGroupMember> {
		let membersList: Array<CGroupMember> = []
		members.forEach((member: GroupUser) => {
			membersList.push(this.transformToCGroupMember(member))
		})
		return membersList
	}


	static transformToCGroupMember(member: Defines.GroupUser, groupId?: string): CGroupMember {
		if (!groupId) {
			const group = MxISDK.getCurrentOrg()
			groupId = group.id
		}
		let user: CBaseUser = this.transformToBaseUser(member.user)
		let role: GroupUserRole = GroupController.getInstance().getRole(member.role, user.type)
		let cGroupMember = {
			avatar: GroupController.getGroupUserAvatar(member, groupId),
			status: this.getMemberStatus(member),
			uniqueIdentifier: user.display_id || user.email || user.phone_number || user.display_email || user.display_phone_number || user.title || '-',
			filterId: user.unique_id || user.email || user.phone_number,
			roleName: role ? role.name : '',
			displayName: user.displayName,
			userId: user.id,
			isAdmin: member.type === GroupAccessType.GROUP_ADMIN_ACCESS || member.type === GroupAccessType.GROUP_OWNER_ACCESS,
			createdTime: member.created_time,
			updatedTime: member.updated_time,
			title: user.title || '',
			displayId: user.display_id,
			relationCount: member.user && member.user.relations_total ? member.user.relations_total : 0,
			sequence: member.sequence,
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
			phone_number: user.phone_number,
			role: member.role,
			roles: member.roles,
			type: user.type,
			disabled: user.disabled,
			unique_id: user.unique_id,
			isOwnerAccess: member.type === GroupAccessType.GROUP_OWNER_ACCESS,
			display_email: user.display_email,
			display_phone_number: user.display_phone_number,
			lastActive: member.user ? member.user.last_active_time : 0
		}
		return cGroupMember
	}

	static actionItemsPreprocess(userBoards: UserBoard[]) {
		const currUser = MxISDK.getCurrentUser()
		const now = Date.now()
		const ret = {
			actionItems: [],
			syncEnableTimeItems: []
		}
		for (let ub of userBoards) {
			const board = ub.board
			if (ub.is_deleted) {
				// delete use case
				const item = {
					sequence: ub.sequence,
					is_deleted: ub.is_deleted
				}
				ret.actionItems.push(item)
			} else if (board.todos && board.todos.length) {
				const todo = ObjectUtils.getByPath(ub, 'board.todos.0')
				let todoItem: CActionItem = {
					sequence: ub.sequence,
					title: todo.name,
					binderId: ub.board.id,
					todoSequence: todo.sequence,
					in_relation: ub.board.is_relation,
					feedSequence: ObjectUtils.getByPath(ub, 'board.feeds.0.sequence')
				}

				const due_date = todo.due_date
				let remind_time = 0
				if (todo.reminders && todo.reminders.length) {
					for (const reminder of todo.reminders) {
						if (!reminder.is_deleted && ObjectUtils.getByPath(reminder, 'creator.user.id') === currUser.basicInfo.id) {
							remind_time = reminder.reminder_time
							break
						}
					}
				}

				const due_time = due_date ? moment(due_date).startOf('day').valueOf() : 0
				if (remind_time && remind_time > now && (!due_time || (due_time > now))) {
					todoItem.pre_remind_time = due_time && remind_time > due_time ? due_time : remind_time
				} else {
					if (ub.enabled_time) {
						todoItem.enable_time = ub.enabled_time
					} else {
						todoItem.enable_time = todo.updated_time
						ret.syncEnableTimeItems.push({
							sequence: ub.sequence,
							enabled_time: todoItem.enable_time
						})
					}
				}
				if (due_time) {
					todoItem.due_time = due_time
				}
				ret.actionItems.push(todoItem)
				
			} else if (board.signatures && board.signatures.length) {
				const signature = ObjectUtils.getByPath(ub, 'board.signatures.0')
				const signCtrl = new SignatureController(ub.board.id, signature)
				if (signCtrl.isWaitMySign()) {
					let esignItem: CActionItem = {
						sequence: ub.sequence,
						title: signature.name,
						binderId: ub.board.id,
						in_relation: ub.board.is_relation,
						signatureSequence: signature.sequence,
						feedSequence: ObjectUtils.getByPath(ub, 'board.feeds.0.sequence')
					}
					if (ub.enabled_time) {
						esignItem.enable_time = ub.enabled_time
					} else {
						esignItem.enable_time = signature.updated_time
						esignItem.enable_time && ret.syncEnableTimeItems.push({
							sequence: ub.sequence,
							enabled_time: esignItem.enable_time
						})
					}
					ret.actionItems.push(esignItem)
				}
			}
		}
		return ret
	}
}
