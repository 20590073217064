import {ObjectUtils} from './object'

export function isHidden(el:HTMLElement,root:Element):boolean {
    if(!root){
        root =document.body
    }
    if(!el){
        return true
    }
    if(el.style.display === 'none'){
        return true;
    }
    if(el.style.visibility==='hidden'){
        return true;
    }
    let transform = el.style.transform
    if(transform){
        let matchs = transform.match(/\.*translateY\((.*?)\)/)
        if(matchs && parseInt(matchs[1]) < -100){
            return true
        }
    }
    let style = window.getComputedStyle(el);
    if(style.display ==='none'){
        return true
    }
    if(style.visibility === 'hidden'){
        return true;
    }

    let node = el.parentElement
    if( node !== root) {
        return isHidden(node, root)
    }
    return false
}
export function isFocusable (el:HTMLFormElement) {
    if (el.tabIndex > 0 || (el.tabIndex === 0 && el.getAttribute('tabIndex') !== null)) {
        return true;
    }
    if (el.tabIndex <0 ){
        return false;
    }
    if (el.disabled) {
        return false;
    }

    switch (el.nodeName) {
        case 'A':
            return !!el.href && el.rel != 'ignore';
        case 'INPUT':
            return el.type != 'hidden' && el.type != 'file';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        default:
            return false;
    }
};
export function getFocusableItems(el:Element, filter:(el:Element)=> Boolean): Array<Element>  {
    let items:Array<Element> = []
    let itemNodes:NodeList = el.querySelectorAll('[tabindex], a, button, input, textarea,select');
    if(!itemNodes || !itemNodes.length){
        return items;
    }
    let item
    for(let i= 0; i < itemNodes.length; i++){
        item = itemNodes[i];
        if(ObjectUtils.isFunction(filter)){
           if(!filter(item)){
               continue;
           }
        }
        if(isFocusable(item) && !isHidden(item,el)){
            items.push(item)
        }
    }
    return items
}
const Keyboard = {
    Up: 38,
    Down:40,
    Enter:13,
    Space:32,
    Esc:27,
    Home:36,
    End:35,
    Tab: 9
}
export { Keyboard}
