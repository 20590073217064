<template>
  <div @mousedown="handleActiveEvent" style="position: relative;">
    <template v-if="basicInfoLoaded">
      <div class="request-header-container">
        <input
            class="secondary-btn"
            :class="{'disabled': showConfirmClose}"
            type="button"
            :value=" `< ${$t('Back')}`"
            @click="back">

        <span class="request-detail">{{ $t('msg_detail') }}</span>

        <span
            v-if="isSRClosed"
            class="completed"
            style="display: flex; align-items: center">
          <img :src="CHECKMARK_IMAGE" alt="checkmark" />
          <span style="padding-left:5px">Completed</span>
        </span>

        <input
            v-if="isAgentComplete"
            class="default-btn"
            type="button"
            value="Reopen"
            @click="handleReopenSR">

        <input
            v-if="isSROpen"
            class="primary-btn"
            :class="{'disabled': showConfirmClose}"
            type="button"
            :value=" $t('mark_as_complete') "
            @click="handleCloseSR">
      </div>

      <div v-if="showConfirmClose" class="confirm-complete">
        <div>
          <span>i</span>
          Mark as complete?
        </div>
        <span>
          You won't be able to reopen requests once you mark them as complete. Are you sure you want to continue?
        </span>
        <div>

          <input
              v-if="isSROpen"
              class="submit-request-btn"
              type="button"
              value="Complete"
              @click="handleConfirmComplete">

          <input
              v-if="isSROpen"
              class="cancel-request-btn"
              type="button"
              value="Cancel"
              @click="showConfirmClose=false">

        </div>

      </div>

      <div class="divider" />

      <div class="request-body-container">
        <!-- upper portion -->
        <div style="padding: 27px 28px 0 28px; ">

          <service-request-type :sr-board="srBoard" style="margin-bottom: 20px;" />

          <div class="row">
            <div>
              <img :src="TITLE_IMAGE" alt="title"/>
            </div>
            <div style="flex:1">
              <span class="binder-title">{{ srBoard.name }}</span>
            </div>
          </div>

          <div class="row">
            <div>
              <img :src="DESCRIPTION_IMAGE" alt="description"/>
            </div>
            <div style="flex:1; display:flex; flex-direction: column; max-width: 100%;">
              <span class="title">{{ $t('Description') }}</span>
              <span
                  class="item-content"
                  style="white-space: pre-wrap">{{ srBoard.description }}</span>
            </div>
          </div>

          <div class="row">
            <div>
              <img :src="CLOCK_IMAGE" alt="clock" />
            </div>
            <div style="flex:1; display:flex; flex-direction: column">
              <span class="title"> {{ $t('submitted_on') }} </span>
              <span class="item-content">{{ displayTime }}</span>
            </div>
          </div>

          <div class="row">
            <div>
              <img :src="ATTACHMENT_IMAGE" alt="attachment" />
            </div>
            <div style="flex:1; display:flex; flex-direction: column">
              <template v-if="hasAttachments">
                <span class="title"> {{ $t('Attachments') }}  </span>
                <ul>
                  <li
                      v-for="resource in srBoard.resources"
                      :key="resource.sequence">
                    <a
                        :href="downloadUrl(resource)"
                        class="item-content" style="display: flex">
                      {{ resource.name }}
                      <img :src="DOWNLOAD_IMAGE" style="margin-left:5px"
                         alt="download" />
                    </a>
                  </li>
                </ul>
              </template>
              <span
                  v-else
                  class="item-content"> {{ $t('no_attachments') }}</span>
            </div>
          </div>

          <template v-if="!isAgentComplete && !isSRClosed">
            <div class="new-request-row">
              <div
                  v-for="(item, index) in fileList"
                  :key="index"
                  class="file-item">
                <div style="display: flex">
                  <img :src="FILE_IMAGE"
                       alt="file"
                      style="padding-left:5px" />
                  <span
                      class="file-item-name"
                      style="flex:1;color: rgb(31, 33, 38);">{{ normalize(item.name) }}</span>
                </div>
                <div style="display: flex">
                  <span
                      v-if="isUploading"
                      style="display: flex; align-items: center; padding-right: 10px">
                    <progress
                        v-show="item.status < 4"
                        max="100"
                        :value="item.percentage" />
                    <span
                        v-show="item.status===4"
                        class="attachment-failed">{{ attachmentFailureReason(item.error) }}</span>
                  </span>

                  <a
                      v-if="!isUploading || item.status === 4"
                      style="display: flex; align-items: center"
                      @click="removeAttachment(index)">
                    <img :src="CLOSE_IMAGE"
                        alt="close"
                        style="font-size:16px; padding-right:10px" />
                  </a>
                </div>
              </div>
            </div>

            <div style="display: flex">
              <div
                  class="add-attachment new-request-row"
                  style="display: flex; align-items: center">
                <a
                    style="display:flex; align-items:center; "
                    @click="interceptClick">
                  <span  class="attachment-icon">
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path d="M6.2735,14.4307 C4.8655,14.4307 3.4575,13.8947 2.3845,12.8227 C0.2405,10.6777 0.2405,7.1887 2.3845,5.0447 L5.5665,1.8627 C5.9575,1.4717 6.5905,1.4717 6.9815,1.8627 C7.3715,2.2527 7.3715,2.8857 6.9815,3.2767 L3.7985,6.4587 C2.4345,7.8237 2.4345,10.0437 3.7985,11.4087 C5.1635,12.7727 7.3835,12.7727 8.7485,11.4087 L12.6375,7.5187 C13.0155,7.1407 13.2235,6.6387 13.2235,6.1047 C13.2235,5.5707 13.0155,5.0687 12.6375,4.6907 C11.8585,3.9107 10.5895,3.9107 9.8095,4.6907 L5.9195,8.5797 C5.7255,8.7747 5.7255,9.0927 5.9205,9.2867 C6.1155,9.4817 6.4325,9.4817 6.6275,9.2867 L9.8095,6.1047 C10.2005,5.7147 10.8335,5.7147 11.2235,6.1047 C11.6145,6.4957 11.6145,7.1287 11.2235,7.5187 L8.0415,10.7017 C7.0665,11.6757 5.4815,11.6757 4.5055,10.7017 C3.5315,9.7257 3.5315,8.1407 4.5055,7.1657 L8.3955,3.2767 C9.9545,1.7167 12.4925,1.7167 14.0515,3.2767 C14.8075,4.0317 15.2235,5.0367 15.2235,6.1047 C15.2235,7.1737 14.8075,8.1777 14.0515,8.9327 L10.1625,12.8227 C9.0905,13.8947 7.6825,14.4307 6.2735,14.4307" id="path-1"></path>
                    </svg>
                  </span>
                  <input
                      ref="input"
                      multiple="multiple"
                      :disabled="this.isUploading"
                      type="file"
                      style="visibility: hidden; position: absolute; width: 1px; height: 1px;"
                      @change="onChange">
                  <span style="font-size: 11px; font-weight: bold;height: 13px; "> {{ $t('add_attachment') }} </span>
                </a>
              </div>

              <div
                  v-if="!isUploading && this.fileList.length > 0"
                  style="display: flex; align-items: center">
                <input
                    class="secondary-btn"
                    style="margin-left:10px"
                    type="button"
                    value="Upload"
                    @click="uploadFiles">
              </div>
            </div>
          </template>
        </div>

        <div
            class="divider"
            style="margin-bottom: 0;" />

        <div
            v-if="!feedsLoaded"
            class="feeds-container" style="display: flex; justify-content: center">
          <div class="spinner"></div>
        </div>
        <div
            v-else
            class="feeds-container">
          <div
              v-if="hasMoreFeeds"
              style="display: flex; justify-content: center">
            <input
                class="secondary-btn"
                type="button"
                value="Load Earlier"
                @click="loadMore">
          </div>

          <service-request-comment
              v-for="feed in getFeeds"
              :key="feed.sequence"
              :feed="feed" />
        </div>

        <div
            v-if="!isSRClosed && !isAgentComplete"
            class="reply-container">
          <!--                <textarea id='comment' placeholder="Reply" v-model="newComment" type="text" @keyup.enter="sendMsg"-->
          <!--                              class="reply"/>-->

          <el-input
              style="font-size: 14px; width: 100%; flex:1; display: flex"
              type="textarea"
              :autosize="{ minRows: 1}"
              :placeholder="$t('Reply')"
              v-model="newComment"
              @keyup.enter.prevent.native="sendMsg"
              @keydown.enter.prevent.native />

          <div class="send-msg-button" @click="sendMsg">
            <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path fill="white" d="M14.5854,7.3291 L3.2444,1.6591 C2.6964,1.3851 2.0684,1.8481 2.1684,2.4531 L2.8384,6.4721 C2.8914,6.7871 3.1394,7.0351 3.4554,7.0881 L8.9274,8.0001 L3.4554,8.9121 C3.1394,8.9651 2.8914,9.2121 2.8384,9.5281 L2.1684,13.5471 C2.0684,14.1521 2.6964,14.6151 3.2444,14.3411 L14.5854,8.6711 C15.1384,8.3941 15.1384,7.6051 14.5854,7.3291">
              </path>

            </svg>
          </div>
        </div>
      </div>
    </template>


    <div v-if="!basicInfoLoaded" style="height: 400px; display: flex; align-items: center">
      <div class="spinner"/>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { MxEvent } from "./MxEvent";
import uploadErrorMixin from "./mixin/uploadError"
import ServiceRequestComment from "./ServiceRequestComment";
import moment from "moment-timezone";
import SEND_BUTTON from "@views/theme/src/images/acd/Button-Send.png";
import TITLE_IMAGE from "@views/theme/src/fonts/icons/source/websdk-title-sm.svg"
import DESCRIPTION_IMAGE from "@views/theme/src/fonts/icons/source/websdk-description-sm.svg"
import ATTACHMENT_IMAGE from "@views/theme/src/fonts/icons/source/websdk-attachment-sm.svg"
import CLOCK_IMAGE from "@views/theme/src/fonts/icons/source/websdk-clock-sm.svg"
import CHECKMARK_IMAGE from "@views/theme/src/images/acd/cardsdk-checkmark-green.svg"
import CLOSE_IMAGE from "@views/theme/src/fonts/icons/source/websdk-close-sm.svg"
import DOWNLOAD_IMAGE from "@views/theme/src/fonts/icons/source/websdk-download-xs.svg"
import FILE_IMAGE from "@views/theme/src/fonts/icons/source/websdk-file-sm.svg"

import eventsHandler from "./mixin/eventsHandler";
import {FunctionUtil} from "@commonUtils/function";
import ServiceRequestType from './ServiceRequestType';

export default {
  name: "ServiceRequestDetail",
  components: {ServiceRequestType, ServiceRequestComment },
  mixins: [uploadErrorMixin, eventsHandler],
  props: {
    srBoardId : {
      type: String
    }
  },
  data () {
    return {
      SEND_BUTTON,
      TITLE_IMAGE,
      DESCRIPTION_IMAGE,
      ATTACHMENT_IMAGE,
      CLOCK_IMAGE,
      CHECKMARK_IMAGE,
      CLOSE_IMAGE,
      DOWNLOAD_IMAGE,
      FILE_IMAGE,
      fileList: [],
      newComment: "",
      isUploading: false,
      feedsLoaded: false,
      triggerLoadMore: false,
      basicInfoLoaded: false,
      showConfirmClose: false
    };
  },
  watch: {

    fileList (v) {
      if (this.isUploading) {
        if (!v || v.length === 0)
            // uploading is completed and successful
          this.isUploading = false;
        else {
          let len = v.length;
          for (const x of v) {
            if (x.status !== 4) break;
            len--;
          }
          if (len === 0) {
            this.isUploading = false;
          }
        }
      }
    },
    getFeeds (n, o) {
      if (!this.feedsLoaded) {
        this.feedsLoaded = true;
        let el = document.querySelector(".feeds-container");
        if (el) {
          this.$nextTick(() => {
            el.scrollTop = el.scrollHeight + el.clientHeight;
          });
        }
      } else if (this.feedsLoaded && !this.triggerLoadMore) {
        let el = document.querySelector(".feeds-container");
        if (el) {
          this.$nextTick(() => {
            el.scrollTop = el.scrollHeight + el.clientHeight;
          });
        }
      } else if (this.feedsLoaded && this.triggerLoadMore) {
        let el = document.querySelector(".feeds-container");
        if (el) {
          el.scrollTop = 0;
        }

        this.triggerLoadMore = false;
      }
    }
  },
  computed: {
    ...mapGetters("cardsdk", [
      "getSRBinder",
      "getFeeds",
      "hasMoreFeeds",
      "getBaseDomain",
      "getUserBasicInfo"
    ]),
    ...mapState("group", ["contextPath"]),
    ...mapState("uploader", ["files"]),

    displayTime () {
      return moment(this.srBoard.created_time).format("MMM D, YYYY");
    },
    isSRClosed () {
      return this.srBoard.routing_status === "ROUTING_STATUS_CLOSE";
    },
    isAgentComplete () {
      return this.srBoard.routing_status === "ROUTING_STATUS_AGENT_COMPLETE";
    },
    isSROpen (){
      return this.srBoard.routing_status === "ROUTING_STATUS_OPEN" ||  this.srBoard.routing_status === "ROUTING_STATUS_IN_PROGRESS"
    },
    hasAttachments () {
      return (this.srBoard.resources && this.srBoard.resources.length) || 0;
    },
    srBoard () {
      return this.getSRBinder || {};
    }
  },
  created () {
    this.initDetailSubscriber({ binderId: this.srBoardId })
        .then( () => this.basicInfoLoaded = true)
        .catch( err => {});
  },

  beforeDestroy () {
    this.resetState();
    this.resetStore();
  },
  methods: {
    ...mapActions("cardsdk", [
      "initDetailSubscriber",
      "createComment",
      "resetState",
      "closeSR",
      "reopenSR",
      "loadMoreFeeds"
    ]),
    ...mapMutations("cardsdk", ["setPendingFeedFailure"]),
    ...mapActions({
      addFilesToUploadQueue: "uploader/addFilesToUploadQueue",
      removeFileFromUploadQueue: "uploader/removeFileFromUploadQueue",
      clearFailedFromQueue: "uploader/clearFailedFromQueue",
      resetStore: "uploader/resetStore"
    }),



    downloadUrl (resource) {
      let type = resource.type === "pdf" ? "pages" : "files";
      let path = 'board'
      if(window['useTrailingSlash']) {
        path = 'board_download'
      }
      let result = `${this.contextPath}/${path}/${this.srBoard.id}/${resource.sequence}/?d=${resource.name}`;
      if (this.getBaseDomain) result = `${this.getBaseDomain}${result}`;
      return result;
    },
    sendMsg () {
      if (this.newComment) {
        let uuid = FunctionUtil.uuid();
        let feed = {
          isFake: true,
          uuid: uuid,
          action: "COMMENT",
          time: new Date(),
          name: this.getUserBasicInfo.displayName,
          actor: {
            avatar: this.getUserBasicInfo.avatar
          },
          comment: this.newComment
        };
        this.createComment(feed).catch( () => {this.setPendingFeedFailure(uuid)});
        this.newComment = ""
      }
    },
    handleCloseSR (){
      this.showConfirmClose = true
    },
    handleConfirmComplete () {
      this.closeSR().then( ()=> {
        this.isCompleted = true
        this.showConfirmClose = false
      })
    },

    interceptClick () {
      this.$refs["input"].click();
    },
    removeAttachment (index) {
      let removed = this.fileList.splice(index, 1);
      if (removed[0].uuid) {
        this.removeFileFromUploadQueue(removed[0].uuid);
      }
    },
    onChange (e) {
      if (e.target.value) {
        let items = Array.from(e.target.files);
        this.fileList.push(...items);
        e.target.value = "";
      }
    },
    normalize (fileName) {
      const MAX_LENGTH = 32;
      if (fileName.length < MAX_LENGTH) return fileName;

      let array = [...fileName];
      return [...array.splice(0, 15), "...", ...array.splice(-14, 14)].join("");
    },
    uploadFiles (e) {
      let newBoardId = this.srBoardId;
      let files = this.fileList
          .filter(f => !f.status)
          .map(f => {
            let result = {
              file: f,
              url: `${this.contextPath}/board/upload/?newfile&id=${newBoardId}&name=${encodeURIComponent(
                  f.name
              )}&type=original`,
              percentage: 0
            };
            if (this.getBaseDomain) {
              result.url = `${this.getBaseDomain}${result.url}`;
            }
            return result;
          });

      this.resetStore();
      this.addFilesToUploadQueue(files).then(() => {
        this.isUploading = true;

        this.fileList = [];
        this.fileList = this.files;
      });
    },
    handleReopenSR () {
      this.reopenSR().then(() => {

      });
    },

    showMessages () {
      this.$root.$emit(MxEvent.onBadgeClick);
    },
    showDetailView () {
      this.$emit("show-detail");
    },
    back () {
      this.$emit("back");
    },

    loadMore () {
      this.triggerLoadMore = true;
      this.loadMoreFeeds();
    }
  }
};
</script>

<style lang="scss" scoped>

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  height: 20px;
  color: rgb(31, 33, 38);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  padding: 3px 0;
}


/deep/ .el-textarea__inner {
  padding: 6px 0px 6px 15px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(31, 33, 38, 0.12);
  border-radius: 18px;
  overflow-y: hidden;
  height: 24px;
  width: 95%;
  resize: none;
  flex: 1;
  margin-right: 10px;
  -webkit-appearance: none;
}

.binder-title {
  color: rgb(31, 33, 38);
  font-size: 19px;
  font-weight: 600;
}

.request-detail {
  flex: 1;
  display: flex;
  justify-content: center;
  height: 18px;
  color: rgb(31, 33, 38);
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 375px) {
  .request-detail {
    width: 100%;
    flex-basis: 100%;
    order: 5;
    padding-top: 26px;
  }
}

.request-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px 0;
  flex-wrap: wrap;
}

.request-body-container {
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.04), 0 1px 14px 0 rgba(0, 0, 0, 0.04);
}

.row {
  padding-bottom: 16px;
  display: flex;
  max-width: 100%;
}

.row > div > i {
  font-size: 20px;
  color: rgb(141, 145, 153);
}

.row > div:first-of-type {
  /*flex: 0;*/
  padding: 2px 18px 0 0;
}

.item-content {
  color: #222222;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: 95%;
  /*white-space: pre-wrap;*/
}

.title {
  color: #444444;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.completed {
  height: 26px;
  border-radius: 18px;
  border: 0px #ebf6f0;
  background: #ebf6f0;
  padding: 0 15px;
  color: rgb(64, 173, 117);

  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 16px;
}

.reply-container {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

textarea::placeholder {
  width: 341.97px;
  /*height: 24px;*/
  color: rgb(138, 138, 142);
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}

.feeds-container {
  padding: 22px 40px 0 28px;
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
  scroll-padding-top: 10px;
}

.default-btn {
  border: 2px solid rgba(118, 118, 128, 0.12);
  background: inherit;
  border-radius: 18px;
  height: 28px;
  padding: 0 30px;
  color: #606369;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  -webkit-appearance: none;
}

@keyframes spinner-border {
  to {transform: rotate(360deg); }
}

.spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
  border-color: var(--cardsdk-branding-color,#035cfe);
  border-right-color: transparent!important;
  margin: 24px auto;
  text-align: center;
}
.confirm-dialog-modal {
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: #1f2126;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
}

.confirm-dialog {
  width: 100%;
  height: 100%;
  z-index: 2001;
  position: absolute;
  left: 0;
  top: 0;

  >div {
    width: 415px;
    background: white;
    margin: auto;
    margin-top: 200px;
    z-index: 20000000;
    border-radius: 4px;
  }
}

.confirm-complete{
  border:1px solid var(--cardsdk-branding-color,#035cfe);
  border-radius: 6px;
  padding: 24px 28px;
  margin-bottom: 28px;

  div:first-child{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    span {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      color: var(--cardsdk-branding-color,#035cfe);
      border:2px solid var(--cardsdk-branding-color,#035cfe);
      display: inline-flex;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }
  }

  div:last-child{
    margin-top: 20px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
  }

}

.send-msg-button {
  width: 28px;
  height: 28px;
  display: flex;
  background: var(--cardsdk-branding-color,#035cfe);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor:pointer;
}
</style>
