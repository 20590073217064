export default {
    userBoards: [],
    binderObj: [],
    feeds: [],
    binderId: '',
    userAvatarMapping: [],
    hasMoreFeeds: false,
    currentSequence : 0,
    baseDomain: '',
    userCap: {},
    userBasicInfo: {}
}
