export enum ClientResponseCode {
  RESPONSE_CONNECT_SUCCESS = 'RESPONSE_CONNECT_SUCCESS',
  RESPONSE_ERROR_UPGRADE_REQUIRED = 'RESPONSE_ERROR_UPGRADE_REQUIRED',
  RESPONSE_SUCCESS = 'RESPONSE_SUCCESS',
  RESPONSE_ACCEPTED = 'RESPONSE_ACCEPTED',
  RESPONSE_NO_CONTENT = 'RESPONSE_NO_CONTENT',
  RESPONSE_ERROR_STATUS_MOVED = 'RESPONSE_ERROR_STATUS_MOVED',
  RESPONSE_ERROR_X_ACCEL_REDIRECT = 'RESPONSE_ERROR_X_ACCEL_REDIRECT',
  RESPONSE_ERROR_TEMPORARY_REDIRECTION = 'RESPONSE_ERROR_TEMPORARY_REDIRECTION',
  RESPONSE_ERROR_INVALID_REQUEST = 'RESPONSE_ERROR_INVALID_REQUEST',
  RESPONSE_ERROR_INVALID_TOKEN = 'RESPONSE_ERROR_INVALID_TOKEN',
  RESPONSE_ERROR_PAYMENT_REQUIRED = 'RESPONSE_ERROR_PAYMENT_REQUIRED',
  RESPONSE_ERROR_PERMISSION = 'RESPONSE_ERROR_PERMISSION',
  RESPONSE_ERROR_NOT_FOUND = 'RESPONSE_ERROR_NOT_FOUND',
  RESPONSE_ERROR_INVALID_OBJECT = 'RESPONSE_ERROR_INVALID_OBJECT',
  RESPONSE_ERROR_TIMEOUT = 'RESPONSE_ERROR_TIMEOUT',
  RESPONSE_ERROR_CONFLICT = 'RESPONSE_ERROR_CONFLICT',
  RESPONSE_ERROR_PRECONDITION_FAILED = 'RESPONSE_ERROR_PRECONDITION_FAILED',
  RESPONSE_ERROR_EXCEED_LIMIT = 'RESPONSE_ERROR_EXCEED_LIMIT',
  RESPONSE_ERROR_TOO_MANY_REQUESTS = 'RESPONSE_ERROR_TOO_MANY_REQUESTS',
  RESPONSE_ERROR_FAILED = 'RESPONSE_ERROR_FAILED',
  RESPONSE_ERROR_BAD_GATEWAY = 'RESPONSE_ERROR_BAD_GATEWAY',
  RESPONSE_ERROR_SERVICE_UNAVAILABLE = 'RESPONSE_ERROR_SERVICE_UNAVAILABLE',
  RESPONSE_SUBSCRIPTION_DATA = 'RESPONSE_SUBSCRIPTION_DATA',
  RESPONSE_CONNECTION_TOKEN_VERIFIED = 'RESPONSE_CONNECTION_TOKEN_VERIFIED',
  RESPONSE_ERROR_DISCONNECTED = 'RESPONSE_ERROR_DISCONNECTED'
}
