import {ISDKConfig} from '../api/defines';
import {stringify} from 'qs'
import {currentUser, currentOrg} from "../data/cache/cacheMgr";
import {Ajax} from './../network/ajax';

export const sdkConfig: ISDKConfig = {
	serviceUrl: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : ''),
	socketUrl: (location.protocol === 'https:' ? 'wss://' : 'ws://') + location.hostname + (location.port ? ':' + location.port: ''),
	contextPath: '',
	accessToken: '',
	clientVersion: '',
	sessionId: '',
	isWebSdk: false,
	isMeetSdk: false,
	noUserBoard: false,
	// on some mobile safari, web-socket can not take cookies set by server, using http long polling instead
	// some android browser also have such issue
	// so for mobile do not use web socket 
	usingWebSocket: /iPhone|iPad|Android/.test(navigator.userAgent) ? false : true,
};

export function getBuildRevision(keepOriginalString= false): Promise<string> {
	let url = location.href.toString();
	let paths: string[];
	url = url.split('#')[0];
	url = url.replace('websdk/dist/', '');
	paths = url.split('/');
	paths.pop();
	paths.push(`build.txt?v=${Math.random()}`);
	url = paths.join('/');
	return Ajax.get(url).then((str: string) => {
		// let str = 'Rev: 195860, Time: 4/18/2019, 1:54:22 AM, URL: /branches/6.0/moxtra, Release: 6.0.9';
		if (str.indexOf('<') >= 0) {
			return '';
		}
		if (keepOriginalString) {
			return str
		}
		let release = str.split(',').pop();
		release = release.split(':').pop() || '';
		return release.trim();
	}).catch(e => {
		return '';
	})
}

export function getSocketUrl() {
	let params: Object = {
		'user.revision': 0,
		'group.revision': 0,
		'group.nomember': true,
		version: sdkConfig.clientVersion
	};

	if (sdkConfig.isMeetSdk || sdkConfig.noUserBoard) {
		params['user.noboard'] = true;
	}else {
		params['user.filtermeet'] = true;
	}

	if (currentUser) {
		params['user.revision'] = currentUser.user.revision;
	}

	if (currentOrg) {
		params['group.revision'] = currentOrg.group.revision;
	}

	if (location.protocol === 'http:' || sdkConfig['isUnitTest']) {
		// server's cookie has secure flag, only can be accessed by https, for http need to take access_token
		params['access_token'] = sdkConfig.accessToken;
	}

	let param = stringify(params);

	return sdkConfig.socketUrl + '/user?' + param;
}

export function getContextPath() {
	return sdkConfig.contextPath || '';
}

export function config(cfg?: ISDKConfig) {
	if (cfg) {
		Object.assign(sdkConfig, cfg);
	}

	if (!sdkConfig.clientVersion) {
		getBuildRevision().then(version => {
			sdkConfig.clientVersion = version;
		});
	}

	return sdkConfig;
}
