/**
 * Created by colin on 2019-11-25
 */
const fileExtensionReg = /[\'|\s]/i
export function getAvaliableName(originalName: string, existNames: string[]): string {
  if (existNames.indexOf(originalName) < 0) {
    return originalName;
  }
  let suffix, subSuffix,firstSuffix
  let name = originalName;
  let count = 1
  if (originalName.indexOf('.') > 0) {
    let temp = originalName.split('.')
    // suffix = temp.pop();
    firstSuffix = temp.pop();
    subSuffix = temp.pop()
    //let fileExpand = /tar|cpio/
    if(['tar', 'cpio'].indexOf(subSuffix) > -1){
      suffix = subSuffix+'.'+firstSuffix
    }else{
      suffix = firstSuffix
      temp.push(subSuffix)
    }

    if(fileExtensionReg.test(suffix)){
      // MVB-11652
      suffix = ''
    }else {
      name = temp.join('.')
    }
  }
  return computeName(name, suffix, count, existNames);
}

function computeName(name: string, suffix: string, count: number, existNames: string[]): string {
  let newName = `${name}_${count}`;
  if (suffix) {
    newName += `.${suffix}`
  }
  if (existNames.indexOf(newName) < 0) {
    return newName
  }
  count++;
  return computeName(name, suffix, count, existNames);
}
