/**
 * Created by colin on 2019-09-05
 */
import ServerErrorInfo from "../../defines/ServerErrorInfo";
import {CServerError} from "../CServerError";

export const getPatchMethod = function (method: Function, methodName: string): Function {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return function(...args) {

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const result = method.apply(this, args);
      result.then((response: any) => {
        resolve(response)
      }).catch((err: ServerErrorInfo|Error) => {
        if(err instanceof Error){
          reject(err)
        }else {
          reject(new CServerError(err))
        }
      });
    })
  };
};
const transformError = function(): Function {
  return function(target: object, methodName: string, descriptor: PropertyDescriptor) {
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, methodName);
    }

    const originalMethod = descriptor.value;

    descriptor.value = getPatchMethod(originalMethod, methodName);
    return descriptor;
  };
};
export  {
  transformError
}
