import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { ClientParam } from '../proto/generated/ClientParam';
import { Group } from '../proto/generated/Group';
import { GroupAccessType } from '../proto/generated/GroupAccessType';
import { UserType } from '../proto/generated/UserType';
import { User } from '../proto/generated/User';
import { SocialType } from '../proto/generated/SocialType';
import { UserRelation } from '../proto/generated/UserRelation';
import { UserIdentity } from '../api/defines';
import { Ajax } from "../network/ajax";
import { groupRequestNode, userRequestNode } from "../network/requestNode";
import { currentOrgId, currentUserId, currentUser } from '../data/cache/cacheMgr';
import { getByPath } from '../util';
import { createComment } from './comment';
import { getAccessToken } from './auth';
import { MxErr } from "../core/error";
import { getContextPath } from "../core/config";

function adminCreateRelation(rm: UserIdentity, customer: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: currentOrgId,
    members: [{
      user: {
        type: UserType.USER_TYPE_LOCAL,
        ...rm,
        relations: [{
          user: customer
        }]
      }
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CREATE_RELATION, group));
}

function adminUpdateRelation(rm: UserIdentity, relations: UserRelation[]): Promise<ClientResponse> {
  let group: Group = {
    id: currentOrgId,
    members: [{
      user: {
        ...rm,
        relations: relations
      }
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_RELATION, group));
}

function adminDeleteRelation(rm: UserIdentity, customer: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: currentOrgId,
    members: [{
      user: {
        ...rm,
        relations: [{
          user: customer
        }]
      }
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_DELETE_RELATION, group));
}

function adminTransferRelation(customer: UserIdentity, oldRm: UserIdentity, newRm: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: currentOrgId,
    members: [{
      user: {
        ...oldRm,
        relations: [{
          user: customer
        }]
      }
    }, {
      user: newRm
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_TRANSFER_RELATION, group));
}

function createRelation(user: User, noFeed?: boolean, welcomeMessage?: string): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params.push({
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    })
  };

  if (welcomeMessage) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_WELCOME_MESSAGE,
      string_value: welcomeMessage
    });
  }

  let group: Group = {
    id: currentOrgId,
    members: [{
      type: GroupAccessType.GROUP_MEMBER_ACCESS,
      user: {
        type: UserType.USER_TYPE_LOCAL,
        ...user
      }
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_INVITE_AND_CREATE_RELATION, group, params));
}

function confirmRelation(relationSequence: number, welcomeMessage: string = '', resendEmail?: boolean, resendSms?: boolean, noFeed: boolean = false): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    });
  };

  if (resendEmail) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_RESEND_EMAIL
    });
  }

  if (resendSms) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_RESEND_SMS
    });
  }

  let isRM = true;
  if (currentUser && currentUser.user.type === UserType.USER_TYPE_LOCAL) {
    isRM = false;
  }
  if (welcomeMessage && !isRM) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_WELCOME_MESSAGE,
      string_value: welcomeMessage
    });
  }

  let group: Group = {
    id: currentOrgId,
    members: [{
      user: {
        id: currentUserId,
        relations: [{
          sequence: relationSequence
        }]
      }
    }]
  };

  let p = Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CONFIRM_RELATION, group, params));
  p.then(response => {
    if (welcomeMessage && isRM) {
      let boardId: string = getByPath(response, 'object.user.boards.0.board.id');
      if (boardId) {
        createComment(boardId, {text: welcomeMessage});
      }
    }
  });
  return p;
}

function createRelationWithQRToken(qrToken: string, noRelationBoard: boolean = false, noFeed: boolean = false): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params.push({
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    })
  }

  if(noRelationBoard){
    params.push({
      name:ClientRequestParameter.GROUP_REQUEST_NO_RELATION_BOARD
    })
  }

  let user: User = {
    relations: [ {
      user: {
          qr_tokens: [ {
                  token: qrToken
              } ]
      }}
  ]}
  

  return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_CREATE_RELATION_VIA_QR_TOKEN, user, params));
}

function createQRToken(user?: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    members: [{
      user: user
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.USER_REQUEST_QR_TOKEN, user ? group : null));
}

// call REST API to create social connection
function createSocialConnection(type: SocialType, customer: User, resendInvitationCode?: boolean): Promise<ClientResponse> {
  return getAccessToken().then(response => {
    let domain = location.origin + getContextPath();
    let accessToken = response.data;
    let restUrl;
    let socialTypeStr = '';
    if (type === SocialType.SOCIAL_TYPE_WECHAT) {
      socialTypeStr = 'wechat';
    }else if (type === SocialType.SOCIAL_TYPE_LINE) {
      socialTypeStr = 'line';
    }else if (type === SocialType.SOCIAL_TYPE_WHATSAPP) {
      socialTypeStr = 'whatsapp';
    }else {
      return Promise.reject(MxErr.InvalidParam());
    }

    let userId = customer.id;
    if (userId) {
      restUrl = `${domain}/v1/users/${userId}/socialchannels/${socialTypeStr}`;
    }else {
      restUrl = `${domain}/v1/users/socialchannels/${socialTypeStr}`;
    }

    let payload = {
      user_id: customer.id,
      email: customer.email,
      first_name: customer.first_name,
      last_name: customer.last_name,
      phone_number: customer.phone_number,
    }
    return Ajax.post(restUrl, payload, {accessToken: accessToken});
  });
}

function reactivateSocialConnection(type: SocialType, boardId: string): Promise<ClientResponse> {
  return getAccessToken().then(response => {
    let domain = location.origin + getContextPath();
    let accessToken = response.data;
    let socialTypeStr = '';
    if (type === SocialType.SOCIAL_TYPE_WECHAT) {
      socialTypeStr = 'wechat';
    }else if (type === SocialType.SOCIAL_TYPE_LINE) {
      socialTypeStr = 'line';
    }else if (type === SocialType.SOCIAL_TYPE_WHATSAPP) {
      socialTypeStr = 'whatsapp';
    }else {
      return Promise.reject(MxErr.InvalidParam());
    }

    let restUrl = `${domain}/v1/users/socialchannels/reactivate/${socialTypeStr}`;

    let payload = {
      binder_id: boardId
    }
    return Ajax.post(restUrl, payload, {accessToken: accessToken});
  });
}

export {
  adminCreateRelation,
  adminUpdateRelation,
  adminDeleteRelation,
  adminTransferRelation,
  createRelation,
  confirmRelation,
  createRelationWithQRToken,
  createQRToken,
  createSocialConnection,
  reactivateSocialConnection,
}