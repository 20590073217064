// FixMe: Remove 'any' type in below codes

import { ObjectFeed } from 'isdk/src/proto/generated/ObjectFeed'
import { ObjectUtils } from '../../commonUtils/object'
import { ArrayUtils } from '../../commonUtils/array'
import { StringUtils } from '../../commonUtils/string'
import { BoardFormatter } from './board'
import { FileFormatter } from './file'
import { BoardResourceStatus } from 'isdk/src/proto/generated/BoardResourceStatus'
import { BoardPageType } from 'isdk/src/proto/generated/BoardPageType'
import { ObjectFeedType } from 'isdk/src/proto/generated/ObjectFeedType'
import { SessionStatus } from 'isdk/src/proto/generated/SessionStatus'
import { BoardSignatureStatus } from 'isdk/src/proto/generated/BoardSignatureStatus'
import { MxUser, MxISDK, Defines } from 'isdk'
import { CBoardUser } from '../defines/CBoardUser'
import { FeedBaseType } from '../defines/CBoardFeed'
import cloneDeep from 'lodash/cloneDeep'
import _assign from 'lodash/assign'
import _findIndex from 'lodash/findIndex'
import {BoardPageGroup} from "../../iSDK/src/proto/generated/BoardPageGroup";
import {BoardFolder} from "../../iSDK/src/proto/generated/BoardFolder";
import moment from 'moment'
import {BoardComment} from "../../iSDK/src/proto/generated/BoardComment";
import { BoardFolderType } from 'isdk/src/proto/generated/BoardFolderType';
import { BoardEditorType } from 'isdk/src/proto/generated/BoardEditorType'
import {BoardResource, UserType} from 'isdk/src/api/defines'

const baseMap = {
  FILE: 'board.page_groups.0',
  PAGES: 'board.pages.0',
  SIGNATURE: 'board.signatures.0',
  MEET: 'board.sessions.0',
  NOTE: 'board.sessions.0',
  TRANSACTION: 'board.transactions.0',
  TODO: 'board.todos.0',
  RELATIONSHIP: 'board.users.0',
  CALL: 'board.calls.0',
  VIEWTOKEN: 'board.view_tokens.0'
}

export function isObjectDeleted (obj: any) {
  if (!obj || obj.is_deleted) {
    return true
  }
  return false
}

function getFolderSequence(file:string) {
    //greedy get 789 from /123/456/789
    return parseInt(file.replace(/^.*\//, ''));
}

export class FeedUtils {
  static transformUpdateFeeds(updateFeeds: ObjectFeed[], board: Defines.Board) {
    const processedFeeds = updateFeeds
      .map(feed => FeedUtils.transformFeed(feed, board))
      .filter(feed => {
        const action = feed.Action
        let canPass: boolean = true
        if (action === 'REPLY_DELETE') {
          canPass = false
        }
        if (feed.isPagesCommentDelete && feed.RelatedType === 'POSITION_COMMENT' && ObjectUtils.getByPath(feed, 'relatedObject.is_deleted')) {
          canPass = false
        }
        switch (feed.BaseType) {
          case 'TODO':
            if (/UPDATE|DELETE_ATTACHMENT|DUE_DATE$/.test(action)) {
              canPass = false
            }
            break
          case 'MEET':
            if (!/^SCHEDULE|RESCHEDULE|CANCEL|RECORDING_READY|START|REPLY|END/.test(action)) {
              canPass = false
            }
            break
          case 'VIEWTOKEN':
            let tokenType = ObjectUtils.getByPath(feed.baseObject, 'actor_file_as.type')
            if (!tokenType || !/USER_TYPE_BOT|USER_TYPE_SERVICE/.test(tokenType)) {
              canPass = false
            }
            break
          case 'SIGNATURE':
            if (/ATTACHMENT|REPLY|STATUS_UPDATE|RENAME|^DELETE/.test(action)) {
              canPass = true
            }
            break
        }
        return canPass
      })
    return processedFeeds.map((feed: any) => {
      if (feed.baseObject) {
        if (feed.type.indexOf('_DELETE') > -1) {
          return feed
        } else if (!feed.baseObject.is_deleted) {
          if (!ObjectUtils.getByPath(feed, 'relatedObject.is_deleted')) {
            return feed
          } else {
            if (!feed.isTodoAttachment) {
              return {
                sequence: feed.sequence,
                is_deleted: true
              }
            } else {
              if (ObjectUtils.getByPath(feed, 'relatedObject.is_original_deleted')) {
                return {
                  sequence: feed.sequence,
                  is_deleted: true
                }
              } else {
                return feed
              }
            }
          }
        } else if (feed.baseObject.is_deleted) {
          if (feed.BaseType !== 'RELATIONSHIP' && !feed.isSessionSchedule && !feed.isSessionCancel) {
            return {
              sequence: feed.sequence,
              is_deleted: true
            }
          } else {
            return feed
          }
        }
      } else {
        return feed
      }
    })
  }

  static transformFeed (feed: ObjectFeed, board: Defines.Board) {
    const clonedFeed = cloneDeep(feed)
    const computedFeed = FeedUtils.computeFeedTypeInfo(clonedFeed, board)
    computedFeed.actor = BoardFormatter.transformBoardActor(feed.actor, board)
    if (computedFeed.baseObject) {
      computedFeed.baseObject.binderId = board.id
    }
    delete computedFeed.board
    return computedFeed
  }

  static transformFeeds (feeds: ObjectFeed[], board: Defines.Board) {
    const processedFeeds = feeds.map(feed => FeedUtils.transformFeed(feed, board))
    const filteredFeeds = processedFeeds.filter(FeedUtils.filterMainStreamFeed)
    return filteredFeeds
  }

  static transformIncompleteFeedsInfo (board: any) {
    let feeds = board.feeds ||[]

    return feeds.map((feed: ObjectFeed|any) => {
      let sequence = ObjectUtils.getByPath(feed, 'board.comments.0.sequence')
      if (sequence) {
        let comment = ArrayUtils.getFromArray(board.comments||[], sequence)
        feed.board.comments = [comment];
      }
      let pageCommentSequence = ObjectUtils.getByPath(feed, 'board.pages.0.comments.0.sequence')
      if (pageCommentSequence) {
        let pageComments = ObjectUtils.getByPath(ArrayUtils.getFromArray(board.pages||[], ObjectUtils.getByPath(feed, 'board.pages.0.sequence')), 'comments')
        if (pageComments)
          feed.board.pages[0].comments = [ArrayUtils.getFromArray(pageComments, pageCommentSequence)]
        }
        let userId = ObjectUtils.getByPath(feed, 'actor.id')
        if (userId) {
          let user = ArrayUtils.getFromArray(board.users||[], userId,'user.id');
          if (user) {
            feed.actor = BoardFormatter.transformBoardActor(feed.actor, board)
          }
        }

        sequence = ObjectUtils.getByPath(feed, 'board.todos.0.sequence')
        if (sequence) {
          let todo = ArrayUtils.getFromArray(board.todos||[], sequence)
          feed.board.todos = [todo]
        }
        sequence = ObjectUtils.getByPath(feed, 'board.transactions.0.sequence')
        if (sequence) {
          let transaction = ArrayUtils.getFromArray(board.transactions, sequence)
          feed.transactions = [transaction]
        }
        sequence = ObjectUtils.getByPath(feed, 'board.signatures.0.sequence')
        if (sequence) {
          let signature = ArrayUtils.getFromArray(board.signatures, sequence)
          signature.thumbnail = FileFormatter.getSignatureThumbnail(signature, board)
          feed.signatures = [signature]
        }

        sequence = ObjectUtils.getByPath(feed, 'board.pages.0.sequence')
        if (sequence) {
          let page = Object.assign({}, ArrayUtils.getFromArray(board.pages, sequence))
          let file
          let resource
          if (page.page_group) {
            file = ArrayUtils.getFromArray(board.files ||[], page.page_group, 'client_uuid')
          }
          let resSequence = page.original_resource_sequence || file && file.original
          if (resSequence) {
            resource = ArrayUtils.getFromArray(board.resources, resSequence)
          }
          page.name = FileFormatter.getFileName(file, page, resource)
          page.thumbnail = FileFormatter.getPageThumbnail(page, board, true)
          feed.pages = [page]
        }

        let feedFiles = FileFormatter.getAllFilesInBoard(feed.board)
        if (feedFiles.length) {
          let file = feedFiles[0]
          let orgFile = ObjectUtils.getBySPath(board,file.SPath)
          if (orgFile) {
            ObjectUtils.setByPath(feed.board, file.SPath, orgFile)
          }
        }
      return feed
    })
  }

  static filterMainStreamFeed (processedFeed: any) {
    let action = processedFeed.Action

    if (!processedFeed.type) {
      return false
    }

    let relatedObject = processedFeed.relatedObject
    let baseObject = processedFeed.baseObject
    // all delete reply feed should not display in main stream
    // remove base object the related feed should be removed  (exclude MEET feed and DELETE feed)
    if (processedFeed.Action === 'REPLY_DELETE') {
      return false
    }
    if (processedFeed.RelatedType === 'POSITION_COMMENT') {
      if (processedFeed.Action === 'COMMENT_DELETE' || ObjectUtils.getByPath(processedFeed, 'relatedObject.is_deleted')) {
        return false
      }
    }
    if (processedFeed.BaseType === 'FILE' && processedFeed.Action === 'CREATE') {
      if(!processedFeed.baseObject.sequence) {
        return false;
      }
    }
    if (baseObject && baseObject.is_deleted && processedFeed.BaseType !== 'RELATIONSHIP') {
      // handle base object delete case
      // relationship feed should show
      switch (processedFeed.BaseType) {
        case FeedBaseType.MEET:
          if (/SCHEDULE|CANCEL/.test(action)) {
            return true
          }
          return false
          break
      }
      if (/COMMENT_DELETE|^DELETE/.test(action)) {
        return true
      } else if (processedFeed.BaseType === 'FILE') {
        if (!FeedUtils.isFileMoveCase(processedFeed)) {
          return false
        }
      } else {
        return false
      }
    }
    if (action === 'REPLY' && (isObjectDeleted(relatedObject) || isObjectDeleted(baseObject))) {
      return false
    }
    switch (processedFeed.BaseType) {
      case 'TODO':
        if (action === 'ATTACHMENT') {
          // if attachment original file has removed the feed should not display
          if (!processedFeed.relatedObject.sequence) {
            return false
          }
          // create attachment always display
          return true
        }
        if (isObjectDeleted(baseObject)) {
          return false
        }
        if (/^CREATE|REPLY|^DELETE|COMPLETE|DELETE_ATTACHMENT|REOPEN|DUE_DATE_ARRIVE|ASSIGN/.test(action)) {
          if (relatedObject && isObjectDeleted(relatedObject)) {
            return false
          }
          return true
        } else {
          return false
        }
        break
      case 'MEET':
        if (/^SCHEDULE|RESCHEDULE|CANCEL|RECORDING_READY|START|REPLY|END/.test(action)) {
          return true
        } else {
          return false
        }
        break
      case 'VIEWTOKEN':
        if (isObjectDeleted(baseObject)) {
          return false
        }
        let tokenType = ObjectUtils.getByPath(baseObject, 'actor_file_as.type')
        if (!tokenType || !/USER_TYPE_BOT|USER_TYPE_SERVICE/.test(tokenType)) {
          return false
        }
        break
      case 'SIGNATURE':
        if (isObjectDeleted(baseObject)) {
          return false
        }
        if (/ATTACHMENT|REPLY|STATUS_UPDATE|RENAME|^DELETE/.test(action)) {
          if (action === 'ATTACHMENT' && isObjectDeleted(relatedObject)) {
            return false
          }
          return true
        }
        break
      case 'COMMENT':
        if (isObjectDeleted(baseObject) && processedFeed.Action !== 'DELETE') {
          return false
        }
        break
      default:

        break
    }
    return true
  }

  static computeFeedTypeInfo (feed: any, binderBasicInfo: Defines.Board, isAudit?:boolean) {
    let type = feed.type
    if (!type) {
      return feed
    }
    ObjectUtils.buildSPath(feed.board)
    // remove feed prefix
    let temp = type.split('_')

    temp.shift()
    const isProperty = StringUtils.transformCamelCase('is', temp)
    feed[isProperty] = true
    if (!feed.board.id) {
      feed.board.id = binderBasicInfo.id
    }
    let baseType = feed.BaseType = temp.shift()
    let action = feed.Action = temp.join('_')
    let board = feed.board

    if (type == 'FEED_BOARD_CREATE') {
      ObjectUtils.setByPath(feed, 'baseObject.name', binderBasicInfo.name)
      return feed
    }
    if (baseType === 'SESSION') {
      baseType = 'MEET'
    }
    if (type === 'FEED_AUDIO_CALL_LOG') {
      baseType = 'CALL'
      action = 'LOG'
    }

    let baseObject
    let path = baseMap[baseType]
    if (path) {
      baseObject = ObjectUtils.getByPath(feed, path)
      if (baseObject) {
        baseObject.SPath = path.replace('board.', '').replace('.0', '') + `[sequence=${baseObject.sequence}]`
      }
    }
    if (baseType === 'PAGES') {
      // 需要修正baseType -> FILE(有file或者folder的话base object一定是file)
      let isPageComment = false
      if (/COMMENT|ANNOTATION/.test(feed.Action) && board.pages) {
        isPageComment = true
      }
      if (!isPageComment && (board.page_groups || board.folders)) {
        baseType = 'FILE'

        if (baseObject && baseObject.file) {
          let page = baseObject
          let fileSPath = FeedUtils.serverPathToSpath(page.file)
          baseObject = ObjectUtils.getBySPath(feed.board, fileSPath) || {}
          baseObject.SPath = fileSPath

          let inFolder = fileSPath.indexOf('folders') >= 0
          if (inFolder) {
            baseObject.folderSpath = FeedUtils.serverPathToFolderSpath(page.file)
            let tmp = (page.file || '').split('/')
            tmp.pop()
            baseObject.folderSequence = tmp.pop()
            if (!baseObject.is_deleted && (feed.board.folders && feed.board.page_groups)) {
              baseObject.isMoved = true
            }
          }
        } else {
          path = []
          baseObject = FileFormatter.getFileInFolder(feed.board, path)
          if (!baseObject) {
            // removed no page file case cannot get file by getFileInFolder
            baseObject = ObjectUtils.getByPath(feed, baseMap.FILE)
            if (baseObject) {
              baseObject.SPath = baseMap.FILE.replace('board.', '').replace('.0', '') + `[sequence=${baseObject.sequence}]`
            }
          }
          if (baseObject) {
            baseObject.SPath = path.join('.')
          }
          if (board.folders) {
            let strPath: string[] = []
            baseObject = FileFormatter.getFileInFolder(board, strPath)
            if (baseObject) {
              let currentPos = board.folders[0]
              while (currentPos.folders) {
                currentPos = currentPos.folders[0]
              }
              const files = currentPos.files || currentPos.page_groups
              if (files && files.length) {
                baseObject.fileLength = files.length
              }
              baseObject.SPath = strPath.join('.')
              if (baseObject.SPath.indexOf('folders') >= 0) {
                let count = strPath.length - 1
                if (count > 0) {
                  count--
                }
                let info = ObjectUtils.parseSPath(strPath[count])
                baseObject.folderSequence = parseInt(info.attrVal, 10)
                strPath.pop()
                baseObject.folderSpath = strPath.join('.')
              }
            }
          }
          if (board.page_groups) {
            baseObject.fileLength = board.page_groups.length
          }
        }

        if (board.pages) {
          const page = board.pages[0]
          if (page.page_type === BoardPageType.PAGE_TYPE_WEB) {
            const currentUser = MxISDK.getCurrentUser()
            if (currentUser && currentUser.basicInfo.type === UserType.USER_TYPE_LOCAL) {
              baseObject.isEditable = false
            } else {
              baseObject.isEditable = true
            }
            if (page.editor_type === BoardEditorType.EDITOR_TYPE_INTERNAL_ONLY) {
              baseObject.editableType = 1
            } else {
              baseObject.editableType = 0
            }
          }
        }
      }
      if (!baseObject && board.resources) {
        baseObject = ObjectUtils.getByPath(board, 'resources.0')
        baseObject.SPath = `resources[sequence=${baseObject.sequence}]`
      }
      if (baseType === 'PAGES') {
        baseObject = FeedUtils.getRelatedPage(baseObject, binderBasicInfo, isAudit)
      }
    } else if (baseType === 'MEET') {
      baseObject = FeedUtils.getMeetBaseObject(feed, baseObject)
    } else if (baseType === 'RELATIONSHIP' || action === 'NAME_CHANGE') {
      baseObject = FeedUtils.getRelationBaseObject(feed, baseObject, action)
    }
    feed.baseObject = baseObject

    feed.BaseType = baseType
    let OriginalSequence = 0
    // should handle file / signature / meet/ transaction reply case
    if (/COMMENT|COMMENT_DELETE/.test(action)) {
      let relatedType = baseType

      if (baseType === 'BOARD') {
        FeedUtils.computeCommentFeedBaseObject(feed)
      } else if (/PAGES|TODO/.test(baseType)) {
        // handle page position comments logic
        let path = baseType === 'PAGES' ? 'board.pages.0.comments' : 'board.todos.0.comments'
        let comments = ObjectUtils.getByPath(feed, path) || []
        FeedUtils.computeCommentReplyFeedInfo(comments, feed)
      }
      if (feed.RelatedType === 'COMMENT' || feed.BaseType == 'POSITION_COMMENT') {
        action = action.replace('COMMENT', 'REPLY')
        if (feed.BaseType === 'POSITION_COMMENT') {
          // add relatedPage for feed display
          feed.relatedPage = FeedUtils.getRelatedPage(ObjectUtils.getByPath(feed, 'board.pages.0'), binderBasicInfo, isAudit)
        }
      } else if (feed.RelatedType === 'POSITION_COMMENT') {
        feed.relatedPage = FeedUtils.getRelatedPage(ObjectUtils.getByPath(feed, 'board.pages.0'), binderBasicInfo, isAudit)
      }
      if (feed.BaseType === 'BOARD') {
        feed.BaseType = 'COMMENT'
      }
      if (ObjectUtils.getByPath(feed, 'baseObject.resource_length') || ObjectUtils.getByPath(feed, 'relatedObject.resource_length')) {
        feed.SubType = 'AUDIO'
        FeedUtils.computeAudioCommentInfo(feed, isAudit)
      }
    }
    feed.Action = action
    FeedUtils.computeObjectInfo(feed, feed.baseObject, feed.BaseType, binderBasicInfo, isAudit)
    if (/PAGES|POSITION_COMMENT/.test(feed.BaseType)) {
      let strPath: string[] = []
      feed.relatedFile = FileFormatter.getFileInFolder(feed.board, strPath)
      feed.FilePath = strPath.join('.')
    }

    if (feed.BaseType === 'EMAIL') {
      FeedUtils.transformEmailFeed(feed, isAudit)
    }
    let pageSequence = ObjectUtils.getByPath(feed.board, 'pages.0.sequence')
    if (pageSequence) {
      if (!feed.baseObject) {
        feed.baseObject = {}
      }
      feed.baseObject.pageSequence = pageSequence
    }

    return feed
  }

  static computeAudioCommentInfo (feed: any, isAudit?: boolean) {
    let baseType = feed.BaseType
    let isAudioComment
    let isAudioReply
    let boardId = ObjectUtils.getByPath(feed, 'board.id')
    let resourceSequence
    let path: string;
    let prefix = isAudit?`${MxISDK.getContextPath()}/board/audit`:`${MxISDK.getContextPath()}/board`
    isAudioReply = ObjectUtils.getByPath(feed, 'relatedObject.resource_length')
    if (isAudioReply) {
      resourceSequence = ObjectUtils.getByPath(feed, 'relatedObject.resource')
      let baseObjectSequence = ObjectUtils.getByPath(feed, 'baseObject.sequence')
      if (baseType === FeedBaseType.PAGES) {
        path = `/${boardId}/${baseObjectSequence}/${resourceSequence}`
      } else if (baseType === FeedBaseType.TODO) {
        path = `/${boardId}/todo/${baseObjectSequence}/${resourceSequence}`
      } else {
        path = `/${boardId}/${resourceSequence}`
      }
      path = prefix + path;
      ObjectUtils.setByPath(feed, 'relatedObject.audioSource', path)
    }
    isAudioComment = ObjectUtils.getByPath(feed, 'baseObject.resource_length')

    if (isAudioComment) {
      resourceSequence = ObjectUtils.getByPath(feed, 'baseObject.resource')
      let baseObjectSequence = ObjectUtils.getByPath(feed, 'baseObject.sequence')
      let resource = ObjectUtils.getByPath(feed, 'board.resources.0')
      if (resource && resourceSequence === resource.sequence) {
        const isAmr = /.amr$/.test(resource.name)
        ObjectUtils.setByPath(feed, 'baseObject.isAmr', isAmr)
      }

      if (baseType === FeedBaseType.PAGES) {
        path = `/${boardId}/${baseObjectSequence}/${resourceSequence}`
      } else if (baseType === FeedBaseType.TODO) {
        path = `/${boardId}/todo/${baseObjectSequence}/${resourceSequence}`
      } else {
        path = `/${boardId}/${resourceSequence}`
      }
      path = prefix + path;
      ObjectUtils.setByPath(feed, 'baseObject.audioSource', path)
    }
  }



  static computeObjectInfo (feed: any, baseObject: any, baseType: string, binderObj: Defines.Board, isAudit?:boolean) {
    let page
    switch (baseType) {
      case 'FILE':
        page = ObjectUtils.getByPath(feed, baseMap.PAGES)
        let resource = FileFormatter.getFileResource(baseObject, feed.board)
        baseObject.fileType = FileFormatter.getFileType(resource, page)
        if (resource) {
          baseObject.isPasswordProtected = resource.is_password_protected || false
          baseObject.creator = FeedUtils.transformFlatBoardUser(resource.creator, binderObj)
          baseObject.totalPages = resource.total_pages
          baseObject.convertedPages = resource.converted_pages
          baseObject.isConvertable = resource.status !== BoardResourceStatus.BOARD_RESOURCE_STATUS_KEEP_UNCONVERTED
          baseObject.isConverted = resource.status === BoardResourceStatus.BOARD_RESOURCE_STATUS_CONVERTED
          baseObject.isConverting = resource.status === BoardResourceStatus.BOARD_RESOURCE_STATUS_CONVERTING || resource.status === BoardResourceStatus.BOARD_RESOURCE_STATUS_QUEUED
          baseObject.status = resource.status
        }
        if (page) {
          if (isAudit || !page.is_deleted) {
            feed.relatedPage = FeedUtils.getRelatedPage(page, binderObj, isAudit)
          }
          baseObject.thumbnail = FileFormatter.getPageThumbnail(page, feed.board, isAudit)
          if (page.page_type === BoardPageType.PAGE_TYPE_GEO) {
            baseObject.geoLocation = FileFormatter.getGeoLocation(page)
            if (baseObject.geoLocation) {
              baseObject.geoAddress = baseObject.geoLocation.address
            }
          }
          baseObject.creator = FeedUtils.transformFlatBoardUser(page.creator, binderObj)
          //   processEditorActor(page,boardId,baseObject)
        }
        break
      case 'PAGES':
        page = ObjectUtils.getByPath(feed, baseMap.PAGES)
        baseObject.thumbnail = FileFormatter.getPageThumbnail(baseObject, feed.board, isAudit)
        baseObject.creator = FeedUtils.transformFlatBoardUser(baseObject.creator, binderObj)
        baseObject.fileType = FileFormatter.getFileType(resource, page)
        // processEditorActor(page,boardId,baseObject)
        let file = FileFormatter.getFileInFolder(feed.board)
        if (file) {
          baseObject.name = file.name
        }
        break
      case 'NOTE':
      case 'MEET':
        let session = baseObject.session
        if (session) {
            baseObject.recording = session.recording ? `${MxISDK.getContextPath()}/board/${session.board_id}/${session.recording}`: ''
        }
        break
      case 'SIGNATURE':
        baseObject = FeedUtils.getSignatureBaseObject(feed, baseObject, binderObj, isAudit)
        break
      //   case 'TRANSACTION':
      //     computeTransactionInfo(baseObject, boardId);
      //     baseObject.attachments = transformAttachments(baseObject, feed.board)
      //     break;
      case 'TODO':
        if (feed.Action === 'ATTACHMENT') {
          let reference = ObjectUtils.getByPath(feed.baseObject, 'references.0')
          feed.relatedObject = FileFormatter.getFileByReference(reference, feed.board)
          if (!feed.relatedObject) {
            // Compatible with old data there only reference to the page
            let path: string[] = []
            feed.relatedObject = FileFormatter.getFileInFolder(feed.board, path)
            if (feed.relatedObject) {
              feed.relatedObject.SPath = path.join('.')
            } else {
              feed.relatedObject = {
                is_original_deleted: true,
                is_deleted: true
              }
            }
          }
          feed.RelatedType = 'FILE'
          if (reference.is_deleted == true) {
            feed.relatedObject.is_deleted = true
            if (!isAudit && feed.revision > reference.revision) {
              feed.Action = 'DELETE_ATTACHMENT'
            }
          }
        }
        FeedUtils.computeTodoAssignee(feed, binderObj)
        // need get the related object
        // computeTodoRelatedObject(feed)
        break
      case 'EMAIL':
        let spath: string[] = []

        baseObject = FileFormatter.getFileInFolder(feed.board, spath)
        // first update data is not include page
        if (baseObject) {
          baseObject.SPath = spath.join('.')
          let page = ObjectUtils.getByPath(feed, 'board.pages.0')
          if (page) {
            page.thumbnail = FileFormatter.getPageThumbnail(page, feed.board, isAudit)
            baseObject.thumbnail = page.thumbnail
          }
          feed.baseObject = baseObject
        }

        break
      case 'POSITION_COMMENT':
        page = ObjectUtils.getByPath(feed, baseMap.PAGES)
        if (page) {
          page.thumbnail = FileFormatter.getPageThumbnail(page, feed.board, isAudit)
        }
        break
      case 'TRANSACTION':
        FeedUtils.computeTransactionInfo(baseObject)
        baseObject.creator = FeedUtils.transformFlatBoardUser(baseObject.creator, binderObj)
        // calculate transaction step assignee info
        if (baseObject.steps) {
          for (let step of baseObject.steps) {
            step.assignee = step.assignee && BoardFormatter.transformBoardActor(step.assignee.user, binderObj)
          }
        }
        break
    }
  }

  static computeTransactionInfo(baseObject: any) {
    // parse transaction display_status and step.actions from string to object
    baseObject.display_status = (typeof baseObject.display_status === 'string') && JSON.parse(baseObject.display_status)
    let steps = baseObject.steps
    if (steps && steps.length) {
      for (let step of steps) {
        step.actions = (typeof step.actions === 'string') && JSON.parse(step.actions)
      }
    }
  }

  static transformEmailFeed (feed: any, isAudit:boolean) {
    const relatedObject: any = {}
    const page = ObjectUtils.getByPath(feed, 'board.pages.0')
    let originalResourceSequence = 0
    if (page) {
      originalResourceSequence = page.original_resource_sequence
    } else if (feed.baseObject) {
      feed.baseObject.isConvertFailed = true
    }
    let isBaseObjectDeleted = feed.baseObject.is_deleted || feed.baseObject.original !== originalResourceSequence
    const resources = ObjectUtils.getByPath(feed, 'board.resources')
    let allResourceSequence = []

    if (resources && resources.length > 0) {
      for (let resource of resources) {
        if (resource.from_email) {
          relatedObject.isEmptyEmail = resources[0].is_email_empty
          relatedObject.subject = resources[0].email_subject
          if (!originalResourceSequence) {
            originalResourceSequence = resource.sequence
          }
        }
        allResourceSequence.push(resource.sequence)
      }
    }

    feed.relatedObject = relatedObject
    const boardFolders = ObjectUtils.getByPath(feed, 'board.folders')
    if (boardFolders) {
      for (let i = 0; i < boardFolders.length; i++) {
        let currentFolder = boardFolders[i]
        while (currentFolder.folders) {
          currentFolder = currentFolder.folders[0]
        }
        if (currentFolder.files) {
          let movedFiles = currentFolder.files
          if (isAudit) {
            let resourceSequenceMap = resources.map(r=>r.sequence)
            movedFiles = currentFolder.files.filter(file=>{
              return resourceSequenceMap.indexOf(file.original) > -1
            })
          }
          movedFiles = movedFiles.filter(file => {
              if (isBaseObjectDeleted) {
                if (file.original === originalResourceSequence) {
                  const pageThumbnail = feed.baseObject.thumbnail
                  feed.baseObject = file
                  feed.baseObject.thumbnail = pageThumbnail
                  feed.baseObject.isMoved = true
                  isBaseObjectDeleted = false
                  return false
                } else {
                  return !file.is_deleted
                }
              } else {
                return file.original !== originalResourceSequence && !file.is_deleted
              }
            })
            .map(file => {
              if (currentFolder.folder_type !== BoardFolderType.FOLDER_TYPE_EMAIL) {
                file.isMoved = true
              }
              return file
            })
          if (feed.relatedAttachments) {
            feed.relatedAttachments = feed.relatedAttachments.concat(movedFiles)
          } else {
            feed.relatedAttachments = movedFiles
          }
        }
      }
    }
    const boardPageGroups = ObjectUtils.getByPath(feed, 'board.page_groups')
    if (boardPageGroups) {
      if (!feed.relatedAttachments) {
        feed.relatedAttachments = []
      }
      boardPageGroups.forEach(pageGroup => {
        if (pageGroup) {
          if (!isBaseObjectDeleted) {
            if (!pageGroup.is_deleted && pageGroup.original !== originalResourceSequence) {
              feed.relatedAttachments.push(pageGroup)
            }
          } else {
            if (pageGroup.original === originalResourceSequence) {
              const pageThumbnail = feed.baseObject.thumbnail
              feed.baseObject = pageGroup
              feed.baseObject.thumbnail = pageThumbnail
              feed.baseObject.isMoved = true
              isBaseObjectDeleted = false
            } else if (!pageGroup.is_deleted) {
              feed.relatedAttachments.push(pageGroup)
            }
          }
        }
      })
    }
    if (feed.relatedAttachments) {
      if (feed.relatedAttachments.length > 1) {
        feed.relatedAttachments.sort((a, b) => {
          return allResourceSequence.indexOf(a.original) - allResourceSequence.indexOf(b.original)
        })
      }
    }
  }

  static getRelationBaseObject (feed: any, baseObject: any, action: string) {
    if (!baseObject) {
      baseObject = {}
    }
    const board: Defines.Board = feed.board
    const boardUsers: Defines.BoardUser[] = board.users
    let cUsers: CBoardUser[] = []
    let hasBotUser: boolean = false
    let hasNonActorUser: boolean = false
    if (boardUsers) {
      for (let boardUser of boardUsers) {
        const tmpCUser = BoardFormatter.transformBoardUser(boardUser, board.id)
        if (tmpCUser.isBot) {
          hasBotUser = true
        }
        if (tmpCUser.id !== feed.actor.id) {
          hasNonActorUser = true
        }
        cUsers.push(tmpCUser)
      }
      cUsers = boardUsers.map((boardUser: any) => {
        return BoardFormatter.transformBoardUser(boardUser, board.id)
      })
      baseObject.users = cUsers
    }
    switch (action) {
      case 'LEAVE':
      case 'JOIN':
        baseObject.hasBotUser = hasBotUser
        baseObject.hasNonActorUser = hasNonActorUser
        baseObject.invitees = cUsers.map((user: CBoardUser) => user.name).join(', ')
        break
      case 'NAME_CHANGE':
        baseObject.binderName = feed.board.name
        break
      case 'REMOVE':
        baseObject.hasBotUser = hasBotUser
        break
    }

    return baseObject
  }

  static getSignatureBaseObject (feed: any, baseObject: any, binderObj: Defines.Board, isAudit?:boolean) {
    baseObject.thumbnail = FileFormatter.getSignatureThumbnail(baseObject, feed.board, isAudit)
    if (baseObject.signees) {
      baseObject.signees = baseObject.signees.map((signee: Defines.BoardSignee) => {
        let convertedSignee = signee
        if (!signee.is_deleted) {
          convertedSignee = _assign(convertedSignee, BoardFormatter.transformBoardActor(signee.actor.user, binderObj))
        }
        return convertedSignee
      })
    }

    switch (feed.type) {
      case ObjectFeedType.FEED_SIGNATURE_RENAME:
        baseObject.isRenamed = true
        break
      case ObjectFeedType.FEED_SIGNATURE_DELETE:
        baseObject.isDeleted = true
        break
    }

    if (baseObject.status === BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED) {
      const actor: Defines.User = feed.actor
      if (baseObject.signees && baseObject.signees.length === 1 &&
        (baseObject.signees[0].id === actor.id || (actor.email && baseObject.signees[0].email === actor.email))) {
        baseObject.isSigned = true
      } else {
        baseObject.isCompleted = true
      }
    } else if (baseObject.status === BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS) {
      baseObject.isBased = true
    } else if (baseObject.status === BoardSignatureStatus.SIGNATURE_STATUS_DECLINED) {
      baseObject.isDeclined = true
    } else {
      baseObject.isSigned = true
    }

    if (baseObject.pages) {
      const basePage = baseObject.pages[0]
      baseObject.isWhiteBoard = basePage.page_type === BoardPageType.PAGE_TYPE_WHITEBOARD
      baseObject.width = basePage.width
      baseObject.height = basePage.height
    }
  }

  static getMeetBaseObject (feed: any, baseObject: any) {
    if (baseObject.session) {
      baseObject = _assign(baseObject, baseObject.session)
      if (baseObject.session_status === SessionStatus.SESSION_STARTED) {
        baseObject.isActive = true
      } else if (baseObject.session_status === SessionStatus.SESSION_ENDED) {
        baseObject.isEnded = true
      }
      delete baseObject['session']
    }

    switch (feed.type) {
      case ObjectFeedType.FEED_SESSION_SCHEDULE:
        baseObject.isScheduled = true
        break
      case ObjectFeedType.FEED_SESSION_START:
        baseObject.isStarted = true
        break
      case ObjectFeedType.FEED_SESSION_CANCEL:
        baseObject.isCanceled = true
        break
      case ObjectFeedType.FEED_SESSION_RENAME:
        baseObject.isRenamed = true
        break
    }

    return baseObject
  }

  static getRelatedPage (page: any, binderBasicInfo: Defines.Board, isAudit?:boolean) {
    if (isAudit || (!page.is_deleted && page.page_type)) {
      const pageTypes: Array<string> = page.page_type.split('_')
      const isProperty: string = StringUtils.transformCamelCase('is', [pageTypes[2]])
      if (page.editor_time) {
        page.editor = BoardFormatter.transformBoardActor(page.editor_actor.user, binderBasicInfo)
        page.isLocked =  (Date.now() - page.editor_time) < 5 * 60 * 1000;
      }
      if (page.page_type === BoardPageType.PAGE_TYPE_WEB) {
        if (page.editor_type === BoardEditorType.EDITOR_TYPE_INTERNAL_ONLY) {
          page.editableType = 1
        } else {
          page.editableType = 0
        }
      }
      page[isProperty] = true
    }
    return page
  }

  static computeCommentReplyFeedInfo (comments: any, feed: any) {
    // we should handle three case : 1. board comment/reply 2. page comment 3. page position comment
    let n = comments.length
    // for comment replay will return two comments, so latest one is current base object
    if (feed.BaseType == 'BOARD') {
      feed.baseObject = comments[0]
    } else {
      feed.RelatedType = 'COMMENT'
    }

    let comment = comments[n - 1]
    let spath = []
    if (feed.BaseType === 'TODO') {
      spath.push(`todos[sequence=${feed.baseObject.sequence}]`)
    } else if (feed.BaseType === 'PAGES') {
      spath.push(`pages[sequence=${feed.baseObject.sequence}]`)
    }
    spath.push(`comments[sequence=${comment.sequence}]`)
    comment.SPath = spath.join('.')
    if (feed.RelatedType === 'COMMENT') {
      feed.relatedObject = comment
    }

    if (n > 1) {
      feed.baseObject = comments[0]
      feed.relatedObject = comment
      feed.BaseType = 'COMMENT'
      feed.RelatedType = 'COMMENT'
    }
    if (comment.is_position_comment) {
      feed.RelatedType = 'POSITION_COMMENT'
      if (n > 1) {
        feed.BaseType = 'POSITION_COMMENT'
      }
    }
  }

  static computeCommentFeedBaseObject (feed: any) {
    let keys = Object.keys(baseMap)
    let comments = ObjectUtils.getByPath(feed, 'board.comments')
    let item
    let key
    let baseObject

    let relatedObject = comments[0]
    if (relatedObject) {
      relatedObject.SPath = `comments[sequence=${relatedObject.sequence}]`
      feed.relatedObject = relatedObject
    }
    if (relatedObject.original_reference_link && feed.board.reference_links) {
      const feedBoard: Defines.Board = feed.board
      const referenceLinkIndex = _findIndex(feedBoard.reference_links, link => link.sequence === relatedObject.original_reference_link)
      if (referenceLinkIndex > -1) {
        const referenceLink = feedBoard.reference_links[referenceLinkIndex]
        if (!referenceLink.is_deleted) {
          if (ObjectUtils.getByPath(referenceLink, 'board.folders')) {
            let currentReferenceFolder = referenceLink.board.folders[0]
            let referenceFilePath = 'folders.0'
            while (currentReferenceFolder.folders) {
              currentReferenceFolder = currentReferenceFolder.folders[0]
              referenceFilePath += '.folders.0'
            }
            referenceFilePath += '.files.0'
            baseObject = ObjectUtils.getByPath(feedBoard, referenceFilePath)
          } else if (ObjectUtils.getByPath(referenceLink, 'board.page_groups')) {
            const referenceFileSequence = ObjectUtils.getByPath(referenceLink, 'board.page_groups.0.sequence')
            const referenceFileIndex = _findIndex(feedBoard.page_groups, pg => pg.sequence === referenceFileSequence)
            if (referenceFileIndex > -1) {
              baseObject = feedBoard.page_groups[referenceFileIndex]
            }
          }
        } else {
          if (feedBoard.page_groups) {
            baseObject = feedBoard.page_groups[0]
          } else if (feedBoard.folders) {
            let currentFileFolder = feedBoard.folders[0]
            while (currentFileFolder.folders) {
              currentFileFolder = currentFileFolder.folders[0]
            }
            baseObject = currentFileFolder.files[0]
          }
        }
      }
      feed.baseObject = baseObject
      feed.BaseType = 'FILE'
      feed.RelatedType = 'COMMENT'
    } else {
      for (let i = 0; i < keys.length; i++) {
        key = keys[i]
        item = baseMap[key]
        baseObject = ObjectUtils.getByPath(feed, item)
        if (baseObject) {
          baseObject.SPath = item.replace('board.', '').replace('.0', '') + `[sequence=${baseObject.sequence}]`
          if (key !== 'MEET') {
            feed.baseObject = baseObject
          } else {
            feed.baseObject = FeedUtils.getMeetBaseObject(feed, baseObject)
          }
          feed.BaseType = key
          feed.RelatedType = 'COMMENT'
          return
        }
      }

      let hasFolder = ObjectUtils.getByPath(feed, 'board.folders')
      if (hasFolder) {
        let strPath: string[] = []
        baseObject = FileFormatter.getFileInFolder(feed.board, strPath)
        if (baseObject) {
          baseObject.SPath = strPath.join('.')
          feed.baseObject = baseObject
          feed.BaseType = 'FILE'
          feed.RelatedType = 'COMMENT'
          return
        }
      }

      FeedUtils.computeCommentReplyFeedInfo(comments, feed)
    }
  }

  static computeTodoAssignee (feed: any, binderObj: Defines.Board) {
    let todo = feed.baseObject
    let user = ObjectUtils.getByPath(todo, 'assignee.user')
    let group = ObjectUtils.getByPath(todo, 'assignee.group')
    let transformUser
    if (user) {
      transformUser = BoardFormatter.transformBoardActor(user, binderObj)
    } else if (todo.assignee_sequence) {
      //   let boardId = ObjectUtils.getByPath(feed, 'board.id');
      //   let board = getCachedBoard(boardId);
      //   user = board.get(todo.assignee_sequence);
      //   if (user) {
      //     transformUser = BoardFormatter.transformBoardActor(user, feed.board.id)
      //   }
    }
    todo.assignee = transformUser
  }

  static transformFlatBoardUser (boardUser: any, binderObj: Defines.Board) {
    let result: any = {}
    if (!boardUser) {
      return result
    }
    let user = boardUser.user

    if (user) {
      result = BoardFormatter.transformBoardActor(user, binderObj)
    }
    return result
  }

  static isFileMoveCase (feed:any) {
    let resource = ObjectUtils.getByPath(feed, 'board.resources.0')
    if (resource && !resource.is_deleted) {
      return true
    }

    if (!feed.baseObject.is_deleted) {
      return false
    }
    // has preview case
    let page = ObjectUtils.getByPath(feed, 'board.pages.0')
    if (page) {
      if (!page.is_deleted) {
        return true
      }
    }
    return false
  }

  static serverPathToFolderSpath (path:string) {
    if (!path) {
      return ''
    }
    let seqs = path.split('/')
    seqs.shift()
    if (seqs.length === 1) {
      return ''
    }
    seqs.pop()
    let filePath = seqs.map((seq:string) => {
      return `folders[sequence=${seq}]`
    })
    return filePath.join('.')
  }

  static serverPathToSpath (path:string) {
    if (!path) {
      return ''
    }
    let seqs = path.split('/')
    let fileSeq
    seqs.shift()
    if (seqs.length === 1) {
      fileSeq = seqs[0]
      return `page_groups[sequence=${fileSeq}]`
    }
    fileSeq = seqs.pop()
    let filePath = seqs.map((seq:string) => {
      return `folders[sequence=${seq}]`
    })
    filePath.push(`files[sequence=${fileSeq}]`)
    return filePath.join('.')
  }

  static transformAuditFeeds(feeds: ObjectFeed[], auditBoard: Defines.Board){
    const fields =['transactions','pages', 'page_groups', 'comments', 'folders','resources', 'signatures', 'todos', 'sessions', 'users', 'reference_links'];
    let map = {};
    let results:Array<any> = []
    function flattenFolder (folders:BoardFolder[]) {
        folders.forEach((f:BoardFolder)=>{
            if (f.files) {
              f.files.forEach((pageGroup:BoardPageGroup)=>{
                map[pageGroup.sequence] = pageGroup;
              })
            }
            if (f.folders) {
                flattenFolder(f.folders);
            }
        })
    }
    fields.forEach((field:string)=>{
        auditBoard[field] && auditBoard[field].forEach((node:any)=>{
            map[node.sequence] = node
        });
        if (field === 'folders' && auditBoard.folders){
            flattenFolder(auditBoard.folders);
        }
    });
    feeds.forEach(feed=>{
        let feedBoard:any = cloneDeep(feed.board);
        let originalFeedBoard: object = feed.board
        let cloneFeed:any = cloneDeep(feed);
        let lastFeed = results[results.length - 1]
        let pageComments: BoardComment[]
        if (lastFeed) {
            let previousFeedTime = moment(lastFeed.created_time)
            let currentFeedTime = moment(feed.created_time)
            if (!previousFeedTime.isSame(currentFeedTime, 'day')) {
                results.push({
                    'sequence': currentFeedTime,
                    'fakeFeed': true,
                    'created_time': currentFeedTime,
                    'type': 'timeitem',
                    'isTimeItem': true
                })
            }
        } else {
            results.push({
                'sequence': auditBoard.created_time,
                'fakeFeed': true,
                'created_time': auditBoard.created_time,
                'type': 'timeitem',
                'isTimeItem': true
            })
        }
        let page = map[ObjectUtils.getByPath(originalFeedBoard, 'pages.0.sequence')];
        if (page && page.comments) {
            pageComments = cloneDeep(page.comments)
        }
        let commentSequence:number = ObjectUtils.getByPath(originalFeedBoard, 'pages.0.comments.0.sequence');
        fields.forEach((field:string)=>{
            if (feedBoard[field]) {
                let node = map[ObjectUtils.getByPath(feedBoard[field], `0.sequence`)];
                if (node){
                    let fullNode = cloneDeep(node)
                    if (field === 'pages') {
                      if (commentSequence) {
                        //audit board board.pages hold all the comments.
                        let nodeComment = ArrayUtils.getFromArray(pageComments, commentSequence)
                        if (nodeComment) {
                          fullNode.comments = [nodeComment]
                          if (nodeComment.original_comment) {
                            fullNode.comments.unshift(ArrayUtils.getFromArray(pageComments, nodeComment.original_comment))
                          }
                        }
                      }
                    }
                    feedBoard[field] = [fullNode];
                }
                if (node && node.original_comment){
                    //reply comment: original comment is 0 and reply comment is 1
                    feedBoard[field].unshift(map[node.original_comment])
                }
                if (node && node.from_email) {
                  auditBoard.resources.forEach((resource:BoardResource)=>{
                    if (resource.original_resource_sequence === node.sequence) {
                      feedBoard[field].push(resource)
                    }
                  })
                }
                if (node && node.original_session) {
                    feedBoard.sessions = [map[node.original_session]]
                }
                if (field === 'todos') {
                  //todo reference board
                  let referenceBoard = ObjectUtils.getByPath(feedBoard[field], `0.references.0.board`)
                  if (referenceBoard) {
                    ['pages', 'page_groups', 'folders', 'reference_links', 'resources'].forEach((f:string)=>{
                      if (referenceBoard[f]) {
                        let sequence = ObjectUtils.getByPath(referenceBoard[f], `0.sequence`);
                        if (sequence && map[sequence]) {
                            feedBoard[f] = [map[sequence]]
                        }
                        if (f === 'reference_links') {
                            referenceBoard[f].forEach((referenceLink:any)=>{
                                let pageGroupSequence: number = ObjectUtils.getByPath(map[referenceLink], 'board.page_groups.0');
                                if (pageGroupSequence) {
                                    feedBoard.page_groups = map[pageGroupSequence]
                                }
                            })
                        }
                      }
                    })
                  }
                }
            }
        });
        cloneFeed.board = feedBoard;
        if (feedBoard.pages && !feedBoard.page_groups) {
          //get related page group in annotation and position comment feed
            let seq = getFolderSequence(ObjectUtils.getByPath(feedBoard.pages, `0.file`))
            if (seq && map[seq]) {
                feedBoard.page_groups = [map[seq]];
            }
        }
        try {
            const computedFeed = FeedUtils.computeFeedTypeInfo(cloneFeed, auditBoard, true);
            if (computedFeed.baseObject) {
                computedFeed.baseObject.binderId = auditBoard.id
            }
            computedFeed.actor = BoardFormatter.transformBoardActor(feed.actor, auditBoard);
            computedFeed.auditBoard = originalFeedBoard;
            delete computedFeed.board;
            results.push(computedFeed)
        } catch (e) {
            console.log('parse audit feed error:', e, feed, auditBoard.id);
        }
    })
    return results
  }

  static needDescriptionFeed (binderBasicInfo: Defines.Board, hasNextPage: boolean): boolean {
    if (hasNextPage) {
      return false
    }
    if (binderBasicInfo.is_channel_subscription || binderBasicInfo.is_service_request) {
      return false
    }
    return binderBasicInfo.description !== undefined && binderBasicInfo.description !== ''
  }

  static generateDescriptionFeed (binderBasicInfo: Defines.Board, firstFeedCreateTime: number): ObjectFeed {
    let feed:ObjectFeed = {}
    feed['baseObject'] = {
      'text': binderBasicInfo.description,
    }
    feed['isDescription'] = true
    feed['sequence'] = 0
    feed['created_time'] = firstFeedCreateTime - 1000
    return feed
  }
}
