/**
 * Created by colin on 2019-09-03
 */
// const emailRegex = /^[a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+$/i;
const emailRegex = /^[_a-z0-9\-']+(\.[_a-z0-9\-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;

export class StringUtils {
  static isEmail(email: string) {
    return emailRegex.test(email);
  }

  static transformCamelCase (prefix: string, wordings: Array<string>): string {
    let result: string = prefix
    for (let wording of wordings) {
      result += wording[0].toUpperCase() + wording.slice(1).toLowerCase()
    }
    return result
  }

  static trim(str: string): string {
    return str && str.trim()
  } 

}
