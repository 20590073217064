export enum BoardResourceStatus {
  BOARD_RESOURCE_STATUS_NONE = 'BOARD_RESOURCE_STATUS_NONE',
  BOARD_RESOURCE_STATUS_QUEUED = 'BOARD_RESOURCE_STATUS_QUEUED',
  BOARD_RESOURCE_STATUS_CONVERTING = 'BOARD_RESOURCE_STATUS_CONVERTING',
  BOARD_RESOURCE_STATUS_CONVERTED = 'BOARD_RESOURCE_STATUS_CONVERTED',
  BOARD_RESOURCE_STATUS_CONVERT_FAILED = 'BOARD_RESOURCE_STATUS_CONVERT_FAILED',
  BOARD_RESOURCE_STATUS_KEEP_UNCONVERTED = 'BOARD_RESOURCE_STATUS_KEEP_UNCONVERTED',
  BOARD_RESOURCE_STATUS_TOO_MANY_PAGES = 'BOARD_RESOURCE_STATUS_TOO_MANY_PAGES',
  BOARD_RESOURCE_STATUS_TOO_LARGE = 'BOARD_RESOURCE_STATUS_TOO_LARGE',
  BOARD_RESOURCE_STATUS_INVALID_PASSWORD = 'BOARD_RESOURCE_STATUS_INVALID_PASSWORD'
}
