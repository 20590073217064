import { SystemConfig } from './../proto/generated/SystemConfig';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { requestNode, userRequestNode } from "../network/requestNode";
import { ClientRequest } from '../proto/generated/ClientRequest';
import { ClientParam } from '../proto/generated/ClientParam';
import { User } from '../proto/generated/User';
import { Group } from '../proto/generated/Group';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { GroupIntegrationType } from '../proto/generated/GroupIntegrationType';
import { Ajax } from "../network/ajax";
import { UserIdentity } from './../api/defines';

function readUser(user: UserIdentity): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.SERVER_USER_READ, user));
}

function enableUser(userId: string): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.SERVER_OBJECT_WRITE_ID,
        string_value: userId
    }];

    return Ajax.sendRequest(requestNode(ClientRequestType.SERVER_USER_ENABLE, params));
}

function disableUser(userId: string): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.SERVER_OBJECT_WRITE_ID,
        string_value: userId
    }];

    return Ajax.sendRequest(requestNode(ClientRequestType.SERVER_USER_DISABLE, params));
}

function readSystemConfig(): Promise<ClientResponse> {
    return Ajax.sendRequest(requestNode(ClientRequestType.SERVER_SYSTEM_CONFIG_READ));
}

function updateSystemConfig(config: SystemConfig): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.SERVER_SYSTEM_CONFIG_UPDATE, 
        object: {
            system_config: config
        }
    };

    return Ajax.sendRequest(req);
}

function listAvailableBaseDomains(): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_LIST_AVAILABLE_BASE_DOMAINS
    };

    return Ajax.sendRequest(req);
}

function checkDomainAvailability(domain: string): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_CHECK_DOMAIN_AVAILABILITY,
        object: {
            group: {
                integrations: [{
                    type: GroupIntegrationType.GROUP_INTEGRATION_LOCAL,
                    domain: domain
                }]
            }
        }
    };

    return Ajax.sendRequest(req);
}

function registerGroup(user: User, group: Group): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_REGISTER,
        object: {
            user: user,
            group: group
        }
    };

    return Ajax.sendRequest(req);
}

function cancelGroup(groupId: string): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_CANCEL,
        object: {
            group: {
                id: groupId
            }
        }
    }
    return Ajax.sendRequest(req);
}

function reactivateCancelGroup(groupId: string): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_UPDATE,
        object: {
            group: {
                id: groupId,
                cancellation_request_time: 0
            }
        }
    }
    return Ajax.sendRequest(req);
}

function createGroupTagWithAccessToken(groupId: string, tags: Object, accessToken: string): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_UPDATE,
        object: {
            group: {
                id: groupId,
                tags: []
            }
        }
    };

    for(let tagName in tags){
        let tagVal = tags[tagName];
        req.object.group.tags.push({
            name: tagName,
            string_value: tagVal})
    }

    return Ajax.sendRequest(req, {accessToken: accessToken});

}

function sendFeedback(user: User, subject: string, message: string, isSystemFeedback?: boolean): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.USER_REQUEST_FEEDBACK_SUBJECT,
        string_value: subject
    }, {
        name: ClientRequestParameter.USER_REQUEST_FEEDBACK_MESSAGE,
        string_value: message
    }];

    let reqType: ClientRequestType = isSystemFeedback ? ClientRequestType.USER_REQUEST_SYSTEM_FEEDBACK : ClientRequestType.USER_REQUEST_FEEDBACK;

    return Ajax.sendRequest(userRequestNode(reqType, user, params));
}

export {
    readUser,
    enableUser,
    disableUser,
    readSystemConfig,
    updateSystemConfig,

    listAvailableBaseDomains,
    checkDomainAvailability,
    registerGroup,
    cancelGroup,
    reactivateCancelGroup,
    createGroupTagWithAccessToken,

    sendFeedback,
} 