import { Partner } from './../proto/generated/Partner';
import { User } from '../proto/generated/User';
import { Group } from '../proto/generated/Group';
import { Board } from '../proto/generated/Board';
import { ActionObject } from './../proto/generated/ActionObject';
import { SystemPasswordRule } from './../proto/generated/SystemPasswordRule';
import { TelephonyDomain } from '../proto/generated/TelephonyDomain';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { MxObjectFactory } from './mxObjectFactory';
import { MxMeetLogger } from './mxMeetLogger';
import { mxAnonymousUser } from './mxAnonymousUserImpl';
import { Ajax } from './../network/ajax';
import { Connection } from './../network/connection';
import { MxCallback, MxUserState, MxTokenType, MxError, MxVerifyCodeAction, MxInitMeetOption } from './../api/defines';
import { ISDKConfig, MxNetworkState, LoginOption, MxSubscription } from "../api/defines";
import { config, sdkConfig } from './config';
import { MxErr } from './error';
import { getByPath, mxLogger, cloneObject } from '../util';
import { MxObservable } from './mxObserver';
import { MxMeet } from '../api/mxMeet';
import { MxISDK } from './../api/mxISDK';
import { MxUser } from '../api/mxUser';
import { MxGroup } from '../api/mxGroup';
import { MxBoard } from '../api/mxBoard';
import * as cacheMgr from '../data/cache/cacheMgr';
import * as bauth from '../biz/auth';
import * as buser from '../biz/user';
import * as bgroup from '../biz/group';
import * as bmeet from '../biz/meet';
import * as bsystem from '../biz/system';

export const _globalErrorObservable = new MxObservable<MxError>();

export function publishError(err: MxError) {
    _globalErrorObservable.publish(err);
}

class MxISDKImpl implements MxISDK
{
    private mxMeetLogger: MxMeetLogger = new MxMeetLogger();

    private _anonymousContext: boolean = false;

    private _readOrgPromise: Promise<any> = null;

    constructor() {
        this.initialize();
        Ajax.waitingForPromise(this.readOrgInfo(), 5000);
    }

    initialize(conf?: ISDKConfig): void {
        config(conf);
    }

    getISDKConfig(): ISDKConfig {
        return cloneObject(sdkConfig);
    }

    getContextPath(): string {
        return sdkConfig.contextPath || '';
    }

    getCurrentUser(): MxUser {
        if(this._anonymousContext)
            return this.getAnonymousUser()
        return cacheMgr.currentUser;
    }

    getCurrentOrg(): MxGroup {
        if(this._anonymousContext)
            return cacheMgr.anonymousOrg
        return cacheMgr.currentOrg;
    }

    getCurrentMeet(): MxMeet {
        return cacheMgr.currentMeet;
    }

    getCurrentSessionId(): string {
        return cacheMgr.currentSessionId;
    }

    getAnonymousUser(): MxUser {
        return mxAnonymousUser;
    }

    networkState(): MxNetworkState {
        return Connection.getInstance().networkState;
    }

    subscribeNetworkState(cb: MxCallback<MxNetworkState>): MxSubscription {
        return Connection.getInstance().subscribeNetworkState(cb);
    }

    subscribeUserState(cb: MxCallback<MxUserState>): MxSubscription {
        return cacheMgr.subscribeUserState(cb);
    }

    subscribeError(cb: MxCallback<MxError>): MxSubscription {
        return _globalErrorObservable.subscribe(cb);
    }

    verifyToken(opt?: LoginOption): Promise<MxUser> {
        if (opt && opt.notLoadFullUser) {
            let user: User = null;
            let group: Group = null;
            return bauth.verifyToken().then((response: ClientResponse) => {
                user = response.object.user;
                return this.readOrgInfo();
            }).then(mxOrg => {
                group = mxOrg.group;
                cacheMgr.onUserLoggedIn(user, group, null);
                return cacheMgr.currentUser;
            })
        }else {
            return bauth.login(opt);
        }
    }

    login(opt?: LoginOption): Promise<MxUser> {
        return bauth.login(opt);
    }

    loginWithAccessToken(token: string): Promise<MxUser> {
        return bauth.loginWithAccessToken(token);
    }

    logout(logoutAllDevice?: boolean): Promise<void> {
        return bauth.logout(logoutAllDevice);
    }

    register(user: User, token?: string, isPartnerAdmin?: boolean): Promise<MxUser> {
        return bauth.registerUser(user, token, isPartnerAdmin);
    }

    resetPassword(email: string): Promise<void> {
        return buser.resetPassword(email);
    }
    
    resetPasswordWithPhoneNum(phoneNum: string, verifyCode: string, password: string): Promise<void> {
        return buser.resetPasswordWithPhoneNum(phoneNum, verifyCode, password);
    }

    resetPasswordWithEmail(email: string, verifyCode: string, password: string): Promise<void> {
        return buser.resetPasswordWithEmail(email, verifyCode, password);
    }

    resetPasswordWithToken(pass: string, token: string): Promise<void> {
        return buser.resetPasswordWithToken(pass, token);
    }

    readPasswordRule(): Promise<SystemPasswordRule> {
        return bauth.readPasswordRule().then((response: ClientResponse) => {
            return getByPath(response, 'object.system_config.password_rule');
        });
    }

    readSSOLoginOption(): Promise<User> {
        return bauth.readSsoOptions().then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    readOrgTelephonyDomain(orgId?: string): Promise<TelephonyDomain> {
        return bgroup.readOrgTelephonyDomain().then((response: ClientResponse) => {
            return getByPath(response, 'object.telephony_domain');
        });
    }

    readOrgInfo(orgId?: string): Promise<MxGroup> {
        if (cacheMgr.currentOrg) {
            return Promise.resolve(cacheMgr.currentOrg);
        }else if (cacheMgr.anonymousOrg) {
            return Promise.resolve(cacheMgr.anonymousOrg);
        }else {
            if (this._readOrgPromise) {
                return this._readOrgPromise;
            }

            this._readOrgPromise = bgroup.readGroupInfo(orgId).then(response => {
                let group: Group = getByPath(response, 'object.group');
                if (group) {
                    let mxGroup = MxObjectFactory.createMxGroup(group);
                    cacheMgr.onAnonymousOrgCreated(mxGroup);
                    return mxGroup;
                }else {
                    return null;
                }
            }).catch(e => {
                return Promise.reject(e);
            })

            return this._readOrgPromise;
        }
    }

    readMeet(meetId: string, boardId?: string): Promise<MxBoard> {
        return bmeet.readMeetBoard(meetId, boardId).then((response: ClientResponse) => {
            // merge session data to last board session
            if (response.object.session && response.object.board.sessions) {
                response.object.board.sessions[response.object.board.sessions.length-1].session.user_roster = response.object.session.user_roster;
            }

            if (response.object.session && response.object.session.telephony_conf && response.object.board.sessions) {
                response.object.board.sessions[response.object.board.sessions.length-1].session.telephony_conf = response.object.session.telephony_conf;
            }

            let mxBoard = MxObjectFactory.createMxBoard(response.object.board);
            return mxBoard;
        })
    }

    decodeBoardViewToken(token: string): Promise<Board> {
        return bauth.decodeViewToken(token, MxTokenType.BOARD_VIEW_TOKEN).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    decodeOrgInviteToken(token: string): Promise<Group> {
        return bauth.decodeViewToken(token, MxTokenType.GROUP_INVITE_TOKEN).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    decodePartnerInviteToken(token: string): Promise<Partner> {
        return bauth.decodeViewToken(token, MxTokenType.PARTNER_INVITE_TOKEN).then((response: ClientResponse) => {
            return getByPath(response, 'object.partner');
        });
    }

    decodeQRToken(token: string): Promise<Group> {
        return bauth.decodeViewToken(token, MxTokenType.QR_TOKEN).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    decodeEmailToken(token: string): Promise<Group> {
        return bauth.decodeViewToken(token, MxTokenType.EMAIL_VERIFICATION_TOKEN).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    verifyEmailToken(token: string): Promise<User> {
        return bauth.verifyEmailToken(token).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    resendAppDownloadLinkSMS(phoneNumber: string, orgId?: string): Promise<void> {
        return buser.resendAppDownloadLinkSMS(phoneNumber, orgId).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    resendVerifyCodeEmail(email: string, qrToken?: string, action?: MxVerifyCodeAction): Promise<void> {
        return bauth.resendVerifyCodeEmail(email, qrToken, action).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    resendVerifyCodeSms(phoneNum: string, qrToken?: string, action?: MxVerifyCodeAction): Promise<void> {
        return bauth.resendVerifyCodeSms(phoneNum, qrToken, action).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    verifyEmailCode(email: string, code: string): Promise<Group> {
        return bauth.verifyEmailCode(email, code).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    verifySmsCode(phoneNum: string, code: string): Promise<Group> {
        return bauth.verifySmsCode(phoneNum, code).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    registerWithQRToken(user: User, qrToken?: string, noRelationBoard?: boolean, isInternalUser?: boolean): Promise<MxUser> {
        return bauth.registerWithQRToken(user, qrToken, noRelationBoard, isInternalUser);
    }

    resendGlobalVerifyCodeEmail(email: string): Promise<void> {
        return bauth.resendGlobalVerifyCodeEmail(email).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    resendGlobalVerifyCodeSms(phoneNum: string): Promise<void> {
        return bauth.resendGlobalVerifyCodeSms(phoneNum).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    verifyGlobalEmailCode(email: string, code: string): Promise<void> {
        return bauth.verifyGlobalEmailCode(email, code).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    verifyGlobalSmsCode(phoneNum: string, code: string): Promise<void> {
        return bauth.verifyGlobalSmsCode(phoneNum, code).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    listAvailableBaseDomains(): Promise<Group> {
        return bsystem.listAvailableBaseDomains().then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    checkDomainAvailability(domain: string): Promise<void> {
        return bsystem.checkDomainAvailability(domain).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    registerGroup(user: User, group: Group): Promise<ClientResponse> {
        return bsystem.registerGroup(user, group);
    }

    cancelGroup(groupId: string): Promise<Group> {
        return bsystem.cancelGroup(groupId).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    reactivateCancelGroup(groupId: string): Promise<Group> {
        return bsystem.reactivateCancelGroup(groupId).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    createGroupTagWithAccessToken(groupId: string, tags: Object, accessToken: string): Promise<Group> {
        return bsystem.createGroupTagWithAccessToken(groupId, tags, accessToken).then((response: ClientResponse) => {
            return getByPath(response, 'object.group');
        });
    }

    sendFeedback(user: User, subject: string, message: string, isSystemFeedback?: boolean): Promise<void> {
        return bsystem.sendFeedback(user, subject, message, isSystemFeedback).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    abortRequest(req: Object|string): void {
        try {
            if (typeof(req) === 'string') {
                Ajax.abortRequest(req);
            }else if (req && req['id']) {
                Ajax.abortRequest(req['id']);
            }
        }catch(e) {
            // ignore
        }
    }
    
    initMeet(opt?: MxInitMeetOption): Promise<MxMeet> {
        opt = opt || {};

        if (!sdkConfig.isMeetSdk && !opt.isClip) {
            mxLogger.warn('initMeet API is for MeetSDK');
            return Promise.reject(MxErr.NotSupported());
        }

        return new Promise((resolve, reject) => {
            if (cacheMgr.currentMeet && opt.sessionKey && opt.sessionKey === cacheMgr.currentMeet.getSessionKey()) {
                resolve(cacheMgr.currentMeet);
            } else {
                let p = opt.sessionKey ? bmeet.readMeetBoard(opt.sessionKey) : bmeet.createMeetBoard(opt);
                p.then(response => {
                  let board: Board = getByPath(response, 'object.board');
                  let session: ActionObject = getByPath(response, 'object.session', {session_key: opt.sessionKey});
                  let mxMeet = MxObjectFactory.createMxMeet(board, session);
                  opt.isClip && mxMeet.setAsClip();
                  cacheMgr.setCurrentMeet(mxMeet);
                  cacheMgr.setCurrentSessionZone(response.zone);
                  cacheMgr.onMeetBoardCreated(session.session_key, mxMeet);
                  resolve(mxMeet);
                }).catch(reject)
          }
        });
    }

    sendMeetLog(meetId: string, log: string, force?: boolean): void {
        try {
            this.mxMeetLogger.sendServerLog(meetId, this.getCurrentSessionId(), log, force);
        }catch(e) {
            // ignore error
        }
    }

    switchMeetLog(meetId: string, enable: boolean): void {
        this.mxMeetLogger.switchMeetLog(meetId, enable);
    }

    setAnonymousContext(): void {
        this._anonymousContext = true
    }

    isAnonymousContext(): boolean {
        return this._anonymousContext
    }

}

export function initISDK(){
    isdkInstance = new MxISDKImpl()
}

export let isdkInstance: MxISDK;
window['mxisdk'] = isdkInstance;
window['mxajax'] = Ajax;


