/**
 * Created by colin on 2019-09-05
 */
import {Defines} from "isdk"
import ServerErrorInfo from "../defines/ServerErrorInfo";
import { ObjectUtils } from "isdk/src/util";
import { UserType } from "isdk/src/api/defines";

const ErrorCode = Defines.ClientResponseCode;
const DetailErrorCode = Defines.ClientResponseDetailCode;

export class CServerError extends Error{
  private data: ServerErrorInfo
  private errorCode: string
  private detailCode: string
  constructor(response: ServerErrorInfo) {
    super(response.message)
    this.name = 'CServerError'
    this.data = response
    this.errorCode = response.code
    this.detailCode = response.detailCode
    Object.setPrototypeOf(this, CServerError.prototype);
  }

  get isRequestTimeoutError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_TIMEOUT;
  }
  get isInvalidRequest (){
    return this.errorCode === ErrorCode.RESPONSE_ERROR_INVALID_REQUEST
  }
  get isUnknownError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_FAILED;
  }

  get isInvalidTokenError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_INVALID_TOKEN
  }

  get isNotFoundError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_NOT_FOUND
  }

  get isSessionExpiredError() {
    return false;
  }

  get isUserNotAuthorized(){
    return this.detailCode === DetailErrorCode.ERROR_USER_NOT_AUTHORIZED
  }

  get isNoPermissionError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_PERMISSION
  }

  get isExceedClientBodyMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_UPLOAD_CLIENT_BODY_MAX;
  }

  get isExceedUserCloudMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_USER_CLOUD_MAX;
  }

  get isExceedSessionUserMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_SESSION_USERS_MAX
  }

  get isExceedBoardUserMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_BOARD_USERS_MAX
  }

  get isExceedBoardPageMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_BOARD_PAGES_MAX
  }

  get isExceedUserBoardMaxError() {
    return this.detailCode === DetailErrorCode.EXCEED_USER_BOARDS_MAX
  }

  get isUploadDetectedVirusError() {
    return this.detailCode === DetailErrorCode.ERROR_VIRUS_DETECTED
  }

  get isFileTypeNotSupportError() {
    return this.detailCode === DetailErrorCode.ERROR_FILE_TYPE_NOT_SUPPORTED;
  }

  get isUserLockedError() {
    return this.detailCode === DetailErrorCode.ERROR_LOGIN_LOCKED;
  }

  get isPasswordRuleConfictError() {
    return this.detailCode === DetailErrorCode.ERROR_PASSWORD_RULE_CONFLICT;
  }

  get isUserDisabledError() {
    return this.detailCode === DetailErrorCode.ERROR_USER_DISABLED
  }

  get isConflictError() {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_CONFLICT
  }

  get isInvalidUserType () {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_INVALID_REQUEST && this.detailCode === DetailErrorCode.ERROR_INVALID_USER_TYPE
  }

  get isVerificationExpired() {
    return this.detailCode === DetailErrorCode.ERROR_VERIFICATION_CODE_EXPIRED
  }

  get isUserNotInGroup() {
    return this.detailCode === DetailErrorCode.ERROR_USER_NOT_GROUP_MEMBER
  }

  get isInvalidGroupSubscriptionForClient () {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_PRECONDITION_FAILED && ObjectUtils.getByPath(this.data, 'detail.object.user.type') === UserType.USER_TYPE_LOCAL
  }

  get isInvalidGroupSubscriptionForInternal () {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_PRECONDITION_FAILED && ObjectUtils.getByPath(this.data, 'detail.object.user.type') === UserType.USER_TYPE_NORMAL
  }

  get isSubscribeFailedError() {
    return false;
  }

  get isExceedLimit () {
    return this.errorCode === ErrorCode.RESPONSE_ERROR_EXCEED_LIMIT
  }
  toString(){
    let errName = ''
    Object.keys(CServerError.prototype).forEach((name) => {
      if(name.indexOf('is')===0 && this[name]){
        errName = name;
      }
    })
    return `ServerError:${errName} Response:${this.data}`
  }
}

