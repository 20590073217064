import {ComponentOption} from "../index"
import {MxComponent} from "./MxComponent"
import {BankMsgSummaryView} from "../../../../views/cardsdk"
import {MxEvent} from "../../../../views/cardsdk/src/MxEvent"

export const BankMsgSummaryMeta = {
    id: "BankMsgSummary",
    onBadgeClick: MxEvent.onBadgeClick, // No parameter callback
    onSendMsgClick: MxEvent.onSendMsgClick, // No parameter callback
    onNewMsg: MxEvent.onNewMsg // Callback when a new msg received. The callback with the new message object.
}

export class BankMsgSummary extends MxComponent{
    constructor(componentOption: ComponentOption) {
        super(componentOption)
        this.componentId = BankMsgSummaryMeta.id
    }

    protected getTemplate(): string {
        return '<BankMsgSummaryView v-if="!destroy" v-show="show"/>'
    }

    protected getVueComponent(): {} {
        return {BankMsgSummaryView}
    }
}
