import { BoardTag } from './../../proto/generated/BoardTag';
import { Board } from './../../proto/generated/Board';
import { BoardPage } from './../../proto/generated/BoardPage';
import { ActionObject } from './../../proto/generated/ActionObject';
import { mergeBoardCacheObject, mergeSessionCacheObject } from './../../proto';
import { BoardCache } from './boardCache';
import { MxTagKey } from '../../api/defines';

export class MeetCache {
    private _boardCache: BoardCache;
    private _cacheSession: ActionObject;

    constructor(board: Board, session: ActionObject) {
        this._boardCache = new BoardCache(board);
        this._cacheSession = session;
    }

    get boardCache(): BoardCache {
        return this._boardCache;
    }

    get board(): Board {
        return this._boardCache.board;
    }

    get session(): ActionObject {
        return this._cacheSession;
    }

    updateSession(session: ActionObject) {
        this._cacheSession = session;
    }

    isAudioCall(): boolean {
        if (!this.board.tags || this.board.tags.length === 0) {
            return false;
        }

        for (let i = 0; i < this.board.tags.length; i++) {
            let tag: BoardTag = this.board.tags[i];
            if (tag.name === MxTagKey.MEET_CALL_FLAG && tag.string_value.toLowerCase() === 'true') {
                return true;
            }
        }

        return false;
    }

    getPages(filterSequences: number[]): BoardPage[] {
        let result: BoardPage[] = [];
        if (filterSequences && this.board.pages) {
            let filter: Set<number> = new Set(filterSequences);
            this.board.pages.forEach(page => {
                if (filter.has(page.sequence)) {
                    result.push(page);
                }
            });
        }
        return result;
    }

    onObjectUpdate(updatedBoard: Board, updatedSession: ActionObject) {
        if (updatedBoard && updatedBoard.revision && updatedBoard.revision > this._boardCache.board.revision) {
            mergeBoardCacheObject(this._boardCache.board, updatedBoard);
        }

        if (updatedSession && updatedSession.revision && updatedSession.revision > this._cacheSession.revision) {
            mergeSessionCacheObject(this._cacheSession, updatedSession);
        }
    }
}