import { BoardUserActivity } from './../proto/generated/BoardUserActivity';
import { BoardReferenceLink } from './../proto/generated/BoardReferenceLink';
import { ObjectFeed } from "../proto/generated/ObjectFeed";
import { BoardSignature } from "../proto/generated/BoardSignature";
import { BoardTransaction } from "../proto/generated/BoardTransaction";
import { BoardSignee } from "../proto/generated/BoardSignee";
import { BoardPage } from "../proto/generated/BoardPage";
import { Board } from "../proto/generated/Board";
import { BoardAccessType } from "../proto/generated/BoardAccessType";
import { BoardComment } from "../proto/generated/BoardComment";
import { BoardTodo } from "../proto/generated/BoardTodo";
import { ClientResponse } from '../proto/generated/ClientResponse';
import { NotificationLevel } from '../proto/generated/NotificationLevel';
import { BoardSession } from '../proto/generated/BoardSession';
import { User } from '../proto/generated/User';
import { BoardTag } from '../proto/generated/BoardTag';
import { BoardEditorType } from '../proto/generated/BoardEditorType';
import { getAllTags, getTagByKey } from "../data/cache/common";
import { parseSPath, getByPath, mxLogger } from "../util";
import { Ajax } from '../network/ajax';
import { MxErr } from './error';
import { MxSubscription, MxCallback, MxSPath, MxBaseObject, UserIdentity, MxPageElementType, MxInviteMemberOption } from "../api/defines";
import { BoardCache } from "../data/cache/boardCache";
import { MxBoardSubscriber } from "../data/subscribe/boardSubscriber";
import { MxSignatureElement, MxBaseObjectType, MxViewBoardAsParam } from './../api/defines';
import { MxBoard } from "../api/mxBoard";
import { getContextPath } from './config';
import * as bboard from '../biz/board';
import * as bcomment from '../biz/comment';
import * as bfile from '../biz/file';
import * as btodo from '../biz/todo';
import * as btag from '../biz/tag';
import * as bthread from '../biz/thread';
import * as bsignature from '../biz/signature';
import * as btransaction from '../biz/transaction';

export class MxBoardImpl implements MxBoard {
    protected _boardCache: BoardCache;
    protected _boardSubscriber: MxBoardSubscriber;
    protected _boardId: string;
    protected _isSubscribed: boolean;
    protected _viewAs: MxViewBoardAsParam;
    private _pendingPrimiseIds: string[];

    constructor(boardCache: BoardCache) {
        this._boardId = boardCache.board.id;
        this._boardCache = boardCache;
        this._boardSubscriber = new MxBoardSubscriber(this._boardCache);
        this._pendingPrimiseIds = [];
        this._isSubscribed = false;
    }

    get id(): string {
        return this._boardId;
    }

    get isMocked(): boolean {
        return false;
    }

    get isSubscribed(): boolean {
        return this._isSubscribed;
    }

    get board(): Board {
        return this._boardCache.board;
    }

    get basicInfo(): Board {
        return this.board;
    }

    get tags(): Object {
        return getAllTags(this.board.tags);
    }

    get viewAs(): MxViewBoardAsParam {
        return this._viewAs;
    }

    setViewBoardAs(viewAs: MxViewBoardAsParam): void {
        this._viewAs = viewAs;
    }
    
    onBoardUpdated(board: Board): void {
        this._boardCache.onObjectUpdate(board);
        this._boardSubscriber.onObjectUpdate(board);
    }

    sync(): Promise<Board> {
        return Promise.resolve(this.board);
    }

    subscribeBoard(): void {
        if (!this._isSubscribed && !this.isMocked) {
            this._isSubscribed = true;
            bboard.subscribeBoard(this.id, this.basicInfo.revision).then(()=> {
                mxLogger.info('subscribe board:' + this.id);
            }).catch(e => {
                this._isSubscribed = false;
                mxLogger.warn('subscribe board failed:' + e);
            });
        }
    }

    subscribeBasicInfo(cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeBasicInfo(cb);
    }    
    
    subscribeMeetDetail(cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeMeetDetail(cb);
    }

    subscribeFeeds(cb: MxCallback<ObjectFeed[]>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeFeeds(cb);
    }

    subscribeFolder(parentFolderPath: string, cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeFolder(parentFolderPath, cb);
    }

    subscribeTodos(cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeTodos(cb);
    }

    subscribeSessions(cb: MxCallback<BoardSession[]>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeSessions(cb);
    }

    subscribeSignatures(cb: MxCallback<BoardSignature[]>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeSignatures(cb);
    }

    subscribeTransactions(cb: MxCallback<BoardTransaction[]>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeTransactions(cb);
    }

    subscribeThread(baseObj: MxBaseObject, cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeThread(baseObj, cb);
    }

    subscribeFile(filePath: MxSPath, cb: MxCallback<Board>): MxSubscription {
        this.subscribeBoard();
        return this._boardSubscriber.subscribeFile(filePath, cb);
    }

    getFeedBaseObject(feedSeq: number): MxBaseObject {
        return this._boardCache.getFeedBaseObject(feedSeq);
    }

    getCommentBaseObject(commentSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.COMMENT, commentSeq);
    }

    getTodoBaseObject(todoSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.TODO, todoSeq);
    }

    getSignatureBaseObject(signatureSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.SIGNATURE, signatureSeq);
    }

    getTransactionBaseObject(transactionSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.TRANSACTION, transactionSeq);
    }
    
    getMeetBaseObject(sessionSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.MEET, sessionSeq);
    }

    getPageBaseObject(pageSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.PAGE, pageSeq);
    }

    getPositionCommentBaseObject(pageSeq: number, commentSeq: number): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.POSITION_COMMENT, commentSeq, pageSeq);
    }

    getFileBaseObject(filePath: MxSPath): MxBaseObject {
        return this._boardCache.getBaseObject(MxBaseObjectType.FILE, 0, 0, filePath);
    }

    readFeeds(startSequence?: number, beforeSize?: number, afterSize?: number): Promise<Board> {
        return this.wrapPendingPromise(bboard.readFeedsByPagination(this.id, startSequence, beforeSize, afterSize)).then((response: ClientResponse) => {
            let feeds: ObjectFeed[] = getByPath(response, 'object.board.feeds', []);
            this._boardCache.addMainStreamFeeds(feeds);
            return {id: this.id, feeds: feeds} as Board;
        });
    }

    readThread(baseObject: MxBaseObject): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheThread(baseObject, true)) {
            let board = this._boardCache.cacheThread(baseObject);
            return Promise.resolve(board);
        }

        return this.wrapPendingPromise(bthread.readThread(this.id, baseObject)).then(response => {
            let threadBoard: Board = getByPath(response, 'object.board', null);
            if (threadBoard) {
                this._boardCache.addCacheThread(baseObject, threadBoard);
            }
            return threadBoard;
        });
    }

    listAllFolders(parentFolder?: MxSPath): Promise<Board> {
        return bfile.listAllFolders(this.id, parentFolder).then(response => {
            return getByPath(response, 'object.board', null);
        });
    }

    listFolder(parentFolder?: MxSPath, noCache?: boolean): Promise<Board> {
        if (!noCache && this.isSubscribed && this._boardCache.cacheFolder(parentFolder, true)) {
            let board = this._boardCache.cacheFolder(parentFolder);
            return Promise.resolve(board);
        }

        let oldP = bfile.listFolder(this.id, parentFolder);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                let folderBoard: Board = getByPath(response, 'object.board', null);
                if (folderBoard) {
                    this._boardCache.addCacheFolder(parentFolder, folderBoard);
                }
                resolve(folderBoard);
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        this._pendingPrimiseIds.push(newP['id']);
        return newP;
    }

    listTodos(): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheTodos()) {
            return Promise.resolve({
                id: this.id,
                todos: this._boardCache.cacheTodos(),
                reference_links: this._boardCache.cacheReferenceLinks()
            });
        }

        return this.wrapPendingPromise(btodo.listTodos(this.id)).then(response => {
            let todos: BoardTodo[] = getByPath(response, 'object.board.todos', []);
            let links: BoardReferenceLink[] = getByPath(response, 'object.board.reference_links', []);
            this._boardCache.addCacheTodos(todos);
            this._boardCache.addCacheReferenceLinks(links);
            return {
                id: this.id,
                todos: todos,
                reference_links: links,
            };
        });
    }

    listSignatures(): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheSignatures()) {
            return Promise.resolve({
                id: this.id,
                signatures: this._boardCache.cacheSignatures()
            });
        }

        if (!this._boardCache.board.total_signatures) {
            this._boardCache.addCacheSignatures([]);
            return Promise.resolve({id: this.id, signatures: []});
        }

        return this.wrapPendingPromise(bsignature.listSignatures(this.id)).then(response => {
            let signatures: BoardSignature[] = getByPath(response, 'object.board.signatures', []);
            this._boardCache.addCacheSignatures(signatures);
            return {id: this.id, signatures: signatures};
        });
    }


    listTransactions(): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheTransactions()) {
            return Promise.resolve({
                id: this.id,
                transactions: this._boardCache.cacheTransactions()
            });
        }

        if (!this._boardCache.hasTransactions()) {
            this._boardCache.addCacheTransactions([]);
            return Promise.resolve({id: this.id, transactions: []});
        }

        return this.wrapPendingPromise(btransaction.listTransactions(this.id)).then(response => {
            let transactions: BoardTransaction[] = getByPath(response, 'object.board.transactions', []);
            this._boardCache.addCacheTransactions(transactions);
            return {id: this.id, transactions: transactions};
        });
    }

    readFileDetail(filePath: MxSPath): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheFile(filePath, true)) {
            return Promise.resolve(this._boardCache.cacheFile(filePath));
        }
        
        return this.wrapPendingPromise(bfile.readFile(this.id, filePath)).then(response => {
            let fileBoard: Board = getByPath(response, 'object.board', null);
            if (fileBoard) {
                this._boardCache.addCacheFile(filePath, fileBoard);
            }
            return fileBoard;
        })
    }

    readPageDetail(pageSequence: number): Promise<Board> {
        return this.wrapPendingPromise(bfile.readPage(this.id, pageSequence)).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    readSignatureDetail(signatureSequence: number): Promise<Board> {
        let spath: MxSPath = `signatures[sequence=${signatureSequence}]`;
        if (this.isSubscribed && this._boardCache.cacheFile(spath, true)) {
            return Promise.resolve(this._boardCache.cacheFile(spath));
        }

        return this.wrapPendingPromise(bsignature.readSignature(this.id, signatureSequence)).then(response => {
            let fileBoard: Board = getByPath(response, 'object.board', null);
            if (fileBoard) {
                this._boardCache.addCacheFile(spath, fileBoard);
            }
            return fileBoard;
        })
    }

    readTransactionDetail(transactionSequence: number): Promise<Board> {
        return this.wrapPendingPromise(btransaction.readTransaction(this.id, transactionSequence)).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    readOnGoingSignatures(): Promise<Board> {
        if (!this._boardCache.board.total_signatures) {
            return Promise.resolve({id: this.id});
        }
        return this.wrapPendingPromise(bsignature.readOnGoingSignatures(this.id)).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    readOnGoingTransactions(): Promise<Board> {
        return this.wrapPendingPromise(btransaction.readOnGoingTransactions(this.id)).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    readOnGoingDelegateFeeds(): Promise<Board> {
        return this.wrapPendingPromise(bboard.readOnGoingDelegateFeeds(this.id)).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    readUserActivities(): Promise<Board> {
        if (this.isSubscribed && this._boardCache.cacheUserActivities()) {
            return Promise.resolve({
                id: this.id,
                user_activities: this._boardCache.cacheUserActivities(),
            });
        }

        return this.wrapPendingPromise(bboard.readUserActivities(this.id)).then((response: ClientResponse) => {
            let activities: BoardUserActivity[] = getByPath(response, 'object.board.user_activities', []);
            this._boardCache.addCacheUserActivities(activities);
            return getByPath(response, 'object.board');
        });
    }

    updateBasicInfo(board: Board): Promise<Board> {
        return bboard.updateBoard(this.id, board).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    inviteMember(users: UserIdentity[], option?: MxInviteMemberOption): Promise<Board> {
        return bboard.inviteUser(this.id, users, option).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    removeMember(boardUserSequence: number, suppressFeed?: boolean): Promise<Board> {
        return bboard.removeUser(this.id, boardUserSequence, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    setOwner(boardUserSequence: number): Promise<Board> {
        return bboard.setOwner(this.id, boardUserSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateMemberAccessType(boardUserSequence: number, type: BoardAccessType): Promise<Board> {
        return bboard.updateUserAccessType(this.id, boardUserSequence, type).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateTypeIndication(): Promise<Board> {
        return bboard.updateTypeIndication(this.id).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createComment(comment: BoardComment): Promise<Board> {
        return bcomment.createComment(this.id, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateComment(commentSequence: number, comment: BoardComment): Promise<Board> {
        return bcomment.updateComment(this.id, commentSequence, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteComment(commentSequence: number, noFeed?: boolean): Promise<Board> {
        return bcomment.deleteComment(this.id, commentSequence, noFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createThreadComment(obj: MxBaseObject, comment: BoardComment): Promise<Board> {
        return bthread.createThreadComment(this.id, obj, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateThreadComment(obj: MxBaseObject, commentSequence: number, comment: BoardComment): Promise<Board> {
        return bthread.updateThreadComment(this.id, obj, commentSequence, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteThreadComment(obj: MxBaseObject, commentSequence: number): Promise<Board> {
        return bthread.deleteThreadComment(this.id, obj, commentSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createPositionComment(pageSequence: number, comment: BoardComment): Promise<Board> {
        return bcomment.createPositionComment(this.id, pageSequence, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updatePositionComment(pageSequence: number, commentSequence: number, comment: BoardComment): Promise<Board> {
        return bcomment.updatePositionComment(this.id, pageSequence, commentSequence, comment).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deletePositionComment(pageSequence: number, commentSequence: number): Promise<Board> {
        return bcomment.deletePositionComment(this.id, pageSequence, commentSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createFolder(name: string, parentFolderPath?: MxSPath): Promise<Board> {
        return bfile.createFolder(this.id, name, parentFolderPath).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteFolder(folderPath: MxSPath): Promise<Board> {
        return bfile.deleteFolder(this.id, folderPath).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    renameFolder(folderPath: MxSPath, name: string): Promise<Board> {
        return bfile.renameFolder(this.id, folderPath, name).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createWhiteboard(name: string, width: number, height: number, folderPath?: MxSPath, fileUUID?: string): Promise<Board> {
        return bfile.createWhiteboard(this.id, name, width, height, folderPath, fileUUID).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    copyResource(fromBoardId: string, resourceSeq: number, toBoardId: string, toFolderPath: MxSPath, newFileName?: string, suppressFeed?: boolean|null): Promise<Board> {
        return bfile.copyResource(fromBoardId, resourceSeq, toBoardId, toFolderPath, newFileName, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    copyFiles(fromBoardId: string, files: string[], toBoardId: string, toFolderPath: MxSPath, newFileNames?: string[], suppressFeed?: boolean|null, keepOrder?: boolean): Promise<Board> {
        return bfile.copyFiles(fromBoardId, files, toBoardId, toFolderPath, newFileNames, suppressFeed, keepOrder).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    moveFiles(files: MxSPath[], toFolderPath: MxSPath, newFileNames?: string[]): Promise<Board> {
        return bfile.moveFiles(this.id, files, toFolderPath, newFileNames).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    uploadFromRemoteUrl(url: string, name: string, authToken: string, toFolderPath?: MxSPath, contentType?: string, id?:string): Promise<Board> {
        return bfile.uploadFromRemoteUrl(this.id, url, name, authToken, toFolderPath, contentType, id).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    getBoxAccessToken(payload: string): Promise<string> {
        return bfile.getBoxAccessToken(this.id, payload).then((response: Object) => {
            if (response && response.hasOwnProperty('access_token')) {
                return response['access_token'];
            }else {
                return Promise.reject(MxErr.ServerError());
            }
        });
    }

    renameFile(filePath: MxSPath, name: string): Promise<Board> {
        return bfile.renameFile(this.id, filePath, name).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteFiles(files: MxSPath[], noFeed?: boolean): Promise<Board> {
        return bfile.deleteFiles(this.id, files, noFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updatePage(pageSequence: number, param: BoardPage): Promise<Board> {
        return bfile.updatePage(this.id, pageSequence, param).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    lockPage(pageSequence: number): Promise<Board> {
        return bfile.lockPage(this.id, pageSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    unlockPage(pageSequence: number): Promise<Board> {
        return bfile.unlockPage(this.id, pageSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updatePageEditorType(pageSequence: number, editorType: BoardEditorType): Promise<Board> {
        return bfile.updatePageEditorType(this.id, pageSequence, editorType).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        }); 
    }

    createPageElement(pageSequence: number, content: string, elementUuid?: string, elementType?: MxPageElementType): Promise<Board> {
        return bfile.createPageElement(this.id, pageSequence, content, elementUuid, elementType).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updatePageElement(pageSequence: number, elementUuid: string, content: string): Promise<Board> {
        return bfile.updatePageElement(this.id, pageSequence, elementUuid, content).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deletePageElement(pageSequence: number, elementUuid: string): Promise<Board> {
        return bfile.deletePageElement(this.id, pageSequence, elementUuid).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    getReferenceLinkBySequence(referenceLinkSeq: number): Board {
        let board : Board = {
            id: this.id,
            reference_links: this._boardCache.cacheReferenceLinks([referenceLinkSeq])
        }
        return board;
    }

    createSignature(filePath: MxSPath, newFileName?: string): Promise<Board> {
        return bsignature.createSignature(this.id, filePath, newFileName).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateSignature(signatureSequence: number, param: BoardSignature): Promise<Board> {
        return bsignature.updateSignature(this.id, signatureSequence, param).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteSignature(signatureSequence: number, noFeed?:boolean): Promise<Board> {
        return bsignature.deleteSignature(this.id, signatureSequence, noFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    addSignatureSignee(signatureSequence: number, signees: UserIdentity| UserIdentity[]): Promise<Board> {
        return bsignature.addSignatureSignee(this.id, signatureSequence, signees).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateSignatureSignee(signatureSequence: number, signeeSequence: number, signee: BoardSignee): Promise<Board> {
        return bsignature.updateSignatureSignee(this.id, signatureSequence, signeeSequence, signee).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    assignElementToSignee(signatureSequence: number, signeeSequence: number, elements: number[]): Promise<Board> {
        return bsignature.assignElementToSignee(this.id, signatureSequence, signeeSequence, elements).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    startSignature(signatureSequence: number): Promise<Board> {
        return bsignature.startSignature(this.id, signatureSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    submitSignature(signatureSequence: number, elements: MxSignatureElement[], keepStatusUnchanged?: boolean): Promise<Board> {
        return bsignature.submitSignature(this.id, signatureSequence, elements, keepStatusUnchanged).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    declineSignature(signatureSequence: number, message: string): Promise<Board> {
        return bsignature.declineSignature(this.id, signatureSequence, message).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createSignatureElement(signatureSequence: number, pageSequence: number, content: string, elementType: MxPageElementType, client_uuid?: string): Promise<Board> {
        return bsignature.createSignatureElement(this.id, signatureSequence, pageSequence, content, elementType, client_uuid).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateSignatureElement(signatureSequence: number, pageSequence: number, client_uuid: string, content: string): Promise<Board> {
        return bsignature.updateSignatureElement(this.id, signatureSequence, pageSequence, client_uuid, content).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteSignatureElement(signatureSequence: number, pageSequence: number, client_uuid: string): Promise<Board> {
        return bsignature.deleteSignatureElement(this.id, signatureSequence, pageSequence, client_uuid).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }
 
    uploadSignatureResourceToPage(signatureSequence: number, pageSequence: number, resourceUrl: string, resourceName: string): Promise<Board> {
        return bsignature.uploadSignatureResourceToPage(this.id, signatureSequence, pageSequence, resourceName, resourceUrl).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    copySignature(fromBoardId: string, signatureSeq: number, toBoardId: string): Promise<Board> {
        return bsignature.copySignature(fromBoardId, signatureSeq, toBoardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createTodo(todo: BoardTodo): Promise<Board> {
        return btodo.createTodo(this.id, todo).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteTodo(todoSequence: number): Promise<Board> {
        return btodo.deleteTodo(this.id, todoSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    copyTodo(todoSequence: number, toBoardId: string): Promise<Board> {
        return btodo.copyTodo(this.id, todoSequence, toBoardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateTodo(todoSequence: number, todo: BoardTodo): Promise<Board> {
        return btodo.updateTodo(this.id, todoSequence, todo).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    addTodoAttachment(todoSequence: number, files: MxSPath[]): Promise<Board> {
        return btodo.addAttachment(this.id, todoSequence, files).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    removeTodoAttachment(todoSequence: number, referenceSequence: number): Promise<Board> {
        return btodo.removeAttachment(this.id, todoSequence, referenceSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    submitTransactionStep(transactionSequence: number, stepSequence: number, clickButtonId: string): Promise<Board> {
        return btransaction.submitTransactionStep(this.id, transactionSequence, stepSequence, clickButtonId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board')
        })
    }

    createTransaction(transaction: BoardTransaction, suppressFeed?: boolean): Promise<Board> {
        return btransaction.createTransaction(this.id, transaction, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board')
        })
    }

    updateTransaction(transactionSeq: number, transaction: BoardTransaction, suppressFeed?: boolean): Promise<Board> {
        return btransaction.updateTransaction(this.id, transactionSeq, transaction, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board')
        })
    }

    deleteTransaction(transactionSeq: number, suppressFeed?: boolean): Promise<Board> {
        return btransaction.deleteTransaction(this.id, transactionSeq, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board')
        })
    }

    removeTransactionAttachment(transactionSeq: number, referenceSequence: number): Promise<Board> {
        return btransaction.removeAttachment(this.id, transactionSeq, referenceSequence).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    getTag(key: string): string|number {
        return getTagByKey(key, this.board.tags);
    }

    getRawTag(key: string): BoardTag {
        let ret: BoardTag;
        this.board.tags && this.board.tags.forEach(tag => {
            if (!tag.is_deleted && tag.name === key) {
                ret = tag;
            }
        });
        return ret;
    }

    createOrUpdateTag(key: string, val: string | number): Promise<void> {
        return btag.createOrUpdateTag(key, val, this.board.tags, btag.MxTagType.BOARD, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    deleteTag(key: string): Promise<void> {
        return btag.deleteTag(key, this.board.tags, btag.MxTagType.BOARD, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    updateNotificationLevel(level: NotificationLevel): Promise<User> {
        return bboard.updateNotificationLevel(this.id, level).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    markFeedAsUnRead(feedSeq: number): Promise<Board> {
        return bboard.markFeedAsUnRead(this.id, feedSeq).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    makeCreateWebdocUrl(name: string, filename: string): string {
        return `${getContextPath()}/board/upload?type=web&id=${this.id}&name=${encodeURIComponent(name)}&newfile&newfilename=${encodeURIComponent(filename||name)}`
    }

    makeUpdateWebdocUrl(name: string, sequence: number): string {
        return `${getContextPath()}/board/upload?type=vector&id=${this.id}&name=${encodeURIComponent(name)}&sequence=${sequence}&destfile=${sequence}`
    }

    makeDownloadFolderUrl(folderSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/download?type=zip&folders=${folderSequence}&d=${fileName}`
    }

    makeDownloadFilesUrl(filesSequences: number[], fileName: string, type: "pdf" | "zip"): string {
        let name = type === 'pdf' ? 'pages': 'files';
        return `${getContextPath()}/board/${this.id}/download?type=${type}&${name}=${encodeURIComponent(fileName)}&files=${filesSequences.join(',')}`;
    }

    makeDownloadResourceUrl(resourceSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/${resourceSequence}?d=${encodeURIComponent(fileName)}`
    }

    makeUploadFileUrl(fileName: string, folderSpath?: MxSPath, resourceId?:string): string {
        let folder =''
        if (folderSpath){
          let paths = folderSpath.split('.');
          paths = paths.map((path)=>{
            return parseSPath(path).attrVal
          })
          folder = '&destfolder='+paths.join(',')
        }
        let newFile = 'newfile'
        if (resourceId) {
            newFile += `=${resourceId}`
        }
        let url = `${getContextPath()}/board/upload?${newFile}&id=${this.id}&name=${encodeURIComponent(fileName)}${folder}&type=original`
        if(this._viewAs && this._viewAs.viewToken)
            return `${url}&t=${this._viewAs.viewToken}`;
        return url;
    }

    makeUploadToPageUrl(pageSequence: number, fileName: string): string {
        return `${getContextPath()}/board/upload?id=${this.id}&seq=${pageSequence}&name=${encodeURIComponent(fileName)}`;
    }
 
    makeUploadToSignatureUrl(signatureSequence: number, pageSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/signature/${signatureSequence}/${pageSequence}/${encodeURIComponent(fileName)}`;
    }

    makeUploadToSignatureSigneeUrl(signatureSequence: number, signeeSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/signature/${signatureSequence}/${signeeSequence}?type=signature&name=${encodeURIComponent(fileName)}`;
    }

    makeUploadToTodoUrl(todoSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/todo/${todoSequence}/${encodeURIComponent(fileName)}?type=original`;
    }

    makeUploadToTransactionUrl(transactionSequence: number, fileName: string): string {
        return `${getContextPath()}/board/${this.id}/attach/transaction/${transactionSequence}/${encodeURIComponent(fileName)}?type=original`;
    }

    makeUploadBoardCoverUrl(fileName: string): string {
        return `${getContextPath()}/board/upload/?type=cover&id=${this.id}&name=${encodeURIComponent(fileName)}`;
    }

    abortAllPendingRequests(): void {
        for (let p of this._pendingPrimiseIds) {
            try {
                Ajax.abortRequest(p);
            }catch(e) {
                // ignore
            }
        }
        this._pendingPrimiseIds = [];
    }

    private wrapPendingPromise(pro: Promise<any>): Promise<any> {
        this._pendingPrimiseIds.push(pro['id']);
        return pro;
    }

}