import { UserTag } from './../../proto/generated/UserTag';
import { BoardTag } from './../../proto/generated/BoardTag';

function filterDeleted(obj: Object) {
    if (obj['is_deleted']) {
        return false;
    }
    return true;	
}

function getSequences(arr: Object[]): number[] {
    let result: number[] = [];

    arr && arr.forEach(obj => {
        if (obj && obj['sequence']) {
            result.push(obj['sequence']);
        }
    })
    return result;	
}

function getAllTags(tags: BoardTag[] | UserTag[]): Object {
    let obj = {};
    if (tags && tags.length > 0) {
        tags.forEach(tag => {
            if (tag.name) {
                obj[tag.name] = tag.string_value || tag.uint64_value;
            }
        })
    }
    return obj;
}

function getTagByKey(key: string, tags: BoardTag[] | UserTag[]): string|number {
    if (tags && tags.length > 0) {
        for (let i = tags.length-1; i >= 0; i--) {
            if (tags[i].name === key) {
                return tags[i].string_value || tags[i].uint64_value;
            }
        }
    }
    return undefined;
}

export {
    filterDeleted,
    getSequences,
    getAllTags,
    getTagByKey,
}