/**
 * Created by colin on 2019-09-06
 */
export function localCache(name:string) {
  let key = 'logger:'+name;
  return {
    load (){
      try {
        let data = localStorage.getItem(key);
        if (data) {
          return JSON.parse(data)
        }
      }catch (e) {
      }
      return null
    },
    save (data:any){
      try {
        localStorage.setItem(key, JSON.stringify(data));
      }catch (e) {

      }
    }
  }

}

enum LogLevel {
  NULL,
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

interface LogItem {
  time: string;
  level: LogLevel;
  module: string;
  args: any[];
}

const MAX_LOG_BUFFER_SIZE = 1000;
const logCache: LogItem[] = [];
let localLevel = localCache('level');
let currLogLevel: LogLevel = localLevel.load() || LogLevel.NULL;
let keepState = false;
let localWatch = localCache('watch');
const watchModules: string[] = localWatch.load() || [];
const isInIframe = window !== top
let record = true;
function format(date: Date, format: string) {
  const o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  let k;
  for (k in o)
    if (new RegExp("(" + k + ")").test(format)) format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return format;
}

function printLog(item: LogItem) {
  let info = `[${item.time}]`;
  if (item.module) {
    info += isInIframe?`[${item.module}(iframe)]`:`[${item.module}]`;
    if (watchModules.length && watchModules.indexOf(item.module) < 0) {
      return;
    }
  }
  const args: any[] = [info].concat(item.args);
  /* eslint-disable */
  switch (item.level) {
    case LogLevel.DEBUG:
      console.debug.apply(console,args);
      break;
    case LogLevel.INFO:
      console.info.apply(console,args);
      break;
    case LogLevel.ERROR:
      console.error.apply(console,args);
      break;

    case LogLevel.WARN:
      console.warn.apply(console,args);
      break;
  }
  /* eslint-enable */

}

function log(level: LogLevel, args: any[], module?: string): void {
  if (level < currLogLevel) {
    return;
  }
  const time = format(new Date(), 'yyyy-MM-dd h:m:s.S');
  if (keepState) {
    args.map((item) => {
      return JSON.stringify(item);
    })
  }
  const item: LogItem = {
    time: time,
    level: level,
    module: module,
    args: args
  };
    if (record) {
        if (logCache.length >= MAX_LOG_BUFFER_SIZE) {
            logCache.shift();
        }
        logCache.push(item);
    }

  printLog(item);
}

 class Logger {
  public Level = LogLevel;
  public forceString = true;
  public module: string;

  constructor(module?: string) {
    this.module = module || '';
  }


  warn(...args: any[]): void {
    log(LogLevel.WARN, args, this.module)
  }

  debug(...args: any[]): void {
    log(LogLevel.DEBUG, args, this.module)
  }

  info(...args: any[]): void {
    log(LogLevel.INFO, args, this.module)
  }

  error(...args: any[]): void {
    log(LogLevel.ERROR, args, this.module)
  }
}

class GlobalLogger extends Logger {
  set keepState(val: boolean) {
    keepState = val;
  }
  get logLevel(){
    return currLogLevel
  }
  public setLevelWarn() {
    currLogLevel = LogLevel.WARN;
    localLevel.save(currLogLevel)
  }
  public setLevelInfo() {
    currLogLevel = LogLevel.INFO;
    localLevel.save(currLogLevel)
  }
  public setLevelError() {
    currLogLevel = LogLevel.ERROR;
    localLevel.save(currLogLevel)
  }
  public setLevelDebug() {
    currLogLevel = LogLevel.DEBUG;
    localLevel.save(currLogLevel)
  }
  public watch(module: string) {
    if (watchModules.indexOf(module) < 0) {
      watchModules.push(module);
      localWatch.save(watchModules);
    }
  }
  public clearWatch() {
    watchModules.length = 0;
  }

    public stopRecord() {
        record = false;
    }

    public startRecord() {
        record = true;
    }

  public create(module: string): Logger {
    return new Logger(module)
  }

    history(count: number, start?: number, filter?: Function) {
        let total = logCache.length - 1;
        let i = total - (start || 0);
        let item;
        for (; i > 0; i--) {
            item = logCache[i];
            if (!filter || filter(item)) {
                printLog(item);
            }
        }
  }
}

const MxLogger = new GlobalLogger();
window['MxLogger'] = MxLogger;
if(currLogLevel === LogLevel.NULL) {
  MxLogger.setLevelError()
}
export { MxLogger, Logger}
