/**
 * Created by colin on 2019-09-18
 */
import {Defines, MxISDK,MxUser} from "isdk";
import {ObjectUtils} from "@controller/utils";
import {UserFormatter} from "@controller/utils/user";

function filterRemovedObject(obj:any) {
  return !obj.is_deleted
}
function isOwner(boardUser: any) {
  if (boardUser.type === Defines.BoardAccessType.BOARD_OWNER) {
    return true;
  }
  return false;
}

function isClient(boardUser: any) {
  let userType = ObjectUtils.getByPath(boardUser, 'user.type');
  if (!UserFormatter.isInternalUser(userType)) {
    return true;
  }
  return false;
}
function isSameUser(userA:Defines.User,userId: string) {
  return userA.id === userId
}


export function onlyWithMe(board: Defines.Board, userId: string) {
  var count = 0, email, id, findUser = false;
  if (!board || !board.users) {
    return false
  }
  board.users.forEach((boardUser: Defines.BoardUser) => {
    if (!boardUser.is_deleted) {
      if (userId && boardUser.user) {
        if(isSameUser(boardUser.user,userId)){
          findUser = true
        }
      }
      count++;
    }
  });
  if (userId) {
    if (!findUser) {
      return false;
    }
  }
  return count === 2 ? true : false;
}


function onlyMyRelationBinder(board: Defines.Board, userId:string) {
  let users = board.users || [];
  let currUser:Defines.User = MxISDK.getCurrentUser().basicInfo
  let iAmRm = UserFormatter.isInternalUser(currUser.type)
  let moreThenOneClient = false;
  let owner:Defines.BoardUser
  let client:Defines.BoardUser;
  users.filter(filterRemovedObject).forEach((boardUser:Defines.BoardUser) => {
    if(isOwner(boardUser)){
      owner = boardUser;
    }
    if(isClient(boardUser)){
      if(!client){
        client = boardUser;
      }else{
        moreThenOneClient = true;
      }
    }
  });
  if(moreThenOneClient){
    return false;
  }
  if(iAmRm){
    if(isSameUser(owner.user,currUser.id) && isSameUser(client.user, userId)){
      return true;
    }
  }else{
    if(isSameUser(owner.user, userId) && isSameUser(client.user,currUser.id)){
      return true;
    }
  }
  return false;
}
export function find1on1chat(boards: Array<Defines.UserBoard>, userId:string):Defines.UserBoard {
  return boards.find((userBoard:Defines.UserBoard )=>{
    const board = userBoard.board;
    if(userBoard.is_deleted || board.is_inactive){
      return false;
    }
    const currUser:Defines.User = MxISDK.getCurrentUser().basicInfo
    if(board.is_relation && onlyMyRelationBinder(board,userId)){
      return true
    }else if(board.isconversation && onlyWithMe(board,currUser.id)){
      return true;
    }
    return false;
  })
}
export function isOneOnOneChat(userBoard:Defines.UserBoard) {
  let board = userBoard.board;
  if(userBoard.is_deleted){
    return false;
  }
  let users = board.users ||[];
  users = users.filter((boardUser:Defines.BoardUser)=>{
    if(boardUser.is_deleted){
      return false;
    }
    return true
  })
  if(!board.is_relation && !board.isconversation){
    return false;
  }
  if(users.length === 2){
    return true
  }
  return false;
}
