export enum BoardPageType {
  PAGE_TYPE_WHITEBOARD = 'PAGE_TYPE_WHITEBOARD',
  PAGE_TYPE_NOT_SUPPORTED = 'PAGE_TYPE_NOT_SUPPORTED',
  PAGE_TYPE_IMAGE = 'PAGE_TYPE_IMAGE',
  PAGE_TYPE_WEB = 'PAGE_TYPE_WEB',
  PAGE_TYPE_VIDEO = 'PAGE_TYPE_VIDEO',
  PAGE_TYPE_AUDIO = 'PAGE_TYPE_AUDIO',
  PAGE_TYPE_PDF = 'PAGE_TYPE_PDF',
  PAGE_TYPE_URL = 'PAGE_TYPE_URL',
  PAGE_TYPE_NOTE = 'PAGE_TYPE_NOTE',
  PAGE_TYPE_DESKTOPSHARE = 'PAGE_TYPE_DESKTOPSHARE',
  PAGE_TYPE_GEO = 'PAGE_TYPE_GEO',
  PAGE_TYPE_ANY = 'PAGE_TYPE_ANY'
}
