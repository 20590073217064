import { Ajax } from "../network/ajax";
import moment from 'moment'

export class MxMeetLogger {
    private cacheLogs: string[] = [];
    private isSending = false;
    private lastSendTime = Date.now();
    private disableLogMeets: Set<string> = new Set();

    constructor() {
        window.addEventListener('beforeunload', this.clearLogs.bind(this));
    }

    switchMeetLog(meetId: string, enable: boolean): void {
        if (enable) {
            this.disableLogMeets.delete(meetId);
        }else {
            this.disableLogMeets.add(meetId);
        }
    }

    sendServerLog(meetId: string, rosterId: string, log: string, force: boolean = false): void {
        if (this.disableLogMeets.has(meetId)) return;

        let formatStr: string = `${meetId}.${rosterId}.web.${moment(Date.now()).format('MM/DD/YY HH:mm:ss.SSS')} ${log}`;
        // console.info(formatStr)
        this.cacheLogs.push(formatStr);

        if (force || this.needSend()) {
            this.doSendServerLog();
        }
    }

    clearLogs() {
        this.doSendServerLog(true);
    }

    private needSend(): boolean {
        let needSend = false;
        if (this.cacheLogs.length >= 25 || ((Date.now() - this.lastSendTime) > 60 * 1000)) {
            needSend = true;
        }else if (Date.now() - this.lastSendTime < 3 * 1000) {
            // reduce send frequency
            needSend = false;
        }

        return needSend;
    }

    private doSendServerLog(force: boolean = false) {
        if (!this.cacheLogs.length) return;
        if (this.isSending && !force) return;

        let logList = [].concat(this.cacheLogs)
        let data = logList.join('\r\n')
        this.cacheLogs = [];
        this.lastSendTime = Date.now();
        this.isSending = true;

        Ajax.post('/user/log', data, {contentType: "text/plain; charset=UTF-8"}).then(() => {
            this.isSending = false
        }, () => {
            this.cacheLogs = logList.concat(this.cacheLogs)
            this.isSending = false
        })
    }

}