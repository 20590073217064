"use strict";

exports.__esModule = true;

exports.default = function (ref) {
  return {
    methods: {
      focus: function focus() {
        this.$refs[ref].focus();
      }
    }
  };
};

;