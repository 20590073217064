import { ActionLaserPointer } from './../proto/generated/ActionLaserPointer';
import { SessionRecordingStatus } from './../proto/generated/SessionRecordingStatus';
import { ActionObject } from './../proto/generated/ActionObject';
import { ClientResponse } from './../proto/generated/ClientResponse';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientParam } from './../proto/generated/ClientParam';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { Board } from '../proto/generated/Board';
import { ActionUserRoster } from '../proto/generated/ActionUserRoster';
import { ActionVideoState } from '../proto/generated/ActionVideoState';
import { BoardTag } from '../proto/generated/BoardTag';
import { RSVPReply } from '../proto/generated/RSVPReply';
import { UserIdentity, MxMeetParam, MxInitMeetOption, MxTagKey } from '../api/defines';
import { MxBoard } from './../api/mxBoard';
import { Ajax } from "../network/ajax";
import { userRequestNode, boardRequestNode, sessionRequestNode, boardAndSessionRequestNode } from "../network/requestNode";
import { getOSType } from '../util';
import { mxLogger } from './../util/index';
import { getBoardById } from '../data/cache/cacheMgr';

function readSessionsByTime(from: number, to: number): Promise<ClientResponse> {
    let params: ClientParam[] =[{
      name: ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_FROM,
      string_value: String(from)
    },{
      name: ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_TO,
      string_value: String(to)
    }]

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_READ_SESSIONS, null, params));
}


function scheduleMeet(param: MxMeetParam): Promise<ClientResponse> {
  let board: Board = {
    is_flexible:true,
    name: param.topic,
    tags:[],
    sessions: [{
      session: param
    }]
  };

  if (param.auto_recording){
    board.tags.push({
      name: MxTagKey.MEET_START_WITH_RECORDING,
      string_value: "1"
    });
  }

  if (param.host_video_on){
    board.tags.push({
      name: MxTagKey.MEET_HOST_VIDEO_ON,
      string_value: "1"
    });
  }

  if (param.participant_video_on){
    board.tags.push({
      name: MxTagKey.MEET_PARTICIPANT_VIDEO_ON,
      string_value: "1"
    });
  }

  if (param.disable_video){
    board.tags.push({
      name: MxTagKey.MEET_DISABLE_VIDEO,
      string_value: "1"
    });
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.SESSION_REQUEST_SCHEDULE, board));
}

function updateMeet(meetBoardId: string, sessionSequence :number, param: MxMeetParam): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if (param.reset_member_status) {
    params.push({name: ClientRequestParameter.BOARD_REQUEST_RESET_MEMBER_STATUS})
  }

  if (param.suppress_notification) {
    params.push({name: ClientRequestParameter.BOARD_REQUEST_PUSH_NOTIFICATION_OFF})
  }
  
  let board: Board = {
    id: meetBoardId,
    tags:[],
    sessions: [{
      sequence: sessionSequence,
      session: param
    }]
  }

  if (param.topic){
    board.name = param.topic
  }

  if (param.host_video_on !== undefined || param.participant_video_on !== undefined) {
    let mxMeet: MxBoard = getBoardById(meetBoardId);
    if (mxMeet) {
      if (param.host_video_on === true || param.host_video_on === false) {
        let tag: BoardTag = mxMeet.getRawTag(MxTagKey.MEET_HOST_VIDEO_ON); 
        if (tag) {
          board.tags.push({sequence: tag.sequence, string_value: param.host_video_on ? '1' : '0'});
        }else {
          board.tags.push({name: MxTagKey.MEET_HOST_VIDEO_ON, string_value: param.host_video_on ? '1' : '0'});
        }
      }

      if (param.participant_video_on === true || param.participant_video_on === false) {
        let tag: BoardTag = mxMeet.getRawTag(MxTagKey.MEET_PARTICIPANT_VIDEO_ON); 
        if (tag) {
          board.tags.push({sequence: tag.sequence, string_value: param.participant_video_on ? '1' : '0'});
        }else {
          board.tags.push({name: MxTagKey.MEET_PARTICIPANT_VIDEO_ON, string_value: param.participant_video_on ? '1' : '0'});
        }
      }
    }else {
      mxLogger.warn('meet board not found:' + meetBoardId);
    }
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE, board, params));
}

function deleteMeet(meetBoardId: string): Promise<ClientResponse> {
  let board: Board = {
    id: meetBoardId,
  }
  
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE, board));
}


function createMeetBoard(opt?: MxInitMeetOption): Promise<ClientResponse> {
  opt = opt || {};

  let board: Board = {
    islive:true,
    is_flexible:true,
    isnote: opt.isClip,
    tags: []
  }
  if (opt.isCall) {
    board.tags.push({
      name: MxTagKey.MEET_CALL_FLAG,
      string_value:'true'
    })
  }

  if (opt.allVideoOn) {
    board.tags.push({
      name: MxTagKey.MEET_ALL_VIDEO_ON,
      string_value:'1'
    })
  }

  if (opt.disableVideo) {
    board.tags.push({
      name: MxTagKey.MEET_DISABLE_VIDEO,
      string_value:'1'
    })
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE, board));
}

function readMeetBoard(meetId: string, boardId?: string): Promise<ClientResponse> {
  let session: ActionObject = {session_key: meetId};
  let board: Board = {id: boardId};
  let params: ClientParam[] = [{
    name: ClientRequestParameter.SUBSCRIBE_REQUEST_NOHANG,
  }];

  return Ajax.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_SUBSCRIBE, board, session, params));
}

function readMeet(meetId: string): Promise<ClientResponse> {
  let session: ActionObject = {session_key: meetId};
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.BOARD_REQUEST_READ, session));
}

function startMeet(sessionKey:string, meetBoardId:string, topic:string, originalBoardId:string = '', auto_recording:boolean = false): Promise<ClientResponse> {
  let session: ActionObject = {
    topic: topic,
    auto_recording: auto_recording,
    session_key: sessionKey,
    board_id: meetBoardId,
    original_board_id: originalBoardId,
    user_roster:[{user: {
        os_type: getOSType()
      }}
    ]
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_START, session));
}

function joinMeet(sessionId: string, name?: string, email?: string, password?: string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    session_password: password,
    user_roster:[{user: {
        os_type: getOSType(),
        name: name,
        email: email
      }}
    ]
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_JOIN, session));
}

function endMeet(sessionId: string, sessionBoardId: string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key: sessionId,
    board_id: sessionBoardId
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_END, session));
}

function leaveMeet(sessionId: string, sessionBoardId: string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key: sessionId,
    board_id: sessionBoardId
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_LEAVE, session));
}


function switchPage(sessionId: string, pageSequence: number): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key: sessionId,
    page_switch: {
      page_sequence: pageSequence
    }
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_SWITCH_PAGE, session));
}

function muteAll(sessionId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_MUTE_ALL, {session_key: sessionId}));
}

function muteRoster(sessionId: string, rosterIds:string[]): Promise<ClientResponse> {
  return muteOrUnmuteRoster(sessionId, rosterIds, true);
}

function unmuteRoster(sessionId: string, rosterIds:string[]): Promise<ClientResponse> {
  return muteOrUnmuteRoster(sessionId, rosterIds, false);
}

function muteOrUnmuteRoster(sessionId: string, rosterIds:string[], isMute: boolean): Promise<ClientResponse> {
  let rosters: ActionUserRoster[] = [];
  rosterIds && rosterIds.forEach((id) => {
     id && rosters.push({id: id});
  });

  let session: ActionObject = {
    session_key: sessionId,
    user_roster: rosters
  };

  let reqType: ClientRequestType = isMute ? ClientRequestType.SESSION_REQUEST_MUTE : ClientRequestType.SESSION_REQUEST_UNMUTE;

  return Ajax.sendRequest(sessionRequestNode(reqType, session));
}

function leaveTelephony(sessionId: string, rosterId: string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key: sessionId,
    user_roster: [{
      id: rosterId
    }]
  };

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_LEAVE_TELEPHONY, session));
}

function setHost(sessionId:string,rosterId:string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    user_roster:[{
        id: rosterId,
        is_host: true
      }]
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_SET_HOST, session));
}

function reclaimHost(sessionId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_RECLAIM_HOST, {session_key: sessionId}));
}

function setPresenter(sessionId:string, rosterId:string, grabPresenterWhenNotSharing?: boolean): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    user_roster:[{
        id: rosterId,
        is_presenter: true
      }]
  };

  let params: ClientParam[] = undefined;
  if (grabPresenterWhenNotSharing) {
    params = [{
      name: ClientRequestParameter.GRAB_PRESENTER_WHEN_NOT_SHARING,
    }];
  }

  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_SET_PRESENTER, session, params));
}


function setRecordingStatus(sessionId:string, recordingStatus: SessionRecordingStatus): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    recording_state: [{
        status: recordingStatus
      }]
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.BOARD_PUBLISH_ACTION, session));
}

function saveRecording(sessionId:string, recordingName: string, destinationBoardId: string): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    recording_state: [{
        status: SessionRecordingStatus.RECORDING_SAVED,
        recording_name: recordingName,
        destination_board_id: destinationBoardId,
      }]
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.BOARD_PUBLISH_ACTION, session));
}

function publishActionVideoState(sessionId:string, state: ActionVideoState): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    video_state: [state]
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.BOARD_PUBLISH_ACTION, session));
}

function publishLaserPointer(sessionId:string, laserPointer: ActionLaserPointer): Promise<ClientResponse> {
  let session: ActionObject = {
    session_key:sessionId,
    laser_pointer: laserPointer
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.BOARD_PUBLISH_ACTION, session));
}

function inviteRosters(sessionId:string, users: UserIdentity[]): Promise<ClientResponse>  {
  let session: ActionObject = {
    session_key: sessionId,
    user_roster: users.map(user => {
      return {user: user}
    })
  };
  return Ajax.sendRequest(sessionRequestNode(ClientRequestType.SESSION_REQUEST_INVITE, session));
}

function sendLogToServer(data: string): Promise<any> {
  return Ajax.post('/user/log', data, {contentType: "text/plain; charset=UTF-8"});
}

function updateRSVPStatus(boardId: string, reply: RSVPReply): Promise<ClientResponse> {
  let board: Board = {
      id: boardId,
      user_rsvps:[{
          replies: [reply]
      }]
  }
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_RSVP, board));
}

export {
    readSessionsByTime,
    scheduleMeet,
    updateMeet,
    deleteMeet,

    createMeetBoard,
    readMeetBoard,
    readMeet,
    startMeet,
    joinMeet,
    leaveMeet,
    endMeet,
    switchPage,
    muteAll,
    muteRoster,
    unmuteRoster,
    leaveTelephony,
    inviteRosters,
    setHost,
    reclaimHost,
    setPresenter,
    setRecordingStatus,
    saveRecording,
    publishActionVideoState,
    publishLaserPointer,
    sendLogToServer,
    updateRSVPStatus,
}