export enum CallStatus {
  CALL_STATUS_INVALID = 'CALL_STATUS_INVALID',
  CALL_STATUS_INITIALIZED = 'CALL_STATUS_INITIALIZED',
  CALL_STATUS_RINGING = 'CALL_STATUS_RINGING',
  CALL_STATUS_CONNECTING = 'CALL_STATUS_CONNECTING',
  CALL_STATUS_CONNECTED = 'CALL_STATUS_CONNECTED',
  CALL_STATUS_CANCELLED = 'CALL_STATUS_CANCELLED',
  CALL_STATUS_NOANSWER = 'CALL_STATUS_NOANSWER',
  CALL_STATUS_DECLINED = 'CALL_STATUS_DECLINED',
  CALL_STATUS_ENDED = 'CALL_STATUS_ENDED',
  CALL_STATUS_FAILED = 'CALL_STATUS_FAILED'
}
