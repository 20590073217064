export default {
	basic_info: {},
	tags: {},
	group_caps: {},
	group_settings: {},
	integrations: [],
	roles: [],
	myManagedTeams: [],
	myTeams: [],
	myManagedMembers: [],
	isM0App: null,
	contextPath: ''
};
