import { Group } from './../proto/generated/Group';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientParam } from './../proto/generated/ClientParam';
import { ClientResponse } from './../proto/generated/ClientResponse';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { GroupAccessType } from '../proto/generated/GroupAccessType';
import { UserIdentity, MxGroupManagementFilter, MxGroupManagementClientFilter } from './../api/defines';
import { Ajax } from './../network/ajax';
import { groupRequestNode } from '../network/requestNode';
import { currentUserId } from '../data/cache/cacheMgr';

function readTeam(teamId: string): Promise<ClientResponse> {
  let group: Group = {
    id: teamId
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ, group));
}

function createTeam(name?: string, description?: string): Promise<ClientResponse> {
  let group: Group = {
    name: name,
    description: description
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_CREATE_PUBLIC_TEAM, group));
}

function updateTeam(teamId: string, name?: string, description?: string): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
    name: name,
    description: description
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE_PUBLIC_TEAM, group));
}

function deleteTeam(teamId: string): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_DELETE_PUBLIC_TEAM, group));
}

function addTeamMembers(teamId: string, users: UserIdentity[], accessType?: GroupAccessType): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
    members: users.map(u => {
      return {
        user: u,
        type: accessType
      };
    })
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_ADD_TEAM_MEMBER, group));
}

function removeTeamMember(teamId: string, user: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
    members: [{
      user: user
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_REMOVE_TEAM_MEMBER, group));
}

function assignTeamOwner(teamId: string, user: UserIdentity): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
    members: [{
      user: user
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_REASSIGN_TEAM_OWNER, group));
}

function updateTeamMemberAccessType(teamId: string, user: UserIdentity, accessType?: GroupAccessType): Promise<ClientResponse> {
  let group: Group = {
    id: teamId,
    members: [{
      user: user,
      type: accessType
    }]
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_SET_TEAM_MEMBER_ACCESS_TYPE, group));
}

function getClientParams(filter: MxGroupManagementFilter): ClientParam[] {
  let params: ClientParam[] = [];
  if (!filter) return params;

  if (filter.size !== undefined) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_SEARCH_SIZE,
      string_value: String(filter.size)
    })
  }

  if (filter.start !== undefined) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_SEARCH_START,
      string_value: String(filter.start)
    })
  }

  if (filter.pageNumber !== undefined) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_SEARCH_PAGE_NUMBER,
      string_value: String(filter.pageNumber)
    })
  }

  if (filter.searchKey !== undefined) {
    params.push({
      name: ClientRequestParameter.PARTNER_REQUEST_SEARCH_TEXT,
      string_value: String(filter.searchKey)
    })
  }

  if (filter.fromTime && filter.toTime) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_OUTPUT_USER_ACTIVITIES
    });

    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_FROM,
      string_value: String(filter.fromTime)
    })

    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_TO,
      string_value: String(filter.toTime)
    })
  }

  if (filter.isNormal) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_MEMBER
    })
  }

  if (filter.isPending) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_INVITED
    })
  }
  
  return params;
}

function readManagementClients(groupId: string, filter: MxGroupManagementFilter): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS_LOCAL
  })

  if (filter.clientFilter === MxGroupManagementClientFilter.TEAM_CLIENTS) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_TEAM_MEMBERS
    })
  }

  let group: Group = {id: groupId};

  if (filter.clientFilter === MxGroupManagementClientFilter.MY_CLIENTS) {
    group = {
      id: groupId,
      members:[{
        user: {
          id: currentUserId
        }
      }]
    }
  }

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS, group, params));
}

function readManagementInternalUsers(groupId: string, filter: MxGroupManagementFilter): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS_INTERNAL
  });
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_TEAM_MEMBERS
  });

  if (filter.isNormal) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_MEMBER
    })
  }

  if (filter.isPending) {
    params.push({
      name: ClientRequestParameter.GROUP_REQUEST_READ_GROUP_INVITED
    })
  }
  
  // let group: Group = {id: groupId};
  let group;
  if(filter.belongedTeams){
    group = {
      id: groupId,
      teams: filter.belongedTeams
    }
  }else{
    group = {id: groupId}
  }
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS, group, params));
}

function readManagementClientDetail(groupId: string, filter: MxGroupManagementFilter, client: UserIdentity): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS_INTERNAL
  })

  let group: Group = {
    id: groupId,
    members: [{
      user: client
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS, group, params));
}

function readManagementInternalUserDetail(groupId: string, filter: MxGroupManagementFilter, rm: UserIdentity): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS_LOCAL
  })

  let group: Group = {
    id: groupId,
    members: [{
      user: rm
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS, group, params));
}

function readManagementTeamDetail(groupId: string, filter: MxGroupManagementFilter, teamId: string): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);
  params.push({
    name: ClientRequestParameter.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS_INTERNAL
  });

  let group: Group = {
    id: groupId,
    teams: [{
      group: {
        id: teamId
      }
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_MEMBERS, group, params));

}

function readManagementShareUserBoards(groupId: string, filter: MxGroupManagementFilter, client: UserIdentity, rm: UserIdentity): Promise<ClientResponse> {
  let params: ClientParam[] = getClientParams(filter);

  let group: Group = {
    id: groupId,
    members: [{
      user: {
        ...client,
        relations: [{
          user: rm
        }]
      }
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_USER_BOARDS, group, params));
}


function readManagementUserActivities(groupId: string, user: UserIdentity, peerUser: UserIdentity, from: number, to: number): Promise<ClientResponse> {
  let params: ClientParam[] = [
    {
      name: ClientRequestParameter.GROUP_REQUEST_OUTPUT_USER_ACTIVITIES
    },
    {
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_FROM,
      string_value: String(from)
    },
    {
      name: ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_TO,
      string_value: String(to)
    }
  ]

  let group: Group = {
    id: groupId,
    members: [{
      user: {
        ...user,
        relations: [{
          user: peerUser
        }]
      }
    }]
  };
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_MANAGEMENT_USER_ACTIVITIES, group, params));
}

export {
  readTeam,
  createTeam,
  updateTeam,
  deleteTeam,
  addTeamMembers,
  removeTeamMember,
  assignTeamOwner,
  updateTeamMemberAccessType,
  
  readManagementClients,
  readManagementInternalUsers,
  readManagementClientDetail,
  readManagementInternalUserDetail,
  readManagementTeamDetail,
  readManagementShareUserBoards,
  readManagementUserActivities,
}