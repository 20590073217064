export enum BoardRoutingStatus {
  ROUTING_STATUS_INVALID = 'ROUTING_STATUS_INVALID',
  ROUTING_STATUS_OPEN = 'ROUTING_STATUS_OPEN',
  ROUTING_STATUS_OPEN_NO_TIMEOUT = 'ROUTING_STATUS_OPEN_NO_TIMEOUT',
  ROUTING_STATUS_IN_PROGRESS = 'ROUTING_STATUS_IN_PROGRESS',
  ROUTING_STATUS_AGENT_COMPLETE = 'ROUTING_STATUS_AGENT_COMPLETE',
  ROUTING_STATUS_CLOSE = 'ROUTING_STATUS_CLOSE',
  ROUTING_STATUS_CLOSE_TIMEOUT = 'ROUTING_STATUS_CLOSE_TIMEOUT',
  ROUTING_STATUS_OFFICE_CLOSE = 'ROUTING_STATUS_OFFICE_CLOSE'
}
