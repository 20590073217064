<template>
  <div class="comment-row">
    <div style="padding-right: 6px">
      <service-request-user-avatar :user-avatar="userAvatar"></service-request-user-avatar>
    </div>
    <div style="flex:1; display:flex; flex-direction: column; max-width: inherit; position: relative">
      <div>
        <span class="title">{{ feed.name }}</span>
        <span class="comment-time">{{displayTime}}</span>
      </div>
      <span class="content">{{feed.comment}}</span>
      <div
              class="spinner-container"
              v-if="feed.isFake && !feed.isFailed"
              v-mx-spinner="spinOptions"/>
      <img :src="BUBBLE_IMAGE" class="failed-tip spinner-container" style="float: right" v-if="feed.isFake && feed.isFailed" />
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import ServiceRequestUserAvatar from "./ServiceRequestUserAvatar";
import BUBBLE_IMAGE from "@views/theme/src/fonts/icons/source/svg-text-bubble4.svg"

export default {
  name: "ServiceRequestComment",
  components: { ServiceRequestUserAvatar },
  props: {
    feed: {}
  },
  data() {
    return {
      BUBBLE_IMAGE,
      spinOptions: {
        lines: 10,
        length: 2,
        width: 1,
        radius: 5,
        scale: 1,
        corners: 0.7,
        speed: 1
      },
    }
  },
  computed: {
    ...mapGetters("cardsdk", ["getUserAvatarMapping", "getBaseDomain"]),

    displayTime() {
      return moment(this.feed.time).format("MMM D, hh:mma");
    },
    userAvatar() {
      if(this.feed.actor && this.feed.actor.avatar)
        return this.feed.actor.avatar
      let result = this.getUserAvatarMapping[this.feed.id];
      if (result && this.getBaseDomain)
        result = `${this.getBaseDomain}${result}`;
      return result;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

.comment-row {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding-bottom: 16px;
}

.content {
  color: rgb(31, 33, 38);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  max-width: inherit;
  display: block;
}

.title {
  height: 16px;
  color: rgb(31, 33, 38);
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-right: 10px;
}

.comment-time {
  display: inline;
  width: 89px;
  height: 16px;
  color: rgb(141, 145, 153);
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}

.spinner-container {
  width: 20px;
  position: absolute;
  height: 20px;
  right: 0;
  top: 18px;
}
</style>
