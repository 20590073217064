export enum ObjectFeedType {
  FEED_INVALID = 'FEED_INVALID',
  FEED_BOARD_CREATE = 'FEED_BOARD_CREATE',
  FEED_BOARD_NAME_CHANGE = 'FEED_BOARD_NAME_CHANGE',
  FEED_BOARD_COMMENT = 'FEED_BOARD_COMMENT',
  FEED_BOARD_VOICE_COMMENT = 'FEED_BOARD_VOICE_COMMENT',
  FEED_BOARD_COMMENT_DELETE = 'FEED_BOARD_COMMENT_DELETE',
  FEED_PAGES_CREATE = 'FEED_PAGES_CREATE',
  FEED_PAGES_CREATE_WITH_ANNOTATION = 'FEED_PAGES_CREATE_WITH_ANNOTATION',
  FEED_PAGES_ANNOTATION = 'FEED_PAGES_ANNOTATION',
  FEED_PAGES_UPDATE = 'FEED_PAGES_UPDATE',
  FEED_PAGES_DELETE = 'FEED_PAGES_DELETE',
  FEED_PAGES_COMMENT = 'FEED_PAGES_COMMENT',
  FEED_PAGES_POSITION_COMMENT = 'FEED_PAGES_POSITION_COMMENT',
  FEED_PAGES_COMMENT_DELETE = 'FEED_PAGES_COMMENT_DELETE',
  FEED_PAGES_RENAME = 'FEED_PAGES_RENAME',
  FEED_PAGES_RECYCLE = 'FEED_PAGES_RECYCLE',
  FEED_PAGES_MOVE = 'FEED_PAGES_MOVE',
  FEED_EMAIL_RECEIVE = 'FEED_EMAIL_RECEIVE',
  FEED_RELATIONSHIP_JOIN = 'FEED_RELATIONSHIP_JOIN',
  FEED_RELATIONSHIP_LEAVE = 'FEED_RELATIONSHIP_LEAVE',
  FEED_RELATIONSHIP_INVITE = 'FEED_RELATIONSHIP_INVITE',
  FEED_RELATIONSHIP_DECLINE = 'FEED_RELATIONSHIP_DECLINE',
  FEED_RELATIONSHIP_CANCEL = 'FEED_RELATIONSHIP_CANCEL',
  FEED_RELATIONSHIP_REMOVE = 'FEED_RELATIONSHIP_REMOVE',
  FEED_RELATIONSHIP_CHANGE_ROLE = 'FEED_RELATIONSHIP_CHANGE_ROLE',
  FEED_TODO_CREATE = 'FEED_TODO_CREATE',
  FEED_TODO_CREATE_WITH_RESOURCE = 'FEED_TODO_CREATE_WITH_RESOURCE',
  FEED_TODO_UPDATE = 'FEED_TODO_UPDATE',
  FEED_TODO_DELETE = 'FEED_TODO_DELETE',
  FEED_TODO_ASSIGN = 'FEED_TODO_ASSIGN',
  FEED_TODO_COMMENT = 'FEED_TODO_COMMENT',
  FEED_TODO_ATTACHMENT = 'FEED_TODO_ATTACHMENT',
  FEED_TODO_DUE_DATE = 'FEED_TODO_DUE_DATE',
  FEED_TODO_COMPLETE = 'FEED_TODO_COMPLETE',
  FEED_TODO_REOPEN = 'FEED_TODO_REOPEN',
  FEED_TODO_DUE_DATE_ARRIVE = 'FEED_TODO_DUE_DATE_ARRIVE',
  FEED_FOLDER_CREATE = 'FEED_FOLDER_CREATE',
  FEED_FOLDER_RENAME = 'FEED_FOLDER_RENAME',
  FEED_FOLDER_RECYCLE = 'FEED_FOLDER_RECYCLE',
  FEED_FOLDER_DELETE = 'FEED_FOLDER_DELETE',
  FEED_SESSION_SCHEDULE = 'FEED_SESSION_SCHEDULE',
  FEED_SESSION_RESCHEDULE = 'FEED_SESSION_RESCHEDULE',
  FEED_SESSION_START = 'FEED_SESSION_START',
  FEED_SESSION_END = 'FEED_SESSION_END',
  FEED_SESSION_RECORDING_READY = 'FEED_SESSION_RECORDING_READY',
  FEED_SESSION_CANCEL = 'FEED_SESSION_CANCEL',
  FEED_SESSION_RENAME = 'FEED_SESSION_RENAME',
  FEED_NOTE_CREATE = 'FEED_NOTE_CREATE',
  FEED_PIN = 'FEED_PIN',
  FEED_CALL_LOG = 'FEED_CALL_LOG',
  FEED_AUDIO_CALL_LOG = 'FEED_AUDIO_CALL_LOG',
  FEED_RELATIONSHIP_INVITE_PENDING = 'FEED_RELATIONSHIP_INVITE_PENDING',
  FEED_RELATIONSHIP_REMOVE_PENDING = 'FEED_RELATIONSHIP_REMOVE_PENDING',
  FEED_SIGNATURE_STATUS_UPDATE = 'FEED_SIGNATURE_STATUS_UPDATE',
  FEED_SIGNATURE_DELETE = 'FEED_SIGNATURE_DELETE',
  FEED_SIGNATURE_RENAME = 'FEED_SIGNATURE_RENAME',
  FEED_VIEWTOKEN_CREATE = 'FEED_VIEWTOKEN_CREATE',
  FEED_VIEWTOKEN_DELETE = 'FEED_VIEWTOKEN_DELETE',
  FEED_TRANSACTION_CREATE = 'FEED_TRANSACTION_CREATE',
  FEED_TRANSACTION_DELETE = 'FEED_TRANSACTION_DELETE',
  FEED_TRANSACTION_UPDATE = 'FEED_TRANSACTION_UPDATE',
  FEED_TRANSACTION_STEP_SUBMIT = 'FEED_TRANSACTION_STEP_SUBMIT',
  FEED_TRANSACTION_ATTACHMENT = 'FEED_TRANSACTION_ATTACHMENT',
  FEED_TRANSACTION_EXPIRATION_DATE_ARRIVE = 'FEED_TRANSACTION_EXPIRATION_DATE_ARRIVE',
  FEED_TRANSACTION_STEP_SUBMIT_BATCH = 'FEED_TRANSACTION_STEP_SUBMIT_BATCH'
}
