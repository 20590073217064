'use strict';

exports.__esModule = true;

exports.default = function (instance, callback) {
  var speed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 300;
  var once = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (!instance || !callback) throw new Error('instance & callback is required');
  var called = false;
  var afterLeaveCallback = function afterLeaveCallback() {
    if (called) return;
    called = true;
    if (callback) {
      callback.apply(null, arguments);
    }
  };
  if (once) {
    instance.$once('after-leave', afterLeaveCallback);
  } else {
    instance.$on('after-leave', afterLeaveCallback);
  }
  setTimeout(function () {
    afterLeaveCallback();
  }, speed + 100);
};

; /**
   * Bind after-leave event for vue instance. Make sure after-leave is called in any browsers.
   *
   * @param {Vue} instance Vue instance.
   * @param {Function} callback callback of after-leave event
   * @param {Number} speed the speed of transition, default value is 300ms
   * @param {Boolean} once weather bind after-leave once. default value is false.
   */