import {GroupController} from '@controller/group/src/groupController';
import {ObjectUtils} from '@commonUtils';
import {UserController} from "@controller/user/src/userController";

let groupController;
export default {
	initGroupSubscriber ({commit, dispatch}) {
		groupController = GroupController.getInstance();
		groupController.subscribeGroupBasicInfo((group) => {
			commit('setBasicInfo', group);
			if (group.integrations) {
				commit('setGroupIntegrations', group.integrations);
			}
			commit('setGroupCaps', group.group_caps);
			commit('setGroupRoles', groupController.groupRoles);

			if(group.group_caps.enable_service_request &&
				ObjectUtils.getByPath(UserController.getInstance(), 'basicInfo.isInternalUser'))
				dispatch('user/loadSRAgentAndBoard', null, { root: true })
		});
		commit('setGroupCaps', groupController.groupCaps);
		commit('setGroupTags', groupController.tags);
		commit('setContextPath', GroupController.getContextPath())
		//commit('setCaps', groupController)
	},
	getGroupMember ({}, userIdentity) {
		return groupController.getGroupMember(userIdentity);
	},


	getUserBasicInfoByQRToken ({commit}, token) {
		return GroupController.getUserBasicInfoByQRToken(token);
	},
	getGroupInfo ({commit, dispatch}) {
		dispatch('identity/getSSOLoginOptions', null, {root: true});
		return GroupController.getOrgInfo().then(group => {
			commit('setBasicInfo', group);
			commit('setGroupCaps', group.group_caps);
			commit('setGroupIntegrations', group.integrations);
			commit('setContextPath', GroupController.getContextPath())
			dispatch('privileges/initGuestPrivileges', null, { root: true })
			return group;
		});
	},
	getClientQuickLinksForWeb ({commit}, cb) {
		return groupController.getQuickLinks(true, true, (links)=>{
			commit('user/setQuicklinks', links, {root: true});
			cb && cb(links)
		});
	},
	getInternalQuickLinksForWeb ({commit}, cb) {
		return groupController.getQuickLinks(false, true, (links)=>{
			commit('user/setQuicklinks', links, {root: true});
			cb && cb(links)
		});
	},
	searchAuditableUsers ({commit, state}, payLoad) {
		return new Promise((resolve, reject) => {
			groupController.searchAuditableUsers(payLoad.searchKey, payLoad.startSequence, payLoad.pageSize, payLoad.isForAudit).then(members => {
				resolve(members);
			}).catch(e => {
				reject(e);
			});
		});
	},
	destroy ({state}) {
		if (groupController) {
			groupController.destroy();
			groupController = null;
		}
		// integration data shouldn't be fetched if user session out
		state.integrations = []
	},
	updateAllPlatformBranding ({commit}, tags) {
		return groupController.updateAllPlatformBranding(tags);
	},
	updateOnlineBillingBranding({ }, { tags, logos }) {
		return groupController.updateOnlineBillingBranding(tags, logos)
	},
	getUserType({ commit, state }) {
		return new Promise((resolve, reject) => {
			let domain = location.host;
			let integration = (state.integrations || []).filter((integration) => {
				if (integration.is_deleted) {
					return false;
				}
				if (integration.type !== 'GROUP_INTEGRATION_LOCAL') {
					return false;
				}
				// for local dev env
				if (domain.startsWith('localhost') || domain.startsWith('0.0.0.0') || domain.startsWith('127.0.0.1')) {
					return !!integration.domain
				}
				return domain === integration.domain;

			}).sort((a, b) => {
				if (a.sequence > b.sequence) {
					return -1;
				} else {
					return 1;
				}
			}).shift();
			if (integration) {
				let id = ObjectUtils.getByPath(integration, 'webapp.id');
				if (id) {
					GroupController.readWebApp(id).then((res) => {
						commit('setIsM0App', !!res.is_universal)
						resolve(res.is_universal);
					}).catch(reject);
					return;
				}
			}
			resolve(false);
		});
	},
	updateMemberEmailPhoneNum ({}, {id: id, email: email, phoneNumber: phoneNumber}) {
		return groupController.updateMemberEmailPhoneNum(id, email, phoneNumber);
	},
	inviteMember ({}, user) {
		return groupController.inviteMember(user);
	},

	readManagementClients ({}, {fromTime: fromTime, toTime: toTime, clientFilter: clientFilter, size: size, start: start}) {
		return groupController.readManagementClients(fromTime, toTime, clientFilter, start, size);
	},

	readManagementInternalUsers ({}, {fromTime: fromTime, toTime: toTime, size: size, start: start}) {
		return groupController.readManagementInternalUsers(fromTime, toTime, start, size);
	},

	readManagementClientDetail ({}, {id: id, email: email, phoneNumber: phoneNumber, fromTime: fromTime, toTime: toTime}) {
		return groupController.readManagementClientDetail({
			email: email,
			id: id,
			phoneNumber: phoneNumber
		}, fromTime, toTime);
	},

	readManagementInternalUserDetail ({}, {id: id, email: email, phoneNumber: phoneNumber, fromTime: fromTime, toTime: toTime}) {
		return groupController.readManagementInternalUserDetail({
			email: email,
			id: id,
			phoneNumber: phoneNumber
		}, fromTime, toTime);
	},

	readManagementTeamDetail ({}, {teamId: teamId, fromTime: fromTime, toTime: toTime}) {
		return groupController.readManagementTeamDetail(teamId, fromTime, toTime);
	},

	readManagementShareUserBoards ({}, {
		clientId: clientId,
		internalId: internalId,
		fromTime: fromTime,
		toTime: toTime
	}) {
		return groupController.readManagementShareUserBoards({id: clientId}, {id: internalId}, fromTime, toTime);
	},

	filterActiveGroupMembers ({}, userIds) {
		return groupController.filterActiveGroupMembers(userIds);
	},
    createOrUpdateInvitationToken () {
	  return groupController.createOrUpdateInvitationToken()
    },
	readOfficeHour ({ rootState }) {
		if (rootState.binder.viewToken) //GroupController is not initialized yet in anonymous ACD use case
			groupController = GroupController.getInstance()
		return groupController.readOfficeHour()
	}
};
