export enum ClientResponseDetailCode {
  DETAIL_CODE_NO_DETAILS = 'DETAIL_CODE_NO_DETAILS',
  DETAIL_CODE_CLIENT_UPGRADE_RECOMMENDED = 'DETAIL_CODE_CLIENT_UPGRADE_RECOMMENDED',
  EXCEED_USER_BOARDS_MAX = 'EXCEED_USER_BOARDS_MAX',
  EXCEED_BOARD_PAGES_MAX = 'EXCEED_BOARD_PAGES_MAX',
  EXCEED_BOARD_USERS_MAX = 'EXCEED_BOARD_USERS_MAX',
  EXCEED_SESSION_USERS_MAX = 'EXCEED_SESSION_USERS_MAX',
  EXCEED_GROUP_BOARDS_MAX = 'EXCEED_GROUP_BOARDS_MAX',
  EXCEED_GROUP_USERS_MAX = 'EXCEED_GROUP_USERS_MAX',
  EXCEED_UPLOAD_CLIENT_BODY_MAX = 'EXCEED_UPLOAD_CLIENT_BODY_MAX',
  EXCEED_USER_CLOUD_MAX = 'EXCEED_USER_CLOUD_MAX',
  EXCEED_NAME_LENGTH_MAX = 'EXCEED_NAME_LENGTH_MAX',
  ERROR_USER_DISABLED = 'ERROR_USER_DISABLED',
  ERROR_GROUP_SUBSCRIPTION_EXPIRED = 'ERROR_GROUP_SUBSCRIPTION_EXPIRED',
  ERROR_SSO_ENFORCED = 'ERROR_SSO_ENFORCED',
  ERROR_INVALID_BOARD_ID = 'ERROR_INVALID_BOARD_ID',
  ERROR_VIRUS_DETECTED = 'ERROR_VIRUS_DETECTED',
  ERROR_FILE_TYPE_NOT_SUPPORTED = 'ERROR_FILE_TYPE_NOT_SUPPORTED',
  ERROR_PASSWORD_RULE_CONFLICT = 'ERROR_PASSWORD_RULE_CONFLICT',
  ERROR_VERIFICATION_CODE_EXPIRED = 'ERROR_VERIFICATION_CODE_EXPIRED',
  ERROR_LOGIN_LOCKED = 'ERROR_LOGIN_LOCKED',
  ERROR_USER_NOT_REGISTERED = 'ERROR_USER_NOT_REGISTERED',
  ERROR_USER_NOT_GROUP_MEMBER = 'ERROR_USER_NOT_GROUP_MEMBER',
  ERROR_USER_NOT_AUTHORIZED = 'ERROR_USER_NOT_AUTHORIZED',
  ERROR_NOT_EMPTY = 'ERROR_NOT_EMPTY',
  ERROR_USER_NOT_LOGIN = 'ERROR_USER_NOT_LOGIN',
  ERROR_INVALID_USER_TYPE = 'ERROR_INVALID_USER_TYPE',
  EXCEED_FILE_SIZE_MAX = 'EXCEED_FILE_SIZE_MAX',
  ERROR_INVALID_FILE_ENCODING = 'ERROR_INVALID_FILE_ENCODING',
  EXCEED_FILE_LINES_MAX = 'EXCEED_FILE_LINES_MAX',
  ERROR_INVALID_FILE_FORMAT = 'ERROR_INVALID_FILE_FORMAT',
  ERROR_INVALID_FILE_HEADER = 'ERROR_INVALID_FILE_HEADER',
  AGENT_ERROR_INVALID_PASSCODE = 'AGENT_ERROR_INVALID_PASSCODE',
  AGENT_ERROR_INVALID_TIMESTAMP = 'AGENT_ERROR_INVALID_TIMESTAMP',
  AGENT_ERROR_INVALID_PATH = 'AGENT_ERROR_INVALID_PATH',
  ERROR_INTEGRATION_INVALID_GROUP = 'ERROR_INTEGRATION_INVALID_GROUP',
  ERROR_INTEGRATION_EXPIRED_GROUP_SUBSCRIPTION = 'ERROR_INTEGRATION_EXPIRED_GROUP_SUBSCRIPTION',
  ERROR_INTEGRATION_INVALID_EXTERNAL_RESPONSE = 'ERROR_INTEGRATION_INVALID_EXTERNAL_RESPONSE',
  ERROR_INTEGRATION_NOT_GROUP_MEMBER = 'ERROR_INTEGRATION_NOT_GROUP_MEMBER',
  ERROR_INTEGRATION_EXCEED_GROUP_MEMBER_QUANTITY = 'ERROR_INTEGRATION_EXCEED_GROUP_MEMBER_QUANTITY',
  ERROR_INTEGRATION_PENDING_GROUP_MEMBER = 'ERROR_INTEGRATION_PENDING_GROUP_MEMBER',
  ERROR_SESSION_NOT_STARTED = 'ERROR_SESSION_NOT_STARTED',
  ERROR_SESSION_ENDED = 'ERROR_SESSION_ENDED',
  ERROR_SESSION_PASSWORD_PROTECTED = 'ERROR_SESSION_PASSWORD_PROTECTED'
}
