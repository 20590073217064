import { mergeGroupCacheObject } from "../../proto";
import { isArray } from "util";
import { Group } from "../../proto/generated/Group";
import { UserGroup } from "../../proto/generated/UserGroup";

export class GroupCache {
    private _cacheGroup: Group;
    private _routingRequestsGroup: Group;
    private _userIdSequenceMap: Map<string, number>;

    constructor(group: Group) {
        this._cacheGroup = group;
        this._routingRequestsGroup = {revision: 0};
        this._userIdSequenceMap = new Map();
    }

    get group(): Group {
        return this._cacheGroup;
    }

    get routingRequestsGroup(): Group {
        return this._routingRequestsGroup;
    }

    updateGroupMemberCache(group: Group): void {
        if (group && group.members) {
            group.members.forEach(member => {
                if (member.sequence > 0 && member.user && member.user.id) {
                    this._userIdSequenceMap.set(member.user.id, member.sequence);
                }
            })
        }
    }

    getMemberSequenceByUserId(userId: string): number {
        if (this._userIdSequenceMap.has(userId)) {
            return this._userIdSequenceMap.get(userId);
        }
        return 0;
    }

    getCacheTeam(seq: number): UserGroup[] {
        let matchedGroups: UserGroup[] = null;
        if (this._cacheGroup.teams) {
            matchedGroups = this._cacheGroup.teams.filter(ug => {
                return ug.sequence === seq;
            })
        }
        return matchedGroups;
    }

    // include roles & tags
    basicInfo(): Group {
        let basicGroup: Group = {};
        const includedKeys: Set<string> = new Set<string>(['roles', 'tags', 'integrations', 'group_caps', 'routing_config', 'invitation_tokens']);
        Object.keys(this._cacheGroup).forEach( key => {
            if (!isArray(this._cacheGroup[key]) || includedKeys.has(key)) {
                basicGroup[key] = this._cacheGroup[key];
            }
        });
    
        return basicGroup;
    }

    onObjectUpdate(updatedGroup: Group) {
        if (updatedGroup.revision && updatedGroup.revision > this._cacheGroup.revision) {
            mergeGroupCacheObject(this._cacheGroup, updatedGroup);
        }
    }

    onRoutingRequestsUpdate(updatedGroup: Group) {
        if (updatedGroup.revision && updatedGroup.revision > this._routingRequestsGroup.revision) {
            mergeGroupCacheObject(this._routingRequestsGroup, updatedGroup);
        }
    }
}