import { TelephonyDomain } from './../proto/generated/TelephonyDomain';
import { WebApp } from './../proto/generated/WebApp';
import { Partner } from './../proto/generated/Partner';
import { ActionObject } from './../proto/generated/ActionObject';
import { Contacts } from './../proto/generated/Contacts';
import { Group } from './../proto/generated/Group';
import { ClientRequest } from './../proto/generated/ClientRequest';
import { ClientRequestType } from './../proto/generated/ClientRequestType';
import { ClientParam } from '../proto/generated/ClientParam';
import { Board } from '../proto/generated/Board';
import { User } from '../proto/generated/User';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { MxBoard } from '../api/mxBoard';
import { MxViewBoardAsParam } from '../api/defines';
import { getBoardById } from '../data/cache/cacheMgr';


export function requestNode(type: ClientRequestType, params?: ClientParam[], user?: User,  board?: Board, group?: Group, contacts?: Contacts): ClientRequest {
  let req: ClientRequest = {type: type, object: {}};
  if (user) {
    req.object.user = user;
  }
  if (board) {
    req.object.board = board;
  }
  if (group) {
    req.object.group = group;
  }
  if (contacts) {
    req.object.contacts = contacts;
  }
  if (params) {
    req.params = params;
  }

  return req;
}

export function userRequestNode(type: ClientRequestType, user: User, params?: ClientParam[]): ClientRequest {
  return requestNode(type, params, user, null, null);
}

export function boardRequestNode(type: ClientRequestType, board: Board, params?: ClientParam[]): ClientRequest {
  if (board && board.id) {
    let mxBoard: MxBoard = getBoardById(board.id);
    if (mxBoard && mxBoard.viewAs) {
      return boardRequestNodeWithViewAs(type, board, params, mxBoard.viewAs);
    }
  }

  return requestNode(type, params, null, board, null);
}

export function boardRequestNodeWithViewAs(type: ClientRequestType, board: Board, params?: ClientParam[], viewAs?: MxViewBoardAsParam): ClientRequest {
  let viewAsGroup: Group = null;
  if (viewAs) {
    if (viewAs.userId) {
      viewAsGroup = {
        members: [{
            user: {
                id: viewAs.userId
            }
        }]
      }
    }

    if (viewAs.viewToken) {
      if (!params) {
        params = [];
      }

      params.push({
        name: ClientRequestParameter.BOARD_REQUEST_VIEW_TOKEN,
        string_value: viewAs.viewToken
      })
    }
  }
  return requestNode(type, params, null, board, viewAsGroup);
}

export function groupRequestNode(type: ClientRequestType, group: Group, params?: ClientParam[]): ClientRequest {
  return requestNode(type, params, null, null, group);
}

export function contactRequestNode(type: ClientRequestType, contacts: Contacts, params?: ClientParam[]): ClientRequest {
  return requestNode(type, params, null, null, null, contacts);
}

export function sessionRequestNode(type: ClientRequestType, session: ActionObject, params?: ClientParam[]): ClientRequest {
  let req: ClientRequest = {type: type, object: {session: session}};
  if (params) {
    req.params = params;
  }
  return req;
}

export function boardAndSessionRequestNode(type: ClientRequestType, board: Board, session: ActionObject, params?: ClientParam[]): ClientRequest {
  let req: ClientRequest = {type: type, object: {}};
  if (board) {
    req.object.board = board;
  }
  if (session) {
    req.object.session = session;
  }
  if (params) {
    req.params = params;
  }

  return req;
}


export function partnerRequestNode(type: ClientRequestType, partner: Partner, params?: ClientParam[]): ClientRequest {
  let req: ClientRequest = {type: type, object: {}};
  if (partner) {
    req.object.partner = partner;
  }
  if (params) {
    req.params = params;
  }

  return req;
}

export function webappRequestNode(type: ClientRequestType, webapp: WebApp, params?: ClientParam[]): ClientRequest {
  let req: ClientRequest = {type: type, object: {}};
  if (webapp) {
    req.object.webapp = webapp;
  }
  if (params) {
    req.params = params;
  }

  return req;
}

export function telephonyDomainRequestNode(type: ClientRequestType, domain: TelephonyDomain, params?: ClientParam[]): ClientRequest {
  let req: ClientRequest = {type: type, object: {}};
  if (domain) {
    req.object.telephony_domain = domain;
  }
  if (params) {
    req.params = params;
  }

  return req;
}