export class Browser {
  private userAgent = window.navigator.userAgent

  get isIE11 () {
    return this.userAgent.indexOf('Trident') > -1 && this.userAgent.indexOf("rv:11.0") > -1
  }

  get isSafari () {
    return this.userAgent.indexOf('Safari') > -1
  }
}

const BrowserUtils = new Browser()
export {
  BrowserUtils
}