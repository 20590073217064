var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.srBoard.routing_status === "ROUTING_STATUS_OPEN" ||
    _vm.srBoard.routing_status === "ROUTING_STATUS_IN_PROGRESS"
    ? _c(
        "div",
        {
          staticClass: "open-request-item",
          class: { "has-update": _vm.unReadFeedsCount },
          on: { click: _vm.showDetailView }
        },
        [
          _c(
            "div",
            { staticClass: "request-item-wrapper" },
            [
              _c(
                "div",
                { staticClass: "name" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "request-item-title request-item-title-highlight"
                    },
                    [_vm._v(_vm._s(_vm.srBoard.name))]
                  ),
                  _vm._v(" "),
                  _vm.unReadFeedsCount
                    ? [
                        _c("img", {
                          staticClass: "icon-space",
                          attrs: { src: _vm.CHAT_IMAGE }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "badge" }, [
                          _vm._v(_vm._s(_vm.unReadFeedsCount))
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "request-item-content",
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "overflow-x": "hidden"
                  }
                },
                [_vm._v(_vm._s(_vm.srBoard.description))]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "request-item-footer" }, [
                _vm._v(
                  "\n      Last activity on " +
                    _vm._s(_vm.displayTime) +
                    "\n        "
                ),
                _vm.hasAttachment
                  ? _c("img", {
                      staticClass: "icon-space",
                      attrs: { src: _vm.ATTACHMENT_XS_IMAGE, alt: "attachment" }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("service-request-type", {
                staticStyle: { "margin-top": "8px" },
                attrs: { "sr-board": _vm.srBoard }
              })
            ],
            1
          )
        ]
      )
    : _c(
        "div",
        {
          staticClass: "complete-request-item",
          on: { click: _vm.showDetailView }
        },
        [
          _c("div", { staticClass: "request-item-wrapper" }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _c("span", { staticClass: "request-item-title" }, [
                  _vm._v(_vm._s(_vm.srBoard.name))
                ]),
                _vm._v(" "),
                _vm.unReadFeedsCount
                  ? [
                      _c("img", {
                        staticClass: "icon-space",
                        attrs: { src: _vm.CHAT_IMAGE }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "badge" }, [
                        _vm._v(_vm._s(_vm.unReadFeedsCount))
                      ])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "request-item-content",
                staticStyle: {
                  "white-space": "pre-wrap",
                  "overflow-x": "hidden"
                }
              },
              [_vm._v(_vm._s(_vm.srBoard.description))]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "request-item-footer" }, [
              _vm._v(
                "\n      Last activity on " +
                  _vm._s(_vm.displayTime) +
                  "\n        "
              ),
              _vm.hasAttachment
                ? _c("img", {
                    staticClass: "icon-space",
                    attrs: { src: _vm.ATTACHMENT_XS_IMAGE, alt: "attachment" }
                  })
                : _vm._e()
            ])
          ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }