import { MxBoardImpl } from './mxBoardImpl';
import { MxMockBoardParam, MOCK_BOARD_PREFIX, MxCheckMockBoardCallback, MxInviteMemberOption } from './../api/defines';
import { Board } from "../proto/generated/Board";
import { BoardAccessType } from "../proto/generated/BoardAccessType";
import { MxSPath } from "../api/defines";
import { BoardCache } from "../data/cache/boardCache";
import { BoardComment } from "../proto/generated/BoardComment";
import { BoardTodo } from "../proto/generated/BoardTodo";
import { cloneObject, getByPath } from "../util";
import { ClientResponse } from '../proto/generated/ClientResponse';
import { BoardUserStatus } from '../proto/generated/BoardUserStatus';
import { currentUser } from '../data/cache/cacheMgr';
import * as bboard from '../biz/board';
import * as brelation from '../biz/relation';
import * as bcomment from '../biz/comment';
import * as btodo from '../biz/todo';
import * as bfile from '../biz/file';
import { NotificationLevel } from '../proto/generated/NotificationLevel';
import { User } from '../proto/generated/User';
import { mergeBoardCacheObject } from '../proto';

export class MxMockBoardImpl extends MxBoardImpl {
    private _realBoardId: string;
    private _realBoard: Board;
    private _param: MxMockBoardParam;
    private _notificationLevel: NotificationLevel;
    private _checkFunc: MxCheckMockBoardCallback;

    constructor(param: MxMockBoardParam, _board?: Board) {
        let board: Board = _board || {
            id: MOCK_BOARD_PREFIX + param.peerUser.id,
            name: param.name,
            created_time: Date.now(),
            updated_time: Date.now(),
            isconversation: param.isConversation,
            is_relation: param.isRelation,
            is_external: param.isRelation,
            total_members: 2,
            users:[{
                sequence: 1,
                type: BoardAccessType.BOARD_OWNER,
                status: BoardUserStatus.BOARD_MEMBER,
                user: currentUser.basicInfo
            }, {
                sequence: 2,
                type: BoardAccessType.BOARD_READ_WRITE,
                status: BoardUserStatus.BOARD_MEMBER,
                user: param.peerUser 
            }]
        };

        super(new BoardCache(board));
        this._param = cloneObject(param);
        this._checkFunc = param.checkFunc;
    }

    readFeeds(startSequence?: number, beforeSize?: number, afterSize?: number): Promise<Board> {
        return Promise.resolve({
            id: this.id,
            feeds: [],
        } as Board);
    }

    listFolder(parentFolder?: MxSPath, noCache?: boolean): Promise<Board> {
        return Promise.resolve({
            id: this.id,
            folders: [],
            page_groups: [],
        } as Board);
    }

    listTodos(): Promise<Board> {
        return Promise.resolve({
            id: this.id,
            todos: []
        } as Board);
    }

    listSignatures(): Promise<Board> {
        return Promise.resolve({
            id: this.id,
            signatures: []
        } as Board);
    }

    listTransactions(): Promise<Board> {
        return Promise.resolve({
            id: this.id,
            transactions: []
        } as Board);
    }

    sync(): Promise<Board> {
        if (this._param.isRelation) {
            return brelation.createRelation(this._param.peerUser, this._param.suppressInviteFeed).then((response: ClientResponse) => {
                this._realBoardId = response.object.user.boards[0].board.id;
                this._boardCache.board.id = this._realBoardId;
                this._boardSubscriber.onBasicInfoUpdate();
                this._realBoard = response.object.user.boards[0].board;

                if (this._notificationLevel) {
                    bboard.updateNotificationLevel(this._realBoardId, this._notificationLevel);
                }
                return this._realBoard;
            });
        }else {
            let b: Board = {
                isconversation: this._param.isConversation
            }

            let createRealBoard = (): Promise<Board> => {
                return bboard.createBoard(b).then((response: ClientResponse) => {
                    this._realBoard = response.object.board;
                    this._realBoardId = response.object.board.id;
                    this._boardCache.board.id = this._realBoardId;
    
                    if (this._notificationLevel) {
                        bboard.updateNotificationLevel(this._realBoardId, this._notificationLevel);
                    }

                    let option: MxInviteMemberOption = {
                        addDirectly: this._param.inviteDirectly,
                        noFeed: this._param.suppressInviteFeed
                    };
    
                    return bboard.inviteUser(this._realBoardId, [this._param.peerUser], option).then((response: ClientResponse) => {
                        this._boardSubscriber.onBasicInfoUpdate();
                        mergeBoardCacheObject(this._realBoard, response.object.board);
                        return this._realBoard;
                    });
                });
            } 

            return new Promise((resolve, reject) => {
                if (this._checkFunc) {
                    this._checkFunc().then(board => {
                        this._realBoard = board;
                        this._realBoardId = board.id;
                        this._boardCache.board.id = this._realBoardId;
                        resolve(this._realBoard);
                    }).catch(e => {
                        resolve(createRealBoard());
                    })
                }else {
                    resolve(createRealBoard());
                }
            })
        }
    }

    createComment(comment: BoardComment): Promise<Board> {
        return this.sync().then(()=> {
            return bcomment.createComment(this._realBoardId, comment).then((response: ClientResponse) => {
                return getByPath(response, 'object.board');
            });
        }); 
    }

    createTodo(todo: BoardTodo): Promise<Board> {
        return this.sync().then(()=> {
            let assigneeSeq: number = todo.assignee_sequence;
            todo.assignee_sequence = 0;

            return btodo.createTodo(this._realBoardId, todo).then((response: ClientResponse) => {
                if (assigneeSeq && this._realBoard && this._realBoard.users) {
                    let responseTodo: BoardTodo =  getByPath(response, 'object.board.todos.0', {});
                    let todoSeq: number = responseTodo.sequence;
                    let realAssigneeSeq: number = 0;

                    for (let i = 0; i < this._realBoard.users.length; i++) {
                        let bu = this._realBoard.users[i];
                        if (assigneeSeq === 1 && bu.user.id === currentUser.id) {
                            realAssigneeSeq = bu.sequence;
                            break;
                        }else if (assigneeSeq === 2 && bu.user.id === this._param.peerUser.id) {
                            realAssigneeSeq = bu.sequence;
                            break;
                        }
                    }

                    btodo.setAssignee(this._realBoardId, todoSeq, realAssigneeSeq);
                }
                return getByPath(response, 'object.board');
            });
        });
    }

    createFolder(name: string, parentFolderPath?: MxSPath): Promise<Board> {
        return this.sync().then(()=> {
            return bfile.createFolder(this._realBoardId, name, parentFolderPath).then((response: ClientResponse) => {
                return getByPath(response, 'object.board');
            });
        });
    }
    
    createWhiteboard(name: string, width: number, height: number, folderPath?: MxSPath, fileUUID?: string): Promise<Board> {
        return this.sync().then(()=> {
            return bfile.createWhiteboard(this._realBoardId, name, width, height, folderPath, fileUUID).then((response: ClientResponse) => {
                return getByPath(response, 'object.board');
            });
        });
    }

    updateNotificationLevel(level: NotificationLevel): Promise<User> {
        this._notificationLevel = level;
        return Promise.resolve({});
    }

    get isMocked(): boolean {
        return true;
    }

    onObjectUpdate(board: Board): void {
        // do nothing
    }
}