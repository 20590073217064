import {Defines, MxGroup, MxISDK, MxUser} from "isdk";
import {BoardFormatter} from "@controller/utils/board";
import {BoardRoutingStatus} from "isdk/src/proto/generated/BoardRoutingStatus";
import {UserController} from "@controller/user/src/userController";
import {CUserBoard} from "@controller/defines/CUserBoard";
import {BinderController} from "@controller/binder/src/binderController";
import {Group, RoutingChannel, RoutingConfig, UserGroup} from "isdk/src/api/defines";
import {ObjectUtils} from "@commonUtils/object";
import {GroupController} from "@controller/group/src/groupController";
import {CGroupUser} from "@controller/defines/CGroupUser";

let mxUser: MxUser
let mxGroup: MxGroup
let srController: SRController


/**
 * This Controller is used both in the Service Request SDK (AKA websdk for FIS) and the service request function (agent & dispatcher)
 * of Moxtra WL app.
 *
 * When used in SDK, this class manage the BinderController instance -- create the binder instance and load the board as user click into
 * a SR binder, and unload the board / destroy binder instance when leaving binder
 *
 * When used in whitelable app, it uses the binder store directly therefore the management of binder instance is resort to the binder store
 * itself.
 */
export class SRController {
    private subscriber: Defines.MxSubscription;
    private userController: UserController;

    private isFirstTime: boolean = true;
    private srAgentGroupId: string;
    private binderController : BinderController;

    constructor(){
        if(!mxUser){
            mxUser = MxISDK.getCurrentUser()
        }

        if (!this.userController)
            this.userController = UserController.getInstance()

        if (!mxGroup)
            mxGroup = MxISDK.getCurrentOrg()

    }

    static getInstance(): SRController {
        if (!srController) {
            srController = new SRController()
        }
        return srController
    }

    unSubscribeSRBoards(){
        if(this.subscriber) {
            this.subscriber.unsubscribe()
            this.subscriber = null
        }
    }

    subscribeSRBoards(resolve: Function): void {
        this.subscriber =  MxISDK.getCurrentOrg().subscribeRoutingRequests( (group: Defines.Group) => {

            if ( group && group.boards){
                let newBoards = [] 
                group.boards.forEach( board=> {
                    if(!board.is_deleted)
                        newBoards.push(Object.assign({}, BoardFormatter.transformBoardBasicInfo(board.board), {sequence: board.sequence}))
                    else 
                        newBoards.push(board)
                })
                resolve(newBoards)
            }

        })

    }

    isSelfSRAgent() :Boolean {
        const mxGroup = MxISDK.getCurrentOrg()
        let mxUser = MxISDK.getCurrentUser();

        if( this.userController.basicInfo.isAdmin &&
            ObjectUtils.getByPath(mxGroup,'routingConfig.sr_channels.0.include_all_admins')){
            return true
        }

        const teams = ObjectUtils.getByPath(mxGroup, 'group.teams')
        const routingConfig: RoutingConfig = mxGroup.routingConfig
        if (routingConfig) {
            let channels: RoutingChannel[] = routingConfig.sr_channels

            if (channels) {
                for(let i = 0; i< channels.length; i++) {
                    let channel = channels[i]
                    if(!channel.is_deleted) {
                        const teamSequence = ObjectUtils.getByPath(channel, "teams.0.sequence")
                        let srTeam: UserGroup = teams.find(team => team.sequence === teamSequence)
                        if(mxUser.basicInfo.groups.findIndex(group => !group.is_deleted && group.group.id === srTeam.group.id) >= 0) {
                            return true;
                        }
                    }
                }
            }
        }

        return false
    }

    async listAgents(resolve: Function){
        if (!this.srAgentGroupId){
            const routingConfig: RoutingConfig = mxGroup.routingConfig
            const teamSequence = ObjectUtils.getByPath(routingConfig, "sr_channels.0.teams.0.sequence")
            const teams = ObjectUtils.getByPath(mxGroup, 'group.teams')
            if ( teams) {
                let srTeam: UserGroup = teams.find(team => team.sequence === teamSequence)
                this.srAgentGroupId = ObjectUtils.getByPath(srTeam, 'group.id')
            }
        }
        let result: CGroupUser[] = []
        if(this.srAgentGroupId) {
            let group = await MxISDK.getCurrentOrg().readTeamMembers(this.srAgentGroupId)
            if(group && group.members)
                result = GroupController.transformGroupUser(group.members, this.srAgentGroupId, true)
        }

        //add all admin users to the agent list if include_all_admins is true
        if( this.userController.basicInfo.isAdmin &&
            ObjectUtils.getByPath(mxGroup,'routingConfig.sr_channels.0.include_all_admins')){
            let group = await mxGroup.searchMembers(0, 1000, {isAdmin: true})
            if(group && group.members){
                const temp = GroupController.transformGroupUser(group.members, mxGroup.id, true)
                temp.forEach( adminUser => {
                    //also need to deduplicate as an user could be both admin and part of the sr team member
                    result.findIndex( agent => agent.id === adminUser.id) < 0 && result.push(adminUser)
                })
            }
        }
        resolve(result)
    }

    subscribeTeam(resolve: Function){
        if(this.srAgentGroupId) {

            mxGroup.subscribeTeams((teams: UserGroup[]) => {
                const foundTeam = teams.find(team => ObjectUtils.getByPath(team, 'group.id') === this.srAgentGroupId)
                if (foundTeam) {
                    this.listAgents(resolve)
                }
            })
        }
    }

    dispatch({boardId, userId}): any{
        return  mxUser.dispatchRoutingRequest(boardId, userId)
    }

    subscribeClientSRBoards(resolve: Function) {
        this.userController.subscribeUserBoards( (response) => {
            let userBoards
            if(Array.isArray(response))
                userBoards = response
            else
                userBoards = response.userBoards
            let validBoards = userBoards.filter( ub=> ub.type === 'BOARD_OWNER')
            resolve(validBoards)
        })
    }

    async initDetailBinder(binderId: string, subscribeCB){
        this.binderController = BinderController.getInstance(binderId)
        let binderBasicInfo, fileBoard
        binderBasicInfo = await this.binderController.getBinderBasicInfo()
        this.binderController.subscribeBinderBasicInfo( (cBoard)=>{
            subscribeCB(cBoard)
        }, ()=>{})
        // At this stage the binderController should have a valid mxBoard
        fileBoard = await this.getBoardFoldersFiles(binderId)

        let b: CUserBoard = Object.assign({}, binderBasicInfo) as CUserBoard;
        b.resources = fileBoard.resources || fileBoard.page_groups
        return b
    }

    leaveSrBinder(){
        if(this.binderController) {
            this.binderController.unsubscribeBinderBasicInfo()
            this.binderController.destroy()
            this.binderController = null
        }
    }

    createServiceRequest(name: string, description: string, routingChannel: number){
        return new Promise( (resolve,reject)=> {
            mxUser.createRoutingRequest(Defines.MxRoutingChannelType.SR, name, description, routingChannel).then ( (Board) =>{
                resolve(Board)
            }).catch( err => reject() )
        })
    }

    createComment(binderId: string, uuid, commentText){
        let binderController = BinderController.getInstance(binderId)
        return binderController.createComment(uuid, commentText)
    }

    updateServiceRequestStatus(binderId: string, status: BoardRoutingStatus ){
        const MxUser: MxUser = MxISDK.getCurrentUser()
        return MxUser.updateRoutingRequestStatus(binderId, status)
    }


    async getBoardFoldersFiles (binderId) {
        let mxBoard = mxUser.getCacheBoard(binderId)
        return mxBoard.listFolder()
    }

    subscribeFolder (binderId, onSuccessCallback: Function, onErrorCallback: Function): void {
        mxUser.getCacheBoard(binderId).subscribeFolder('',
            (board: Defines.Board) => {
                onSuccessCallback(board)
            }
        )
    }

    //Make sure calling this method when the SDK is unloaded
    destroy(){
        if ( this.binderController) {
            this.binderController.destroy()
            this.binderController = null
        }
        if (this.subscriber){
            this.unSubscribeSRBoards()
        }
        mxUser = null
        mxGroup = null
        srController = null
        this.srAgentGroupId = null
    }

}