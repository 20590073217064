import { render, staticRenderFns } from "./ServiceRequestComment.vue?vue&type=template&id=92a4e494&scoped=true&"
import script from "./ServiceRequestComment.vue?vue&type=script&lang=js&"
export * from "./ServiceRequestComment.vue?vue&type=script&lang=js&"
import style0 from "./ServiceRequestComment.vue?vue&type=style&index=0&id=92a4e494&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92a4e494",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/linfeng/work/svnrepo/boardUI/branches/cardsdk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92a4e494')) {
      api.createRecord('92a4e494', component.options)
    } else {
      api.reload('92a4e494', component.options)
    }
    module.hot.accept("./ServiceRequestComment.vue?vue&type=template&id=92a4e494&scoped=true&", function () {
      api.rerender('92a4e494', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "views/cardsdk/src/ServiceRequestComment.vue"
export default component.exports