import { ArrayUtils } from './array'
import _differenceWith from 'lodash/differenceWith'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import _pick from 'lodash/pick'
import _isEqual from 'lodash/isEqual'
/**
 * Return true if the passed value is Object.
 * @param {Object} obj the target to test
 * @return {Boolean}
 */

 const isArray = Array.isArray


export class ObjectUtils {

    static pick = _pick
    static isEmpty = _isEmpty
    static cloneDeep = _cloneDeep
    static differenceWith = _differenceWith
    static isEqual = _isEqual
    static getByPath(root:any, path:string|Array<string>,defaultVal:any = null) {
        if(!path || !root){
            return defaultVal;
        }
        if (!Array.isArray(path)) {
            path = path.split('.');
        }
        for (var i = 0; i < path.length; i++) {
            root = root[path[i]];
            if (root === undefined || root === null) {
                return defaultVal;
            }
        }
        return root;
    } 

    static setByPath(obj:any,path:string,val:any,defineFn?:Function) {
        if(path.indexOf('[') >0){
            path = path.replace(/\[sequence=\d{1,20}\]/,'.0.');
        }
        let keys = path.split('.');
        let attrName :string= keys.pop()||'';
        if(keys.length){
            for(let i=0 ; i <keys.length; i++){
                if(!obj[keys[i]]) {
                    obj[keys[i]] = {};
                }
                obj = obj[keys[i]];
            }
        }
        if(defineFn){
            defineFn(obj,attrName,val);
        }else {
            if(attrName) {
                obj[attrName] = val;
            }else{
                ObjectUtils.mergeObject(obj,val);
            }
        }
    }

    static getBySPath(root: any, spath: string) {
        if (!spath) {
            return null;
        }
        let paths = spath.split('.');
        let path;
        let result;
        let count = paths.length;
        for(let i=0 ; i < count ; i++) {
          path = ObjectUtils.parseSPath(paths[i]);
          root = root[path.key];
          if(Array.isArray(root)) {
            root = ArrayUtils.getFromArray(root,parseInt(path.attrVal,10),path.attrName);
            if(!root){
                return null;
            }
          }else{
            return null;
          }
          if(i === (count-1)){
            result = root;
          }
        }
        return result;
    }

    static buildSPath(root: any, path: string = '') {
		const fields =['pages', 'page_groups', 'folders', 'files',' resources', 'signatures']
		if (!root) {
			return;
		}
		if (Array.isArray(root)) {
			root.forEach((item:any) => {
				if(item.sequence){
					item.SPath = path + `[sequence=${item.sequence}]`;
					ObjectUtils.buildSPath(item,item.SPath);
				}
			})
			return;
		}
		if (path) {
			path += '.';
		}
		fields.forEach((name) => {
			let item = root[name];
			if(item){
				let npath =path+name;
				ObjectUtils.buildSPath(item,npath);
			}
		})
	}

    static parseSPath(path:string) {
        let firstDemarcation = path.indexOf('[');
        let equalSign = path.indexOf('=');
        let key = path.substr(0,firstDemarcation);
        let attrName  = path.substr(firstDemarcation+1,equalSign-firstDemarcation-1);
        let attrVal:any = path.substr(equalSign+1,path.indexOf(']')-equalSign-1);
        if(attrName === 'sequence'){
          attrVal = parseInt(attrVal);
        }
        return {
          key,attrName,attrVal
        }
      }
    static eachObject(obj: any, callback: Function) {
        Object.keys(obj).forEach((attrName) => {
            callback(attrName, obj[attrName]);
        })
    }
    static mergeObject (target: any, source: any, opts:any={}) {
        Object.keys(source).forEach(function (name) {
            let oldItem = target[name];
            let newItem = source[name];
            let mergeConfig = opts[name] || {};
            if (oldItem === undefined || oldItem === null) {
              if (Array.isArray(newItem)) {
                target[name] = ArrayUtils.merge(newItem, null, mergeConfig)
              } else {
                target[name] = newItem
              }
              return
            }
            if (mergeConfig.merge) {
                target[name] = mergeConfig.merge(oldItem, newItem);
            } else if(mergeConfig.replace){
                target[name] = newItem;
            }else {
                let oldType = typeof oldItem
                let newType = typeof newItem
                if (oldType !== newType) {
                    target[name] = newItem
                    return
                }
                if (['string','boolean', 'number'].indexOf(typeof(newItem)) >=0 || newItem === null) {
                    if (newItem !== oldItem) {
                        target[name] = newItem
                        return
                    } else {
                        return;
                    }
                }
                if (Array.isArray(newItem)) {
                  target[name] = ArrayUtils.merge(oldItem, newItem, mergeConfig)
                } else {
                    ObjectUtils.mergeObject(oldItem, newItem, mergeConfig);
                }
            }

        })
        return target
    }
    static isObject (obj: any): obj is Object {
        return ObjectUtils.isDefine(obj) && typeof(obj) === 'object' && !isArray(obj)
    }
    /**
     * Return true if the passed value is undefined.
     * @param {Object} obj the target to test
     * @return {Boolean}
     */
    static isUndefined (obj: any): boolean {
        return undefined === obj
    }
    /**
     * Return true if the passed value is not  undefined or null.
     * @param {Object} obj the target to test
     * @return {Boolean}
     */
    static isDefine (obj: any): boolean {
        return !ObjectUtils.isUndefined(obj) && null !== obj
    }

    static isBoolean(obj: any){
        return typeof(obj) ==='boolean'
    }
    static isNumber(obj: any){
        return typeof(obj) ==='number'
    }
    static isString(obj: any){
        return typeof(obj) ==='string'
    }
    static isFunction(obj: any) {
        return typeof(obj) === 'function';
    }
    static isNull(obj:any) {
        return null === obj;
    }

}
