/**
 * Created by colin on 2019-09-06
 */
import {MxLogger, ObjectUtils} from "@controller/utils";
import {Logger} from "@commonUtils/MxLogger";
import {CServerError} from "@controller/common/CServerError";
import {CCustomError} from "@controller/common/CCustomError";

const getClassName = function(instance: Function): string {
  return instance.constructor ? instance.constructor.name : null;
};
const getMonkeyPatchMethod = function (method: Function, methodName: string,logger: any): Function {
  return function(...args: any[]) {
    if(!logger.module) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const className = getClassName(this)
      logger.module = className;
    }
    let debugArgs = args.map((arg)=>{
      if(ObjectUtils.isFunction(arg)){
        return arg.name || 'function'
      }
      return arg
    })
    logger.debug.apply(logger,[`.${methodName}(`, ...debugArgs, ')']);
    let time = Date.now();
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const result = method.apply(this, args);
    if(result instanceof Promise) {
      result.then((result: any) => {
        let runTime = (Date.now()-time)/1000
        logger.debug(`.${methodName}()[${runTime}ms] then ->`, result);
      }).catch((e: any) => {
        let runTime = (Date.now()-time)/1000
        let info = e;
        if(e.name === 'CServerError' || e.name === 'CCustomError'){
          info = e.toString()
        }
        logger.warn(`.${methodName}()[${runTime}ms] catch ->`, info);
      })
    }else{
      logger.debug(`.${methodName}() return ->`, result);
    }
    return result;
  };
};

export function ClassLogger(): Function {

  return function(target: Function) {
    // if(MxLogger.logLevel === MxLogger.Level.DEBUG) {
      const logger = MxLogger.create('');
      const props =Object.getOwnPropertyDescriptors(target.prototype);
      Object.keys(props).forEach(function (methodName: string): void {
        const originalDesc = props[methodName];
        if(originalDesc.value){
          const originalMethod = target.prototype[methodName];
          if(methodName === 'constructor'){
            return;
          }
          // set by method logger decorator for disabling the method log
          if (typeof (originalMethod) !== "function" || originalMethod.__loggerMonkeyPatchCompleted === true) {
            return;
          }
          target.prototype[methodName] = getMonkeyPatchMethod(originalMethod, methodName, logger);
        }else if(originalDesc.get || originalDesc.set){

        }
      });
    // }
  };
}
