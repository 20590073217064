import { ClassLogger, transformError } from "@controller/common/decorators";
import { MxSubscription, BoardComment, RichTextFormat, Board, BoardAccessType } from "isdk/src/api/defines";
import { MxUser, MxISDK, MxBoard } from "isdk";
import { ObjectFeed } from "isdk/src/proto/generated/ObjectFeed";
import { FeedUtils } from "@controller/utils/feed";
import { TransactionUtils } from "@controller/utils/transaction";

let __chatController__: ChatController = null

@ClassLogger()
export class ChatController {
  private binderId: string
  private binderInstance: MxBoard
  private binderFeedSubscriber: MxSubscription
  private myTurnTransactionSubscriber: MxSubscription
  private feedPageSize = 20

  constructor (binderId: string) {
    const currentUser: MxUser = MxISDK.getCurrentUser()
    this.binderId = binderId
    this.binderInstance = currentUser.getCacheBoard(binderId)
  }

  /**
	* @param binderId - binder id
	*
	* @return The unique instance of binder controller
	*/
  static getInstance (binderId: string): ChatController {
    if (!__chatController__ || (__chatController__.getBinderId() !== binderId)) {
      __chatController__ = new ChatController(binderId)
    }
    return __chatController__
  }
    
  static destroyInstance () {
    __chatController__ = null
  }
    
  private getBinderId () {
    return this.binderId
  }

  destroy () {
    this.unsubscribeBinderFeeds()
    this.unsubscribeMyTurnTransactions()
    __chatController__ = null
  }

  /**
   * @param onSuccessCallback - callback function of when subscriber in controller receives new data
   * @param onErrorCallback - callback fucntion of when subscriber in controller receives error info
   *
   * @return void
   */
  subscribeBinderFeeds (onSuccessCallback: Function, onErrorCallback: Function): void {
    if (this.binderInstance && !this.binderFeedSubscriber) {
      this.binderFeedSubscriber = this.binderInstance.subscribeFeeds(
        (feeds: ObjectFeed[]) => {
          onSuccessCallback(FeedUtils.transformUpdateFeeds(feeds, this.binderInstance.basicInfo))
        }
      )
    }
  }
  
  /**
   * @return null
   */
  unsubscribeBinderFeeds (): void {
    if (this.binderFeedSubscriber) {
      this.binderFeedSubscriber.unsubscribe()
      this.binderFeedSubscriber = null
    }
  }

  subscribeMyTurnTransactions (onSuccessCallback: Function) {
    if (this.binderInstance && !this.myTurnTransactionSubscriber) {
      this.myTurnTransactionSubscriber = this.binderInstance.subscribeTransactions(
        transactions => {
          const currentUser = MxISDK.getCurrentUser()
          const result = []
          for (let transaction of transactions) {
            if (transaction.is_deleted) {
              result.push(transaction)
            } else if (TransactionUtils.isMyTurn(transaction, currentUser.basicInfo.id)) {
              result.push(transaction)
            } else if (transaction.expiration_date < Date.now()) {
              result.push({
                sequence: transaction.sequence,
                isExpired: true,
              })
            } else {
              result.push({
                sequence: transaction.sequence,
                isNotMyTurn: true
              })
            }
          }
          // onSuccessCallback(transactions)
          onSuccessCallback(result)
        }
      )
    }
  }

  unsubscribeMyTurnTransactions () {
    if (this.myTurnTransactionSubscriber) {
      this.myTurnTransactionSubscriber.unsubscribe()
      this.myTurnTransactionSubscriber = null
    }
  }

  /**
	 *
	 * @param sequence - comment feed sequence
	 * @param comment - comment object
	 *
	 * @return Promise<ClientResponse>
	 */
  @transformError()
  updateComment (sequence: number, comment: BoardComment) {
    return this.binderInstance.updateComment(sequence, comment)
  }

  @transformError()
  createTodoUsingComment (text) {
    return this.binderInstance.createTodo({
      name: text
    })
  }

  getFeedBaseObject (sequence: number) {
    return this.binderInstance.getFeedBaseObject(sequence)
  }

  @transformError()
  getOnGoingTransactionLists () {
    return new Promise((resolve, reject) => {
      this.binderInstance.listTransactions()
        .then(board => {
          resolve(TransactionUtils.filterMyTurnTransactions(board.transactions))
        })
        .catch(reject)
    })
  }

  getTransactionBaseObject (sequence: number) {
    return this.binderInstance.getTransactionBaseObject(sequence)
  }

  /**
   * need double check this api
   * @param text - Normal comment text
   * @param id - if not specified will use default
   * @param richText - bbcode or other rich Text
   * @param richFormat - rich text's format
   *
   * @return Promise<ClientResponse>
  */
 @transformError()
 createComment (id: string, text: string, richText?: string, richFormat?: RichTextFormat) {
   return new Promise((resolve, reject) => {
     const binderComment: BoardComment = {
       client_uuid: id,
       text: text
     }

     if (richText) {
       binderComment.rich_text = richText
     }

     if (richFormat) {
       binderComment.rich_text_format = richFormat
     }

     this.binderInstance.createComment(binderComment)
       .then((board: Board) => {
         if (board.feeds) {
           const feed = board.feeds[0]
           if (feed.board.comments && board.comments) {
             feed.board.comments = board.comments
           }
           const convertedFeed = FeedUtils.transformFeed(feed, this.binderInstance.basicInfo)
           resolve(convertedFeed)
         } else {
           resolve({
             baseObject: {
               binderID: board.id
             }
           })
         }
       })
       .catch(reject)
   })
 }

  /**
   * @param startSequence - optional, if not set, will load latest feeds
   * @param isBefore - optional, when set to true will load feeds before the start sequence
   * @param isAfter - optional, when set to true will load feeds after the start sequence
   *                - when both isBefore and isAfter set to true, will load feeds among the sequence
   *
   * @return Promise<Board>
   */
  @transformError()
  async getMainFeeds (startSequence?: number, isBefore?: boolean, isAfter?: boolean): Promise<object> {
    const args: Array<number> = []
    const defaultFeedPageSize = this.feedPageSize
    let hasValidFeeds: boolean = false
    let processedFeeds = []
    let nearby: boolean = false
    let hasNextPage: boolean = true
    if (startSequence) {
      args.push(startSequence)
    } else {
      args.push(0)
    }
    if (isBefore && isAfter) {
      nearby = true
      args.push(defaultFeedPageSize, defaultFeedPageSize)
    } else {
      if (isAfter) {
        args.push(0)
      }
      args.push(defaultFeedPageSize * 2)
    }

    try {
      if (this.binderInstance && this.binderInstance.isMocked) {
        hasNextPage = false
        processedFeeds = []
      } else {
        while (hasNextPage && !hasValidFeeds && this.binderInstance) {
          const board = await this.binderInstance.readFeeds(...args)
          const binderBasicInfo = this.binderInstance.basicInfo
          const feeds = board.feeds

          if (!nearby) {
            hasNextPage = feeds.length === defaultFeedPageSize * 2

            if (isAfter) {
              feeds.reverse()
            }
            for (let i = feeds.length - 1; i >= 0; i--) {
              const convertedFeed = FeedUtils.transformFeed(feeds[i], binderBasicInfo)
              if (FeedUtils.filterMainStreamFeed(convertedFeed)) {
                processedFeeds.push(convertedFeed)
              }
              if (processedFeeds.length >= defaultFeedPageSize + 1) {
                if (i > 0) {
                  hasNextPage = true
                }
                break
              }
            }
            
            if (processedFeeds.length > defaultFeedPageSize) {
              processedFeeds.pop()
            }
            processedFeeds.reverse()
          } else {
            let targetFeedIndex = 0
            // suggest to use binary search
            for (let i = 0; i < feeds.length; i++) {
              if (feeds[i].sequence === args[0]) {
                targetFeedIndex = i
                break
              }
            }

            const checkAndPush = (feed: ObjectFeed) => {
              if (!feed) {
                return false
              }
              if (processedFeeds.length >= defaultFeedPageSize) {
                return true
              }
                
              const convertedFeed = FeedUtils.transformFeed(feed, binderBasicInfo)
              if (FeedUtils.filterMainStreamFeed(convertedFeed)) {
                processedFeeds.push(convertedFeed)
              }
              return false
            }

            checkAndPush(feeds[targetFeedIndex])

            let prevIndex = 0
            let nextIndex = 0
            for (let i = 1; i < 20; i++) {
              prevIndex = targetFeedIndex - i
              nextIndex = targetFeedIndex + i
              if (prevIndex >= 0) {
                if (checkAndPush(feeds[prevIndex])) {
                  break
                }
              }

              if (nextIndex < feeds.length) {
                if (checkAndPush(feeds[nextIndex])) {
                  break
                }
              }
            }

            hasNextPage = true

            processedFeeds.sort((a: ObjectFeed, b: ObjectFeed) => {
              if (a.sequence > b.sequence) {
                return 1
              } else {
                return -1
              }
            })
          }

          if (processedFeeds.length !== 0) {
            hasValidFeeds = true
          } else if (hasNextPage) {
            if (nearby) {
              args[1] += defaultFeedPageSize
              args[2] += defaultFeedPageSize
            } else {
              args[0] = feeds[0].sequence
            }
          }
        }

        let addDescription = FeedUtils.needDescriptionFeed(this.binderInstance.basicInfo, hasNextPage,)
        if (addDescription) {
          let firstFeedCreateTime = (processedFeeds[0] && processedFeeds[0].created_time) || Date.now()
          let feed = FeedUtils.generateDescriptionFeed(this.binderInstance.basicInfo, firstFeedCreateTime)
          processedFeeds.unshift(feed)
        }
        
      }
    } catch (e) {
      throw e
    }
    return {
      feeds: processedFeeds,
      hasNextPage: hasNextPage
    }
  }
}