import { UserBroadcast } from './../proto/generated/UserBroadcast';
import { BoardActor } from './../proto/generated/BoardActor';
import { BoardUserStatus } from './../proto/generated/BoardUserStatus';
import { BoardSignatureStatus } from './..//proto/generated/BoardSignatureStatus';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientParam } from './../proto/generated/ClientParam';
import { Board } from './../proto/generated/Board';
import { User } from './../proto/generated/User';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { UserBoard } from '../proto/generated/UserBoard';
import { TransactionStatus } from '../proto/generated/TransactionStatus';
import { SessionStatus } from '../proto/generated/SessionStatus';
import { Ajax } from '../network/ajax';
import { userRequestNode, boardRequestNode, requestNode } from '../network/requestNode';
import { UserIdentity, MxMyAccountSearchFilter, MxGlobalSearchCategory, MxGlobalSearchOption, MxMyAccountSearchOption, Group, MxMyAccountSearchAssignToOption } from '../api/defines';
import { currentUserId } from '../data/cache/cacheMgr';
import {BoardUser} from "../api/defines";

function updateProfile(user: User): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE, user));
}

function updateActionItemAccessTime(): Promise<ClientResponse> {
    let user: User = {
        action_accessed_time: 0
    };
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_ACTION_ITEM, user));
}

function updateActionItemEnableTime(actionItems: UserBoard[]): Promise<ClientResponse> {
    let user: User = {
        action_items: actionItems
    };
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_ACTION_ITEM, user));
}

function removeAvatar(): Promise<ClientResponse> {
    let user: User = {
        picture: 0,
        picture2x: 0,
        picture4x: 0
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_PICTURES, user));
}

function duplicateBoard(fromBoardId: string, newBoardName: string): Promise<ClientResponse> {
    let user: User = {
        boards: [{
            board: {
                id: fromBoardId
            }
        }]
    }
    
    let board: Board = {
        name: newBoardName
    }

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_DUPLICATE, null, user, board));
}

function updateUserBoardAccessTime(boardId: string, keepUnreadFeedTime?: boolean): Promise<ClientResponse> {
    let user: User = {
        boards: [{ 
            accessed_time: 0,
            board: {
                id: boardId
            }
        }]
    };

    let params: ClientParam[] = [];
    if (keepUnreadFeedTime) {
        params.push({name: ClientRequestParameter.BOARD_REQUEST_KEEP_UNREAD_FEED_TIMESTAMP});
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_USER_BOARD_ENTER, user, params));
}

function updateUserBoard(boardId: string, userBoard: UserBoard): Promise<ClientResponse> {
    userBoard.board = { id: boardId };
    let user: User = {
        boards: [userBoard]
    };

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_USER_BOARD, user));
}

function queryShareBoards(users: UserIdentity[]): Promise<ClientResponse> {
    let boardUsers: BoardUser[] = users.map((u)=>{
        return {
            user: u
        }
    })
    let board: Board = {
        users: boardUsers
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.USER_REQUEST_BOARD_LOOKUP, board));
}

function readUserBoards(boardIds:string[]): Promise<ClientResponse> {
    let params: ClientParam[] = [{
      name: ClientRequestParameter.OUTPUT_FILTER_STRING,
      string_value: '/user/boards?(board.id in ("' + boardIds.join('","') + '"))'
    }];
    let user: User = {
        revision: 0
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_SUBSCRIBE, user, params));
}

function readAllUserBoards(): Promise<ClientResponse> {
    let params: ClientParam[] = [{
      name: ClientRequestParameter.OUTPUT_FILTER_STRING,
      string_value: '/user/boards'
    }, {
        name: ClientRequestParameter.USER_SUBSCRIBE_FILTER_MEET
    }];
    let user: User = {
        revision: 0
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_SUBSCRIBE, user, params));
}


function searchUserBoards(searchKey: string, startIndex:number = 0, pageSize:number = 21, boardId?: string): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.BOARD_REQUEST_SEARCH_TEXT,
        string_value: searchKey
    }, {
        name: ClientRequestParameter.BOARD_REQUEST_SEARCH_SIZE,
        string_value: String(pageSize)
    }, {
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_START,
        string_value: String(startIndex)
    }];

    let board: Board = boardId ? { id: boardId } : null;
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SEARCH_BOARD, board, params));      
}

function searchUserBoardsByMember(searchKey: string, startIndex:number = 0, pageSize:number = 21): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.BOARD_REQUEST_SEARCH_TEXT,
        string_value: searchKey
    }, {
        name: ClientRequestParameter.BOARD_REQUEST_SEARCH_SIZE,
        string_value: String(pageSize)
    }, {
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_START,
        string_value: String(startIndex)
    }, {
        name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_USER
    }];

    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_SEARCH, params));      
}

function changePassword(old_pass: string, pass: string): Promise<ClientResponse> {
    let user: User = {
        pass: pass,
        old_pass: old_pass
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_CHANGE_PASSWORD, user));
}

function resetPassword(email: string) {
    let user: User = {
        email: email,
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESET_PASSWORD, user));
}

function resetPasswordWithPhoneNum(phoneNum: string, verifyCode: string, password: string) {
    let user: User = {
        phone_number: phoneNum,
        email_verification_code: verifyCode,
        pass: password
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESET_PASSWORD, user));
}

function resetPasswordWithEmail(email: string, verifyCode: string, password: string) {
    let user: User = {
        email: email,
        email_verification_code: verifyCode,
        pass: password
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESET_PASSWORD, user));
}

function resetPasswordWithToken(pass: string, token: string) {
    let user: User = {
        pass: pass,
        email_verification_token: token
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESET_PASSWORD, user));
}

function resendVerificationEmail(clientRelationSeq?: number): Promise<ClientResponse> {
    let user: User = {};
    if (clientRelationSeq) {
        user.relations = [{sequence: clientRelationSeq}];
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESEND_VERIFICATION_EMAIL, user));
}

function resendAppDownloadLinkSMS(phoneNumber: string, orgId?: string): Promise<ClientResponse> {
    let user: User = {
        phone_number: phoneNumber
    };
    let group: Group = {
        id: orgId
    };
    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_RESEND_APP_DOWNLOAD_LINK_SMS, null, user, null, group));
}

function createJWT(payload: string, expireAfter?: number): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name: ClientRequestParameter.GROUP_REQUEST_JWT_PAYLOAD,
        string_value: payload
    }];

    if (expireAfter) {
        params.push({
            name: ClientRequestParameter.GROUP_REQUEST_JWT_EXPIRES_AFTER,
            string_value: String(expireAfter)
        });
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.GROUP_REQUEST_JWT_TOKEN, {}, params));
}

function searchMyAccount(fromTime: number, toTime: number, startIndex: number, pageSize: number, filter: MxMyAccountSearchFilter, opt?: MxMyAccountSearchOption): Promise<ClientResponse> {
    let board: Board = {};
    let params: ClientParam[] = [{
        name: ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_FROM,
        string_value: String(fromTime)
    }, {
        name:ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_TO,
        string_value: String(toTime)
    }, {
        name: ClientRequestParameter.USER_REQUEST_SEARCH_SIZE,
        string_value: String(pageSize)
    }, {
        name:ClientRequestParameter.USER_REQUEST_SEARCH_START,
        string_value: String(startIndex)
    }, {
        name:ClientRequestParameter.USER_REQUEST_SEARCH_SORT_BY_TIME
    }];

    let assignee: BoardActor = undefined;

    if (opt) {
        if (opt.createdByMe === true) {
            params.push({
                name: ClientRequestParameter.BOARD_REQUEST_SEARCH_CREATOR,
                string_value: currentUserId
            });
        }else if (opt.createdByMe === false) {
            params.push({
                name: ClientRequestParameter.USER_REQUEST_SEARCH_EXCLUDE_CREATOR,
                string_value: currentUserId
            });
        }

        if (opt.assignedTo === MxMyAccountSearchAssignToOption.ASSIGNED_TO_ME) {
            assignee = {
                user: {
                    id: currentUserId
                }
            }
        }else if (opt.assignedTo === MxMyAccountSearchAssignToOption.ASSIGNED_TO_OTHERS) {
            assignee = {
                user: {
                    id: ""
                }
            }
        }if (opt.assignedTo === MxMyAccountSearchAssignToOption.UNASSIGNED) {
            assignee = {
                is_null: true
            }
        }
    }

    if (filter.startsWith('ESIGN')) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SIGNATURE
        });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATED_OR_ASSIGNED
        });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATED_OR_SUBMITTED
        // });

        
        if (filter === MxMyAccountSearchFilter.ESIGN_MY_TURN) {
            assignee = null;
            board.signatures = [{
                status: BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS,
                signees: [{
                    order_number: '0',
                    actor: {
                        user: {
                            id: currentUserId
                        }
                    }
                }]
            }];
        }else if (filter === MxMyAccountSearchFilter.ESIGN_PENDING) {
            board.signatures = [{
                status: BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS,
                signees: [{
                    order_number: '1',
                    actor: assignee
                }]
            }];
        }else if (filter === MxMyAccountSearchFilter.ESIGN_COMPLETED) {
            board.signatures = [{
                status: BoardSignatureStatus.SIGNATURE_STATUS_COMPLETED,
            }];
        }

        if (assignee && board.signatures.length > 0 && !board.signatures[0].signees) {
            board.signatures[0].signees = [{actor: assignee}];
        }
    }else if (filter.startsWith('FILE')) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_FILE
        });
    }else if (filter.startsWith('TODO')) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_TODO
        });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATED_OR_ASSIGNED
        });

        if (filter === MxMyAccountSearchFilter.TODO_DUE) {
            board.todos = [{
                is_completed: false
            }]

            if (opt && opt.dueFrom) {
                params.push({
                    name: ClientRequestParameter.USER_REQUEST_SEARCH_DUE_FROM,
                    string_value: String(opt.dueFrom)
                });
            }
    
            if (opt && opt.dueTo) {
                params.push({
                    name: ClientRequestParameter.USER_REQUEST_SEARCH_DUE_TO,
                    string_value: String(opt.dueTo)
                });
            }
        }else if (filter === MxMyAccountSearchFilter.TODO_PENDING) {
            board.todos = [{
                is_completed: false
            }]
        }else if (filter === MxMyAccountSearchFilter.TODO_COMPLETED) {
            board.todos = [{
                is_completed: true
            }]
        }

        if (assignee && board.todos && board.todos.length > 0) {
            board.todos[0].assignee = assignee;
        }

    }else if (filter.startsWith('MEETING')) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SESSION
        });

        if (filter === MxMyAccountSearchFilter.MEETING_ALL) {

        }else if (filter === MxMyAccountSearchFilter.MEETING_NEED_TO_ACCEPT) {
            board.users = [{
                user: {
                    id: currentUserId
                },
                status: BoardUserStatus.BOARD_INVITED
            }];
            board.sessions = [{
                session: {
                    session_status: SessionStatus.SESSION_SCHEDULED
                }
            }];
        }else if (filter === MxMyAccountSearchFilter.MEETING_NEED_TO_JOIN) {
            board.users = [{
                user: {
                    id: currentUserId
                }
            }];
            board.sessions = [{
                session: {
                    session_status: SessionStatus.SESSION_STARTED
                }
            }];
        }
    }else if (filter.startsWith('TRANSACTION')) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_TRANSACTION
        });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATED_OR_ASSIGNED
        });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATED_OR_SUBMITTED
        // });

        if (filter === MxMyAccountSearchFilter.TRANSACTION_MY_TURN) {
            board.transactions = [{
                status: TransactionStatus.TRANSACTION_STATUS_ACTIVE,
                steps: [{
                    order_number: '0',
                    assignee: {
                        user: {
                            id: currentUserId
                        }
                    }
                }]
            }];
        }else if (filter === MxMyAccountSearchFilter.TRANSACTION_UPCOMING) {
            board.transactions = [{
                status: TransactionStatus.TRANSACTION_STATUS_ACTIVE,
                steps: [{
                    assignee: {
                        user: {
                            id: currentUserId
                        }
                    }
                }]
            }];
        }else if (filter === MxMyAccountSearchFilter.TRANSACTION_PENDING) {
            board.transactions = [{
                status: TransactionStatus.TRANSACTION_STATUS_ACTIVE,
                steps: [{
                    order_number: '1',
                    assignee: assignee
                }]
            }];            
        }else if (filter === MxMyAccountSearchFilter.TRANSACTION_COMPLETED) {
            board.transactions = [{
                status: TransactionStatus.TRANSACTION_STATUS_COMPLETED,
            }]; 
        }
    }else if (filter === MxMyAccountSearchFilter.MENTION_ME) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_MENTION_COMMENT
        });
    }
    
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.USER_REQUEST_SEARCH, board, params));
}

function searchGlobal(searchKey: string, fromTime: number, toTime: number, startIndex: number, pageSize: number, category: MxGlobalSearchCategory, opt?: MxGlobalSearchOption): Promise<ClientResponse> {
    let board: Board = {};
    let params: ClientParam[] = [{
        name: ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_FROM,
        string_value: String(fromTime)
    }, {
        name:ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_TO,
        string_value: String(toTime)
    }, {
        name: ClientRequestParameter.USER_REQUEST_SEARCH_SIZE,
        string_value: String(pageSize)
    }, {
        name:ClientRequestParameter.USER_REQUEST_SEARCH_START,
        string_value: String(startIndex)
    }, {
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_TEXT,
        string_value: searchKey
    }];

    if (opt) {
        if (opt.creator) {
            opt.creator.forEach(c => {
                params.push({
                    name: ClientRequestParameter.USER_REQUEST_SEARCH_CREATOR,
                    string_value: c
                });
            })
        }

        if (opt.boardId) {
            opt.boardId.forEach(b => {
                params.push({
                    name: ClientRequestParameter.USER_REQUEST_SEARCH_ID,
                    string_value: b
                });
            })
        }
    }

    if (category === MxGlobalSearchCategory.MESSAGE) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_COMMENT
        });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_PAGE_COMMENT
        });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_TODO_COMMENT
        });
    }else if (category === MxGlobalSearchCategory.FILE) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_FILE
        });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_FOLDER
        // });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_PAGE
        // });
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SIGNATURE
        });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SIGNATURE_PAGE
        // });
    }else if (category === MxGlobalSearchCategory.TODO) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_TODO
        });
    }else if (category === MxGlobalSearchCategory.ESIGN) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SIGNATURE
        });
        // params.push({
        //     name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SIGNATURE_PAGE
        // });
    }else if (category === MxGlobalSearchCategory.TRANSACTION) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_TRANSACTION
        });
    }else if (category === MxGlobalSearchCategory.MEETING) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_SEARCH_BOARD_SESSION
        });
    }   

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.USER_REQUEST_SEARCH, board, params));
}

function createBroadcast(broadcast: UserBroadcast): Promise<ClientResponse> {
    let user: User = {
        broadcasts: [broadcast]
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_CREATE_BROADCAST, user));
}

function updateBroadcast(broadcastSeq: number, broadcast: UserBroadcast): Promise<ClientResponse> {
    let user: User = {
        broadcasts: [{
            ...broadcast,
            sequence: broadcastSeq
        }]
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_BROADCAST, user));
}

function deleteBroadcast(broadcastSeq: number): Promise<ClientResponse> {
    let user: User = {
        broadcasts: [{
            sequence: broadcastSeq,
            is_deleted: true
        }]
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_DELETE_BROADCAST, user));
}

export {
    updateProfile,
    updateActionItemAccessTime,
    updateActionItemEnableTime,
    removeAvatar,
    duplicateBoard,
    queryShareBoards,
    readUserBoards,
    readAllUserBoards,
    searchUserBoards,
    searchUserBoardsByMember,
    updateUserBoardAccessTime,
    updateUserBoard,
    changePassword,
    resetPassword,
    resetPasswordWithPhoneNum,
    resetPasswordWithEmail,
    resetPasswordWithToken,
    resendVerificationEmail,
    createJWT,
    resendAppDownloadLinkSMS,
    searchMyAccount,
    searchGlobal,
    createBroadcast,
    updateBroadcast,
    deleteBroadcast,
}