import { ClientRequest } from '../proto/generated/ClientRequest';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientResponseCode } from "../proto/generated/ClientResponseCode";
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { encodeProtobuf, decodeProtobuf } from '../proto';
import { MxNetworkState, MxCallback } from "../api/defines";
import { ISocket } from "./ISocket";
import { uuid, mxLogger } from "../util";
import { isSubscriptionResponse, isSuccessResponse, isInvalidTokenResponse } from "../util";
import { getSocketUrl, sdkConfig } from "../core/config";
import { MxErr } from "../core/error";
import * as cacheMgr from '../data/cache/cacheMgr';

interface IRequest {
  request: ClientRequest;
  time?: number;
  resolve?: Function;
  reject?: Function;
}


let RECONNECT_INTERVAL = 10 * 1000;
let KEEP_ALIVE_INTERVAL = 20 * 1000;
let KEEP_ALIVE_TIMEOUT = 30 * 1000;

export class WebSocketConnection implements ISocket {
  private _socket: WebSocket;
  private _state: MxNetworkState;
  private _requests: Map<string, IRequest>;
  private _keepAliveTimer: number;
  private _reconnectTimer: number;
  private _lastServerResponseTime: number;
  private _retryCount: number;
  private _networkStateCallback: MxCallback<MxNetworkState>;
  private _subscriptionDataCallback: MxCallback<ClientResponse>;

  constructor(cb1: MxCallback<MxNetworkState>, cb2: MxCallback<ClientResponse>) {
    this._networkStateCallback = cb1;
    this._subscriptionDataCallback = cb2;
    this._requests = new Map<string, IRequest>();
    this._lastServerResponseTime = 0;
    this._state = MxNetworkState.DISCONNECTED;

    this._keepAliveTimer = 0;
    this._reconnectTimer = 0;
    this._retryCount = 0;

    if (sdkConfig.isMeetSdk) {
      RECONNECT_INTERVAL = 10 * 1000;
      KEEP_ALIVE_INTERVAL = 10 * 1000;
      KEEP_ALIVE_TIMEOUT = 15 * 1000;    
    }
  }

  state(): MxNetworkState {
    return this._state;
  }

  setCallback(cb1: MxCallback<MxNetworkState>, cb2: MxCallback<ClientResponse>) {
    this._networkStateCallback = cb1;
    this._subscriptionDataCallback = cb2;
  }

  connect(): void {
    // close previous connection
    this.close();

    this._socket = new WebSocket(getSocketUrl());
    this._socket.binaryType = "arraybuffer";

    this._socket.onopen = this.onSocketOpen();
    this._socket.onclose = this.onSocketClose();
    this._socket.onerror = this.onSocketError();
    this._socket.onmessage = this.onSocketMessage();

    this.updateState(MxNetworkState.CONNECTING);
  }

  reconnect(): void {
    mxLogger.warn('ws reconnect');
    this._retryCount++;
    this.close();
    this.connect();
  }

  close(): void {
    this.stopKeepalive();
    this._lastServerResponseTime = 0;

    if (this._socket) {
      this._socket.onopen = null;
      this._socket.onclose = null;
      this._socket.onerror = null;
      this._socket.onmessage = null;

      this._socket.close();
      this._socket = null;
    }
  }

  send(msg: ClientRequest): Promise<ClientResponse> {
    return new Promise<ClientResponse>((resolve, reject) => {
      if (this._state !== MxNetworkState.CONNECTED) {
        mxLogger.warn('send msg failed: websocket disconnected');
        return reject(MxErr.ClientError(ClientResponseCode.RESPONSE_ERROR_DISCONNECTED));
      }
  
      if (!msg.sequence) {
        msg.sequence = uuid();
      }
      mxLogger.debug('send:', msg);
  
      let req: IRequest = {
        request: msg,
        time: new Date().getMilliseconds(),
      };
      
      this._requests.set(msg.sequence, req);
  
      let data = encodeProtobuf(msg);
      req.resolve = resolve;
      req.reject = reject;
      this._socket.send(data);
    });
  }

  private updateState(st: MxNetworkState) {
    this._state = st;
    if (this._networkStateCallback) {
      this._networkStateCallback(st);
    }

    if (this._state === MxNetworkState.DISCONNECTED) {
      window.clearTimeout(this._reconnectTimer);
      this._reconnectTimer = window.setTimeout(() => {
        if (this._state === MxNetworkState.DISCONNECTED) {
          this.reconnect();
        }
      }, this._retryCount === 0 ? 100 : RECONNECT_INTERVAL);
      // user logged in same account on 2 tabs, one tab logout, another tab will receive websocket close
      // for this case, need to reconnect immediately
    }
  }

  private onSocketOpen()  {
    return (message: any) => {
      mxLogger.warn('ws open');
      this.updateState(MxNetworkState.CONNECTED);
      this.startKeepalive();
      // this.verifyToken('');
    }
  }

  private onSocketClose() {
    return (message: any) => {
      mxLogger.warn('ws close');
      this._lastServerResponseTime = 0;
      this._socket = null;
      this.stopKeepalive();
      this.updateState(MxNetworkState.DISCONNECTED);
    }
  }

  private onSocketError() {
    return (message: any) => {
      mxLogger.warn('ws error');
      this._lastServerResponseTime = 0;
      this._socket = null;
      this.stopKeepalive();
      this.updateState(MxNetworkState.DISCONNECTED);
    }
  }

  private onSocketMessage() {
    return (message: any) => {
      try {
        let response: ClientResponse = decodeProtobuf(message.data);
        this._lastServerResponseTime = Date.now();

        if (response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED 
          || response.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN) {
          this._retryCount = 0;
        }

        mxLogger.debug('recv:', response);
        if (isSubscriptionResponse(response) || isInvalidTokenResponse(response)) {
          this.onRecvSubscriptionData(response);
        }else if (isSuccessResponse(response)) {
          this.onRecvSuccessResponse(response);
        }else {
          this.onRecvErrorResponse(response);
        }
      } catch (e) {
        mxLogger.error('handle server response error:', e);
      }
    }
  }

  private onRecvSubscriptionData(response: ClientResponse): void {
    if (this._subscriptionDataCallback) {
      this._subscriptionDataCallback(response);
    }
  }

  private onRecvSuccessResponse(response: ClientResponse): void {
    if (!response.sequence) return;

    let request: IRequest = this._requests.get(response.sequence);
    if (request && request.resolve) {
      request.resolve(response);
    }

    this._requests.delete(response.sequence);
  }

  private onRecvErrorResponse(response: ClientResponse): void {
    if (!response.sequence) return;

    let request: IRequest = this._requests.get(response.sequence);
    if (request && request.reject && response) {
      request.reject(MxErr.ServerError(response.code, response.detail_code, response.message));
    }

    this._requests.delete(response.sequence);
  }

  verifyToken(accessToken?: string): void {
    if (accessToken) {
      let request: ClientRequest = {
        sequence: uuid(),
        type: ClientRequestType.USER_REQUEST_VERIFY_TOKEN,
        params: [
          {
            name: ClientRequestParameter.USER_REQUEST_READ_SET_COOKIE
          }
        ]
      };
      this.send(request);
    }
  }

  // start ping after login success
  // as first subscrption data may be large, may need several seconds
  ping(): void {
    let data: ClientRequest = {
      type: ClientRequestType.CLIENT_REQUEST_PING,
      sequence:uuid()
    }
    mxLogger.debug('send: ping');

    try {
      this._socket.send(encodeProtobuf(data));
    }catch (e) {
      mxLogger.warn("send ping error:", e);
    }
  }

  startKeepalive() : void {
    this._keepAliveTimer = window.setInterval(() => {
      if (this._lastServerResponseTime > 0 && Date.now() >  this._lastServerResponseTime + KEEP_ALIVE_TIMEOUT) {
          mxLogger.debug('send: ping timeout');
          this.reconnect();
      }else {
        this.ping();
      }
    }, KEEP_ALIVE_INTERVAL);
  }

  stopKeepalive(): void {
    window.clearInterval(this._keepAliveTimer);
    this._keepAliveTimer = 0;
  } 
}
