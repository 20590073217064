import { v4 as uuid } from 'uuid'
import { MxUser, MxISDK, Defines } from 'isdk'
import {Board, BoardFolder, BoardPageType, UserType} from 'isdk/src/api/defines'
import { ClassLogger } from '../../common/decorators'
import { ObjectUtils } from "@commonUtils/object"
import { ArrayUtils } from "@commonUtils/array"
import { CBoardFile } from "@controller/defines/CBoardFile"
import {SignatureController} from '@controller/signature/src/signatureController'
import { FileFormatter } from "@controller/utils/file"
import { BoardSignature } from "isdk/src/proto/generated/BoardSignature"
import { transformError } from "@controller/common/decorators"
import { MxBoard } from 'isdk/src/api/mxBoard'
import { BoardComment } from "isdk/src/proto/generated/BoardComment"
import {CPartialFile,CFile} from "@controller/defines/CPartialFile";
import {getAvaliableName} from './getAvaliableName'
import { BoardEditorType } from 'isdk/src/proto/generated/BoardEditorType'
let fileControllerInstance: FileController = null

@ClassLogger()
export class FileController {
  private binderId: string
  binderInstance: MxBoard
  binderFolderSubscriber: Defines.MxSubscription
  binderSignatureSubscriber: Defines.MxSubscription
  currentUser: MxUser
  private currentRequest: Promise<Defines.Board> | void = null

  constructor(binderId: string) {
    this.binderId = binderId
    this.currentUser = MxISDK.getCurrentUser()
    this.binderInstance = this.currentUser.getCacheBoard(binderId)
  }

  static getInstance (binderId: string): FileController {
      if (fileControllerInstance) {
        if (binderId !== fileControllerInstance.getBinderId()) {
          fileControllerInstance.destroy()
        } else {
          //To fix issue: the user opens a binder, then switch to contacts/calendar page
          // and back to the same binder, the status of the file is not updated in the file module
          fileControllerInstance.setBinderInstance()
        }
      }
      if (!fileControllerInstance) {
        fileControllerInstance = new FileController(binderId)
      }
      return fileControllerInstance
  }

  private getBinderId () {
      return this.binderId
  }

  private setBinderInstance () {
    let binderInstance = this.currentUser.getCacheBoard(this.binderId)
    if (binderInstance) {
      this.binderInstance = binderInstance
    }
  }

  @transformError()
  getBoardFoldersFiles (folderSpath?: string, cancelPrevRequest?: boolean, noCache?: boolean) {
    return new Promise((resolve, reject) => {
      const request = this.binderInstance.listFolder(folderSpath, noCache)
      if (cancelPrevRequest) {
          if (this.currentRequest) {
              MxISDK.abortRequest(this.currentRequest)
              this.currentRequest = null
          }
          this.currentRequest = request
      }
      request.then(board => {
          resolve(this.transformFoldersFiles(board, folderSpath))
      }).catch(reject)
    })
  }

  @transformError()
  listAllFolders (folderSpath?: string) {
      return new Promise(((resolve, reject) => {
          this.binderInstance.listAllFolders(folderSpath).then(board => {
              resolve(board)
          }).catch(reject)
      }))
  }

  /**
   * @param folderSpath - folder spath
   * @param onSuccessCallback - callback function of when subscriber in controller receives new data
   * @param onErrorCallback - callback fucntion of when subscriber in controller receives error info
   *
   * @return void
   */
  subscribeFolder (folderSpath: string, onSuccessCallback: Function, onErrorCallback: Function): void {
    if (this.binderInstance && !this.binderFolderSubscriber) {
      this.binderFolderSubscriber = this.binderInstance.subscribeFolder(folderSpath,
        (board: Defines.Board) => {
          onSuccessCallback(this.transformFoldersFiles(board, folderSpath))
        }
      )
    }
  }

  /**
   * @return null
   */
  unsubscribeFolder (): void {
    if (this.binderFolderSubscriber) {
      this.binderFolderSubscriber.unsubscribe()
      this.binderFolderSubscriber = null
    }
  }

  @transformError()
  renameFolder (folderSpath: string, name: string) {
    return this.binderInstance.renameFolder(folderSpath, name)
  }

  @transformError()
  deleteFolder (folderSpath: string) {
    return this.binderInstance.deleteFolder(folderSpath)
  }

  //@transformError()
  createFolder (name, parentFolderSpath) {
    return new Promise((resolve, reject) => {
      this.binderInstance.createFolder(name, parentFolderSpath).then((board: Defines.Board) => {
        let temp = board;
        if ( temp ){
          temp = temp.folders[0]
          while (temp.folders){
            temp = temp.folders[0]
          }
        }
        let transformedFolders = this.transformFolders([temp], parentFolderSpath)
        resolve({boardId: board.id, folders: transformedFolders})
      }).catch(() => {
        reject()
      })
    })
  }

  @transformError()
  moveFiles (files:CBoardFile[], toFolderPath, comment) {
    return new Promise(((resolve, reject) => {
      const filesSPath:string[] = files.map(file=>file.SPath);
      this.getAvailableFilesName(toFolderPath,files).then((newFiles:CPartialFile[])=> {
        let newNames = newFiles.map(file => file.name);
        this.binderInstance.moveFiles(filesSPath, toFolderPath,newNames).then(() => {
          resolve()
          if (comment) {
            const binderComment: BoardComment = {
              client_uuid: uuid(),
              text: comment
            }
            this.binderInstance.createComment(binderComment)
          }
        }).catch(reject)
      }).catch(reject)
    }))
  }
  @transformError()
  getAvailableFilesNameWithOtherBinder(boardId:string,folderSPath:string, files:CFile[],existFileNames?:string[]):Promise<CPartialFile[]>{
    const mxUser = MxISDK.getCurrentUser();
    return mxUser.loadBoard(boardId).then((board:MxBoard)=>{
      const fileController = new FileController(boardId);
      return fileController.getAvailableFilesName(folderSPath,files,existFileNames).then((files)=>{
        mxUser.unloadBoard(boardId);
        return files;
      })
    })
  }
  @transformError()
  copyFiles(fromBoardId: string, files: CBoardFile[], toBoardId: string, toFolderPath: string, suppressFeed: boolean | null, comment: string) {
    return new Promise((resolve, reject) => {
      const filesSPath = files.map(file=>file.SPath);
      const callback = (newFiles:CPartialFile[])=>{
        let  newNames = newFiles.map(file=>file.name);
        this.binderInstance.copyFiles(fromBoardId, filesSPath, toBoardId, toFolderPath, newNames, suppressFeed).then((board: Defines.Board) => {
          let transformedFiles = this.transformFoldersFiles(board, toFolderPath)
          resolve(transformedFiles.files)
          if (comment) {
            const binderComment: BoardComment = {
              client_uuid: uuid(),
              text: comment
            }
            this.binderInstance.createComment(binderComment)
          }
        }).catch(reject)
      }

      if(fromBoardId !== toBoardId){
       this.getAvailableFilesNameWithOtherBinder(toBoardId,toFolderPath,files).then(callback).catch(reject)
        return;
      }
      this.getAvailableFilesName(toFolderPath,files).then(callback).catch(reject)

    })
  }

  @transformError()
  copyResource (fromBoardId: string, resource: CFile, toBoardId: string, toFolderPath: string, comment: string) {
    return new Promise((resolve, reject) => {
      this.getAvailableFilesNameWithOtherBinder(toBoardId,toFolderPath,[resource]).then((files)=>{
        this.binderInstance.copyResource(fromBoardId, resource.sequence, toBoardId, toFolderPath,files[0].name).then((board: Defines.Board) => {
          resolve(board)
          if (comment) {
            const binderComment: BoardComment = {
              client_uuid: uuid(),
              text: comment
            }
            this.binderInstance.createComment(binderComment)
          }
        }).catch(reject)
      }).catch(reject)
    })
  }

  transformFoldersFiles (board: Board, folderSpath: string) {
    let files
    let folders
    let pathNodes = []
    let result: any = {}
    if (folderSpath) {
      let folderInfo = this.getFolderInfo(board, folderSpath)
      files = folderInfo.folder.files
      folders = folderInfo.folder.folders
      pathNodes = folderInfo.pathNodes
    } else {
      if (this.binderInstance) {
        result.totalSignatures = this.binderInstance.basicInfo.total_signatures
        result.totalEmails = this.binderInstance.basicInfo.total_emails
      }
      files = board.page_groups
      folders = board.folders
    }
    if (files) {
      let filteredFiles = files.filter(f => !f.is_deleted)
      let deletedFiles = files.filter(f => f.is_deleted)
      let transformedFiles = this.transformFiles(filteredFiles, board, folderSpath)
      result.files = deletedFiles.concat(transformedFiles)
    }
    if (folders) {
      let filteredFolders = folders.filter(f => !f.is_deleted && f.folder_type !== 'FOLDER_TYPE_TRANSACTION')
      let deletedFolders = folders.filter(f => f.is_deleted)
      let transformedFolders = this.transformFolders(filteredFolders, folderSpath)
      result.folders = deletedFolders.concat(transformedFolders)
    }
    result.pathNodes = pathNodes

    return result
  }

  getFolderInfo (board: Board, folderSpath: string) {
    let paths = folderSpath.split('.')
    let root
    let path
    let folder
    let pathNodes = []
    let collapsedFolderSpath = ''
    let count = paths.length
    for(let i = 0; i < count; i++) {
      path = ObjectUtils.parseSPath(paths[i]);
      root = board[path.key][0]
      let getDestFolder = () => {
        if (root.sequence === path.attrVal) {
          folder = root
        } else {
          root = root.folders[0]
          getDestFolder()
        }
      }
      getDestFolder()
      if (count > 4) {
        if (pathNodes.length && i > count - 4) {
          if (pathNodes.length === 1) {
            pathNodes.push({
              name: root.name,
              sequence: root.sequence,
              spath: `${collapsedFolderSpath}.folders[sequence=${root.sequence}]`
            })
          } else {
            pathNodes.push({
              name: root.name,
              sequence: root.sequence,
              spath: pathNodes[pathNodes.length - 1].spath + `.folders[sequence=${root.sequence}]`
            })
          }
        } else {
          if (!pathNodes.length) {
            pathNodes.push({})
          }
          if (collapsedFolderSpath) {
            collapsedFolderSpath += `.folders[sequence=${root.sequence}]`
          } else {
            collapsedFolderSpath = `folders[sequence=${root.sequence}]`
          }
        }
      } else {
        if (pathNodes.length) {
          pathNodes.push({
            name: root.name,
            sequence: root.sequence,
            spath: pathNodes[i - 1].spath + `.folders[sequence=${root.sequence}]`
          })
        } else {
          pathNodes.push({
            name: root.name,
            sequence: root.sequence,
            spath: `folders[sequence=${root.sequence}]`
          })
        }
      }
    }
    return { folder, pathNodes }
  }

  transformFiles (files:any, board:any, spath?: string):CBoardFile[] {
    let transformedFiles:CBoardFile[] = []
    let queue:number[] = []
    let pages = board.pages || []
    pages = pages.filter((page) => {
      if (!page.original_page_number || page.original_page_number === 1) {
        return true
      }
      return false
    })
    files.forEach((file: CBoardFile) => {
      if (queue.indexOf(file.sequence) === -1) {
        let page = ArrayUtils.getFromArray(pages, file.client_uuid, 'page_group')
        let formatedFile = this.transformFile(file, page, board, spath)
        transformedFiles.push(formatedFile)
        queue.push(file.sequence)
      }
    })
    return transformedFiles
  }

  transformFile (file: any, page: any, board: any, spath?: string):CBoardFile {
    let resource = FileFormatter.getFileResource(file, board);
    let path = [];
    if (spath) {
      path.push(spath);
      path.push(`files[sequence=${file.sequence}]`);
    } else {
      path.push(`page_groups[sequence=${file.sequence}]`);
    }
    let result:CBoardFile = {
      name: file.name || page && page.name || resource && resource.name,
      thumbnail: FileFormatter.getPageThumbnail(page, board),
      thumbnailRotate: page && page.rotate || 0,
      pageSequence:page && page.sequence,
      sequence: file.sequence,
      client_uuid: file.client_uuid,
      is_deleted: file.is_deleted || false,
      creator: FileFormatter.getFileCreator(file, resource, page, this.binderInstance.basicInfo),
      fileType: FileFormatter.getFileType(resource, page),
      pageType: page && page.page_type,
      totalPages: 1,
      SPath: path.join('.')
    }
    let updated_time
    if (page && page.background) {
      result.previewBackground = `${MxISDK.getContextPath()}/board/${board.id}/${page.sequence}/${page.background}`
    }
    if (page && page.page_type === BoardPageType.PAGE_TYPE_WEB) {
      updated_time = file.updated_time > page.updated_time ? file.updated_time : page.updated_time
      if (result.fileType !== 'EML') {
        if (this.currentUser && this.currentUser.basicInfo.type === UserType.USER_TYPE_LOCAL) {
          result.isEditable = false
        } else {
          result.isEditable = true
        }
        if (page.editor_type === BoardEditorType.EDITOR_TYPE_INTERNAL_ONLY) {
          result.editableType = 1
        } else {
          result.editableType = 0
        }
      }
    } else {
      updated_time = file.updated_time || page && page.updated_time
    }
    if(updated_time){
      result.updated_time = updated_time
    }
    let created_time = file.created_time || page && page.created_time
    if(created_time){
      result.created_time = created_time
    }
    let original_created_time = file.original_created_time || page && page.original_created_time
    if(original_created_time) {
      result.original_created_time = original_created_time
    }
    if (page) {
      FileFormatter.processEditorActor(page, this.binderInstance.basicInfo,result)
    }
    if (resource) {
      if (resource.total_pages) {
        result.totalPages = resource.total_pages
      }
      result.resourceStatus = resource.status
      result.convertedPage = resource.converted_pages
      result.resourceSequence = resource.sequence

      if (resource.is_password_protected) {
        result.isPasswordProtected = true
      }
    }
    return result
  }

  transformFolders (folders:any, spath?: string) {
    return folders.map((folder: any) => {
      let path = []
      if (spath) {
        path.push(spath)
      }
      path.push(`folders[sequence=${folder.sequence}]`)
      folder.SPath = path.join('.')
      return folder
    })
  }

    transformSignatureFiles (signatures:BoardSignature[], binderId:string){
        return signatures.filter((sign)=>{
            return sign.status !== 'SIGNATURE_STATUS_PREPARING'
        }).map(sign => {
            if (sign.is_deleted) {
              return sign
            }
            let firstPage = sign.pages[0];
            let fileType = '';
            if (firstPage) {
              fileType = FileFormatter.getFileType(null, firstPage)
            }
            let signCtrlInstance = new SignatureController(binderId, sign);
            const isWaitMySign = signCtrlInstance.isWaitMySign();
            const creator = FileFormatter.getFileCreator(sign, null, firstPage, this.binderInstance)
            const {signees, isInProgress, isEnded, isDeclined} = signCtrlInstance
            signCtrlInstance = null
            return {
                ...sign,
                SPath: `signatures[sequence=${sign.sequence}]`,
                signees,
                isWaitMySign,
                fileType,
                isInProgress,
                creator,
                isEnded,
                isDeclined,
                isOwner: creator.id === this.currentUser.id,
                originalSignature: sign
            }
        })
    }

    getSignatureFileLists () {
        return new Promise((resolve, reject) => {
            this.binderInstance.listSignatures().then(board => {
                resolve(this.transformSignatureFiles(board.signatures, this.binderInstance.id))
            })
                .catch(reject)
        })
    }

    subscribeSignatureList (onSuccessCallback: Function, onErrorCallback: Function): void {
        if (this.binderInstance && !this.binderSignatureSubscriber) {
            this.binderSignatureSubscriber = this.binderInstance.subscribeSignatures(
                (signatures: BoardSignature[]) => {
                    onSuccessCallback(this.transformSignatureFiles(signatures, this.binderInstance.id))
                }
            )
        }
    }

    unsubscribeSignatureList (): void {
        if (this.binderSignatureSubscriber) {
            this.binderSignatureSubscriber.unsubscribe()
            this.binderSignatureSubscriber = null
        }
    }


  findFolderFromResponse(folderName:string, sequence: number, board: Board) :any{

    for ( const folder of board.folders){
      let result = this.findFromFolder(folderName, sequence, folder)
      return result
    }
  }

  findFromFolder(folderName:string, sequence: number, boardFolder: BoardFolder) :any{

    if ( boardFolder && boardFolder.name === folderName && boardFolder.sequence === sequence ){
      return {foundFolders:boardFolder.folders, foundFiles:boardFolder.files}
    }

    if ( boardFolder.folders ){
      for ( let folder of boardFolder.folders ){
        let result = this.findFromFolder(folderName, sequence, folder)
        if (result)
          return result
      }
    }
  }

  checkCurrentInstance (binderId: string, sPath: string) {
    if (this.binderInstance) {
      return new Promise((resolve) => {
        this.getFilesForOtherBoard(sPath, resolve)
      })
    } else {
      return new Promise((resolve) => {
        this.currentUser.loadBoard(binderId).then((MxBoard) => {
          this.binderInstance = MxBoard
          this.getFilesForOtherBoard(sPath, resolve)
        })
      })
    }
  }

  getFilesForOtherBoard(sPath: string, resolve: Function){
    this.binderInstance.listFolder(sPath).then((Board) => {
      let temp = this.transformFoldersFiles(Board, sPath)

      if (temp && temp.files )
        temp.files = temp.files.filter( f=>!f.is_deleted )

      if (temp && temp.folders ) {
        temp.folders = temp.folders.filter(f => !f.is_deleted)
      }

      // Prefetch the next level directory so we can determine if the current node is leaf node
      let folders = temp && temp.folders

      if (folders) {

        Promise.all( folders.map( folder => {
          return this.binderInstance.listFolder(ObjectUtils.getByPath(folder,'SPath')).then( (boardResponse)=>{
            const { foundFolders, foundFiles} = this.findFolderFromResponse(folder.name, folder.sequence, boardResponse)
            folder.folders = foundFolders ? foundFolders.filter( (f=>!f.is_deleted)): []
            folder.files = foundFiles? foundFiles.filter( (f=>!f.is_deleted)) : []
          })
        })).then( ()=> resolve(temp))
      } else {
        resolve(temp)
      }
    })
  }

  deleteSingleSignature(signature){
    let controller = new SignatureController(this.binderInstance.id, signature)
    return controller.deleteSignature()
  }
  @transformError()
  getAvailableFilesName(folderSPath:string, files:CFile[],existFileNames?:string[]):Promise<CPartialFile[]>{
    return this.binderInstance.listFolder(folderSPath).then((board:Defines.Board)=> {
      let existNames: string[] = existFileNames || [];
      let result:CPartialFile[] = [];
      let temp = this.transformFoldersFiles(board, folderSPath)

      if (temp && temp.files)
        temp.files = temp.files.filter(f => !f.is_deleted)

      let existFiles = temp.files || [];
      existNames = existNames.concat(existFiles.map((file: any) => {
        return file.name
      }));
      files.forEach((file) => {
        let newName: string = getAvaliableName(file.name, existNames)
        let info: any = {name: newName};
        if (file.sequence) {
          info.sequence = file.sequence
        }
        result.push(info)

      });
      return result
    })
  }

  getAvaliableName(name: string, existNames: string[]) {
    return getAvaliableName(name, existNames)
  }
  @transformError()
  getAvailableSignatureName(file:CFile):Promise<CPartialFile>{
      return this.binderInstance.listSignatures().then((board:Defines.Board)=>{
        let signatures = this.transformSignatureFiles(board.signatures, this.binderInstance.id) || [];
        let existNames = signatures.map((file: any) => {
          return file.name
        });

        let name: string = getAvaliableName(file.name, existNames)||file.name
        return {
          ...file,
          name
        } as CPartialFile;
      })
  }
  makeUploadUrl(name:string,toFolderSPath:string){
    return this.binderInstance.makeUploadFileUrl(name,toFolderSPath)
  }

  destroy () {
    fileControllerInstance = null
  }

  unloadBoard (selectedBinderId: string, currentBinderId: string) {
    if (selectedBinderId !== currentBinderId) {
      this.currentUser.unloadBoard(selectedBinderId).then(() => {
        this.binderInstance = null
      })
    }
  }

  static isTransactionAttachment (payload: {binderId: string, pageSequence?: number, fileSPath?: string}) {
    return new Promise((resolve, reject) => {
      const boardId = payload.binderId
      const spath = payload.fileSPath && payload.fileSPath.split('.')
      if (spath && spath[0].startsWith('files')) {
        // file not in folder, then not in "Transaction" folder
        resolve(false)
      } else {
        const currUser = MxISDK.getCurrentUser()
        currUser.loadBoard(payload.binderId).then(boardIns => {
          let promise
          if (spath && spath.length) {
            promise = boardIns.listFolder(spath[0])
          } else {
            promise = boardIns.readPageDetail(payload.pageSequence)
          }
          promise.then(board => {
            resolve(ObjectUtils.getByPath(board, 'folders.0.folder_type') === 'FOLDER_TYPE_TRANSACTION')
            currUser.unloadBoard(boardId)
          }).catch(err => {
            reject(err)
            currUser.unloadBoard(boardId)
          })
        }).catch(err => {
          reject(err)
          currUser.unloadBoard(boardId)
        })
      }
    })
  }
}
