import { User } from 'isdk/src/proto/generated/User';
import { Board } from 'isdk/src/proto/generated/Board';
import { Group } from 'isdk/src/proto/generated/Group';
import { ActionObject } from '../proto/generated/ActionObject';

import { MxMockBoardParam } from "../api/defines";
import { MxUser } from '../api/mxUser';
import { MxGroup } from '../api/mxGroup';
import { MxBoard } from '../api/mxBoard';
import { MxMeet } from '../api/mxMeet';

import { MxUserImpl } from './mxUserImpl';
import { MxGroupImpl } from './mxGroupImpl';
import { MxBoardImpl } from './mxBoardImpl';
import { MxMockBoardImpl } from './mxMockBoardImpl';
import { MxMeetImpl } from './mxMeetImpl';

import { UserCache } from '../data/cache/userCache';
import { GroupCache } from '../data/cache/groupCache';
import { MeetCache } from '../data/cache/meetCache';
import { BoardCache } from '../data/cache/boardCache';

export class MxObjectFactory {
    static createMxUser(user: User): MxUser {
        return new MxUserImpl(new UserCache(user));
    }
    
    static createMxBoard(board: Board): MxBoard {
        return new MxBoardImpl(new BoardCache(board));
    }

    static createMxMockBoard(param: MxMockBoardParam, board?: Board): MxBoard {
        return new MxMockBoardImpl(param, board);
    }

    static createMxMeet(board: Board, session: ActionObject): MxMeet {
        return new MxMeetImpl(new MeetCache(board, session));
    }

    static createMxGroup(group: Group): MxGroup {
        return new MxGroupImpl(new GroupCache(group));
    }
}
