export default {
  uploadingFiles(state){
    return state.files && state.files.filter( f => !(f.binderId));
  },

  serviceRequestFiles: (state) => (id) => {
    return state.files && state.files.filter( f => f.binderId === id );
  },
  binderId (state) {
    return state.binderId
  },
  allFilesUploaded (state) {
    if (state.totalFiles) {
      return state.totalFiles === state.uploadedFiles
    }
  }
}
