import {ComponentOption} from "../index"
import Vue from "vue"
import Vuex from 'vuex'
import group from "../../../../views/store/modules/group"
import cardsdk from "../../../../views/store/modules/cardsdk"
import uploader from "../../../../views/store/modules/uploader"
import {Input, Select, Option, Dropdown} from '../../../../vendor/element-ui'
import Spinner from '../../../../views/common/directives/spinner/spinner';
import i18n from '../../../../views/cardsdk/src/i18n';
import VueI18n from "vue-i18n";

export abstract class MxComponent {
    selector: string
    componentId: string
    isShow: boolean
    isDestroy: boolean
    vm: any

    protected constructor(componentOption: ComponentOption) {
        this.selector = componentOption.selector
        this.componentId = componentOption.componentId
        this.isShow = true
        this.isDestroy = false
    }

    show(): MxComponent {
        this.isShow = true
        if (!this.vm) {
            Vue.config.devtools = true //todo
            Vue.use(Vuex)
            Vue.directive('mx-spinner', Spinner)
            Vue.component(Input.name, Input)
            Vue.component(Select.name, Select)
            Vue.component(Option.name, Option)
            Vue.component(Dropdown.name, Dropdown)
            let store = new Vuex.Store({
                modules: { cardsdk, group, uploader}
            })
            Vue.use(VueI18n)
            this.vm = new Vue({
                el: this.selector,
                store,
                i18n,
                components: this.getVueComponent(),
                template: this.getTemplate(),
                data: {
                    show: this.isShow,
                    destroy: this.isDestroy
                }
            })
            // store.dispatch("user/initUserSubscriber", {})
            store.dispatch("group/initGroupSubscriber",{})
        }
        this.vm.show = this.isShow
        return this
    }
    hide(): MxComponent {
        this.isShow = false
        this.vm.show = this.isShow
        return this
    }
    destroy(): MxComponent {
        this.isDestroy = true
        this.vm.destroy = this.isDestroy
        return this
    }
    onEvent(event: string, callback: Function): MxComponent {
        this.vm.$on(event, callback)
        return this
    }
    protected abstract getTemplate(): string
    protected abstract getVueComponent(): {}
}

