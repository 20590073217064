import { MxError } from './../api/defines';
import { ClientResponseCode } from '../proto/generated/ClientResponseCode'

class MxErrorImpl implements MxError {
  code: string;
  detailCode?: string;
  message?: string;
  detail?: Object | string;
  stack?: string;
  
  constructor(code: string, detailCode: string='', message: string='', detail: Object | string='') {
    this.code = code;
    this.detailCode = detailCode;
    this.message = message;
    this.detail = detail;
  }

  toString(): string {
    return 'MxError[code: ' + this.code + ', detailCode: ' + this.detailCode + ', message: ' + this.message + ', detail: ' + this.detail + ']';
  }
}

class MxErr {
  static ServerError(code: string=ClientResponseCode.RESPONSE_ERROR_FAILED, detailCode: string='', message: string='', detail: Object | string=''): MxError {
    return new MxErrorImpl(code, detailCode, message, detail);
  }

  static ClientError(code: string=ClientResponseCode.RESPONSE_ERROR_FAILED, detailCode: string='', message: string=''): MxError {
    return new MxErrorImpl(code, detailCode, message);
  }
  
  static InvalidParam(message: string=''): MxError {
    return new MxErrorImpl(ClientResponseCode.RESPONSE_ERROR_INVALID_REQUEST, '', message || 'Invalid params');
  }

  static NotSupported(): MxError {
    return new MxErrorImpl(ClientResponseCode.RESPONSE_ERROR_INVALID_REQUEST, '', 'Not supported');
  }
}

export {
  MxErr
}
