import {UserController} from '@controller/user/src/userController'
import { ChatController } from '@controller/chat/src/chatController'
import {SRController} from "@controller/cardsdk/src/ServiceRequestController";
import {MxConsts, FunctionUtil, ObjectUtils} from '@commonUtils'
import cardsdk_i18n from '../../../cardsdk/src/i18n'

let userController
let chatController
let srController


const transformToSimpleFeeds = (feeds, state) => {
	if (feeds) {
		let result = feeds.map(feed => {

			let f =  {
				uuid: feed.baseObject.client_uuid,
				id: feed.baseObject.user.id,
				comment: feed.baseObject.text,
				time: feed.baseObject.created_time,
				sequence: feed.sequence,
				avatar: state.userAvatarMapping[feed.baseObject.user.id]
			}
			let name = feed.baseObject.user.first_name
			if (state.userBasicInfo.id === feed.baseObject.user.id && feed.baseObject.user.last_name)
				name =`${name} ${feed.baseObject.user.last_name}`

			f.name = name
			return f
		})
		return result
	}
}

const updateBinderObj = (binderObj, commit) => {
	let userAvatarMapping = {}
	let boardUsers = binderObj.boardUsers
	if (boardUsers && boardUsers.length) {
		boardUsers.forEach(bu => {
			userAvatarMapping[bu.id] = bu.avatar
		})
		commit('setUserAvatarMapping', userAvatarMapping)
	}

	commit('setBinderObj', binderObj)
}


export default {
	createServiceRequest({commit}, {name, description, routing_channel}) {
		return srController.createServiceRequest(name, description, routing_channel)
	},
	initSRSubscriber({state,commit}) {
		if (!userController) {
			userController = UserController.getInstance()
			commit('setUserCap', ObjectUtils.getByPath(userController, 'basicInfo.userCap'))

			const basicInfo = ObjectUtils.getByPath(userController, 'basicInfo')
			commit('setUserBasicInfo', basicInfo)

			if(basicInfo && basicInfo.language){
				cardsdk_i18n.locale = basicInfo.language
			}

		}
		if (!srController ) {
			srController = SRController.getInstance()

			srController.subscribeClientSRBoards( (result)=>{
				commit('setBoards', result)
			})
		}
	},

	async initDetailSubscriber({state, commit}, {binderId}) {
		// if (!binderController) {
		// 	binderController = BinderController.getInstance(binderId)
		// 	binderController.updateAccessTime()
		// }
		let binderObj = await srController.initDetailBinder(binderId, (cBoard)=>{
			let updatedBinderObj = Object.assign({}, state.binderObj, cBoard)
			updateBinderObj(updatedBinderObj, commit)
		})
		updateBinderObj(binderObj, commit)
		userController.updateUserBoardAccessTime(binderId)

		// let binderObj = state.userBoards.find( (ub) => ub.id === binderId)
		commit('setActiveBinderId', binderId)

		chatController = ChatController.getInstance(binderId)

		chatController.getMainFeeds().then((response) => {
			let feeds = response.feeds
			state.currentSequence = feeds && feeds[0].sequence || 0

			feeds = feeds.filter(f => f.Action === 'COMMENT')
			commit('setHasMoreFeeds', response.hasNextPage)

			commit('setFeeds', transformToSimpleFeeds(feeds, state))
		}).catch( (err) => {})

		chatController.subscribeBinderFeeds(
			feeds => {

				// Check if any new attachment or an existing attachment being deleted

				let isFilesChanged = feeds.filter(f => f.BaseType === 'FILE' && ( f.type === 'FEED_PAGES_DELETE'  || f.type === 'FEED_PAGES_CREATE') )

				// FIXME For the interim we simply refetch the files
				if (isFilesChanged && isFilesChanged.length){
					srController.getBoardFoldersFiles(binderId)
						.then(board => {
							let updatedBinderObj = Object.assign({}, state.binderObj, {resources: board.resources})
							updateBinderObj(updatedBinderObj, commit)
						})
				}
				let deletedFeeds = feeds.filter(f => f.is_deleted)
				let commentFeeds = feeds.filter(f => f.Action === 'COMMENT')
				let convertedFeeds = transformToSimpleFeeds(commentFeeds, state)
				convertedFeeds.forEach(feed => commit('addFeed', feed))
				deletedFeeds.forEach(feed => commit('addFeed', feed))
				userController.updateUserBoardAccessTime(binderId)
			},
			err => {

			}
		)

		// userController.subscribeUserRelation((userRelations) => {
		// 	if (userRelations && userRelations.length) {
		// 		userRelations.forEach((ur) => {
		// 			commit('updateUserAvatarMapping', {id: ur.id, avatar: ur.avatar})
		// 		})
		// 	}
		// })



	},

	loadMoreFeeds({commit,state}){
		chatController.getMainFeeds(state.currentSequence, true).then((response) => {
			let feeds = response.feeds
			if ( feeds && feeds.length ){

				if (state.currentSequence === 0 || feeds[0].sequence < state.currentSequence)
					state.currentSequence = feeds[0].sequence

				feeds = feeds.filter(f => f.Action === 'COMMENT')
				commit('setHasMoreFeeds', response.hasNextPage)
				commit('setFeeds', transformToSimpleFeeds(feeds, state))
			}

		})
	},


	createComment({commit,state}, feed){
		const {uuid, comment} = feed
		commit('addPendingFeed', feed)
		return srController.createComment(state.binderId, uuid, comment).then( ()=>{
			// userController.updateUserBoardAccessTime(state.binderId)
		})
	},

	updateBoardAccessTime(state,binderId){
		userController.updateUserBoardAccessTime(binderId)
	},


	closeSR({commit, state}){
		return srController.updateServiceRequestStatus(state.binderId, MxConsts.BoardRoutingStatus.ROUTING_STATUS_CLOSE )
	},

    reopenSR({commit,state}){
        return srController.updateServiceRequestStatus(state.binderId, MxConsts.BoardRoutingStatus.ROUTING_STATUS_OPEN )
    },

	resetState({commit, state}){
		commit('resetState')
		if(chatController){
			chatController.destroy()
			ChatController.destroyInstance()
		}
		srController.leaveSrBinder()

		state.currentSequence = 0
		state.hasMoreFeeds = false

		chatController = null
	},

	updateBaseDomain({state,commit},payload){
		commit('updateBaseDomain',payload)
	},

	destroy(){
		userController && userController.destroy();
		srController && srController.destroy()

		srController = null
		userController = null
	}
}
