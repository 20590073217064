import { OutOfOfficeStatus } from './../proto/generated/OutOfOfficeStatus';
import { User } from '../proto/generated/User';
import { Board } from '../proto/generated/Board';
import { BoardAccessType } from '../proto/generated/BoardAccessType';

export enum MxNetworkState {
	UNREACHABLE = 'UNREACHABLE', REACHABLE = 'REACHABLE', CONNECTING = 'CONNECTING', CONNECTED = 'CONNECTED', DISCONNECTED = 'DISCONNECTED'
}

export enum MxUserState {
	NONE = 'NONE', LOGGED_IN = 'LOGGED_IN', LOGGED_OUT = 'LOGGED_OUT'
}

export enum MxBaseObjectType {
	NONE = 'NONE', FILE = 'FILE', PAGE = 'PAGE', POSITION_COMMENT = 'POSITION_COMMENT', COMMENT = 'COMMENT', TODO = 'TODO', TRANSACTION = 'TRANSACTION', SIGNATURE = 'SIGNATURE', MEET = 'MEET',
}

export enum MxPageElementType {
	PAGE_ELEMENT_TYPE_UNDEFINED = 0, PAGE_ELEMENT_TYPE_ARROW = 10, PAGE_ELEMENT_TYPE_DOUBLE_ARROW = 20, PAGE_ELEMENT_TYPE_SIGNATURE = 30, PAGE_ELEMENT_TYPE_AUDIO_TAG = 40, PAGE_ELEMENT_TYPE_TEXT_TAG = 50, PAGE_ELEMENT_TYPE_SIGN_SIGNATURE = 60, PAGE_ELEMENT_TYPE_SIGN_INITIALS = 70, PAGE_ELEMENT_TYPE_SIGN_DATE = 80, PAGE_ELEMENT_TYPE_SIGN_TEXT = 90, PAGE_ELEMENT_TYPE_SIGN_CHECKBOX = 100
}

export enum MxTokenType {
	GROUP_INVITE_TOKEN, PARTNER_INVITE_TOKEN, BOARD_VIEW_TOKEN, QR_TOKEN, EMAIL_VERIFICATION_TOKEN
}

export enum MxRoutingChannelType {
	ACD = 'ACD', SR = 'SR',
}

export enum MxVerifyCodeAction {
	REGISTER = 'REGISTER', RESET_PASSWORD = 'RESET_PASSWORD',
}

export enum MxResetPasswordChannel {
	EMAIL = 'EMAIL', SMS = 'SMS',
}

export enum MxTagKey {
	// one-one call flag, tag val: 'true' | 'false' 
	MEET_CALL_FLAG = 'API_MXCallFlag',

	// auto recording flag, tag val: '1' | '0' 
	MEET_START_WITH_RECORDING = 'START_WITH_RECORDING',

	// MV-5615: Video preview Intermedia support, tag val: '1' | '0' 
	MEET_ALL_VIDEO_ON = 'API_all_video_on',
	MEET_HOST_VIDEO_ON = 'API_host_video_on',
	MEET_PARTICIPANT_VIDEO_ON = 'API_participant_video_on',

	// MV-5753: SCB: ACL for enabling video in a Meeting
	MEET_DISABLE_VIDEO = 'API_disable_video',
}

// object spath, such as: pages[sequence=100].comments[sequence=109]
export type MxSPath = string;

export const MOCK_BOARD_PREFIX = 'MXLB_';

export interface MxCallback<T> {
	(data: T): void;
}

export interface MxError {
	code: string;
	detailCode?: string;
	message?: string;
}

export interface MxSubscription {
	unsubscribe(): void;
}

export interface ISDKConfig {
	serviceUrl?: string;
	socketUrl?: string;
	contextPath?: string;
	accessToken?: string;
	clientVersion?: string;
	sessionId?: string;
	isWebSdk?: boolean;
	isMeetSdk?: boolean;
	usingWebSocket?: boolean;
	noUserBoard?: boolean;
	useTrailingSlash?: boolean;
}

export interface LoginOption {
	email?: string;
	phone_number?: string;
	pass?: string;
	rememberMe?: boolean;
	notLoadFullUser?: boolean;
	isMep?: boolean;
	isOrgAdmin?: boolean;
	isSuperAdmin?: boolean;
	isPartnerAdmin?: boolean;
}

export interface UserPresence {
	id?: string;
	email?: string;
	unique_id?: string;
	phone_number?: string;
	online?: boolean;
	in_meet?: boolean;
	customized_presence_status?: number;
	customized_presence_message?: string;
	out_of_office?: OutOfOfficeStatus;

	has_push_notification?: boolean;
	accessed_time?: number;
	updated_time?: number;
}

export interface MxBaseObject {
	type?: MxBaseObjectType;
	spath?: MxSPath;
	sequence?: number;
	parentSequence?: number;
}

export interface UserIdentity {
	id?: string;
	email?: string;
	unique_id?: string;
	phone_number?: string;
}


export interface MxSignatureElement {
	pageSequence: number;
	elementSequence: number;
	svgTag: string;
}

export interface MxGlobalSearchOption {
	creator?: string[];
	boardId?: string[];
}


export interface MxInviteMemberOption {
	addDirectly?: boolean;
	noFeed?: boolean;
	noNotification?: boolean;
	accessType?: BoardAccessType;
	message?: string;
}

export enum MxGlobalSearchCategory {
	NONE = 'NONE', 

	MESSAGE = 'MESSAGE',
	FILE = 'FILE',
	TODO = 'TODO',
	ESIGN = 'ESIGN',
	TRANSACTION = 'TRANSACTION',
	MEETING = 'MEETING',
}

export enum MxMyAccountSearchAssignToOption {
	ALL = 'ALL',
	ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
	ASSIGNED_TO_OTHERS = 'ASSIGNED_TO_OTHERS',
	UNASSIGNED = 'UNASSIGNED',	
}

export interface MxMyAccountSearchOption {
	createdByMe?: boolean; //true: me created; false: others created; undefined: showall
	assignedTo?: MxMyAccountSearchAssignToOption;
	dueFrom?: number;
	dueTo?: number;
}

export enum MxMyAccountSearchFilter {
	NONE = 'NONE', 

	// esign
	ESIGN_MY_TURN = 'ESIGN_MY_TURN', // all documents requiring your signature
	ESIGN_PENDING = 'ESIGN_PENDING', // all documents you've created or signed that are still in progress
	ESIGN_COMPLETED = 'ESIGN_COMPLETED', // all finalized esign documents

	// file
	FILE = 'FILE', // All my uploaded files.

	// todo
	TODO_DUE = 'TODO_DUE', // filter by due date
	TODO_PENDING = 'TODO_PENDING', // created by or assigned to you but not yet due
	TODO_COMPLETED = 'TODO_COMPLETED', // all completed todos
	
	// meeting
	MEETING_ALL = 'MEETING_ALL',  // All Meetings I am host or attendee;
	MEETING_NEED_TO_ACCEPT = 'MEETING_NEED_TO_ACCEPT',  // All scheduled meetings I need to accept
	MEETING_NEED_TO_JOIN = 'MEETING_NEED_TO_JOIN',  // All in-progress meetings I need to join now. (no matter I am in session or not)

	// transaction
	TRANSACTION_MY_TURN = 'TRANSACTION_MY_TURN', // all transactions requiring your action
	TRANSACTION_UPCOMING = 'TRANSACTION_UPCOMING', // all transactions you'll need to act in the future
	TRANSACTION_PENDING = 'TRANSACTION_PENDING', // all transactions you've created or involved that are still in progress
	TRANSACTION_COMPLETED = 'TRANSACTION_COMPLETED', // all finalized transactions

	// mention me
	MENTION_ME = 'MENTION_ME',  // All mention me;
}

export interface MxGroupMemberSorter {
	sortField : string;
	sortMethod: string;  // ASC, DESC
}

export interface MxGroupMemberFilter {
	isAdmin?: boolean;
	isLocal?: boolean;
	isInternal?: boolean;
	isNormal?: boolean;
	isPending?: boolean;
	isDeactived?: boolean;
	isForAudit?: boolean;
	includeRelationUser?: boolean;
	includePresence?: boolean;
	role?: number[];
	searchKey?: string;

	sorter?: MxGroupMemberSorter;
}

export interface MxGroupManagementFilter {
	start?: number;      // start sequence
	pageNumber?: number;
	size?: number;
	fromTime?: number;
	toTime?: number;
	searchKey?: string;
	isNormal?: boolean;
	isPending?: boolean;
	belongedTeams?: string[];

	clientFilter?: MxGroupManagementClientFilter;
}

export enum MxGroupManagementClientFilter {
	ALL_CLIENTS = 'ALL_CLIENTS',
	TEAM_CLIENTS = 'TEAM_CLIENTS',
	MY_CLIENTS = 'MY_CLIENTS',
}

export interface MxUpdateEmailPhoneNumParam {
	email?: string;
	phone_number?: string;
	emailOff?: boolean;
	smsOff?: boolean;
}

export interface MxViewBoardAsParam {
	userId?: string;

	viewToken?: string;
}

export interface MxMeetParam {
	topic?: string;
	agenda?: string;
	auto_recording?: boolean;
	dtstart?: number;
	milliseconds_allowed_to_join_before_start?: number;
	rrule?: string;
	timezone?: string;
	scheduled_end_time?: number;
	scheduled_start_time?: number;
	original_board_id?: string;
	reset_member_status?: boolean;
	suppress_notification?: boolean;
	host_video_on?: boolean;
	participant_video_on?: boolean;
	disable_video?: boolean;
}

export interface MxInitMeetOption {
	sessionKey?: string;
	isCall?: boolean; 
	isClip?: boolean;
	allVideoOn?: boolean;
	disableVideo?: boolean;
}

export interface MxCheckMockBoardCallback {
	(): Promise<Board>;
}

export interface MxMockBoardParam {
	name?: string;
	peerUser?: User;
	isConversation?: boolean;
	isRelation?: boolean;
	inviteDirectly?: boolean;
	suppressInviteFeed?: boolean;

	checkFunc?: MxCheckMockBoardCallback;
}

export interface IRequestParam {
	method?: string;
	contentType?: string;
	nocache?: boolean;
	queryParams?: Object;
	accessToken?: string;
	timeout?: number;
}

export interface ConnectorSFDCConfig{
	client_id?: string;
	private_key?: string;
	aud_server?: string;
	user_name?: string;
	job_run_time?: number;
	timezone?: string
}

export interface ConnectorGlobalRelayConfig{
	smtp_server_host?: string;
	smtp_server_port?: string;
	receiver_email?: string;
	smtp_username?: string;
	smtp_password?: string;
	job_run_time?: number;
	timezone?: string
}

export interface ConnectorHubSpotConfig{
	hapikey?: string;
	job_run_time?: number;
	timezone?: string
}

// export pb defines
export { User } from '../proto/generated/User';
export { UserBoard } from '../proto/generated/UserBoard';
export { UserType } from '../proto/generated/UserType'
export { UserCap } from '../proto/generated/UserCap';
export { UserRelation } from '../proto/generated/UserRelation';
export { UserContact } from '../proto/generated/UserContact';
export { UserFavorite } from '../proto/generated/UserFavorite';
export { UserMentionMe } from '../proto/generated/UserMentionMe';
export { UserTag } from './../proto/generated/UserTag';
export { UserQRToken } from './../proto/generated/UserQRToken';
export { OutOfOfficeStatus } from './../proto/generated/OutOfOfficeStatus';
export { UserRelationStatus } from '../proto/generated/UserRelationStatus'
export { SocialType } from '../proto/generated/SocialType';
export { UserBroadcast } from '../proto/generated/UserBroadcast';

export { Board } from '../proto/generated/Board';
export { BoardTag } from '../proto/generated/BoardTag';
export { BoardAccessType } from '../proto/generated/BoardAccessType'
export { BoardUserStatus } from '../proto/generated/BoardUserStatus'
export { BoardUser } from '../proto/generated/BoardUser'
export { BoardComment } from "../proto/generated/BoardComment";
export { BoardPage } from './../proto/generated/BoardPage'
export { BoardPageType } from './../proto/generated/BoardPageType'
export { BoardFolder } from './../proto/generated/BoardFolder'
export { BoardPageGroup } from './../proto/generated/BoardPageGroup'
export { BoardResource } from "../proto/generated/BoardResource";
export { BoardResourceStatus } from "../proto/generated/BoardResourceStatus";
export { BoardSignee } from './../proto/generated/BoardSignee';
export { BoardSignature } from "../proto/generated/BoardSignature";
export { BoardTodo } from '../proto/generated/BoardTodo';
export { BoardReferenceLink } from '../proto/generated/BoardReferenceLink';
export { BoardUserActivity } from "../proto/generated/BoardUserActivity";
export { BoardTransaction } from "../proto/generated/BoardTransaction";
export { RichTextFormat } from '../proto/generated/RichTextFormat';
export { TransactionStep } from './../proto/generated/TransactionStep';

export { UserGroup } from '../proto/generated/UserGroup';
export { Group } from '../proto/generated/Group';
export { GroupCap } from '../proto/generated/GroupCap';
export { GroupSetting } from '../proto/generated/GroupSetting'
export { GroupIntegration } from '../proto/generated/GroupIntegration'
export { GroupUser } from './../proto/generated/GroupUser';
export { GroupUserRole } from './../proto/generated/GroupUserRole';
export { GroupUserRoleType } from '../proto/generated/GroupUserRoleType'
export { GroupSubscriptionStatus } from './../proto/generated/GroupSubscriptionStatus';
export { GroupUserStatus } from './../proto/generated/GroupUserStatus';
export { GroupBoard } from './../proto/generated/GroupBoard';
export { GroupType } from './../proto/generated/GroupType';
export { GroupAccessType } from './../proto/generated/GroupAccessType';
export { UsageStatistics } from './../proto/generated/UsageStatistics';
export { UserActivityLog } from './../proto/generated/UserActivityLog';
export { UserActivityLogEntry } from './../proto/generated/UserActivityLogEntry';
export { UserActivityLogDetail } from './../proto/generated/UserActivityLogDetail';
export { SessionUsageItem } from './../proto/generated/SessionUsageItem';
export { SystemSamlService } from '../proto/generated/SystemSamlService';
export { RoutingConfig } from './../proto/generated/RoutingConfig';
export { RoutingChannel } from './../proto/generated/RoutingChannel';

export { ClientResponse } from './../proto/generated/ClientResponse';
export { ClientResponseCode } from './../proto/generated/ClientResponseCode';
export { ClientResponseDetailCode } from './../proto/generated/ClientResponseDetailCode';
export { SystemPasswordRule } from './../proto/generated/SystemPasswordRule'
export { Partner } from './../proto/generated/Partner';
export { WebApp } from './../proto/generated/WebApp';

export { ActionUserRoster } from '../proto/generated/ActionUserRoster';
export { SessionRecordingStatus } from '../proto/generated/SessionRecordingStatus';
export { DesktopShareConf } from '../proto/generated/DesktopShareConf';
export { AudioConf } from '../proto/generated/AudioConf';
export { TelephonyConf } from '../proto/generated/TelephonyConf';
export { TelephoneNumber } from '../proto/generated/TelephoneNumber';
export { DesktopShareState } from '../proto/generated/DesktopShareState';
export { DesktopShareStatus } from '../proto/generated/DesktopShareStatus';
export { SessionVideoState } from '../proto/generated/SessionVideoState';
export { SessionVideoStatus } from '../proto/generated/SessionVideoStatus';
export { SessionStatus } from '../proto/generated/SessionStatus';
export { ActionObject } from '../proto/generated/ActionObject';
export { ActionPageSwitch } from '../proto/generated/ActionPageSwitch';
export { ActionVideoState } from '../proto/generated/ActionVideoState';
export { ActionLaserPointer } from '../proto/generated/ActionLaserPointer';
export { VideoStatus } from '../proto/generated/VideoStatus';
export { TelephonyDomain } from '../proto/generated/TelephonyDomain';
