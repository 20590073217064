export enum GroupIntegrationType {
  GROUP_INTEGRATION_SAML = 'GROUP_INTEGRATION_SAML',
  GROUP_INTEGRATION_APP = 'GROUP_INTEGRATION_APP',
  GROUP_INTEGRATION_LOCAL = 'GROUP_INTEGRATION_LOCAL',
  GROUP_INTEGRATION_WECHAT = 'GROUP_INTEGRATION_WECHAT',
  GROUP_INTEGRATION_WHATSAPP = 'GROUP_INTEGRATION_WHATSAPP',
  GROUP_INTEGRATION_LINE = 'GROUP_INTEGRATION_LINE',
  GROUP_INTEGRATION_SMTP = 'GROUP_INTEGRATION_SMTP',
  GROUP_INTEGRATION_CRM = 'GROUP_INTEGRATION_CRM'
}
