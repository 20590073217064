import { Spinner } from 'spin.js'

const defaultOptions = {
  lines: 12,            // The number of lines to draw
  length: 7,            // The length of each line
  width: 5,             // The line thickness
  radius: 10,           // The radius of the inner circle
  rotate: 0,            // Rotation offset
  corners: 1,           // Roundness (0..1)
  color: 'rgba(0,0,0,0.3)',        // #rgb or #rrggbb
  direction: 1,         // 1: clockwise, -1: counterclockwise
  speed: 1,             // Rounds per second
  trail: 100,           // Afterglow percentage
  opacity: 1/4,         // Opacity of the lines
  className: 'spinner', // CSS class to assign to the element
  top: 'auto',          // center vertically
  left: '50%',         // center horizontally
  position: 'absolute'  // element position
}
let spinner

export default {
  bind(el, binding, vnode) {
    let opts
    if (binding.value) {
      opts = binding.value;
    } else {
      opts = Object.assign({}, defaultOptions)
    }
    spinner = new Spinner(opts).spin(el)
  }
}
