var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showDetailView
    ? _c("div", { on: { mousedown: _vm.handleActiveEvent } }, [
        _c(
          "div",
          [
            !_vm.showLayOver
              ? _c("div", { staticClass: "button-wrapper" }, [
                  _c("input", {
                    staticClass: "secondary-btn",
                    attrs: { type: "button", value: "New Secure Message" },
                    on: {
                      click: function($event) {
                        _vm.showLayOver = true
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showLayOver
              ? _c(
                  "div",
                  { staticClass: "new-request-container" },
                  [
                    !_vm.submitSuccess
                      ? [
                          _c("div", { staticClass: "new-request-row" }, [
                            _vm._v(" " + _vm._s(_vm.$t("your_msgs")) + " ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "new-request-row",
                              staticStyle: {
                                position: "relative",
                                "margin-bottom": "0",
                                display: "flex"
                              }
                            },
                            [
                              _c("label", { staticClass: "mx-request-label" }, [
                                _vm._v("Request Title")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    position: "static"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      "font-size": "14px",
                                      display: "inline-block",
                                      width: "100%"
                                    },
                                    attrs: { maxlength: "100" },
                                    model: {
                                      value: _vm.newSR.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newSR, "name", $$v)
                                      },
                                      expression: "newSR.name"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "new-request-row" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "Request Type"
                                  },
                                  model: {
                                    value: _vm.newSR.routing_channel,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.newSR,
                                        "routing_channel",
                                        $$v
                                      )
                                    },
                                    expression: "newSR.routing_channel"
                                  }
                                },
                                _vm._l(_vm.allChannels, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ])
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "new-request-row request-description-wrapper"
                            },
                            [
                              _c("label", { staticClass: "mx-request-label" }, [
                                _vm._v("Request Description")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    position: "static"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      id: "mx-description",
                                      rows: "4",
                                      autosize: _vm.isIE
                                        ? false
                                        : { minRows: 4 },
                                      type: "textarea"
                                    },
                                    model: {
                                      value: _vm.newSR.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newSR, "description", $$v)
                                      },
                                      expression: "newSR.description"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "new-request-row" },
                            _vm._l(_vm.fileList, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: item.name || item.uuid,
                                  staticClass: "file-item"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("img", {
                                        staticStyle: { "padding-left": "5px" },
                                        attrs: {
                                          src: _vm.FILE_IMAGE,
                                          alt: "file"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "file-item-name" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.normalize(item.name))
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _vm.isUploading
                                        ? _c(
                                            "span",
                                            { staticClass: "progress-wrapper" },
                                            [
                                              _c("progress", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status < 4,
                                                    expression:
                                                      "item.status < 4"
                                                  }
                                                ],
                                                attrs: { max: "100" },
                                                domProps: {
                                                  value: item.percentage
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: item.status === 4,
                                                      expression:
                                                        "item.status===4"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "attachment-failed"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.attachmentFailureReason(
                                                        item.error
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.isUploading
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "close-icon",
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeAttachment(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  "font-size": "16px",
                                                  "padding-right": "10px"
                                                },
                                                attrs: {
                                                  src: _vm.CLOSE_IMAGE,
                                                  alt: "close"
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "add-attachment new-request-row",
                              attrs: { disabled: _vm.isSubmitting }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: { click: _vm.interceptClick }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "attachment-icon" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "16px",
                                            height: "16px",
                                            viewBox: "0 0 16 16",
                                            version: "1.1",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M6.2735,14.4307 C4.8655,14.4307 3.4575,13.8947 2.3845,12.8227 C0.2405,10.6777 0.2405,7.1887 2.3845,5.0447 L5.5665,1.8627 C5.9575,1.4717 6.5905,1.4717 6.9815,1.8627 C7.3715,2.2527 7.3715,2.8857 6.9815,3.2767 L3.7985,6.4587 C2.4345,7.8237 2.4345,10.0437 3.7985,11.4087 C5.1635,12.7727 7.3835,12.7727 8.7485,11.4087 L12.6375,7.5187 C13.0155,7.1407 13.2235,6.6387 13.2235,6.1047 C13.2235,5.5707 13.0155,5.0687 12.6375,4.6907 C11.8585,3.9107 10.5895,3.9107 9.8095,4.6907 L5.9195,8.5797 C5.7255,8.7747 5.7255,9.0927 5.9205,9.2867 C6.1155,9.4817 6.4325,9.4817 6.6275,9.2867 L9.8095,6.1047 C10.2005,5.7147 10.8335,5.7147 11.2235,6.1047 C11.6145,6.4957 11.6145,7.1287 11.2235,7.5187 L8.0415,10.7017 C7.0665,11.6757 5.4815,11.6757 4.5055,10.7017 C3.5315,9.7257 3.5315,8.1407 4.5055,7.1657 L8.3955,3.2767 C9.9545,1.7167 12.4925,1.7167 14.0515,3.2767 C14.8075,4.0317 15.2235,5.0367 15.2235,6.1047 C15.2235,7.1737 14.8075,8.1777 14.0515,8.9327 L10.1625,12.8227 C9.0905,13.8947 7.6825,14.4307 6.2735,14.4307",
                                              id: "path-1"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    ref: "input",
                                    attrs: {
                                      multiple: "multiple",
                                      disabled: _vm.isSubmitting,
                                      type: "file"
                                    },
                                    on: { change: _vm.onChange }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        "font-weight": "bold",
                                        height: "13px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("add_attachment")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "new-request-row" }, [
                            _c("input", {
                              staticClass: "submit-request-btn",
                              class: { disabled: _vm.isSubmitting },
                              attrs: {
                                type: "button",
                                disabled: _vm.isSubmitting,
                                value: _vm.$t("send_msg")
                              },
                              on: { click: _vm.handleSubmitSR }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "cancel-request-btn",
                              class: { disabled: _vm.isSubmitting },
                              attrs: {
                                type: "button",
                                value: _vm.$t("cancel")
                              },
                              on: { click: _vm.handleCancel }
                            }),
                            _vm._v(" "),
                            _vm.submitState.msg
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      "error-text": _vm.submitState.isError
                                    },
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "normal",
                                      "padding-left": "20px"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.submitState.msg))]
                                )
                              : _vm._e()
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.submitSuccess
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "rgb(64, 173, 117)",
                                "text-align": "center",
                                "font-size": "12px",
                                "font-weight": "600",
                                "line-height": "16px"
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("request_created_success")) +
                                  "\n          "
                              ),
                              _vm.hasAttachmentError
                                ? _c("span", [
                                    _vm._v(
                                      ", but unfortunately the attachment failed."
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "divider" }),
            _vm._v(" "),
            _c("div", { staticClass: "request-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("open_requests")) +
                  " (" +
                  _vm._s(_vm.openCount) +
                  ")"
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.getMyOpenBoards, function(item) {
              return _c("service-request-item", {
                key: item.id,
                attrs: { "sr-board": item },
                on: { "show-detail": _vm.handleShowDetail }
              })
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "request-title",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("completed_requests")) +
                      " (" +
                      _vm._s(_vm.completedCount) +
                      ")"
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "padding-right": "10px" } }, [
                  !_vm.showCompletedRequest
                    ? _c("a", { on: { click: _vm.toggleCompletedRequests } }, [
                        _c("img", {
                          attrs: {
                            src: _vm.ARROW_DOWN_IMAGE,
                            alt: "arrow down"
                          }
                        })
                      ])
                    : _c("a", { on: { click: _vm.toggleCompletedRequests } }, [
                        _c("img", {
                          attrs: { src: _vm.ARROW_UP_IMAGE, alt: "arrow up" }
                        })
                      ])
                ])
              ]
            ),
            _vm._v(" "),
            _vm.showCompletedRequest
              ? [
                  _vm._l(_vm.displayCompletedRequests, function(item) {
                    return _c("service-request-item", {
                      key: item.id,
                      attrs: { "sr-board": item },
                      on: { "show-detail": _vm.handleShowDetail }
                    })
                  }),
                  _vm._v(" "),
                  _vm.hasMoreCompletedRequests
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                            padding: "10px 0"
                          }
                        },
                        [
                          _c("input", {
                            staticClass: "secondary-btn",
                            attrs: {
                              type: "button",
                              value: _vm.$t("load_more")
                            },
                            on: { click: _vm.loadMore }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      ])
    : _c("service-request-detail", {
        attrs: { "sr-board-id": _vm.selectedBinder.id },
        on: { back: _vm.handleBack }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }