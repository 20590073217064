import { User } from '../../proto/generated/User';
import { Contacts } from '../../proto/generated/Contacts';
import { Board } from '../../proto/generated/Board';
import { ClientResponse } from "../../proto/generated/ClientResponse";
import { ClientRequestType } from './../../proto/generated/ClientRequestType';
import { MxObjectFactory } from './../../core/mxObjectFactory';
import { CacheMessage } from './../../proto/generated/CacheMessage';
import { mxLocalStorage } from './../storage/storage';
import { ActionObject } from './../../proto/generated/ActionObject';
import { ClientResponseCode } from './../../proto/generated/ClientResponseCode';
import { Group } from './../../proto/generated/Group';
import { MxCallback, MxUserState, MxSubscription, MOCK_BOARD_PREFIX, MxMockBoardParam } from './../../api/defines';
import { mxLogger, isMeetId, isSubscriptionResponse } from "../../util";
import { publishError } from '../../core/mxISDKImpl';
import { mxEvent, eventType } from '../../core/event';
import { MxErr } from '../../core/error';
import { MxObservable } from '../../core/mxObserver';
import { MxUser } from '../../api/mxUser';
import { MxGroup } from '../../api/mxGroup';
import { MxMeet } from '../../api/mxMeet';
import { MxBoard } from '../../api/mxBoard';
import { Connection } from '../../network/connection';

let currentMeet: MxMeet = null;

let currentSessionId = '';
let currentSessionZone = '';
let currentUserId = '';
let currentOrgId = '';
let currentUserState: MxUserState = MxUserState.NONE;

let currentVerifyTokenResponseUser: User = null;
let currentUser: MxUser = null;
let currentOrg: MxGroup = null;
let anonymousOrg: MxGroup = null;
let cacheBoardsMap: Map<string, MxBoard> = new Map();
let cacheMeetsMap: Map<string, MxMeet> = new Map();

let userStateObservable : MxObservable<MxUserState> = new MxObservable<MxUserState>();
function subscribeUserState(cb: MxCallback<MxUserState>): MxSubscription {
    cb(currentUserState);
    return userStateObservable.subscribe(cb);
}

function updateUserState(state: MxUserState) {
    currentUserState = state;
    userStateObservable.publish(currentUserState);
}

function setCurrentSessionId(sessionId: string) {
    if (sessionId) {
        currentSessionId = sessionId;
    }
}

function setCurrentSessionZone(zone: string) {
    currentSessionZone = zone;
}

function setCurrentMeet(mxMeet: MxMeet) {
    currentMeet = mxMeet;
}

function setCurrentVerifyTokenResponseUser(user: User) {
    currentVerifyTokenResponseUser = user;
}

function onAnonymousOrgCreated(mxGroup: MxGroup) {
    anonymousOrg = mxGroup;
}

function onUserLoggedIn(user: User, org: Group, contacts: Contacts) {
    mxLogger.warn('onUserLoggedIn');

    if (org && org.id) {
        currentOrgId = org.id;
        if (!currentOrg) {
            currentOrg = MxObjectFactory.createMxGroup(org);
        }else {
            currentOrg.onGroupUpdated(org);
        }
    }

    if (user && user.id) {
        currentUserId = user.id;

        // merge user role & cap
        if (currentVerifyTokenResponseUser && currentVerifyTokenResponseUser.id === currentUserId) {
            if (currentVerifyTokenResponseUser.role) {
                user.role = currentVerifyTokenResponseUser.role;
            }

            if (currentVerifyTokenResponseUser.cap) {
                user.cap = currentVerifyTokenResponseUser.cap;
            }
        }
      
        if (!currentUser) {
            currentUser = MxObjectFactory.createMxUser(user);
        }else {
            currentUser.onUserUpdated(user, contacts);
        }

        mxEvent.emit(eventType.USER_LOGGED_IN);
        updateUserState(MxUserState.LOGGED_IN);
    }else {
        mxLogger.error('onUserLoggedIn: user is null');
    }
}

function onUserLoggedOut() {
    if (currentUser) {
        mxEvent.emit(eventType.USER_LOGGED_OUT);
        updateUserState(MxUserState.LOGGED_OUT);
        // trigger global error callback
        publishError(MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN));

        currentUserId = '';
        currentOrgId = '';
        currentUser = null;
        currentOrg = null;
        anonymousOrg = null;
        currentVerifyTokenResponseUser = null;
        cacheBoardsMap.clear();
        cacheMeetsMap.clear();

        Connection.getInstance().release();
    }
}

function onUserUpdated(user?: User, contacts?: Contacts, notification?: CacheMessage) {
    if (currentUser) {
        currentUser.onUserUpdated(user, contacts, notification);
    }
}

function onGroupUpdated(group: Group) {
    if (currentOrg && group.id === currentOrg.id) {
        currentOrg.onGroupUpdated(group);
    }
}

function onCurrentMeetUpdated(board: Board, session: ActionObject) {
    if (currentMeet) {
        currentMeet.onMeetUpdated(board, session);
    }
}

// get board by board id or meet id
function getBoardById(boardOrMeetId: string): MxBoard {
    if (cacheBoardsMap.has(boardOrMeetId)) {
        return cacheBoardsMap.get(boardOrMeetId);
    }

    // load mock board from storage
    if (boardOrMeetId.startsWith(MOCK_BOARD_PREFIX)) {
        let board: Board = mxLocalStorage.getObject(boardOrMeetId) as Board;
        if (board) {
            let param: MxMockBoardParam = board['__param'] || {};
            delete board['__param'];
            let mxBoard = MxObjectFactory.createMxMockBoard(param, board);
            onBoardCreated(boardOrMeetId, mxBoard);
            return mxBoard;
        }
    }

    return null;    
}

function onBoardCreated(boardId: string, mxBoard: MxBoard, param?: MxMockBoardParam) {
    cacheBoardsMap.set(boardId, mxBoard);

    if (boardId.startsWith(MOCK_BOARD_PREFIX)) {
        if (param) {
            mxBoard.board['__param'] = param;
        }
        mxLocalStorage.setObject(boardId, mxBoard.board);
    }
}

function onBoardDeleted(boardId: string) {
    cacheBoardsMap.delete(boardId);

    if (boardId.startsWith(MOCK_BOARD_PREFIX)) {
        mxLocalStorage.removeItem(boardId);
    }
}

// meetKey maybe meetId or boardId
function onMeetBoardCreated(meetKey: string, mxBoard: MxBoard) {
    if (isMeetId(meetKey)) {
        cacheBoardsMap.set(meetKey, mxBoard);
    }

    if (mxBoard.id) {
        cacheBoardsMap.set(mxBoard.id, mxBoard);
    }
}

function onMeetBoardDeleted(meetKey: string) {
    let mxBoard = cacheBoardsMap.get(meetKey);
    if (mxBoard && mxBoard.id) {
        cacheBoardsMap.delete(mxBoard.id);
    }
    cacheBoardsMap.delete(meetKey);
}

function getMeetById(meetId: string): MxMeet {
    if (cacheMeetsMap.has(meetId)) {
        return cacheMeetsMap.get(meetId);
    }

    return null;    
}

function onMeetCreated(meetId: string, mxMeet: MxMeet) {
    cacheMeetsMap.set(meetId, mxMeet);
}

function onMeetDeleted(meetId: string) {
    cacheMeetsMap.delete(meetId);
}

function checkMeetUpdated(board: Board, session: ActionObject) {
    cacheMeetsMap.forEach(mxMeet => {
        if (mxMeet) {
            if (session && session.session_key === mxMeet.getSessionKey()) {
                mxMeet.onMeetUpdated(board, session);
            }else if (board && board.id === mxMeet.getBoardId()) {
                mxMeet.onMeetUpdated(board, session);
            }
        }
    })
}

function onBoardUpdated(board: Board) {
    let mxBoard: MxBoard = cacheBoardsMap.get(board.id);
    if (mxBoard) {
        mxBoard.onBoardUpdated(board);
    }
}


function onRecvSubscriptionData(response: ClientResponse) {
    if (!currentSessionId && isSubscriptionResponse(response)) {
        setCurrentSessionId(response.sequence);
    }

    if (response.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN) {
        return onUserLoggedOut();
    }
    
    if (response.client_message) {
        // notification message
        onUserUpdated(null, null, response.client_message);
    }

    if (response.client_messages) {
        // long polling notification message
        response.client_messages.forEach(msg => {
            onUserUpdated(null, null, msg);
        })
    }

    if (!response.object) {
        return;
    }

    if (response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED) {
        return onUserLoggedIn(response.object.user, response.object.group, response.object.contacts);
    }

    if (response.type === ClientRequestType.GROUP_REQUEST_SUBSCRIBE_SERVICE_REQUESTS) {
        if (currentOrg && response.object.group) {
            currentOrg.onRoutingRequestsUpdated(response.object.group)
        }
        return;
    }

    if (response.object.user || response.object.contacts) {
        onUserUpdated(response.object.user, response.object.contacts);
    }
    
    if (response.object.group) {
        onGroupUpdated(response.object.group);
    }
    
    if (currentMeet) {
        if ( (response.object.board && response.object.board.id === currentMeet.getBoardId()) || 
            (response.object.session && response.object.session.session_key === currentMeet.getSessionKey())) {
                onCurrentMeetUpdated(response.object.board, response.object.session);
        }else if (response.object.board) {
            onBoardUpdated(response.object.board);
        }
    }else {
        if (response.object.board) {
            onBoardUpdated(response.object.board);
        }
    }

    if (response.object.board || response.object.session) {
        checkMeetUpdated(response.object.board, response.object.session);
    }
}


export {
    currentSessionId,
    currentSessionZone,
    currentMeet,
    currentUser,
    currentOrg,
    currentUserId,
    currentOrgId,
    currentVerifyTokenResponseUser,
    anonymousOrg,
    onAnonymousOrgCreated,
    setCurrentSessionId,
    setCurrentSessionZone,
    setCurrentMeet,
    setCurrentVerifyTokenResponseUser,
    subscribeUserState,
    onRecvSubscriptionData,
    onUserLoggedIn,
    onUserLoggedOut,
    onBoardCreated,
    onBoardDeleted,
    onMeetBoardCreated,
    onMeetBoardDeleted,
    getBoardById,
    getMeetById,
    onMeetCreated,
    onMeetDeleted
};