import { UserIdentity, MxViewBoardAsParam, MxInviteMemberOption } from './../api/defines';
import { Connection } from './../network/connection';
import { Ajax } from "../network/ajax";
import { boardRequestNode, boardRequestNodeWithViewAs } from "../network/requestNode";
import { getByPath } from "../util";
import { updateUserBoard } from './user';
import { Board } from "../proto/generated/Board";
import { BoardAccessType } from "../proto/generated/BoardAccessType";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ObjectFeed } from "../proto/generated/ObjectFeed";
import { NotificationLevel } from '../proto/generated/NotificationLevel';
import { BoardUser } from '../proto/generated/BoardUser';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientParam } from './../proto/generated/ClientParam';


function createBoard(board: Board): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE, board));
}

function deleteBoard(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE, {id: boardId}));
}

function updateBoard(boardId: string, board: Board): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE, {id: boardId, ...board}));
}

function convertTempBoardToNormalBoard(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_ISTEMP_OFF, {id: boardId}));
}

function joinBoard(boardId: string, noFeed?: boolean): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params =[{
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    }]
  }

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_JOIN, {id: boardId}, params));
}

function leaveBoard(boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_LEAVE, {id: boardId}));
}

function subscribeBoard(boardId:string, revision:number): Promise<ClientResponse> {
  return Connection.getInstance().subscribeBoard(boardId, revision);
}

function unsubscribeBoard(boardId:string): Promise<ClientResponse> {
  return Connection.getInstance().unsubscribeBoard(boardId);
}

function readCover(boardId: string, viewAs?: MxViewBoardAsParam): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNodeWithViewAs(ClientRequestType.BOARD_REQUEST_READ_COVER, {id: boardId}, null, viewAs));
}

function readBoard(boardId: string): Promise<Board> {
  let board: Board = {id: boardId};
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board)).then((response: ClientResponse) => {
      return getByPath(response, 'object.board');
  });
}


function inviteUser(boardId: string, users: UserIdentity[], option?: MxInviteMemberOption): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  option = option || {};

  if (option.message) {
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_INVITE_MESSAGE,
      string_value: option.message
    })
  }
  if (option.addDirectly) {
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_INVITE_ADD_DIRECTLY
    })
  }
  if(option.noFeed){
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    })
  }

  if(option.noNotification){
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_PUSH_NOTIFICATION_OFF
    })
  }


  let boardUsers: BoardUser[] = [];
  users.forEach(user => {
      boardUsers.push({
          type: option.accessType,
          user: user
      })
  });

  let board: Board = {
      id: boardId,
      users: boardUsers
  };
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_INVITE, board, params));
}

function removeUser(boardId: string, userSequence: number, noFeed?: boolean): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params =[{
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    }]
  }

  let board: Board = {
    id: boardId,
    users: [{
      sequence: userSequence
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_EXPEL, board, params));
}

function setOwner(boardId: string, userSequence: number, noFeed?: boolean): Promise<ClientResponse> {
  let params: ClientParam[] = [];
  if(noFeed){
    params =[{
      name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
    }]
  }

  let board: Board = {
    id: boardId,
    users: [{
      sequence: userSequence
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_OWNER, board, params));
}

function updateUserAccessType(boardId: string, userSequence: number,type: BoardAccessType): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    users: [{
      sequence: userSequence,
      type: type
    }]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_ACCESS_TYPE, board));
}

function readFeedsByPagination(boardId: string, startSequence?: number, sizeBefore?: number, sizeAfter?: number): Promise<ClientResponse> {
    let params: ClientParam[] = [];

    if(sizeBefore){
        params.push({
          name: ClientRequestParameter.BOARD_REQUEST_READ_SIZE_BEFORE,
          uint64_value: sizeBefore
        });
    }

    if(sizeAfter){
      params.push({
        name: ClientRequestParameter.BOARD_REQUEST_READ_SIZE_AFTER,
        uint64_value: sizeAfter
      });
    }

    let board: Board = {id: boardId};
    if(startSequence){
      board.feeds =[{
        sequence: startSequence
      }];
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_FLAT_FEEDS, board, params));
}

function readFlatFeeds(boardId: string, feedSeqs: number[]): Promise<ClientResponse> {
    if (!feedSeqs || feedSeqs.length === 0) {
      Promise.resolve([]);
    }

    let feeds: ObjectFeed[] = feedSeqs.map(e=> {
      return {sequence: e};
    });

    let board: Board = {
      id: boardId, 
      feeds: feeds
    };

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_FLAT_FEEDS, board));
}

function updateTypeIndication(boardId: string): Promise<ClientResponse> {
  let board: Board = {id: boardId};
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_TYPE_INDICATION, board));
}

function readOnGoingDelegateFeeds(boardId:string): Promise<ClientResponse> {
  let board: Board = {id: boardId};
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_ONGOING_DELEGATE_FEEDS, board));
}

function readUserActivities(boardId: string): Promise<ClientResponse> {
  let board: Board = {id: boardId, revision: 0};
  let params: ClientParam[] = [{
      name: ClientRequestParameter.OUTPUT_FILTER_STRING,
      string_value: "/board/user_activities"
  }];
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ, board, params));
}

function updateNotificationLevel(boardId:string, level:NotificationLevel): Promise<ClientResponse> {
  return updateUserBoard(boardId, {
    push_notification_level: level
  });
}

function markFeedAsUnRead(boardId:string, feedSeq: number): Promise<ClientResponse> {
  let board: Board = {
    id: boardId,
    feeds:[
      {
        sequence: feedSeq
      }
    ]
  };

  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SET_FEED_UNREAD, board));
}


export {
  createBoard,
  deleteBoard,
  updateBoard,
  joinBoard,
  leaveBoard,
  subscribeBoard,
  unsubscribeBoard,
  readBoard,
  readCover,
  inviteUser,
  removeUser,
  setOwner,
  updateUserAccessType,
  readFeedsByPagination,
  readFlatFeeds,
  updateTypeIndication,
  readOnGoingDelegateFeeds,
  readUserActivities,
  updateNotificationLevel,
  convertTempBoardToNormalBoard,
  markFeedAsUnRead,
}