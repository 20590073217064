import { WebApp } from './../proto/generated/WebApp';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientRequest } from '../proto/generated/ClientRequest';
import { Ajax } from "../network/ajax";
import { webappRequestNode } from "../network/requestNode";

function readWebApp(appId: string): Promise<ClientResponse> {
    let webapp: WebApp = {
        id: appId
    };

    return Ajax.sendRequest(webappRequestNode(ClientRequestType.WEBAPP_REQUEST_READ, webapp));
}

function createWebApp(partnerId: string, app: WebApp): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.WEBAPP_REQUEST_CREATE, 
        object: {
            partner: {
                id: partnerId
            },
            webapp: app
        }
    };
    return Ajax.sendRequest(req);
}

function updateWebApp(appId: string, app: WebApp): Promise<ClientResponse> {
    app.id = appId;
    return Ajax.sendRequest(webappRequestNode(ClientRequestType.WEBAPP_REQUEST_DELETE, app));
}

function deleteWebApp(appId: string): Promise<ClientResponse> {
    let webapp: WebApp = {
        id: appId
    };
    return Ajax.sendRequest(webappRequestNode(ClientRequestType.WEBAPP_REQUEST_DELETE, webapp));
}

export {
    readWebApp, 
    createWebApp,
    updateWebApp,
    deleteWebApp,
} 