import { Group } from './../proto/generated/Group';
import { UserTag } from './../proto/generated/UserTag';
import { BoardTag } from "../proto/generated/BoardTag";
import { Board } from "../proto/generated/Board";
import { Ajax } from "../network/ajax";
import { boardRequestNode, userRequestNode, groupRequestNode } from "../network/requestNode";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { User } from '../proto/generated/User';
import { MxErr } from '../core/error';
import { ClientResponse } from '../proto/generated/ClientResponse';

enum MxTagType {
    USER,
    BOARD,
    GROUP
}

function createOrUpdateTag(key: string, val: string|number, allTags: BoardTag[] | UserTag[], type: MxTagType, objId?: string): Promise<ClientResponse> {
    let matchedTags: number[] = [];
    let tags: BoardTag[] | UserTag[] = [];
    let tag: BoardTag|UserTag = {name: key};
    if (typeof(val) === 'number') {
      tag.uint64_value = val;
    }else {
      tag.string_value = String(val);
    }

    if (allTags && allTags.length > 0) {
        allTags.forEach(tag => {
            if (tag.name === key) {
                matchedTags.push(tag.sequence);
            }
        })
    }

    if (matchedTags.length === 0) {
        tags.push(tag);
    }else if (matchedTags.length === 1) {
        tag.sequence = matchedTags[0];
        tags.push(tag);
    }else {
        // more than one tag matched, deleted matched tags & create new one
        matchedTags.forEach(seq => {
            tags.push({
                sequence: seq,
                is_deleted: true
            })
        })
        tags.push(tag);
    }

    let reqType: ClientRequestType;
    if (type === MxTagType.USER) {
        let user: User = {
            tags: tags
        }
        return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE, user)); 
    }else if (type === MxTagType.GROUP) {
        let group: Group = { tags: tags }
        return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE, group)); 
    }else if (type === MxTagType.BOARD) {
        let board: Board = {
            id: objId,
            tags: tags
        }
        return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE, board)); 
    }else {
        return Promise.reject(MxErr.InvalidParam());
    }
}


function createOrUpdateTags(_tags: Object, allTags: BoardTag[] | UserTag[], type: MxTagType, objId?: string): Promise<ClientResponse> {
    let tags: BoardTag[] | UserTag[] = [];

    for(let tagName in _tags){
        let tagVal = _tags[tagName];
        let tag: BoardTag | UserTag = {name: tagName};
        if (typeof(tagVal) === 'number') {
            tag.uint64_value = tagVal;
          }else {
            tag.string_value = tagVal;
        }

        let matchedTags: number[] = [];
        allTags && allTags.forEach((_tag) => {
            if (_tag.name === tagName) {
                matchedTags.push(_tag.sequence);
            }
        })

        if (matchedTags.length === 0) {
            tags.push(tag);
        }else if (matchedTags.length === 1) {
            tag.sequence = matchedTags[0];
            tags.push(tag);
        }else {
            // more than one tag matched, deleted matched tags & create new one
            matchedTags.forEach(seq => {
                tags.push({
                    sequence: seq,
                    is_deleted: true
                })
            })
            tags.push(tag);
        }
    }

    if (type === MxTagType.USER) {
        let user: User = {
            tags: tags
        }
        return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE, user)); 
    }else if (type === MxTagType.GROUP) {
        let group: Group = { tags: tags }
        return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE, group)); 
    }else if (type === MxTagType.BOARD) {
        let board: Board = {
            id: objId,
            tags: tags
        }
        return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE, board)); 
    }else {
        return Promise.reject(MxErr.InvalidParam());
    }
}

function deleteTag(key: string, allTags: BoardTag[] | UserTag[], type: MxTagType, objId?: string): Promise<ClientResponse> {
    let tags: BoardTag[] | UserTag[] = [];

    if (allTags && allTags.length > 0) {
        allTags.forEach(tag => {
            if (tag.name === key) {
                tags.push({
                    sequence: tag.sequence,
                    is_deleted: true
                })
            }
        })
    }

    let reqType: ClientRequestType;
    if (type === MxTagType.USER) {
        let user: User = { tags: tags }
        return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE, user)); 
    }else if (type === MxTagType.GROUP) {
        let group: Group = { tags: tags }
        return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_UPDATE, group)); 
    }else if (type === MxTagType.BOARD) {
        let board: Board = {
            id: objId,
            tags: tags
        }
        return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE, board)); 
    }else {
        return Promise.reject(MxErr.InvalidParam());
    }
}

export {
    MxTagType,
    createOrUpdateTag,
    createOrUpdateTags,
    deleteTag,
}