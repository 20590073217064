import {ObjectUtils} from '@commonUtils';

export default {
	groupBasicInfo (state) {
		return state.basic_info;
	},
	groupTags (state) {
		return state.basic_info.tags;
	},
	groupCaps (state) {
		return state.group_caps;
	},
	isEnableAudit (state) {
		return state.group_caps['has_audit']
	},
	isEnableReport (state) {
		return state.group_caps['has_org_report'] === null || state.group_caps['has_org_report'] === undefined  ? state.group_caps['has_audit'] : state.group_caps['has_org_report']
	},
	isAllowClientAccessWebPortal (state, getters) {
		return getters.clientCanAccessWeb;
	},
	isWeChatIntegrationEnabled (state) {
		const activeIntegration = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_WECHAT' && !integration.is_deleted);
		return activeIntegration && activeIntegration.length > 0;
	},
	isLineIntegrationEnabled (state) {
		const activeIntegration = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_LINE' && !integration.is_deleted);
		return activeIntegration && activeIntegration.length > 0;
	},
	isWhatsappIntegrationEnabled (state) {
		const activeIntegration = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_WHATSAPP' && !integration.is_deleted);
		return activeIntegration && activeIntegration.length > 0;
	},
	isSocialChannelEnabled (_, getters) {
		return getters.isWeChatIntegrationEnabled ||
			getters.isLineIntegrationEnabled ||
			getters.isWhatsappIntegrationEnabled;
	},
	isSamlEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.has_saml) {
			return true;
		}
		return false;
	},
	isBrandingEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.has_branding) {
			return true;
		}
		return false;
	},
	isAcdEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_acd) {
			return true;
		}
		return false;
	},
	acdAllowedChannels (state, getters) {
		let tags = getters.groupTags || {}
		if (tags['Maximum_Allowed_Acd_Channels']){
			return  parseInt(tags['Maximum_Allowed_Acd_Channels'], 10)
		} else
			return 1
	},
	allSRChannels (state) {
		let channels = ObjectUtils.getByPath(state.basic_info, 'routing_config.sr_channels') || []
	 	return channels.filter( ch => !ch.is_deleted)
	},
	isServiceRequestEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_service_request) {
			return true;
		}
		return false;
	},
	isSFDCIntegrationEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_sfdc_integration) {
			return true;
		}
		return false;
	},
	sfdcIntegrationConfig(state) {
		const configs = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_CRM' && !integration.is_deleted)
		let sfdcNode = {}
		if(configs && configs.length >= 1) {
			configs.forEach( config => {
				const configStr = config.crm_configuration
				try{
					const obj = JSON.parse(configStr) || {}
					if(obj.aud_server) { //aud_server is specifically for Salesforce configuration, so this means we have found the Salesforce node
						sfdcNode = {
							config: obj,
							sequence: config.sequence
						}
					}
				} catch (e) {}
			})
		}
		return sfdcNode
	},
	isGlobalRelayIntegrationEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_globalrelay_integration) {
			return true;
		}
		return false;
	},
	globalRelayIntegrationConfig (state) {
		const configs = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_CRM' && !integration.is_deleted)
		let sfdcNode = {}
		if(configs && configs.length >= 1) {
			configs.forEach( config => {
				const configStr = config.crm_configuration
				try{
					const obj = JSON.parse(configStr) || {}
					if(obj.service === 'global_relay') {
						sfdcNode = {
							config: obj,
							sequence: config.sequence
						}
					}
				} catch (e) {}
			})
		}
		return sfdcNode
	},
	isHubSpotIntegrationEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_hubspot_integration) {
			return true;
		}
		return false;
	},
	isXeroIntegrationEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_xero_integration) {
			return true;
		}
		return false;
	},
	hubSpotIntegrationConfig(state) {
		const configs = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_CRM' && !integration.is_deleted)
		let sfdcNode = {}
		if(configs && configs.length >= 1) {
			configs.forEach( config => {
				const configStr = config.crm_configuration
				try{
					const obj = JSON.parse(configStr) || {}
					if(obj.service === 'hubspot') {
						sfdcNode = {
							config: obj,
							sequence: config.sequence
						}
					}
				} catch (e) {}
			})
		}
		return sfdcNode
	},
	xeroIntegrationConfig(state) {
		const configs = state.integrations.filter(integration => integration.type === 'GROUP_INTEGRATION_CRM' && !integration.is_deleted)
		let sfdcNode = {}
		if(configs && configs.length >= 1) {
			configs.forEach( config => {
				const configStr = config.crm_configuration
				try{
					const obj = JSON.parse(configStr) || {}
					if(obj.service === 'xero') {
						sfdcNode = {
							config: obj,
							sequence: config.sequence
						}
					}
				} catch (e) {}
			})
		}
		return sfdcNode
	},
	isSubscriptionEnabled (state, getters) {
		let caps = getters.groupCaps;
		if (caps && caps.enable_channel_subscription) {
			return true;
		}
		return false;
	},
	enableVerifyEmail (state, getters) {
		const caps = getters.groupCaps;
		if (caps && caps.ignore_email_verification === true) {
			return false;
		}
		return true;
	},
	clientCanAccessWeb (state, getters) {
		const tags = getters.groupTags || {}
		return !tags.hasOwnProperty('Client_Can_Access_Web') || tags['Client_Can_Access_Web'] === true
	},
	clientCanAccessMobileWeb (state, getters) {
		const tags = getters.groupTags || {}
		return tags.hasOwnProperty('Client_Can_Access_Mobile_Web') && tags['Client_Can_Access_Mobile_Web'] === true
	},
    canAcdClientAddFile (state, getters) {
		const tags = getters.groupTags || {};
		return tags['API_Acd_Show_Add_File'] || false;
	},
	hideTermsPolicy (_, getters) {
		const tags = getters.groupTags || {}
		return tags['Hide_Terms_Policy'] || false
	},
	downloadAppLinks (state, getters) {
		let tags = getters.groupTags || {};
		return {
			androidDownloadLink: tags.OrgConfig_DownloadLink_Android,
			iosDownloadLink: tags.OrgConfig_DownloadLink_iOS
		};
	},
	isAppDownloadLinkConfigured (_, getters) {
	  let tags = getters.groupTags || {}
	  return tags.OrgConfig_DownloadLink_Android || tags.OrgConfig_DownloadLink_iOS
	},
	actionColor (state, getters) {
		const tags = getters.groupTags || {};
		return tags.B_Branding_Color;
	},
	brandingLogoLeft (state, getters) {
		const tags = getters.groupTags || {};
		return tags.Main_Color_Rectangle_Logo_Left;
	},
	brandingLogoCenter (state, getters) {
		const tags = getters.groupTags || {};
		return tags.Main_Color_Rectangle_Logo;
	},
	brandingName (state, getters) {
		const tags = getters.groupTags || {};
		return tags.OrgConfig_BrandName || getters.groupBasicInfo.name;
	},
	groupRoles (state) {
		return state.roles;
	},
	isPhoneNumberEnabled (state, getters) {
		const caps = getters.groupCaps;
		if (caps && caps.enable_phone_number_sign_up === true) {
			return true;
		}
		return false;
	},
	isPhoneNumberPrimary (state, getters) {
		const caps = getters.groupCaps;
		if (caps && caps.primary_sign_up_phone_number === true) {
			return true;
		}
		return false;
	},
	samlIntegrations (state) {
		const samlIntegrations = state.integrations.filter((integration) => integration.type === 'GROUP_INTEGRATION_SAML' && !integration.is_deleted);
		return samlIntegrations;
	},
	groupIntegrations (state) {
		return state.integrations;
	},
	isOnlineBillingOrg (state, getters) {
		return getters.groupCaps.is_online_billing;
	},
	isPrivateMeetEnabled (state) {
		let basic_info = state.basic_info;
		return (basic_info.group_settings && basic_info.group_settings.enable_private_meet);
	},
	enableMobileWebMeetingJoin (state) {
		let basic_info = state.basic_info;
		return (basic_info.group_settings && basic_info.group_settings.enable_mobile_web_meeting_join);
	},
	roleMap (state) {
		let result = {};
		if (state.roles) {
			state.roles.forEach(role => {
				result[role.sequence] = role;
			});
		}
		return result;
	},
	defaultInternalRoleSequence (state) {
		let roles = state.roles || [];
		let defaultSequence = 0;
		roles.forEach(role => {
			if (role.is_default && role.type === 'ROLE_TYPE_NORMAL') {
				defaultSequence = role.sequence;
			}
		});
		return defaultSequence;
	},
	defaultClientRoleSequence (state) {
		let roles = state.roles || [];
		let defaultSequence = 0;
		roles.forEach(role => {
			if (role.is_default && role.type === 'ROLE_TYPE_LOCAL') {
				defaultSequence = role.sequence;
			}
		});
		return defaultSequence;
	},
	allRolesScheduleMeetDisabled (state) {
		let disabled = true
		let roles = state.roles || []
		for (let role of roles) {
			if (role.type === 'ROLE_TYPE_NORMAL' || role.type === 'ROLE_TYPE_LOCAL') {
				if (role.name !== "MXAuditRole" && role.name !== "MXReportRole" && role.meet && role.meet.can_scheduled_meet) {
					disabled = false
					break;
				}
			}
		}
		return disabled
	},
	myTeams (state) {
		return state.myTeams;
	},
	myOwnedTeam (state) {
		return state.myManagedTeams;
	},
	myManagedMembers (state) {
		return state.myManagedMembers
	},
	hideMeetingRecording (state) {
		let basic_info = state.basic_info;
		return (basic_info.group_settings && basic_info.group_settings.hide_meet_recording);
	},
	disableMeetRecordingSharing (state) {
		let basic_info = state.basic_info;
		return (basic_info.group_settings && basic_info.group_settings.disable_meet_recording_sharing);
	},
	remainingDaysForTrial(state){
		let time = state.basic_info.trial_end_time
		let now = Date.now()
		let day = 24 * 60 * 60 * 1000
		return Math.ceil((time - now) / day)
	},
	isM0App (state) {
		return state.isM0App
	},
	isReportsEnabled(state, getters) {
        let caps = getters.groupCaps;
        if (caps && caps.has_org_report) {
            return true;
        } else {
            if (caps.has_audit && (caps.has_org_report === undefined || caps.has_org_report === null)) {
                return true;
            } else {
                return false;
            }
        }
  },
	remoteUploadOptions (state, getters) {
		const tags = getters.groupTags || {};
		let json = {}
		if (tags.Cloud_File_Integration_Options) {
			let string = tags.Cloud_File_Integration_Options
			try {
				json = JSON.parse(string)
				Object.keys(json).forEach((k)=>{
					//delete if value is false so that Object.keys(json).length will be 0, if all services are turned off by OA
					if (!json[k]) {
						delete json[k]
					}
				})
			} catch (e) {
			}
		}
		return json
	},
	isQuicklinksEnabledForOA (state, getters) {
		let tags = getters.groupTags || {}
		return tags['Enable_Quick_Links_For_OA'] || false;		
	},

	enableDigestEmail (state) {
		let basic_info = state.basic_info;
		if(basic_info.group_settings){
			if(basic_info.group_settings.enable_digest_email){
				return true
			}else{
				return false
			}
		}else{
			return false
		}
	},
	showNativeFileSelector (state, getters) {
		return !getters.groupTags.Enable_Share_File_Warning && !Object.keys(getters.remoteUploadOptions).length
	},
	isBroadcastEnabled (state) {
		let enable_broadcast = true
		const basic_info = state.basic_info
		if (basic_info.group_settings && typeof basic_info.group_settings.enable_broadcast === 'boolean') {
			enable_broadcast = basic_info.group_settings.enable_broadcast
		}
		return enable_broadcast
	}
};
