import {ObjectUtils} from '@commonUtils';

export default {

  setBoards(state, payload){
    let userBoards = state.userBoards

    payload.forEach( (newBoard) => {
      let foundIndex = userBoards.findIndex( ub => ub.sequence === newBoard.sequence )

      //In theory SR board can NOT be deleted, so we are not handling is_deleted here
      if ( foundIndex === -1 )
        userBoards.push(newBoard)
      else
        userBoards.splice(foundIndex, 1, newBoard)
    })

    state.userBoards = userBoards.sort((userBoardA, userBoardB) => {
      return userBoardB.weight - userBoardA.weight || userBoardB.timestamp - userBoardA.timestamp
    })
  },

  setBinderObj(state, binderObj){
    state.binderObj = binderObj
  },

  setFeeds(state, payload){
    if ( !state.feeds || state.feeds.length === 0 ){
      state.feeds = []
      state.feeds = payload
    } else
       state.feeds.unshift(...payload)
  },

  resetState(state){
    state.feeds = []
    state.binderId = ''
    state.userAvatarMapping = []
    state.binderObj = {}
  },

  addFeed(state, payload){
    let currentFeeds = state.feeds

    let index = currentFeeds.findIndex( f => f.sequence === payload.sequence || f.uuid === payload.uuid)
    if (index >= 0){
      if (payload.is_deleted){
        currentFeeds.splice(index, 1)
      } else {
        currentFeeds.splice(index, 1, payload)
      }
    } else {
      currentFeeds.push(payload)
    }

  },

  addPendingFeed(state, payload){
    state.feeds.push(payload)
  },

  setPendingFeedFailure(state, payload){
    let pending = state.feeds.findIndex( f => f.uuid === payload )

    if ( pending >= 0 ){
      let replaceFeed = ObjectUtils.cloneDeep(state.feeds[pending])
      replaceFeed.isFailed = true
      state.feeds.splice(pending, 1, replaceFeed)
    }
  },

  setActiveBinderId(state, binderId){
    state.binderId = binderId
  },

  setUserAvatarMapping(state, payload){
    state.userAvatarMapping = payload
  },

  updateUserAvatarMapping(state, {id, avatar}){
    state.userAvatarMapping[id] = avatar
  },

  setHasMoreFeeds(state, payload){
    state.hasMoreFeeds = payload
  },

  updateBaseDomain(state,payload){
    state.baseDomain = payload
  },

  setUserCap(state, payload){
    state.userCap = payload
  },

  setUserBasicInfo(state, payload) {
    state.userBasicInfo = payload
  }
}