import { ClientParam } from './../proto/generated/ClientParam';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { User } from './../proto/generated/User';
import { ClientRequestParameter } from './../proto/generated/ClientRequestParameter';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { Ajax } from './../network/ajax';
import { groupRequestNode, userRequestNode, requestNode } from '../network/requestNode';
import {Group} from "../proto/generated/Group";


function subscribeStripe(token: string, user: User): Promise<ClientResponse> {
  const params = [{
        name: ClientRequestParameter.GROUP_REQUEST_STRIPE_SUBSCRIBE_TOKEN,
        string_value:token
  }];

  return Ajax.sendRequest(userRequestNode(ClientRequestType.GROUP_REQUEST_STRIPE_SUBSCRIBE, user, params))
}

function getStripePublicKey(): Promise<ClientResponse> {
  return Ajax.sendRequest(requestNode(ClientRequestType.GROUP_REQUEST_STRIPE_PUBLISHABLE_KEY))
}

function getStripePrice(group: Group): Promise<any> {
  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_STRIPE_PRICE, group))
}

function getStripeCustomer(): Promise<ClientResponse> {
  return Ajax.sendRequest(requestNode(ClientRequestType.GROUP_REQUEST_STRIPE_CUSTOMER))
}

function getStripeInvoice(start?: string, size?: number): Promise<ClientResponse > {
  let params: ClientParam[] = [];
  if (start) {
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_SEARCH_START,
      string_value:String(start)
    })
  }

  if (size) {
    params.push({
      name: ClientRequestParameter.BOARD_REQUEST_SEARCH_SIZE,
      string_value:String(size)
    })
  }

  return Ajax.sendRequest(requestNode(ClientRequestType.GROUP_REQUEST_STRIPE_INVOICES, params));
}

function getStripeCoupon(): Promise<ClientResponse> {
  return Ajax.sendRequest(requestNode(ClientRequestType.GROUP_REQUEST_STRIPE_COUPON))
}

export {
  subscribeStripe,
  getStripePublicKey,
  getStripeCustomer,
  getStripeInvoice,
  getStripeCoupon,
  getStripePrice
}