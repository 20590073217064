import { Contacts } from './../proto/generated/Contacts';
import { UserIdentity } from './../api/defines';
import { Ajax } from "../network/ajax";
import { contactRequestNode, userRequestNode } from "../network/requestNode";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { User } from '../proto/generated/User';
import * as cacheMgr from '../data/cache/cacheMgr';


function queryUserPresence(users: UserIdentity[]): Promise<ClientResponse> {
    let contacts: Contacts = {
        contacts: []
    }
    users.forEach((user: User) => {
        if (user.id && cacheMgr.currentOrg) {
            let seq: number = cacheMgr.currentOrg.getMemberSequenceByUserId(user.id);
            if (seq > 0) {
                user = {
                    ...user,
                    groups:[{
                        group_sequence: seq
                    }]
                }
            }
        }

        contacts.contacts.push(user);
    })

    return Ajax.sendRequest(contactRequestNode(ClientRequestType.PRESENCE_USER_REQUEST_READ, contacts));
}

function updatePresence(status: number, message: string): Promise<ClientResponse> {
    let user: User = {
        customized_presence_status: status,
        customized_presence_message: message
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.PRESENCE_USER_REQUEST_UPDATE, user));
}

function setOutOfOffice(startTime: number, endTime: number, message: string): Promise<ClientResponse> {
    let user: User = {
        out_of_office: {
            start_time: startTime,
            end_time: endTime,
            message: message
        }
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_UPDATE_OUT_OF_OFFICE, user));
}

 export {
    queryUserPresence,
    updatePresence,
    setOutOfOffice,
} 