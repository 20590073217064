import {Defines, MxAjax, MxGroup, MxISDK} from 'isdk'
import {
  ClientResponseCode,
  GroupSubscriptionStatus,
  GroupUser,
  GroupUserRoleType,
  GroupUserStatus,
  MxGroupManagementFilter,
  UserIdentity,
  UserTag as GroupTag,
  UserType
} from 'isdk/src/api/defines'
import {UserFormatter} from '@controller/utils/user'
import {CGroupUser} from '@controller/defines/CGroupUser'
import {CBaseGroup} from '@controller/defines/CBaseGroup'
import {CQuickLink} from '@controller/defines/CQuickLink'
import {BrowserUtils, ObjectUtils} from '@controller/utils'
import {transformError} from '@controller/common/decorators'
import {OnlineStatus} from '@controller/defines/EOnlineStatus'
import {CAcdChannelInfo} from '@controller/defines/CAcdChannelInfo';


let groupController: GroupController

export class GroupController {
  private currentGroup: MxGroup
  private currentRequest: Promise<Defines.Group> | void = null
  private groupBasicInfoSubscriber: Defines.MxSubscription
  private privilegesResolveCallback: Function
  private quickLinkSubscriber: Defines.MxSubscription
  private currentGetMemberRequest: Promise<Defines.Group> | void = null
  private _tags: object


  private constructor () {
    this.currentGroup = MxISDK.getCurrentOrg()
  }

  get brandName () {
    return this.currentGroup.tags['OrgConfig_BrandName']
  }

  static getContextPath () {
    return MxISDK.getContextPath()
  }

  @transformError()
  static getUserBasicInfoByQRToken (token: string): Promise<CGroupUser> {
    return MxISDK.decodeQRToken(token).then((group: Defines.Group) => {
      const user = GroupController.transformGroupUser(group.members, group.id)[0]
      if (user.avatar) {
        user.avatar = `${user.avatar}?t=${token}`
      }
      return user
    })
  }

  static formatQuickLinks (data: string, filterWebOnly: boolean): CQuickLink[] {
    //filterWebOnly: web client portal and internal portal will filter out platform is android or ios
    //admin will need all the data
    let arr = JSON.parse(data) as any[]
    let result = [] as CQuickLink[]
    if (filterWebOnly) {
      arr = arr.filter((link) => {
        //old data might have no platform field
        return !link.platform || link.platform.indexOf('web') > -1
      })
    }
    if (arr && arr.length) {
      arr.forEach(link => {
        let quickLink = {} as CQuickLink
        quickLink.name = link.name || ''
        quickLink.description = link.description || ''
        quickLink.icon = link.icon || ''
        quickLink.identity = link.identity || ''
        quickLink.order_number = link.order_number || 0
        quickLink.url = link.url || ''
        quickLink.tiled_icon = link.tiled_icon || ''
        result.push(quickLink)
      })
    }
    return result
  }

  get tags () {
    return this._tags
  }

  static transformOrgTags (tags: Array<GroupTag>): object {
    let result = {
      'Show_App_Review': true,
      'Show_Send_Feedback': true,
      'Show_Todo': false,
      'SHOW_MEET_LINK': true,
      'START_WITH_RECORDING': true,
      'Enable_Recording_Meetings': true,
      'Start_Meet_From_Binder': true,
      'Show_Binder_Options': true,
      'Show_Binder_Name': true,
      'Show_Binder_Email_Address': true,
      'Show_New_Binder': true,
      'Show_Meet_Now': true,
      'Show_Schedule_Meet': true,
      'Show_Meet_Share_Screen': true,
      'Enable_Call': true,
      'Enable_Attendee_Annotate_In_Meet': true,
      'Enable_Attendee_Chat_In_Meet': true,
      'Show_Meet_Audio': true,
      'Show_Meet_Video': true,
      'Show_Meet_Remote_Control': true,
      'Enable_Meet_Telephony': true,
      'Allow_Join_Before_Host': true,
      'Disable_Auto_Join_Meet_Audio': true,
      'Show_Presence_Indicator': true,
      'Allow_CopyMove_AnotherBinder': true,
      'Show_Share': true,
      'Enable_VCF': true,
      'Show_Deleted_User_Name': true,
      'Enable_Secure_Token_Url': true,
      'Enable_Welcome_Message': true,
      'Enable_Presence_Status': true,
      'Enable_Calendar': true,
      'Show_Help_Support': true,
      'Hide_Terms_Policy': false,
       OrgConfig_Enable_Customize_Link: false,
      'Enable_Share_File_Warning': false
    }

    const stringTypeTagNames = [
      'B_Branding_Color',
      'B_Incomming_Msg_BgColor',
      'B_Outgoing_Msg_BgColor',
      'B_Branding_Foreground_Color',
      'B_Branding_Background_Color',
      'OrgConfig_BrandName',
      'Main_White_Square_Logo',
      'Main_Color_Rectangle_Logo',
      'Main_White_Rectangle_Logo',
      'Main_Color_Square_Logo',
      'Main_Color_Rectangle_Logo_Left',
      'Email_Header_Logo',
      'Moxtra_Logo_Url',
      'OrgConfig_DownloadLink_iOS',
      'OrgConfig_DownloadLink_Android',
      'OrgConfig_DownloadLink_Mac',
      'OrgConfig_DownloadLink_Windows',
      'White_Logo_Moxtra_Powerby',
      'Note_Watermark_Image',
      'B_Branding_Pre_Login_Background_Image',
      'Sms_Prefix',
      'B_Term_Set_Client',
      'B_Term_Set_Internal',
      'Maximum_Allowed_Acd_Channels',
      'API_CUS_TAC',
      'API_Org_Policy_URL',
      'Quick_Links_Client',
      'Quick_Links_Internal',
      'SAML_Logout_Url',
      'OrgConfig_Customize_Link',
      'OrgConfig_Customize_Link_Label'
    ]

    const integerTypeTagNames = [
        'Session_Timeout_Interval',
        'Web_Session_Timeout_Interval'
    ]

    tags.forEach((tag: GroupTag) => {
      let tagValue: any
      if (integerTypeTagNames.indexOf(tag.name) > -1) {
        tagValue = parseInt(tag.string_value)
      } else if (stringTypeTagNames.indexOf(tag.name) > -1) {
        if (/^\/group\/\w+\/resource\//gi.test(tag.string_value)) {
          tagValue = MxISDK.getContextPath() + tag.string_value
        } else {
          tagValue = tag.string_value
        }
      } else if (tag.string_value === '1' || tag.string_value === 'true') {
        tagValue = true
      } else if (tag.string_value === '0' || tag.string_value === 'false') {
        tagValue = false
      } else {
        tagValue = tag.string_value
      }
      result[tag.name] = tagValue
    })
    return result
  }

  getQuickLinks (isClient, filterWebOnly, resolve: Function): void {
    if (!this.quickLinkSubscriber) {
      let tags = this.currentGroup.tags
      resolve(this.parseQuickLinks(tags, filterWebOnly, isClient))
      this.quickLinkSubscriber = this.currentGroup.subscribeBasicInfo(group => {
        const tags = this.currentGroup.tags
        resolve(this.parseQuickLinks(tags, filterWebOnly, isClient))
      })
    }
  }

  private parseQuickLinks (tags, filterWebOnly, isClient) {
    let quickLinks = isClient ? tags['Quick_Links_Client'] : tags['Quick_Links_Internal']
    if (quickLinks) {
      return GroupController.formatQuickLinks(quickLinks, filterWebOnly)
    } else {
      return []
    }
  }

  static transformGroupUser (members: Array<GroupUser>, id: string, includeAll: boolean = false) {
    let membersList: Array<CGroupUser> = []
    members.forEach((member: GroupUser) => {
      let result: CGroupUser = Object.assign({}, member) as CGroupUser
      Object.assign(result, UserFormatter.transformToBaseUser(member.user))
      result.avatar = GroupController.getGroupUserAvatar(member, id)
      result.uniqueKey = 'G' + result.sequence
      result.onlineStatus = result.user.online ? OnlineStatus.Online : OnlineStatus.Offline
      result.title = result.isInternalUser ? result.title : result.email
      result.isPendingRelation = !result.isInternalUser && member.status === Defines.GroupUserStatus.GROUP_INVITED
      result.type = member.type
      if (includeAll) {
        membersList.push(result)
      } else if (!member.is_deleted && !ObjectUtils.getByPath(member, 'user.disabled')) {
        membersList.push(result)
      }
    })
    return membersList
  }

  static getGroupUserAvatar (groupUser: GroupUser, id: string) {
    let user = groupUser.user
    if (user) {
      let picture = user.picture4x || user.picture2x || user.picture
      if (picture) {
        return `${MxISDK.getContextPath()}/group/${id}/member/${groupUser.sequence}/${picture}`
      } else {
        return UserFormatter.getInitialAvatar(user.first_name || '', user.last_name || '')
      }
    }
    return ''
  }

  @transformError()
  getGroupMember (user: UserIdentity): Promise<CGroupUser> {
    return this.currentGroup.readMember(user).then(group => {
      const members = group.members
      if (members.length) {
        return GroupController.transformGroupUser(members, this.currentGroup.id)[0]
      }
      return null
    })
  }

  getGroupMembers (startSequence = 0, includeAll = false, includeRelationUser = true) {
    const PAGE_SIZE = 200
    return this.currentGroup.searchMembers(startSequence, PAGE_SIZE, {
      includeRelationUser, isInternal: true
    }).then((group: Defines.Group) => {
      const members = (group.members || []) as Array<GroupUser>
      return {
        members: GroupController.transformGroupUser(members, this.currentGroup.id, includeAll), 
        hasNextPage: members.length >= PAGE_SIZE
      }
    })
  }

  searchGroupMembers (startSequence = 0, searchKey: string, includeRelationUser = true, isInternal = true, includeAll = false, isNormal = false): Promise<CGroupUser[]> {
    const PAGE_SIZE = 200
    const request = this.currentGroup.searchMembers(startSequence, PAGE_SIZE, {
      includeRelationUser: includeRelationUser, isInternal: isInternal, searchKey: searchKey, isNormal
    })
    if (this.currentRequest) {
      MxISDK.abortRequest(this.currentRequest)
      this.currentRequest = null
    }
    this.currentRequest = request
    return request.then((group: Defines.Group) => {
      const members = group.members || [] as Array<GroupUser>
      return GroupController.transformGroupUser(members, this.currentGroup.id,includeAll)
    })
  }

  searchAuditableUsers (searchKey: string, startSequence = 0, pageSize = 200, isForAudit = false) {
    const request = this.currentGroup.searchMembers(startSequence, pageSize, {
      searchKey: searchKey,
      isDeactived: false,
      isNormal: true,
      isForAudit
    })
    this.currentRequest = request
    return request.then((group: Defines.Group) => {
      let members = group.members || [] as Array<GroupUser>
      return GroupController.transformGroupUser(members, this.currentGroup.id, true)
    })
  }

  getUsersOnlineStatus (contacts: Array<Defines.User>) {
    const currentUser = MxISDK.getCurrentUser()
    const identities = contacts.map((u) => {
      return {id: u.id}
    })
    if (identities.length > 1000) {
      const request_chunk = []
      while (identities.length > 0) {
        let count = 1000 > identities.length ? identities.length : 1000
        request_chunk.push(currentUser.queryUserPresence(identities.splice(0, count)))
      }
      return Promise.all(request_chunk).then(userStatusArr => {
        let presenceArr = []
        if (userStatusArr && userStatusArr.length) {
          userStatusArr.forEach(status => {
            presenceArr = presenceArr.concat(status)
          })
        }
        return presenceArr.map((user: Defines.User) => {
          return {...user, status: UserFormatter.getUserStatus(user)}
        })
      })
    } else {
      return currentUser.queryUserPresence(identities).then((userStatus: Defines.UserPresence[]) => {
        return (userStatus || []).map((user: Defines.User) => {
          return {...user, status: UserFormatter.getUserStatus(user)}
        })
      })
    }
  }


  get getBasicInfo (): CBaseGroup {
    return GroupController.transformGroupBasicInfo(this.currentGroup.basicInfo, this.brandName)
  }

  static getOrgInfo () {
    return new Promise((resolve, reject) => {
      MxISDK.readOrgInfo()
        .then((group: MxGroup) => {
          const basicInfo = group.basicInfo
          const isOnlineBillingOrg = ObjectUtils.getByPath(basicInfo, 'group_caps.is_online_billing')
          if (basicInfo.status === GroupSubscriptionStatus.GROUP_DISABLED_SUBSCRIPTION || (
            !isOnlineBillingOrg && (basicInfo.status === GroupSubscriptionStatus.GROUP_EXPIRED_SUBSCRIPTION ||
              basicInfo.status === GroupSubscriptionStatus.GROUP_CANCELED_SUBSCRIPTION)
          )) {
            reject({
              isOrgInvalid: true
            })
          } else {
            resolve(GroupController.transformGroupBasicInfo(basicInfo, group.tags['OrgConfig_BrandName']))
          }
        })
        .catch(err => {
          if (err.code === ClientResponseCode.RESPONSE_ERROR_NOT_FOUND || err.code === ClientResponseCode.RESPONSE_ERROR_INVALID_REQUEST) {
            reject({
              isOrgInvalid: true
            })
          }
        })
    })
  }

  static transformRoutingChannels(config:Defines.RoutingConfig, id: string):CAcdChannelInfo[] {
    let transformedChannels = []
    if(config && config.acd_channels) {
      transformedChannels = config.acd_channels.filter(channel => !channel.is_deleted).map(channel => {
        const channlPic = channel.picture
        return {
          channel_id: channel.sequence,
          picture: channlPic ? `/group/${id}/channel/${channel.sequence}/picture?revision=${channlPic}` : '',
          name: channel.name,
          order_number: channel.order_number
        } as CAcdChannelInfo
      })

      transformedChannels = transformedChannels.sort((c1, c2) => parseInt(c1.order_number) - parseInt(c2.order_number))
    }
    return transformedChannels as CAcdChannelInfo[]
  }

  private static transformGroupBasicInfo (group: Defines.Group, brandName: string): CBaseGroup {
    let groupInfo = {} as CBaseGroup
    groupInfo.id = group.id
    groupInfo.name = brandName || group.name
    groupInfo.isOrg = true
    groupInfo.alias = group.alias
    groupInfo.plan_code = group.plan_code
    groupInfo.plan_quantity = group.plan_quantity
    groupInfo.plan_code_local = group.plan_code_local
    groupInfo.plan_quantity_local = group.plan_quantity_local
    groupInfo.commitment_end_time = group.commitment_end_time
    groupInfo.cancellation_request_time = group.cancellation_request_time
    groupInfo.timezone = group.timezone
    groupInfo.tac = group.tac
    groupInfo.group_caps = group.group_caps
    groupInfo.group_settings = group.group_settings
    groupInfo.created_time = group.created_time
    groupInfo.updated_time = group.updated_time
    groupInfo.status = group.status
    groupInfo.routing_config = group.routing_config
    groupInfo.tags = GroupController.transformOrgTags(group.tags || [])
    groupInfo.originalTags = (group.tags || []).filter(tag => {
      return !tag.is_deleted
    }) // complete tags info
    groupInfo.acdChannels = GroupController.transformRoutingChannels(group.routing_config, group.id)

    const sr_channels = ObjectUtils.getByPath(group, 'routing_config.sr_channels')
    groupInfo.isSRChannelsInit = sr_channels && sr_channels.length > 0

    groupInfo.integrations = group.integrations || []
    groupInfo.supportEmail = ObjectUtils.getByPath(group, 'support.user.email', '')
    if (group.customer_id) {
      groupInfo.customer_id = group.customer_id
    }
    if (group.trial_start_time) {
      groupInfo.trial_start_time = group.trial_start_time
    }
    if (group.trial_end_time) {
      groupInfo.trial_end_time = group.trial_end_time
    }

    switch (group.status) {
      case GroupSubscriptionStatus.GROUP_CANCELED_SUBSCRIPTION:
        groupInfo.isCancelled = true
        break
      case GroupSubscriptionStatus.GROUP_DISABLED_SUBSCRIPTION:
        groupInfo.isDisabled = true
        break
      case GroupSubscriptionStatus.GROUP_EXPIRED_SUBSCRIPTION:
        if(groupInfo.trial_start_time === groupInfo.trial_end_time)
          groupInfo.isPendingSubscription = true
        else if (groupInfo.customer_id) {
          groupInfo.isPaymentExpired = true
        } else {
          groupInfo.isTrialExpired = true
        }
        break
      case GroupSubscriptionStatus.GROUP_NORMAL_SUBSCRIPTION:
        groupInfo.isNormal = true
        break
      case GroupSubscriptionStatus.GROUP_PAST_DUE_SUBSCRIPTION:
        groupInfo.isPassedDue = true
        break
      case GroupSubscriptionStatus.GROUP_TRIAL_SUBSCRIPTION:
        groupInfo.isTrial = true
        break
    }

    if (group.partner) {
        groupInfo.partnerId = group.partner.partner.id
    }

    if (group.invitation_tokens) {
      let user = ObjectUtils.getByPath(group.invitation_tokens, '0.creator.user')
      Object.assign(user, UserFormatter.transformToBaseUser(user))
      groupInfo.invitation_tokens = group.invitation_tokens
    }

    return groupInfo
  }


  static getInstance () {
    if (!groupController) {
      groupController = new GroupController()
    }
    return groupController
  }

  isMemberExist (userIdentity: { email?: string, phone_number?: string }): Promise<object> {
    let {email, phone_number} = userIdentity
    return new Promise((resolve, reject) => this.currentGroup.readMember({email, phone_number})
      .then((group: Defines.Group) => {
        const groupUser = ObjectUtils.getByPath(group, 'members.0.user') || {}
        const currentUserImpl = MxISDK.getCurrentUser()
        if (groupUser.type) {
          const userId = groupUser.id
          if (groupUser.type === UserType.USER_TYPE_LOCAL) {
            const hasUserRelation = currentUserImpl.getUserRelationByUserId(userId)
            if (hasUserRelation && !hasUserRelation.is_deleted) {
              reject({
                isMyClient: true,
                isMemberExist: true,
                isDisabledClient: groupUser.disabled
              })
            } else {
              reject({
                isDisabledClient: groupUser.disabled,
                isMemberExist: true
              })
            }
          } else if (groupUser.type === UserType.USER_TYPE_NORMAL) {
            reject({
              isInternalUser: true,
              isMemberExist: true
              // isMyClient: true  // isMyClient is completely unsuited here, remove it.
            })
          }
        } else {
          resolve({
            isMemberExist: true
          })
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code === ClientResponseCode.RESPONSE_ERROR_NOT_FOUND) {
            resolve({
              isMemberExist: false
            })
          } else if (err.code === ClientResponseCode.RESPONSE_ERROR_FAILED) {
            reject({
              isUnknownError: true
            })
          }
        } else {
          reject({
            isUnknownError: true
          })
        }
      }))
  }

  get groupCaps () {
    return this.currentGroup.basicInfo.group_caps
  }

  get groupSettings () {
    return this.currentGroup.group && this.currentGroup.group.group_settings
  }

  get groupRoles () {
    return this.currentGroup.roles
  }

  subscribeGroupBasicInfo (resolve: Function): void {
    if (!this.groupBasicInfoSubscriber) {
      let group = GroupController.transformGroupBasicInfo(this.currentGroup.basicInfo, this.brandName)
      this._tags = group.tags
      resolve(group)
      if (this.privilegesResolveCallback) {
        this.privilegesResolveCallback(group)
      }
      this.groupBasicInfoSubscriber = this.currentGroup.subscribeBasicInfo((group: Defines.Group) => {
        let formattedGroup = GroupController.transformGroupBasicInfo(group, this.brandName)
        this._tags = formattedGroup.tags
        resolve(formattedGroup)
        if (this.privilegesResolveCallback) {
          this.privilegesResolveCallback(formattedGroup)
        }
      })
    }
  }


  subscribeGroupACLInfo (resolve: Function): void {
    this.privilegesResolveCallback = resolve
    resolve()
  }

  getRole (sequence: number, userType: String): Defines.GroupUserRole {
    if (!sequence) {
      return this.currentGroup.roles.find((role: Defines.GroupUserRole) => {
        if (role.is_default) {
          if (userType === UserType.USER_TYPE_LOCAL) {
            return role.type === GroupUserRoleType.ROLE_TYPE_LOCAL
          } else if(userType === UserType.USER_TYPE_NORMAL){
            return role.type === GroupUserRoleType.ROLE_TYPE_NORMAL
          } else {
            return role.type === GroupUserRoleType.ROLE_TYPE_GUEST
          }
        } else {
          return false
        }
      })
    } else {
      return this.currentGroup.roles.find((role: Defines.GroupUserRole) => {
        return role.sequence === sequence
      })
    }
  }

  getUserByEmail (email: string): Promise<CGroupUser> {
    return this.currentGroup.readMember({email: email}).then((group: Defines.Group) => {
      const members = (group.members || []) as Array<GroupUser>
      return GroupController.transformGroupUser(members, this.currentGroup.id).find(member => member.email && member.email.toLowerCase() === email)
    })
  }

  @transformError()
  resendInviteEmail (email: String): Promise<boolean> {
    return this.currentGroup.resendInviteEmail({email: email} as UserIdentity).then(_ => true).catch(_ => false)
  }

  @transformError()
  updateAllPlatformBranding (brandingTag: Object) {
    let brandTag: Defines.BoardTag = {}
    Object.values(brandingTag).forEach(tag => {
      brandTag[tag.name] = tag.value
    })
    return this.currentGroup.createOrUpdateTags(brandTag)
  }

  @transformError()
  updateOnlineBillingBranding (brandingTag: Object, brandLogoTag: Array<Object>) {
    return Promise.all(this.uploadOnlineBillingLogo(brandingTag, brandLogoTag)).then(() => {
      let brandTag: Defines.BoardTag = {}
      Object.values(brandingTag).forEach(tag => {
        brandTag[tag.name] = tag.value
      })
      return this.currentGroup.createOrUpdateTags(brandTag)
    })
  }

  private uploadOnlineBillingLogo (brandingTag: Object, brandLogoTag: Array<Object>): Promise<any>[] {
    const uploadUrl = `/group/${this.currentGroup.id}/upload`
    let requests = [] as Promise<any>[]
    brandLogoTag.forEach(brandLogo => {
      requests.push(MxAjax.post(uploadUrl, BrowserUtils.base64ToBlob(brandLogo['value']), {
        contentType: brandLogo['fileType']
      }).then((response) => {
        const sequence = ObjectUtils.getByPath(response, 'object.group.resources.0.sequence')
        const groupId = ObjectUtils.getByPath(response, 'object.group.id')
        let link = ''
        if (sequence) {
          if (groupId) {
            link = `${MxISDK.getContextPath()}/group/${groupId}/resource/${sequence}`
          } else {
            link = `${MxISDK.getContextPath()}/group/resource/${sequence}`
          }
        }
        brandingTag[brandLogo['tag']].value = link
      }))
    })
    return requests
  }

  @transformError()
  updateGroup (group: Defines.Group): Promise<Defines.Group> {
    return this.currentGroup.updateGroup(group)
  }

  @transformError()
  generateQRToken (user: Defines.User) {
    return MxISDK.getCurrentUser().createQRToken(user)
  }

  destroy () {
    groupController = null
  }

  @transformError()
  static readWebApp (id: string) {
    return MxISDK.getCurrentUser().readWebApp(id)
  }

  @transformError()
  updateMemberEmailPhoneNum (id: string, email: string, phoneNumber: string, emailOff: boolean = true, smsOff: boolean = true) {
    return this.currentGroup.updateMemberEmailPhoneNum(id, {email: email, phone_number: phoneNumber, emailOff, smsOff})
  }

  @transformError()
  inviteMember (user: GroupUser | GroupUser[], updateExisting?: boolean) {
    return new Promise((resolve, reject) => {
      this.currentGroup.inviteMember(user, updateExisting).then(group => {
        let members = GroupController.transformGroupUser(group.members, this.currentGroup.id)
        resolve(members[0])
      }).catch(reject)
    })
  }

  readManagementInternalUsers (fromTime: number, toTime: number, start: number = 0, size: number = 50) {
    let filter = {
      fromTime: fromTime,
      toTime: toTime,
      start: start,
      size: size
    } as MxGroupManagementFilter
    return this.currentGroup.readManagementInternalUsers(filter)
  }

  readManagementClientDetail (userIdentity: {
    email?: string,
    id?: string,
    phoneNumber: string
  }, fromTime: number, toTime: number) {
    let filter = {
      fromTime: fromTime,
      toTime: toTime
    } as MxGroupManagementFilter
    return this.currentGroup.readManagementClientDetail(filter, userIdentity).then(res => {
      let group = res.object.group, peerData = res.report.user_engagement
      return {
        members: group.members.map((member) => {
          let activity = peerData.find(p => p.peer_user.id === member.user.id)
          return {
            name: UserFormatter.getUserName(member.user),
            ...member,
            teams: member.user.groups,
            docSharedCount: activity.doc_shared_count,
            esignCount: activity.esign_count,
            meetCount: activity.meet_count,
            msgCount: activity.msg_count,
            peerDocShareCount: activity.peer_doc_shared_count,
            peerEsignCount: activity.peer_esign_count,
            peerMsgCount: activity.peer_msg_count,
            peerTodoCount: activity.peer_todo_count,
            todoCount: activity.todo_count,
            totalCount: activity.todo_count
              + activity.peer_todo_count
              + activity.peer_msg_count
              + activity.esign_count
              + activity.meet_count
              + activity.msg_count
              + activity.peer_esign_count
              + activity.peer_doc_shared_count
              + activity.doc_shared_count
          }
        })
      }
    })
  }

  readManagementInternalUserDetail (userIdentity: {
    email?: string,
    id?: string,
    phoneNumber: string
  }, fromTime: number, toTime: number) {
    let filter = {
      fromTime: fromTime,
      toTime: toTime
    } as MxGroupManagementFilter
    return this.currentGroup.readManagementInternalUserDetail(filter, userIdentity)
  }

  readManagementTeamDetail (teamId: string, fromTime: number, toTime: number) {
    return this.currentGroup.readManagementTeamDetail({fromTime: fromTime, toTime: toTime}, teamId)
  }

  readManagementShareUserBoards (clientIdentity: {
    email?: string,
    id?: string,
    phoneNumber: string
  }, internalIdentity: {
    email?: string,
    id?: string,
    phoneNumber: string
  }, fromTime: number, toTime: number) {
    return this.currentGroup.readManagementShareUserBoards({
      fromTime: fromTime,
      toTime: toTime
    }, clientIdentity, internalIdentity).then(res => {
      let activity = res.report.user_engagement[0]
      return {
        boards: UserFormatter.transformUserBoards(res.object.user.boards || []),
        docSharedCount: activity.doc_shared_count || 0,
        esignCount: activity.esign_count || 0,
        meetCount: activity.meet_count || 0,
        msgCount: activity.msg_count || 0,
        peerDocShareCount: activity.peer_doc_shared_count || 0,
        peerEsignCount: activity.peer_esign_count || 0,
        peerMsgCount: activity.peer_msg_count || 0,
        peerTodoCount: activity.peer_todo_count || 0,
        todoCount: activity.todo_count || 0
      }
    })
  }

  readManagementClients (fromTime: number, toTime: number, clientFilter: MxGroupManagementFilter, start: number = 0, size: number = 50) {
    let filter = {
      fromTime: fromTime,
      toTime: toTime,
      pageNumber: start,
      size: size,
      clientFilter: clientFilter
    } as MxGroupManagementFilter
    return this.currentGroup.readManagementClients(filter).then((res) => {
      if (!res.object || !res.object.group.total_members) {
        return {
          members: [],
          totalMembers: 0
        }
      }
      let group = res.object.group
 	  return {
        members: GroupController.transformGroupUser(group.members, group.id).map(groupMember => {
          if (!res.report || !res.report.user_activity_summary) {
            return {...groupMember}
          }
          let activitySummary = res.report.user_activity_summary.find(item => item.user.id === groupMember.id) || {}
          return {
            ...groupMember,
            activeRelationTotal: activitySummary.active_relation_total || 0,
            docSharedCount: activitySummary.doc_shared_count || 0,
            esignCount: activitySummary.esign_count || 0,
            meetCount: activitySummary.meet_count || 0,
            msgCount: activitySummary.msg_count || 0,
            todoCount: activitySummary.todo_count || 0,
            totalCount: (activitySummary.msg_count || 0)
              + (activitySummary.meet_count || 0)
              + (activitySummary.esign_count || 0)
              + (activitySummary.doc_shared_count || 0)
              + (activitySummary.todo_count || 0)
          }
        }),
        totalMembers: res.object.group.total_members
      }
    })
  }

  filterActiveGroupMembers (userIds: UserIdentity[]) {
    let cleanUserIds = userIds.map(user => {
      // MVB-11514, invalid phone_number will cause 400 error when do GROUP_REQUEST_READ_MEMBERS
      // if email exists, just request with email directly
      if (user.email && user.phone_number) {
        delete user.phone_number
      }
      return user
    })
    return MxISDK.getCurrentOrg().readMembers(cleanUserIds).then(group => {
      return group.members && group.members.filter(member => member.status === GroupUserStatus.GROUP_MEMBER).map(member => {
        let {id, unique_id, email, phone_number, type} = member.user
        return {id, unique_id, email, phone_number, type}
      })
    })
  }

  createOrUpdateInvitationToken () {
    return new Promise((resolve, reject) => {
      this.currentGroup.createOrUpdateInvitationToken().then((group) => {
        let creator = ObjectUtils.getByPath(group, 'invitation_tokens.0.creator.user')
        Object.assign(creator, UserFormatter.transformToBaseUser(creator))
        resolve(group)
      }).catch(reject)
    })
  }

  @transformError()
  readOfficeHour () {
    return this.currentGroup.readOfficeHour()
  }
}
