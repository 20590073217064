export enum UserOSType {
  OS_TYPE_WINDOWS = 'OS_TYPE_WINDOWS',
  OS_TYPE_WINDOWS_XP = 'OS_TYPE_WINDOWS_XP',
  OS_TYPE_WINDOWS_VISTA = 'OS_TYPE_WINDOWS_VISTA',
  OS_TYPE_WINDOWS_7 = 'OS_TYPE_WINDOWS_7',
  OS_TYPE_WINDOWS_8 = 'OS_TYPE_WINDOWS_8',
  OS_TYPE_MAC = 'OS_TYPE_MAC',
  OS_TYPE_MAC_10_6 = 'OS_TYPE_MAC_10_6',
  OS_TYPE_MAC_10_7 = 'OS_TYPE_MAC_10_7',
  OS_TYPE_MAC_10_8 = 'OS_TYPE_MAC_10_8',
  OS_TYPE_MAC_10_9 = 'OS_TYPE_MAC_10_9',
  OS_TYPE_MAC_10_10 = 'OS_TYPE_MAC_10_10',
  OS_TYPE_IOS = 'OS_TYPE_IOS',
  OS_TYPE_ANDROID = 'OS_TYPE_ANDROID',
  OS_TYPE_CLOUD = 'OS_TYPE_CLOUD'
}
