import {MxConsts} from "@commonUtils";
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters({ getUploadingFiles: "uploader/uploadingFiles" }),

    },
    data() {
        return {
            timer: 0,
        }
    },
    watch: {
        getUploadingFiles(v, oldValue){
            if(this.$options.name === "BankMsgDetailsView") {
                if (v && v.length > 0 && !this.timer) {
                    this.timer = setInterval(() => {
                        this.handleActiveEvent()
                    }, 10000)
                }

                if (!v || v.length === 0) {
                    clearInterval(this.timer)
                    this.timer = 0
                }
            }
        }
    },
    methods: {
        handleActiveEvent() {
            this.$root.$emit('lastActiveTimeChange', Date.now())
        },
    }
}