<template>
  <div class="request-type">
    <span
      :style="{backgroundColor: srBoard.srChannelColor, color: srBoard.srChannelFontColor}"> {{ srBoard.srChannelName }} </span>
  </div>
</template>

<script>
export default {
  name: 'ServiceRequestType',
  props: {
    srBoard: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.request-type {

  span {
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 4px 8px;
  }
}
</style>