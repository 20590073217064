import {UserRelationStatus} from 'isdk/src/proto/generated/UserRelationStatus'
import {UserType} from 'isdk/src/proto/generated/UserType'
import {MxUserState, SocialType,MxResetPasswordChannel} from "isdk/src/api/defines"
import {CallStatus} from 'isdk/src/proto/generated/CallStatus'
import {BoardUserStatus} from "isdk/src/proto/generated/BoardUserStatus";
import {ObjectFeedType} from 'isdk/src/proto/generated/ObjectFeedType'
import {TransactionStepStatus} from "isdk/src/proto/generated/TransactionStepStatus";
import {TransactionStatus} from "isdk/src/proto/generated/TransactionStatus";
import {BoardPageType} from "isdk/src/proto/generated/BoardPageType";
import {ObjectFeedStatus} from 'isdk/src/proto/generated/ObjectFeedStatus'
import {SessionStatus} from "isdk/src/proto/generated/SessionStatus";
import {BoardResourceStatus} from "isdk/src/proto/generated/BoardResourceStatus";
import {BoardSignatureStatus} from "isdk/src/proto/generated/BoardSignatureStatus";
import {BoardAccessType} from "isdk/src/proto/generated/BoardAccessType"
import {BoardRoutingStatus} from 'isdk/src/proto/generated/BoardRoutingStatus'
import {GroupUserRoleType} from 'isdk/src/proto/generated/GroupUserRoleType'
enum ErrorCode {
	SessionTimeout, NotLogin, BoardNotFound, RequestTimeout, FileMoved, ObjectRemoved, ServerError, ErrorPermission, UnKnownError, PageNotFind = 404, ExceedFileMaxSize, ExceedClientBodyMax, ExceedUserCloudMax, ExceedSessionUserMax, ExceedBoardUserMax, ExceedBoardPageMax, ExceedUserBoardMax, UploadDetectedVirus, FileTypeNotSupport, UserLocked, EmailDomainLocked, PasswordRuleConfict, UserDisabled, SubscribeFailed, ResourceNotFound, UserIsNotAdmin, MeetEnded, MeetNotStarted, MeetNumberIsRequired, GetMeetInfoError, ClientCannotAccessWeb, InvalidRequest, AuditObjectNotReady
}

const UploadStatus = {
	PENDING: 1, INPROGRESS: 2, UPLOADED: 3, ERROR: 4, CANCELED: 5
}

const UserOnlineStatus = {
	Online: 100,
	Busy: 200,
	Offline: 300,
	OutOfOffice: 400,
	CustomizeAvailable: 100,
	CustomizeBusy: 200,
	CustomizeInvisiable: 300
}

const KeyCode = {
	'BackSpace': 8,
	'Tab': 9,
	'Clear': 12,
	'Enter': 13,
	'Shift': 16,
	'Control': 17,
	'Alt': 18,
	'Pause': 19,
	'Caps_Lock': 20,
	'Escape': 27,
	'Space': 32,
	'Next': 34,
	'End': 35,
	'Home': 36,
	'Left': 37,
	'Up': 38,
	'Right': 39,
	'Down': 40,
	'Select': 41,
	'Execute': 43,
	'Insert': 45,
	'Delete': 46,
	'Help': 47,
	'Z': 90,
	'R': 82,
	'Comma': 188,
	'Semicolon': 186
}

const CustomEvent = {
	BOARD_READY: 'BOARD_READY',
	IMAGE_LOADED: 'IMAGE_LOADED',
	FILES_TAB_INIT: 'FILES_TAB_INIT',
	TODO_TAB_INIT: 'TODO_TAB_INIT',
	VIEW_FLOW_DETAIL: 'VIEW_FLOW_DETAIL',
	VIEW_PAGE: 'VIEW_PAGE',
	CREATE_TODO: 'CREATE_TODO',
	CLOSE_FLOW_DETAIL: 'CLOSE_FLOW_DETAIL',
	SHOW_BINDER_MEMBER: 'SHOW_BINDER_MEMBER',
	ADD_MEMBER_IN_BINDER: 'ADD_MEMBER_IN_BINDER',
	ADD_BOARD_DESCRIPTION: 'ADD_BOARD_DESCRIPTION',
	RESEND_COMMENT: 'RESEND_COMMENT',
	TYPE_AHEAD_INIT: 'TYPE_AHEAD_INIT',
	START_SIGN: 'START_SIGN',
	DECLINE_SIGN: 'DECLINE_SIGN',
	EDIT_MEET: 'EDIT_MEET',
	PLAY_RECORDING: 'PLAY_RECORDING',
	SHARE_RECORDING: 'SHARE_RECORDING',
	COPY_RECORDING: 'COPY_RECORDING',
	SHOW_MEET_MEMBERS: 'SHOW_MEET_MEMBERS',
	BOTTOM_MENUS_INIT: 'BOTTOM_MENUS_INIT',
	CALL_MEET_BACK: 'CALL_MEET_BACK',
	RELOAD_BINDER: 'RELOAD_BINDER'
}

const SignFieldType = {
	PAGE_ELEMENT_TYPE_UNDEFINED: 0,
	PAGE_ELEMENT_TYPE_ARROW: 10,
	PAGE_ELEMENT_TYPE_DOUBLE_ARROW: 20,
	PAGE_ELEMENT_TYPE_SIGNATURE: 30,
	PAGE_ELEMENT_TYPE_AUDIO_TAG: 40,
	PAGE_ELEMENT_TYPE_TEXT_TAG: 50,
	PAGE_ELEMENT_TYPE_SIGN_SIGNATURE: 60,
	PAGE_ELEMENT_TYPE_SIGN_INITIALS: 70,
	PAGE_ELEMENT_TYPE_SIGN_DATE: 80,
	PAGE_ELEMENT_TYPE_SIGN_TEXT: 90,
	PAGE_ELEMENT_TYPE_SIGN_CHECKBOX: 100
}

const FileTypeAlias = {
	'whiteboard': 'WHITEBOARD',
	'note': 'NOTE',
	'web_clip': 'CLIP',
	'video': 'VIDEO',
	'audio': 'AUDIO',
	'location': 'LOCATION',
	'web': 'WEB',
	'unknown': 'UNKNOWN',
	'url': 'URL',
	'geo': 'GEO'
};
const supportedLanguageList = [
	{
		code: 'en',
		localName: 'English',
		i18nKey: 'language_english'
	},
	// {
	//   code: 'da',
	//   localName: 'Dansk', // Danish
	//   i18nKey: 'language_danish'
	// },
    {
        code: 'bs',
        localName: 'Bosanski', // Bosnian
        i18nKey: 'language_bosanski'
    },
	{
		code: 'bg',
		localName: 'български', // Bulgarian
		i18nKey: 'language_bulgarian'
	},
	{
		code: 'de',
		localName: 'Deutsch', // German
		i18nKey: 'language_german'
	},
	{
		code: 'es',
		localName: 'Español', // Spanish
		i18nKey: 'language_spanish'
	},
	{
	  code: 'fi',
	  localName: 'Suomi', // Finnish
	  i18nKey: 'language_finnish'
	},
	{
		code: 'fr',
		localName: 'Français', // French
		i18nKey: 'language_french'
	},
	{
	  code: 'id',
	  localName: 'Bahasa Indonesia', // Indonesian
	  i18nKey: 'language_indonesian'
	},
	{
		code: 'hr',
		localName: 'Hrvatski', // Croatian
		i18nKey: 'language_croatian'
	},
	{
		code: 'it',
		localName: 'Italiano', // Italian
		i18nKey: 'language_italian'
	},
	{
		code: 'ja',
		localName: '日本語', // Japanese
		i18nKey: 'language_japanese'
	},
	// {
	//   code: 'ko',
	//   localName: '한국어', // Korean
	//   i18nKey: 'language_korean'
	// },
	// {
	//   code: 'lt',
	//   localName: 'Lietuvos', // Lithuanian
	//   i18nKey: 'language_lithuanian'
	// },
	{
		code: 'hu',
		localName: 'Magyar', // Hungarian
		i18nKey: 'language_hungarian'
	},
	{
	  code: 'nl',
	  localName: 'Nederlands', // Dutch
	  i18nKey: 'language_dutch'
	},
	// {
	//   code: 'no',
	//   localName: 'Norsk', // Norwegian
	//   i18nKey: 'language_norwegian'
	// },
	{
		code: 'pt',
		localName: 'Português', // Portuguese
		i18nKey: 'language_portuguese'
	},
	{
		code: 'ro',
		localName: 'Română', // Romanian
		i18nKey: 'language_romanian'
	},
	{
		code: 'ru',
		localName: 'Русский язык', // Russian
		i18nKey: 'language_russian'
	},

	// {
	//   code: 'sv',
	//   localName: 'Svenska', // Swedish
	//   i18nKey: 'language_swedish'
	// },
	{
		code: 'ms',
		localName: 'Melayu', // Malay
		i18nKey: 'language_malay'
	},
	{
	  code: 'th',
	  localName: 'ไทย', // Thai
	  i18nKey: 'language_thai'
	},
	// {
	//   code: 'tr',
	//   localName: 'Türkçe', // Turkish
	//   i18nKey: 'language_turkish'
	// },
	{
	  code: 'vi',
	  localName: 'Tiếng Việt', // Vietnamese
	  i18nKey: 'language_vietnamese'
	},
	{
		code: 'zh',
		localName: '简体中文', // Chinese Simplified
		i18nKey: 'language_chinese_simplified'
	},
	{
		code: 'zh-tw',
		localName: '繁體中文', // Chinese Traditional
		i18nKey: 'language_chinese_traditional'
	}
]

const MobileViewBreakPoint = {
	screenXS: 480, screenSM: 768, screenMD: 992, screenLG: 1200
}
const PreviewRoleContext = {
	meet: 'meet', note: 'note', 'public': 'public', binder: 'binder', annotate: 'annotate', activityAudit: 'activity', manage: 'manage'
}

const Annotation = {
	default: {
		Line: {
			color: '#0066de',
			size: 6
		},
		HighLighter: {
			color: '#FFFF00',
			size: 10
		},
		Text: {
			width: 200,
			height: 80,
			color: '#FF0000',
			fontSize: 16,
			fontFamily: 'Helvetica'
		},
		Shape: {
			color: '#FF0000',
			size: 2
		},
		Arrow: {
			color: '#FF0000',
			size: 1
		},
		Indicator: {
			color: '#FF0000',
			size: 1
		}
	}
}

/*
 error types, different type of mail link has different error code
 Default : Default plain error, for non-link cases
 EO: Org is no longer valid
 ETI: Default invalid token for ET links
 ETI2: Invalid secure url and can't self sign up for ET links
 ETI3: Invalid secure url but can self sign up for ET links
 ETI4: Link registered before, redirect to login page if no current user logged in
 ETU: Require user to login first
 ETU2: Logged in user not same with target user
 ETU3: Logged in user same with onboarding invitee
 ETOV: Invalid object
 ENTOV:Invalid object
 ETSI: Invalid token and can't self sign up
 ETSI5:Invalid token and can self sign up
*/
const TokenErrorType = {
  Default: 'default',
  EO: 'invalid_org',
  ETI: 'invalid_token',
  ETI2: 'invalid_secure_url_eti2',
  ETI3: 'invalid_secure_url_eti3',
  ETI4: 'link_registered',
  ETU: 'require_login',
  ETU2: 'user_not_match',
  ETU3: 'user_matched_currentuser',
  ETOV: 'invalid_object_et',
  ENTOV: 'invalid_object_ent',
  ETSI: 'invalid_token_forbid_self_sign_up',
  ETSI5: 'invalid_token_allow_self_sign_up'
}

const UserEngagementReports = {
	CLIENT_ENGAGEMENT: 'client_engagement',
	INTERNAL_USER_ENGAGEMENT: 'internal_user_engagement',
	CLIENT_COVERAGE: 'client_coverage',
	WECHAT_ENGAGEMENT: 'wechat_engagement',
	WHATSAPP_ENGAGEMENT: 'whatsapp_engagement',
	ACD_SUMMARY: 'live_chat_summary',
	ACD_AGENT_SUMMARY: 'live_chat_agent_summary',
	REQUEST_SUMMARY: 'request_summary',
	REQUEST_AGENT_SUMMARY: 'request_agent_summary'
}
const MxGroupRoleNames = {
	MXAuditRole: 'MXAuditRole',
	MXReportRole: 'MXReportRole'
}


export const SR_TYPE_COLORS = [
	'rgba(12, 94, 111, 0.1)',
	'rgba(167, 52, 15, 0.1)',
	'rgba(138, 45, 163, 0.1)',
	'rgba(7, 71, 154, 0.1)',
	'rgba(103, 63, 21, 0.1)',
	'rgba(61, 102, 12, 0.1)',
	'rgba(172, 30, 74, 0.1)',
	'rgba(83, 52, 188, 0.1)',
	'rgba(32, 102, 77, 0.1)',
	'rgba(85, 44, 109, 0.1)'
]


export const SR_TYPE_FONT_COLORS = [
	'rgb(12, 94, 111)',
	'rgb(167, 52, 15)',
	'rgb(138, 45, 163)',
	'rgb(7, 71, 154)',
	'rgb(103, 63, 21)',
	'rgb(61, 102, 12)',
	'rgb(172, 30, 74)',
	'rgb(83, 52, 188)',
	'rgb(32, 102, 77)',
	'rgb(85, 44, 109)'
]

export const MxConsts = {
	UserRelationStatus,
	UserType,
	SocialType,
	MxUserState,
	BoardUserStatus,
	UserOnlineStatus,
	ErrorCode,
	UploadStatus,
	ObjectFeedType,
	CallStatus,
	TransactionStepStatus,
	TransactionStatus,
	ObjectFeedStatus,
	MobileViewBreakPoint,
	MOCK_BOARD_PREFIX: 'MXLB_',
	MOCK_EMAIL_PREFIX: 'MOCK_',
	BoardPageType,
	SessionStatus,
	BoardResourceStatus,
	KeyCode,
	BoardSignatureStatus,
	SignFieldType,
	FileTypeAlias,
	CustomEvent,
	PreviewRoleContext,
	BoardAccessType,
	BoardRoutingStatus,
	supportedLanguageList,
	Annotation,
	GroupUserRoleType,
	TokenErrorType,
	MxResetPasswordChannel,
	UserEngagementReports,
	MxGroupRoleNames
}

