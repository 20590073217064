module.exports = {
    "send_secure_msg": "Enviar mensaje seguro",
    "new_secure_msg": "Nuevo mensaje seguro",
    "unread_msgs": "Mensajes no leídos",
    "mark_as_complete": "¿Desea marcarlo como completo?",
    "submitted_on_x": "Enviado el {time}",
    "submitted_on": "Enviado el",
    "your_msgs": "Tu mensaje",
    "request_description": "Descripción de solicitud",
    "Attachments": "Anexos",
    "add_attachment": "añadir anexos",
    "send_msg": "Enviar mensaje",
    "cancel": "Cancelar",
    "request_create_success": "Solicitud creada con éxito",
    "open_requests": "Solicitudes abiertas",
    "completed_requests": "Solicitudes completadas",
    "load_more": "Carga más",
    "no_attachments": "Sin adjuntos",
    "msg_detail": "Detalle del mensaje",
    "Description": "descripción",
    "Reply": "Responde",
    "Back": "Regresar"
}