<template>
    <div class="summary-container"  @mousedown="handleActiveEvent">
    <div class="bank-msg-summary-view">
        <div class="bank-msg-summary-view__unread">
            <span class="cardsdk-title"> {{ $t('unread_msgs') }} </span>

            <div class="cardsdk-badge" :class="{'badge-alert': hasUnReadMsg}">
                <span class="notification" :class="{'notification-alert': hasUnReadMsg}" @click="showMessages">
                     <img :src="ex_chat" />
                    {{unreadFeedsTotal}}
                </span>
            </div>
        </div>
        <div class="bank-msg-summary-view__send-msg">

            <button v-on:click="sendMsg" style="cursor: pointer">{{ $t('send_secure_msg') }}</button>

        </div>
    </div>
    </div>
</template>

<script>
    import {mapActions,mapGetters} from "vuex"
    import {MxEvent} from "./MxEvent";
    import ex_chat from '@views/theme/src/images/acd/ex_chat.png'
    import eventsHandler from "./mixin/eventsHandler";

    export default {
        name: "BankMsgSummaryView",
        mixins: [eventsHandler],
        data () {
            return {
                ex_chat
                // unReadMsgCount: 4
            }
        },
        created (){
            this.initSRSubscriber()
        },
        computed: {
            ...mapGetters("cardsdk", ["unreadFeedsTotal"]),
            hasUnReadMsg(){
                return this.unreadFeedsTotal > 0
            }
        },
        methods: {
            ...mapActions("cardsdk", ['initSRSubscriber', 'createServiceRequest']),
            sendMsg: function() {
                // this.unReadMsgCount--
                this.$root.$emit(MxEvent.onSendMsgClick)
            },

            showMessages() {
                this.$root.$emit(MxEvent.onBadgeClick)
            }
        }

    }
</script>

<style lang="scss" scoped>

    .summary-container{
        width: 259px;
        height: 104px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.04),
        0px 1px 14px 0px rgba(0, 0, 0, 0.04),
        0px 3px 5px 0px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
    }

    .bank-msg-summary-view {
        font-size: 14px;
        height: 100%;


        &__unread {
            background: #FFFFFF;
            height: 50%;
            display:flex;
            align-items: center;
            justify-content: center;

            .cardsdk-title {
                color: rgb(31, 33, 38);
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                width: 60%;
            }

            .cardsdk-badge{
                //width: 46px;
                height: 28px;
                border-radius: 6px;
                display: inline-block;
                padding: 0 8px;
                background: rgb(240, 242, 245);

            }

            .badge-alert{
                background: rgb(219, 70, 70);

            }

            .notification {
                position: relative;
                top: 50%;
                transform: translateY(-50%);


                display:flex;
                align-items: center;
                justify-content: center;
                color: #8d9199;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }

            .notification-alert{
                color:white
            }
        }
        &__send-msg {
            height: 50%;
            background: rgb(240, 242, 245);
            text-align: center;
            display:flex;
            align-items: center;
            justify-content: center;
            /*border: 1px solid yellowgreen;*/
            /*padding: 6px;*/

            button {
                width: 226px;
                height: 28px;
                border: 1px solid var(--cardsdk-branding-color,#035cfe);
                border-radius: 14px;
                padding: 0 30px;
                color: var(--cardsdk-branding-color,#035cfe);
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                line-height: 16px;
                background: white;

                &:focus {
                  outline: none;
                }
            }
        }
    }
</style>
