'use strict'
import {v4 as uuid} from 'uuid'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'

// const emailRegex = /^[a-zA-Z0-9\+\'\.\_\%\-\+]{1,256}\@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(\.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+$/i;
const emailRegex = /^[_a-z0-9\-']+(\.[_a-z0-9\-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;

export class FunctionUtil {

	static debounce = _debounce
	static throttle = _throttle

	static isEmail(str: string) {
		return emailRegex.test(str);
	}

	static isDefine(o: any) {
		return !(o === undefined);
	}

	static isObject(obj: any) {
		return typeof (obj) === 'object' && obj === Object(obj);
	}

	static isNull(obj: any) {
		return obj === null;
	}

	/**
	 * Return true if the passed value is undefined.
	 * @param {Object} obj the target to test
	 * @return {Boolean}
	 */
	static isUndefined(obj: any) {
		return undefined === obj;
	}

	static each(props: string, fn: Function) {
		if (!props) {
			return;
		}
		const keys = Object.keys(props)
		let key
		for (let i = 0; i < keys.length; i++) {
			key = keys[i];
			if (fn.call(this, props[key], key) === false) {
				break;
			}
		}
	}

	static set(path: string, val: any, root: object) {
		const items = path.split('.'), last = items.pop()
		let result
		path = items.join('.');
		if (path === '') {
			result = root;
		} else {
			result = FunctionUtil.get(path, root, true);
		}
		result[last] = val;
		return root;
	}

	static get(path: string, obj: object, createIfUndefined?: boolean) {
		let result;
		// check input parameter
		if (!path) {
			return undefined;
		}
		if (!obj) {
			obj = window;
		}
		if (path.indexOf('.') < 0) {
			if ((result = obj[path]) === undefined) {
				if (createIfUndefined) {
					result = obj[path] = {};
				} else {
					return undefined;
				}
			}
			return result;
		}
		let items = path.split('.'), last = items.pop(), root = obj;
		items.forEach((curr) => {
			if (root[curr]) {
				root = root[curr];
			} else {
				if (createIfUndefined) {
					root[curr] = {};
					root = root[curr];
				} else {
					root = {};
				}
			}
		})

		result = root[last];
		if (!result && createIfUndefined) {
			root[last] = {};
			result = root[last];
		}
		return result;
	}

	static uuid() {
		return uuid()
	}

	static all(props: string, fn: Function) {
		let flag = false;
		FunctionUtil.each(props,(v: any) => flag = fn.call(v, v));
		return flag;
	}

	static any(props: Array<any>, fn: Function) {
		let find = false;
		props.forEach(v => {
			if (!find) {
				find = fn.call(v, v);
			}
		});
		return find;
	}

	static sleep(time: number) {
		return new Promise((resolve) => {
			setTimeout(resolve, time);
		})
	}

	/**
	 * a cached async/await error-first function wrapper
	 *
	 * @example
	 * const [err, res] = FunctionUtil.catchedAsync(isdk.someApiCall())
	 */
	static async catchAsync <T> (promise: Promise<T>): Promise<[Error | null, T | null]> {
		try {
			const res = await promise;
			return [null, res];
		}
		catch (err) {
			return [err, null];
		}
	}

}

