var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thumbnail" }, [
    _c("span", { staticClass: "mx-thumbnail-outer" }, [
      _c("span", { staticClass: "mx-thumbnail-inner initials" }, [
        _vm.userAvatar
          ? _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasError,
                  expression: "!hasError"
                }
              ],
              attrs: {
                src: _vm.userAvatar,
                lazyload: _vm.lazyload,
                "data-original": _vm.userAvatar
              },
              on: { error: _vm.onError }
            })
          : _c("img", { attrs: { src: _vm.defaultAvatar } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }