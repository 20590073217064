/**
 * Created by colin on 2018/12/12
 */
import {EventEmitter} from 'eventemitter3'
let mxEvent = new EventEmitter();

enum eventType {
  USER_LOGGED_IN = 'USER_LOGGED_IN',
  USER_LOGGED_OUT = 'USER_LOGGED_OUT',

  MEET_SESSION_ERROR = 'MEET_SESSION_ERROR',
}
export {
  mxEvent, eventType
}
