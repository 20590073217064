import { render, staticRenderFns } from "./ServiceRequestType.vue?vue&type=template&id=081d60f3&scoped=true&"
import script from "./ServiceRequestType.vue?vue&type=script&lang=js&"
export * from "./ServiceRequestType.vue?vue&type=script&lang=js&"
import style0 from "./ServiceRequestType.vue?vue&type=style&index=0&id=081d60f3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081d60f3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/linfeng/work/svnrepo/boardUI/branches/cardsdk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('081d60f3')) {
      api.createRecord('081d60f3', component.options)
    } else {
      api.reload('081d60f3', component.options)
    }
    module.hot.accept("./ServiceRequestType.vue?vue&type=template&id=081d60f3&scoped=true&", function () {
      api.rerender('081d60f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "views/cardsdk/src/ServiceRequestType.vue"
export default component.exports