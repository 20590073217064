import {getFocusableItems, isHidden} from '@commonUtils'
import {initAriaFocusIndicator, isKeyboardEvent} from './accessibility/focusVisible'

function getLastFocusableElement(el) {
  const items = getFocusableItems(el)
  return items.pop()
}
function getFirstFocusableElement(el) {
  const items = getFocusableItems(el)
  return items[0]
}
function focusableElementLoop(e, handleOneFocusableElement) {
  let el = e.currentTarget;
  if(e.keyCode !== Keyboard.Tab){
    return
  }
  let items = getFocusableItems(el)
  if (items.length < 2) {
    if(handleOneFocusableElement){
      e.preventDefault()
    }
    return;
  }

  let lastEl = items[items.length - 1]
  let nextFocusItem
  if (!e.shiftKey) {
    if (e.target === lastEl) {
      nextFocusItem = items[0];
    }
  } else {
    if (e.target === items[0]) {
      nextFocusItem = lastEl;
    }
  }
  if (nextFocusItem) {
    nextFocusItem.focus()
    e.preventDefault()
  }
}

function needAddOutLine(el) {
  if (el.getAttribute('role') === 'button') {
    return true
  }
  if (el.tabIndex >= 0) {
    return true
  }
  return false
}

const keyboardOutlineClass = 'is-keyboard-focus'
let lastFocusEl
let activeEl


function focusElement(el, border, delay, clearActiveEl) {
  //focusRef before on keyup
  if (!border && activeEl && activeEl !== el) {
    //this focus trigger by keyboard
    //  border = true
  }
  resetFocus()

  function doFocus() {
    if(el.tabIndex < 0){
      el.tabIndex = 0;
      el.dataset.resetTabIndex = true
      border = false
    }
    el.focus()
    lastFocusEl = el
    if(clearActiveEl){
      activeEl = null
    }
    // activeEl = el
    if (border) {
      // el.classList.add(keyboardOutlineClass)
    }
  }

  if (delay) {
    delay = parseInt(delay) || 300
    setTimeout(doFocus, delay)
  } else {
    doFocus()
  }
  // activeEl = el
}

function resetFocus(e) {
  if (lastFocusEl) {
    lastFocusEl.classList.remove(keyboardOutlineClass)
    if(lastFocusEl.dataset.resetTabIndex){
      lastFocusEl.tabIndex = -1
      lastFocusEl.dataset.resetTabIndex = false
    }
    lastFocusEl = null
    const el = document.body.querySelector('.' + showAllHideAction)
    // just mousedown and window blur need process hover class
    if (e && el) {
      //delay to check for fix flash issue
      setTimeout(()=>{
        if(!hoverBox || !hoverBox.querySelector(`.${keyboardOutlineClass}`)) {
          el.classList.remove(showAllHideAction)
        }
      },300)
    }
  }
  inKeydown = false
  forcedAddOutLine = false
  // activeEl = window.activeElement
}
let inKeydown = false
function initAriaFocusIndicators() {
  document.body.addEventListener('keydown', (e) => {
    forcedAddOutLine = false
    let el = e.target
    inKeydown = true
    activeEl = document.activeElement;

    if (el !== activeEl) {
      // the focus change by code
      activeEl = el
    }
    if (!needAddOutLine(el)) {
      return;
    }
  })

  const theKeyboardAffectsTabOrder = [27, 13, 32, 38, 37, 39, 40]

  function handleKeyup(e) {
    let activeNow = document.activeElement

    if (activeEl !== activeNow) {
      //keyboard effect the focus el change
      if (activeEl && needAddOutLine(activeNow)) {
        focusElement(activeNow, true, false, true)
      }
    }
    if(!activeEl){
      let el = document.querySelector('.'+keyboardOutlineClass)
      if(el && el !== activeNow){
        el.classList.remove(keyboardOutlineClass)
      }
    }
  }

  let timer
  //by default esc / space / enter will trigger delay check
  const triggerKeys = [27, 13, 32]
  document.body.addEventListener('keyup', (e) => {
    if (timer) {
      clearTimeout(timer)
    }
    handleKeyup(e)
    setTimeout(()=>{
      inKeydown = false
    },300)
    //Compatible with some component animation effects like dialog
    if (triggerKeys.indexOf(e.keyCode) >= 0) {
      timer = setTimeout(() => {
        handleKeyup(e);
        activeEl = null
      }, 300)
    } else {
      activeEl = null
    }

  })

  document.body.addEventListener('mousedown', resetFocus)
  window.addEventListener('blur', resetFocus)
  function onInitialPointerMove(e) {
    // Work around a Safari quirk that fires a mousemove on <html> whenever the
    // window blurs, even if you're tabbing out of the page. ¯\_(ツ)_/¯
    if (e.target.nodeName && e.target.nodeName.toLowerCase() === 'html') {
      return;
    }

    inKeydown = false;
  }

  document.addEventListener('mousemove', onInitialPointerMove);
  document.addEventListener('mousedown', onInitialPointerMove);
  document.addEventListener('mouseup', onInitialPointerMove);
  document.addEventListener('pointermove', onInitialPointerMove);
  document.addEventListener('pointerdown', onInitialPointerMove);
  document.addEventListener('pointerup', onInitialPointerMove);
  document.addEventListener('touchmove', onInitialPointerMove);
  document.addEventListener('touchstart', onInitialPointerMove);
  document.addEventListener('touchend', onInitialPointerMove);
  function wrap(name){
      return (e)=>{
        console.debug(name, e.target)
      }
  }
  document.addEventListener('keydown', wrap('keydown'), true);
  document.addEventListener('mousedown', wrap('mousedown'), true);
  document.addEventListener('pointerdown', wrap('pointerdown'), true);
  document.addEventListener('touchstart', wrap('touchstart'), true);
  document.addEventListener('visibilitychange', wrap('visibilitychange'), true);
}

let forcedAddOutLine = false

function requestFocusBorder() {
  forcedAddOutLine = true
}

function focusTo(selectorOrElement, {border, delay,retry} = {border: false, delay: false, retry: true}) {
  let el = selectorOrElement
  let addBoarder = border || forcedAddOutLine || isFocusByKeyboardNow() || isInKeydown()
  if(delay){
    setTimeout(() =>{
      focusTo(selectorOrElement,{border:addBoarder})
    },parseInt(delay)|| 300)
    return;
  }
  // console.debug('focusTo',selectorOrElement)

  const isStringSelector = typeof selectorOrElement === 'string'
  if (isStringSelector) {
    el = document.querySelector(selectorOrElement)
  }
  if(!el){
    if(isStringSelector && retry){
      //fix async case the dom not render on current time
      setTimeout(()=>{
        focusTo(selectorOrElement, {
          border:addBoarder,
          retry:false
        })
      }, parseInt(delay) || 200)
    }
    return
  }
  // if(el.tabIndex < 0){
  //   el.tabIndex = 0
  //   el.data.resetTabIndex = true
  //   // // setTimeout(()=>{
  //   //    el.tabIndex = -1
  //   //  },500)
  // }
  if (el.$el) {
    el = el.$el
  }

  focusElement(el, addBoarder,delay)
  forcedAddOutLine = false

}
let keepHoverShow = false
export function keepHoverShowElement() {
  keepHoverShow = true
}
const showAllHideAction = 'show-all-actions'
let hoverBox
const firstFocusableElId ='firstFocusableEl'
export function focusToPageFirst() {

  let el = document.getElementById(firstFocusableElId)
  if(!el){
    const firstEl = document.createElement('a')
    firstEl.setAttribute('id', firstFocusableElId);
    firstEl.setAttribute('aria-hidden' ,'true')
    firstEl.setAttribute('href' ,'#')
    firstEl.setAttribute('aria-disable' ,'true')
    let keydownHandler = ()=>{
      const el = document.getElementById(firstFocusableElId)
      el.removeEventListener('keydown', keydownHandler)
      document.body.removeChild(el)
    }
    firstEl.addEventListener('keydown', keydownHandler)
    document.body.insertBefore(firstEl,document.body.firstElementChild)
    firstEl.focus()
    document.body.removeChild(firstEl)

  }else{
    el.focus()
  }
}
export function resetPageFocus(delay = 300) {
  if(delay){
    setTimeout(()=>{
      focusToPageFirst()
    },delay)
  }else{
    focusToPageFirst()
  }
}
export function handleHoverShowElement(e) {
  // const box = e.currentTarget
  // if(e.keyCode !== Keyboard.Tab){
  //   return
  // }
  // setTimeout(() => {
  //   const keyboardFocus = box.querySelector(`.${keyboardOutlineClass}`)
  //   const hasShow = box.classList.contains(showAllHideAction)
  //   if (keyboardFocus) {
  //     if (!hasShow) {
  //       box.classList.add(showAllHideAction)
  //       hoverBox = box
  //     }
  //   } else {
  //     if (hasShow && !keepHoverShow) {
  //       box.classList.remove(showAllHideAction)
  //       hoverBox = null
  //       keepHoverShow = false
  //     }
  //   }
  // }, 300)
}
export function handleHoverShowKeydown(e) {
  // when user press tab in container should check user has leave the container
  // if(e.keyCode !== Keyboard.Tab){
  //   return
  // }
  // const box = e.currentTarget
  // const hasShow = box.classList.contains(showAllHideAction)
  // if (hasShow) {
  //   setTimeout(() =>{
  //     if(!box.contains(document.activeElement)) {
  //       box.classList.remove(showAllHideAction)
  //     }
  //   },600)
  //
  // }
}
export function handleHoverShowKeyup(e){
  // if(e.keyCode !== Keyboard.Tab){
  //   return
  // }
  // const box = e.currentTarget
  // if(box.contains(document.activeElement)) {
  //   box.classList.add(showAllHideAction)
  // }
}

export function showHoverAction(elementOrVNode) {
  let el = elementOrVNode
  if(elementOrVNode.$el){
    el = elementOrVNode.$el
  }
  el.classList.add(showAllHideAction)
}
// export function isFocusByKeyboardNow() {
//   let el =  document.activeElement
//   if(!el){
//     return false
//   }
//   if(el && el.classList.contains(keyboardOutlineClass)){
//     return true
//   }
//   if(forcedAddOutLine){
//     return true
//   }
//   return false
// }
const isFocusByKeyboardNow = isKeyboardEvent
export function isInKeydown() {
  return inKeydown
}
export function focusToFirstElement(el,opts) {
  if(!el){return}
  let items = getFocusableItems(el);
  if(!items.length){
    return
  }
  focusTo(items[0],opts)
}


const Keyboard = {
  Up: 38,
  Down:40,
  Enter:13,
  Space:32,
  Esc:27,
  Home:36,
  End:35,
  Tab: 9,
  Right:39,
  Left:37,
  PageUp:33,
  PageDown:34
}

export function detectHighContrast() {
  let el = document.createElement('div');
  el.innerHTML = "<p style='position:absolute;top:0;left:-999px;color:#878787!important;'>T</p >";
  el = el.firstChild;
  document.body.appendChild(el);
  // setTimeout(()=>{
  let strColor = document.defaultView ? document.defaultView.getComputedStyle(el, null).color : el.currentStyle.color;
  strColor = strColor.replace(/ /g, '');
  console.debug(strColor);
  if (strColor !== '#878787' && strColor !== 'rgb(135,135,135)') {
    document.body.classList.add('contrast-model');
  }
  document.body.removeChild(el);
  // },200)
}
function getScrollableContainer(el) {
  if(window.getComputedStyle(el).overflowY === 'auto'){
    if(el.offsetHeight < el.scrollHeight) {
      return el
    }
  }
  if(el === document.body){
    return el
  }
  if(el.parentNode){
    return getScrollableContainer(el.parentNode)
  }
  return el
}
function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  let container = getScrollableContainer(element)
  if(container === document.body){
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  const wrap = container.getBoundingClientRect()
  return (
    rect.top >= wrap.top &&
    rect.left >= wrap.left &&
    rect.bottom <= wrap.bottom &&
    rect.right <= wrap.right
  )
}

export {
  focusableElementLoop, isFocusByKeyboardNow, getFocusableItems, initAriaFocusIndicator,getFirstFocusableElement,
  requestFocusBorder, focusTo, getLastFocusableElement, isHidden, Keyboard,keyboardOutlineClass, isInViewport
}

export default {
  methods: {
    focusableElementLoop,
    focusRef(name, {border, delay} = {border: false, delay: false}) {
      let el = this.$refs[name];
      let addBoarder = border || forcedAddOutLine
      focusTo(el.$el || el, {border:addBoarder,delay})
      forcedAddOutLine = false
    }
  }
}
