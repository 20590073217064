import { User } from '../proto/generated/User';
import { UserType } from './../proto/generated/UserType';
import { ClientParam } from './../proto/generated/ClientParam';
import { ClientResponseCode } from './../proto/generated/ClientResponseCode';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { GroupAccessType } from '../proto/generated/GroupAccessType';
import { UserRole } from '../proto/generated/UserRole';

import { Connection } from './../network/connection';
import { Ajax } from "../network/ajax";
import { requestNode, userRequestNode } from "../network/requestNode";
import { MxUser } from './../api/mxUser';
import { LoginOption, MxTokenType, MxVerifyCodeAction } from './../api/defines';
import { config } from '../core/config';
import { eventType, mxEvent } from '../core/event';
import { MxErr } from '../core/error';
import * as cacheMgr from '../data/cache/cacheMgr';
import { getByPath } from '../util';

interface IPromise {
    resolve?: Function;
    reject?: Function;
    timerId?: number;
}

let loginPromise: IPromise = {};
const LOGIN_TIMEOUT: number = 60 * 1000;

function verifyToken(accessToken: string='', rememberMe: boolean = true): Promise<ClientResponse> {
    let params: ClientParam[] =[{
        name: ClientRequestParameter.USER_REQUEST_READ_SET_COOKIE
    }];

    if (rememberMe) {
        params.push({
        name: ClientRequestParameter.USER_REQUEST_LOGIN_REMEMBER
        });
    }
    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_VERIFY_TOKEN,params), {accessToken: accessToken});
}

function getAccessTokenByEmailPassword(opt: LoginOption): Promise<ClientResponse> {
    let params: ClientParam[] =[{
      name: ClientRequestParameter.USER_REQUEST_GET_ACCESS_TOKEN
    }];

    if(opt.isSuperAdmin || opt.isPartnerAdmin){
      params.push({
        name:ClientRequestParameter.USER_REQUEST_LOGIN_PARTNER_ADMIN_EXPECTED
      });
    }
    let user: User = {
        email: opt.email,
        phone_number: opt.phone_number,
        pass: opt.pass
    }
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_LOGIN, user, params));
}

function getAccessToken(): Promise<ClientResponse> {
    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_ACCESS_TOKEN));
}

// function loginNormalUser(opt: LoginOption): Promise<ClientResponse> {
//     let params: ClientParam[] = [{
//       name: ClientRequestParameter.USER_REQUEST_LOGIN_OUTPUT_BASIC
//     }]

//     if (opt.rememberMe) {
//       params.push({
//         name: ClientRequestParameter.USER_REQUEST_LOGIN_REMEMBER
//       });
//     }

//     let user: User = {
//         email: opt.email,
//         pass: opt.pass
//     }
//     return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_LOGIN, user, params));
// }

function loginMepUser(opt: LoginOption): Promise<ClientResponse> {
    return getAccessTokenByEmailPassword(opt).then((response: ClientResponse) => {
        // for SA PA, login success will return user object directly
        let token = response.data;
        config({accessToken: token});

        return verifyToken(token, opt.rememberMe);
    }).catch(e => {
        return Promise.reject(e);
    });
}


function login(opt?: LoginOption): Promise<MxUser> {
    let isAutoLogin: boolean = !opt || !opt.pass;

    if (isAutoLogin && cacheMgr.currentUser) {
        // already login?
        return Promise.resolve(cacheMgr.currentUser);
    }

    return new Promise((resolve, reject) => {
        let p: Promise<ClientResponse>;

        if (isAutoLogin) {
            // auto login with token in cookie
            p = verifyToken();
        }else {
            p = loginMepUser(opt);
        }

        p.then((response: ClientResponse) => {
            let user: User = getByPath(response, 'object.user');
            checkUserPermission(user, opt);

            cacheMgr.setCurrentVerifyTokenResponseUser(user);

            loginPromise.resolve = resolve;
            loginPromise.reject = reject;
            loginPromise.timerId = window.setTimeout(onLoginTimeout, LOGIN_TIMEOUT);

            // open websocket connection
            Connection.getInstance().open();    
        }).catch(e => {
            reject(e);
        });
    });
}

function loginWithAccessToken(accessToken: string): Promise<MxUser> {
    return new Promise((resolve, reject) => {
        verifyToken(accessToken, true).then((response: ClientResponse) => {
            loginPromise.resolve = resolve;
            loginPromise.reject = reject;
            loginPromise.timerId = window.setTimeout(onLoginTimeout, LOGIN_TIMEOUT);

            if (getByPath(response, 'object.user')) {
                cacheMgr.setCurrentVerifyTokenResponseUser(response.object.user);
            }

            // open websocket connection
            Connection.getInstance().open();    
        }).catch(e => {
            reject(e);
        });
    });
}

function logout(logoutAllDevice?: boolean): Promise<void> {
    let reqType = logoutAllDevice ? ClientRequestType.USER_REQUEST_LOGOUT_ALL_DEVICES : ClientRequestType.USER_REQUEST_LOGOUT;
    return Ajax.sendRequest(requestNode(reqType)).then(() => {
        onLogout(logoutAllDevice);
        return Promise.resolve();
    }).catch((e) => {
        return Promise.reject(e);
    })
}


function registerUser(user: User, token?: string, isPartnerAdmin?: boolean): Promise<MxUser> {
    let params: ClientParam[] = [];
    let type = ClientRequestType.USER_REQUEST_REGISTER_LOCAL_USER;
    let tokenName = ClientRequestParameter.GROUP_REQUEST_INVITE_TOKEN;
    if(isPartnerAdmin){
      type = ClientRequestType.USER_REQUEST_REGISTER;
      tokenName = ClientRequestParameter.PARTNER_REQUEST_INVITE_TOKEN;
      params.push({
        name: ClientRequestParameter.USER_REQUEST_LOGIN_PARTNER_ADMIN_EXPECTED
      })
    }

    if (token) {
      params.push({
        name: tokenName,
        string_value: token
      })
    }

    return Ajax.sendRequest(userRequestNode(type, user, params)).then((response: ClientResponse) => {
        return loginWithAccessToken(response.data);
    }).catch(e => {
        return Promise.reject(e);
    });
}
  

function readPasswordRule(): Promise<ClientResponse> {
    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_READ_PASSWORD_RULE));
}

function readSsoOptions(): Promise<ClientResponse> {
    return Ajax.sendRequest(requestNode(ClientRequestType.USER_REQUEST_READ_SSO_OPTIONS));
}

function decodeViewToken(token: string, tokenType?: MxTokenType): Promise<ClientResponse> {
    let requestType: ClientRequestType;
    let paramName: ClientRequestParameter;

    if (tokenType === MxTokenType.BOARD_VIEW_TOKEN) {
        requestType = ClientRequestType.BOARD_REQUEST_VIEW_INVITATION;
        paramName = ClientRequestParameter.BOARD_REQUEST_VIEW_TOKEN;
    }else if (tokenType === MxTokenType.GROUP_INVITE_TOKEN) {
        requestType = ClientRequestType.GROUP_REQUEST_VIEW_INVITATION;
        paramName = ClientRequestParameter.GROUP_REQUEST_INVITE_TOKEN;
    }else if (tokenType === MxTokenType.PARTNER_INVITE_TOKEN) {
        requestType = ClientRequestType.PARTNER_REQUEST_VIEW_INVITATION;
        paramName = ClientRequestParameter.PARTNER_REQUEST_INVITE_TOKEN;
    }else if (tokenType === MxTokenType.QR_TOKEN) {
        requestType = ClientRequestType.USER_REQUEST_VIEW_QR_TOKEN;
        paramName = ClientRequestParameter.GROUP_REQUEST_USER_TOKEN;
    }else if (tokenType === MxTokenType.EMAIL_VERIFICATION_TOKEN) {
        requestType = ClientRequestType.USER_REQUEST_PREVIEW_EMAIL_TOKEN;
        return Ajax.sendRequest(userRequestNode(requestType, {email_verification_token: token}));
    }else {
        return Promise.reject(MxErr.InvalidParam());
    }

    let params: ClientParam[] = [{
        name: paramName,
        string_value: token
    }];

    return Ajax.sendRequest(requestNode(requestType, params));
}

function verifyEmailToken(token: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_VERIFY_EMAIL_TOKEN, {email_verification_token: token}));
}

function verifyEmailCode(email: string, code: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_VERIFY_LOCAL_EMAIL_CODE, {email: email, email_verification_code: code}));
}

function verifyGlobalEmailCode(email: string, code: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_VERIFY_EMAIL_CODE, {email: email, email_verification_code: code}));
}

function verifyGlobalSmsCode(phoneNum: string, code: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_VERIFY_EMAIL_CODE, {phone_number: phoneNum, email_verification_code: code}));
}

function verifySmsCode(phoneNum: string, code: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_VERIFY_LOCAL_SMS_CODE, {phone_number: phoneNum, email_verification_code: code}));
}



function resendVerifyCodeEmail(email: string, qrToken?: string, action?: MxVerifyCodeAction): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if (qrToken) {
        params.push({
            name: ClientRequestParameter.GROUP_REQUEST_USER_TOKEN,
            string_value: qrToken
        });
    }

    if (action === MxVerifyCodeAction.REGISTER) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_CODE_TO_REGISTER,
        });
    }else if (action === MxVerifyCodeAction.RESET_PASSWORD) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_CODE_TO_RESET_PASSWORD,
        });
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_EMAIL, {email: email}, params));
}

function resendGlobalVerifyCodeEmail(email: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESEND_VERIFICATION_CODE_EMAIL, {email: email}));
}

function resendGlobalVerifyCodeSms(phoneNum: string): Promise<ClientResponse> {
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESEND_VERIFICATION_CODE_EMAIL, {phone_number: phoneNum}));
}

function resendVerifyCodeSms(phoneNum: string, qrToken?: string, action?: MxVerifyCodeAction): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if (qrToken) {
        params.push({
            name: ClientRequestParameter.GROUP_REQUEST_USER_TOKEN,
            string_value: qrToken
        });
    }

    if (action === MxVerifyCodeAction.REGISTER) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_CODE_TO_REGISTER,
        });
    }else if (action === MxVerifyCodeAction.RESET_PASSWORD) {
        params.push({
            name: ClientRequestParameter.USER_REQUEST_CODE_TO_RESET_PASSWORD,
        });
    }
    
    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_SMS, {phone_number: phoneNum}, params));
}

function registerWithQRToken(user: User, qrToken: string, noRelationBoard: boolean, isInternalUser: boolean): Promise<MxUser> {
    let params: ClientParam[] = [];
    if(noRelationBoard){
      params.push({
        name:ClientRequestParameter.GROUP_REQUEST_NO_RELATION_BOARD
      })
    }

    if(isInternalUser){
        params.push({
          name:ClientRequestParameter.GROUP_REQUEST_INVITE_TOKEN,
          string_value: qrToken
        })
    }else {
        user.type = UserType.USER_TYPE_LOCAL;
        user.relations = [ {
            user: {
                qr_tokens: [ {
                        token: qrToken
                    } ]
            }}
        ];
    }

    return Ajax.sendRequest(userRequestNode(ClientRequestType.USER_REQUEST_REGISTER_LOCAL_USER_WITH_QR_TOKEN, user, params)).then((response: ClientResponse) => {
        return loginWithAccessToken(response.data);
    }).catch(e => {
        return Promise.reject(e);
    });
}

function checkUserPermission(user: User, loginOpts: LoginOption) {
    if (!user) {
        throw MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_PERMISSION, '', 'user expected');
    }

    let userGroups = (user.groups || []).filter((group) => !group.is_deleted);
    let userPartners = (user.partners || []).filter((partner) => !partner.is_deleted);
    let userRole: UserRole = user.role || UserRole.USER_ROLE_NORMAL;
    let userGroupRole: GroupAccessType = userGroups.length > 0 ? userGroups[0].type : GroupAccessType.GROUP_NO_ACCESS;

    const ORG_ADMIN_ROLES: GroupAccessType[] = [
        GroupAccessType.GROUP_OWNER_ACCESS,
        GroupAccessType.GROUP_ADMIN_ACCESS,
      ];

    const SUPER_ADMIN_ROLES: UserRole[] = [
        UserRole.USER_ROLE_SUPERADMIN,
        UserRole.USER_ROLE_OBJECT_READ,
        UserRole.USER_ROLE_OBJECT_WRITE,
        UserRole.USER_ROLE_SUPERADMIN_READONLY,
      ];

    let isOrgAdmin: boolean =  (ORG_ADMIN_ROLES.indexOf(userGroupRole) > -1) ? true : false;
    let isSuperAdmin: boolean = (SUPER_ADMIN_ROLES.indexOf(userRole) > -1) ? true : false;
    let isPartnerAdmin: boolean = userPartners.length > 0 ? true : false;
    let userOrgId = userGroups.length > 0 ? userGroups[0].group.id : '';

    let expectedRoles: LoginOption = loginOpts || {isOrgAdmin: false, isSuperAdmin: false, isPartnerAdmin: false};
    let expectIsOrgAdmin: boolean = expectedRoles.isOrgAdmin || false;
    let expectIsSuperAdmin: boolean = expectedRoles.isSuperAdmin || false;
    let expectIsPartnerAdmin: boolean = expectedRoles.isPartnerAdmin || false;
    let expectNormalUser: boolean = !(expectIsOrgAdmin || expectIsSuperAdmin || expectIsPartnerAdmin);
    let expectUserOrgId = cacheMgr.anonymousOrg ? cacheMgr.anonymousOrg.id : '';

    if (expectNormalUser) {
        // do not allow SA PA to access web portal
        if (userOrgId && expectUserOrgId === userOrgId) {
            // for dirty data: some super admin account has user group, allow it to access web portal
        }else if (isSuperAdmin || isPartnerAdmin)  {
            throw MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_PERMISSION, '', 'normal user expected');
        }
    }

    if (expectIsOrgAdmin) {
        // allow OA SA PA to access admin portal
        if (!isOrgAdmin && !isSuperAdmin && !isPartnerAdmin) {
            throw MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_PERMISSION, '', 'org admin expected');
        }
    }

    if (expectIsPartnerAdmin) {
        // allow SA PA to access partner admin portal
        if (!isSuperAdmin && !isPartnerAdmin) {
            throw MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_PERMISSION, '', 'partner admin expected');
        }
    }

    if (expectIsSuperAdmin) {
        // allow SA to access super admin portal
        if (!isSuperAdmin) {
            throw MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_PERMISSION, '', 'super admin expected');
        }
    }

    // for admin portal, do not need user board data
    if (expectIsOrgAdmin || expectIsPartnerAdmin || expectIsSuperAdmin) {
        config({noUserBoard: true});
    }else {
        config({noUserBoard: false});
    }
  }

function onLoginTimeout() {
    if (loginPromise.reject) {
        loginPromise.reject(MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_TIMEOUT));
        loginPromise = {};
    }
}

function onLogout(logoutAllDevice?: boolean) {
    if (!logoutAllDevice) {
        cacheMgr.onUserLoggedOut();
    }
}

mxEvent.on(eventType.USER_LOGGED_IN, () => {
    if (loginPromise.resolve && cacheMgr.currentUser) {
        window.clearTimeout(loginPromise.timerId);
        loginPromise.resolve(cacheMgr.currentUser);
        loginPromise = {};
    }
}).on(eventType.USER_LOGGED_OUT, () => {
    if (loginPromise.reject) {
        window.clearTimeout(loginPromise.timerId);
        loginPromise.reject(MxErr.ServerError(ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN));
        loginPromise = {};
    }
});

export {
    verifyToken,
    login,
    loginWithAccessToken,
    logout,
    getAccessToken,
    registerUser,
    readPasswordRule,
    readSsoOptions,
    decodeViewToken,
    verifyEmailToken,
    verifyEmailCode,
    verifySmsCode,
    resendVerifyCodeEmail,
    resendVerifyCodeSms,
    resendGlobalVerifyCodeEmail,
    resendGlobalVerifyCodeSms,
    verifyGlobalEmailCode,
    verifyGlobalSmsCode,
    registerWithQRToken,
}
