import actions from './actions'
import mutations from './mutations'
import getters from './getters'
export default {
  namespaced: true,
  state: {
    files:[],
    binderId: '',
    comment: '',
    allFilesResolved: false,
    totalFiles: 0,
    uploadedFiles: 0
  },
  actions,
  mutations,
  getters
}
