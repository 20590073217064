import { ActionObject } from './../proto/generated/ActionObject';
import { ClientRequestType } from './../proto/generated/ClientRequestType';
import { ClientRequest } from '../proto/generated/ClientRequest';
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientResponseCode } from '../proto/generated/ClientResponseCode';
import { Board } from '../proto/generated/Board';
import { Group } from '../proto/generated/Group';
import { ISocket } from "./ISocket";
import { WebSocketConnection } from "./webSocketConnection";
import { HttpLongPollingConnection } from "./httpLongPollingConnection";
import { boardRequestNode, boardAndSessionRequestNode, groupRequestNode, sessionRequestNode } from './requestNode';
import { MxNetworkState, MxCallback, MxSubscription, MxError } from '../api/defines';
import { MxBoard } from '../api/mxBoard';
import { MxMeet } from '../api/mxMeet';
import { MxObservable } from '../core/mxObserver';
import { sdkConfig } from '../core/config';
import { mxEvent, eventType } from '../core/event';
import { mxLogger, sendISDKMeetLog } from '../util';
import * as cacheMgr from '../data/cache/cacheMgr';
import { isdkInstance } from '../core/mxISDKImpl';

const MEET_KEEPALIVE_INTERVAL = 30 * 1000;

export class Connection {
  private _networkState: MxNetworkState;
  private _connection: ISocket;
  private _tryConnection: ISocket;
  private _networkStateObservable : MxObservable<MxNetworkState>;
  private _subscribedBoards: Set<string>;
  private _pendingRequests: Map<ClientRequest, {resolve: Function, reject: Function}>;
  private _isRoutingRequestsSubscribed: boolean;
  private _meetKeepAliveTimer: number;
  private _resubscribeTimer: number;

  private static _instance: Connection;
  public static getInstance(): Connection {
    if (!Connection._instance) {
      Connection._instance = new Connection();
    }
    return Connection._instance;
  }

  get networkState(): MxNetworkState {
    return this._networkState;
  }

  release(): void {
    this._isRoutingRequestsSubscribed = false;
    this._subscribedBoards.clear();
    this._pendingRequests.clear();
  }

  private constructor() {
    // navigator.onLine is NOT accurate sometimes
    // this._networkState = navigator.onLine ? MxNetworkState.REACHABLE: MxNetworkState.UNREACHABLE;
    this._networkState = MxNetworkState.REACHABLE;
    this._networkStateObservable = new MxObservable<MxNetworkState>();
    this._subscribedBoards = new Set();
    this._pendingRequests = new Map();
    this._isRoutingRequestsSubscribed = false;
    this._meetKeepAliveTimer = 0;
    this._resubscribeTimer = 0;
    this._tryConnection = null;

    // if (!sdkConfig.usingWebSocket) {
    //   mxEvent.on(eventType.USER_LOGGED_IN, () => {
    //     this.tryWebsocket();
    //   })
    // }

    window.addEventListener('online', ()=>{
      mxLogger.warn('network online');
      this.updateNetworkState(MxNetworkState.REACHABLE);
    });
    window.addEventListener('offline', ()=>{
      mxLogger.warn('network offline');
      this.updateNetworkState(MxNetworkState.UNREACHABLE);
    });

    let usingWebSocket: boolean = sdkConfig.usingWebSocket;
    let isSupportWebSocket: boolean = ('WebSocket' in window) ? true : false;

    if (isSupportWebSocket && usingWebSocket) {
      this._connection = new WebSocketConnection(this.onNetworkStateChange, this.onSubscriptionData);
    }else {
      this._connection = new HttpLongPollingConnection(this.onNetworkStateChange, this.onSubscriptionData);
    }
  }

  public subscribeNetworkState(cb: MxCallback<MxNetworkState>): MxSubscription {
    return this._networkStateObservable.subscribe(cb);
  }

  public subscribeBoard(boardId:string, revision:number): Promise<ClientResponse> {
    this._subscribedBoards.add(boardId);
    let board: Board = {
      id: boardId,
      revision:revision
    }
    return this.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SUBSCRIBE, board)).then(response => {
      cacheMgr.onRecvSubscriptionData(response);
      return response;
    }).catch(e => { return Connection.getInstance().onSubscribeBoardFailed(e);})
  }

  public unsubscribeBoard(boardId:string): Promise<ClientResponse> {
    this._subscribedBoards.delete(boardId);
    let board: Board = {
      id: boardId
    }
    return this.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UNSUBSCRIBE, board));
  }

  public subscribeCurrentMeet(): Promise<ClientResponse> {
    if (!cacheMgr.currentMeet) {
      return Promise.resolve({});
    }

    let board: Board = null;
    if (cacheMgr.currentMeet.meetBoard) {
      board = {
        id: cacheMgr.currentMeet.meetBoard.id,
        revision: cacheMgr.currentMeet.meetBoard.revision
      };
    }
    
    let session: ActionObject = null;
    if (cacheMgr.currentMeet.meetSession) {
      session = {
        session_key: cacheMgr.currentMeet.meetSession.session_key,
        revision: cacheMgr.currentMeet.meetSession.revision
      };
    }

    let log = '';
    if (board && board.revision) {
        log += `br=${board.revision}, `
    }
    if (session && session.revision) {
        log += `sr=${session.revision} `
    }

    if (log.length > 0) {
        sendISDKMeetLog('subscribeCurrentMeet: ' + log);
    }

    return this.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_SUBSCRIBE, board, session)).then(response => {
        cacheMgr.onRecvSubscriptionData(response);
        this.sendMeetKeepAlive();
        return response;
      }).catch(e => { return Connection.getInstance().onSubscribeBoardFailed(e);})
    }

  public unsubscribeCurrentMeet(): Promise<ClientResponse> {
    if (!cacheMgr.currentMeet || !cacheMgr.currentMeet.meetSession) {
      return Promise.resolve({});
    }

    let board: Board = {
      id: cacheMgr.currentMeet.getBoardId()
    }

    let session: ActionObject = {
      session_key: cacheMgr.currentMeet.meetSession.session_key,
    }

    return this.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_UNSUBSCRIBE, board, session));
  }

  public subscribeMeet(meetId: string): Promise<ClientResponse> {
    let board: Board = null;
    let mxMeet: MxMeet = cacheMgr.getMeetById(meetId);
    if (!mxMeet) return Promise.resolve({});

    if (mxMeet.meetBoard) {
      board = {
        id: mxMeet.meetBoard.id,
        revision: mxMeet.meetBoard.revision
      };
    }
    
    let session: ActionObject = null;
    if (mxMeet.meetSession) {
      session = {
        session_key: mxMeet.meetSession.session_key,
        revision: mxMeet.meetSession.revision
      };
    }

    return this.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_SUBSCRIBE, board, session)).then(response => {
        cacheMgr.onRecvSubscriptionData(response);
        return response;
      }).catch(e => { return Connection.getInstance().onSubscribeBoardFailed(e);})
    }

  public unsubscribeMeet(meetId: string): Promise<ClientResponse> {
    return this.sendRequest(boardAndSessionRequestNode(ClientRequestType.BOARD_REQUEST_UNSUBSCRIBE, {}, {session_key: meetId}));
  }

  public subscribeRoutingRequests(): Promise<ClientResponse> {
    this._isRoutingRequestsSubscribed = true;
    let group: Group = {
      id: cacheMgr.currentOrgId, 
      revision: cacheMgr.currentOrg.routingRequests.revision
    }

    return this.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_SUBSCRIBE_SERVICE_REQUESTS, group)).then(response => {
      cacheMgr.onRecvSubscriptionData(response);
      return response;
    }).catch(e => { return Connection.getInstance().onSubscribeBoardFailed(e);})
  }

  public sendMeetKeepAlive(): void {
    if (cacheMgr.currentMeet) {
      if (this._connection.state() === MxNetworkState.CONNECTED) {
        let session: ActionObject = {
          session_key: cacheMgr.currentMeet.getSessionKey()
        }

        let board: Board = {
          id: cacheMgr.currentMeet.getBoardId()
        }

        sendISDKMeetLog('send keepalive');
        this.sendRequest(boardAndSessionRequestNode(ClientRequestType.SESSION_REQUEST_KEEP_ALIVE, board, session)).catch(e => {
          mxEvent.emit(eventType.MEET_SESSION_ERROR, e);
          sendISDKMeetLog('keepalive error');
        });
      }

      window.clearTimeout(this._meetKeepAliveTimer);
      this._meetKeepAliveTimer = window.setTimeout(() => {
        this.sendMeetKeepAlive();
      }, MEET_KEEPALIVE_INTERVAL);
    }
  }

  public resubscribeBoards(): void {
    this._subscribedBoards.forEach(boardId => {
      let mxBoard: MxBoard = cacheMgr.getBoardById(boardId);
      if (mxBoard) {
        this.subscribeBoard(boardId, mxBoard.board.revision);
      }
    })

    this.subscribeCurrentMeet();

    if (this._isRoutingRequestsSubscribed) {
      this.subscribeRoutingRequests();
    }
  }
  
  public open(): void {
    if (this._connection) {
      this._connection.connect();
    }
  }

  public close(): void {
    if (this._connection) {
      this._connection.close();
    }
  }

  public reconnect(): void {
    if (this._connection) {
      this._connection.reconnect();
    }
  }

  private tryWebsocket(): void {
    if (sdkConfig.usingWebSocket) return;
    if (this._tryConnection) return;

    mxLogger.info("tryWebsocket");
    this._tryConnection = new WebSocketConnection((state: MxNetworkState) => {
      mxLogger.info("tryWebsocket:", state);
    }, (response: ClientResponse) => {
      if (response && response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED) {
        mxLogger.info("tryWebsocket success");
        sdkConfig.usingWebSocket = true;
        this._networkState = MxNetworkState.CONNECTED;
        
        // switch from long polling to websocket connection
        this._connection.setCallback(null, null);
        this._connection.close();

        this._connection = this._tryConnection;
        this._connection.setCallback(this.onNetworkStateChange, this.onSubscriptionData);
      }
    });
    this._tryConnection.connect();

    window.setTimeout(()=>{
      if(this._connection !== this._tryConnection) {
        mxLogger.info("tryWebsocket failed");
        if (this._tryConnection) {
          this._tryConnection.setCallback(null, null);
          this._tryConnection.close();
        }
      }

    }, 30*1000);
  }

  private sendRequest(req: ClientRequest): Promise<ClientResponse> {
    if (this._connection.state() === MxNetworkState.CONNECTED) {
      return this._connection.send(req);
    }else {
      return new Promise((resovle, reject) => {
        this._pendingRequests.set(req, {resolve: resovle, reject: reject});
        this._connection.connect();
      }) ;
    }
  }

  private updateNetworkState(state: MxNetworkState): void {
    if (this._networkState === MxNetworkState.CONNECTED && state === MxNetworkState.REACHABLE) {
      // fix issue on safari: close wifi several seconds and then open wifi
      // navigator online event have some delay, sometimes got it when web-socket already connected
      return;
    }

    let needReconnect = (this._networkState === MxNetworkState.UNREACHABLE && state === MxNetworkState.REACHABLE);
    this._networkState = state;
    this._networkStateObservable.publish(this._networkState);

    if (needReconnect) {
      this._connection.reconnect();
    }

    if (this._networkState === MxNetworkState.UNREACHABLE) {
      // release old web socket connection
      this._connection.close();
    }

    if (this._networkState === MxNetworkState.CONNECTED) {
      // resend pending requests
      this._pendingRequests.forEach((p, req) => {
        this._connection.send(req).then(response => {
          p.resolve && p.resolve(response);
        }).catch(e => {
          p.reject && p.reject(e);
        });
      })
      this._pendingRequests.clear();
    }
  }

  private onNetworkStateChange(state: MxNetworkState)  {
    Connection.getInstance().updateNetworkState(state);
    sendISDKMeetLog('onNetworkStateChange:' + state);
  }

  private onSubscribeBoardFailed(err: MxError) {
    if (err && err.code === ClientResponseCode.RESPONSE_ERROR_SERVICE_UNAVAILABLE) {
      window.clearTimeout(this._meetKeepAliveTimer);
      window.clearTimeout(this._resubscribeTimer);
      this._resubscribeTimer = window.setTimeout(() => {
          Connection.getInstance().resubscribeBoards();            
      }, 5000);
    }

    mxLogger.warn('onSubscribeBoardFailed:' + err);
    sendISDKMeetLog('onSubscribeBoardFailed:' + err);
    return {};
  }

  private onSubscriptionData(response: ClientResponse) {
    cacheMgr.onRecvSubscriptionData(response);

    if (response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED) {
      Connection.getInstance().resubscribeBoards();
    }else if (response.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN && cacheMgr.currentMeet) {
      // for anonymous join meet case, network reconnect need to re-subscribe current meet
      Connection.getInstance().resubscribeBoards();
    }else if (response.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN && isdkInstance.isAnonymousContext()) {
      Connection.getInstance().resubscribeBoards();
    }
  }

}
