import { ClientParam } from './../proto/generated/ClientParam';
import { BoardTag } from './../proto/generated/BoardTag';
import { User } from './../proto/generated/User';
import { BoardSignee } from './../proto/generated/BoardSignee';
import { BoardPage } from './../proto/generated/BoardPage';
import { BoardSignature } from "../proto/generated/BoardSignature";
import { Board } from "../proto/generated/Board";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { MxSPath, UserIdentity, MxPageElementType, MxSignatureElement } from './../api/defines';
import { Ajax } from "../network/ajax";
import { boardRequestNode, requestNode } from "../network/requestNode";
import { spathToObject, uuid, parseFileSequenceFromSPath } from "../util";
import { isArray } from 'util';


function getSignatureBoard(boardId: string, signatureSequence: number): Board {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
        }]
    }
    return board;
}

function listSignatures(boardId: string): Promise<ClientResponse> {
    let board: Board = {id: boardId};
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_LIST_SIGNATURES, board));
}

function readOnGoingSignatures(boardId: string): Promise<ClientResponse> {
    let board: Board = {id: boardId};
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_ONGOING_SIGNATURES, board));
}
  
function readSignature(boardId:string, sequence: number): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: sequence
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_SIGNATURE, board));
}

function createSignature(boardId: string, filePath: MxSPath, newFileName?: string): Promise<ClientResponse> {
    let board: Board = spathToObject(filePath, boardId);
    let params: ClientParam[] = [];
    if (newFileName) {
        params.push({
            name: ClientRequestParameter.BOARD_REQUEST_NEW_FILE_NAME_SEQUENCE,
            uint64_value: parseFileSequenceFromSPath(filePath),
            string_value: newFileName
          });
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_SIGNATURE, board, params));
}

function updateSignature(boardId: string, signatureSequence: number, signature: BoardSignature): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            ...signature,
            sequence: signatureSequence,
        }]
    }

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_SIGNATURE, board));
}

function deleteSignature(boardId:string, signatureSequence:number, noFeed?:boolean): Promise<ClientResponse> {
    let params: ClientParam[] = [];
    if(noFeed){
        params =[{
            name:ClientRequestParameter.BOARD_REQUEST_SUPPRESS_FEED
        }]
    }
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_SIGNATURE, getSignatureBoard(boardId, signatureSequence),params));
}

function startSignature(boardId:string, signatureSequence: number): Promise<ClientResponse> {
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_START_SIGNATURE, getSignatureBoard(boardId, signatureSequence)));
}
  
function submitSignature(boardId: string,signatureSequence: number, elements: MxSignatureElement[], keepStatusUnchanged?: boolean): Promise<ClientResponse> {
    let params: ClientParam[] = null;
    if(keepStatusUnchanged){
        params =[{
            name:ClientRequestParameter.BOARD_REQUEST_SUBMIT_SIGNATURE_KEEP_STATUS_UNCHANGED
        }]
    }

    let pages: BoardPage[] = [];
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            pages: pages,
        }]
    };

    elements.forEach((e) => {
        pages.push({
            sequence: e.pageSequence,
            contents: [{
                sequence: e.elementSequence,
                svg_tag: e.svgTag
            }]
        })
    });
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SUBMIT_SIGNATURE, board, params));
}

function declineSignature(boardId: string,signatureSequence: number, message: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
        }]
    };

    let params: ClientParam[] = [{
        'name': ClientRequestParameter.BOARD_REQUEST_SIGNEE_MESSAGE,
        'string_value': message || ''
    }];

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SUBMIT_SIGNATURE, board, params));
}

function addSignatureSignee(boardId: string, signatureSequence: number, users: UserIdentity | UserIdentity[]): Promise<ClientResponse> {
    let signees: BoardSignee[] = [];
    if (!isArray(users)) {
        users = [users];
    }

    users.forEach((u) => {
        signees.push({
            actor: {
                user: u as User
            }
        })
    });

    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            signees: signees,
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_ADD_SIGNATURE_SIGNEE, board));
}

function updateSignatureSignee(boardId: string, signatureSequence: number, signeeSequence: number, signee: BoardSignee): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            signees: [{
                ...signee,
                sequence: signeeSequence,
            }],
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_SIGNEE_UPDATE, board));
}

function createSignatureElement(boardId: string, signatureSequence: number, pageSequence: number, content: string, elementType: MxPageElementType, client_uuid?: string): Promise<ClientResponse> {
    let tags: BoardTag[];
    if (elementType) {
        tags = [];
        tags.push({
            name: 'TYPE',
            uint64_value:elementType
        })
    }

    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            pages: [{
                sequence: pageSequence,
                contents: [{
                    client_uuid:client_uuid|| uuid(),
                    svg_tag:content,
                    tags: tags
                }]
            }],
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_CREATE_SIGNATURE_PAGE_ELEMENT, board));
}

function deleteSignatureElement(boardId: string, signatureSequence: number, pageSequence: number, client_uuid: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            pages: [{
                sequence: pageSequence,
                contents: [{
                    client_uuid: client_uuid,
                    is_deleted: true
                }]
            }],
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_DELETE_SIGNATURE_PAGE_ELEMENT, board));
}

function updateSignatureElement(boardId: string, signatureSequence: number, pageSequence: number, client_uuid: string, content: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            pages: [{
                sequence: pageSequence,
                contents: [{
                    client_uuid: client_uuid,
                    svg_tag: content,
                }]
            }],
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_SIGNATURE_PAGE_ELEMENT, board));
}

function assignElementToSignee(boardId: string, signatureSequence: number, signeeSequence: number, elements: number[]): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            signees: [{
                sequence: signeeSequence,
                elements: elements
            }]         
        }]
    };
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPDATE_SIGNATURE_SIGNEE, board));
}

function uploadSignatureResourceToPage(boardId: string, signatureSequence: number, pageSequence: number, name: string, url: string): Promise<ClientResponse> {
    let board: Board = {
        id: boardId,
        signatures: [{
            sequence: signatureSequence,
            pages: [{
                sequence: pageSequence,
                resources: [{
                    name: name
                }]
            }]       
        }]
    };
    let params: ClientParam[] = [{
        'name': ClientRequestParameter.RESOURCE_UPLOAD_RESOURCE_URL,
        'string_value': url
    }];

    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_UPLOAD_SIGNATURE_RESOURCE, board, params));
}

function copySignature(fromBoardId: string, sigSeq: number, toBoardId: string): Promise<ClientResponse> {
    let toBoard: Board = {id: toBoardId};
    let fromBoard: Board = {
        id: fromBoardId,
        signatures:[{
            sequence: sigSeq
        }]
    };

    let user: User = {
        boards: [{board: fromBoard}]
    }

    return Ajax.sendRequest(requestNode(ClientRequestType.BOARD_REQUEST_COPY_SIGNATURE, null, user, toBoard));
}


export {
    listSignatures,
    readOnGoingSignatures,
    readSignature,
    createSignature,
    updateSignature,
    deleteSignature,
    startSignature,
    submitSignature,
    declineSignature,
    addSignatureSignee,
    updateSignatureSignee,
    createSignatureElement,
    deleteSignatureElement,
    updateSignatureElement,
    assignElementToSignee,
    uploadSignatureResourceToPage,
    copySignature,
 } 