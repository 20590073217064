import { UserActivityLog } from './../proto/generated/UserActivityLog';
import { UserIdentity } from './../api/defines';
import { Group } from './../proto/generated/Group';
import { ClientRequest } from "../proto/generated/ClientRequest";
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { currentOrgId, currentUser, currentUserId } from "../data/cache/cacheMgr";
import { sdkConfig } from "../core/config";
import { Ajax } from "../network/ajax";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientParam } from "../proto/generated/ClientParam";
import { ClientRequestParameter } from "../proto/generated/ClientRequestParameter";
import { groupRequestNode, boardRequestNode } from "../network/requestNode";
import { GroupUser } from '../proto/generated/GroupUser';


function postAuditLog(groupId: string, actionGroupId: string, actionTypeId: string, actionBoardId: string, payload: Object): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.GROUP_REQUEST_USER_POST_ACTIVITIES,
        user_activity: {
            "actor_org_id": currentOrgId,
            "actor_email": currentUser.basicInfo.email,
            "actor_id": currentUserId,
            "activities": [
              {
                "action_type_id": actionTypeId,
                "action_group_id": actionGroupId,
                "platform": "Web",
                "client_version": sdkConfig.clientVersion,
                "detail": {
                  "object": {
                    "board": {
                      "id": actionBoardId,
                      "tags": [
                        {
                          "name": "ACTIVITY_DETAIL",
                          "string_value": JSON.stringify(payload)
                        }
                      ]
                    }
                  }
                }
              }
            ]
          }
        };

    return Ajax.sendRequest(req);
  }

function readOrgUsage(orgId: string, timestamp: number): Promise<ClientResponse> {
    let params: ClientParam[] =[{
        name: ClientRequestParameter.GROUP_REQUEST_READ_USAGE_TIMESTAMP,
        string_value: timestamp.toString(),
    }];

    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_USAGE, {id: orgId}, params));
}

function readOrgCrmReport(orgId: string, timestamp: number): Promise<ClientResponse> {
  let params: ClientParam[] =[{
      name: ClientRequestParameter.GROUP_REQUEST_READ_USAGE_TIMESTAMP,
      string_value: timestamp.toString(),
  }];

  return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_READ_CRM_REPORT, {id: orgId}, params));
}

function searchOrgBoards(orgId: string, searchKey:string, from: number, to: number, ownerIds:string[], start: number, size: number, boardId?: string): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        name:ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_FROM,
        string_value:from.toString()
    },{
        name:ClientRequestParameter.USER_REQUEST_READ_TIMESTAMP_TO,
        string_value:to.toString()
    },{
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_START,
        string_value:start.toString()
    },{
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_SIZE,
        string_value:size.toString()
    }];
    if(searchKey){
        params.push({
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_TEXT,
        string_value:searchKey
        })
    }
    if(ownerIds){
      ownerIds.forEach((id: string)=>{
        params.push({
          name:ClientRequestParameter.BOARD_REQUEST_SEARCH_CREATOR,
          string_value:id
        })
      })
    }
    if(boardId){
        params.push({
        name:ClientRequestParameter.BOARD_REQUEST_SEARCH_ID,
        string_value:boardId
        })
    }
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.BOARD_REQUEST_SEARCH_GROUP, {id: orgId}, params));
}

function readUserActivites(orgId: string, users: UserIdentity[], fromTime: number, toTime: number, type?:string[]): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        "name": ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_FROM,
        "string_value": fromTime.toString()
    }, {
        "name": ClientRequestParameter.GROUP_REQUEST_READ_TIMESTAMP_TO,
        "string_value": toTime.toString()
    }];
    if(type){
        params.push({
        "name": ClientRequestParameter.GROUP_REQUEST_ACTION_TYPE,
        "string_value": type.join(',')
        })
    }

    let members: GroupUser[] = [];
    users.forEach(user => {
        members.push({
            user: user
        });
    });

    let group: Group = {
        id: orgId,
        members: members,
    };

    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_USER_READ_ACTIVITIES, group, params));
}

function readAuditObject(orgId: string, boardId: string): Promise<ClientResponse> {
  return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_AUDIT_OBJECT, {id: boardId}));
}

export {
    postAuditLog,
    readOrgUsage,
    readOrgCrmReport,
    searchOrgBoards,
    readUserActivites,
    readAuditObject,
}