var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-row" }, [
    _c(
      "div",
      { staticStyle: { "padding-right": "6px" } },
      [
        _c("service-request-user-avatar", {
          attrs: { "user-avatar": _vm.userAvatar }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          flex: "1",
          display: "flex",
          "flex-direction": "column",
          "max-width": "inherit",
          position: "relative"
        }
      },
      [
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.feed.name))]),
          _vm._v(" "),
          _c("span", { staticClass: "comment-time" }, [
            _vm._v(_vm._s(_vm.displayTime))
          ])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.feed.comment))
        ]),
        _vm._v(" "),
        _vm.feed.isFake && !_vm.feed.isFailed
          ? _c("div", {
              directives: [
                {
                  name: "mx-spinner",
                  rawName: "v-mx-spinner",
                  value: _vm.spinOptions,
                  expression: "spinOptions"
                }
              ],
              staticClass: "spinner-container"
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.feed.isFake && _vm.feed.isFailed
          ? _c("img", {
              staticClass: "failed-tip spinner-container",
              staticStyle: { float: "right" },
              attrs: { src: _vm.BUBBLE_IMAGE }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }