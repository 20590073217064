import { MxSPath } from '../api/defines';
import { ClientResponseCode } from './../proto/generated/ClientResponseCode';
import { ClientResponse } from "../proto/generated/ClientResponse";
import { UserOSType } from '../proto/generated/UserOSType';

import cloneDeep from 'lodash/cloneDeep'
import {ObjectUtils} from '../../../commonUtils/object'
import {FunctionUtil} from '../../../commonUtils/function'
import {ArrayUtils} from '../../../commonUtils/array'
import {TimeUtils} from '../../../commonUtils/time'
import {StringUtils} from '../../../commonUtils/string'
import {BrowserUtils} from '../../../commonUtils/browser'
import { MxLogger } from '../../../commonUtils/MxLogger';
import { isdkInstance } from '../core/mxISDKImpl';
import { currentMeet } from '../data/cache/cacheMgr';

export {
  ObjectUtils,
  FunctionUtil,
  ArrayUtils,
  TimeUtils,
  StringUtils,
}

export const mxLogger = MxLogger.create('isdk');

export const getByPath = ObjectUtils.getByPath;
export const getBySPath = ObjectUtils.getBySPath;
export const parseSPath = ObjectUtils.parseSPath;
export const mergeObject = ObjectUtils.mergeObject;
export const getFromArray = ArrayUtils.getFromArray;
export const isArray = Array.isArray;
export const uuid = FunctionUtil.uuid;

export function sendISDKMeetLog(log: string): void {
  if (!currentMeet) return;

  isdkInstance.sendMeetLog(currentMeet.getSessionKey(), '[ISDK] ' + log);
}

export function isMeetId(key: string): boolean {
  return /^(\d{9})$/.test(key);
}

export function cloneObject(obj:any): any {
  return cloneDeep(obj);
}

export function getOSType(): UserOSType {
  let osName = BrowserUtils.os.name.toLowerCase();
  let type = UserOSType.OS_TYPE_WINDOWS;

  if(osName === 'windows'){
    type =  UserOSType.OS_TYPE_WINDOWS;
  }else if(osName === 'mac os'){
    type = UserOSType.OS_TYPE_MAC;
  }else if(osName === 'android'){
    type = UserOSType.OS_TYPE_ANDROID;
  }else if(osName === 'ios'){
    type = UserOSType.OS_TYPE_IOS;
  }
  
  return type;
}

export function isSubscriptionResponse(response: ClientResponse): boolean {
  if (response.code === ClientResponseCode.RESPONSE_SUBSCRIPTION_DATA 
    || response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED) {
    return true;
  }
  return false;
}

export function isSuccessResponse(response: ClientResponse): boolean {
  if (response.code === ClientResponseCode.RESPONSE_SUCCESS 
    || response.code === ClientResponseCode.RESPONSE_SUBSCRIPTION_DATA
    || response.code === ClientResponseCode.RESPONSE_CONNECTION_TOKEN_VERIFIED) {
    return true;
  }
  return false;
}

export function isInvalidTokenResponse(response: ClientResponse): boolean {
  if (response.code === ClientResponseCode.RESPONSE_ERROR_INVALID_TOKEN) {
    return true;
  }
  return false;
}

export function spathToObject(spath: MxSPath, objId?: string): Object {
  let root: Object = {};
  if (objId) {
    root['id'] = objId;
  }

  if (!spath) {
    return root;
  }

  let paths = spath.split('.');
  let obj = root;
  let path;
  let info;
  let node;

  for(let i = 0 ; i < paths.length; i++){
    path = paths[i];
    info = ObjectUtils.parseSPath(path);
    node = {};
    node[info.attrName] = info.attrVal;
    obj[info.key] = [node];
    obj = node
  }
  return root;
}

// filePath: /100
// filePath: /100/200/300
export function filepath2spath(filePath: string): string {
    let paths: number[] = filePath.split('/').filter(s => s).map(s => parseInt(s));
    let spath = '';
    if (paths.length === 1) {
        spath = `page_groups[sequence=${paths[0]}]`;
    }else if (paths.length > 1) {
        for (let i = 0; i < paths.length-1; i++) {
            spath += `folders[sequence=${paths[i]}].`;
        }
        spath += `files[sequence=${paths.pop()}]`;
    }

    return spath;
}

// filePath: /100/200/300, return 300
export function parseFileSequenceFromFilePath(filePath: string) : number {
  if (filePath && filePath.split('/').length > 0) {
      return parseInt(filePath.split('/').pop());
  }
  return 0;
}

export function parseFileSequenceFromSPath(filePath: MxSPath): number {
  let lastPath = filePath.split('.').pop();
  let info = ObjectUtils.parseSPath(lastPath);
  return info.attrVal;
}