import { BoardPageGroup } from './../../proto/generated/BoardPageGroup';
import { ClientResponseCode } from './../../proto/generated/ClientResponseCode';
import { ObjectFeedType } from './../../proto/generated/ObjectFeedType';
import { BoardResourceStatus } from './../../proto/generated/BoardResourceStatus';
import { BoardResource } from './../../proto/generated/BoardResource';
import { BoardPage } from './../../proto/generated/BoardPage';
import { BoardFolder } from './../../proto/generated/BoardFolder';
import { BoardSession } from './../../proto/generated/BoardSession';
import { BoardTransaction } from './../../proto/generated/BoardTransaction';
import { BoardSignature } from './../../proto/generated/BoardSignature';
import { BoardComment } from './../../proto/generated/BoardComment';
import { BoardUser } from './../../proto/generated/BoardUser';
import { Board } from './../../proto/generated/Board';
import { ObjectFeed } from "../../proto/generated/ObjectFeed";
import { ClientResponse } from '../../proto/generated/ClientResponse';
import { mergeBoardCacheObject } from '../../proto';
import { MxCallback, MxSubscription, MxSPath, MxBaseObject, MxError } from './../../api/defines';
import { cloneObject, getByPath, mergeObject, getBySPath, parseSPath, spathToObject, parseFileSequenceFromFilePath, mxLogger } from '../../util';
import { sdkConfig } from '../../core/config';
import { MxObservable } from "../../core/mxObserver";
import { BoardCache } from '../cache/boardCache';
import { getSequences } from '../cache/common';
import { currentUserId } from '../cache/cacheMgr';
import * as bboard from '../../biz/board';
import * as bfile from '../../biz/file';
import * as bthread from '../../biz/thread';

interface MxFileInfo {
    pages?: Set<number>;
    files?: Set<number>;
    resources?: Set<number>;
    signatures?: Set<number>;
}

function getFilesInFolder(folder: BoardFolder, files: Set<number>): void {
    folder.files && folder.files.forEach(file => {
        file.sequence && files.add(file.sequence);
    })

    folder.folders && folder.folders.forEach(subFolder => {
        getFilesInFolder(subFolder, files);
    })
}

function parseBoardFiles(board: Board): MxFileInfo {
    let f: MxFileInfo = {
        pages: new Set(),
        files: new Set(),
        resources: new Set(),
        signatures: new Set(),
    }

    board.pages && board.pages.forEach(page => {
        page.sequence && f.pages.add(page.sequence)
    })

    board.page_groups && board.page_groups.forEach(file => {
        file.sequence && f.files.add(file.sequence)
    })

    board.folders && board.folders.forEach(folder => {
        getFilesInFolder(folder, f.files);
    })

    board.resources && board.resources.forEach(resource => {
        resource.sequence && f.resources.add(resource.sequence)
    })

    board.signatures && board.signatures.forEach(signature => {
        signature.sequence && f.signatures.add(signature.sequence)
    })

    return f;
}


// filePath: /100/200/300, return 200
function getParentFolderSequence(filePath: string): number {
    if (!filePath)  return -1;

    let s = filePath.split('/');
    if (s.length === 1) return -1; // invalid path
    if (s.length === 2) return 0;  // root folder

    if (s.length > 2) {
        return parseInt(s[s.length-2]);
    }

    return -1;
}

// folderPath: folders[sequence=100].folders[sequence=200], return 200
function parseFolderSequence(folderSPath: MxSPath): number {
    if (folderSPath === '')  return 0; // root folder

    let spath = folderSPath.split('.').pop();
    let ret = parseSPath(spath);
    if (ret && ret.attrVal) {
        return parseInt(ret.attrVal);
    }
    return -1;
}

function getUpdatedBoard(currentFolderSPath: MxSPath, cacheBoard: Board, updatedFiles: Set<number>, updatedFolders: Set<number>, updatedResources: Set<number>, updatedPages: Set<number>): Board {
    let board: Board = {
        id: cacheBoard.id,
        page_groups: [],
        pages: [],
        resources: [],
        folders: [],
    };

    if (updatedFiles.size > 0 && cacheBoard.page_groups) {
        board.page_groups = cacheBoard.page_groups.filter(f => updatedFiles.has(f.sequence));
    }

    if (updatedPages.size > 0 && cacheBoard.pages) {
        board.pages = cacheBoard.pages.filter(p => updatedPages.has(p.sequence));
    }

    if (updatedResources.size > 0 && cacheBoard.resources) {
        board.resources = cacheBoard.resources.filter(r => updatedResources.has(r.sequence));
    }

    if ((updatedFiles.size > 0 || updatedFolders.size > 0) && cacheBoard.folders) {
        if (currentFolderSPath) {
            let currentFolder: BoardFolder = getBySPath(cacheBoard, currentFolderSPath);
            let updatedBoard: Board = spathToObject(currentFolderSPath) as Board;  
            let updatedFolder: BoardFolder = getBySPath(updatedBoard, currentFolderSPath);

            // fill in all folder name
            let subFolders = updatedBoard.folders;
            let spath = '';
            while (subFolders && subFolders.length > 0) {
                let subFolder = subFolders[0];
                if (spath.length > 0) {
                    spath += '.';
                }
                spath += `folders[sequence=${subFolder.sequence}]`;
                let cacheSubFolder: BoardFolder = getBySPath(cacheBoard, spath);
                if (cacheSubFolder) {
                    subFolder.name = cacheSubFolder.name;
                }

                // recursive
                subFolders = subFolder.folders;
            }
            
            let updatedFolder2: BoardFolder = {
                sequence: currentFolder.sequence,
                name: currentFolder.name,
                files: currentFolder.files ? currentFolder.files.filter(f => updatedFiles.has(f.sequence)) : [],
                folders: currentFolder.folders ? currentFolder.folders.filter(f => updatedFolders.has(f.sequence)) : [],
            };

            mergeObject(updatedFolder, updatedFolder2);
            mergeObject(board, updatedBoard);
        }else {
            board.folders = cacheBoard.folders.filter(f => updatedFolders.has(f.sequence));
        }
    }

    return board;
}

export class MxBoardSubscriber {
    private _boardId: string;
    private _boardCache: BoardCache;
    private _pendingUpdateFeeds: Set<number>;
    private _basicInfoObservable : MxObservable<Board>;
    private _meetDetailObservable : MxObservable<Board>;
    private _feedsObservable : MxObservable<ObjectFeed[]>;
    private _commentsObservable : MxObservable<BoardComment[]>;
    private _todosObservable : MxObservable<Board>;
    private _filesObservable : Map<MxSPath, MxObservable<Board>>;
    private _foldersObservable : Map<MxSPath, MxObservable<Board>>;
    private _sessionObservable : MxObservable<BoardSession[]>;
    private _signaturesObservable : MxObservable<BoardSignature[]>;
    private _transactionsObservable : MxObservable<BoardTransaction[]>;
    private _threadsObservable: Map<MxBaseObject, MxObservable<Board>>;

    constructor(boardCache: BoardCache) {
        this._boardId = boardCache.board.id;
        this._boardCache = boardCache;
        this._pendingUpdateFeeds = new Set();

        this._basicInfoObservable = new MxObservable<Board>('board.basicInfo');
        this._meetDetailObservable = new MxObservable<Board>('board.meetDetail');
        this._feedsObservable = new MxObservable<ObjectFeed[]>('board.feeds');
        this._commentsObservable = new MxObservable<BoardComment[]>('board.comments');
        this._todosObservable = new MxObservable<Board>('board.todos');
        this._filesObservable = new Map<MxSPath, MxObservable<Board>>();
        this._foldersObservable = new Map<MxSPath, MxObservable<Board>>();
        this._sessionObservable = new MxObservable<BoardSession[]>('board.sessions');
        this._signaturesObservable = new MxObservable<BoardSignature[]>('board.signatures');
        this._transactionsObservable = new MxObservable<BoardTransaction[]>('board.transactions');
        this._threadsObservable = new Map<MxBaseObject, MxObservable<Board>>();
    }

    subscribeBasicInfo(cb: MxCallback<Board>): MxSubscription {
        return this._basicInfoObservable.subscribe(cb);
    }

    subscribeMeetDetail(cb: MxCallback<Board>): MxSubscription {
        return this._meetDetailObservable.subscribe(cb);
    }

    subscribeFeeds(cb: MxCallback<ObjectFeed[]>): MxSubscription {
        return this._feedsObservable.subscribe(cb);
    }

    subscribeComments(cb: MxCallback<BoardComment[]>): MxSubscription {
        return this._commentsObservable.subscribe(cb);
    }

    subscribeTodos(cb: MxCallback<Board>): MxSubscription {
        return this._todosObservable.subscribe(cb);
    }

    subscribeSessions(cb: MxCallback<BoardSession[]>): MxSubscription {
        return this._sessionObservable.subscribe(cb);
    }

    subscribeSignatures(cb: MxCallback<BoardSignature[]>): MxSubscription {
        return this._signaturesObservable.subscribe(cb);
    }

    subscribeTransactions(cb: MxCallback<BoardTransaction[]>): MxSubscription {
        return this._transactionsObservable.subscribe(cb);
    }

    subscribeFile(filePath: MxSPath, cb: MxCallback<Board>): MxSubscription {
        let observable: MxObservable<Board>;
        if (this._filesObservable.has(filePath)) {
            observable = this._filesObservable.get(filePath);
        }else {
            observable = new MxObservable<Board>(`board.file: ${filePath}`);
            this._filesObservable.set(filePath, observable);
        }

        return observable.subscribe(cb);
    }

    subscribeFolder(parentFolder: MxSPath, cb: MxCallback<Board>): MxSubscription {
        let observable: MxObservable<Board>;
        if (!parentFolder) {
            // root folder
            parentFolder = '';
        }

        if (this._foldersObservable.has(parentFolder)) {
            observable = this._foldersObservable.get(parentFolder);
        }else {
            observable = new MxObservable<Board>(`board.folder: ${parentFolder}`);
            this._foldersObservable.set(parentFolder, observable);
        }

        return observable.subscribe(cb);
    }

    subscribeThread(baseObj: MxBaseObject, cb: MxCallback<Board>): MxSubscription {
        let observable: MxObservable<Board>;
        if (this._threadsObservable.has(baseObj)) {
            observable = this._threadsObservable.get(baseObj);
        }else {
            observable = new MxObservable<Board>(`board.thread: ${baseObj.spath}`);
            this._threadsObservable.set(baseObj, observable);
        }

        return observable.subscribe(cb);
    }

    onBasicInfoUpdate(): void {
        const board: Board = this._boardCache.basicInfo();
        this._basicInfoObservable.publish(board);
    }

    onObjectUpdate(obj: Board): void {
        if (!obj) return;
        
        if ((obj.local_revision || obj.users || obj.tags || obj.is_deleted) && this._basicInfoObservable.hasObservers()) {
            this._basicInfoObservable.publish(this._boardCache.basicInfo());
        }

        if (this._meetDetailObservable.hasObservers()) {
            // callback full board
            this._meetDetailObservable.publish(this._boardCache.board);
        }

        if (this._todosObservable.hasObservers()) {
            let b: Board = {id: this._boardId};
            if (obj.todos) {
                b.todos = this._boardCache.cacheTodos(getSequences(obj.todos), true);
                b.reference_links = this._boardCache.getReferenceLinksByTodos(b.todos.map(todo => todo.sequence));
            } else if (obj.reference_links) {
                b.todos = this._boardCache.getTodosByReferenceLinks(obj.reference_links.map(link => link.sequence));
                b.reference_links = this._boardCache.getReferenceLinksByTodos(b.todos.map(todo => todo.sequence));
            }

            if (b.todos || b.reference_links) {
                this._todosObservable.publish(b);
            }
        }

        if (obj.signatures && this._signaturesObservable.hasObservers()) {
            let signatures: BoardSignature[] = this._boardCache.cacheSignatures(getSequences(obj.signatures), true);
            if (signatures && signatures.length > 0) {
                this._signaturesObservable.publish(signatures);
            }
        }

        if (obj.sessions && this._sessionObservable.hasObservers()) {
            let sessions: BoardSession[] = this._boardCache.cacheSessions(getSequences(obj.sessions), true);
            if (sessions && sessions.length > 0) {
                this._sessionObservable.publish(sessions);
            }
        }

        if (obj.transactions && this._transactionsObservable.hasObservers()) {
            let transactions: BoardTransaction[] = this._boardCache.cacheTransactions(getSequences(obj.transactions), true);
            if (transactions && transactions.length > 0) {
                this._transactionsObservable.publish(transactions);
            }
        }

        // handle thread base object update
        this._threadsObservable.forEach((observable, baseObj) => {
            if (observable.hasObservers()) {
                // if thread base object not updated, return null
                let updatedThreadBoard: Board = this._boardCache.updateCacheThread(baseObj, obj);
                if (updatedThreadBoard && updatedThreadBoard['needReloadThread']) {
                    bthread.readThread(this._boardId, baseObj).then(response => {
                        let threadBoard: Board = getByPath(response, 'object.board', null);
                        if (threadBoard) {
                            this._boardCache.addCacheThread(baseObj, threadBoard);
                            let updatedBaseBoard: Board = cloneObject(threadBoard);
                            delete updatedBaseBoard.feeds; // only callback thread base object
                            observable.publish(updatedBaseBoard);
                        }
                    });
                }else if (updatedThreadBoard) {
                    observable.publish(updatedThreadBoard);
                }
            }
        })

        this.handleFeedUpdate(obj);

        this.handleFileUpdate(obj);
    }

    handleFeedUpdate(obj: Board): void {
        if (sdkConfig.isMeetSdk && this._boardCache.board.islive) {
            // for meet case, do not care feeds update
            return;
        }

        if (obj.feeds) {
            this._boardCache.addMainStreamFeeds(obj.feeds, false);
        }

        let excludedFeeds: Set<number> = new Set();
        obj.feeds && obj.feeds.forEach(feed => {
            if (feed.type === ObjectFeedType.FEED_RELATIONSHIP_LEAVE || feed.type === ObjectFeedType.FEED_RELATIONSHIP_REMOVE) {
                let leftMemberSeq: number = getByPath(feed, 'board.users.0.sequence');
                let leftMember: BoardUser = this._boardCache.getBoardUserBySequence(leftMemberSeq);
                let leftMemberId: string = getByPath(leftMember, 'user.id');
                if (leftMemberId === currentUserId) {
                    excludedFeeds.add(feed.sequence);
                }
            }
        })

        let updatedFeeds: Set<number> = this._boardCache.getBoardRelatedFeeds(obj);
        excludedFeeds.forEach(feed => updatedFeeds.delete(feed));

        // when convert multi-pages files, may receive subscription data page by page
        // no need to read flat feed for each page update
        obj.resources && obj.resources.forEach(resource => {
            if (resource.status === BoardResourceStatus.BOARD_RESOURCE_STATUS_CONVERTING
                && resource.sequence 
                && resource.converted_pages && resource.converted_pages > 1) {
                obj.feeds && obj.feeds.forEach(feed => {
                    if (feed.type === ObjectFeedType.FEED_PAGES_CREATE && resource.sequence === getByPath(feed, 'board.resources.0.sequence')) {
                        let cacheFeed: ObjectFeed = this._boardCache.getFeedBySequence(feed.sequence);
                        let updatedFeedBoard: Board = {
                            resources: [resource],
                        }
                        mergeObject(cacheFeed.board, updatedFeedBoard);
                        this._feedsObservable.publish([cacheFeed]);
                        updatedFeeds.delete(feed.sequence);
                    }
                })
            }
        })

        if (updatedFeeds.size > 0 && (this._feedsObservable.hasObservers() || this._threadsObservable.size > 0)) {
            // merge pending feeds
            this._pendingUpdateFeeds.forEach(feed => {updatedFeeds.add(feed)})
            this._pendingUpdateFeeds.clear();

            bboard.readFlatFeeds(this._boardId, Array.from(updatedFeeds).sort((a, b) => a - b)).then((response: ClientResponse) => {
                let feeds: ObjectFeed[] = getByPath(response, 'object.board.feeds', []);
                // update flat feeds to board cache
                this._boardCache.addCacheFeeds(feeds);
                // main stream feeds update
                this._feedsObservable.publish(this._boardCache.filterMainStreamFeeds(feeds));
                // thread feeds update
                this._threadsObservable.forEach((observable, baseObj) => {
                    if (observable.hasObservers()) {
                        let updatedThreadFeeds: ObjectFeed[] = this._boardCache.filterThreadFeeds(feeds, baseObj);
                        if (updatedThreadFeeds && updatedThreadFeeds.length > 0) {
                            let board: Board = {
                                id: this._boardId,
                                feeds: updatedThreadFeeds
                            }
                            observable.publish(board);
                        }
                    }
                })
            }).catch(e => {
                this._pendingUpdateFeeds = cloneObject(updatedFeeds);
                mxLogger.warn("read flat feed failed:", e);
            })
        }
    }

    handleFileUpdate(obj: Board): void {
        if (!obj.pages && !obj.page_groups && !obj.folders && !obj.resources && !obj.signatures && !obj.total_signatures) {
            return;
        }

        let updatedFilesInfo: MxFileInfo = parseBoardFiles(obj);

        this._filesObservable.forEach((observable, filePath) => {
            let cacheFileBoard = this._boardCache.cacheFile(filePath);
            if (cacheFileBoard && observable.hasObservers()) {
                let cacheFileInfo: MxFileInfo = parseBoardFiles(cacheFileBoard);
                let isFileUpdated = false;
                let isFileConverting = false;
                let updatedPages: Set<number> = new Set();

                // signature update
                cacheFileInfo.signatures.forEach(seq => {
                    if (updatedFilesInfo.signatures.has(seq)) {
                        let updatedSignature: BoardSignature = getBySPath(obj, filePath);
                        mergeBoardCacheObject(cacheFileBoard, {
                            id: this._boardId,
                            revision: obj.revision,
                            signatures:[updatedSignature]
                        } as Board);

                        let updatedSignaturePages: Set<number> = new Set();
                        updatedSignature.pages && updatedSignature.pages.forEach(p => {
                            updatedSignaturePages.add(p.sequence);
                        })

                        let b: Board = cloneObject(cacheFileBoard);
                        // only callback updated pages
                        if (cacheFileBoard.signatures 
                            && cacheFileBoard.signatures.length > 0
                            && cacheFileBoard.signatures[0].pages) {
                            b.signatures[0].pages = cacheFileBoard.signatures[0].pages.filter(page => {
                                return updatedSignaturePages.has(page.sequence);
                            })
                        }
                        observable.publish(b);
                    }
                })

                cacheFileInfo.files.forEach(seq => {
                    if (updatedFilesInfo.files.has(seq)) {
                        isFileUpdated = true;
                    }
                })

                !isFileUpdated && cacheFileInfo.resources.forEach(seq => {
                    if (updatedFilesInfo.resources.has(seq)) {
                        isFileUpdated = true;

                        // for converting file, do not need to reload file on each page update
                        obj.resources && obj.resources.forEach(resource => {
                            if (resource.sequence === seq && resource.status === BoardResourceStatus.BOARD_RESOURCE_STATUS_CONVERTING) {
                                isFileConverting = true;
                                let convertedPages: BoardPage[] = [];
                                if (!cacheFileBoard.pages || cacheFileBoard.pages.length === 0) {
                                    // for converting file, only output first page
                                    obj.pages && obj.pages.forEach(page => {
                                        if (page.file && page.sequence) {
                                            let fileSeq = parseFileSequenceFromFilePath(page.file);
                                            if (cacheFileInfo.files.has(fileSeq)) {
                                                convertedPages.push(page);
                                            }
                                        }
                                    });
                                }

                                let b: Board = {
                                    pages: convertedPages,
                                    resources: [resource]
                                }
                                mergeObject(cacheFileBoard, b);
                            }
                        })
                    }
                })

                !isFileUpdated && cacheFileInfo.pages.forEach(seq => {
                    if (updatedFilesInfo.pages.has(seq)) {
                        updatedPages.add(seq);
                    }
                })

                !isFileUpdated && obj.pages && obj.pages.forEach(page => {
                    // new page created
                    if (page.file && page.sequence) {
                        let fileSeq = parseFileSequenceFromFilePath(page.file);
                        if (cacheFileInfo.files.has(fileSeq)) {
                            updatedPages.add(page.sequence);
                        }
                    }
                })

                if (isFileConverting) {
                    observable.publish(cacheFileBoard);
                }else if (isFileUpdated) {
                    // reload file
                    bfile.readFile(this._boardId, filePath).then(response => {
                        let b = getByPath(response, 'object.board');
                        if (b) {
                            // replace cache file
                            this._boardCache.addCacheFile(filePath, b);
                            observable.publish(b);
                        }
                    }).catch((e: MxError) => {
                        // file deleted case
                        if (e && e.code === ClientResponseCode.RESPONSE_ERROR_NOT_FOUND) {
                            let f: BoardPageGroup = getBySPath(cacheFileBoard, filePath);
                            if (f) {
                                f.is_deleted = true;
                            }
                            observable.publish(cacheFileBoard);
                        }
                    })
                }else if (updatedPages.size > 0) {
                    // reload pages
                    bfile.readPages(this._boardId, Array.from(updatedPages)).then(response => {
                        let b = getByPath(response, 'object.board');
                        if (b) {
                            // update cache file
                            mergeBoardCacheObject(cacheFileBoard, b);
                            observable.publish(b);
                        }
                    })
                }
            }
        })

        this._foldersObservable.forEach((observable, folderPath) => {
            let folderSeq = parseFolderSequence(folderPath);
            let cacheFolderBoard = this._boardCache.cacheFolder(folderPath);
            if (cacheFolderBoard && observable.hasObservers()) {
                let cacheFolderInfo: MxFileInfo = parseBoardFiles(cacheFolderBoard);
                let updatedFiles: Set<number> = new Set();
                let updatedFolders: Set<number> = new Set();
                let updatedResources: Set<number> = new Set();
                let updatedPages: Set<number> = new Set();

                if (folderPath === '') {
                    // signature updated
                    if (obj.total_signatures) {
                        observable.publish({id: this._boardId, total_signatures: obj.total_signatures});
                    }

                    // root folder updated
                    if (obj.page_groups) {
                        mergeObject(cacheFolderBoard, {page_groups: obj.page_groups} as Board);
                        obj.page_groups.forEach(f => updatedFiles.add(f.sequence));
                    }

                    if (obj.folders) {
                        mergeObject(cacheFolderBoard, {folders: obj.folders} as Board);
                        obj.folders.forEach(f => updatedFolders.add(f.sequence));
                    }
                }else {
                    // sub folder updated
                    if (obj.folders) {
                        obj.folders.forEach(f => {
                            let tmpBoard: Board = {folders: [f]};
                            let matchedFolder: BoardFolder = getBySPath(tmpBoard, folderPath);
                            if (matchedFolder) {
                                mergeObject(cacheFolderBoard, tmpBoard);
                                if (matchedFolder.local_revision) {
                                    updatedFolders.add(matchedFolder.sequence);
                                }
                                matchedFolder.folders && matchedFolder.folders.forEach(folder => {
                                    updatedFolders.add(folder.sequence);
                                });
                                matchedFolder.files && matchedFolder.files.forEach(file => {
                                    updatedFiles.add(file.sequence);
                                });
                            }
                        })
                    }
                }

                if (obj.pages) {
                    let newFiles: Set<string> = new Set();
                    obj.pages.filter(page => page.local_revision).forEach(page => {
                        if (cacheFolderInfo.pages.has(page.sequence)) {
                            mergeObject(cacheFolderBoard, {pages: [page]});

                            let page2: BoardPage = getBySPath(cacheFolderBoard, `pages[sequence=${page.sequence}]`);
                            if (page2 && page2.file) {
                                updatedFiles.add(parseFileSequenceFromFilePath(page2.file));
                                updatedPages.add(page.sequence);
                            }
                        }else if (page.file && getParentFolderSequence(page.file) === folderSeq && !newFiles.has(page.file)) {
                            // if new file have multiple pages, only merge first page
                            mergeObject(cacheFolderBoard, {pages: [page]});
                            newFiles.add(page.file);
                            updatedFiles.add(parseFileSequenceFromFilePath(page.file));
                            updatedPages.add(page.sequence);
                        }
                    })
                }

                if (obj.resources) {
                    obj.resources.forEach(resource => {
                        if (cacheFolderInfo.resources.has(resource.sequence)) {
                            mergeObject(cacheFolderBoard, {resources: [resource]});
                            let resource2: BoardResource = getBySPath(cacheFolderBoard, `resources[sequence=${resource.sequence}]`);
                            if (resource2 && resource2.file) {
                                updatedFiles.add(parseFileSequenceFromFilePath(resource2.file));
                            }
                            updatedResources.add(resource.sequence);
                        }else if (resource.file && getParentFolderSequence(resource.file) === folderSeq) {
                            // new file resource
                            mergeObject(cacheFolderBoard, {resources: [resource]});
                            updatedFiles.add(parseFileSequenceFromFilePath(resource.file));
                            updatedResources.add(resource.sequence);
                        }
                    })
                }

                if (updatedFiles.size > 0) {
                    // file updated, need to callback related pages & resources
                    cacheFolderBoard.pages && cacheFolderBoard.pages.forEach(page => {
                        if (page.file && updatedFiles.has(parseFileSequenceFromFilePath(page.file))) {
                            updatedPages.add(page.sequence);
                        }
                    });

                    cacheFolderBoard.resources && cacheFolderBoard.resources.forEach(r => {
                        if (r.file && updatedFiles.has(parseFileSequenceFromFilePath(r.file))) {
                            updatedResources.add(r.sequence);
                        }
                    });
                }

                if (updatedFiles.size > 0 || updatedFolders.size > 0 || updatedResources.size > 0 || updatedPages.size > 0) {
                    observable.publish(getUpdatedBoard(folderPath, cacheFolderBoard, updatedFiles, updatedFolders, updatedResources, updatedPages));
                }
            }
        })
    }
}


