import {Defines, MxISDK} from "isdk";
import {BoardRoutingStatus} from "isdk/src/proto/generated/BoardRoutingStatus";
import {RoutingConfig} from "isdk/src/proto/generated/RoutingConfig";
import {ObjectUtils} from "../../commonUtils";
import {UserGroup} from "isdk/src/proto/generated/UserGroup";
import {Group} from "isdk/src/proto/generated/Group";
import {RoutingChannel} from "isdk/src/proto/generated/RoutingChannel";
import {isAnonymousContext} from "@controller/utils/anon";
import {CUserInfo} from "@controller/defines/CUserInfo";


export class AcdController {

    static openAcdRequest(binderId, view_token?: string) {
        if(isAnonymousContext()){
            return MxISDK.getAnonymousUser().updateRoutingRequestStatus(binderId, BoardRoutingStatus.ROUTING_STATUS_OPEN, {viewToken: view_token} )
        } else
            return MxISDK.getCurrentUser().updateRoutingRequestStatus(binderId, BoardRoutingStatus.ROUTING_STATUS_OPEN)
    }

    static closeAcdRequest(binderId: string, view_token?: string) {
        if(isAnonymousContext() || view_token){
            return MxISDK.getAnonymousUser().updateRoutingRequestStatus(binderId, BoardRoutingStatus.ROUTING_STATUS_CLOSE, {viewToken: view_token} )
        } else
            return MxISDK.getCurrentUser().updateRoutingRequestStatus(binderId, BoardRoutingStatus.ROUTING_STATUS_CLOSE)
    }

    static requestWaitingAcd(binderId: string) {
        return MxISDK.getCurrentUser().updateRoutingRequestStatus(binderId, BoardRoutingStatus.ROUTING_STATUS_OPEN_NO_TIMEOUT)
    }

    static acceptAcdRequest(binderId: string) {
        return MxISDK.getCurrentUser().acceptRoutingRequest(binderId)
    }

    static declineAcdRequest(binderId: string) {
        return MxISDK.getCurrentUser().declineRoutingRequest(binderId)
    }

    static isSelfAcdAgent() {
        const mxGroup = MxISDK.getCurrentOrg()
        let mxUser = MxISDK.getCurrentUser();
        const teams = ObjectUtils.getByPath(mxGroup, 'group.teams')
        const routingConfig: RoutingConfig = mxGroup.routingConfig
        if (routingConfig) {
            let channels: RoutingChannel[] = routingConfig.acd_channels

            if (channels) {
                for(let i = 0; i< channels.length; i++) {
                    let channel = channels[i]
                    if(!channel.is_deleted) {
                        const teamSequence = ObjectUtils.getByPath(channel, "teams.0.sequence")
                        let acdTeam: UserGroup = teams.find(team => team.sequence === teamSequence)
                        if(mxUser.basicInfo.groups.findIndex(group => acdTeam && !group.is_deleted && group.group.id === acdTeam.group.id) >= 0) {
                            return true;
                        }
                    }
                }
            }
        }

        return false
    }

    static createChannel(channelName: string, channelSequence: number) {
        return MxISDK.getCurrentUser().createRoutingRequest(Defines.MxRoutingChannelType.ACD, "", '', channelSequence, BoardRoutingStatus.ROUTING_STATUS_CLOSE)
    }
}
