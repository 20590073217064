import {UAParser} from 'ua-parser-js'

export class Browser {
  private parser: UAParser;

  constructor(ua?: string) {
    this.parser = new UAParser(ua)
  }

  get os(): { name: string, version: string } {
    return this.parser.getOS()
  }

  get device(): { model: string, type: string, vendor: string } {
    return this.parser.getDevice()
  }

  get browser(): { name: string, version: string } {
    return this.parser.getBrowser()
  }

  get browserVersion() {
    let version = this.browser.version
    let intVersion = 0
    if(typeof version !== 'string' || version === '') {
      intVersion = 0
    } else {
      version = version.replace(/[^\d\.]/g,'').split(".")[0]
      intVersion = parseInt(version)
    }
    return intVersion
  }

  get isIOS(): boolean {
    return this.os.name === 'iOS' || ((/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        !window['MSStream'])
  }

  get isMac(): boolean {
    return this.os.name === 'Mac OS'
  }

  get isWin(): boolean {
    return this.os.name.toLowerCase() === 'windows'
  }

  get isChromeOS(): boolean {
    return this.os.name.toLowerCase() === 'chromium os'
  }

  get isMobile(): boolean {
    return this.device.type === 'mobile'
  }

  get isTablet(): boolean {
    if(this.isIOS){
       return !this.isMobile
    } else {
      if (!this.device.type && this.isAndroid) {
        return !this.isMobile
      } else {
        return this.device.type === 'tablet'  
      }
    }
  }

  get isAndroid(): boolean {
    return this.os.name === 'Android'
  }

  get isPad():boolean{

    return this.isIOS && !this.isMobile
  }

  get isFirefox(): boolean {
    return this.browser.name === 'Firefox'
  }

  get isEdge():boolean{
    return this.browser.name.toLowerCase() === 'edge'
  }

  get isOldEdge():boolean{
    return this.browser.name.toLowerCase() === 'edge'
        && this.browserVersion < 19
  }

  get isNewEdge():boolean{
    return this.browser.name.toLowerCase() === 'edge'
        && this.browserVersion > 77
  }

  get isSafari(): boolean {
    return this.browser.name === 'Safari'
  }

  get isMobileSafari(): boolean {
    return this.browser.name === 'Mobile Safari'
  }

  get isChrome(): boolean {
    return this.browser.name === 'Chrome'
  }

  get isIE (): boolean {
    return this.browser.name === 'IE'
  }

  get isMoxoAgent(): boolean {
    return /MoxoAgent/.test(navigator.userAgent)
  }

  get isTAEnv(): boolean {
    return /Cypress/.test(navigator.userAgent)
  }
  getOrigin(): string {
    let origin = location.origin;
    if (origin) {
      return origin
    } else {
      origin = location.protocol + "//" + location.hostname;
      if (location.port) {
        origin += ":" + location.port;
      }
      return origin;
    }
  }

  isSameDomain(backUrl): boolean{
    let currentHost = location.host
    let aHref = document.createElement('a')
    aHref.href = backUrl
    return currentHost === aHref.hostname
  }

  base64ToBlob (dataUrl) {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

}

const BrowserUtils = new Browser()
export {
  BrowserUtils
}
