import {ClientResponse} from 'isdk/src/proto/generated/ClientResponse'
import {BoardFormatter} from '../../utils/board'
import {Defines, MxAjax, MxISDK, MxUser} from 'isdk'
import {MxBoard} from 'isdk/src/api/mxBoard'
import {FeedUtils} from '../../utils/feed'
import {ObjectUtils} from '@controller/utils'
import {UserFormatter} from '@controller/utils/user'

import {CBoard} from '../../defines/CBoard'
import {ClassLogger, transformError} from '../../common/decorators'
import {BoardComment} from 'isdk/src/proto/generated/BoardComment'
import {RichTextFormat} from 'isdk/src/proto/generated/RichTextFormat'
import {
    BoardAccessType,
    BoardSignature,
    BoardUserStatus,
    MxSPath,
    User,
    UserBoard,
    UserIdentity,
    UserType,
    ClientResponseCode,
    ClientResponseDetailCode, MxViewBoardAsParam, MxBaseObject, MxInviteMemberOption
} from 'isdk/src/api/defines'
import {NotificationLevel} from 'isdk/src/proto/generated/NotificationLevel'
import {UserController} from '@controller/user/src/userController'
import {SignatureController} from '@controller/signature/src/signatureController'
import {BoardSignatureStatus} from 'isdk/src/proto/generated/BoardSignatureStatus'
import cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniqBy'
import some from 'lodash/some'
import {isAnonymousContext} from "@controller/utils/anon";

let binderInstancePool: Map<string, BinderController> = new Map<string, BinderController>()

let _binderControllerInstance: BinderController

interface ICreateCommentResponse {
  boardId: string
}

@ClassLogger()
export class BinderController {
  private binderInfoSubscriber: Defines.MxSubscription
  private myTurnSignatureSubscriber: Defines.MxSubscription
  private binderId: string
  private binderInstance: MxBoard

  protected constructor (binderId: string) {
    this.binderId = binderId
  }

  /**
   * @param binderId - binder id
   *
   * @return The unique instance of binder controller
   */
  static getInstance (binderId: string): BinderController {
    if (!binderInstancePool[binderId]) {
      binderInstancePool[binderId] = new BinderController(binderId)
    }
    return binderInstancePool[binderId]
  }

  /**
   * clean subscriber and some other parts when init a new controller instance
   * make sure all actions are synchronized
   */
  destroy (): void {
    this.unsubscribeBinderBasicInfo()
    this.unsubscribeMyTurnSignatures()
    if (this.binderId) {
      const binderId: string = this.binderId
      const MxUser: MxUser = MxISDK.getCurrentUser()
      if (MxUser) {
        if (this.binderInstance && this.binderInstance.isMocked) {
          MxUser.deleteMockBoard(this.binderId)
        } else {
          MxUser.unloadBoard(binderId)
        }
        this.binderId = ''
      }

      if (binderInstancePool[binderId]) {
        binderInstancePool[binderId] = null
      }
    }

    this.binderInstance = null
  }

  /**
   * @return Binder id
   */
  getBinderId (): string {
    return this.binderId
  }

  /**
   * @param onSuccessCallback - callback function of when subscriber in controller receives new data
   * @param onErrorCallback - callback fucntion of when subscriber in controller receives error info
   *
   * @dependency - isdk.subscriber.boardBasicInfo
   *
   * @return null
   */
  subscribeBinderBasicInfo (onSuccessCallback: Function, onErrorCallback: Function): void {
    if (this.binderInstance && !this.binderInfoSubscriber) {
      this.binderInfoSubscriber = this.binderInstance.subscribeBasicInfo(
        (binderinfo: Defines.Board) => {
          if (!binderinfo.is_deleted) {
            if(isAnonymousContext()){
                onSuccessCallback(BoardFormatter.transformBoardBasicInfo(binderinfo, this.binderInstance.isMocked))
            } else {
                const MxUser: MxUser = MxISDK.getCurrentUser()
                const existingUsers: Array<Defines.BoardUser> = binderinfo.users.filter(user => !user.is_deleted)
                const existingUserIds: Array<string> = existingUsers.map(user => user.user ? user.user.id : '')
                if ( existingUserIds.indexOf(MxUser.basicInfo.id) === -1 ) {
                    onErrorCallback({
                        isNotAllowToAccessBinder: true
                    })
                } else {
                    onSuccessCallback(BoardFormatter.transformBoardBasicInfo(binderinfo, this.binderInstance.isMocked))
                }
            }
          } else {
            onErrorCallback({
              isBinderDeleted: true
            })
          }
        }
      )
    }
  }

  /**
   * @return null
   */
  unsubscribeBinderBasicInfo (): void {
    if (this.binderInfoSubscriber) {
      this.binderInfoSubscriber.unsubscribe()
      this.binderInfoSubscriber = null
    }
  }

  subscribeMyTurnSignatures (onSuccessCallback: Function, onErrorCallback: Function): void {
    if (this.binderInstance && !this.myTurnSignatureSubscriber) {
      this.myTurnSignatureSubscriber = this.binderInstance.subscribeSignatures(
        signatures => {
          const mySignatures = []
          for (let signature of signatures) {
            if (signature.status === BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS) {
              let _signatureController_ = new SignatureController(this.binderInstance.basicInfo.id, signature)
              if (_signatureController_.isWaitMySign()) {
                mySignatures.push(signature)
              } else {
                const signees = _signatureController_.signees
                const mySignee = signees.find(signee => signee.isMySelf)
                if (mySignee && mySignee.isSigned) {
                  mySignatures.push({
                    sequence: signature.sequence,
                    is_deleted: true
                  })
                }
              }
              _signatureController_ = null
            } else if (signature.status === BoardSignatureStatus.SIGNATURE_STATUS_DECLINED) {
              mySignatures.push({
                sequence: signature.sequence,
                is_deleted: true
              })
            } else if (signature.is_deleted) {
              mySignatures.push(signature)
            }
          }
          onSuccessCallback(mySignatures)
        }
      )
    }
  }

  unsubscribeMyTurnSignatures (): void {
    if (this.myTurnSignatureSubscriber) {
      this.myTurnSignatureSubscriber.unsubscribe()
      this.myTurnSignatureSubscriber = null
    }
  }

  toBinderUserBoard (myUserBoard: UserBoard) {
    const result: any = {}
    if (myUserBoard) {
      if (!myUserBoard.is_deleted) {
        const notificationLevel = myUserBoard.push_notification_level
        switch (notificationLevel) {
          case NotificationLevel.NOTIFICATION_LEVEL_ALL:
            result.isNotificationLevelAll = true
            break
          case NotificationLevel.NOTIFICATION_LEVEL_RELATED:
            result.isNotificationLevelRelated = true
            break
          case NotificationLevel.NOTIFICATION_LEVEL_NOTHING:
            result.isNotificationLevelNothing = true
            break
        }
      } else {
        result.is_deleted = true
      }
    }
    return result
  }

 /**
  * @return signature file list
  */
  @transformError()
  getOnGoingSignatureLists (): Promise<Array<object>> {
    return new Promise((resolve, reject) => {
      this.binderInstance.listSignatures()
        .then(board => {
          const mySignatures = []
          const allSignatuers = board.signatures
          if (allSignatuers) {
            allSignatuers.forEach(signature => {
              if (signature.status === BoardSignatureStatus.SIGNATURE_STATUS_IN_PROGRESS) {
                let _signatureController_ = new SignatureController(board.id, signature)
                if (_signatureController_.isWaitMySign()) {
                  mySignatures.push(signature)
                }
                _signatureController_ = null
              }
            })
          }
          resolve(mySignatures)
        })
        .catch(reject)
    })
  }

  /**
   * @return Promise<Board>
   */
  @transformError()
  async getBinderBasicInfo (noFeed?: boolean, viewUserId?: string): Promise<CBoard> {
    const MxUser: MxUser = MxISDK.getCurrentUser();
    let viewAs: MxViewBoardAsParam;
    if (viewUserId) {
        if (isAnonymousContext()) {
          viewAs = {
              viewToken: viewUserId
          }
        } else {
          viewAs = {
              userId: viewUserId
          }
        }
    }
   
      try {
      const MxBoard = await MxUser.loadBoard(this.binderId, viewAs)
      const basicInfo = MxBoard.basicInfo
      if (!basicInfo.is_deleted) {
        const isMockBinder = MxBoard.isMocked
        this.binderInstance = MxBoard
        if (basicInfo.users && !isMockBinder) {
          const myId = ObjectUtils.getByPath(MxUser, 'basicInfo.id')
          const myBinderUser = basicInfo.users.filter(binderUser => !binderUser.is_deleted && ObjectUtils.getByPath(binderUser, 'user.id') === myId)
          if (myBinderUser.length > 0) {
            const isNotJoined = myBinderUser[0].status === BoardUserStatus.BOARD_INVITED
            if (isNotJoined) {
              await MxUser.joinBoard(basicInfo.id, noFeed)
            }
          }
        }
        return BoardFormatter.transformBoardBasicInfo(basicInfo, isMockBinder, false, !!viewUserId)
      } else {
        throw {
          code: ClientResponseCode.RESPONSE_ERROR_NOT_FOUND
        }
      }
    } catch (e) {
      throw e
    }
  }

  /**
   * @return UserBoard
   */
  getBinderUserBoardInfo (needOrignal?: boolean): UserBoard {
    const MxUser: MxUser = MxISDK.getCurrentUser()
		const userBoard: UserBoard = MxUser.getUserBoardByBoardId(this.binderId)
		if (!needOrignal) {
		  return this.toBinderUserBoard(userBoard)
		} else {
			return userBoard
		}
  }

  /**
   * need to have double check this api
   * @param callback - after temporary board is created to be an real binder will excute this callback function
   *
   * @dependency - isdk.board.createTemporaryBoardProxy
   *
   * @return Promise<ClientResponse>
   */
  static createTemporaryBoard (callback: Function): Promise<ClientResponse> {
    return new Promise((resolve, reject) => {
    })
  }

  inviteBinderMember (users: UserIdentity[], message?: string, addDirectly?: boolean, suppressFeed?: boolean, accessType?: BoardAccessType) {
    let option: MxInviteMemberOption = {
      addDirectly: addDirectly,
      noFeed: suppressFeed,
      accessType: accessType,
      message: message
  };
  
    return this.binderInstance.inviteMember(users, option)
  }

  /**
   * @param sequence - The sequence of tobe delete user
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  deleteUser (sequence: number, suppressFeed?: boolean): Promise<Defines.Board> {
    return this.binderInstance.removeMember(sequence, suppressFeed)
  }

  /**
   *
   * @dependency - isdk.board.deleteBoard
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  deleteBinder (): Promise<void> {
    const MxUser: MxUser = MxISDK.getCurrentUser()
    return MxUser.deleteBoard(this.binderId)
  }

  /**
   * @param content - updated info
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  updateBinderBasicInfo (content: Defines.Board): Promise<Defines.Board> {
    return this.binderInstance.updateBasicInfo(content)
  }

  /**
   * @return Promise<ClientResponse>
   */
  @transformError()
  leaveBinder (): Promise<Defines.Board> {
    const MxUser: MxUser = MxISDK.getCurrentUser()
    return MxUser.leaveBoard(this.binderId)
  }

  transferOwner (sequence): Promise<Defines.Board> {
      return this.binderInstance.setOwner(sequence)
  }

  /**
   * @param type - specific user board update type like USER_REQUEST_UPDATE_USER_BOARD
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  updateAccessTime (keepUnreadFeedTime = false): Promise<Defines.User> {
    const MxUser: MxUser = MxISDK.getCurrentUser()
    if (MxUser && this.binderId) {
      return MxUser.updateUserBoardAccessTime(this.binderId, keepUnreadFeedTime)
    } else {
      return Promise.resolve({})
    }
  }

  /**
   *
   * @param binderId - current binder id
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  updateTypeIndication (): Promise<Defines.Board> {
    return this.binderInstance.updateTypeIndication()
  }

  /**
   * need double check this api
   * @param text - Normal comment text
   * @param id - if not specified will use default
   * @param richText - bbcode or other rich Text
   * @param richFormat - rich text's format
   *
   * @return Promise<ClientResponse>
  */
  @transformError()
  createComment (id: string, text: string, richText?: string, richFormat?: RichTextFormat): Promise<ICreateCommentResponse> {
    return new Promise((resolve, reject) => {
      const binderComment: BoardComment = {
        client_uuid: id,
        text: text
      }

      if (richText) {
        binderComment.rich_text = richText
      }

      if (richFormat) {
        binderComment.rich_text_format = richFormat
      }

      this.binderInstance.createComment(binderComment)
        .then((board:Defines.Board) => {
          const boardId = board.id
          resolve({
            boardId
          })
        })
        .catch(reject)
    })
  }

  /**
   *
   * @param sequence - comment feed sequence
   *
   * @return Promise<ClientResponse>
   */
  @transformError()
  deleteComment (sequence: number, noFeed?: boolean): Promise<Defines.Board> {
    return this.binderInstance.deleteComment(sequence, noFeed)
  }

  /**
   *
   * @param level - Specific notification level
   *
   * @return server response
  */
  @transformError()
  updateNotificationLevel (level: NotificationLevel): Promise<User> {
    return new Promise((resolve, reject) => {
      this.binderInstance.updateNotificationLevel(level)
        .then(user => {
          if (user.boards) {
            const userBoard = user.boards[0]
            resolve(this.toBinderUserBoard(userBoard))
          } else {
            resolve()
          }
        })
        .catch(reject)
    })
  }

  /**
   *
   * @param boardIds - array contains all meet board id
   *
   * @return server response
   */
  @transformError()
  loadMeetBoards (boardIds: Array<string>) {
    return new Promise((resolve, reject) => {
      const requestBoardIds: Array<string> = []
      const fectchedBoards: Array<Defines.UserBoard> = []
      const MxUser: MxUser = MxISDK.getCurrentUser()
      for (let boardId of boardIds) {
        const cacheMeetBoard = MxUser.getUserBoardByBoardId(boardId)
        if (cacheMeetBoard) {
          fectchedBoards.push(cacheMeetBoard)
        } else {
          requestBoardIds.push(boardId)
        }
      }
      if (requestBoardIds.length > 0) {
        MxUser.readUserBoards(requestBoardIds)
          .then((user:Defines.User) => {
            const requestMeetBoards = user.boards
            resolve(fectchedBoards.concat(requestMeetBoards))
          })
      } else {
        resolve(fectchedBoards)
      }
    })
  }

  /**
   *
   * @param SPath - File SPath
   * @param name - Changed file name
   *
   * @return server response
   */
  @transformError()
  renameFile (SPath: MxSPath, name: string) {
    return this.binderInstance.renameFile(SPath, name)
  }

  /**
   *
   * @param files - Array of file SPaths
   *
   * @return server response
  */
  @transformError()
  deleteFiles (files: MxSPath[]) {
    return this.binderInstance.deleteFiles(files)
  }

  /**
   *
   * @param sequence - signature file sequence
   * @param content - updated signature content
   *
   * @return server response
   */
  @transformError()
  updateSignatureFile (sequence: number, content: BoardSignature) {
    return this.binderInstance.updateSignature(sequence, content)
  }

  /**
   *
   * @param sequence - signature file sequence
   *
   * @return server response
   */
  @transformError()
  deleteSignatureFile (sequence: number, noFeed?:boolean) {
    return this.binderInstance.deleteSignature(sequence, noFeed)
  }

  static searchBoardContent (boardId: string, query: string): Promise<any> {
    let userCtrl = UserController.getInstance()

    return userCtrl.searchUserBoard(query, boardId).then((board:Defines.Board) => {
      //There are some pages be searched if the page have content be indexed
      let pages = ObjectUtils.getByPath(board, 'pages')
      let page_groups = ObjectUtils.getByPath(board, 'page_groups')
      let files = []
      let folder
      let currentFolderPath = []
      if (page_groups && page_groups.length) {
        files = page_groups
      }
      if(pages && pages.length) {
        files = files.concat(pages.map(page => {
          return {
            sequence:page.sequence,
            client_uuid: page.page_group,
            name:page.name || page.original_resource_name,
            type:'page'
          }
        }))
      }
      if (board.folders) {
        folder = board.folders[0]
        while (folder) {
          let file = ObjectUtils.getByPath(folder, 'files.0')
          currentFolderPath.push(folder.sequence)
          if (file) {
            file.folderOrder = currentFolderPath
            files.push(file)
          }
          folder = folder.folders && folder.folders[0]
        }
      }
      let feeds = []; let todos = board.todos || []
      feeds = FeedUtils.transformIncompleteFeedsInfo(board)
      if (feeds) {
        feeds = FeedUtils.transformFeeds(feeds, board)
      }
      return {
        feeds: feeds,
        todos: todos,
        files: files
      }
    })
  }

  /**
   *
   * @param sequence - signature file sequence
   *
   * @return server response
   */
  @transformError()
  createWhiteboard (name: string, width: number, height: number, folderPath?: MxSPath) {
    return new Promise((resolve, reject) => {
      this
        .binderInstance
        .createWhiteboard(name, width, height, folderPath)
        .then((board:Defines.Board) => {
          if (folderPath) {
            resolve({
              pageSequence: ObjectUtils.getByPath(board, 'pages.0.sequence')
            })
          } else {
            resolve({
              fileSequence: ObjectUtils.getByPath(board, 'page_groups.0.sequence'),
              boardId: ObjectUtils.getByPath(board, 'id')
            })
          }
        })
        .catch(reject)
    })
  }

  /**
   *
   * @param url - target upload url
   * @param data - request data
   *
   * @return server response
   */
  createNote (url: string, data: any) {
    return new Promise((resolve, reject) => {
      MxAjax
        .post(url, data, {
          contentType: 'text/html'
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          const errorInfo = {}
          if (err.detail.detail_code === ClientResponseDetailCode.ERROR_FILE_TYPE_NOT_SUPPORTED) {
            reject({
              isFileTypeNotSupported: true
            })
          }
          reject(errorInfo)
        })
    })
  }

  @transformError()
  lockPage (sequence: number) {
    return this.binderInstance.lockPage(sequence)
  }

  @transformError()
    unlockPage (sequence: number) {
     return this.binderInstance.unlockPage(sequence)
  }

  @transformError()
  getSignatureDetail (sequence: number) {
    return new Promise((resolve, reject) => {
      this.binderInstance.readSignatureDetail(sequence)
        .then(board => {
          const responseBoard: Defines.Board = board
          resolve({
            binderId: responseBoard.id,
            signature: responseBoard.signatures[0]
          })
        })
        .catch(reject)
    })
  }

  makeUploadUrl (name:string, folderSpath:string, resourceId?:string) {
    // MxISDK.getCurrentUser().getCacheBoard()
    return this.binderInstance.makeUploadFileUrl(name, folderSpath,resourceId)
  }

  /**
   *
   * @param name - todo name
   * @param fileSpathArray - Array of file spath
   */
  @transformError()
  async createTodoWithAttachment (name: string, fileSpathArray: MxSPath[]) {
    try {
      const boardResponse = await this.binderInstance.createTodo({
        name: name
      })
      const todoSequence = ObjectUtils.getByPath(boardResponse, 'todos.0.sequence')
      await this.binderInstance.addTodoAttachment(todoSequence, fileSpathArray)
    } catch (e) {
      throw e
    }
  }

  /**
   *
   * @param fileSpath - file spath
   *
   * @return server response
   */
  @transformError()
  readFileDetail (fileSpath: string) {
    return this.binderInstance.readFileDetail(fileSpath)
  }

  @transformError()
  async copyCommentToBinder (binderId: string, comment: BoardComment) {
    try {
      if (binderId === this.binderId) {
        await this.binderInstance.createComment(comment)
      } else {
        const MxUser: MxUser = MxISDK.getCurrentUser()
        const binderInstance = await MxUser.loadBoard(binderId)
        await binderInstance.createComment(comment)
        if (MxUser) {
          MxUser.unloadBoard(binderId)
        }
      }
    } catch (e) {
      throw e
    }
	}

	@transformError()
	syncBoard (): Promise<CBoard> {
    return this.binderInstance.sync().then((binder: Defines.Board) => {
      return BoardFormatter.transformBoardBasicInfo(binder, false)
    })

	}

	@transformError()
	getUserActivityLists () {
		return new Promise((resolve, reject) => {
			this.binderInstance.readUserActivities()
			  .then(board => {
					resolve(board.user_activities)
				})
				.catch(reject)
		})
	}
  static postBotCallback(boardId: string, sequence: number, data: any) {
    return MxAjax.post(`/board/${boardId}/callback/${sequence}`,data)
  }

    static getMemberList(mxBoard: CBoard) {
        const binderObj = mxBoard;
        let actualBinderUsers = [];
        if (binderObj.boardUsers) {
            let removedUsers = []
            const currentUser = MxISDK.getCurrentUser();
            const isInternalUser = currentUser.basicInfo.type !== Defines.UserType.USER_TYPE_LOCAL
            const validUsers = []
            let hasValidSocialClient = false
            let lastValidSocialClient = null
            for (let binderUser of binderObj.boardUsers) {
                if (binderObj.isSocial && binderUser.isClient) {
                    if (!binderUser.is_deleted) {
                        hasValidSocialClient = true
                        validUsers.push(binderUser)
                    } else {
                        lastValidSocialClient = binderUser
                    }
                } else if (binderUser.is_deleted) {
                    removedUsers.push(binderUser)
                } else if (!binderUser.isBot) {
                    validUsers.push(binderUser)
                }
            }

            if (binderObj.isSocial && !hasValidSocialClient && lastValidSocialClient) {
                validUsers.push(lastValidSocialClient)
            }

            actualBinderUsers = cloneDeep(validUsers)
            if (!isInternalUser && removedUsers.length > 0) {
                removedUsers = _uniq(removedUsers, 'id')
                removedUsers.forEach(removed => {
                    let isThisUserBack = some(actualBinderUsers, normal => normal.primaryKey === removed.primaryKey)
                    if (!isThisUserBack && removed.isOwner) {
                        actualBinderUsers.push(removed)
                    }
                })
            }
        }
        return actualBinderUsers
    }

  @transformError()
  getBinderUserPresenceStatus (userIDs: string[]) {
      if(isAnonymousContext())
          return Promise.resolve([])
    let users = userIDs.map((userID) => {
      return {
        id: userID
      }
    });
    return MxISDK.getCurrentUser().queryUserPresence(users)
      .then((presences: Defines.UserPresence[]) => {
        let binderPresences = presences.filter((userPresence:Defines.UserPresence)=>{
          return userIDs.includes(userPresence.id)
        }).map(presence => {
          return {
            ...presence,
            id: presence.id,
            status: UserFormatter.getUserStatus(presence)
          }
        })
        return binderPresences
      })
  }

  @transformError()
  markFeedAsUnRead(feedSeq: number) {
      return this.binderInstance.markFeedAsUnRead(feedSeq)
  }

  //@transformError() //TODO refactor the code to rely on CServerError to implement error message
  uploadRemoteFile (url: string, name: string, token?:string, toFolderPath?:MxSPath, contentType?:string, id?:string) {
      return this.binderInstance.uploadFromRemoteUrl(url, name, token, toFolderPath, contentType, id)
  }

  getBoxAccessToken (payload: string) {
      return this.binderInstance.getBoxAccessToken(payload)
  }

  getFeedBaseObject (sequence: number) {
    return this.binderInstance.getFeedBaseObject(sequence)
  }

  getFileBaseObject (filePath: MxSPath) {
    return this.binderInstance.getFileBaseObject(filePath)  
  }

  getPageBaseObject (sequence: number) {
    return this.binderInstance.getPageBaseObject(sequence)  
  }

  getTodoBaseObject (todoSequence: number) {
    return this.binderInstance.getTodoBaseObject(todoSequence)
  }

  readThread (baseObject: MxBaseObject) {
    return this.binderInstance.readThread(baseObject)
      .then(tBoard => {
        if (baseObject.type === 'PAGE') {
          return tBoard.pages ? tBoard.pages[0].is_deleted : true
        } else if (baseObject.type === 'FILE') {
          if (!tBoard.page_groups && !tBoard.folders) {
            return true
          } else {
            return false
          }
        } else if (baseObject.type === 'TODO') {
          return tBoard.todos ? tBoard.todos[0].is_deleted : true
        } else if (baseObject.type === 'SIGNATURE') {
          return tBoard.signatures ? tBoard.signatures[0].is_deleted : true
        } else {
          return false
        }
      })
  }
  
  static checkBoardTransaction (payload) {
    const MxUser: MxUser = MxISDK.getCurrentUser()
    return new Promise((resolve, reject) => {
      MxUser.loadBoard(payload.binderId)
        .then(boardIns => {
          // so far, donot support V3 transaction
          if (boardIns.board && boardIns.board.is_transaction) {
            reject({name: 'MEPUnknownError', message: 'V3 transaction not support'})
          } else {
            boardIns.readTransactionDetail(parseInt(payload.transactionId))
              .then(transaction => {
                resolve(transaction)
                MxUser.unloadBoard(payload.binderId)
              })
              .catch(err => {
                reject(err)
                MxUser.unloadBoard(payload.binderId)
              })
          }
        })
        .catch(reject)
    })
  }
}
