import { BoardPage, BoardSignature, BoardPageGroup, Board, BoardComment } from "isdk/src/api/defines";
import { ArrayUtils } from '../../commonUtils/array'
import { ObjectUtils } from '../../commonUtils/object'
import { BoardFormatter } from './board'
import { BoardPageType } from "isdk/src/proto/generated/BoardPageType";
import { BoardResource } from "isdk/src/proto/generated/BoardResource";
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import { UserFormatter } from "@controller/utils/user"
import { CBoardFile } from "@controller/defines/CBoardFile"
import { MxISDK } from "isdk";
import { SignatureController } from "@controller/signature/src/signatureController";

export class FileFormatter {
  static getSignaturePageSource(page: BoardPage, file: BoardSignature|BoardPageGroup, boardId: string, isAudit: boolean = false) {
    let signatureSequence = file.sequence
    let background = page && page.background
    let prefix = isAudit ? `${MxISDK.getContextPath()}/board/audit` : `${MxISDK.getContextPath()}/board`
    if (!background) {
      return ''
    }
    let sequence = page.sequence
    return `${prefix}/${boardId}/signature/${signatureSequence}/${sequence}/${background}`
  }

  static getPageThumbnail (page: BoardPage, board: Board, isAudit: boolean = false) {
    let auditPath = isAudit ? 'audit/' : ''
    if (!page) {
      return ''
    }
    let thumbnail = page.thumbnail
    if (thumbnail && typeof thumbnail !== 'number') {
      //avoid duplicate call
      return thumbnail
    }
    if (page.page_type === BoardPageType.PAGE_TYPE_URL) {
      let background = page.background
      if (background && typeof (background) === 'number') {
        thumbnail = background
      }
    }
    // handle gif thumbnail case
    if (page.original_resource_sequence) {
      let resource = ArrayUtils.getFromArray(board.resources, page.original_resource_sequence)
      if (resource && resource.content_type === 'image/gif') {
        return `${MxISDK.getContextPath()}/board/${auditPath}${board.id}/${page.original_resource_sequence}`
      }
    }
    if (thumbnail) {
      return `${MxISDK.getContextPath()}/board/${auditPath}${board.id}/${page.sequence}/${thumbnail}`
    }
    return ''
  }

  static getSignatureThumbnail (file: any, board: Board, isAudit: boolean = false) {
    if (!file.pages) {
      return ''
    }
    let auditPath = isAudit ? 'audit/' : ''
    let page = file.pages[0]
    if (page.thumbnail) {
      return `${MxISDK.getContextPath()}/board/${auditPath}${board.id}/signature/${file.sequence}/${page.sequence}/${page.thumbnail}`
    } else {
      return ''
    }
  }

  static getPageSource(page: BoardPage, boardId: string, isAudit?: boolean) {
    let auditPath = isAudit ? 'audit/' : ''
    if (!page) {
      return ''
    }
    let type = page.page_type
    let source
    if (type === BoardPageType.PAGE_TYPE_WHITEBOARD) {
      source = '' //defaults.source;
    } else if (type === BoardPageType.PAGE_TYPE_NOT_SUPPORTED) {
      source = '' //defaults.source;
    } else if (/WEB|URL|VIDEO|AUDIO|NOTE/.test(type) || (page.vector && /IMAGE/.test(type))) {
      //for svg server has fix a bug, it's cause svg should not use background
      //so if type is IMAGE and has vector we should use vector
      source = `${MxISDK.getContextPath()}/board/${auditPath}${boardId}/${page.sequence}/${page.vector}`
    } else {
      source = `${MxISDK.getContextPath()}/board/${auditPath}${boardId}/${page.sequence}/${page.background}`
    }
    return source
  }

  static makeDownloadResourceUrl(boardId: string, resourceSequence: number, name: string, isAudit?: boolean) {
    const auditPath = isAudit ? 'audit/' : ''
    return `${MxISDK.getContextPath()}/board/${auditPath}${boardId}/${resourceSequence}?d=${encodeURIComponent(name)}`
  }

  static processEditorActor(page: BoardPage, board: Board, appendObject: any) {
    if (page && !_isEmpty(page.editor_actor)) {
      appendObject.editor = BoardFormatter.transformBoardActor(page.editor_actor.user, board)
      appendObject.isLocked =  (Date.now() - page.editor_time) < 5 * 60 * 1000
    }
  }

  static getFileSize (bytes: number) {
    if (bytes) {
      const sizeType = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']
      const e = Math.floor(Math.log(bytes) / Math.log(1024))
      return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + sizeType[e]
    } else {
      return '0 B'
    }
  }

  static getFileName(file: BoardPageGroup,page: BoardPage,resource?: BoardResource) {
    return file && file.name || (resource && resource.name) || page && page.name || ''
  }

  static getAllFilesInBoard(board: any): any {
    let result: any[] = []
    let file = ObjectUtils.getByPath(board, 'page_groups.0')

    const eachFolder = (root: any, callback: Function, paths: string[] = []) => {
      let folder = ObjectUtils.getByPath(root, 'folders.0')
      if (folder) {
        paths.push(`folders[sequence=${folder.sequence}]`)
        let file = ObjectUtils.getByPath(folder, 'files.0')
        if (file) {
          file.SPath = paths.concat(`files[sequence=${file.sequence}]`).join('.')
          file.folderSpath = paths.join('.')
          file.folderSequence = folder.sequence
          callback(file)
        }
        if (folder.folders) {
          eachFolder(folder, callback, paths)
        }
      }
    }

    if (file) {
      file.SPath = `page_groups[sequence=${file.sequence}]`
      result.push(file)
    }
    if (board.folders) {
      eachFolder(board, (file: any) =>{
        result.push(file)
      })
    }
    return result
  }

  static getTypeFormName (name: string): string {
    if (!name) {
      return ''
    }
    let tmp = name.split('.')
    if (tmp.length > 1) {
      return tmp.pop().toUpperCase()
    }
    return ''
  }

  static getFileType (resource: BoardResource, page?: BoardPage) {
    const typeMap = {
      WEB: 'NOTE',
      NOTE: 'CLIP',
      GEO: 'LOCATION'
    }
    if (resource) {
      let type = this.getTypeFormName(resource.name)
      if (!type && resource.content_type) {
        type = resource.content_type.split('/').pop()
        type = type.toUpperCase()
      }
      if (type) {
        return type
      }
    }
    if (page) {
      let type = ''
      let pageType = ''
      if (page.page_type) {
        pageType = page.page_type.split('_').pop()
        pageType = typeMap[pageType] || pageType
      }
      if ([BoardPageType.PAGE_TYPE_WHITEBOARD, BoardPageType.PAGE_TYPE_WEB, BoardPageType.PAGE_TYPE_NOTE, BoardPageType.PAGE_TYPE_GEO].indexOf(page.page_type) >= 0) {
        type = pageType
      } else {
        type = this.getTypeFormName(page.original_resource_name || page.name)
        type = (type || pageType).toUpperCase()
      }
      
      if (type) {
        return type
      }
    }
    return 'UNKNOWN'
  }

  static transformPositionComments(comments: BoardComment[], board: Board) {
    return comments.map((comment:BoardComment) => {
      if (comment.is_deleted) {
        return comment
      }
      const creator = comment.creator
      if (creator && creator.user) {
        comment.creator = BoardFormatter.transformBoardActor(creator.user, board)
      }
      return comment
    })
  }

  static getFileCreator(file: any, resource: any, page: any, board: any) {
    let creator = file.creator || resource && resource.creator || page && page.creator
    return this.transformCreator(creator, board)
  }

  static transformCreator(creator:any,board:any, auditBoard?:any):any {
    let boardUser;
    if(!creator){
      return null
    }
    let user = creator.user;
    if (user) {
      boardUser = BoardFormatter.transformBoardActor(user, board)
      if (boardUser) {
        let result = Object.assign({},boardUser)
        result.displayName = result.name || UserFormatter.getUserName(boardUser.user)
        return result
      } else if (user.groups) {
        let group = user.groups[0]
        user.displayName = UserFormatter.getUserName(user)
        user.avatar = ['/group', group.id, user.id, 'picture'].join('/')
      }

      return creator
    } else if (creator.group){
      return creator
    }
  }

  static getFileResource (file:any, board:any) {
    if (file) {
      let originalSequence = file.original || file.original_resource_sequence
      if (originalSequence) {
        let resources = board.resources
        if (resources && Array.isArray(resources)) {
          let resource = ArrayUtils.getFromArray(resources, originalSequence)
          if (resource) {
            return resource
          }
        }
      }
    }
    return null
  }

  static handleEmpty (result:any) {
    if (!result) {
      return {
        is_original_deleted: true,
        is_deleted: true
      }
    }
    return result
  }

  static getFileByReference (reference:any, obj:any):any {
    let data = reference.board
    let result
    if (!data) {
      return {
        is_original_deleted: true,
        is_deleted: true
      }
    }
    let referenc_link = ObjectUtils.getByPath(data, 'reference_links.0.sequence')
    if (referenc_link) {
      reference = ArrayUtils.getFromArray(obj.reference_links, referenc_link)
      if (reference) {
        return this.getFileByReference(reference, obj)
      }
    }
    let page_group = ObjectUtils.getByPath(data, 'page_groups.0.sequence')
    if (page_group) {
      result = ArrayUtils.getFromArray(obj.page_groups, page_group)
      result.SPath = `page_groups[sequence=${page_group}]`
      return this.handleEmpty(result)
    }
    // some old data there only has page in references we need find the file by the page
    let page = ObjectUtils.getByPath(data, 'pages.0.sequence')
    if (page && obj.pages) {
      page = ArrayUtils.getFromArray(obj.pages, page)
      if (page.page_group) {
        result = ArrayUtils.getFromArray(obj.page_groups, page.page_group, 'client_uuid')
        if (result) {
          result.SPath = `page_groups[sequence=${result.sequence}]`
        }
      }
      return this.handleEmpty(result)
    }

    let file = ObjectUtils.getByPath(data, 'files.0.sequence')
    if (file) {
      result = ArrayUtils.getFromArray(obj.files, file)
      result.SPath = `files[sequence=${file}]`
      return result
    }
    if (data.folders) {
      let path = []
      while (data.folders) {
        data = data.folders[0]
        obj = ArrayUtils.getFromArray(obj.folders, data.sequence)
        if (!obj) {
          break
        }
        path.push(`folders[sequence=${data.sequence}]`)
      }
      if (obj && obj.files) {
        let file = ObjectUtils.getByPath(data, 'files.0.sequence')
        result = ArrayUtils.getFromArray(obj.files, file)
        path.push(`files[sequence=${result.sequence}]`)
        result.SPath = path.join('.')
        return result
      }
    }
    if (data.pages) {

    }
    return null
  }

  static getFileInFolder (root: any, path:string[] = [], isFolderRemoved:boolean = false): any {
    if (!root) {
      return null
    }
    if (Array.isArray(root)) {
      root = root[0]
      path.push(`[sequence=${root.sequence}]`)
    }
    // should check the folder at first
    let folder = ObjectUtils.getByPath(root, 'folders.0')
    let files = ObjectUtils.getByPath(root, 'page_groups')
    let file = null
    if (files) {
      if (files.length == 2) {
        // has moved and now in the root
        file = files.filter((f: any) => !f.is_deleted)[0]
      } else {
        file = files[0]
      }
    }
    if (folder) {
      if (file) {
        if (file.is_deleted) {
          // move from root to folder
          path.push(`folders[sequence=${folder.sequence}]`)
          let folderFile = this.getFileInFolder(folder, path, isFolderRemoved || folder.is_deleted)
          folderFile.isMoved = true
          file = folderFile
        } else {
          // move from folder to root
          path.push(file.SPath)
          file.isMoved = true
        }
      } else {
        path.push(`folders[sequence=${folder.sequence}]`)
        file = this.getFileInFolder(folder, path, isFolderRemoved || folder.is_deleted)
      }
      if (path.length > 1) {
        let folderPath = path[path.length - 2]
        let info:any = ObjectUtils.parseSPath(folderPath)
        file.folderSequence = parseInt(info.attrVal, 10)
      }
      return file
    }

    if (file) {
      path.push(`page_groups[sequence=${file.sequence}]`)
      return file
    }
    file = ObjectUtils.getByPath(root, 'files.0')
    if (file) {
      path.push(`files[sequence=${file.sequence}]`)
      // handle folder remove case , server will not set is_delete to file in this case
      if (isFolderRemoved) {
        file.is_deleted = isFolderRemoved
      }
      return file
    }
  }

  static getGeoLocation (page:any) {
    let result = {}
    let tags = page.tags
    if (tags) {
      tags.forEach((tag:any) => {
        if (tag.name === 'geo_location') {
          try {
            result = JSON.parse(tag.string_value)
          } catch (e) {
            console.log('got error geo location data', tag.string_value)
          }
        }
      })
    }
    return result
  }

  static getFileById(root:any,uuid:string,spath:string[]=[]):any {
    let file = ArrayUtils.getFromArray(root.page_groups,uuid,'client_uuid');
    if(file){
      spath.push(`page_groups[sequence=${file.sequence}]`)
      file.SPath = spath.join('.');
      return file;
    }
    if(root.files){
      file = ArrayUtils.getFromArray(root.files,uuid,'client_uuid');
      if(file){
        spath.push(`files[sequence=${file.sequence}]`)
        file.SPath = spath.join('.');
        return file;
      }
    }
    root = ObjectUtils.getByPath(root,'folders.0')
    if(root){
      spath.push(`folders[sequence=${root.sequence}]`)
      return FileFormatter.getFileById(root,uuid,spath);
    }
    return null;
  }

  // NOTE: this method is for manage -> clients -> connection detail -> conversations only
  static transformFolders (folders:any, spath?: string) {
    return folders.map((folder: any) => {
      let path = []
      if (spath) {
        path.push(spath)
      }
      path.push(`folders[sequence=${folder.sequence}]`)
      folder.SPath = path.join('.')
      return folder
    })
  }

  static transformFiles (files:any, board:any, spath?: string):CBoardFile[] {
    let transformedFiles:CBoardFile[] = []
    let queue:number[] = []
    let pages = board.pages || []
    pages = pages.filter((page) => {
      if (!page.original_page_number || page.original_page_number === 1) {
        return true
      }
      return false
    })
    files.forEach((file: CBoardFile) => {
      if (queue.indexOf(file.sequence) === -1) {
        let page = ArrayUtils.getFromArray(pages, file.client_uuid, 'page_group')
        let formatedFile = this.transformFile(file, page, board, spath)
        transformedFiles.push(formatedFile)
        queue.push(file.sequence)
      }
    })
    return transformedFiles
  }

  // NOTE: this method is for manage -> clients -> connection detail -> conversations only
  static transformFile (file: any, page: any, board: any, spath?: string):CBoardFile {
    let resource = FileFormatter.getFileResource(file, board);
    let path = [];
    if (spath) {
      path.push(spath);
      path.push(`files[sequence=${file.sequence}]`);
    } else {
      path.push(`page_groups[sequence=${file.sequence}]`);
    }
    let result:CBoardFile = {
      name: file.name || page && page.name || resource && resource.name,
      thumbnail: FileFormatter.getPageThumbnail(page, board),
      thumbnailRotate: page && page.rotate || 0,
      pageSequence:page && page.sequence,
      sequence: file.sequence,
      client_uuid: file.client_uuid,
      is_deleted: file.is_deleted || false,
      creator: FileFormatter.getFileCreator(file, resource, page, board),
      fileType: FileFormatter.getFileType(resource, page),
      pageType: page && page.page_type,
      totalPages: 1,
      SPath: path.join('.')
    }
    let updated_time
    if (page && page.page_type === 'PAGE_TYPE_WEB') {
        updated_time = file.updated_time > page.updated_time ? file.updated_time : page.updated_time
    } else {
        updated_time = file.updated_time || page && page.updated_time
    }
    if(updated_time){
      result.updated_time = updated_time
    }
    let created_time = file.created_time || page && page.created_time
    if(created_time){
      result.created_time = created_time
    }
    let original_created_time = file.original_created_time || page && page.original_created_time
    if(original_created_time) {
      result.original_created_time = original_created_time
    }
    if (page) {
      FileFormatter.processEditorActor(page, board,result)
    }
    if (resource) {
      if (resource.total_pages) {
        result.totalPages = resource.total_pages
      }
      result.resourceStatus = resource.status
      result.convertedPage = resource.converted_pages
      result.resourceSequence = resource.sequence

      if (resource.is_password_protected) {
        result.isPasswordProtected = true
      }
    }
    return result
  }

  // NOTE: this method is for manage -> clients -> connection detail -> conversations only
  static getFolderInfo (board: Board, folderSpath: string) {
    let paths = folderSpath.split('.')
    let root
    let path
    let folder
    let pathNodes = []
    let collapsedFolderSpath = ''
    let count = paths.length
    for(let i = 0; i < count; i++) {
      path = ObjectUtils.parseSPath(paths[i]);
      root = root || board[path.key].find(folder => folder.sequence === path.attrVal)
      let getDestFolder = () => {
        if (root.sequence === path.attrVal) {
          folder = root
        } else {
          root = root.folders.find(folder => folder.sequence === path.attrVal)
          getDestFolder()
        }
      }
      getDestFolder()
      if (count > 4) {
        if (pathNodes.length && i > count - 4) {
          if (pathNodes.length === 1) {
            pathNodes.push({
              name: root.name,
              sequence: root.sequence,
              spath: `${collapsedFolderSpath}.folders[sequence=${root.sequence}]`
            })
          } else {
            pathNodes.push({
              name: root.name,
              sequence: root.sequence,
              spath: pathNodes[pathNodes.length - 1].spath + `.folders[sequence=${root.sequence}]`
            })
          }
        } else {
          if (!pathNodes.length) {
            pathNodes.push({})
          }
          if (collapsedFolderSpath) {
            collapsedFolderSpath += `.folders[sequence=${root.sequence}]`
          } else {
            collapsedFolderSpath = `folders[sequence=${root.sequence}]`
          }
        }
      } else {
        if (pathNodes.length) {
          pathNodes.push({
            name: root.name,
            sequence: root.sequence,
            spath: pathNodes[i - 1].spath + `.folders[sequence=${root.sequence}]`
          })
        } else {
          pathNodes.push({
            name: root.name,
            sequence: root.sequence,
            spath: `folders[sequence=${root.sequence}]`
          })
        }
      }
    }
    return { folder, pathNodes }
  }

  // NOTE: this method is for manage -> clients -> connection detail -> conversations only
  static transformFoldersFiles (board: Board, folderSpath: string) {
    let files
    let folders
    let pathNodes = []
    let result: any = {}
    if (folderSpath) {
      let folderInfo = FileFormatter.getFolderInfo(board, folderSpath)
      files = folderInfo.folder.files
      folders = folderInfo.folder.folders
      pathNodes = folderInfo.pathNodes
    } else {
      if (board) {
        result.totalSignatures = board.total_signatures
        result.totalEmails = board.total_emails
      }
      files = board.page_groups
      folders = board.folders
    }
    if (files) {
      let filteredFiles = files.filter(f => !f.is_deleted)
      let deletedFiles = files.filter(f => f.is_deleted)
      let transformedFiles = FileFormatter.transformFiles(filteredFiles, board, folderSpath)
      result.files = deletedFiles.concat(transformedFiles)
    }
    if (folders) {
      let filteredFolders = folders.filter(f => !f.is_deleted)
      let deletedFolders = folders.filter(f => f.is_deleted)
      let transformedFolders = FileFormatter.transformFolders(filteredFolders, folderSpath)
      result.folders = deletedFolders.concat(transformedFolders)
    }
    result.pathNodes = pathNodes

    return result
  }

  // NOTE: this method is for manage -> clients -> connection detail -> conversations only
  static transformSignatureFiles (board:Board){
    let currentUser = MxISDK.getCurrentUser()
    let signatures = board.signatures
    return signatures && signatures.filter((sign)=>{
        return sign.status !== 'SIGNATURE_STATUS_PREPARING'
    }).map(sign => {
        if (sign.is_deleted) {
          return sign
        }
        let firstPage = sign.pages[0];
        let fileType = '';
        let fileName = firstPage.original_resource_name || sign.name;
        if (/.\w+$/.test(fileName)) {
            fileType = fileName.match(/.\w+$/)[0].slice(1)
        }
        let signCtrlInstance = new SignatureController(board.id, sign, board);
        const isWaitMySign = false;
        const creator = FileFormatter.getFileCreator(sign, null, firstPage, board)
        const {signees, isInProgress, isEnded, isDeclined} = signCtrlInstance
        signCtrlInstance = null
        return {
            ...sign,
            SPath: `signatures[sequence=${sign.sequence}]`,
            signees,
            isWaitMySign,
            fileType,
            isInProgress,
            creator,
            isEnded,
            isDeclined,
            isOwner: creator.id === currentUser.id,
            originalSignature: sign
        }
    })
  }

}
