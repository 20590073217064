

export default {

    getSRBoardById(state){
        return state.userBoards.find( (ub)=> ub.id === state.binderId )
    },

    getSRBinder(state){
        return state.binderObj
    },

    getMyOpenBoards(state){
        return state.userBoards.filter( ub => ub.is_service_request &&
            (ub.routing_status === 'ROUTING_STATUS_OPEN' || ub.routing_status === 'ROUTING_STATUS_IN_PROGRESS')
        )
    },

    getMyCompletedBoards(state){
        return state.userBoards.filter( ub => ub.is_service_request &&
            (ub.routing_status === 'ROUTING_STATUS_CLOSE' || ub.routing_status === 'ROUTING_STATUS_AGENT_COMPLETE' ||ub.routing_status === 'ROUTING_STATUS_CLOSE_TIMEOUT'))
    },


    getFeeds(state){
        if(state.hasMoreFeeds)
            return state.feeds

        return state.feeds
    },

    getUserAvatarMapping(state){
        return state.userAvatarMapping
    },

    hasMoreFeeds(state){
        return state.hasMoreFeeds
    },

    unreadFeedsTotal(state,getters){
        let boards = [...getters.getMyOpenBoards, ...getters.getMyCompletedBoards]

        let total = 0
        for ( const board of boards ){
            total += board.feed_unread_count
        }
        return total || 0
    },

    getBaseDomain(state){
        return state.baseDomain
    },

    getUserCap(state) {
        return state.userCap
    },

    getUserBasicInfo(state) {
        return state.userBasicInfo
    }
}