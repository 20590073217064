import {ClientResponse} from "../proto/generated/ClientResponse";
import {getAccessToken} from "./auth";
import {Ajax} from "../network/ajax";
import {ConnectorGlobalRelayConfig, ConnectorHubSpotConfig, ConnectorSFDCConfig} from "../api/defines";
import { currentOrgId, currentUserId } from '../data/cache/cacheMgr';
import { getContextPath } from "../core/config";


function testSFDCConnection (config: ConnectorSFDCConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl;

        restUrl = `${domain}/v1/${currentOrgId}/jobs/sfconnect`;

        let payload = {
            client_id: config.client_id,
            user_name: config.user_name,
            aud_server: config.aud_server,
            private_key: config.private_key
        }

        return Ajax.post(restUrl, payload, {accessToken: accessToken});
    });
}

function testGlobalRelayConnection (config: ConnectorGlobalRelayConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl;

        restUrl = `${domain}/v1/${currentOrgId}/jobs/glconnect`;

        let payload = {
            smtp_server_host: config.smtp_server_host,
            smtp_server_port: config.smtp_server_port,
            receiver_email: config.receiver_email,
            smtp_username: config.smtp_username,
            smtp_password: config.smtp_password
        } as any

        if(config.smtp_server_host.indexOf('globalrelay.com') === -1) {
            payload.from_address = config.smtp_username
        }

        return Ajax.post(restUrl, payload, {accessToken: accessToken});
    });
}

function testHubSpotConnection (config: ConnectorHubSpotConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl;

        restUrl = `${domain}/v1/${currentOrgId}/jobs/hubspotconnect`;


        return Ajax.post(restUrl, config, {accessToken: accessToken});
    });
}

function scheduleSFDCJob(config: ConnectorSFDCConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl = `${domain}/v1/${currentOrgId}/jobs/schedule`;

        let payload = {
            service: "salesforce",
            job_run_time: config.job_run_time,
            timezone: config.timezone
        }
        return Ajax.post(restUrl, payload, {accessToken: accessToken});
    });
}

function scheduleGlobalRelayJob (config: ConnectorGlobalRelayConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl = `${domain}/v1/${currentOrgId}/jobs/schedule`;

        let payload = {
            service: "global_relay",
            job_run_time: config.job_run_time,
            timezone: config.timezone
        }
        return Ajax.post(restUrl, payload, {accessToken: accessToken});
    });
}

function scheduleHubSpotJob(config: ConnectorHubSpotConfig): Promise<ClientResponse> {
    return getAccessToken().then(response => {
        let domain = location.origin + getContextPath();
        let accessToken = response.data;
        let restUrl = `${domain}/v1/${currentOrgId}/jobs/schedule`;

        let payload = {
            service: "hubspot",
            job_run_time: config.job_run_time,
            timezone: config.timezone
        }
        return Ajax.post(restUrl, payload, {accessToken: accessToken});
    });
}


export {testSFDCConnection,
        scheduleSFDCJob,
        testHubSpotConnection,
        scheduleHubSpotJob,
        testGlobalRelayConnection,
        scheduleGlobalRelayJob}