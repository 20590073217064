var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "summary-container",
      on: { mousedown: _vm.handleActiveEvent }
    },
    [
      _c("div", { staticClass: "bank-msg-summary-view" }, [
        _c("div", { staticClass: "bank-msg-summary-view__unread" }, [
          _c("span", { staticClass: "cardsdk-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("unread_msgs")) + " ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cardsdk-badge",
              class: { "badge-alert": _vm.hasUnReadMsg }
            },
            [
              _c(
                "span",
                {
                  staticClass: "notification",
                  class: { "notification-alert": _vm.hasUnReadMsg },
                  on: { click: _vm.showMessages }
                },
                [
                  _c("img", { attrs: { src: _vm.ex_chat } }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.unreadFeedsTotal) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bank-msg-summary-view__send-msg" }, [
          _c(
            "button",
            { staticStyle: { cursor: "pointer" }, on: { click: _vm.sendMsg } },
            [_vm._v(_vm._s(_vm.$t("send_secure_msg")))]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }