import { MxCallback, MxSubscription } from './../../api/defines';
import { MxObservable } from "../../core/mxObserver";
import { GroupCache } from '../cache/groupCache';
import { Group } from '../../proto/generated/Group';
import { UserGroup } from '../../proto/generated/UserGroup';
import { GroupBoard } from '../../proto/generated/GroupBoard';

export class MxGroupSubscriber {
    private _groupCache: GroupCache;
    private _basicInfoObservable : MxObservable<Group>;
    private _routingRequestsObservable : MxObservable<Group>;
    private _teamsObservable : MxObservable<UserGroup[]>;
    private _boardsObservable : MxObservable<GroupBoard[]>;

    constructor(groupCache: GroupCache) {
        this._groupCache = groupCache;

        this._basicInfoObservable = new MxObservable<Group>('group.basicInfo');
        this._routingRequestsObservable = new MxObservable<Group>('group.routingRequests');
        this._teamsObservable = new MxObservable<UserGroup[]>('group.teams');
        this._boardsObservable = new MxObservable<GroupBoard[]>('group.boards');
    }

    subscribeBasicInfo(cb: MxCallback<Group>): MxSubscription {
        return this._basicInfoObservable.subscribe(cb);
    }

    subscribeRoutingRequests(cb: MxCallback<Group>): MxSubscription {
        return this._routingRequestsObservable.subscribe(cb);
    }

    subscribeTeams(cb: MxCallback<UserGroup[]>): MxSubscription {
        return this._teamsObservable.subscribe(cb);
    }

    subscribeGroupBoards(cb: MxCallback<GroupBoard[]>): MxSubscription {
        return this._boardsObservable.subscribe(cb);
    }

    onObjectUpdate(obj: Group): void {
        if (obj.local_revision || obj.roles || obj.tags || obj.integrations || obj.group_caps || obj.routing_config || obj.invitation_tokens) {
            this._basicInfoObservable.publish(this._groupCache.basicInfo());
        }

        obj.teams && obj.teams.forEach(team => {
            this._teamsObservable.publish(this._groupCache.getCacheTeam(team.sequence));
        })

        if (obj.boards) {
            this._boardsObservable.publish(obj.boards);
        }
    }

    onRoutingRequestsUpdate(obj: Group): void {
        this._routingRequestsObservable.publish(obj);
    }
}