<template>
  <div class="thumbnail">
    <span class="mx-thumbnail-outer">
      <span class="mx-thumbnail-inner initials">
        <img
          v-if="userAvatar"
          v-show="!hasError"
          :src="userAvatar"
          :lazyload="lazyload"
          :data-original="userAvatar"
          @error="onError"
        />
        <img v-else :src="defaultAvatar" />
      </span>
    </span>
  </div>
</template>

<script>
import defaultProfile from "@views/theme/src/images/default/default_profile_2x.png";

export default {
  name: "ServiceRequestUserAvatar",
  props: {
    userAvatar: {
      type: String,
      required: true,
      default: () => ""
    },
    size: {
      type: String,
      required: false,
      default: () => ""
    },
    lazyload: {
      type: Boolean,
      required: false,
      default: false
    },
    displayStatus: {
      type: String,
      required: false,
      default: () => ""
    },
    onlineStatus: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      hasError: false,
      defaultAvatar: defaultProfile
    };
  },
  computed: {
    sizeClass() {
      return `thumbnail-${this.size}`;
    }
  },
  watch: {
    userAvatar() {
      this.hasError = false;
    }
  },
  methods: {
    onError: function() {
      this.hasError = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail {
  display: inline;
}
.thumbnail img {
  max-height: 32px;
  max-width: 32px;
  border-radius: 4px;
}
</style>
