module.exports = {
    "send_secure_msg": "Send Secure Message",
    "new_secure_msg": "New Secure Message",
    "unread_msgs": "Unread Messages",
    "mark_as_complete": "Mark as Complete",
    "submitted_on_x": "Submitted on {time}",
    "submitted_on": "Submitted on",
    "your_msgs": "Your Message",
    "request_description": "Request Description",
    "Attachments": "Attachments",
    "add_attachment": "ADD ATTACHMENT",
    "send_msg": "Send Message",
    "cancel": "Cancel",
    "request_create_success": "Request Created Successfully",
    "open_requests": "Open Requests",
    "completed_requests": "Completed Requests",
    "load_more": "Load More",
    "no_attachments": "No Attachments",
    "msg_detail": "Message Detail",
    "Description": "Description",
    "Reply": "Reply",
    "Back": "Back",
    "request_created_success": "Request Created Successfully"
}