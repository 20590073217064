
import { Board } from '../proto/generated/Board';
import { ActionObject } from '../proto/generated/ActionObject';
import { ActionUserRoster } from '../proto/generated/ActionUserRoster';
import { SessionRecordingStatus } from '../proto/generated/SessionRecordingStatus';
import { SessionStatus } from '../proto/generated/SessionStatus';
import { DesktopShareConf } from '../proto/generated/DesktopShareConf';
import { AudioConf } from '../proto/generated/AudioConf';
import { TelephonyConf } from '../proto/generated/TelephonyConf';
import { DesktopShareState } from '../proto/generated/DesktopShareState';
import { SessionVideoState } from '../proto/generated/SessionVideoState';
import { BoardUser } from "../proto/generated/BoardUser";
import { BoardComment } from "../proto/generated/BoardComment";
import { BoardPage } from "../proto/generated/BoardPage";
import { BoardResource } from '../proto/generated/BoardResource';
import { SessionRecordingState } from '../proto/generated/SessionRecordingState';
import { ActionPageSwitch } from '../proto/generated/ActionPageSwitch';
import { ActionVideoState } from '../proto/generated/ActionVideoState';
import { ActionLaserPointer } from '../proto/generated/ActionLaserPointer';
import { UserCap } from './../proto/generated/UserCap';
import { BoardAccessType } from './../proto/generated/BoardAccessType';
import { sdkConfig } from './config';
import { MxErr } from './error';
import { MxMeetSubscriber } from '../data/subscribe/meetSubscriber';
import { MeetCache } from '../data/cache/meetCache';
import { getByPath, filepath2spath, sendISDKMeetLog, cloneObject } from '../util';
import { MxSPath, MxTagKey } from '../api/defines';
import { Connection } from './../network/connection';
import { MxMeet } from "../api/mxMeet";
import { MxBoardImpl } from './mxBoardImpl';
import { MxCallback, MxSubscription, UserIdentity, MxError } from '../api/defines';
import * as bfile from '../biz/file';
import * as bmeet from '../biz/meet';
import * as cacheMgr from '../data/cache/cacheMgr';

function getMaxRevisionFromArray(arr: any[]) {
    if (!arr || arr.length === 0) {
        return null;
    }

    let theMax = arr[0];
    arr.forEach((obj: any)=> {
      if(obj.revision > theMax.revision){
        theMax = obj;
      }
    });
    return theMax;
  }

export class MxMeetImpl extends MxBoardImpl implements MxMeet  {
    private _meetCache: MeetCache;
    private _meetSubscriber: MxMeetSubscriber;
    private _meetId: string;
    private _meetBoardId: string;
    private _isMeetSubscribed: boolean;
    private _name: string;
    private _email: string;
    private _password: string;
    private _isClip: boolean;

    constructor(meetCache: MeetCache) {
        super(meetCache.boardCache);
        this._meetCache = meetCache;
        this._meetId = this._meetCache.session.session_key;
        this._meetBoardId = this._meetCache.board.id;
        this._isMeetSubscribed = false;
        this._isClip = false;

        this._meetSubscriber = new MxMeetSubscriber(meetCache);
    }

    get boardId(): string {
        return this._meetBoardId || this._meetCache.board.id;
    }

    get meetId(): string {
        return this._meetId || this._meetCache.session.session_key;
    }

    get meetBoard(): Board {
        return this._meetCache.board;
    }

    get meetSession(): ActionObject {
        return this._meetCache.session;
    }

    getSessionKey(): string {
        return this.meetId;
    }
    
    getBoardId():string {
        return this.boardId;
    }
    
    getTopic(): string {
        return this.meetSession.topic;
    }

    getStartTime(): number {
        return this.meetSession.start_time;
    }

    getRosterId(): string {
        return cacheMgr.currentSessionId;
    }

    getTimeZone(): string {
        return cacheMgr.currentUser ? cacheMgr.currentUser.user.timezone : '';
    }

    getSessionZone(): string {
        return cacheMgr.currentSessionZone;
    }

    getLanguage(): string {
        return cacheMgr.currentUser ? cacheMgr.currentUser.user.language : '';
    }

    isStarted(): boolean {
        return getByPath(this.meetBoard, 'sessions.0.session.session_status') === SessionStatus.SESSION_STARTED;
    }

    isEnded(): boolean {
        return getByPath(this.meetBoard, 'sessions.0.session.session_status') === SessionStatus.SESSION_ENDED;
    }

    isExpired(): boolean {
        return this.meetSession.is_expired || false;
    }

    getDsConfig(): DesktopShareConf {
        return this.meetSession.ds_conf;
    }

    getAudioConfig(): AudioConf {
        return this.meetSession.audio_conf;
    }

    getTelephonyConf(): TelephonyConf {
        return this.meetSession.telephony_conf;
    }

    getUsers(): BoardUser[] {
        return this.meetBoard.users || [];
    }

    getRosters(): ActionUserRoster[] {
        return this.meetSession.user_roster || [];
    }

    getComments(): BoardComment[] {
        return this.meetBoard.comments || [];
    }

    getPages(): BoardPage[] {
        return this.meetBoard.pages || [];
    }

    getHost(): ActionUserRoster {
        if (!this.meetSession.user_roster) return null;

        for (let i = 0; i < this.meetSession.user_roster.length; i++) {
            if (this.meetSession.user_roster[i].is_host) {
                return this.meetSession.user_roster[i];
            }
        }
        return null;
    }

    getPresenter(): ActionUserRoster {
        if (!this.meetSession.user_roster) return null;

        for (let i = 0; i < this.meetSession.user_roster.length; i++) {
            if (this.meetSession.user_roster[i].is_presenter) {
                return this.meetSession.user_roster[i];
            }
        }
        return null;
    }

    getDsState(): DesktopShareState {
        return getMaxRevisionFromArray(this.meetSession.ds_state);
    }

    getVideoState(): SessionVideoState {
        return getMaxRevisionFromArray(this.meetSession.session_video_state);
    }
    
    getRecordingStatus(): SessionRecordingStatus {
        let recordingState: SessionRecordingState = getMaxRevisionFromArray(this.meetSession.recording_state);
        return recordingState ? recordingState.status : SessionRecordingStatus.RECORDING_STOPPED;
    }

    getIsAudioCall(): boolean {
        return this._meetCache.isAudioCall();
    }

    getSharingPage(): ActionPageSwitch {
        return this._meetCache.session.page_switch;
    }

    getActionVideoState(): ActionVideoState {
        return getMaxRevisionFromArray(this.meetSession.video_state);
    }

    getLaserPointer(): ActionLaserPointer {
        return this._meetCache.session.laser_pointer;
    }

    getActiveSpeaker(): string {
        return this._meetCache.session.active_speaker_id;
    }

    // subscribers
    subscribeRosters(cb: MxCallback<ActionUserRoster[]>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeRosters(cb);
    }

    subscribeUsers(cb: MxCallback<BoardUser[]>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeUsers(cb);
    }

    subscribeComments(cb: MxCallback<BoardComment[]>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeComments(cb);
    }
  
    subscribePages(cb: MxCallback<BoardPage[]>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribePages(cb);
    }

    subscribeResources(cb: MxCallback<BoardResource[]>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeResources(cb);
    }

    subscribeDsConf(cb: MxCallback<DesktopShareConf>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeDSConf(cb);
    }
  
    subscribeAudioConf(cb: MxCallback<AudioConf>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeAudioConf(cb);
    }
  
    subscribeVideoState(cb: MxCallback<SessionVideoState>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeVideoState(cb);
    }
  
    subscribeDsState(cb: MxCallback<DesktopShareState>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeDSState(cb);
    }
  
    subscribeRecordingStatus(cb: MxCallback<SessionRecordingStatus>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeRecordingStatus(cb);
    }
  
    subscribeSessionStatus(cb: MxCallback<SessionStatus>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeSessionStatus(cb);
    }
  
    subscribeTelephonyConf(cb: MxCallback<TelephonyConf>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeTelephonyConf(cb);
    }

    subscribeIsAudioCall(cb: MxCallback<boolean>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeIsAudioCall(cb);
    }

    subscribeSharingPage(cb: MxCallback<ActionPageSwitch>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeSharingPage(cb);
    }

    subscribeActionVideoState(cb: MxCallback<ActionVideoState>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeActionVideoState(cb);
    }

    subscribeLaserPointer(cb: MxCallback<ActionLaserPointer>): MxSubscription {
        this.subscribeMeet();
        return this._meetSubscriber.subscribeLaserPointer(cb);
    }

    subscribeSessionError(cb: MxCallback<MxError>): MxSubscription {
        return this._meetSubscriber.subscribeSessionError(cb);
    }

    subscribeActiveSpeaker(cb: MxCallback<string>): MxSubscription {
        return this._meetSubscriber.subscribeActiveSpeaker(cb);
    }

    // APIs
    startMeet(topic?: string, originalBoardId?: string, auto_recording?: boolean): Promise<void> {
        return bmeet.startMeet(this.meetId, this.boardId, topic, originalBoardId, auto_recording).then(response => {
            if (response.object.session) {
                this._meetCache.updateSession(response.object.session);
            }

            if (response.session_id) {
                cacheMgr.setCurrentSessionId(response.session_id);
            }

            if (getByPath(this.meetBoard, 'sessions.0.session')) {
                this.meetBoard.sessions[0].session.session_status = SessionStatus.SESSION_STARTED;
            }
            
            return this.subscribeMeet();
        })
    }

    joinMeet(name?: string, email?: string, password?: string): Promise<void> {
        this._name = name;
        this._email = email;
        this._password = password;

        return bmeet.joinMeet(this.meetId, name, email, password).then(response => {
            if (!this._meetCache.session.revision && response.object.session) {
                this._meetCache.updateSession(response.object.session);
            }else if (response.object.session && response.object.session.user_roster) {
                this._meetCache.session.user_roster = cloneObject(response.object.session.user_roster);
                this._meetCache.session.total_rosters = response.object.session.total_rosters;
            }

            if (response.object.board && response.object.board.users) {
                this._meetCache.board.users = cloneObject(response.object.board.users);
                this._meetCache.board.total_members = response.object.board.total_members;
            }

            if (response.session_id) {
                cacheMgr.setCurrentSessionId(response.session_id);
            }

            let sessionPassword = getByPath(response, 'object.session.session_password');
            if (sessionPassword) {
                // for anonymous join meet case, server will return password when join meet success
                this._meetCache.session.session_password = sessionPassword;
            }

            return this.subscribeMeet();
        })
    }
  
    rejoinMeet(): Promise<void> {
        this._isMeetSubscribed = false;
        return this.joinMeet(this._name, this._email, this._password);
    }

    leaveMeet(): Promise<void> {
        this.cleanup();

        return bmeet.leaveMeet(this.meetId, this.boardId).then(response => {
            return Promise.resolve();
        });
    }

    endMeet(): Promise<void> {
        return bmeet.endMeet(this.meetId, this.boardId).then(response => {
            this.cleanup();
            return Promise.resolve();
        });
    }

    switchPage(pageSequence: number): Promise<void> {
        return bmeet.switchPage(this.meetId, pageSequence).then(response => {
            return Promise.resolve();
        })
    }

    deletePage(pageSequence: number): Promise<void> {
        let pages = this._meetCache.getPages([pageSequence]);
        let spath: MxSPath = '';
        if (pages && pages.length === 1 && pages[0].file) {
            spath = filepath2spath(pages[0].file);
        }

        if (spath) {
            return bfile.deleteFiles(this.id, [spath]).then(response => {
                return Promise.resolve();
            });
        }else {
            return Promise.reject(MxErr.InvalidParam());
        }
    }

    setPresenter(rosterId: string, grabPresenterWhenNotSharing?: boolean): Promise<void> {
        return bmeet.setPresenter(this.meetId, rosterId, grabPresenterWhenNotSharing).then(response => {
            return Promise.resolve();
        })
    }

    setHost(rosterId: string): Promise<void> {
        return bmeet.setHost(this.meetId, rosterId).then(response => {
            return Promise.resolve();
        })
    }

    reclaimHost(): Promise<void> {
        return bmeet.reclaimHost(this.meetId).then(response => {
            return Promise.resolve();
        })
    }

    mutePeers(rosterIds: string[]): Promise<void> {
        return bmeet.muteRoster(this.meetId, rosterIds).then(response => {
            return Promise.resolve();
        })
    }

    unmutePeers(rosterIds: string[]): Promise<void> {
        return bmeet.unmuteRoster(this.meetId, rosterIds).then(response => {
            return Promise.resolve();
        })
    }

    muteAllPeers(): Promise<void> {
        return bmeet.muteAll(this.meetId).then(response => {
            return Promise.resolve();
        })
    }

    leaveTelephony(rosterId: string): Promise<void> {
        return bmeet.leaveTelephony(this.meetId, rosterId).then(response => {
            return Promise.resolve();
        })
    }

    setRecordingStatus(status: SessionRecordingStatus): Promise<void> {
        return bmeet.setRecordingStatus(this.meetId, status).then(response => {
            return Promise.resolve();
        })
    }

    startRecording(): Promise<void> {
        return this.setRecordingStatus(SessionRecordingStatus.RECORDING_STARTED);
    }

    pauseRecording(): Promise<void> {
        return this.setRecordingStatus(SessionRecordingStatus.RECORDING_PAUSED);
    }
  
    resumeRecording(): Promise<void> {
        return this.setRecordingStatus(SessionRecordingStatus.RECORDING_RESUMED);
    }
  
    stopRecording(): Promise<void> {
        return this.setRecordingStatus(SessionRecordingStatus.RECORDING_STOPPED);
    }

    saveRecording(recordingName: string, destinationBoardId: string): Promise<void> {
        return bmeet.saveRecording(this.meetId, recordingName, destinationBoardId).then(response => {
            return Promise.resolve();
        })
    }

    inviteRosters(users: UserIdentity[]): Promise<void> {
        return bmeet.inviteRosters(this.meetId, users).then(response => {
            return Promise.resolve();
        })
    }

    sendLogToServer(log: string): Promise<void> {
        return bmeet.sendLogToServer(log).then(response => {
            return Promise.resolve();
        })
    }

    isClip(): boolean {
        return this._isClip;
    }

    setAsClip(): void {
        this._isClip = true;
    }

    setAsMeet(): Promise<void> {
        return this.deleteTag(MxTagKey.MEET_CALL_FLAG);
    }

    setAsAudioCall(): Promise<void> {
        return this.createOrUpdateTag(MxTagKey.MEET_CALL_FLAG, 'true');
    }

    publishActionVideoState(state: ActionVideoState): Promise<void> {
        return bmeet.publishActionVideoState(this.meetId, state).then(response => {
            return Promise.resolve();
        })
    }

    publishLaserPointer(laserPointer: ActionLaserPointer): Promise<void> {
        return bmeet.publishLaserPointer(this.meetId, laserPointer).then(response => {
            return Promise.resolve();
        })
    }

    readOwnerCap(): Promise<UserCap> {
        return bmeet.readMeet(this.meetId).then(response => {
            try {
                let users: BoardUser[] = getByPath(response, 'object.board.users');
                let owner = users.filter(bu => bu.type === BoardAccessType.BOARD_OWNER)[0];
                return owner.user.cap || {}
            }catch(e) {
                return {}
            }
        })
    }

    subscribeMeet(): Promise<void> {
        if (!this._isMeetSubscribed && this.meetSession && this.meetSession.session_key) {
            this._isMeetSubscribed = true;
            this._isSubscribed = true;  // no need to subscribe board in MxBoard
            let p = null;
            if (cacheMgr.currentMeet && cacheMgr.currentMeet.getSessionKey() === this.meetId) {
                p = Connection.getInstance().subscribeCurrentMeet();
            }else {
                p = Connection.getInstance().subscribeMeet(this.meetId);
            }

            p.then(response => {
                return Promise.resolve();
            }).catch(e => {
                this._isMeetSubscribed = false;
                this._isSubscribed = false;
                return Promise.reject(e);
            });
        }

        return Promise.resolve();
    }

    onMeetUpdated(board: Board, session: ActionObject): void {
        this._meetCache.onObjectUpdate(board, session);
        this._boardSubscriber.onObjectUpdate(board);
        this._meetSubscriber.onObjectUpdate(board, session);

        if (session) {
            if (session.session_status === SessionStatus.SESSION_ENDED || session.is_deleted) {
                // meet ended
                this.cleanup();
            }
        }

        let log = '';
        if (board && board.revision) {
            log += `br=${board.revision}, `
        }
        if (session && session.revision) {
            log += `sr=${session.revision} `
        }

        if (log.length > 0) {
            sendISDKMeetLog('rsd: ' + log);
        }
    }

    cleanup(): void {
        try {
            if (cacheMgr.currentMeet && cacheMgr.currentMeet.getSessionKey() === this.meetId) {
                if (this.isClip) {
                    Connection.getInstance().unsubscribeCurrentMeet().then(r => {}).catch(e => {});
                }else if (sdkConfig.isMeetSdk){
                    Connection.getInstance().close();
                }

                cacheMgr.onMeetBoardDeleted(this.getSessionKey());
                cacheMgr.setCurrentMeet(null);
            }
        }catch(e) {
            // ignore error
        }
    }
}