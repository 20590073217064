import { PartnerPlanCode } from './../proto/generated/PartnerPlanCode';
import { UserIdentity } from './../api/defines';
import { Partner } from './../proto/generated/Partner';
import { WebAppType } from './../proto/generated/WebAppType';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientParam } from '../proto/generated/ClientParam';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { Ajax } from "../network/ajax";
import { partnerRequestNode } from "../network/requestNode";

function listWebApps(partnerId: string, filter: WebAppType): Promise<ClientResponse> {
    let reqType: ClientRequestType = ClientRequestType.WEBAPP_REQUEST_LIST;
    if (filter === WebAppType.WEBAPP_TYPE_BOT) {
        reqType = ClientRequestType.WEBAPP_REQUEST_LIST_BOT;
    }else if (filter === WebAppType.WEBAPP_TYPE_EMBEDDED) {
        reqType = ClientRequestType.WEBAPP_REQUEST_LIST_EMBEDDED;
    }else if (filter === WebAppType.WEBAPP_TYPE_SUBSCRIPTION) {
        reqType = ClientRequestType.WEBAPP_REQUEST_LIST_SUBSCRIPTION;
    }

    let partner: Partner = {};
    if (partnerId) {
        partner.id = partnerId;
    }

    return Ajax.sendRequest(partnerRequestNode(reqType, partner));
}

function listPartners(startIndex: number, size: number): Promise<ClientResponse> {
    let params: ClientParam[] = null;
    if (startIndex && size) {
        params = [{
            name: ClientRequestParameter.PARTNER_REQUEST_SEARCH_START,
            string_value: String(startIndex)
            }, {
            name: ClientRequestParameter.PARTNER_REQUEST_SEARCH_SIZE,
            string_value: String(size)
        }];
    }

    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_LIST, null, params));
}

function readPartner(partnerId: string): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_READ, partner));
}

function createPartner(partner: Partner): Promise<ClientResponse> {
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_CREATE, partner));
}

function updatePartner(partnerId: string, partner: Partner): Promise<ClientResponse> {
    partner.id = partnerId;
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_UPDATE, partner));
}

// function deletePartner(partnerId: string): Promise<ClientResponse> {
//     let partner: Partner = {
//         id: partnerId
//     };
//     return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_DELETE, partner));
// }

function readPartnerStatistics(partnerId: string): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_READ_STATISTICS, partner));
}

function readPartnerPlanCodes(partnerId?: string): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_READ_PLAN_CODES, partner));
}

function addPartnerPlanCode(partnerId: string, planCode: PartnerPlanCode): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId,
        plan_codes: [
            planCode
        ]
    };

    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_ADD_PLAN_CODE, partner));
}

function deletePartnerPlanCode(partnerId: string, planCode: PartnerPlanCode): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId,
        plan_codes: [
            planCode
        ]
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_DELETE_PLAN_CODE, partner));
}

function addPartnerMember(partnerId: string, user: UserIdentity): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId,
        members: [{
            user: user
        }]
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_ADD_MEMBER, partner));
}

function deletePartnerMember(partnerId: string, user: UserIdentity): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId,
        members: [{
            user: user
        }]
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_DELETE_MEMBER, partner));
}

function listPartnerGroups(partnerId: string, startIndex: number, size: number): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId
    };

    let params: ClientParam[] = null;
    if (startIndex && size) {
        params = [{
            name: ClientRequestParameter.PARTNER_REQUEST_SEARCH_START,
            string_value: String(startIndex)
            }, {
            name: ClientRequestParameter.PARTNER_REQUEST_SEARCH_SIZE,
            string_value: String(size)
        }];
    }

    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_LIST_GROUPS, partner, params));
}

function listPartnerTelephonyDomains(partnerId?: string): Promise<ClientResponse> {
    let partner: Partner = {
        id: partnerId
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_LIST_TELEPHONY_DOMAINS, partner));
}

export {
    listWebApps, 
    listPartners,
    readPartner,
    createPartner,
    updatePartner,
    readPartnerStatistics,
    readPartnerPlanCodes,
    addPartnerPlanCode,
    deletePartnerPlanCode,
    addPartnerMember,
    deletePartnerMember,
    listPartnerGroups,
    listPartnerTelephonyDomains,
} 