import {Defines, MxBoard, MxISDK, MxUser} from "isdk";
import {CUserBoard} from "../../defines/CUserBoard";
import {UserFormatter} from '@controller/utils/user'
import {GroupController} from "@controller/group/src/groupController";
import {CUserCap} from "@controller/defines/CUserCap";
import {CGroupUser} from '@controller/defines/CGroupUser.ts'
import {ObjectUtils} from "@controller/utils";
import {find1on1chat} from "@controller/user/src/util/find1on1chat";
import {User} from "isdk/src/proto/generated/User";
import {UserTag} from "isdk/src/proto/generated/UserTag";
import {transformError} from '@controller/common/decorators/transformError'
import {ClassLogger} from "@controller/common/decorators";
import {ArrayUtils} from "@commonUtils/array";
import {CBaseUser} from "@controller/defines/CBaseUser";
import {BoardUserStatus} from "isdk/src/proto/generated/BoardUserStatus";
import {
	BoardComment,
	SocialType,
	UserIdentity,
	UserMentionMe,
	UserType,
	UserBoard,
	SessionStatus,
	Board
} from "isdk/src/api/defines";
import {CUserRelation} from "@controller/defines/CUserRelation";
import {CContactUser} from "@controller/defines/CContactUser";
import {BoardFormatter} from "@controller/utils/board";
import {ObjectFeed} from "isdk/src/proto/generated/ObjectFeed";
import {UserContactStatus} from "isdk/src/proto/generated/UserContactStatus";
import { BoardRoutingStatus } from "isdk/src/proto/generated/BoardRoutingStatus";

let userController: UserController;

interface CUserMention {
	text: string,
	createdTime: number,
	actor: object,
	binderName: string,
	binderId: string,
	feedSequence: number,
	sequence: number,
	board: Board
}

@ClassLogger()
export class UserController {
	private userBoardSubscriber: Defines.MxSubscription;
	private userRelationSubscriber: Defines.MxSubscription;
	private userBasicInfoSubscriber: Defines.MxSubscription;
	private userContactsSubscriber: Defines.MxSubscription;
	private userPresenceSubscriber: Defines.MxSubscription;
	private userActionItemsSubscriber: Defines.MxSubscription;
	private readonly currentUser: MxUser;
	private readonly userCap: CUserCap;
	private meetResolveCallback: Function;
	private suggestedContactResolveCallback: Function;
	private userBoardCallback: Function;
	private groupCtrl: GroupController;
  private searchUserBoardRequest = null
	private searchMemberBoardRequest = null
	
  private userBoardMapper = {}
	private pendingAcdBoard = null
  private recentClosedAcd = {}
  private sortedUserBoardList = []
  private sortedPendingMeetList = []
  private sortedOngoingMeetList = []
  private userBoardCompareFn = (a, b) => { 
   const weightDiff = a.weight - b.weight
   if (weightDiff === 0) {
     return a.timestamp - b.timestamp > 0
   } else {
     return weightDiff > 0
   }
  }
  private pendingMeetCompareFn = (a, b) => a.created_time - b.created_time > 0
  private ongoingMeetCompareFn = (a, b) => a.start_time - b.start_time > 0

	protected constructor() {
		if (!userController) {
			this.currentUser = MxISDK.getCurrentUser();
			this.userCap = this.currentUser.cap;
      this.groupCtrl = GroupController.getInstance()
		}
	}

	static getInstance(): UserController {
		if (!userController) {
			userController = new UserController()
		}
		return userController
	}

	get basicInfo() {
		return UserFormatter.transformUserInfo(this.currentUser.basicInfo, this.userCap)
	}

  get group() {
    const groups = ObjectUtils.getByPath(this.currentUser, 'user.groups')
    if (groups) {
      return groups.find((group) => {
        return group.group && group.group.type !== Defines.GroupType.GROUP_TYPE_TEAM
      })
    } else {
      return {}
    }
  }

	get role() {
		if(this.group) {
			return this.group.role
		} else {
			return null
		}
	}

	getUserRelation(id: string): Defines.UserRelation {
		return this.currentUser.getUserRelationByUserId(id)
	}

	getUserContact (id: string): Defines.UserContact {
		return this.currentUser.getUserContactByUserId(id)
	}
 
	getSharedBinder(userId: string): Promise<Array<CUserBoard>> {
		return this.currentUser.queryShareBoards([{id: userId}]).then((user: Defines.User) => {
			let userBoards = user.boards
			return UserFormatter.transformUserBoards(
				userBoards.map((board) => {
					return this.currentUser.getUserBoardByBoardId(board.board.id)
				}))
		})
	}

	subscribeUserBasicInfo(resolve: Function): void {
		if (!this.userBasicInfoSubscriber) {
			resolve(UserFormatter.transformUserInfo(this.currentUser.basicInfo, this.userCap));
			this.userBasicInfoSubscriber = this.currentUser.subscribeUserBasicInfo((user: Defines.User) => {
				resolve(UserFormatter.transformUserInfo(user, this.userCap))
			})
		}
	}


	getAccessToken(): Promise<string> {
		return this.currentUser.getAccessToken().then((token: string) => {
			return token
		})
	}

	subscribeUserRelation(resolve: Function): void {
		if (!this.userRelationSubscriber) {
			const result = UserFormatter.transformUserRelations(this.currentUser.relations, false)
			this.processUserBoardsWhenContactsChange(result)
			resolve(result);
			this.userRelationSubscriber = this.currentUser.subscribeUserRelations((relations: Array<Defines.UserRelation>) => {
				const result = UserFormatter.transformUserRelations(relations, false)
				this.processUserBoardsWhenContactsChange(result)
				resolve(result)
			})
		}
	}

	private getWelcomeMessage (message?: string) {
	  // MV-5751
	  // If org enable welcome message
	  // For RM: Use invitation message in user tag
	  // For Client: Use default welcome message defined in local lang file
	  let welcomeMessage = message || ''
	  const groupBasicInfo = MxISDK.getCurrentOrg().basicInfo
	  if (groupBasicInfo) {
		const tags = groupBasicInfo.tags
		let enableWelcomeMessage = true
		for (let tag of tags) {
		  if (!tag.is_deleted && tag.name === 'Enable_Welcome_Message' && (tag.string_value === '0' || tag.string_value === 'false')) {
			enableWelcomeMessage = false
		  }
		}

		if (!enableWelcomeMessage) {
		  welcomeMessage = ''
		} else if (!welcomeMessage) {
		  const currentUser = MxISDK.getCurrentUser()
		  const userType = currentUser.basicInfo.type
		  if (userType === UserType.USER_TYPE_NORMAL) {
			const userTags = currentUser.tags
			if (userTags['invitation_message']) {
			  welcomeMessage = userTags['invitation_message']
			}
		  }
		}
	  }

	  return welcomeMessage
	}

	@transformError()
	confirmRelation(relationSequence: number, noFeed: boolean = false, message?: string, resendEmail?: boolean, resendSms?: boolean): Promise<Object> {
		const welcomeMessage = this.getWelcomeMessage(message)
		return this.currentUser.confirmRelation(relationSequence, welcomeMessage, resendEmail, resendSms, noFeed).then((user: Defines.User) => {
			return {
				binderId: ObjectUtils.getByPath(user, 'boards.0.board.id'),
				userId: ObjectUtils.getByPath(user, 'relations.0.user.id')
			}
		})
	}

	@transformError()
	resendInvitationByRM(relationSequence: number, viaEmail: boolean = true, viaPhoneNumber: boolean): Promise<object> {
		return this.currentUser.confirmRelation(relationSequence, '', viaEmail, viaPhoneNumber, true).then((user: Defines.User) => {
			const responseBoard = ObjectUtils.getByPath(user, 'boards.0.board')
			if (responseBoard) {
				const binderId = responseBoard.id
				const binderUsers = responseBoard.users
				const clientUser = binderUsers.find(binderUser => binderUser.invited_time)
				const result = {
					binderId,
					invitedTime: 0
				}
				if (clientUser) {
					result.invitedTime = clientUser.invited_time
				}
				return result
			} else {
				return {}
			}
		})
	}

	@transformError()
	createRelation(user: User, noFeed: boolean = false, message?: string): Promise<string> {
		return this.currentUser.createRelation(user, noFeed).then((user: Defines.User) => {
			return ObjectUtils.getByPath(user, 'relations.0.sequence')
		})
	}

	@transformError()
	createSocialConnection(type: SocialType, user: User, isResend?: boolean) {
		return this.currentUser.createSocialConnection(type, user, isResend).then((user: Defines.User) => {
			// return ObjectUtils.getByPath(user, 'boards.0.board.id')
			return user
		})
	}

	@transformError()
	reactivateSocialConnection (binderId: string, social: {
	  isWeChat?: boolean,
	  isWhatsapp?: boolean
	}) {
	  let socialType
	  if (social.isWeChat) {
		socialType = SocialType.SOCIAL_TYPE_WECHAT
	  } else if (social.isWhatsapp) {
		socialType = SocialType.SOCIAL_TYPE_WHATSAPP
	  } else {
		socialType = SocialType.SOCIAL_TYPE_INVALID
	  }
	  return this.currentUser.reactivateSocialConnection(socialType, binderId)
	}

	processUserBoardsWhenContactsChange(users: CBaseUser[]) {
		if(this.userBoardCallback) {
			let userIdArr = users.map(u => u.id).join('|')
			let changedBoards = []
			let userBoards = this.currentUser.boards
			if (userBoards && userBoards.length) {
				userBoards.forEach((userBoard) => {
					if(ObjectUtils.getByPath(userBoard,'board.id')) {
						const index = userBoard.board.users.findIndex(user => userIdArr.indexOf(ObjectUtils.getByPath(user, 'user.id')) >= 0)
						if (index >= 0) {
							changedBoards.push(userBoard)
						}
					}
				})
				this.userBoardCallback(this.processUserBoardChange(ArrayUtils.mergeArray([], changedBoards, {clone: true}), true))
			}

		}
	}

	subscribeUserBoards(resolve: Function): void {
		this.userBoardCallback = resolve
		if (!this.userBoardSubscriber) {
			if (this.currentUser && this.currentUser.boards) {
				this.userBoardCallback(this.processUserBoardChange(ArrayUtils.mergeArray([], this.currentUser.boards, {clone: true}), true));
				if (this.meetResolveCallback) {
					this.meetResolveCallback(ArrayUtils.mergeArray([], this.currentUser.boards, {clone: true}))
				}
			}
			this.userBoardSubscriber = this.currentUser.subscribeUserBoards((userBoards: Array<Defines.UserBoard>) => {
				this.userBoardCallback(this.processUserBoardChange(ArrayUtils.mergeArray([], userBoards, {clone: true})));
				if (this.meetResolveCallback) {
					this.meetResolveCallback(ArrayUtils.mergeArray([], userBoards, {clone: true}))
				}
			})
		}
	}

	processUserBoardChange (ubs: Array<UserBoard>, isInit: boolean = false) {
    const currentUser = MxISDK.getCurrentUser()
    let isPMListChanged = false

    for (let ub of ubs) {
      const existingUBObj = this.userBoardMapper[ub.sequence]
      let existingUbRevision = 0
      if (existingUBObj) {
        existingUbRevision = this[existingUBObj.category][existingUBObj.index].revision
      }
      if (ub.revision <= existingUbRevision) {
        continue
      }
      const transformedUb = UserFormatter.transformUserBoard(ub, isInit)
      if (Object.keys(transformedUb).length > 0) {		  
        if (currentUser.basicInfo.type === UserType.USER_TYPE_NORMAL) {
          const routingStatus = transformedUb.routing_status
          if (transformedUb.is_acd) {
             if ([BoardRoutingStatus.ROUTING_STATUS_OPEN, BoardRoutingStatus.ROUTING_STATUS_OPEN_NO_TIMEOUT, BoardRoutingStatus.ROUTING_STATUS_IN_PROGRESS].indexOf(routingStatus) > -1) {
              if (routingStatus === BoardRoutingStatus.ROUTING_STATUS_OPEN || routingStatus === BoardRoutingStatus.ROUTING_STATUS_OPEN_NO_TIMEOUT) {
                if (!transformedUb.is_deleted) {
                  this.pendingAcdBoard = transformedUb
                }
              } else if (this.pendingAcdBoard && this.pendingAcdBoard.id === transformedUb.id) {
                this.pendingAcdBoard = null
              }
            }
          }
          if (this.pendingAcdBoard && transformedUb.is_deleted && transformedUb.sequence === this.pendingAcdBoard.sequence) {
            this.pendingAcdBoard = null
          }
        }
        if (existingUBObj) {
          if (existingUBObj.category === 'sortedUserBoardList') {
            const existingUb = this.sortedUserBoardList[existingUBObj.index]
            if (transformedUb.is_deleted) {
              if (existingUb.is_acd) {
                //TODO should not show toast message for guests (only for primary agent)
                const boardUsers = existingUb.boardUsers
                const currentUserId = currentUser.basicInfo.id
                //ACD binder is closed, show toast message
                if (boardUsers.findIndex(user => user.isPrimaryAgent && user.id === currentUserId) >= 0) {
                  let customerName
                  let acd = boardUsers.filter(user => user.isOwner)
                  if (acd && acd.length) {
                    customerName = acd[0].name || acd[0].displayName
                  }
                  this.recentClosedAcd = {
                    name: customerName
                  }
                }
              }
            }
            this.modifyCategoryList(transformedUb, 'sortedUserBoardList', this.userBoardCompareFn, transformedUb.is_deleted)
          } else if (existingUBObj.category === 'sortedPendingMeetList') {
            isPMListChanged = true
            if (transformedUb.is_deleted) {
              this.modifyCategoryList(transformedUb, 'sortedPendingMeetList', this.pendingMeetCompareFn, true)
            } else {
              const session = ObjectUtils.getByPath(transformedUb, 'sessions.0.session', {})
              if (session.session_status === SessionStatus.SESSION_SCHEDULED) {
                if (transformedUb.status === BoardUserStatus.BOARD_MEMBER) {
                  delete this.userBoardMapper[ub.sequence]
									this.sortedPendingMeetList.splice(existingUBObj.index, 1)
                } else {
									this.modifyCategoryList(transformedUb, 'sortedPendingMeetList', this.pendingMeetCompareFn)
								}
              } else {
                if (session.session_status === SessionStatus.SESSION_STARTED) {
                  this.modifyCategoryList(transformedUb, 'sortedOngoingMeetList', this.ongoingMeetCompareFn)
                }
                this.sortedPendingMeetList.splice(existingUBObj.index, 1)
              }
            }
          } else if (existingUBObj.category === 'sortedOngoingMeetList') {
            let isOGMeetDeleted = false
            const session = ObjectUtils.getByPath(transformedUb, 'sessions.0.session', {})
            if (transformedUb.is_deleted || session.session_status === SessionStatus.SESSION_ENDED) {
              isOGMeetDeleted = true
            }
            this.modifyCategoryList(transformedUb, 'sortedOngoingMeetList', this.ongoingMeetCompareFn, isOGMeetDeleted)
          }
        } else {
          if (!transformedUb.is_deleted) {
            if (transformedUb.islive) {
              const session = ObjectUtils.getByPath(transformedUb, 'sessions.0.session', {})
              if (session.session_status === SessionStatus.SESSION_ENDED) {
                continue
              } else if (session.session_status === SessionStatus.SESSION_STARTED) {
                this.modifyCategoryList(transformedUb, 'sortedOngoingMeetList', this.ongoingMeetCompareFn)
              } else if (session.session_status === SessionStatus.SESSION_SCHEDULED && transformedUb.status === BoardUserStatus.BOARD_INVITED) {
                isPMListChanged = true
                this.modifyCategoryList(transformedUb, 'sortedPendingMeetList', this.pendingMeetCompareFn)
              }
            } else {
              this.modifyCategoryList(transformedUb, 'sortedUserBoardList', this.userBoardCompareFn)
            }
          }
        }
      }
    }

    const result: any = {}
    result.userBoards = [...this.sortedUserBoardList]
    if (isPMListChanged) {
      result.pendingMeets = [...this.sortedPendingMeetList]
			for (let i = 0; i < this.sortedPendingMeetList.length; i++) {
				const pm = this.sortedPendingMeetList[i]
				this.userBoardMapper[pm.sequence] = {
					category: 'sortedPendingMeetList',
					index: i
				}
			}
    }
    result.ongoingMeets = [...this.sortedOngoingMeetList]
    result.pendingAcdBoard = this.pendingAcdBoard
    result.recentClosedAcd = this.recentClosedAcd

    return result
  }
  
  modifyCategoryList (ub: CUserBoard, category: string, compareFn: Function, isDeleted?: boolean) {
    let needReflow = false
    let index = -1
    const sequence = ub.sequence
    const existingUBObj = this.userBoardMapper[sequence]
    if (existingUBObj && existingUBObj.category === category) {
      index = existingUBObj.index
    }
    if (isDeleted) {
      needReflow = true
      this[category].splice(index, 1)
      delete this.userBoardMapper[ub.sequence]
      this.buildUserBoardMapper(category, index, this[category].length - 1)
    } else {
      if (index >= 0) {
        if (category === 'sortedUserBoardList') {
          this[category].splice(index, 1)
        } else {
          this[category].splice(index, 1, ub)
          return false
        }
      }
      let insertBeforeIndex = -1
      const sortedList = this[category]
      for (let i = 0; i < sortedList.length; i++) {
        const existingUb = sortedList[i] 
        if (compareFn(ub, existingUb)) {
          insertBeforeIndex = i
          break
        }
      }
      if (insertBeforeIndex < 0) {
        needReflow = true
        this[category].push(ub)
        this.userBoardMapper[ub.sequence] = {
          category: category,
          index: this[category].length - 1
        }
      } else {
        needReflow = index !== insertBeforeIndex
        this[category] = [...sortedList.slice(0, insertBeforeIndex), ub, ...sortedList.slice(insertBeforeIndex)]
        if (needReflow) {
          if (index >= 0) {
            this.buildUserBoardMapper(category, Math.min(index, insertBeforeIndex), Math.max(index, insertBeforeIndex))
          } else {
            this.buildUserBoardMapper(category, insertBeforeIndex, this[category].length - 1)
          }
        }
      }
    }
    return needReflow
  }

  buildUserBoardMapper (category: string, start: number, end: number) {
    for (let i = start; i <= end; i++) {
      const sequence = this[category][i].sequence
      this.userBoardMapper[sequence]= {
        category: category,
        index: i
      }
    }
  }

	searchUserBoards(searchKey: string, startIndex: number = 0, pageSize: number = 21): Promise<CUserBoard[]> {

		if (this.searchUserBoardRequest) {
			MxISDK.abortRequest(this.searchUserBoardRequest)
		}

		this.searchUserBoardRequest = this.currentUser.searchUserBoards(searchKey, startIndex, pageSize)

		return this.searchUserBoardRequest.then((user: Defines.User) => {
			if(!user) {
				return []
			}
			let userBoards = user.boards
			return UserFormatter.transformUserBoards(userBoards)
		})
	}

	searchUserBoard(searchKey: string, boardId: string) {
		return this.currentUser.searchBoard(searchKey, 0, 21, boardId)
	}

	findConversationWithUser(id: string): CUserBoard {
		const userBoards = this.currentUser.boards
		const userBoard = userBoards.find(ub => {
			if (!ub.board) {
				return false
			}
			const boardInfo = ub.board
			if (boardInfo.is_relation || boardInfo.isconversation) {
				let members = boardInfo.users
				let memberIndex = members.findIndex(member => {
					return member.user.id === id
				})
				return memberIndex >= 0
			} else {
				return false
			}
		})
		if (userBoard) {
			return UserFormatter.transformUserBoards([userBoard])[0]
		} else {
			return null
		}
	}

	unsubscribeUserMeets(): void {
		this.meetResolveCallback = null
	}

	subscribeUserMeets(resolve: Function): void {
		this.meetResolveCallback = resolve
	}

	subscribeContacts(resolve: Function): void {
		if (!this.userContactsSubscriber) {
			const formattedContacts = UserFormatter.transformContacts(this.currentUser.contacts, false)
			resolve(formattedContacts)
			this.processUserBoardsWhenContactsChange(formattedContacts)
			if (this.suggestedContactResolveCallback) {
        this.suggestedContactResolveCallback(this.findSuggestedContacts(formattedContacts))
			}
			this.userContactsSubscriber = this.currentUser.subscribeUserContacts(async (userContacts: Array<Defines.UserContact>) => {
        const formattedContacts = UserFormatter.transformContacts(userContacts, true)
				resolve(formattedContacts)
				this.processUserBoardsWhenContactsChange(formattedContacts)
				if(this.suggestedContactResolveCallback) {
          this.suggestedContactResolveCallback(this.findSuggestedContacts(formattedContacts))
				}
			})
		}
	}

	findSuggestedContacts (contacts: CContactUser[]) {
		let suggestedContacts = []
		for (let contact of contacts) {
			if (!contact.isInternalUser) {
				if (!this.currentUser.getUserRelationByUserId(contact.id)) {
					suggestedContacts.push(contact)
				}
			}
		}
		return suggestedContacts
	}

	subscribeSuggestContacts(resolve: Function) {
		this.suggestedContactResolveCallback = resolve
	}

	subscribeUserPresence(resolve: Function) {
		if (!this.userPresenceSubscriber) {
			this.currentUser.subscribeUserPresences((presences: Defines.UserPresence[]) => {
				resolve(presences.map(presence => {
					return {
						...presence,
						id: presence.id,
						status: UserFormatter.getUserStatus(presence)
					}
				}))
			})
		}
	}

	startChat(userId: string): Promise<Defines.Board> {
		return new Promise(((resolve, reject) => {
			const create = () => {
				this.currentUser.createBoard({
					isconversation: true,
					use_member_avatar_as_cover: true
				}).then((board: Defines.Board) => {
					this.currentUser.loadBoard(board.id).then((mxBoard: MxBoard) => {
						mxBoard.inviteMember([{
							id: userId
						}]).then((res) => {
							this.currentUser.unloadBoard(board.id);
							resolve(board)
						});
					}).catch(reject);
				}).catch(reject)
			};
			this.currentUser.queryShareBoards([{id: userId}]).then((user: Defines.User) => {
				let boards: Array<Defines.UserBoard> = ObjectUtils.getByPath(user, 'boards', []);
				let userBoard: Defines.UserBoard = find1on1chat(boards, userId);
				if (userBoard) {
					if(userBoard.status === BoardUserStatus.BOARD_INVITED) {
						this.currentUser.joinBoard(ObjectUtils.getByPath(userBoard,'board.id')).then(() => {
							resolve(userBoard.board);
						}).catch(reject)
					} else {
						resolve(userBoard.board);
					}
				} else {
					create()
				}
			}).catch((e) => {
				create()
			})
		}))
	}

	static createRelationWithQRToken(token:string) {
		return MxISDK.getCurrentUser().createRelationWithQRToken(token, true).then(user => {
			return user
		})
	}

	createGroupConversation(name: string, members: Defines.UserIdentity[]) {
		const mxUser = MxISDK.getCurrentUser();
		return new Promise((resolve, reject) => {
			mxUser.createBoard({
				name,
				use_member_avatar_as_cover: true,
			}).then((board: Defines.Board) => {
				if (members && members.length > 0) {
					mxUser.loadBoard(board.id).then((mxBoard: MxBoard) => {
						return mxBoard.inviteMember(members)
					}).then(resolve).catch(reject)
				} else {
					resolve(board)
				}
			}).catch(reject)
		})
	}

	createMockConversation(name: string, member: CGroupUser) {
		const userId = member.id
		
		function checkExistingBinder () {
	      return new Promise((resolve, reject) => {
			const currentUser = MxISDK.getCurrentUser()
			currentUser.queryShareBoards([{id: userId}]).then((user: Defines.User) => {
				let boards: Array<Defines.UserBoard> = ObjectUtils.getByPath(user, 'boards', []);
				let userBoard: Defines.UserBoard = find1on1chat(boards, userId);
				if (userBoard) {
					if(userBoard.status === BoardUserStatus.BOARD_INVITED) {
						currentUser.joinBoard(ObjectUtils.getByPath(userBoard,'board.id')).then(() => {
							resolve(userBoard.board);
						}).catch(reject)
					} else {
						resolve(userBoard.board);
					}
				} else {
					reject()
				}
			}).catch((e) => {
				reject()
			})
		  })
		}
 
		const createMockBoard = () => {
			const mockBoardParam: Defines.MxMockBoardParam = {
				name: name.trim(),
				peerUser: {
					...member,
					id: userId,
					name: name,
					title: member.title,
					avatar: member.avatar,
					isJoined: false,
				} as User,
				isConversation: member.isInternalUser,
				isRelation: !member.isInternalUser,
				suppressInviteFeed: true,
				checkFunc: checkExistingBinder
			}
			return MxISDK.getCurrentUser().createMockBoard(mockBoardParam)
		}
		return new Promise((resolve, reject) => {
			checkExistingBinder()
			  .then(board => {
				resolve(board)
			  })
			  .catch(() => {
			    resolve(createMockBoard())  
			  })
		})


	}

	updateUserBoardAccessTime(boardId: string): Promise<boolean> {
		return new Promise<boolean>(((resolve, reject) => {
			this.currentUser.updateUserBoardAccessTime(boardId).then(() => resolve(true))
				.catch((err) => reject(err))
		}))
	}

	acceptBoard(boardId: string): Promise<boolean> {
		return new Promise<boolean>(((resolve, reject) => {
			this.currentUser.joinBoard(boardId).then(() => resolve(true))
				.catch((err) => reject(err))
		}))
	}

	declineBoard(boardId: string): Promise<boolean> {
		return new Promise<boolean>(((resolve, reject) => {
			this.currentUser.leaveBoard(boardId).then(() => resolve(true))
				.catch((err) => reject(err))
		}))
	}

	updatePendingInvitationRemind(time: number): Promise<boolean> {
		if (!time) {
			// saved value should be second timestemp
			time = parseInt((Date.now() / 1000).toString());
		}
		const TAG_NAME = 'pending_invitation_remind_timestamp';
		let tags = this.currentUser.user.tags;
		let oldSequence;
		if (tags && tags.length >= 1) {
			tags.forEach((tag) => {
				if (tag.name == TAG_NAME)
					oldSequence = tag.sequence
			})
		}

		const usr = {} as User;
		const tag: UserTag = {
			name: TAG_NAME,
			string_value: time.toString()
		};
		if (oldSequence)
			tag.sequence = oldSequence;

		usr.tags = [tag];
		return new Promise<boolean>((resolve, reject) => {
			this.currentUser.updateProfile(usr).then(() => {
				resolve(true)
			}).catch(err => reject(err.message))
		})
	}

	@transformError()
	static createUserWithQRToken(token: string, user: User, isInternalUser?: boolean): Promise<MxUser> {
		return MxISDK.registerWithQRToken(user, token, true, isInternalUser)
	}

	destroy() {
		this.userBoardSubscriber && this.userBoardSubscriber.unsubscribe()
		this.userRelationSubscriber && this.userRelationSubscriber.unsubscribe()
		this.userBasicInfoSubscriber && this.userBasicInfoSubscriber.unsubscribe()
		this.userContactsSubscriber && this.userContactsSubscriber.unsubscribe()
		this.userBasicInfoSubscriber = null
		this.userRelationSubscriber = null
		this.userBoardSubscriber = null
		this.userContactsSubscriber = null
		userController = null
	}

	getQrToken (): Promise<string> {
		const tokens = (this.currentUser.basicInfo.qr_tokens || []).filter(t => t.is_deleted !== true) || []
		const currentToken = ObjectUtils.getByPath(tokens, '0.token', '')

		if (currentToken) {
			return Promise.resolve(currentToken)
		} else {
			return this.currentUser.createQRToken().then((user: Defines.User) => {
				return ObjectUtils.getByPath(user, 'qr_tokens.0.token', '')
			})
		}
	}

	resendVerificationEmail (clientUserId: string): Promise<void> {
		return this.currentUser.resendVerificationEmail(clientUserId)
	}

    @transformError()
    postAuditLog(actionGroupId: string, actionTypeId: string, payload: any, actionBoardId?:string): Promise<void> {
    	payload = payload || {}
        return this.currentUser.postAuditLog(actionGroupId, actionTypeId, actionBoardId || payload.board_id, payload)
	}
	
	@transformError()
	createJWT(binder_id?: string, object?:object): Promise<string> {
		// unique_id or email (required), org_id (required), binder_id (optional)
		let payload: any = {org_id: this.group.group.id}
		if (object) {
			payload = object
		} else {
			if (binder_id) {
				payload.binder_id = binder_id
			}
			let currUser = this.currentUser.user
			if (currUser.unique_id) {
				payload.unique_id = currUser.unique_id
			} else if (currUser.email) {
				payload.email = currUser.email
			} else if (currUser.phone_number) {
				payload.phone_number = currUser.phone_number
			}
		}
		return this.currentUser.createJWT(JSON.stringify(payload))
	}

	@transformError()
	getRelationWithQRToken(qrToken: string): Promise<CUserRelation> {
		let self = this
		return new Promise((resolve, reject) => {
			MxISDK.decodeQRToken(qrToken).then(group => {
				let guser = group.members[0]
				let relation = self.currentUser.relations.find(relation => relation.user.id === guser.user.id)
				if (relation) {
					resolve(UserFormatter.transformUserRelations([relation])[0])
				} else {
					resolve(null)
				}
			}).catch(_ => reject)
		})
	}

	@transformError()
	createPrivateConversation(userId: string): Promise<String> {
		return new Promise((resolve, reject) => {
			let mxUser = MxISDK.getCurrentUser()
			mxUser.queryShareBoards([{id: userId}]).then(board =>{
				resolve(board.id)
			}).catch(err => {
				mxUser.createBoard({
					isconversation: true,
					use_member_avatar_as_cover: true
				}).then((board: Defines.Board) => {
					mxUser.loadBoard(board.id).then((mxBoard: MxBoard) => {
						mxBoard.inviteMember([{
							id: userId
						}]).then((res) => {
							mxUser.unloadBoard(board.id);
							resolve(board.id)
						});
					}).catch(reject);
				}).catch(reject)
			})
		})
	}

	@transformError()
	duplicateConversation(boardId: string, name: string, users: UserIdentity[]) {
		return new Promise((resolve, reject) => {
			let mxUser = MxISDK.getCurrentUser()
			mxUser.duplicateBoard(boardId, name, users).then(board => {
				resolve(board.id)
			}).catch(reject)
		})
  }
  
  @transformError()
  searchMemberBoards(searchKey: string, startIndex: number = 0, pageSize: number = 20): Promise<CUserBoard[]> {

		if (this.searchMemberBoardRequest) {
			MxISDK.abortRequest(this.searchMemberBoardRequest)
    }
    this.searchMemberBoardRequest = this.currentUser.searchUserBoardsByMember(searchKey, startIndex, pageSize)

		return this.searchMemberBoardRequest.then((user: Defines.User) => {
      let userBoards = user && user.boards
			if(userBoards) {
        return UserFormatter.transformUserBoards(userBoards)
			}
      return []
		})
	}

	subscribeActionItems(cb: Function) {
		if (!this.userActionItemsSubscriber) {
			const actionItems = this.currentUser.actionItems
			const ret = UserFormatter.actionItemsPreprocess(actionItems)
			if (ret.syncEnableTimeItems.length) {
				this.updateActionItemEnableTime(ret.syncEnableTimeItems)
			}
			cb(ret.actionItems)
			this.userActionItemsSubscriber = this.currentUser.subscribeActionItems(userBoards => {
				const preproRet = UserFormatter.actionItemsPreprocess(userBoards)
				if (preproRet.syncEnableTimeItems.length) {
					this.updateActionItemEnableTime(preproRet.syncEnableTimeItems)
				}
				cb(preproRet.actionItems)
			})
		}
	}

	updateActionItemsAccessTime() {
		return this.currentUser.updateActionItemAccessTime()
	}

	updateActionItemEnableTime(userBoards: Defines.UserBoard[]) {
		return this.currentUser.updateActionItemEnableTime(userBoards)
	}

	isValidActionItem(payload: {binderId: string, sequence: number, type: string}): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const mxUser = this.currentUser
			const {binderId, sequence, type} = payload
			mxUser.loadBoard(binderId).then(mxBoard => {
				let baseObj = null
				if (type === 'TODO') {
					baseObj = mxBoard.getTodoBaseObject(sequence) 
				} else if (type === 'SIGNATURE') {
					baseObj = mxBoard.getSignatureBaseObject(sequence)
				}
				mxBoard.readThread(baseObj).then(thread => {
					mxUser.unloadBoard(binderId)
					if (type === 'TODO' && thread.todos) {
						resolve(true)
					} else if (type === 'SIGNATURE' && thread.signatures) {
						resolve(true)
					} else {
						resolve(false)
					}
				}).catch(() => {
					mxUser.unloadBoard(binderId)
					resolve(false)
				})
			}).catch(() => resolve(false))
		})
	}

	updateToolTipViewed () {
		return this.currentUser.createOrUpdateTag("tooltip_view_time", Date.now())
	}

	transformMentionMe (mentions: UserMentionMe[]) {
		return mentions.map((userBoard)=>{
			let board = userBoard.board
			let comment: BoardComment
			let comments: BoardComment[]
			if (userBoard.is_deleted) {
				//to let client side delete
				return userBoard
			}
			if (board.comments) {
				comments = board.comments
			} else if (board.pages) {
				comments = ObjectUtils.getByPath(board.pages, '0.comments')
			} else if (board.todos) {
				comments = ObjectUtils.getByPath(board.todos, '0.comments')
			}
			if (comments) {
				if (comments.length === 2) {
					comment = comments[1]
				} else {
					comment = comments[0]
				}
			}
			let feed: ObjectFeed = board.feeds[0]
			let actor = BoardFormatter.transformBoardActor(feed.actor, board)
			let binderName = BoardFormatter.getBoardName(board, true)
			let {text, rich_text, created_time} = comment
			return {
				text: rich_text || text,
				createdTime: created_time,
				actor,
				binderName,
				binderId: board.id,
				feedSequence: feed.sequence,
				sequence: userBoard.sequence,
				board: userBoard.board
			}
		}).filter((mention:CUserMention)=>{
			let {first_name, last_name} = this.currentUser.basicInfo
			let {board, text} = mention
			let regex = new RegExp(`@${first_name} ${last_name}`, 'gi')
			if (board && (board.is_transaction || board.islive)) {
				return false
			} else {
				return regex.test(text)
			}
		})
	}

	getMentionList (callback: Function) {
    callback(this.transformMentionMe(this.currentUser.mentionmes))
    return this.currentUser.subscribeUserMentionMes((mentions)=>{
			mentions = mentions || []
			callback(this.transformMentionMe(mentions))
		})
	}

	updateUserTag (tag: UserTag) {
		return this.currentUser.updateProfile({tags: [tag]})
	}

	updatePingUserAlertStatus(updateInfo:[any]) {
		const TAG_NAME = 'Ping_User_Alert_Status';
		let tags = this.currentUser.tags;
		let tagsStatus = tags.hasOwnProperty('Ping_User_Alert_Status') ? tags['Ping_User_Alert_Status'] : '';
		let updatedTags = {}
		if(tagsStatus){
			updatedTags = JSON.parse(tagsStatus)
		}

		updateInfo.forEach(item=>{
			let peerId = item['peerId']
			let time = item['time']
			updatedTags[peerId] = JSON.stringify(time)
		})

		return new Promise<boolean>((resolve, reject) => {
			this.currentUser.createOrUpdateTag(TAG_NAME, JSON.stringify(updatedTags)).then(() => {
				resolve(true)
			}).catch(err => reject(err.message))
		})
	}

	@transformError()
	loadBinderCover(boardId:string) {
		return this.currentUser.readBoardBasicInfo(boardId).then((board:Board)=>{
			return BoardFormatter.transformBoardBasicInfo(board)
		})
	}
}
