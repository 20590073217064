import { TelephoneNumber } from './../proto/generated/TelephoneNumber';
import { TelephonyDomain } from './../proto/generated/TelephonyDomain';
import { Partner } from './../proto/generated/Partner';
import { ClientRequestType } from "../proto/generated/ClientRequestType";
import { ClientResponse } from "../proto/generated/ClientResponse";
import { ClientRequest } from '../proto/generated/ClientRequest';
import { Ajax } from "../network/ajax";
import { partnerRequestNode, telephonyDomainRequestNode, groupRequestNode } from "../network/requestNode";
import { Group } from '../api/defines';

function readTelephonyDomain(domainId: string): Promise<ClientResponse> {
    let td: TelephonyDomain = {
        id: domainId
    };
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_READ_TELEPHONY_DOMAIN, td));
}

function createTelephonyDomain(partnerId: string, td: TelephonyDomain): Promise<ClientResponse> {
    let req: ClientRequest = {
        type: ClientRequestType.PARTNER_REQUEST_CREATE_TELEPHONY_DOMAIN, 
        object: {
            partner: {
                id: partnerId
            },
            telephony_domain: td
        }
    };
    return Ajax.sendRequest(req);
}

function updateTelephonyDomain(td: TelephonyDomain): Promise<ClientResponse> {
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_UPDATE_TELEPHONY_DOMAIN, td));
}

function deleteTelephonyDomain(domainId: string): Promise<ClientResponse> {
    let td: TelephonyDomain = {
        id: domainId,
        is_deleted: true
    };
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_DELETE_TELEPHONY_DOMAIN, td));
}

function setGlobalDefaultTelephonyDomain(tds: number): Promise<ClientResponse> {
    let partner: Partner = {
        partner_telephony_domains: [{
            sequence: tds,
            is_default: true
        }]
    };
    return Ajax.sendRequest(partnerRequestNode(ClientRequestType.PARTNER_REQUEST_SET_DEFAULT_TELEPHONY_DOMAIN, partner));
}

function assignTelephonyDomain(groupId: string, domainId: string): Promise<ClientResponse> {
    let group: Group = {
        id: groupId,
        group_telephony_domain: {
            telephony_domain: {
                id: domainId
            }
        }
    }
    return Ajax.sendRequest(groupRequestNode(ClientRequestType.GROUP_REQUEST_ASSIGN_TELEPHONY_DOMAIN, group));
}

function createTelephoneNumber(domainId: string, tn: TelephoneNumber): Promise<ClientResponse> {
    let td: TelephonyDomain = {
        id: domainId,
        numbers: [tn]
    };
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_CREATE_TELEPHONE_NUMBER, td));
}

function updateTelephoneNumber(domainId: string, tn: TelephoneNumber): Promise<ClientResponse> {
    let td: TelephonyDomain = {
        id: domainId,
        numbers: [tn]
    };
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_UPDATE_TELEPHONE_NUMBER, td));
}

function deleteTelephoneNumber(domainId: string, tnSeq: number): Promise<ClientResponse> {
    let td: TelephonyDomain = {
        id: domainId,
        numbers: [{
            sequence: tnSeq,
            is_deleted: true
        }]
    };
    return Ajax.sendRequest(telephonyDomainRequestNode(ClientRequestType.PARTNER_REQUEST_DELETE_TELEPHONE_NUMBER, td));
}

export {
    readTelephonyDomain,
    createTelephonyDomain,
    updateTelephonyDomain,
    deleteTelephonyDomain,
    setGlobalDefaultTelephonyDomain,
    assignTelephonyDomain,
    createTelephoneNumber,
    updateTelephoneNumber,
    deleteTelephoneNumber,
} 