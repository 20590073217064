
export interface MxStorage {
  getItem(key: string): string;
  setItem(key: string, val: string): void;
  getObject(key: string): Object;
  setObject(key: string, val: Object): void;
  removeItem(key: string): void;
  clear(): void;
}

class MxEmptyStorage implements MxStorage {
  getItem(key: string): string {
    return '';
  }  
  setItem(key: string, val: string): void {
  }

  getObject(key: string): Object {
    return null;
  } 
  
  setObject(key: string, val: Object): void {
  } 

  removeItem(key: string): void {
  }

  clear(): void {
  }
}

class MxSessionStorage implements MxStorage {
  getItem(key: string): string {
    return sessionStorage.getItem(key)
  }  
  
  setItem(key: string, val: string): void {
    return sessionStorage.setItem(key, val);
  }

  getObject(key: string): Object {
    let data: string = this.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } 
  
  setObject(key: string, val: Object): void {
    if (val) {
      this.setItem(key, JSON.stringify(val));
    }
  } 

  removeItem(key: string): void {
    return sessionStorage.removeItem(key);
  }

  clear(): void {
    return sessionStorage.clear();
  }
}

class MxLocalStorage implements MxStorage {
  getItem(key: string): string {
    return localStorage.getItem(key)
  }  
  
  setItem(key: string, val: string): void {
    return localStorage.setItem(key, val);
  }

  getObject(key: string): Object {
    let data: string = this.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  } 
  
  setObject(key: string, val: Object): void {
    if (val) {
      this.setItem(key, JSON.stringify(val));
    }
  } 

  removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  clear(): void {
    return localStorage.clear();
  }
}

// todo
// class MxDBStorage implements MxStorage {

// }

export const mxSessionStorage: MxStorage = window.sessionStorage ? new MxSessionStorage() : new MxEmptyStorage();
export const mxLocalStorage: MxStorage =  window.localStorage ? new MxLocalStorage() : new MxEmptyStorage();