import { Board } from "../proto/generated/Board";
import { BoardComment } from './../proto/generated/BoardComment';
import { ClientRequestType } from '../proto/generated/ClientRequestType';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { ClientParam } from '../proto/generated/ClientParam';
import { ClientRequestParameter } from '../proto/generated/ClientRequestParameter';
import { MxBaseObject, MxBaseObjectType } from "../api/defines";
import { MxErr } from "../core/error";
import { spathToObject } from "../util";
import { Ajax } from '../network/ajax';
import { boardRequestNode } from '../network/requestNode';


function readThread(boardId: string, baseObject: MxBaseObject): Promise<ClientResponse> {
    let params: ClientParam[] = [{
        "name": ClientRequestParameter.BOARD_REQUEST_READ_SIZE_AFTER,
        "uint64_value": 1000
      },{
        "name": ClientRequestParameter.BOARD_REQUEST_READ_SIZE_BEFORE,
        "uint64_value": 1000
      } ];
  
    let board: Board = spathToObject(baseObject.spath, boardId) as Board;  
    return Ajax.sendRequest(boardRequestNode(ClientRequestType.BOARD_REQUEST_READ_THREAD, board, params));
  }
  

function createThreadComment(boardId: string, obj: MxBaseObject, comment: BoardComment): Promise<ClientResponse> {
    let reqType: ClientRequestType;
    let board: Board = spathToObject(obj.spath, boardId) as Board;

    switch (obj.type) {
        case MxBaseObjectType.COMMENT:
            if (board.comments && board.comments.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_CREATE_COMMENT;
                comment.original_comment = obj.sequence;
                board.comments = [comment];                
            }
            break;
        case MxBaseObjectType.TODO:
            if (board.todos && board.todos.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_CREATE_TODO_COMMENT;
                board.todos[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.PAGE:
                if (board.pages && board.pages.length > 0) {
                    reqType = ClientRequestType.BOARD_REQUEST_CREATE_PAGE_COMMENT;
                    board.pages[0].comments = [comment];                
                }
                break;
        case MxBaseObjectType.POSITION_COMMENT:
                if (board.pages && board.pages.length > 0 && board.pages[0].comments && board.pages[0].comments.length > 0) {
                    reqType = ClientRequestType.BOARD_REQUEST_CREATE_PAGE_POSITION_COMMENT;
                    comment.original_comment = obj.sequence;
                    board.pages[0].comments = [comment];                
                }
                break;
        case MxBaseObjectType.FILE:
                if (board.page_groups || board.folders) {
                    reqType = ClientRequestType.FILE_FLOW_REQUEST_COMMENT_CREATE;
                    board.comments = [comment];                
                }
                break;
        case MxBaseObjectType.SIGNATURE:
                if (board.signatures && board.signatures.length > 0) {
                    reqType = ClientRequestType.SIGN_FLOW_REQUEST_COMMENT_CREATE;
                    comment.original_signature = obj.sequence;
                    board.comments = [comment];                
                }
                break;
        case MxBaseObjectType.TRANSACTION:
                if (board.transactions && board.transactions.length > 0) {
                    reqType = ClientRequestType.BOARD_REQUEST_TRANSACTION_COMMENT_CREATE;
                    comment.original_transaction = obj.sequence;
                    board.comments = [comment];                
                }
                break;
        case MxBaseObjectType.MEET:
                if (board.sessions && board.sessions.length > 0) {
                    reqType = ClientRequestType.SESSION_FLOW_REQUEST_COMMENT_CREATE;
                    comment.original_session = obj.sequence;
                    board.comments = [comment];                
                }
                break;
    }

    if (!reqType) {
        return Promise.reject(MxErr.InvalidParam());
    }
    
    return Ajax.sendRequest(boardRequestNode(reqType, board));
}


function updateThreadComment(boardId: string, obj: MxBaseObject, commentSequence: number, comment2: BoardComment): Promise<ClientResponse> {
    let reqType: ClientRequestType;
    let board: Board = spathToObject(obj.spath, boardId) as Board;
    let comment: BoardComment = {
        ...comment2,
        sequence: commentSequence,
        is_modified: true,
    }

    switch (obj.type) {
        case MxBaseObjectType.COMMENT:
            if (board.comments && board.comments.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_UPDATE_COMMENT;
                board.comments = [comment];                
            }
            break;
        case MxBaseObjectType.TODO:
            if (board.todos && board.todos.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_UPDATE_TODO_COMMENT;
                board.todos[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.PAGE:
            if (board.pages && board.pages.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_UPDATE_PAGE_COMMENT;
                board.pages[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.POSITION_COMMENT:
            if (board.pages && board.pages.length > 0 && board.pages[0].comments && board.pages[0].comments.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_UPDATE_PAGE_POSITION_COMMENT;
                board.pages[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.FILE:
            reqType = ClientRequestType.FILE_FLOW_REQUEST_COMMENT_UPDATE;
            board.comments = [comment]; 
            break;
        case MxBaseObjectType.SIGNATURE:
            reqType = ClientRequestType.SIGN_FLOW_REQUEST_COMMENT_UPDATE;
            board.comments = [comment];
            break;
        case MxBaseObjectType.TRANSACTION:
            reqType = ClientRequestType.BOARD_REQUEST_TRANSACTION_COMMENT_UPDATE;
            board.comments = [comment];    
            break;
        case MxBaseObjectType.MEET:
            reqType = ClientRequestType.SESSION_FLOW_REQUEST_COMMENT_UPDATE;
            board.comments = [comment];     
            break;
    }

    if (!reqType) {
        return Promise.reject(MxErr.InvalidParam());
    }
    
    return Ajax.sendRequest(boardRequestNode(reqType, board));
}

function deleteThreadComment(boardId: string, obj: MxBaseObject, commentSequence: number): Promise<ClientResponse> {
    let reqType: ClientRequestType;
    let board: Board = spathToObject(obj.spath, boardId) as Board;
    let comment: BoardComment = {
        sequence: commentSequence,
        is_deleted: true,
    }

    switch (obj.type) {
        case MxBaseObjectType.COMMENT:
            if (board.comments && board.comments.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_DELETE_COMMENT;
                board.comments = [comment];                
            }
            break;
        case MxBaseObjectType.TODO:
            if (board.todos && board.todos.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_DELETE_TODO_COMMENT;
                board.todos[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.PAGE:
            if (board.pages && board.pages.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_DELETE_PAGE_COMMENT;
                board.pages[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.POSITION_COMMENT:
            if (board.pages && board.pages.length > 0 && board.pages[0].comments && board.pages[0].comments.length > 0) {
                reqType = ClientRequestType.BOARD_REQUEST_DELETE_PAGE_POSITION_COMMENT;
                board.pages[0].comments = [comment];                
            }
            break;
        case MxBaseObjectType.FILE:
            reqType = ClientRequestType.FILE_FLOW_REQUEST_COMMENT_DELETE;
            board.comments = [comment]; 
            break;
        case MxBaseObjectType.SIGNATURE:
            reqType = ClientRequestType.SIGN_FLOW_REQUEST_COMMENT_DELETE;
            board.comments = [comment];
            break;
        case MxBaseObjectType.TRANSACTION:
            reqType = ClientRequestType.BOARD_REQUEST_TRANSACTION_COMMENT_DELETE;
            board.comments = [comment];    
            break;
        case MxBaseObjectType.MEET:
            reqType = ClientRequestType.SESSION_FLOW_REQUEST_COMMENT_DELETE;
            board.comments = [comment];     
            break;
    }

    if (!reqType) {
        return Promise.reject(MxErr.InvalidParam());
    }
    
    return Ajax.sendRequest(boardRequestNode(reqType, board));
}

export {
    readThread,
    createThreadComment,
    updateThreadComment,
    deleteThreadComment,
}