import {ComponentOption} from "../index"
import {BankMsgSummary, BankMsgSummaryMeta} from "./BankMsgSummary"
import {BankMsgDetails, BankMsgDetailsMeta} from "./BankMsgDetails"
import {MxComponent} from "./MxComponent"
export function createComponentByOption(option: ComponentOption): MxComponent {
    switch (option.componentId) {
        case BankMsgSummaryMeta.id:
            return new BankMsgSummary(option)
        case BankMsgDetailsMeta.id:
            return new BankMsgDetails(option)
        default:
            return null
    }
}
