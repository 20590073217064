import { MxISDK } from 'isdk';
import { MxObjectFactory } from './mxObjectFactory';
import { UserCache } from './../data/cache/userCache';
import { User } from '../proto/generated/User';
import { UserCap } from '../proto/generated/UserCap';
import { UserBoard } from '../proto/generated/UserBoard';
import { UserRelation } from '../proto/generated/UserRelation';
import { UserContact } from '../proto/generated/UserContact';
import { UserFavorite } from '../proto/generated/UserFavorite';
import { UserMentionMe } from '../proto/generated/UserMentionMe';
import { Partner } from '../proto/generated/Partner';
import { Board } from '../proto/generated/Board';
import { Contacts } from '../proto/generated/Contacts';
import { CacheMessage } from '../proto/generated/CacheMessage';
import { BoardRoutingStatus } from '../proto/generated/BoardRoutingStatus';
import { ClientResponse } from '../proto/generated/ClientResponse';
import { SocialType } from '../proto/generated/SocialType';
import { WebAppType } from './../proto/generated/WebAppType';
import { WebApp } from '../proto/generated/WebApp';
import { UserRole } from '../proto/generated/UserRole';
import { CacheObject } from '../proto/generated/CacheObject';
import { ActionObject } from '../proto/generated/ActionObject';
import { UserBroadcast } from '../proto/generated/UserBroadcast';
import { RSVPReply } from '../proto/generated/RSVPReply';
import { getAllTags, getTagByKey, filterDeleted } from '../data/cache/common';
import { getByPath, mxLogger } from '../util';
import { sdkConfig, getContextPath } from './config';
import { MxErr } from './error';
import { Connection } from './../network/connection';
import { MxCallback, MxSubscription, UserPresence, UserIdentity, MxMeetParam, MxMockBoardParam, MOCK_BOARD_PREFIX, MxViewBoardAsParam, MxRoutingChannelType, MxMyAccountSearchFilter, MxGlobalSearchCategory, MxGlobalSearchOption, MxMyAccountSearchOption } from '../api/defines';
import { MxUserSubscriber } from '../data/subscribe/userSubscriber';
import { MxUser } from '../api/mxUser';
import { MxBoard } from '../api/mxBoard';
import { MxMeet } from '../api/mxMeet';
import { MxAdmin } from '../api/mxAdmin';
import { MxAdminImpl } from './mxAdminImpl';
import * as bauth from '../biz/auth';
import * as buser from '../biz/user';
import * as bboard from '../biz/board';
import * as bmeet from '../biz/meet';
import * as btag from '../biz/tag';
import * as baudit from '../biz/audit';
import * as bpresence from '../biz/presence';
import * as brelation from '../biz/relation';
import * as bacdsr from '../biz/acdsr';
import * as bpartner from '../biz/partner';
import * as bwebapp from '../biz/webapp';
import * as cacheMgr from '../data/cache/cacheMgr';

export class MxUserImpl implements MxUser {
    private _userCache: UserCache;
    private _userSubscriber: MxUserSubscriber;
    private _boardReferences: Map<string, number>;
    private _loadBoardPromises: Map<string, Promise<any>>;
    private _isUserBoardsInit: boolean;
    private _adminHandler: MxAdmin;

    constructor(userCache: UserCache){
        this._userCache = userCache;
        this._userSubscriber = new MxUserSubscriber(userCache);
        this._boardReferences = new Map();
        this._loadBoardPromises = new Map();
        this._isUserBoardsInit = sdkConfig.isMeetSdk ? false: true;
    }

    get id(): string {
        return this._userCache.user.id;
    }

    get user(): User {
        return this._userCache.user;
    }

    get basicInfo(): User {
        return this._userCache.basicInfo();
    }

    get cap() : UserCap {
        return this._userCache.user.cap;
    }

    get tags(): Object {
        return getAllTags(this.user.tags);
    }

    get boards(): UserBoard[] {
        return (this.user.boards || []).filter(filterDeleted);
    }

    get mentionMes(): UserMentionMe[] {
        return (this.user.mentionmes || []).filter(filterDeleted);
    }

    get actionItems(): UserBoard[] {
        return (this.user.action_items || []).filter(filterDeleted);
    }

    get relations(): UserRelation[] {
        return (this.user.relations || []).filter(filterDeleted);
    }

    get contacts(): UserContact[] {
        return (this.user.contacts || []).filter(filterDeleted);
    }

    get favorites(): UserFavorite[] {
        return (this.user.favorites || []).filter(filterDeleted);
    }

    get mentionmes(): UserMentionMe[] {
        return (this.user.mentionmes || []).filter(filterDeleted);
    }

    get broadcasts(): UserBroadcast[] {
        return (this.user.broadcasts || []).filter(filterDeleted);
    }

    get isAnonymousUser(): boolean {
        return false;
    }
    
    onUserUpdated(user?: User, contacts?: Contacts, notification?: CacheMessage): void {
        this._userCache.onObjectUpdate(user, contacts);
        this._userSubscriber.onObjectUpdate(user, contacts, notification);
    }

    subscribeUserBasicInfo(cb: MxCallback<User>): MxSubscription {
        return this._userSubscriber.subscribeUserBasicInfo(cb);
    }    
    
    subscribeUserBoards(cb: MxCallback<UserBoard[]>): MxSubscription {
        return this._userSubscriber.subscribeUserBoards(cb);
    }

    subscribeActionItems(cb: MxCallback<UserBoard[]>): MxSubscription {
        return this._userSubscriber.subscribeActionItems(cb);
    }

    subscribeUserRelations(cb: MxCallback<UserRelation[]>): MxSubscription {
        return this._userSubscriber.subscribeUserRelations(cb);
    }

    subscribeUserContacts(cb: MxCallback<UserContact[]>): MxSubscription {
        return this._userSubscriber.subscribeUserContacts(cb);
    }

    subscribeUserFavorites(cb: MxCallback<UserFavorite[]>): MxSubscription {
        return this._userSubscriber.subscribeUserFavorites(cb);
    }

    subscribeUserMentionMes(cb: MxCallback<UserMentionMe[]>): MxSubscription {
        return this._userSubscriber.subscribeUserMentionMes(cb);
    }

    subscribeUserPresences(cb: MxCallback<UserPresence[]>): MxSubscription {
        return this._userSubscriber.subscribeUserPresences(cb);
    }

    subscribeUserBroadcasts(cb: MxCallback<UserBroadcast[]>): MxSubscription {
        return this._userSubscriber.subscribeUserBroadcasts(cb);
    }

    subscribeUserNotification(cb: MxCallback<CacheMessage>): MxSubscription {
        return this._userSubscriber.subscribeUserNotification(cb);
    }

    getUserBoardByBoardId(boardId: string): UserBoard {
        return this._userCache.getUserBoardByBoardId(boardId);
    }

    getUserBoardByRoutingChannel(channelSeq: number): UserBoard {
        return this._userCache.getUserBoardByRoutingChannel(channelSeq);
    }

    getUserRelationByUserId(userId: string): UserRelation {
        return this._userCache.getUserRelationByUserId(userId);
    }

    getUserContactByUserId(userId: string): UserContact {
        return this._userCache.getUserContactByUserId(userId);
    }

    updateProfile(user: User): Promise<User> {
        return buser.updateProfile(user).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    updateActionItemAccessTime(): Promise<User> {
        return buser.updateActionItemAccessTime().then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    updateActionItemEnableTime(actionItems: UserBoard[]): Promise<User> {
        return buser.updateActionItemEnableTime(actionItems).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    removeAvatar(): Promise<void> {
        return buser.removeAvatar().then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    getCacheBoard(boardId: string): MxBoard {
        return cacheMgr.getBoardById(boardId);
    }

    readBoardBasicInfo(boardId: string): Promise<Board> {
        return bboard.readCover(boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        })
    }

    readMeetBasicInfo(meetId: string, boardId?: string): Promise<CacheObject> {
        return bmeet.readMeetBoard(meetId, boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object');
        })
    }

    loadBoard(boardId: string, viewAs?: MxViewBoardAsParam): Promise<MxBoard> {
        mxLogger.info('loadBoard:', boardId);
        this._boardReferences.set(boardId, this._boardReferences.has(boardId) ? this._boardReferences.get(boardId) + 1 : 1);

        if (boardId.startsWith(MOCK_BOARD_PREFIX)) {
            return Promise.resolve(cacheMgr.getBoardById(boardId));
        }

        let mxBoard: MxBoard = cacheMgr.getBoardById(boardId);
        if (mxBoard) {
            return Promise.resolve(mxBoard);
        }

        let p = bboard.readCover(boardId, viewAs);
        this._loadBoardPromises.set(boardId, p);

        return p.then((response: ClientResponse) => {
            let board: Board = getByPath(response, 'object.board');
            let mxBoard = MxObjectFactory.createMxBoard(board);
            mxBoard.setViewBoardAs(viewAs);
            cacheMgr.onBoardCreated(board.id, mxBoard);
            return mxBoard;
        }).catch(e => {
            return Promise.reject(e);
        });
    }

    unloadBoard(boardId: string): Promise<void> {
        mxLogger.info('unloadBoard: ' + boardId + ', ref: ' + (this._boardReferences.get(boardId) ? this._boardReferences.get(boardId) : 0));
        if (this._boardReferences.has(boardId) && this._boardReferences.get(boardId) > 1) {
            this._boardReferences.set(boardId, this._boardReferences.get(boardId) - 1);
            return Promise.resolve();
        }

        if (this._loadBoardPromises.has(boardId)) {
            MxISDK.abortRequest(this._loadBoardPromises.get(boardId));
            this._loadBoardPromises.delete(boardId);
        }

        this._boardReferences.delete(boardId);

        if (boardId.startsWith(MOCK_BOARD_PREFIX)) {
            cacheMgr.onBoardDeleted(boardId);
            return Promise.resolve();
        }

        let mxBoard = cacheMgr.getBoardById(boardId);
        if (mxBoard) {
            mxBoard.abortAllPendingRequests();

            cacheMgr.onBoardDeleted(boardId);
            if (mxBoard.isSubscribed) {
                bboard.unsubscribeBoard(boardId).then(response => {}, e => {});
            }
        }

        return Promise.resolve();
    }

    loadMeetBoard(meetId: string, boardId?: string): Promise<MxBoard> {
        mxLogger.info('loadMeetBoard meetId:', meetId, 'boardId:', boardId);
        let sessionKey: string = boardId || meetId;
        this._boardReferences.set(sessionKey, this._boardReferences.has(sessionKey) ? this._boardReferences.get(sessionKey) + 1 : 1);

        let mxBoard: MxBoard = cacheMgr.getBoardById(sessionKey);
        if (mxBoard) {
            return Promise.resolve(mxBoard);
        }

        let p = bmeet.readMeetBoard(meetId, boardId);
        return p.then((response: ClientResponse) => {
            let mxBoard = MxObjectFactory.createMxBoard(response.object.board);
            cacheMgr.onMeetBoardCreated(sessionKey, mxBoard);
            return mxBoard;
        }).catch(e => {
            return Promise.reject(e);
        });
    }

    unloadMeetBoard(meetId: string, boardId?: string): Promise<void> {
        mxLogger.info('unloadMeetBoard meetId:', meetId, 'boardId:', boardId);
        let sessionKey: string = boardId || meetId;
        if (this._boardReferences.has(sessionKey) && this._boardReferences.get(sessionKey) > 1) {
            this._boardReferences.set(sessionKey, this._boardReferences.get(sessionKey) - 1);
            return Promise.resolve();
        }

        this._boardReferences.delete(sessionKey);

        let mxBoard = cacheMgr.getBoardById(sessionKey);
        if (mxBoard) {
            if (mxBoard.isSubscribed) {
                bboard.unsubscribeBoard(mxBoard.id).then(response => {}, e => {});
            }
            cacheMgr.onMeetBoardDeleted(sessionKey);
        }

        return Promise.resolve();
    }
    
    loadMeetObject(meetId: string): Promise<MxMeet> {
        mxLogger.info('loadMeetObject meetId:', meetId);
        const meetKey = meetId + '_obj'
        let mxMeet: MxMeet = cacheMgr.getMeetById(meetKey);
        if (mxMeet) {
            return Promise.resolve(mxMeet);
        }

        let p = bmeet.readMeetBoard(meetId);
        return p.then((response: ClientResponse) => {
            let board: Board = getByPath(response, 'object.board');
            let session: ActionObject = getByPath(response, 'object.session', {session_key: meetId});
            let mxMeet = MxObjectFactory.createMxMeet(board, session);

            cacheMgr.onMeetCreated(meetId, mxMeet);
            return mxMeet;
        }).catch(e => {
            return Promise.reject(e);
        });
    }

    unloadMeetObject(meetId: string): Promise<void> {
        mxLogger.info('unloadMeetObject meetId:', meetId);
        const meetKey = meetId + '_obj'
        let mxMeet = cacheMgr.getMeetById(meetKey);
        if (mxMeet) {
            if (mxMeet.isSubscribed) {
                Connection.getInstance().unsubscribeMeet(meetId).then(response => {}, e => {});
            }
            cacheMgr.onMeetDeleted(meetId);
        }

        return Promise.resolve();
    }

    createTempBoard(board: Board): Promise<Board> {
        board.istemp = true;
        return bboard.createBoard(board).then((response: ClientResponse) => {
            let board: Board = getByPath(response, 'object.board');
            let mxBoard = MxObjectFactory.createMxBoard(board);
            cacheMgr.onBoardCreated(board.id, mxBoard);
            return board;
        });
    }

    convertTempBoardToNormalBoard(boardId: string): Promise<Board> {
        return bboard.convertTempBoardToNormalBoard(boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    createBoard(board: Board): Promise<Board> {
        return bboard.createBoard(board).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteBoard(boardId: string): Promise<void> {
        return bboard.deleteBoard(boardId).then((response: ClientResponse) => {
            cacheMgr.onBoardDeleted(boardId);
            return Promise.resolve();
        });
    }

    joinBoard(boardId: string, noFeed?: boolean): Promise<Board> {
        return bboard.joinBoard(boardId, noFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    leaveBoard(boardId: string): Promise<Board> {
        return bboard.leaveBoard(boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    getInstantBoard(boardId: string): MxBoard {
        let board: Board = {
            id: boardId
        }
        let mxBoard = MxObjectFactory.createMxBoard(board);
        return mxBoard;
    }
    
    createMockBoard(param: MxMockBoardParam): MxBoard {
        let mxBoard = MxObjectFactory.createMxMockBoard(param);
        cacheMgr.onBoardCreated(mxBoard.id, mxBoard, param);
        return mxBoard;
    }

    deleteMockBoard(boardId: string): void {
        cacheMgr.onBoardDeleted(boardId);
    }

    updateUserBoardAccessTime(boardId: string, keepUnreadFeedTime?: boolean): Promise<User> {
        return buser.updateUserBoardAccessTime(boardId, keepUnreadFeedTime).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    duplicateBoard(fromBoardId: string, newBoardName: string, members?: UserIdentity[]): Promise<Board> {
        return buser.duplicateBoard(fromBoardId, newBoardName).then((response: ClientResponse) => {
            let boardId = getByPath(response, 'object.board.id');
            if (members && members.length > 0) {
                return bboard.inviteUser(boardId, members);
            }else {
                return response;
            }
        }).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }


    createRelation(user: User, suppressFeed?: boolean, welcomeMessage?: string): Promise<User> {
        return brelation.createRelation(user, suppressFeed, welcomeMessage).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    confirmRelation(relationSequence: number, welcomeMessage?: string, resendEmail?: boolean, resendSms?: boolean, suppressFeed?: boolean): Promise<User> {
        return brelation.confirmRelation(relationSequence, welcomeMessage, resendEmail, resendSms, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    scheduleMeet(param: MxMeetParam): Promise<Board> {
        return bmeet.scheduleMeet(param).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    updateMeet(meetBoardId: string, sessionSequence :number, param: MxMeetParam): Promise<Board> {
        return bmeet.updateMeet(meetBoardId, sessionSequence, param).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    deleteMeet(meetBoardId: string): Promise<void> {
        return bmeet.deleteMeet(meetBoardId).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    queryMeets(fromTime: number, toTime: number): Promise<User> {
        return bmeet.readSessionsByTime(fromTime, toTime).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    updateRSVPStatus(meetBoardId: string, reply: RSVPReply): Promise<Board> {
        return bmeet.updateRSVPStatus(meetBoardId, reply).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    queryShareBoards(users: UserIdentity[]): Promise<User> {
        return buser.queryShareBoards(users).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    loadUserBoards(): Promise<User> {
        // for meet SDK, by default we do not subscribe user boards on web-socket connection
        if (this._isUserBoardsInit && this.user.boards) {
            return Promise.resolve({boards: this.user.boards});
        }

        return buser.readAllUserBoards().then((response: ClientResponse) => {
            this._isUserBoardsInit = true;
            let user: User = getByPath(response, 'object.user');
            this._userCache.setUserBoards(user.boards);
            this._userSubscriber.onObjectUpdate(user);
            return user;
        })
    }

    readUserBoards(boardIds: string[]): Promise<User> {
        let userBoards: UserBoard[] = [];
        boardIds.forEach(boardId => {
            if (this._userCache.getUserBoardByBoardId(boardId)) {
                userBoards.push(this._userCache.getUserBoardByBoardId(boardId));
            }
        })

        if (userBoards.length === boardIds.length) {
            // all user boards found in cache?
            return Promise.resolve({
                boards: userBoards
            });
        }

        return buser.readUserBoards(boardIds).then((response: ClientResponse) => {
            let userBoards: UserBoard[] = getByPath(response, 'object.user.boards', []);
            this._userCache.addCacheUserBoards(userBoards);
            return {boards: userBoards};
        })
    }

    searchUserBoards(searchKey: string, startIndex: number, pageSize: number): Promise<User> {
        let oldP = buser.searchUserBoards(searchKey, startIndex, pageSize);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                resolve(getByPath(response, 'object.user'));
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    searchUserBoardsByMember(searchKey: string, startIndex: number, pageSize: number): Promise<User> {
        let oldP = buser.searchUserBoardsByMember(searchKey, startIndex, pageSize);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                resolve(getByPath(response, 'object.user'));
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    searchBoard(searchKey: string, startIndex: number, pageSize: number, boardId: string): Promise<Board> {
        let oldP = buser.searchUserBoards(searchKey, startIndex, pageSize, boardId);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                resolve(getByPath(response, 'object.board'));
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    searchMyAccount(fromTime: number, toTime: number, startIndex: number, pageSize: number, filter: MxMyAccountSearchFilter, opt?: MxMyAccountSearchOption): Promise<User> {
        let oldP = buser.searchMyAccount(fromTime, toTime, startIndex, pageSize, filter, opt);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                resolve(getByPath(response, 'object.user'));
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    searchGlobal(searchKey: string, fromTime: number, toTime: number, startIndex: number, pageSize: number, category: MxGlobalSearchCategory, opt?: MxGlobalSearchOption): Promise<User> {
        let oldP = buser.searchGlobal(searchKey, fromTime, toTime, startIndex, pageSize, category, opt);
        let newP = new Promise((resolve, reject) => {
            oldP.then((response: ClientResponse) => {
                resolve(getByPath(response, 'object.user'));
            }).catch(e => reject(e));
        });

        newP['id'] = oldP['id'];
        return newP;
    }

    updatePresence(status: number, message: string): Promise<void> {
        return bpresence.updatePresence(status, message).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    setOutOfOffice(startTime: number, endTime: number, message: string): Promise<User> {
        return bpresence.setOutOfOffice(startTime, endTime, message).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    queryUserPresence(users: UserIdentity[]): Promise<UserPresence[]> {
        let presences: UserPresence[] = [];
        for (let i = 0; i < users.length; i++) {
            let p = this._userCache.getUserPresenceByUserId(users[i].id);
            if (p) {
                presences.push(p);
            }else {
                break;
            }
        }

        if (presences.length === users.length) {
            return Promise.resolve(presences);
        }
        
        return bpresence.queryUserPresence(users).then((response: ClientResponse) => {
            return getByPath(response, 'object.contacts.contacts', []);
        });
    }

    changePassword(oldPass: string, newPass: string): Promise<void> {
        return buser.changePassword(oldPass, newPass).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    postAuditLog(actionGroupId: string, actionTypeId: string, actionBoardId: string, payload: Object): Promise<void> {
        return baudit.postAuditLog(cacheMgr.currentOrgId, actionGroupId, actionTypeId, actionBoardId, payload).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    getTag(key: string): string|number {
        return getTagByKey(key, this.user.tags);
    }

    createOrUpdateTag(key: string, val: string | number): Promise<void> {
        return btag.createOrUpdateTag(key, val, this.user.tags, btag.MxTagType.USER, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    deleteTag(key: string): Promise<void> {
        return btag.deleteTag(key, this.user.tags, btag.MxTagType.USER, this.id).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    getAccessToken(): Promise<string> {
        return bauth.getAccessToken().then((response: ClientResponse) => {
            return response.data;
        });
    }

    createQRToken(user?: UserIdentity): Promise<User> {
        return brelation.createQRToken(user).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    createRelationWithQRToken(qrToken: string, noRelationBoard?: boolean, suppressFeed?: boolean): Promise<User> {
        return brelation.createRelationWithQRToken(qrToken, noRelationBoard, suppressFeed).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    resendVerificationEmail(clientUserId?: string): Promise<void> {
        let relationSequence: number = 0;
        if (clientUserId && clientUserId !== this.id) {
            let relation = this._userCache.getUserRelationByUserId(clientUserId);
            if (!relation) {
                return Promise.reject(MxErr.InvalidParam('client user not found'))
            }
            relationSequence = relation.sequence;
        }

        return buser.resendVerificationEmail(relationSequence).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }

    createSocialConnection(type: SocialType, customer: User, resendInvitationCode?: boolean): Promise<User> {
        return brelation.createSocialConnection(type, customer, resendInvitationCode).then((response: ClientResponse) => {
            let boardId =  getByPath(response, 'data.social_binder');
            return this.readUserBoards([boardId]);
        });
    }

    reactivateSocialConnection(type: SocialType, boardId: string): Promise<void> {
        return brelation.reactivateSocialConnection(type, boardId).then((response: ClientResponse) => {
            return Promise.resolve();
        });
    }


    makeUploadSignatureUrl(filename: string): string {
        return `${getContextPath()}/user/upload?name=${encodeURIComponent(filename)}&type=signature`;   
    }

    createRoutingRequest(type: MxRoutingChannelType, name: string, description: string, routingChannel: number, routingStatus?: BoardRoutingStatus, user?: User): Promise<Board> {
        return bacdsr.createRoutingRequest(type, name, description, routingChannel, routingStatus, user).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });
    }

    acceptRoutingRequest(boardId: string): Promise<Board> {
        return bacdsr.acceptRoutingRequest(boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });    
    }

    declineRoutingRequest(boardId: string): Promise<Board> {
        return bacdsr.declineRoutingRequest(boardId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });  
    }

    updateRoutingRequestStatus(boardId: string, status: BoardRoutingStatus, viewAs?: MxViewBoardAsParam): Promise<Board> {
        if(!viewAs){
            let ub: UserBoard = this._userCache.getUserBoardByBoardId(boardId);
            if (!ub) {
                return Promise.reject(MxErr.InvalidParam(`board not found: ${boardId}`));
            }
            let isACD = ub.board.is_acd;

            return bacdsr.updateRoutingRequestStatus(boardId, status, isACD).then((response: ClientResponse) => {
                return getByPath(response, 'object.board');
            });
        } else {
            return bacdsr.updateRoutingRequestStatus(boardId, status, true, viewAs).then((response: ClientResponse) => {
                return getByPath(response, 'object.board');
            });
        }
    }

    dispatchRoutingRequest(boardId: string, agentUserId: string): Promise<Board> {
        return bacdsr.dispatchRoutingRequest(boardId, agentUserId).then((response: ClientResponse) => {
            return getByPath(response, 'object.board');
        });  
    }

    readWebApp(webappId: string): Promise<WebApp> {
        return bwebapp.readWebApp(webappId).then((response: ClientResponse) => {
            return getByPath(response, 'object.webapp');
        });
    }

    listBotWebApp(partnerId?: string): Promise<Partner> {
        return bpartner.listWebApps(partnerId, WebAppType.WEBAPP_TYPE_BOT).then((response: ClientResponse) => {
            return getByPath(response, 'object.partner');
        });
    }

    createBroadcast(broadcast: UserBroadcast): Promise<User> {
        return buser.createBroadcast(broadcast).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    updateBroadcast(broadcastSeq: number, broadcast: UserBroadcast): Promise<User> {
        return buser.updateBroadcast(broadcastSeq, broadcast).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    deleteBroadcast(broadcastSeq: number): Promise<User> {
        return buser.deleteBroadcast(broadcastSeq).then((response: ClientResponse) => {
            return getByPath(response, 'object.user');
        });
    }

    createJWT(payload: string, expireAfter?: number): Promise<string> {
        return buser.createJWT(payload, expireAfter).then((response: ClientResponse) => {
            return response.data;
        });
    }

    getAdminHandler(): MxAdmin {
        let userPartners = (this.user.partners || []).filter((partner) => !partner.is_deleted);
        let userRole: UserRole = this.user.role || UserRole.USER_ROLE_NORMAL;
    
        const SUPER_ADMIN_ROLES: UserRole[] = [
            UserRole.USER_ROLE_SUPERADMIN,
            UserRole.USER_ROLE_OBJECT_READ,
            UserRole.USER_ROLE_OBJECT_WRITE,
            UserRole.USER_ROLE_SUPERADMIN_READONLY,
          ];
    
        let isSuperAdmin: boolean = (SUPER_ADMIN_ROLES.indexOf(userRole) > -1) ? true : false;
        let isPartnerAdmin: boolean = userPartners.length > 0 ? true : false;

        if (!isSuperAdmin && !isPartnerAdmin) {
            return null;
        }

        if (!this._adminHandler) {
            this._adminHandler = new MxAdminImpl();
        }
        return this._adminHandler;
    }

}