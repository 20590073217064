import { UsageStatistics } from "../proto/generated/UsageStatistics";
import { WebApp } from "../proto/generated/WebApp";
import { Group } from "../proto/generated/Group";
import { GroupIntegration } from "../proto/generated/GroupIntegration";
import { TelephoneNumber } from "../proto/generated/TelephoneNumber";
import { SystemConfig } from "../proto/generated/SystemConfig";
import { Partner } from "../proto/generated/Partner";
import { PartnerPlanCode } from "../proto/generated/PartnerPlanCode";
import { WebAppType } from "../proto/generated/WebAppType";
import { TelephonyDomain } from "../proto/generated/TelephonyDomain";
import { User } from "../proto/generated/User";
import { CacheObject } from "../proto/generated/CacheObject";

import { MxAdmin } from "../api/mxAdmin";
import * as bgroup from '../biz/group';
import * as bpartner from '../biz/partner';
import * as bwebapp from '../biz/webapp';
import * as bsystem from '../biz/system';
import * as btelephony from '../biz/telephony';
import * as baudit from '../biz/audit';

import { getByPath } from "../util";
import { UserIdentity } from "../api/defines";


export class MxAdminImpl implements MxAdmin
{
    enableUser(userId: string): Promise<User> {
        return bsystem.enableUser(userId).then(response => {
            return getByPath(response, 'object.user')
        })
    }    
    
    disableUser(userId: string): Promise<User> {
        return bsystem.enableUser(userId).then(response => {
            return getByPath(response, 'object.user')
        })
    }

    readUser(user: UserIdentity): Promise<User> {
        return bsystem.readUser(user).then(response => {
            return getByPath(response, 'object.user')
        })
    }

    readSystemConfig(): Promise<SystemConfig> {
        return bsystem.readSystemConfig().then(response => {
            return getByPath(response, 'object.system_config')
        })
    }

    updateSystemConfig(config: SystemConfig): Promise<SystemConfig> {
        return bsystem.updateSystemConfig(config).then(response => {
            return getByPath(response, 'object.system_config')
        })
    }

    listPartners(startIndex: number, size: number): Promise<CacheObject> {
        return bpartner.listPartners(startIndex, size).then(response => {
            return getByPath(response, 'object')
        })
    }

    readPartner(partnerId: string): Promise<Partner> {
        return bpartner.readPartner(partnerId).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    createPartner(partner: Partner): Promise<Partner> {
        return bpartner.createPartner(partner).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    updatePartner(partnerId: string, partner: Partner): Promise<Partner> {
        return bpartner.updatePartner(partnerId, partner).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    readPartnerStatistics(partnerId?: string): Promise<UsageStatistics> {
        return bpartner.readPartnerStatistics(partnerId).then(response => {
            return getByPath(response, 'object.usage')
        })
    }

    readPartnerPlanCodes(partnerId?: string): Promise<Partner> {
        return bpartner.readPartnerPlanCodes(partnerId).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    addPartnerPlanCode(partnerId: string, planCode: PartnerPlanCode): Promise<Partner> {
        return bpartner.addPartnerPlanCode(partnerId, planCode).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    deletePartnerPlanCode(partnerId: string, planCode: PartnerPlanCode): Promise<Partner> {
        return bpartner.deletePartnerPlanCode(partnerId, planCode).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    addPartnerMember(partnerId: string, user: UserIdentity): Promise<Partner> {
        return bpartner.addPartnerMember(partnerId, user).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    deletePartnerMember(partnerId: string, user: UserIdentity): Promise<Partner> {
        return bpartner.deletePartnerMember(partnerId, user).then(response => {
            return getByPath(response, 'object.partner')
        })
    }
    
    listPartnerGroups(partnerId: string, startIndex: number, size: number): Promise<CacheObject> {
        return bpartner.listPartnerGroups(partnerId, startIndex, size).then(response => {
            return getByPath(response, 'object')
        })
    }

    listPartnerWebApps(partnerId: string, filter: WebAppType): Promise<Partner> {
        return bpartner.listWebApps(partnerId, filter).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    listPartnerTelephonyDomains(partnerId?: string): Promise<Partner> {
        return bpartner.listPartnerTelephonyDomains(partnerId).then(response => {
            return getByPath(response, 'object.partner')
        })
    }

    readWebApp(appId: string): Promise<WebApp> {
        return bpartner.listPartnerTelephonyDomains(appId).then(response => {
            return getByPath(response, 'object.webapp')
        })
    }

    createWebApp(partnerId: string, app: WebApp): Promise<WebApp> {
        return bwebapp.createWebApp(partnerId, app).then(response => {
            return getByPath(response, 'object.webapp');
        })
    }

    updateWebApp(appId: string, app: WebApp): Promise<WebApp> {
        return bwebapp.updateWebApp(appId, app).then(response => {
            return getByPath(response, 'object.webapp');
        })
    }

    deleteWebApp(appId: string): Promise<WebApp> {
        return bwebapp.deleteWebApp(appId).then(response => {
            return getByPath(response, 'object.webapp');
        })
    }

    readGroup(groupId: string): Promise<Group> {
        return bgroup.readGroupInfo(groupId).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    createGroup(partnerId: string, group: Group): Promise<Group> {
        return bgroup.createGroup(partnerId, group).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    updateGroup(groupId: string, group: Group): Promise<Group> {
        return bgroup.updateGroup(groupId, group).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    createIntegration(groupId: string, integration: GroupIntegration): Promise<Group> {
        return bgroup.createIntegration(groupId, integration).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    updateIntegration(groupId: string, integration: GroupIntegration): Promise<Group> {
        return bgroup.updateIntegration(groupId, integration.sequence, integration).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    deleteIntegration(groupId: string, integrationSeq: number): Promise<Group> {
        return bgroup.deleteIntegration(groupId, integrationSeq).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    readGroupUsage(groupId: string, timestamp: number): Promise<UsageStatistics> {
        return baudit.readOrgUsage(groupId, timestamp).then(response => {
            return getByPath(response, 'object.usage');
        })
    }

    readGroupCrmReport(groupId: string, timestamp: number): Promise<Group> {
        return baudit.readOrgCrmReport(groupId, timestamp).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    readTelephonyDomain(domainId: string): Promise<TelephonyDomain> {
        return btelephony.readTelephonyDomain(domainId).then(response => {
            return getByPath(response, 'object.telephony_domain');
        })
    }

    createTelephonyDomain(td: TelephonyDomain, partnerId?: string): Promise<Partner> {
        return btelephony.createTelephonyDomain(partnerId, td).then(response => {
            return getByPath(response, 'object.partner');
        })
    }

    updateTelephonyDomain(td: TelephonyDomain): Promise<Partner> {
        return btelephony.updateTelephonyDomain(td).then(response => {
            return getByPath(response, 'object.partner');
        })
    }

    deleteTelephonyDomain(domainId: string): Promise<Partner> {
        return btelephony.deleteTelephonyDomain(domainId).then(response => {
            return getByPath(response, 'object.partner');
        })
    }

    setGlobalDefaultTelephonyDomain(domainSequence: number): Promise<Partner> {
        return btelephony.setGlobalDefaultTelephonyDomain(domainSequence).then(response => {
            return getByPath(response, 'object.partner');
        })
    }

    assignTelephonyDomain(groupId: string, domainId: string): Promise<Group> {
        return btelephony.assignTelephonyDomain(groupId, domainId).then(response => {
            return getByPath(response, 'object.group');
        })
    }

    createTelephoneNumber(domainId: string, tn: TelephoneNumber): Promise<TelephonyDomain> {
        return btelephony.createTelephoneNumber(domainId, tn).then(response => {
            return getByPath(response, 'object.telephony_domain');
        })
    }

    updateTelephoneNumber(domainId: string, tn: TelephoneNumber): Promise<TelephonyDomain> {
        return btelephony.updateTelephoneNumber(domainId, tn).then(response => {
            return getByPath(response, 'object.telephony_domain');
        })
    }

    deleteTelephoneNumber(domainId: string, tnSeq: number): Promise<TelephonyDomain> {
        return btelephony.deleteTelephoneNumber(domainId, tnSeq).then(response => {
            return getByPath(response, 'object.telephony_domain');
        })
    }

}


