<template>
  <!--    <div class="messages-container">-->
  <div v-if="!showDetailView" @mousedown="handleActiveEvent">
    <div>
      <!--        <div :class="showLayOver? 'layover': ''" @click="showLayOver=false"/>-->
      <div class="button-wrapper" v-if="!showLayOver">
        <input type="button" class="secondary-btn" value="New Secure Message" @click="showLayOver=true" />
      </div>

      <div class="new-request-container" v-if="showLayOver">
        <template v-if="!submitSuccess">
          <div class="new-request-row"> {{ $t('your_msgs') }} </div>

          <div
            class="new-request-row"
            style="position: relative;
                 margin-bottom: 0;
                 display: flex;"
          >
            <label class="mx-request-label">Request Title</label>

            <div style="width: 100%; position: static;">
              <el-input
                style="font-size: 14px; display: inline-block; width: 100%;"
                maxlength="100"
                v-model="newSR.name"
              ></el-input>
            </div>
          </div>

          <div
              class="new-request-row">

            <el-select size="small" placeholder="Request Type" v-model="newSR.routing_channel">
              <el-option v-for="item in allChannels" :key="item.value" :label="item.label" :value="item.value">
                <span> {{item.label}} </span>
              </el-option>
            </el-select>
          </div>

          <div class="new-request-row request-description-wrapper">
            <label class="mx-request-label">Request Description</label>
            <div style="width: 100%; position: static;">
              <el-input
                id="mx-description"
                rows="4"
                :autosize="isIE ? false : {minRows:4}"
                type="textarea"
                v-model="newSR.description" />
            </div>
          </div>

          <div class="new-request-row">
            <div class="file-item" v-for="(item, index) in fileList" :key="item.name || item.uuid">
              <div style="display: flex">
                <img :src="FILE_IMAGE" alt="file" style="padding-left:5px" />
                <span class="file-item-name">{{normalize(item.name)}}</span>
              </div>
              <div style="display: flex">
                <span class="progress-wrapper" v-if="isUploading">
                  <progress max="100" :value="item.percentage" v-show="item.status < 4" />
                  <span v-show="item.status===4" class="attachment-failed">{{attachmentFailureReason(item.error)}}</span>
                </span>

                <a class="close-icon" @click="removeAttachment(index)" v-if="!isUploading">
                  <img :src="CLOSE_IMAGE" alt="close" style="font-size:16px; padding-right:10px" />
                </a>
              </div>
            </div>
          </div>
          <div :disabled="isSubmitting" class="add-attachment new-request-row">
            <a style="display:flex; align-items:center; " @click="interceptClick">
              <span class="attachment-icon">
                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path d="M6.2735,14.4307 C4.8655,14.4307 3.4575,13.8947 2.3845,12.8227 C0.2405,10.6777 0.2405,7.1887 2.3845,5.0447 L5.5665,1.8627 C5.9575,1.4717 6.5905,1.4717 6.9815,1.8627 C7.3715,2.2527 7.3715,2.8857 6.9815,3.2767 L3.7985,6.4587 C2.4345,7.8237 2.4345,10.0437 3.7985,11.4087 C5.1635,12.7727 7.3835,12.7727 8.7485,11.4087 L12.6375,7.5187 C13.0155,7.1407 13.2235,6.6387 13.2235,6.1047 C13.2235,5.5707 13.0155,5.0687 12.6375,4.6907 C11.8585,3.9107 10.5895,3.9107 9.8095,4.6907 L5.9195,8.5797 C5.7255,8.7747 5.7255,9.0927 5.9205,9.2867 C6.1155,9.4817 6.4325,9.4817 6.6275,9.2867 L9.8095,6.1047 C10.2005,5.7147 10.8335,5.7147 11.2235,6.1047 C11.6145,6.4957 11.6145,7.1287 11.2235,7.5187 L8.0415,10.7017 C7.0665,11.6757 5.4815,11.6757 4.5055,10.7017 C3.5315,9.7257 3.5315,8.1407 4.5055,7.1657 L8.3955,3.2767 C9.9545,1.7167 12.4925,1.7167 14.0515,3.2767 C14.8075,4.0317 15.2235,5.0367 15.2235,6.1047 C15.2235,7.1737 14.8075,8.1777 14.0515,8.9327 L10.1625,12.8227 C9.0905,13.8947 7.6825,14.4307 6.2735,14.4307" id="path-1"></path>
                </svg>
              </span>
              <input
                multiple="multiple"
                ref="input"
                :disabled="isSubmitting"
                type="file"
                @change="onChange"
              />
              <span style="font-size: 11px; font-weight: bold;height: 13px; "> {{ $t('add_attachment') }} </span>
            </a>
          </div>

          <div class="new-request-row">
            <input
              type="button"
              class="submit-request-btn"
              :class="{'disabled': isSubmitting}"
              :disabled="isSubmitting"
              @click="handleSubmitSR"
              :value="$t('send_msg')"
            />
            <input
              type="button"
              class="cancel-request-btn"
              :class="{'disabled': isSubmitting}"
                    @click="handleCancel"
              :value="$t('cancel')"
            />

            <span
                    v-if="submitState.msg"
                    style="font-size: 14px; font-weight: normal; padding-left: 20px"
                    :class="{'error-text': submitState.isError}"
            >{{submitState.msg}}</span>
          </div>
        </template>

        <div v-if="submitSuccess">
          <div
            style="    color: rgb(64, 173, 117); text-align: center;font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 16px;"
          >
            {{ $t('request_created_success') }}
            <span
              v-if="hasAttachmentError"
            >, but unfortunately the attachment failed.</span>
          </div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="request-title"> {{ $t('open_requests') }} ({{ openCount }})</div>

      <service-request-item  v-for="item in getMyOpenBoards"
                             :key="item.id"
                             :sr-board="item"
                             @show-detail="handleShowDetail">
      </service-request-item>
    
    </div>

    <div>
      <div class="request-title" style="display: flex;  justify-content: space-between">
        <span> {{ $t('completed_requests') }} ({{completedCount}})</span>
        <span style="padding-right:10px">
          <a @click="toggleCompletedRequests" v-if="!showCompletedRequest">
            <img :src="ARROW_DOWN_IMAGE" alt="arrow down" />
          </a>
          <a @click="toggleCompletedRequests" v-else>
            <img :src="ARROW_UP_IMAGE" alt="arrow up" />
          </a>
        </span>
      </div>

      <template v-if="showCompletedRequest">
        <service-request-item
          :sr-board="item"
          v-for="item in displayCompletedRequests"
          :key="item.id"
          @show-detail="handleShowDetail"
        ></service-request-item>

        <div
          style="display: flex; justify-content: center; padding: 10px 0"
          v-if="hasMoreCompletedRequests"
        >
          <input
            class="secondary-btn"
            type="button"
            :value=" $t('load_more') "
            @click="loadMore"
          />
        </div>
      </template>
    </div>
  </div>

  <service-request-detail v-else :sr-board-id="selectedBinder.id" @back="handleBack"></service-request-detail>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import uploadErrorMixin from "./mixin/uploadError"
import eventsHandler from "./mixin/eventsHandler";
import ServiceRequestItem from "./ServiceRequestItem";
import ServiceRequestDetail from "./ServiceRequestDetail";
import {BrowserUtils} from '@commonUtils'
import CLOSE_IMAGE from "@views/theme/src/fonts/icons/source/websdk-close-sm.svg"
import FILE_IMAGE from "@views/theme/src/fonts/icons/source/websdk-file-sm.svg"
import ARROW_UP_IMAGE from "@views/theme/src/fonts/icons/source/mep-arrow-up.svg"
import ARROW_DOWN_IMAGE from "@views/theme/src/fonts/icons/source/mep-arrow-down.svg"
import ElSelectDropdown from '../../../vendor/element-ui/packages/select/src/select-dropdown';


export default {
  name: "BankMsgDetailsView",
  components: {
    ElSelectDropdown,
    ServiceRequestItem,
    ServiceRequestDetail
  },
  mixins: [uploadErrorMixin, eventsHandler],
  created() {
    this.initSRSubscriber();
  },
  mounted() {
    this.updateBaseDomain(this.baseDomain);
  },
  props: {
    isSendNewMsg: false,
    baseDomain: ""
  },
  data() {
    return {
      CLOSE_IMAGE,
      FILE_IMAGE,
      ARROW_UP_IMAGE,
      ARROW_DOWN_IMAGE,
      showLayOver: this.isSendNewMsg,
      submitSuccess: false,
      hasAttachmentError: false,
      showCompletedRequest: true,
      selectMenu: "Requests",
      title: "",
      newBoardId: "",
      newSR: {
        name: "",
        description: "",
        routing_channel: null
      },
      showDetailView: false,
      selectedBinder: {},
      // fileList: [{"name": 'abc'}, {"name": 'def'}, {"name": 'xyz'}, {"name": 'ghj'},{"name": 'abc'}, {"name": 'def'}, {"name": 'xyz'}, {"name": 'ghj'}]
      fileList: [],
      isUploading: false,
        submitState: {
          msg: "",
          isError: false
        },
      isSubmitting: false,
      currentDisplayIndex: 5, //by default show 5 completed requests
      increment: 5 //by default each "load more" loads 5 more completed requests
    };
  },
  watch: {

    fileList: {
      deep: true,
      handler: function(v) {
        if (this.isUploading) {
          if (!v || v.length === 0) {
            // uploading is completed and successful
            this.isUploading = false;
            setTimeout(() => {
              this.submitSuccess = true;
            }, 1500);
            this.updateBoardAccessTime(this.newBoardId);
            this.restoreStateAfterCreate();
          } else {
            let len = v.length;
            for (const x of v) {
              if (x.status === 4 || x.error) len--;
            }
            if (len === 0) {

              this.hasAttachmentError = true;
              setTimeout(() => {
                this.submitSuccess = true;
                this.isUploading = false;
              }, 3000);
              this.updateBoardAccessTime(this.newBoardId);
              this.restoreStateAfterCreate();
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapActions("cardsdk", [
      "updateBaseDomain",
      "initSRSubscriber",
      "createServiceRequest",
      "updateBoardAccessTime"
    ]),
    ...mapActions({
      addFilesToUploadQueue: "uploader/addFilesToUploadQueue",
      resetStore: "uploader/resetStore"
    }),
    normalize(fileName) {
      const MAX_LENGTH = 32;
      if (fileName.length < MAX_LENGTH) return fileName;

      let array = [...fileName];
      return [...array.splice(0, 15), "...", ...array.splice(-14, 14)].join("");
    },
    restoreStateAfterCreate() {
      setTimeout(() => {
        this.showLayOver = false;
          this.submitState = {
            msg: "",
            isError: false
          };
        this.isSubmitting = false;
        this.submitSuccess = false;
        this.newSR.name = "";
        this.newSR.description = "";
        this.newSR.routing_channel = null
        this.newBoardId = "";
        this.hasAttachmentError = false;

        this.resetStore();
        this.fileList = [];
      }, 10000);
    },
    handleCancel() {
      this.showLayOver = false;
      this.newSR.name = "";
      this.newSR.description = "";
      this.newSR.routing_channel = null
        this.submitState = {
          msg: "",
          isError: false
        },
        this.resetStore()
        for ( const file of this.fileList ){
          if(file.uuid){
            file.uploader && file.uploader.cancel()
            this.hasAttachmentError = true
          }
        }
        this.fileList = []
      },
    handleSubmitSR() {
        this.submitState = {
          msg: "Creating Request...",
          isError:  false
        };
      this.isSubmitting = true;

      this.createServiceRequest({
        name: this.newSR.name,
        description: this.newSR.description,
        routing_channel: this.newSR.routing_channel
      }).then(newBoard => {
        this.newBoardId = newBoard.id;
        let files = this.fileList.map(f => {
          let result = {
            file: f,
            url: `${this.contextPath}/board/upload?newfile&id=${
              this.newBoardId
            }&name=${encodeURIComponent(f.name)}&type=original`,
            percentage: 0
          };
          if (this.getBaseDomain) {
            result.url = `${this.getBaseDomain}${result.url}`;
          }
          return result;
        });

        this.resetStore();

        if (files && files.length) {
          this.addFilesToUploadQueue(files).then(() => {
            this.isUploading = true;

            this.fileList = [];
            this.fileList = this.files;
          });
        } else {
          this.updateBoardAccessTime(this.newBoardId).then(() => {
            // this.submitState = 'SR Created Successfully'
            setTimeout(() => {
              this.submitSuccess = true;
            }, 1500);
            this.restoreStateAfterCreate();
          });
        }
        }).catch( err => {
          this.submitState= {
            msg : "Unable to create request, please retry",
            isError: true
          }

          this.isSubmitting = false
      });
    },
    handleShowDetail(e) {
      this.selectedBinder = e;
      this.showDetailView = true;
    },
    handleBack() {
      this.showDetailView = false;
    },
    toggleCompletedRequests() {
      this.showCompletedRequest = !this.showCompletedRequest;
    },
    uploadFileAccept() {
      return "";
    },
    beforeUpload(files, done) {
      //this.$refs.checkComponent.checkSameFiles(files, done)
    },
    createUploadUrl() {},
    onChange(e) {
      if (e.target.value) {
        let items = Array.from(e.target.files);
        this.fileList.push(...items);
        e.target.value = "";
      }
    },
    interceptClick() {
      this.$refs["input"].click();
    },
    removeAttachment(index) {
      this.fileList.splice(index, 1);
    },
    loadMore() {
      this.currentDisplayIndex += this.increment;
    }
  },
  computed: {
    ...mapState('group', ['contextPath']),
    ...mapGetters("cardsdk", [
      "getMyOpenBoards",
      "getMyCompletedBoards",
      "getBaseDomain"
    ]),
    ...mapGetters('group', ['allSRChannels']),
    ...mapState("uploader", ["files"]),

    allChannels () {
      let result = []
      this.allSRChannels.forEach( ch => {
        result.push({
          label: ch.name,
          value: ch.sequence
        })
      })
      return result
    },

    openCount() {
      return this.getMyOpenBoards && this.getMyOpenBoards.length;
    },
    completedCount() {
      return this.getMyCompletedBoards && this.getMyCompletedBoards.length;
    },
    displayCompletedRequests() {
      return this.getMyCompletedBoards.slice(0, this.currentDisplayIndex);
    },
    hasMoreCompletedRequests() {
      return this.getMyCompletedBoards
        ? this.currentDisplayIndex <= this.getMyCompletedBoards.length
        : false;
    },
    isIE() {
      return BrowserUtils.isIE || BrowserUtils.isOldEdge
    }
  }
};
</script>

<style scoped lang="scss">

.request-title {
  margin: 20px 0 15px 0;
}

.micon-attachment-badge {
  transform: rotate(90deg) scaleX(-1);
}


.button-wrapper {
  padding: 15px;
}

.counter-wrapper {
  align-self: flex-end;
  padding-top: 1px;
}

/deep/ .el-input__inner {
  font-size: 14px;
  height: 44px;
  color: #1f2126;
  display: block;
  padding: 6px 0 0 8px;
  box-shadow: none;
  width: calc(100% - 8px);
  float: left;
  font-weight: 400;
  /*line-height: 20px;*/
  border-radius: 6px;
  border: 1px solid #dde2eb;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;

  &:focus {
    border-color: var(--cardsdk-branding-color,#035cfe);
    border-width: 2px;
  }
}

/deep/ #mx-description{
  font-size: 14px;
  color: #1f2126;
  display: block;
  padding: 16px 0 0 8px;
  box-shadow: none;
  width: calc(100% - 8px);
  float: left;
  font-weight: 400;
  /*line-height: 20px;*/
  border-radius: 6px;
  border: 1px solid #dde2eb;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  resize: none;
  box-sizing: border-box;

  &:focus {
    border-color: var(--cardsdk-branding-color,#035cfe);
    border-width: 2px;
  }
}

.mx-request-label {
  left: 8px;
  top: 0;
  color: #8d9199;
  position: absolute;
  margin-bottom: 0;
  margin-top: 3px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  float: left;
}

/deep/ textarea {
  font-family: inherit;
  font-size: inherit;

  &:focus {
    outline: none;
  }
}



.new-request-container {
  position: relative;
  /*z-index:99999;*/
  display: flex;
  padding: 20px;
  flex-direction: column;
  background: white;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 0 24px;
}



/deep/ input {
  &:focus {
    outline: none;
  }


  &::placeholder{
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #8d9199;
    opacity: 1; //firefox needs this
  }

  .disabled {
    color: #bcc2cc;
    background-image: none;
    background-color: #f0f2f5;
    border-color: #f0f2f5;
  }
}

.progress-wrapper {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.close-icon {
  flex: 0;
  display: flex;
  align-items: center;
}

.request-description-wrapper {
  position: relative;
  margin-bottom: 0;
  display: flex;
}

input[type="file"] {
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}



li, ul {
  list-style: none;
  margin: 0;
}

</style>

<style lang="scss">
.el-scrollbar {
  overflow: hidden;
  position: relative;

  &__wrap{
    overflow: scroll;
    height: 100%;
  }
}

.el-select-dropdown {
  background-color: #fff;
  box-sizing: border-box;
  margin: 8px 0;
  transform: translateZ(0);
  box-shadow: 0 -2px 4px 0 rgba(0,0,0,.04),0 4px 6px 0 rgba(31,33,38,.12);

  &__wrap {
    max-height: 274px;
  }

  &__list {
    list-style: none;
    padding: 8px 0;
    margin: 0;
    box-sizing: border-box;
  }

}


.el-select-dropdown__item {
  font-size: 14px;
  padding: 12px 12px 12px 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
  height: auto;
  line-height: 20px;
  box-sizing: border-box;
  cursor: pointer;

  &.hover {
    background: #F0F2F5;
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }
}

.el-select{
  .el-input{

    position: relative;
    height: 28px;

    &__inner {
      cursor: pointer;
      text-align: left;
      overflow: hidden;
      user-select: none;
      height: 28px;
      padding-top: 0;
    }

    &__suffix {
      line-height: 28px;
      box-sizing: border-box;
      position: absolute;
      height: 100%;
      top: 0;
      text-align: center;
      color: #616161;
      transition: all .3s;
      pointer-events: auto;
      font-size: 20px;
      right: 20px;
    }
  }
}

//this is to show the down/up arrow in the dropdown list, since we don't want to boast the css size, we are not including the font icon
//as a hack we simply use the svg in-place: first minify the svg, then encode it https://yoksel.github.io/url-encoder/
.micon-mep-arrow-down{
  &:before {
    content: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon/03 - XS/Arrow (Dropdown Closed)%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath d='M11.5355339,11.5355339 C11.2796116,11.5355339 11.0236893,11.4379028 10.8284271,11.2426407 L7.29289322,7.70710678 C6.90236893,7.31658249 6.90236893,6.68341751 7.29289322,6.29289322 C7.68341751,5.90236893 8.31658249,5.90236893 8.70710678,6.29289322 L11.5355339,9.12132034 L14.363961,6.29289322 C14.7544853,5.90236893 15.3876503,5.90236893 15.7781746,6.29289322 C16.1686989,6.68341751 16.1686989,7.31658249 15.7781746,7.70710678 L12.2426407,11.2426407 C12.0473785,11.4379028 11.7914562,11.5355339 11.5355339,11.5355339 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Icon/03---XS/Arrow-(Dropdown-Closed)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Color' fill='%23606369' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  }
}

.micon-mep-arrow-up{
  &:before {
    content: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcon/03 - XS/Arrow (Dropdown Open)%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath d='M11.5355339,5 C11.7914562,5 12.0473785,5.09763107 12.2426407,5.29289322 L15.7781746,8.82842712 C16.1686989,9.21895142 16.1686989,9.8521164 15.7781746,10.2426407 C15.3876503,10.633165 14.7544853,10.633165 14.363961,10.2426407 L11.5355339,7.41421356 L8.70710678,10.2426407 C8.31658249,10.633165 7.68341751,10.633165 7.29289322,10.2426407 C6.90236893,9.8521164 6.90236893,9.21895142 7.29289322,8.82842712 L10.8284271,5.29289322 C11.0236893,5.09763107 11.2796116,5 11.5355339,5 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='Icon/03---XS/Arrow-(Dropdown-Open)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Color' fill='%23606369' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  }
}
</style>