var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      on: { mousedown: _vm.handleActiveEvent }
    },
    [
      _vm.basicInfoLoaded
        ? [
            _c("div", { staticClass: "request-header-container" }, [
              _c("input", {
                staticClass: "secondary-btn",
                class: { disabled: _vm.showConfirmClose },
                attrs: { type: "button", value: "< " + _vm.$t("Back") },
                on: { click: _vm.back }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "request-detail" }, [
                _vm._v(_vm._s(_vm.$t("msg_detail")))
              ]),
              _vm._v(" "),
              _vm.isSRClosed
                ? _c(
                    "span",
                    {
                      staticClass: "completed",
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.CHECKMARK_IMAGE, alt: "checkmark" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "padding-left": "5px" } }, [
                        _vm._v("Completed")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAgentComplete
                ? _c("input", {
                    staticClass: "default-btn",
                    attrs: { type: "button", value: "Reopen" },
                    on: { click: _vm.handleReopenSR }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSROpen
                ? _c("input", {
                    staticClass: "primary-btn",
                    class: { disabled: _vm.showConfirmClose },
                    attrs: {
                      type: "button",
                      value: _vm.$t("mark_as_complete")
                    },
                    on: { click: _vm.handleCloseSR }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.showConfirmClose
              ? _c("div", { staticClass: "confirm-complete" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n        You won't be able to reopen requests once you mark them as complete. Are you sure you want to continue?\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.isSROpen
                      ? _c("input", {
                          staticClass: "submit-request-btn",
                          attrs: { type: "button", value: "Complete" },
                          on: { click: _vm.handleConfirmComplete }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isSROpen
                      ? _c("input", {
                          staticClass: "cancel-request-btn",
                          attrs: { type: "button", value: "Cancel" },
                          on: {
                            click: function($event) {
                              _vm.showConfirmClose = false
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "divider" }),
            _vm._v(" "),
            _c("div", { staticClass: "request-body-container" }, [
              _c(
                "div",
                { staticStyle: { padding: "27px 28px 0 28px" } },
                [
                  _c("service-request-type", {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { "sr-board": _vm.srBoard }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: _vm.TITLE_IMAGE, alt: "title" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { flex: "1" } }, [
                      _c("span", { staticClass: "binder-title" }, [
                        _vm._v(_vm._s(_vm.srBoard.name))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: _vm.DESCRIPTION_IMAGE,
                          alt: "description"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          display: "flex",
                          "flex-direction": "column",
                          "max-width": "100%"
                        }
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("Description")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "item-content",
                            staticStyle: { "white-space": "pre-wrap" }
                          },
                          [_vm._v(_vm._s(_vm.srBoard.description))]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: _vm.CLOCK_IMAGE, alt: "clock" }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("submitted_on")) + " ")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "item-content" }, [
                          _vm._v(_vm._s(_vm.displayTime))
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", [
                      _c("img", {
                        attrs: { src: _vm.ATTACHMENT_IMAGE, alt: "attachment" }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          display: "flex",
                          "flex-direction": "column"
                        }
                      },
                      [
                        _vm.hasAttachments
                          ? [
                              _c("span", { staticClass: "title" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Attachments")) + "  "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "ul",
                                _vm._l(_vm.srBoard.resources, function(
                                  resource
                                ) {
                                  return _c("li", { key: resource.sequence }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item-content",
                                        staticStyle: { display: "flex" },
                                        attrs: {
                                          href: _vm.downloadUrl(resource)
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(resource.name) +
                                            "\n                    "
                                        ),
                                        _c("img", {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            src: _vm.DOWNLOAD_IMAGE,
                                            alt: "download"
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          : _c("span", { staticClass: "item-content" }, [
                              _vm._v(" " + _vm._s(_vm.$t("no_attachments")))
                            ])
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.isAgentComplete && !_vm.isSRClosed
                    ? [
                        _c(
                          "div",
                          { staticClass: "new-request-row" },
                          _vm._l(_vm.fileList, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "file-item" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("img", {
                                      staticStyle: { "padding-left": "5px" },
                                      attrs: {
                                        src: _vm.FILE_IMAGE,
                                        alt: "file"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "file-item-name",
                                        staticStyle: {
                                          flex: "1",
                                          color: "rgb(31, 33, 38)"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.normalize(item.name)))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _vm.isUploading
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "padding-right": "10px"
                                            }
                                          },
                                          [
                                            _c("progress", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.status < 4,
                                                  expression: "item.status < 4"
                                                }
                                              ],
                                              attrs: { max: "100" },
                                              domProps: {
                                                value: item.percentage
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: item.status === 4,
                                                    expression:
                                                      "item.status===4"
                                                  }
                                                ],
                                                staticClass: "attachment-failed"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.attachmentFailureReason(
                                                      item.error
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isUploading || item.status === 4
                                      ? _c(
                                          "a",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeAttachment(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                "font-size": "16px",
                                                "padding-right": "10px"
                                              },
                                              attrs: {
                                                src: _vm.CLOSE_IMAGE,
                                                alt: "close"
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "add-attachment new-request-row",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  },
                                  on: { click: _vm.interceptClick }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "attachment-icon" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "16px",
                                            height: "16px",
                                            viewBox: "0 0 16 16",
                                            version: "1.1",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M6.2735,14.4307 C4.8655,14.4307 3.4575,13.8947 2.3845,12.8227 C0.2405,10.6777 0.2405,7.1887 2.3845,5.0447 L5.5665,1.8627 C5.9575,1.4717 6.5905,1.4717 6.9815,1.8627 C7.3715,2.2527 7.3715,2.8857 6.9815,3.2767 L3.7985,6.4587 C2.4345,7.8237 2.4345,10.0437 3.7985,11.4087 C5.1635,12.7727 7.3835,12.7727 8.7485,11.4087 L12.6375,7.5187 C13.0155,7.1407 13.2235,6.6387 13.2235,6.1047 C13.2235,5.5707 13.0155,5.0687 12.6375,4.6907 C11.8585,3.9107 10.5895,3.9107 9.8095,4.6907 L5.9195,8.5797 C5.7255,8.7747 5.7255,9.0927 5.9205,9.2867 C6.1155,9.4817 6.4325,9.4817 6.6275,9.2867 L9.8095,6.1047 C10.2005,5.7147 10.8335,5.7147 11.2235,6.1047 C11.6145,6.4957 11.6145,7.1287 11.2235,7.5187 L8.0415,10.7017 C7.0665,11.6757 5.4815,11.6757 4.5055,10.7017 C3.5315,9.7257 3.5315,8.1407 4.5055,7.1657 L8.3955,3.2767 C9.9545,1.7167 12.4925,1.7167 14.0515,3.2767 C14.8075,4.0317 15.2235,5.0367 15.2235,6.1047 C15.2235,7.1737 14.8075,8.1777 14.0515,8.9327 L10.1625,12.8227 C9.0905,13.8947 7.6825,14.4307 6.2735,14.4307",
                                              id: "path-1"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    ref: "input",
                                    staticStyle: {
                                      visibility: "hidden",
                                      position: "absolute",
                                      width: "1px",
                                      height: "1px"
                                    },
                                    attrs: {
                                      multiple: "multiple",
                                      disabled: this.isUploading,
                                      type: "file"
                                    },
                                    on: { change: _vm.onChange }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        "font-weight": "bold",
                                        height: "13px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("add_attachment")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.isUploading && this.fileList.length > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("input", {
                                    staticClass: "secondary-btn",
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "button", value: "Upload" },
                                    on: { click: _vm.uploadFiles }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "divider",
                staticStyle: { "margin-bottom": "0" }
              }),
              _vm._v(" "),
              !_vm.feedsLoaded
                ? _c(
                    "div",
                    {
                      staticClass: "feeds-container",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center"
                      }
                    },
                    [_c("div", { staticClass: "spinner" })]
                  )
                : _c(
                    "div",
                    { staticClass: "feeds-container" },
                    [
                      _vm.hasMoreFeeds
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "secondary-btn",
                                attrs: {
                                  type: "button",
                                  value: "Load Earlier"
                                },
                                on: { click: _vm.loadMore }
                              })
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.getFeeds, function(feed) {
                        return _c("service-request-comment", {
                          key: feed.sequence,
                          attrs: { feed: feed }
                        })
                      })
                    ],
                    2
                  ),
              _vm._v(" "),
              !_vm.isSRClosed && !_vm.isAgentComplete
                ? _c(
                    "div",
                    { staticClass: "reply-container" },
                    [
                      _c("el-input", {
                        staticStyle: {
                          "font-size": "14px",
                          width: "100%",
                          flex: "1",
                          display: "flex"
                        },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 1 },
                          placeholder: _vm.$t("Reply")
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.sendMsg($event)
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          }
                        },
                        model: {
                          value: _vm.newComment,
                          callback: function($$v) {
                            _vm.newComment = $$v
                          },
                          expression: "newComment"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "send-msg-button",
                          on: { click: _vm.sendMsg }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16px",
                                height: "16px",
                                viewBox: "0 0 16 16",
                                version: "1.1",
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  fill: "white",
                                  d:
                                    "M14.5854,7.3291 L3.2444,1.6591 C2.6964,1.3851 2.0684,1.8481 2.1684,2.4531 L2.8384,6.4721 C2.8914,6.7871 3.1394,7.0351 3.4554,7.0881 L8.9274,8.0001 L3.4554,8.9121 C3.1394,8.9651 2.8914,9.2121 2.8384,9.5281 L2.1684,13.5471 C2.0684,14.1521 2.6964,14.6151 3.2444,14.3411 L14.5854,8.6711 C15.1384,8.3941 15.1384,7.6051 14.5854,7.3291"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.basicInfoLoaded
        ? _c(
            "div",
            {
              staticStyle: {
                height: "400px",
                display: "flex",
                "align-items": "center"
              }
            },
            [_c("div", { staticClass: "spinner" })]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("i")]),
      _vm._v("\n        Mark as complete?\n      ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }