import {MxGroupMemberSorter} from "isdk/src/api/defines";

export enum GroupMemberStatues {
    pending = 'pending',
    active = 'active',
    deactivated = 'deactivated'
}

export interface CGroupMember {
    status?: GroupMemberStatues
    roleName?: string
    avatar: string
    createdTime?: number
    updatedTime?: number
    uniqueIdentifier: string,
    filterId?: string,  
    relationCount?: number,
    displayName: string,
    userId?: string,
    isAdmin?: boolean,
    title?: string,
    displayId?: string,
    email?: string,
    sequence: number,
    role?: number,
    first_name?: string,
    last_name?: string,
    phone_number?: string
    type?: string,
    disabled?: boolean,
    unique_id?: string,
    isOwnerAccess?: boolean,
    display_email?: string,
    display_phone_number?: string,
    lastActive?: number,
}

export interface CGroupMemberFilter {
    role?: number[],
    status?: string,
    searchKey?: string,
    size?: number,
    sorter?: MxGroupMemberSorter
}

export interface InvitationToken {
    lastUpdatedBy?: string,
    lastUpdatedOn?: number,
    link: string
}