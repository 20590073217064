export enum ClientRequestType {
  INVALID_REQUEST = 'INVALID_REQUEST',
  CLIENT_REQUEST_CONNECT = 'CLIENT_REQUEST_CONNECT',
  JOB_REQUEST_CONNECT = 'JOB_REQUEST_CONNECT',
  CLIENT_REQUEST_PING = 'CLIENT_REQUEST_PING',
  USER_REQUEST_SSO = 'USER_REQUEST_SSO',
  USER_REQUEST_READ_SSO_OPTIONS = 'USER_REQUEST_READ_SSO_OPTIONS',
  USER_REQUEST_REGISTER = 'USER_REQUEST_REGISTER',
  USER_REQUEST_REGISTER_AGENT = 'USER_REQUEST_REGISTER_AGENT',
  USER_REQUEST_UNREGISTER_AGENT = 'USER_REQUEST_UNREGISTER_AGENT',
  USER_REQUEST_RESEND_VERIFICATION_EMAIL = 'USER_REQUEST_RESEND_VERIFICATION_EMAIL',
  USER_REQUEST_VERIFY_EMAIL_TOKEN = 'USER_REQUEST_VERIFY_EMAIL_TOKEN',
  USER_REQUEST_ENTER_FOREGROUND = 'USER_REQUEST_ENTER_FOREGROUND',
  USER_REQUEST_RESEND_VERIFICATION_CODE = 'USER_REQUEST_RESEND_VERIFICATION_CODE',
  USER_REQUEST_RESEND_VERIFICATION_CODE_EMAIL = 'USER_REQUEST_RESEND_VERIFICATION_CODE_EMAIL',
  USER_REQUEST_VERIFY_CODE = 'USER_REQUEST_VERIFY_CODE',
  USER_REQUEST_VERIFY_EMAIL_CODE = 'USER_REQUEST_VERIFY_EMAIL_CODE',
  USER_REQUEST_READ = 'USER_REQUEST_READ',
  USER_REQUEST_READ_CAP = 'USER_REQUEST_READ_CAP',
  USER_REQUEST_UNREAD_FEEDS_COUNT = 'USER_REQUEST_UNREAD_FEEDS_COUNT',
  USER_REQUEST_SUBSCRIBE = 'USER_REQUEST_SUBSCRIBE',
  USER_REQUEST_READ_FEEDS = 'USER_REQUEST_READ_FEEDS',
  USER_REQUEST_READ_NOTES = 'USER_REQUEST_READ_NOTES',
  USER_REQUEST_UPDATE = 'USER_REQUEST_UPDATE',
  USER_REQUEST_UPDATE_PICTURES = 'USER_REQUEST_UPDATE_PICTURES',
  USER_REQUEST_UPDATE_NAME = 'USER_REQUEST_UPDATE_NAME',
  USER_REQUEST_UPDATE_PHONE = 'USER_REQUEST_UPDATE_PHONE',
  USER_REQUEST_UPDATE_EMAIL = 'USER_REQUEST_UPDATE_EMAIL',
  USER_REQUEST_UPDATE_USER_BOARD = 'USER_REQUEST_UPDATE_USER_BOARD',
  USER_REQUEST_UPDATE_USER_BOARD_ENTER = 'USER_REQUEST_UPDATE_USER_BOARD_ENTER',
  USER_REQUEST_UPDATE_USER_GROUP = 'USER_REQUEST_UPDATE_USER_GROUP',
  USER_REQUEST_UPDATE_AGENT = 'USER_REQUEST_UPDATE_AGENT',
  USER_REQUEST_LOGIN = 'USER_REQUEST_LOGIN',
  USER_REQUEST_RESOURCE_TOKEN = 'USER_REQUEST_RESOURCE_TOKEN',
  USER_REQUEST_ACCESS_TOKEN = 'USER_REQUEST_ACCESS_TOKEN',
  USER_REQUEST_VERIFY_TOKEN = 'USER_REQUEST_VERIFY_TOKEN',
  USER_REQUEST_DUPLICATE_TOKEN = 'USER_REQUEST_DUPLICATE_TOKEN',
  USER_REQUEST_VERIFY_PASSWORD = 'USER_REQUEST_VERIFY_PASSWORD',
  USER_REQUEST_REFRESH_TOKEN = 'USER_REQUEST_REFRESH_TOKEN',
  USER_REQUEST_LOGOUT = 'USER_REQUEST_LOGOUT',
  USER_REQUEST_LOGOUT_ALL_DEVICES = 'USER_REQUEST_LOGOUT_ALL_DEVICES',
  USER_REQUEST_UPLOAD_RESOURCE = 'USER_REQUEST_UPLOAD_RESOURCE',
  USER_REQUEST_UPLOAD_PROFILE_PICTURES = 'USER_REQUEST_UPLOAD_PROFILE_PICTURES',
  USER_REQUEST_DOWNLOAD_RESOURCE = 'USER_REQUEST_DOWNLOAD_RESOURCE',
  USER_REQUEST_RESET_PASSWORD = 'USER_REQUEST_RESET_PASSWORD',
  USER_REQUEST_CHANGE_PASSWORD = 'USER_REQUEST_CHANGE_PASSWORD',
  USER_REQUEST_CATEGORY_CREATE = 'USER_REQUEST_CATEGORY_CREATE',
  USER_REQUEST_CATEGORY_RENAME = 'USER_REQUEST_CATEGORY_RENAME',
  USER_REQUEST_CATEGORY_DELETE = 'USER_REQUEST_CATEGORY_DELETE',
  USER_REQUEST_CATEGORY_ASSIGN = 'USER_REQUEST_CATEGORY_ASSIGN',
  USER_REQUEST_READ_SESSIONS = 'USER_REQUEST_READ_SESSIONS',
  USER_REQUEST_READ_BOARDS = 'USER_REQUEST_READ_BOARDS',
  USER_REQUEST_READ_RELATIONS = 'USER_REQUEST_READ_RELATIONS',
  USER_REQUEST_EMAIL_LOOKUP = 'USER_REQUEST_EMAIL_LOOKUP',
  USER_REQUEST_PHONE_NUMBER_LOOKUP = 'USER_REQUEST_PHONE_NUMBER_LOOKUP',
  USER_REQUEST_BOARD_LOOKUP = 'USER_REQUEST_BOARD_LOOKUP',
  USER_REQUEST_RELATION_LOOKUP = 'USER_REQUEST_RELATION_LOOKUP',
  USER_REQUEST_REMOVE_FAVORITE = 'USER_REQUEST_REMOVE_FAVORITE',
  USER_REQUEST_REMOVE_MENTIONME = 'USER_REQUEST_REMOVE_MENTIONME',
  USER_REQUEST_UPDATE_ORDER_NUMBER = 'USER_REQUEST_UPDATE_ORDER_NUMBER',
  USER_REQUEST_UPDATE_ACTION_ITEM = 'USER_REQUEST_UPDATE_ACTION_ITEM',
  USER_REQUEST_REGISTER_LOCAL_USER = 'USER_REQUEST_REGISTER_LOCAL_USER',
  USER_REQUEST_LOGIN_LOCAL_USER = 'USER_REQUEST_LOGIN_LOCAL_USER',
  USER_REQUEST_REGISTER_LOCAL_USER_WITH_QR_TOKEN = 'USER_REQUEST_REGISTER_LOCAL_USER_WITH_QR_TOKEN',
  USER_REQUEST_VERIFY_LOCAL_EMAIL_CODE = 'USER_REQUEST_VERIFY_LOCAL_EMAIL_CODE',
  USER_REQUEST_CREATE_RELATION_VIA_QR_TOKEN = 'USER_REQUEST_CREATE_RELATION_VIA_QR_TOKEN',
  USER_REQUEST_PREVIEW_EMAIL_TOKEN = 'USER_REQUEST_PREVIEW_EMAIL_TOKEN',
  USER_REQUEST_UPDATE_DEVICE_TOKEN = 'USER_REQUEST_UPDATE_DEVICE_TOKEN',
  USER_REQUEST_SEARCH = 'USER_REQUEST_SEARCH',
  USER_REQUEST_EMAIL_AUTH = 'USER_REQUEST_EMAIL_AUTH',
  USER_REQUEST_EMAIL_DELIVERY = 'USER_REQUEST_EMAIL_DELIVERY',
  USER_REQUEST_CONTACT_INVITE = 'USER_REQUEST_CONTACT_INVITE',
  USER_REQUEST_CONTACT_ACCEPT = 'USER_REQUEST_CONTACT_ACCEPT',
  USER_REQUEST_CONTACT_DENY = 'USER_REQUEST_CONTACT_DENY',
  USER_REQUEST_CONTACT_CANCEL = 'USER_REQUEST_CONTACT_CANCEL',
  USER_REQUEST_CONTACT_VIEW_INVITATION = 'USER_REQUEST_CONTACT_VIEW_INVITATION',
  USER_REQUEST_CREATE_CONTACT = 'USER_REQUEST_CREATE_CONTACT',
  USER_REQUEST_UPDATE_CONTACT = 'USER_REQUEST_UPDATE_CONTACT',
  USER_REQUEST_DELETE_CONTACT = 'USER_REQUEST_DELETE_CONTACT',
  USER_REQUEST_FEEDBACK = 'USER_REQUEST_FEEDBACK',
  USER_REQUEST_SYSTEM_FEEDBACK = 'USER_REQUEST_SYSTEM_FEEDBACK',
  USER_REQUEST_SSO_REGISTERED_USER = 'USER_REQUEST_SSO_REGISTERED_USER',
  USER_REQUEST_SSO_GROUP_UNIQUE_ID = 'USER_REQUEST_SSO_GROUP_UNIQUE_ID',
  USER_REQUEST_SSO_EXTERNAL_MOXTRA = 'USER_REQUEST_SSO_EXTERNAL_MOXTRA',
  USER_REQUEST_SSO_REDIRECT = 'USER_REQUEST_SSO_REDIRECT',
  USER_REQUEST_UPDATE_SIP_REGISTRATION_STATUS = 'USER_REQUEST_UPDATE_SIP_REGISTRATION_STATUS',
  USER_REQUEST_CREATE_CALL_LOG = 'USER_REQUEST_CREATE_CALL_LOG',
  USER_REQUEST_UPDATE_CALL_LOG = 'USER_REQUEST_UPDATE_CALL_LOG',
  USER_REQUEST_DELETE_CALL_LOG = 'USER_REQUEST_DELETE_CALL_LOG',
  USER_REQUEST_READ_PASSWORD_RULE = 'USER_REQUEST_READ_PASSWORD_RULE',
  USER_REQUEST_RESEND_DELETION_EMAIL = 'USER_REQUEST_RESEND_DELETION_EMAIL',
  USER_REQUEST_VERIFY_DELETION_TOKEN = 'USER_REQUEST_VERIFY_DELETION_TOKEN',
  USER_REQUEST_DELETE = 'USER_REQUEST_DELETE',
  USER_REQUEST_FOLLOW_GROUP_BOARD = 'USER_REQUEST_FOLLOW_GROUP_BOARD',
  USER_REQUEST_UNFOLLOW_GROUP_BOARD = 'USER_REQUEST_UNFOLLOW_GROUP_BOARD',
  USER_REQUEST_UPDATE_GROUP_BOARD = 'USER_REQUEST_UPDATE_GROUP_BOARD',
  USER_REQUEST_UPDATE_OUT_OF_OFFICE = 'USER_REQUEST_UPDATE_OUT_OF_OFFICE',
  USER_REQUEST_POST_ACTIVITY = 'USER_REQUEST_POST_ACTIVITY',
  USER_REQUEST_READ_ACTIVITY = 'USER_REQUEST_READ_ACTIVITY',
  USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_EMAIL = 'USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_EMAIL',
  USER_REQUEST_QR_TOKEN = 'USER_REQUEST_QR_TOKEN',
  USER_REQUEST_VIEW_QR_TOKEN = 'USER_REQUEST_VIEW_QR_TOKEN',
  USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_SMS = 'USER_REQUEST_RESEND_LOCAL_VERIFICATION_CODE_SMS',
  USER_REQUEST_VERIFY_LOCAL_SMS_CODE = 'USER_REQUEST_VERIFY_LOCAL_SMS_CODE',
  USER_REQUEST_RESEND_APP_DOWNLOAD_LINK_SMS = 'USER_REQUEST_RESEND_APP_DOWNLOAD_LINK_SMS',
  USER_REQUEST_PUSH_NOTIFICATION = 'USER_REQUEST_PUSH_NOTIFICATION',
  USER_REQUEST_SMS = 'USER_REQUEST_SMS',
  USER_REQUEST_CREATE_BROADCAST = 'USER_REQUEST_CREATE_BROADCAST',
  USER_REQUEST_UPDATE_BROADCAST = 'USER_REQUEST_UPDATE_BROADCAST',
  USER_REQUEST_DELETE_BROADCAST = 'USER_REQUEST_DELETE_BROADCAST',
  USER_REQUEST_MAX = 'USER_REQUEST_MAX',
  BOARD_REQUEST_CREATE = 'BOARD_REQUEST_CREATE',
  BOARD_REQUEST_DUPLICATE = 'BOARD_REQUEST_DUPLICATE',
  BOARD_REQUEST_READ = 'BOARD_REQUEST_READ',
  BOARD_REQUEST_VIEW = 'BOARD_REQUEST_VIEW',
  BOARD_REQUEST_VIEW_AS_OGO = 'BOARD_REQUEST_VIEW_AS_OGO',
  BOARD_REQUEST_READ_FEEDS = 'BOARD_REQUEST_READ_FEEDS',
  BOARD_REQUEST_SUBSCRIBE = 'BOARD_REQUEST_SUBSCRIBE',
  BOARD_REQUEST_UNSUBSCRIBE = 'BOARD_REQUEST_UNSUBSCRIBE',
  BOARD_REQUEST_SUBSCRIBE_MULTIPLE = 'BOARD_REQUEST_SUBSCRIBE_MULTIPLE',
  BOARD_REQUEST_UPDATE = 'BOARD_REQUEST_UPDATE',
  BOARD_REQUEST_COPY_PAGES = 'BOARD_REQUEST_COPY_PAGES',
  BOARD_REQUEST_COPY_RESOURCES = 'BOARD_REQUEST_COPY_RESOURCES',
  BOARD_REQUEST_COPY_TODOS = 'BOARD_REQUEST_COPY_TODOS',
  BOARD_REQUEST_CREATE_COMMENT = 'BOARD_REQUEST_CREATE_COMMENT',
  BOARD_REQUEST_UPLOAD_COMMENT = 'BOARD_REQUEST_UPLOAD_COMMENT',
  BOARD_REQUEST_UPDATE_COMMENT = 'BOARD_REQUEST_UPDATE_COMMENT',
  BOARD_REQUEST_DELETE_COMMENT = 'BOARD_REQUEST_DELETE_COMMENT',
  BOARD_REQUEST_TYPE_INDICATION = 'BOARD_REQUEST_TYPE_INDICATION',
  BOARD_REQUEST_DELETE = 'BOARD_REQUEST_DELETE',
  BOARD_REQUEST_UPLOAD_RESOURCE = 'BOARD_REQUEST_UPLOAD_RESOURCE',
  BOARD_REQUEST_UPLOAD_AUDIO = 'BOARD_REQUEST_UPLOAD_AUDIO',
  BOARD_REQUEST_DOWNLOAD_RESOURCE = 'BOARD_REQUEST_DOWNLOAD_RESOURCE',
  BOARD_REQUEST_DOWNLOAD_BOARD = 'BOARD_REQUEST_DOWNLOAD_BOARD',
  BOARD_REQUEST_UPLOAD_RESOURCE_URL = 'BOARD_REQUEST_UPLOAD_RESOURCE_URL',
  BOARD_REQUEST_DOWNLOAD_FOLDER = 'BOARD_REQUEST_DOWNLOAD_FOLDER',
  BOARD_REQUEST_INVITE = 'BOARD_REQUEST_INVITE',
  BOARD_REQUEST_JOIN = 'BOARD_REQUEST_JOIN',
  BOARD_REQUEST_LEAVE = 'BOARD_REQUEST_LEAVE',
  BOARD_REQUEST_APPROVE = 'BOARD_REQUEST_APPROVE',
  BOARD_REQUEST_DENY = 'BOARD_REQUEST_DENY',
  BOARD_REQUEST_EXPEL = 'BOARD_REQUEST_EXPEL',
  BOARD_REQUEST_SET_ACCESS_TYPE = 'BOARD_REQUEST_SET_ACCESS_TYPE',
  BOARD_REQUEST_VIEW_INVITATION = 'BOARD_REQUEST_VIEW_INVITATION',
  BOARD_REQUEST_GET_RECORDINGS = 'BOARD_REQUEST_GET_RECORDINGS',
  BOARD_REQUEST_CREATE_VIEW_TOKEN = 'BOARD_REQUEST_CREATE_VIEW_TOKEN',
  BOARD_REQUEST_UPDATE_VIEW_TOKEN = 'BOARD_REQUEST_UPDATE_VIEW_TOKEN',
  BOARD_REQUEST_REMOVE_VIEW_TOKEN = 'BOARD_REQUEST_REMOVE_VIEW_TOKEN',
  BOARD_REQUEST_EMAIL_VIEW_TOKEN = 'BOARD_REQUEST_EMAIL_VIEW_TOKEN',
  BOARD_REQUEST_READ_VIEW_TOKEN = 'BOARD_REQUEST_READ_VIEW_TOKEN',
  BOARD_REQUEST_READ_VIEW_TOKENS = 'BOARD_REQUEST_READ_VIEW_TOKENS',
  BOARD_REQUEST_SEARCH_BOARD = 'BOARD_REQUEST_SEARCH_BOARD',
  BOARD_REQUEST_SEARCH_GROUP_BOARD = 'BOARD_REQUEST_SEARCH_GROUP_BOARD',
  BOARD_REQUEST_SEARCH_GROUP = 'BOARD_REQUEST_SEARCH_GROUP',
  SESSION_REQUEST_START = 'SESSION_REQUEST_START',
  SESSION_REQUEST_END = 'SESSION_REQUEST_END',
  SESSION_REQUEST_JOIN = 'SESSION_REQUEST_JOIN',
  SESSION_REQUEST_SUBSCRIBE = 'SESSION_REQUEST_SUBSCRIBE',
  SESSION_REQUEST_LEAVE = 'SESSION_REQUEST_LEAVE',
  SESSION_REQUEST_INVITE = 'SESSION_REQUEST_INVITE',
  SESSION_REQUEST_KEEP_ALIVE = 'SESSION_REQUEST_KEEP_ALIVE',
  SESSION_REQUEST_EVENT_LOG = 'SESSION_REQUEST_EVENT_LOG',
  SESSION_REQUEST_READ_EVENT_LOG = 'SESSION_REQUEST_READ_EVENT_LOG',
  SESSION_REQUEST_WEBRTC_OFFER = 'SESSION_REQUEST_WEBRTC_OFFER',
  SESSION_REQUEST_CHANGE_PRESENTER = 'SESSION_REQUEST_CHANGE_PRESENTER',
  SESSION_REQUEST_START_DS = 'SESSION_REQUEST_START_DS',
  SESSION_REQUEST_STOP_DS = 'SESSION_REQUEST_STOP_DS',
  SESSION_REQUEST_PUBLISH_DS_STATE = 'SESSION_REQUEST_PUBLISH_DS_STATE',
  SESSION_REQUEST_PUBLISH_AUDIO_STATE = 'SESSION_REQUEST_PUBLISH_AUDIO_STATE',
  SESSION_REQUEST_PUBLISH_VIDEO_STATE = 'SESSION_REQUEST_PUBLISH_VIDEO_STATE',
  SESSION_REQUEST_READ = 'SESSION_REQUEST_READ',
  SESSION_REQUEST_READ_ROSTER = 'SESSION_REQUEST_READ_ROSTER',
  SESSION_REQUEST_UPDATE_AUDIO_STATUS = 'SESSION_REQUEST_UPDATE_AUDIO_STATUS',
  SESSION_REQUEST_UPDATE_VIDEO_STATUS = 'SESSION_REQUEST_UPDATE_VIDEO_STATUS',
  SESSION_REQUEST_RECLAIM_HOST = 'SESSION_REQUEST_RECLAIM_HOST',
  SESSION_REQUEST_SET_PRESENTER = 'SESSION_REQUEST_SET_PRESENTER',
  SESSION_REQUEST_SET_HOST = 'SESSION_REQUEST_SET_HOST',
  SESSION_REQUEST_MUTE = 'SESSION_REQUEST_MUTE',
  SESSION_REQUEST_UNMUTE = 'SESSION_REQUEST_UNMUTE',
  SESSION_REQUEST_MUTE_ALL = 'SESSION_REQUEST_MUTE_ALL',
  SESSION_REQUEST_SWITCH_PAGE = 'SESSION_REQUEST_SWITCH_PAGE',
  SESSION_REQUEST_START_DESKTOPSHARE = 'SESSION_REQUEST_START_DESKTOPSHARE',
  SESSION_REQUEST_LEAVE_TELEPHONY = 'SESSION_REQUEST_LEAVE_TELEPHONY',
  SESSION_REQUEST_SCHEDULE = 'SESSION_REQUEST_SCHEDULE',
  SESSION_REQUEST_DOWNLOAD_CALENDAR = 'SESSION_REQUEST_DOWNLOAD_CALENDAR',
  SESSION_REQUEST_CREATE_PERSONAL_ROOM = 'SESSION_REQUEST_CREATE_PERSONAL_ROOM',
  SESSION_REQUEST_REMOVE_ROSTER = 'SESSION_REQUEST_REMOVE_ROSTER',
  SESSION_REQUEST_LOCK = 'SESSION_REQUEST_LOCK',
  SESSION_REQUEST_UNLOCK = 'SESSION_REQUEST_UNLOCK',
  BOARD_REQUEST_UPDATE_BOARD = 'BOARD_REQUEST_UPDATE_BOARD',
  BOARD_REQUEST_UPLOAD_BOARD_RESOURCE = 'BOARD_REQUEST_UPLOAD_BOARD_RESOURCE',
  BOARD_REQUEST_CREATE_PAGE = 'BOARD_REQUEST_CREATE_PAGE',
  BOARD_REQUEST_UPLOAD_PAGE = 'BOARD_REQUEST_UPLOAD_PAGE',
  BOARD_REQUEST_UPDATE_PAGE = 'BOARD_REQUEST_UPDATE_PAGE',
  BOARD_REQUEST_DELETE_PAGE = 'BOARD_REQUEST_DELETE_PAGE',
  BOARD_REQUEST_DELETE_RESOURCE = 'BOARD_REQUEST_DELETE_RESOURCE',
  BOARD_REQUEST_SET_EDITOR = 'BOARD_REQUEST_SET_EDITOR',
  BOARD_REQUEST_REMOVE_EDITOR = 'BOARD_REQUEST_REMOVE_EDITOR',
  BOARD_REQUEST_SET_EDITOR_TYPE = 'BOARD_REQUEST_SET_EDITOR_TYPE',
  BOARD_REQUEST_CREATE_PAGE_ELEMENT = 'BOARD_REQUEST_CREATE_PAGE_ELEMENT',
  BOARD_REQUEST_UPLOAD_PAGE_ELEMENT = 'BOARD_REQUEST_UPLOAD_PAGE_ELEMENT',
  BOARD_REQUEST_UPDATE_PAGE_ELEMENT = 'BOARD_REQUEST_UPDATE_PAGE_ELEMENT',
  BOARD_REQUEST_DELETE_PAGE_ELEMENT = 'BOARD_REQUEST_DELETE_PAGE_ELEMENT',
  BOARD_REQUEST_CREATE_PAGE_COMMENT = 'BOARD_REQUEST_CREATE_PAGE_COMMENT',
  BOARD_REQUEST_UPLOAD_PAGE_COMMENT = 'BOARD_REQUEST_UPLOAD_PAGE_COMMENT',
  BOARD_REQUEST_UPDATE_PAGE_COMMENT = 'BOARD_REQUEST_UPDATE_PAGE_COMMENT',
  BOARD_REQUEST_DELETE_PAGE_COMMENT = 'BOARD_REQUEST_DELETE_PAGE_COMMENT',
  BOARD_REQUEST_CREATE_PAGE_POSITION_COMMENT = 'BOARD_REQUEST_CREATE_PAGE_POSITION_COMMENT',
  BOARD_REQUEST_UPLOAD_PAGE_POSITION_COMMENT = 'BOARD_REQUEST_UPLOAD_PAGE_POSITION_COMMENT',
  BOARD_REQUEST_UPDATE_PAGE_POSITION_COMMENT = 'BOARD_REQUEST_UPDATE_PAGE_POSITION_COMMENT',
  BOARD_REQUEST_DELETE_PAGE_POSITION_COMMENT = 'BOARD_REQUEST_DELETE_PAGE_POSITION_COMMENT',
  BOARD_REQUEST_CREATE_PAGE_GROUP = 'BOARD_REQUEST_CREATE_PAGE_GROUP',
  BOARD_REQUEST_UPDATE_PAGE_GROUP = 'BOARD_REQUEST_UPDATE_PAGE_GROUP',
  BOARD_REQUEST_DELETE_PAGE_GROUP = 'BOARD_REQUEST_DELETE_PAGE_GROUP',
  BOARD_REQUEST_COPY_PAGE_GROUP = 'BOARD_REQUEST_COPY_PAGE_GROUP',
  BOARD_REQUEST_MOVE_PAGE_GROUP = 'BOARD_REQUEST_MOVE_PAGE_GROUP',
  BOARD_REQUEST_COPY_SIGNATURE = 'BOARD_REQUEST_COPY_SIGNATURE',
  BOARD_REQUEST_CREATE_PAGE_TAG = 'BOARD_REQUEST_CREATE_PAGE_TAG',
  BOARD_REQUEST_UPDATE_PAGE_TAG = 'BOARD_REQUEST_UPDATE_PAGE_TAG',
  BOARD_REQUEST_DELETE_PAGE_TAG = 'BOARD_REQUEST_DELETE_PAGE_TAG',
  BOARD_REQUEST_DOWNLOAD_NOTE = 'BOARD_REQUEST_DOWNLOAD_NOTE',
  BOARD_REQUEST_CREATE_TODO = 'BOARD_REQUEST_CREATE_TODO',
  BOARD_REQUEST_UPLOAD_TODO = 'BOARD_REQUEST_UPLOAD_TODO',
  BOARD_REQUEST_UPDATE_TODO = 'BOARD_REQUEST_UPDATE_TODO',
  BOARD_REQUEST_DELETE_TODO = 'BOARD_REQUEST_DELETE_TODO',
  BOARD_REQUEST_SET_TODO_ASSIGNEE = 'BOARD_REQUEST_SET_TODO_ASSIGNEE',
  BOARD_REQUEST_SET_TODO_DUE_DATE = 'BOARD_REQUEST_SET_TODO_DUE_DATE',
  BOARD_REQUEST_SET_TODO_COMPLETED = 'BOARD_REQUEST_SET_TODO_COMPLETED',
  BOARD_REQUEST_UPDATE_TODO_ATTACHMENT = 'BOARD_REQUEST_UPDATE_TODO_ATTACHMENT',
  BOARD_REQUEST_UPLOAD_TODO_COMMENT = 'BOARD_REQUEST_UPLOAD_TODO_COMMENT',
  BOARD_REQUEST_CREATE_TODO_COMMENT = 'BOARD_REQUEST_CREATE_TODO_COMMENT',
  BOARD_REQUEST_UPDATE_TODO_COMMENT = 'BOARD_REQUEST_UPDATE_TODO_COMMENT',
  BOARD_REQUEST_DELETE_TODO_COMMENT = 'BOARD_REQUEST_DELETE_TODO_COMMENT',
  BOARD_REQUEST_CREATE_TODO_REMINDER = 'BOARD_REQUEST_CREATE_TODO_REMINDER',
  BOARD_REQUEST_UPDATE_TODO_REMINDER = 'BOARD_REQUEST_UPDATE_TODO_REMINDER',
  BOARD_REQUEST_DELETE_TODO_REMINDER = 'BOARD_REQUEST_DELETE_TODO_REMINDER',
  BOARD_REQUEST_DELETE_TODO_FILE = 'BOARD_REQUEST_DELETE_TODO_FILE',
  BOARD_REQUEST_READ_FLAT_FEEDS = 'BOARD_REQUEST_READ_FLAT_FEEDS',
  BOARD_REQUEST_READ_THREAD = 'BOARD_REQUEST_READ_THREAD',
  BOARD_REQUEST_READ_ONGOING_SIGNATURES = 'BOARD_REQUEST_READ_ONGOING_SIGNATURES',
  BOARD_REQUEST_READ_ONGOING_TRANSACTIONS = 'BOARD_REQUEST_READ_ONGOING_TRANSACTIONS',
  BOARD_REQUEST_READ_ONGOING_DELEGATE_FEEDS = 'BOARD_REQUEST_READ_ONGOING_DELEGATE_FEEDS',
  BOARD_REQUEST_READ_COVER = 'BOARD_REQUEST_READ_COVER',
  BOARD_REQUEST_LIST_FOLDER = 'BOARD_REQUEST_LIST_FOLDER',
  BOARD_REQUEST_READ_FILE = 'BOARD_REQUEST_READ_FILE',
  BOARD_REQUEST_LIST_SIGNATURES = 'BOARD_REQUEST_LIST_SIGNATURES',
  BOARD_REQUEST_LIST_TODOS = 'BOARD_REQUEST_LIST_TODOS',
  BOARD_REQUEST_READ_SIGNATURE = 'BOARD_REQUEST_READ_SIGNATURE',
  BOARD_REQUEST_READ_AUDIT_OBJECT = 'BOARD_REQUEST_READ_AUDIT_OBJECT',
  BOARD_REQUEST_AUDIT_OBJECT_DOWNLOAD_RESOURCE = 'BOARD_REQUEST_AUDIT_OBJECT_DOWNLOAD_RESOURCE',
  BOARD_REQUEST_AUDIT_OBJECT_DOWNLOAD_BOARD = 'BOARD_REQUEST_AUDIT_OBJECT_DOWNLOAD_BOARD',
  BOARD_PUBLISH_ACTION = 'BOARD_PUBLISH_ACTION',
  OBSOLETE_BOARD_REQUEST_SET_EMAIL_ADDRESS = 'OBSOLETE_BOARD_REQUEST_SET_EMAIL_ADDRESS',
  BOARD_REQUEST_SET_PHONE_NUMBER = 'BOARD_REQUEST_SET_PHONE_NUMBER',
  BOARD_REQUEST_CREATE_FOLDER = 'BOARD_REQUEST_CREATE_FOLDER',
  BOARD_REQUEST_UPDATE_FOLDER = 'BOARD_REQUEST_UPDATE_FOLDER',
  BOARD_REQUEST_DELETE_FOLDER = 'BOARD_REQUEST_DELETE_FOLDER',
  BOARD_REQUEST_COPY_FOLDER = 'BOARD_REQUEST_COPY_FOLDER',
  BOARD_REQUEST_MOVE_FOLDER = 'BOARD_REQUEST_MOVE_FOLDER',
  BOARD_REQUEST_CREATE_FAVORITE = 'BOARD_REQUEST_CREATE_FAVORITE',
  BOARD_REQUEST_PIN = 'BOARD_REQUEST_PIN',
  BOARD_REQUEST_CHECK_ISRESTRICT = 'BOARD_REQUEST_CHECK_ISRESTRICT',
  BOARD_REQUEST_CALL_LOG = 'BOARD_REQUEST_CALL_LOG',
  BOARD_REQUEST_SET_OWNER_DELEGATE = 'BOARD_REQUEST_SET_OWNER_DELEGATE',
  BOARD_REQUEST_SET_FEED_STATUS = 'BOARD_REQUEST_SET_FEED_STATUS',
  BOARD_REQUEST_SET_OWNER = 'BOARD_REQUEST_SET_OWNER',
  BOARD_REQUEST_CREATE_SIGNATURE = 'BOARD_REQUEST_CREATE_SIGNATURE',
  BOARD_REQUEST_UPDATE_SIGNATURE = 'BOARD_REQUEST_UPDATE_SIGNATURE',
  BOARD_REQUEST_DELETE_SIGNATURE = 'BOARD_REQUEST_DELETE_SIGNATURE',
  BOARD_REQUEST_ADD_SIGNATURE_SIGNEE = 'BOARD_REQUEST_ADD_SIGNATURE_SIGNEE',
  BOARD_REQUEST_UPDATE_SIGNATURE_SIGNEE = 'BOARD_REQUEST_UPDATE_SIGNATURE_SIGNEE',
  BOARD_REQUEST_REMOVE_SIGNATURE_SIGNEE = 'BOARD_REQUEST_REMOVE_SIGNATURE_SIGNEE',
  BOARD_REQUEST_CREATE_SIGNATURE_PAGE_ELEMENT = 'BOARD_REQUEST_CREATE_SIGNATURE_PAGE_ELEMENT',
  BOARD_REQUEST_UPLOAD_SIGNATURE_PAGE_ELEMENT = 'BOARD_REQUEST_UPLOAD_SIGNATURE_PAGE_ELEMENT',
  BOARD_REQUEST_UPDATE_SIGNATURE_PAGE_ELEMENT = 'BOARD_REQUEST_UPDATE_SIGNATURE_PAGE_ELEMENT',
  BOARD_REQUEST_DELETE_SIGNATURE_PAGE_ELEMENT = 'BOARD_REQUEST_DELETE_SIGNATURE_PAGE_ELEMENT',
  BOARD_REQUEST_START_SIGNATURE = 'BOARD_REQUEST_START_SIGNATURE',
  BOARD_REQUEST_UPLOAD_SIGNATURE_RESOURCE = 'BOARD_REQUEST_UPLOAD_SIGNATURE_RESOURCE',
  BOARD_REQUEST_SUBMIT_SIGNATURE = 'BOARD_REQUEST_SUBMIT_SIGNATURE',
  BOARD_REQUEST_VIEW_SIGNATURE = 'BOARD_REQUEST_VIEW_SIGNATURE',
  BOARD_REQUEST_SIGNEE_UPDATE = 'BOARD_REQUEST_SIGNEE_UPDATE',
  BOARD_REQUEST_SIGNEE_UPLOAD_RESOURCE = 'BOARD_REQUEST_SIGNEE_UPLOAD_RESOURCE',
  BOARD_REQUEST_DOWNLOAD_SIGNATURE_RESOURCE = 'BOARD_REQUEST_DOWNLOAD_SIGNATURE_RESOURCE',
  BOARD_REQUEST_CREATE_WEBAPP_TOKEN = 'BOARD_REQUEST_CREATE_WEBAPP_TOKEN',
  BOARD_REQUEST_WEBAPP_CALLBACK = 'BOARD_REQUEST_WEBAPP_CALLBACK',
  FILE_FLOW_REQUEST_COMMENT_CREATE = 'FILE_FLOW_REQUEST_COMMENT_CREATE',
  FILE_FLOW_REQUEST_COMMENT_UPDATE = 'FILE_FLOW_REQUEST_COMMENT_UPDATE',
  FILE_FLOW_REQUEST_COMMENT_UPLOAD = 'FILE_FLOW_REQUEST_COMMENT_UPLOAD',
  FILE_FLOW_REQUEST_COMMENT_DELETE = 'FILE_FLOW_REQUEST_COMMENT_DELETE',
  SESSION_FLOW_REQUEST_COMMENT_CREATE = 'SESSION_FLOW_REQUEST_COMMENT_CREATE',
  SESSION_FLOW_REQUEST_COMMENT_UPDATE = 'SESSION_FLOW_REQUEST_COMMENT_UPDATE',
  SESSION_FLOW_REQUEST_COMMENT_UPLOAD = 'SESSION_FLOW_REQUEST_COMMENT_UPLOAD',
  SESSION_FLOW_REQUEST_COMMENT_DELETE = 'SESSION_FLOW_REQUEST_COMMENT_DELETE',
  SIGN_FLOW_REQUEST_COMMENT_CREATE = 'SIGN_FLOW_REQUEST_COMMENT_CREATE',
  SIGN_FLOW_REQUEST_COMMENT_UPDATE = 'SIGN_FLOW_REQUEST_COMMENT_UPDATE',
  SIGN_FLOW_REQUEST_COMMENT_UPLOAD = 'SIGN_FLOW_REQUEST_COMMENT_UPLOAD',
  SIGN_FLOW_REQUEST_COMMENT_DELETE = 'SIGN_FLOW_REQUEST_COMMENT_DELETE',
  BOARD_REQUEST_CREATE_WAITING_USER = 'BOARD_REQUEST_CREATE_WAITING_USER',
  BOARD_REQUEST_UPDATE_WAITING_USER = 'BOARD_REQUEST_UPDATE_WAITING_USER',
  BOARD_REQUEST_DELETE_WAITING_USER = 'BOARD_REQUEST_DELETE_WAITING_USER',
  BOARD_REQUEST_UPDATE_RESOURCE = 'BOARD_REQUEST_UPDATE_RESOURCE',
  BOARD_REQUEST_TRANSACTION_CREATE = 'BOARD_REQUEST_TRANSACTION_CREATE',
  BOARD_REQUEST_TRANSACTION_DELETE = 'BOARD_REQUEST_TRANSACTION_DELETE',
  BOARD_REQUEST_TRANSACTION_UPDATE = 'BOARD_REQUEST_TRANSACTION_UPDATE',
  BOARD_REQUEST_TRANSACTION_COPY = 'BOARD_REQUEST_TRANSACTION_COPY',
  BOARD_REQUEST_TRANSACTION_READ = 'BOARD_REQUEST_TRANSACTION_READ',
  BOARD_REQUEST_TRANSACTION_STEP_SUBMIT = 'BOARD_REQUEST_TRANSACTION_STEP_SUBMIT',
  BOARD_REQUEST_TRANSACTION_UPLOAD_ATTACHMENT = 'BOARD_REQUEST_TRANSACTION_UPLOAD_ATTACHMENT',
  BOARD_REQUEST_TRANSACTION_DELETE_ATTACHMENT = 'BOARD_REQUEST_TRANSACTION_DELETE_ATTACHMENT',
  BOARD_REQUEST_TRANSACTION_COMMENT_CREATE = 'BOARD_REQUEST_TRANSACTION_COMMENT_CREATE',
  BOARD_REQUEST_TRANSACTION_COMMENT_UPDATE = 'BOARD_REQUEST_TRANSACTION_COMMENT_UPDATE',
  BOARD_REQUEST_TRANSACTION_COMMENT_DELETE = 'BOARD_REQUEST_TRANSACTION_COMMENT_DELETE',
  BOARD_REQUEST_TRANSACTION_COMMENT_UPLOAD = 'BOARD_REQUEST_TRANSACTION_COMMENT_UPLOAD',
  BOARD_REQUEST_TRANSACTION_STEP_SUBMIT_BATCH = 'BOARD_REQUEST_TRANSACTION_STEP_SUBMIT_BATCH',
  BOARD_REQUEST_SET_FEED_UNREAD = 'BOARD_REQUEST_SET_FEED_UNREAD',
  BOARD_REQUEST_SET_ISTEMP_OFF = 'BOARD_REQUEST_SET_ISTEMP_OFF',
  BOARD_REQUEST_UPDATE_RSVP = 'BOARD_REQUEST_UPDATE_RSVP',
  BOARD_REQUEST_UNITTEST = 'BOARD_REQUEST_UNITTEST',
  BOARD_REQUEST_MAX = 'BOARD_REQUEST_MAX',
  SERVER_AUDIO_CAPACITY = 'SERVER_AUDIO_CAPACITY',
  SERVER_PBX_REPORT = 'SERVER_PBX_REPORT',
  SERVER_DESKTOP_SHARE_CAPACITY = 'SERVER_DESKTOP_SHARE_CAPACITY',
  SERVER_PROBE = 'SERVER_PROBE',
  SERVER_OBJECT_READ = 'SERVER_OBJECT_READ',
  SERVER_OBJECT_SUBSCRIBE = 'SERVER_OBJECT_SUBSCRIBE',
  SERVER_OBJECT_UNSUBSCRIBE = 'SERVER_OBJECT_UNSUBSCRIBE',
  SERVER_OBJECT_ACTIVITY = 'SERVER_OBJECT_ACTIVITY',
  SERVER_OBJECT_AUDIT = 'SERVER_OBJECT_AUDIT',
  SERVER_OBJECT_WRITE = 'SERVER_OBJECT_WRITE',
  SERVER_OBJECT_STATS = 'SERVER_OBJECT_STATS',
  SERVER_OBJECT_LIST_SERVERS = 'SERVER_OBJECT_LIST_SERVERS',
  SERVER_FILE_DOWNLOAD = 'SERVER_FILE_DOWNLOAD',
  SERVER_FILE_UPLOAD = 'SERVER_FILE_UPLOAD',
  SERVER_FILE_PREVIEW = 'SERVER_FILE_PREVIEW',
  SERVER_USER_DISABLE = 'SERVER_USER_DISABLE',
  SERVER_USER_ENABLE = 'SERVER_USER_ENABLE',
  SERVER_USER_LEVEL_UPGRADE = 'SERVER_USER_LEVEL_UPGRADE',
  SERVER_USER_LEVEL_DOWNGRADE = 'SERVER_USER_LEVEL_DOWNGRADE',
  SERVER_USER_READ = 'SERVER_USER_READ',
  SERVER_USER_UP_SIZE = 'SERVER_USER_UP_SIZE',
  SERVER_USER_DOWN_SIZE = 'SERVER_USER_DOWN_SIZE',
  SERVER_REDO_JOB = 'SERVER_REDO_JOB',
  SERVER_FORWARD_REQUEST = 'SERVER_FORWARD_REQUEST',
  SERVER_UPLOAD_CRASH_REPORT = 'SERVER_UPLOAD_CRASH_REPORT',
  SERVER_LIST_CRASH_REPORTS = 'SERVER_LIST_CRASH_REPORTS',
  SERVER_DOWNLOAD_CRASH_REPORT = 'SERVER_DOWNLOAD_CRASH_REPORT',
  SERVER_DELETE_CRASH_REPORT = 'SERVER_DELETE_CRASH_REPORT',
  SERVER_READ_STATISTICS = 'SERVER_READ_STATISTICS',
  SERVER_TOKEN_DECODE = 'SERVER_TOKEN_DECODE',
  SERVER_SERVICE_PROVIDERS_READ = 'SERVER_SERVICE_PROVIDERS_READ',
  SERVER_SERVICE_PROVIDERS_UPDATE = 'SERVER_SERVICE_PROVIDERS_UPDATE',
  SERVER_IDP_CONFIG_READ = 'SERVER_IDP_CONFIG_READ',
  SERVER_WEBAPP_READ = 'SERVER_WEBAPP_READ',
  SERVER_SYSTEM_CONFIG_READ = 'SERVER_SYSTEM_CONFIG_READ',
  SERVER_SYSTEM_CONFIG_UPDATE = 'SERVER_SYSTEM_CONFIG_UPDATE',
  SERVER_REQUEST_VALIDATION_CODE = 'SERVER_REQUEST_VALIDATION_CODE',
  SERVER_GROUP_LIST_ADD = 'SERVER_GROUP_LIST_ADD',
  SERVER_GROUP_LIST_REMOVE = 'SERVER_GROUP_LIST_REMOVE',
  SERVER_CHART_READ = 'SERVER_CHART_READ',
  SERVER_GROUP_USAGE_REPORT = 'SERVER_GROUP_USAGE_REPORT',
  SERVER_PROBE_REPORT = 'SERVER_PROBE_REPORT',
  SERVER_STATISTICS_REPORT = 'SERVER_STATISTICS_REPORT',
  SERVER_REQUEST_MAX = 'SERVER_REQUEST_MAX',
  AGENT_REQUEST_LIST_FOLDER = 'AGENT_REQUEST_LIST_FOLDER',
  AGENT_REQUEST_DOWNLOAD_FILE = 'AGENT_REQUEST_DOWNLOAD_FILE',
  AGENT_REQUEST_PREVIEW_FILE = 'AGENT_REQUEST_PREVIEW_FILE',
  AGENT_REQUEST_UPLOAD_RESOURCE = 'AGENT_REQUEST_UPLOAD_RESOURCE',
  AGENT_REQUEST_UPLOAD_FILE = 'AGENT_REQUEST_UPLOAD_FILE',
  AGENT_REQUEST_UPLOAD_FILE_RESOURCE = 'AGENT_REQUEST_UPLOAD_FILE_RESOURCE',
  AGENT_REQUEST_CREATE_FOLDER = 'AGENT_REQUEST_CREATE_FOLDER',
  AGENT_REQUEST_MOVE_ENTRY = 'AGENT_REQUEST_MOVE_ENTRY',
  AGENT_REQUEST_DELETE_ENTRY = 'AGENT_REQUEST_DELETE_ENTRY',
  AGENT_REQUEST_QUERY_UPLOAD_PROGRESS = 'AGENT_REQUEST_QUERY_UPLOAD_PROGRESS',
  AGENT_PUBLISH_RESPONSE = 'AGENT_PUBLISH_RESPONSE',
  AGENT_SERVE_FILE = 'AGENT_SERVE_FILE',
  AGENT_REQUEST_ONLINE = 'AGENT_REQUEST_ONLINE',
  AGENT_REQUEST_OFFLINE = 'AGENT_REQUEST_OFFLINE',
  AGENT_REQUEST_MAX = 'AGENT_REQUEST_MAX',
  WEBAPP_REQUEST_CREATE = 'WEBAPP_REQUEST_CREATE',
  WEBAPP_REQUEST_READ = 'WEBAPP_REQUEST_READ',
  WEBAPP_REQUEST_UPDATE = 'WEBAPP_REQUEST_UPDATE',
  WEBAPP_REQUEST_DELETE = 'WEBAPP_REQUEST_DELETE',
  WEBAPP_REQUEST_LIST = 'WEBAPP_REQUEST_LIST',
  WEBAPP_REQUEST_LIST_BOT = 'WEBAPP_REQUEST_LIST_BOT',
  WEBAPP_REQUEST_LIST_EMBEDDED = 'WEBAPP_REQUEST_LIST_EMBEDDED',
  WEBAPP_REQUEST_LIST_SUBSCRIPTION = 'WEBAPP_REQUEST_LIST_SUBSCRIPTION',
  WEBAPP_REQUEST_LIST_INBOX_BOT = 'WEBAPP_REQUEST_LIST_INBOX_BOT',
  WEBAPP_REQUEST_DOWNLOAD_RESOURCE = 'WEBAPP_REQUEST_DOWNLOAD_RESOURCE',
  WEBAPP_REQUEST_UPLOAD_RESOURCE = 'WEBAPP_REQUEST_UPLOAD_RESOURCE',
  WEBAPP_REQUEST_UPLOAD_PICTURE = 'WEBAPP_REQUEST_UPLOAD_PICTURE',
  WEBAPP_REQUEST_CREATE_TOKEN = 'WEBAPP_REQUEST_CREATE_TOKEN',
  WEBAPP_REQUEST_MAX = 'WEBAPP_REQUEST_MAX',
  GROUP_REQUEST_CREATE = 'GROUP_REQUEST_CREATE',
  GROUP_REQUEST_REGISTER = 'GROUP_REQUEST_REGISTER',
  GROUP_REQUEST_LIST_AVAILABLE_BASE_DOMAINS = 'GROUP_REQUEST_LIST_AVAILABLE_BASE_DOMAINS',
  GROUP_REQUEST_CHECK_DOMAIN_AVAILABILITY = 'GROUP_REQUEST_CHECK_DOMAIN_AVAILABILITY',
  GROUP_REQUEST_READ = 'GROUP_REQUEST_READ',
  GROUP_REQUEST_READ_MEMBERS = 'GROUP_REQUEST_READ_MEMBERS',
  GROUP_REQUEST_READ_SORT_MEMBERS = 'GROUP_REQUEST_READ_SORT_MEMBERS',
  GROUP_REQUEST_READ_MANAGEMENT_MEMBERS = 'GROUP_REQUEST_READ_MANAGEMENT_MEMBERS',
  GROUP_REQUEST_EXPORT_MEMBERS = 'GROUP_REQUEST_EXPORT_MEMBERS',
  GROUP_REQUEST_EXPORT_USER_ACTIVITIES = 'GROUP_REQUEST_EXPORT_USER_ACTIVITIES',
  GROUP_REQUEST_EXPORT_CLIENT_ENGAGEMENT = 'GROUP_REQUEST_EXPORT_CLIENT_ENGAGEMENT',
  GROUP_REQUEST_EXPORT_INTERNAL_USER_ENGAGEMENT = 'GROUP_REQUEST_EXPORT_INTERNAL_USER_ENGAGEMENT',
  GROUP_REQUEST_EXPORT_CLIENT_COVERAGE = 'GROUP_REQUEST_EXPORT_CLIENT_COVERAGE',
  GROUP_REQUEST_EXPORT_SOCIAL_ENGAGEMENT = 'GROUP_REQUEST_EXPORT_SOCIAL_ENGAGEMENT',
  GROUP_REQUEST_READ_MANAGEMENT_USER_ACTIVITIES = 'GROUP_REQUEST_READ_MANAGEMENT_USER_ACTIVITIES',
  GROUP_REQUEST_READ_MANAGEMENT_USER_BOARDS = 'GROUP_REQUEST_READ_MANAGEMENT_USER_BOARDS',
  GROUP_REQUEST_EXPORT_SERVICE_REQUEST_SUMMARY = 'GROUP_REQUEST_EXPORT_SERVICE_REQUEST_SUMMARY',
  GROUP_REQUEST_EXPORT_SERVICE_REQUEST_AGENT_SUMMARY = 'GROUP_REQUEST_EXPORT_SERVICE_REQUEST_AGENT_SUMMARY',
  GROUP_REQUEST_EXPORT_ACD_SUMMARY = 'GROUP_REQUEST_EXPORT_ACD_SUMMARY',
  GROUP_REQUEST_EXPORT_ACD_AGENT_SUMMARY = 'GROUP_REQUEST_EXPORT_ACD_AGENT_SUMMARY',
  GROUP_REQUEST_UPDATE = 'GROUP_REQUEST_UPDATE',
  GROUP_REQUEST_UPDATE_ALIAS = 'GROUP_REQUEST_UPDATE_ALIAS',
  GROUP_REQUEST_UPDATE_MEMBER_ALIAS = 'GROUP_REQUEST_UPDATE_MEMBER_ALIAS',
  GROUP_REQUEST_READ_ALIAS = 'GROUP_REQUEST_READ_ALIAS',
  GROUP_REQUEST_CANCEL = 'GROUP_REQUEST_CANCEL',
  GROUP_REQUEST_READ_USAGE = 'GROUP_REQUEST_READ_USAGE',
  GROUP_REQUEST_SUBSCRIBE = 'GROUP_REQUEST_SUBSCRIBE',
  GROUP_REQUEST_UNSUBSCRIBE = 'GROUP_REQUEST_UNSUBSCRIBE',
  GROUP_REQUEST_READ_APP_ASSOCIATION = 'GROUP_REQUEST_READ_APP_ASSOCIATION',
  GROUP_REQUEST_READ_APP_ASSETLINKS = 'GROUP_REQUEST_READ_APP_ASSETLINKS',
  GROUP_REQUEST_READ_TELEPHONY_DOMAIN = 'GROUP_REQUEST_READ_TELEPHONY_DOMAIN',
  GROUP_REQUEST_INVITE = 'GROUP_REQUEST_INVITE',
  GROUP_REQUEST_INVITE_CSV_IMPORT = 'GROUP_REQUEST_INVITE_CSV_IMPORT',
  GROUP_REQUEST_DOWNLOAD_TASK_RESOURCE = 'GROUP_REQUEST_DOWNLOAD_TASK_RESOURCE',
  GROUP_REQUEST_JOIN = 'GROUP_REQUEST_JOIN',
  GROUP_REQUEST_JOIN_VIA_INVITATION = 'GROUP_REQUEST_JOIN_VIA_INVITATION',
  GROUP_REQUEST_LEAVE = 'GROUP_REQUEST_LEAVE',
  GROUP_REQUEST_RESEND_INVITATION_EMAIL = 'GROUP_REQUEST_RESEND_INVITATION_EMAIL',
  GROUP_REQUEST_INVITATION_CONFIRM_EMAIL = 'GROUP_REQUEST_INVITATION_CONFIRM_EMAIL',
  GROUP_REQUEST_RESEND_INVITATION_SMS = 'GROUP_REQUEST_RESEND_INVITATION_SMS',
  GROUP_REQUEST_INVITATION_CONFIRM_SMS = 'GROUP_REQUEST_INVITATION_CONFIRM_SMS',
  GROUP_REQUEST_EXPEL = 'GROUP_REQUEST_EXPEL',
  GROUP_REQUEST_REMOVE_MEMBER = 'GROUP_REQUEST_REMOVE_MEMBER',
  GROUP_REQUEST_SET_ACCESS_TYPE = 'GROUP_REQUEST_SET_ACCESS_TYPE',
  GROUP_REQUEST_VIEW_INVITATION = 'GROUP_REQUEST_VIEW_INVITATION',
  GROUP_REQUEST_DOWNLOAD_RESOURCE = 'GROUP_REQUEST_DOWNLOAD_RESOURCE',
  GROUP_REQUEST_UPLOAD_RESOURCE = 'GROUP_REQUEST_UPLOAD_RESOURCE',
  GROUP_REQUEST_USER_READ = 'GROUP_REQUEST_USER_READ',
  GROUP_REQUEST_USER_UPDATE = 'GROUP_REQUEST_USER_UPDATE',
  GROUP_REQUEST_USER_UPDATE_EMAIL = 'GROUP_REQUEST_USER_UPDATE_EMAIL',
  GROUP_REQUEST_USER_UPDATE_EMAIL_PHONE_NUMBER = 'GROUP_REQUEST_USER_UPDATE_EMAIL_PHONE_NUMBER',
  GROUP_REQUEST_USER_DISABLE = 'GROUP_REQUEST_USER_DISABLE',
  GROUP_REQUEST_USER_ENABLE = 'GROUP_REQUEST_USER_ENABLE',
  GROUP_REQUEST_USER_TRANSFER = 'GROUP_REQUEST_USER_TRANSFER',
  GROUP_REQUEST_USER_ARCHIVE = 'GROUP_REQUEST_USER_ARCHIVE',
  GROUP_REQUEST_USERS_READ = 'GROUP_REQUEST_USERS_READ',
  GROUP_REQUEST_IMPORT_REDEEM_URL = 'GROUP_REQUEST_IMPORT_REDEEM_URL',
  GROUP_REQUEST_RESET_REDEEM_URL = 'GROUP_REQUEST_RESET_REDEEM_URL',
  GROUP_REQUEST_GET_REDEEM_URL = 'GROUP_REQUEST_GET_REDEEM_URL',
  GROUP_REQUEST_BOARD_LEAVE = 'GROUP_REQUEST_BOARD_LEAVE',
  GROUP_REQUEST_BOARD_CREATE = 'GROUP_REQUEST_BOARD_CREATE',
  GROUP_REQUEST_BOARD_DELETE = 'GROUP_REQUEST_BOARD_DELETE',
  GROUP_REQUEST_BOARD_ADD_MEMBER = 'GROUP_REQUEST_BOARD_ADD_MEMBER',
  GROUP_REQUEST_BOARD_REMOVE_MEMBER = 'GROUP_REQUEST_BOARD_REMOVE_MEMBER',
  GROUP_REQUEST_BOARD_UPDATE_MEMBER = 'GROUP_REQUEST_BOARD_UPDATE_MEMBER',
  GROUP_REQUEST_BOARD_UPDATE = 'GROUP_REQUEST_BOARD_UPDATE',
  GROUP_REQUEST_SESSION_SCHEDULE = 'GROUP_REQUEST_SESSION_SCHEDULE',
  GROUP_REQUEST_CREATE_RELATION = 'GROUP_REQUEST_CREATE_RELATION',
  GROUP_REQUEST_INVITE_AND_CREATE_RELATION = 'GROUP_REQUEST_INVITE_AND_CREATE_RELATION',
  GROUP_REQUEST_CONFIRM_RELATION = 'GROUP_REQUEST_CONFIRM_RELATION',
  GROUP_REQUEST_TRANSFER_RELATION = 'GROUP_REQUEST_TRANSFER_RELATION',
  GROUP_REQUEST_DELETE_RELATION = 'GROUP_REQUEST_DELETE_RELATION',
  GROUP_REQUEST_UPDATE_RELATION = 'GROUP_REQUEST_UPDATE_RELATION',
  GROUP_REQUEST_CREATE_BOT_RELATION = 'GROUP_REQUEST_CREATE_BOT_RELATION',
  GROUP_REQUEST_DELETE_BOT_RELATION = 'GROUP_REQUEST_DELETE_BOT_RELATION',
  GROUP_REQUEST_CREATE_INTEGRATION = 'GROUP_REQUEST_CREATE_INTEGRATION',
  GROUP_REQUEST_UPDATE_INTEGRATION = 'GROUP_REQUEST_UPDATE_INTEGRATION',
  GROUP_REQUEST_DELETE_INTEGRATION = 'GROUP_REQUEST_DELETE_INTEGRATION',
  GROUP_REQUEST_VERIFY_INTEGRATION = 'GROUP_REQUEST_VERIFY_INTEGRATION',
  GROUP_REQUEST_GET_INTEGRATION_USER_ACCESSTOKEN = 'GROUP_REQUEST_GET_INTEGRATION_USER_ACCESSTOKEN',
  GROUP_REQUEST_STRIPE_WEBHOOK = 'GROUP_REQUEST_STRIPE_WEBHOOK',
  GROUP_REQUEST_STRIPE_CUSTOMER = 'GROUP_REQUEST_STRIPE_CUSTOMER',
  GROUP_REQUEST_STRIPE_SUBSCRIBE = 'GROUP_REQUEST_STRIPE_SUBSCRIBE',
  GROUP_REQUEST_STRIPE_PRICE = 'GROUP_REQUEST_STRIPE_PRICE',
  GROUP_REQUEST_STRIPE_INVOICES = 'GROUP_REQUEST_STRIPE_INVOICES',
  GROUP_REQUEST_STRIPE_COUPON = 'GROUP_REQUEST_STRIPE_COUPON',
  GROUP_REQUEST_STRIPE_PUBLISHABLE_KEY = 'GROUP_REQUEST_STRIPE_PUBLISHABLE_KEY',
  GROUP_REQUEST_CREATE_TEAM = 'GROUP_REQUEST_CREATE_TEAM',
  GROUP_REQUEST_UPDATE_TEAM = 'GROUP_REQUEST_UPDATE_TEAM',
  GROUP_REQUEST_DELETE_TEAM = 'GROUP_REQUEST_DELETE_TEAM',
  GROUP_REQUEST_CREATE_PUBLIC_TEAM = 'GROUP_REQUEST_CREATE_PUBLIC_TEAM',
  GROUP_REQUEST_UPDATE_PUBLIC_TEAM = 'GROUP_REQUEST_UPDATE_PUBLIC_TEAM',
  GROUP_REQUEST_DELETE_PUBLIC_TEAM = 'GROUP_REQUEST_DELETE_PUBLIC_TEAM',
  GROUP_REQUEST_ADD_TEAM_MEMBER = 'GROUP_REQUEST_ADD_TEAM_MEMBER',
  GROUP_REQUEST_REMOVE_TEAM_MEMBER = 'GROUP_REQUEST_REMOVE_TEAM_MEMBER',
  GROUP_REQUEST_LEAVE_TEAM = 'GROUP_REQUEST_LEAVE_TEAM',
  GROUP_REQUEST_REASSIGN_TEAM_OWNER = 'GROUP_REQUEST_REASSIGN_TEAM_OWNER',
  GROUP_REQUEST_SET_TEAM_MEMBER_ACCESS_TYPE = 'GROUP_REQUEST_SET_TEAM_MEMBER_ACCESS_TYPE',
  GROUP_REQUEST_ASSIGN_TELEPHONY_DOMAIN = 'GROUP_REQUEST_ASSIGN_TELEPHONY_DOMAIN',
  GROUP_REQUEST_ADD_INTEGRATION_SUBSCRIBER = 'GROUP_REQUEST_ADD_INTEGRATION_SUBSCRIBER',
  GROUP_REQUEST_REMOVE_INTEGRATION_SUBSCRIBER = 'GROUP_REQUEST_REMOVE_INTEGRATION_SUBSCRIBER',
  GROUP_REQUEST_READ_INTEGRATION_SUBSCRIBERS = 'GROUP_REQUEST_READ_INTEGRATION_SUBSCRIBERS',
  GROUP_REQUEST_SET_BOARD_MEMBER_ACCESS_TYPE = 'GROUP_REQUEST_SET_BOARD_MEMBER_ACCESS_TYPE',
  GROUP_REQUEST_USER_READ_ACTIVITIES = 'GROUP_REQUEST_USER_READ_ACTIVITIES',
  GROUP_REQUEST_READ_GROUP_ACTIVITIES = 'GROUP_REQUEST_READ_GROUP_ACTIVITIES',
  GROUP_REQUEST_USER_POST_ACTIVITIES = 'GROUP_REQUEST_USER_POST_ACTIVITIES',
  GROUP_REQUEST_SUBSCRIBE_SERVICE_REQUESTS = 'GROUP_REQUEST_SUBSCRIBE_SERVICE_REQUESTS',
  GROUP_REQUEST_CREATE_ROLE = 'GROUP_REQUEST_CREATE_ROLE',
  GROUP_REQUEST_UPDATE_ROLE = 'GROUP_REQUEST_UPDATE_ROLE',
  GROUP_REQUEST_DELETE_ROLE = 'GROUP_REQUEST_DELETE_ROLE',
  GROUP_REQUEST_USER_RESET_PASSWORD = 'GROUP_REQUEST_USER_RESET_PASSWORD',
  GROUP_REQUEST_USER_UPDATE_PICTURES = 'GROUP_REQUEST_USER_UPDATE_PICTURES',
  GROUP_REQUEST_USER_UPLOAD_PROFILE_PICTURES = 'GROUP_REQUEST_USER_UPLOAD_PROFILE_PICTURES',
  GROUP_REQUEST_USER_UPLOAD_RESOURCE = 'GROUP_REQUEST_USER_UPLOAD_RESOURCE',
  GROUP_REQUEST_CREATE_SOCIAL_CONNECTION = 'GROUP_REQUEST_CREATE_SOCIAL_CONNECTION',
  GROUP_REQUEST_UPDATE_SOCIAL_CONNECTION = 'GROUP_REQUEST_UPDATE_SOCIAL_CONNECTION',
  GROUP_REQUEST_DELETE_SOCIAL_CONNECTION = 'GROUP_REQUEST_DELETE_SOCIAL_CONNECTION',
  GROUP_REQUEST_READ_SOCIAL_CONNECTION = 'GROUP_REQUEST_READ_SOCIAL_CONNECTION',
  GROUP_REQUEST_JWT_TOKEN = 'GROUP_REQUEST_JWT_TOKEN',
  GROUP_REQUEST_USER_RESET_PASSWORD_SMS = 'GROUP_REQUEST_USER_RESET_PASSWORD_SMS',
  GROUP_REQUEST_CREATE_ROUTING_CHANNEL = 'GROUP_REQUEST_CREATE_ROUTING_CHANNEL',
  GROUP_REQUEST_UPDATE_ROUTING_CHANNEL = 'GROUP_REQUEST_UPDATE_ROUTING_CHANNEL',
  GROUP_REQUEST_DELETE_ROUTING_CHANNEL = 'GROUP_REQUEST_DELETE_ROUTING_CHANNEL',
  GROUP_REQUEST_UPDATE_USER_INVITATION_TOKEN = 'GROUP_REQUEST_UPDATE_USER_INVITATION_TOKEN',
  GROUP_REQUEST_READ_SUBSCRIPTION_BOARDS = 'GROUP_REQUEST_READ_SUBSCRIPTION_BOARDS',
  GROUP_REQUEST_UPDATE_CRM_REPORT = 'GROUP_REQUEST_UPDATE_CRM_REPORT',
  GROUP_REQUEST_READ_CRM_REPORT = 'GROUP_REQUEST_READ_CRM_REPORT',
  GROUP_REQUEST_BOX_ACCESS_TOKEN = 'GROUP_REQUEST_BOX_ACCESS_TOKEN',
  GROUP_REQUEST_MAX = 'GROUP_REQUEST_MAX',
  PRESENCE_REQUEST_READ = 'PRESENCE_REQUEST_READ',
  PRESENCE_USER_REQUEST_READ = 'PRESENCE_USER_REQUEST_READ',
  PRESENCE_REQUEST_MESSAGE = 'PRESENCE_REQUEST_MESSAGE',
  PRESENCE_USER_REQUEST_UPDATE = 'PRESENCE_USER_REQUEST_UPDATE',
  PRESENCE_REQUEST_MAX = 'PRESENCE_REQUEST_MAX',
  PARTNER_REQUEST_CREATE = 'PARTNER_REQUEST_CREATE',
  PARTNER_REQUEST_READ = 'PARTNER_REQUEST_READ',
  PARTNER_REQUEST_UPDATE = 'PARTNER_REQUEST_UPDATE',
  PARTNER_REQUEST_LIST = 'PARTNER_REQUEST_LIST',
  PARTNER_REQUEST_ADD_MEMBER = 'PARTNER_REQUEST_ADD_MEMBER',
  PARTNER_REQUEST_DELETE_MEMBER = 'PARTNER_REQUEST_DELETE_MEMBER',
  PARTNER_REQUEST_VIEW_INVITATION = 'PARTNER_REQUEST_VIEW_INVITATION',
  PARTNER_REQUEST_ADD_PLAN_CODE = 'PARTNER_REQUEST_ADD_PLAN_CODE',
  PARTNER_REQUEST_DELETE_PLAN_CODE = 'PARTNER_REQUEST_DELETE_PLAN_CODE',
  PARTNER_REQUEST_CREATE_INTEGRATION = 'PARTNER_REQUEST_CREATE_INTEGRATION',
  PARTNER_REQUEST_UPDATE_INTEGRATION = 'PARTNER_REQUEST_UPDATE_INTEGRATION',
  PARTNER_REQUEST_DELETE_INTEGRATION = 'PARTNER_REQUEST_DELETE_INTEGRATION',
  PARTNER_REQUEST_READ_STATISTICS = 'PARTNER_REQUEST_READ_STATISTICS',
  PARTNER_REQUEST_READ_PLAN_CODES = 'PARTNER_REQUEST_READ_PLAN_CODES',
  PARTNER_REQUEST_READ_USAGE = 'PARTNER_REQUEST_READ_USAGE',
  PARTNER_REQUEST_CREATE_GROUP = 'PARTNER_REQUEST_CREATE_GROUP',
  PARTNER_REQUEST_LIST_GROUPS = 'PARTNER_REQUEST_LIST_GROUPS',
  PARTNER_REQUEST_CREATE_USERS = 'PARTNER_REQUEST_CREATE_USERS',
  PARTNER_REQUEST_CREATE_TELEPHONY_DOMAIN = 'PARTNER_REQUEST_CREATE_TELEPHONY_DOMAIN',
  PARTNER_REQUEST_UPDATE_TELEPHONY_DOMAIN = 'PARTNER_REQUEST_UPDATE_TELEPHONY_DOMAIN',
  PARTNER_REQUEST_DELETE_TELEPHONY_DOMAIN = 'PARTNER_REQUEST_DELETE_TELEPHONY_DOMAIN',
  PARTNER_REQUEST_LIST_TELEPHONY_DOMAINS = 'PARTNER_REQUEST_LIST_TELEPHONY_DOMAINS',
  PARTNER_REQUEST_READ_TELEPHONY_DOMAIN = 'PARTNER_REQUEST_READ_TELEPHONY_DOMAIN',
  PARTNER_REQUEST_SET_DEFAULT_TELEPHONY_DOMAIN = 'PARTNER_REQUEST_SET_DEFAULT_TELEPHONY_DOMAIN',
  PARTNER_REQUEST_CREATE_TELEPHONE_NUMBER = 'PARTNER_REQUEST_CREATE_TELEPHONE_NUMBER',
  PARTNER_REQUEST_UPDATE_TELEPHONE_NUMBER = 'PARTNER_REQUEST_UPDATE_TELEPHONE_NUMBER',
  PARTNER_REQUEST_DELETE_TELEPHONE_NUMBER = 'PARTNER_REQUEST_DELETE_TELEPHONE_NUMBER',
  PARTNER_REQUEST_UPLOAD_TELEPHONE_NUMBER_RESOURCE = 'PARTNER_REQUEST_UPLOAD_TELEPHONE_NUMBER_RESOURCE',
  PARTNER_REQUEST_DOWNLOAD_TELEPHONE_NUMBER_RESOURCE = 'PARTNER_REQUEST_DOWNLOAD_TELEPHONE_NUMBER_RESOURCE',
  PARTNER_REQUEST_MAX = 'PARTNER_REQUEST_MAX',
  TELEPHONY_REQUEST_ONCALLIN = 'TELEPHONY_REQUEST_ONCALLIN',
  TELEPHONY_REQUEST_SUBMIT_SESSIONKEY = 'TELEPHONY_REQUEST_SUBMIT_SESSIONKEY',
  TELEPHONY_REQUEST_SUBMIT_PARTICIPANTNUM = 'TELEPHONY_REQUEST_SUBMIT_PARTICIPANTNUM',
  TELEPHONY_REQUEST_ONLEAVE = 'TELEPHONY_REQUEST_ONLEAVE',
  TELEPHONY_REQUEST_ON_SIPGATEWAY_CALL = 'TELEPHONY_REQUEST_ON_SIPGATEWAY_CALL',
  TELEPHONY_REQUEST_POST_SIPGATEWAY_CALL = 'TELEPHONY_REQUEST_POST_SIPGATEWAY_CALL',
  TELEPHONY_REQUEST_ON_TEXT_MESSAGE = 'TELEPHONY_REQUEST_ON_TEXT_MESSAGE',
  TELEPHONY_REQUEST_ON_VOICE_MESSAGE = 'TELEPHONY_REQUEST_ON_VOICE_MESSAGE',
  TELEPHONY_REQUEST_MAX = 'TELEPHONY_REQUEST_MAX',
  ROUTING_ACD_REQUEST_CREATE = 'ROUTING_ACD_REQUEST_CREATE',
  ROUTING_ACD_REQUEST_UPDATE = 'ROUTING_ACD_REQUEST_UPDATE',
  ROUTING_ACD_REQUEST_ACCEPT = 'ROUTING_ACD_REQUEST_ACCEPT',
  ROUTING_ACD_REQUEST_DECLINE = 'ROUTING_ACD_REQUEST_DECLINE',
  ROUTING_ACD_REQUEST_READ_OFFICE_HOUR = 'ROUTING_ACD_REQUEST_READ_OFFICE_HOUR',
  ROUTING_SERVICE_REQUEST_SUBSCRIBE = 'ROUTING_SERVICE_REQUEST_SUBSCRIBE',
  ROUTING_SERVICE_REQUEST_LIST = 'ROUTING_SERVICE_REQUEST_LIST',
  ROUTING_SERVICE_REQUEST_CREATE = 'ROUTING_SERVICE_REQUEST_CREATE',
  ROUTING_SERVICE_REQUEST_UPDATE = 'ROUTING_SERVICE_REQUEST_UPDATE',
  ROUTING_REQUEST_MAX = 'ROUTING_REQUEST_MAX',
  SEARCH_REQUEST_INDEX = 'SEARCH_REQUEST_INDEX',
  SEARCH_REQUEST_SEARCH = 'SEARCH_REQUEST_SEARCH',
  SEARCH_REQUEST_MAX = 'SEARCH_REQUEST_MAX',
  SSO_SP_REQUEST_GET = 'SSO_SP_REQUEST_GET',
  SSO_SP_REQUEST_POST = 'SSO_SP_REQUEST_POST',
  SSO_SP_REQUEST_MAX = 'SSO_SP_REQUEST_MAX',
  SSO_IDP_REQUEST_GET = 'SSO_IDP_REQUEST_GET',
  SSO_IDP_REQUEST_POST = 'SSO_IDP_REQUEST_POST',
  SSO_REQUEST_MAX = 'SSO_REQUEST_MAX',
  ACTIVITY_REQUEST_QUERY = 'ACTIVITY_REQUEST_QUERY',
  ACTIVITY_REQUEST_MAX = 'ACTIVITY_REQUEST_MAX'
}
