import {ComponentOption} from "../index";
import Vue from 'vue'
import Vuex from 'vuex'
import cardsdk from '../../../../views/store/modules/cardsdk'


export class DataChannelProvider{

    private static dataInstance: DataChannelProvider

    eventListenerMap:Map<string, Function>;

    private constructor(componentOption?: ComponentOption) {

        this.eventListenerMap = new Map<string, Function>()

        Vue.use(Vuex)

        let store = new Vuex.Store({
            modules: { cardsdk }
        })

        store.watch( (state: any)=>{
            const boards = state.cardsdk.userBoards
            let count = 0
            boards.forEach( board => {
                if( board.is_service_request && (board.routing_status === 'ROUTING_STATUS_OPEN'
                    || board.routing_status === 'ROUTING_STATUS_IN_PROGRESS'
                    || board.routing_status === 'ROUTING_STATUS_CLOSE'
                    || board.routing_status == 'ROUTING_STATUS_AGENT_COMPLETE')){
                    count += board.feed_unread_count
                }
            })
            return count
        }, (v) => {
            if(!isNaN(v))
                this.onInternalMsg({event_name: 'unreadMsgNumber', value: v})
        });
        store.watch( (state: any)=>{
            const boards = state.cardsdk.userBoards
            let count = 0
            boards.forEach( board => {
                if( board.is_acd && (board.routing_status === 'ROUTING_STATUS_OPEN'
                    || board.routing_status === 'ROUTING_STATUS_IN_PROGRESS')){
                    count += board.feed_unread_count
                }
            })
            return count
        }, (v) => {
            if(!isNaN(v))
                this.onInternalMsg({event_name: 'unreadMsgNumberAcd', value: v})
        });
        store.dispatch('cardsdk/initSRSubscriber',null ,{root: true})

    }

    public static getInstance(options?: ComponentOption)  {
        if(!DataChannelProvider.dataInstance){
            DataChannelProvider.dataInstance = new DataChannelProvider(options)
        }
        return DataChannelProvider.dataInstance
    }

    onEvent(event: string, callback: Function) {
        this.eventListenerMap[event] = callback;
    }

    onInternalMsg(msg: any){

        if ( msg.event_name ) {
            let callback: Function = this.eventListenerMap[msg.event_name]
            callback && callback(msg.value)
        }
    }
}
