var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "request-type" }, [
    _c(
      "span",
      {
        style: {
          backgroundColor: _vm.srBoard.srChannelColor,
          color: _vm.srBoard.srChannelFontColor
        }
      },
      [_vm._v(" " + _vm._s(_vm.srBoard.srChannelName) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }