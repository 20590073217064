import {MxConsts} from "@commonUtils";
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('cardsdk', ['getUserCap']),
    },
    methods: {
        getSizeObj (size) {
            if (size >= 1024 * 1024) {
                return {
                    number: Math.floor(size / 1024 / 1024),
                    unit: 'MB'
                }
            } else if (size >= 1024) {
                return {
                    number: Math.floor(size / 1024),
                    unit: 'KB'
                }
            } else {
                return {
                    number: Math.floor(size),
                    unit: 'B'
                }
            }
        },
        attachmentFailureReason(error) {
            const ErrorCode = MxConsts.ErrorCode
            let errorMessage = `attachment failed`
            const maxFileSize = this.getUserCap && this.getUserCap.client_max_body_size
            let code = error && error.code || ''
            switch (code) {
                case ErrorCode.ExceedClientBodyMax: {
                    if (maxFileSize) {
                        let sizeObj = this.getSizeObj(maxFileSize)
                        errorMessage = `We don't accept files larger than ${sizeObj.number} ${sizeObj.unit}`
                    }
                    break
                }
                case ErrorCode.FileTypeNotSupport: {
                    errorMessage = `File type not supported.`
                    break
                }
                default: {
                    break
                }
            }
            return errorMessage
        },
    }
}