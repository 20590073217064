import { mxLogger } from '../util';
import { MxSubscription, MxCallback } from "../api/defines";


class MxSubscriptionImpl implements MxSubscription {
    private _unsubscribe: Function ;
    private _name: string;
    constructor(unsubscribe: Function, name: string ='') {
        this._unsubscribe = unsubscribe;
        this._name = name;
    }

    unsubscribe(): void {
        mxLogger.debug(`unsubscribe ${this._name}`);
        if (this._unsubscribe) {
            this._unsubscribe();
        }
    }
}

export class MxObservable<T> {
    private _observers: Map<MxSubscription, MxCallback<T>>;
    private _name: string;

    constructor(name: string ='') {
        this._observers = new Map<MxSubscription, MxCallback<T>>();
        this._name = name;
    }

    subscribe(cb: MxCallback<T>): MxSubscription {
        mxLogger.debug(`subscribe ${this._name}`);
        let sup = new MxSubscriptionImpl(() => {
            this._observers.delete(sup);
        }, this._name);
        this._observers.set(sup, cb);
        return sup;
    }

    unsubscribeAll(): void {
        this._observers.clear();
    }

    publish(val?: T): void {
        try {
            this._observers.forEach((cb, key, m) => {
                mxLogger.debug(`callback ${this._name}:`, val);
                cb(val);
            });
        }catch(e) {
            mxLogger.error(`callback ${this._name} exception:`, e);
        }
    }

    hasObservers(): boolean {
        return this._observers.size > 0 ? true : false;
    }

    observersCount(): number {
        return this._observers.size;
    }


}