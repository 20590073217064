import {createComponentByOption} from "./components/ComponentFactory"
import {MxComponent} from "./components/MxComponent"
import {BankMsgSummaryMeta} from "./components/BankMsgSummary"
import {BankMsgDetailsMeta} from "./components/BankMsgDetails"
import {MxLogger} from "@controller/utils"
import {AcdChatCallback, MainController, SetupOption} from "@controller/cardsdk/src/MainController";
import {DataChannelProvider} from "./components/DataChannelProvider";
import {version} from './CardsdkVersion'

// import {MainController, SetupOption} from "@controller/cardsdk/src/MainController"
// import {Form, FormItem, Input} from 'element-ui';
// import Vue from 'vue';

export interface ComponentOption {
    selector: string
    componentId: string
    showSendUI?: boolean
    baseDomain?: string
}

let globalSetupOption: SetupOption

export function getSetupOption(): SetupOption {
    return globalSetupOption
}

export function setup(setupOption: SetupOption): Promise<boolean> {
    // Vue.component(Input.name, Input);
    // Vue.component(Form.name, Form);
    // Vue.component(FormItem.name, FormItem);


    globalSetupOption = setupOption
    // return new Promise<boolean>( ((resolve, reject) => {
    //     resolve(true)
    // }))
    return MainController.setup(setupOption)
}

export function provideNewToken(token: string): Promise<boolean> {
    return MainController.provideNewToken(token)
}

export function showChatWrapper(container, binderId, options?: AcdChatCallback){
    return MainController.showChatWrapper(container, binderId, options)
}

export function setUseBranding(enabled: boolean) {
    return MainController.setUseBranding(enabled)
}

export function listAcdChannels(){
    return MainController.listAcdChannels()
}

export function openAcdChat(container: string, channelId: number, options?: AcdChatCallback): Promise<void>{
    return MainController.openAcdChat(container, channelId, options)
}

export function endAcdChat(channelId: number): Promise<void>{
    return MainController.endAcdChat(channelId)
}

export function openAcdChatWithToken(container: string, anonymousBoardInfo: any, options?: AcdChatCallback): Promise<void>{
    return MainController.openAcdChatWithToken(container, anonymousBoardInfo, options)
}

export function createComponent(componentOption: ComponentOption): MxComponent {
    MxLogger.info(`create component ${componentOption}`)
    return createComponentByOption(componentOption)
}

export function showComponent(selector: string) {
    MxLogger.info(`show component ${selector}`)
}

export function hideComponent(selector: string) {
    MxLogger.info(`hide component ${selector}`)
}

export function destroyComponent(selector: string) {
    MxLogger.info(`destroy component ${selector}`)
}

export function getDataChannel(componentOption?: ComponentOption){
    return  DataChannelProvider.getInstance(componentOption);
}

export function setLanguage (lang: string){
    return MainController.setLanguage(lang)
}

export {version}

export {BankMsgSummaryMeta, BankMsgDetailsMeta}

