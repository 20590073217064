let timers ={}
import {MxConsts} from "@commonUtils";

export default {
  updateBinderId (state, id) {
    state.binderId = id
  },
  REMOVE_FROM_UPLOAD_QUEUE (state, fileUid) {
    if (fileUid) {
      let find = state.files.findIndex((file) => file.uuid === fileUid);
      if (find >= 0) {
        let file = state.files[find]
        if (file.status === MxConsts.UploadStatus.UPLOADED) {
          state.uploadedFiles++
        }
        if (file.xhr && (file.status === MxConsts.UploadStatus.PENDING || file.status === MxConsts.UploadStatus.INPROGRESS)) {
          state.totalFiles--
          file.xhr.abort()
        }
        state.files.splice(find, 1);
        delete timers[fileUid];
      }
    } else {
      state.files = []
      timers = {}
    }
  },
  ADD_TO_UPLOAD_QUEUE (state, files) {
    const storeFiles = state.files;
    state.files = storeFiles.concat(files)
    state.allFilesResolved = false
    state.totalFiles = state.files.length
  },
  UPDATE_UPLOAD_FILE (state, updateds) {
    if(!Array.isArray(updateds)){
      updateds = [updateds]
    }
    updateds.forEach((file)=>{
      state.files.forEach((item, index) => {
        if (item.uuid === file.id) {
          if(!timers[item.uuid] || (Date.now() - timers[item.uuid]) > 200 || file.percentage === 100 || file.error){
            timers[item.uuid] = Date.now();
            Object.assign(item, file);
            state.files[index] = item;
          }
        }
        return item;
      })
    })
    state.allFilesResolved = state.files.every(file=>file.status === MxConsts.UploadStatus.CANCELED
      || file.status === MxConsts.UploadStatus.ERROR
      || file.status === MxConsts.UploadStatus.UPLOADED)
  },
  updateComment(state, comment) {
    state.comment = comment
  },
  resetState (state) {
    state.allFilesResolved = false
    state.binderId = ''
    state.totalFiles = 0
    state.uploadedFiles = 0
  }
}
