import { ActionLaserPointer } from './../../proto/generated/ActionLaserPointer';
import { BoardResource } from '../..//proto/generated/BoardResource';
import { ActionObject } from './../../proto/generated/ActionObject';
import { TelephonyConf } from './../../proto/generated/TelephonyConf';
import { DesktopShareConf } from './../../proto/generated/DesktopShareConf';
import { AudioConf } from './../../proto/generated/AudioConf';
import { ActionUserRoster } from './../../proto/generated/ActionUserRoster';
import { DesktopShareState } from '../../proto/generated/DesktopShareState';
import { SessionStatus } from '../..//proto/generated/SessionStatus';
import { SessionRecordingStatus } from '../..//proto/generated/SessionRecordingStatus';
import { SessionVideoState } from '../..//proto/generated/SessionVideoState';
import { BoardUser } from '../..//proto/generated/BoardUser';
import { Board } from '../..//proto/generated/Board';
import { BoardComment } from '../../proto/generated/BoardComment';
import { BoardPage } from '../../proto/generated/BoardPage';
import { ActionPageSwitch } from '../../proto/generated/ActionPageSwitch';
import { ActionVideoState } from '../../proto/generated/ActionVideoState';
import { MxCallback, MxSubscription, MxError } from './../../api/defines';
import { MxObservable } from "../../core/mxObserver";
import { mxEvent, eventType } from '../../core/event';
import { MeetCache } from './../cache/meetCache';

export class MxMeetSubscriber {
    private _meetCache: MeetCache;

    private _usersObservable : MxObservable<BoardUser[]>;
    private _rostersObservable : MxObservable<ActionUserRoster[]>;
    private _commentsObservable : MxObservable<BoardComment[]>;
    private _pagesObservable : MxObservable<BoardPage[]>;
    private _resourcesObservable : MxObservable<BoardResource[]>;
    private _audioConfObservable : MxObservable<AudioConf>;
    private _dsConfObservable : MxObservable<DesktopShareConf>;
    private _telephonyConfObservable : MxObservable<TelephonyConf>;
    private _videoStateObservable : MxObservable<SessionVideoState>;
    private _dsStateObservable : MxObservable<DesktopShareState>;
    private _sessionStatusObservable : MxObservable<SessionStatus>;
    private _recordingStatusObservable : MxObservable<SessionRecordingStatus>;
    private _isAudioCallObservable : MxObservable<boolean>;
    private _sharingPageObservable : MxObservable<ActionPageSwitch>;
    private _actionVideoStateObservable : MxObservable<ActionVideoState>;
    private _actionLaserPointerObservable : MxObservable<ActionLaserPointer>;
    private _sessionErrorObservable : MxObservable<MxError>;
    private _activeSpeakerObservable : MxObservable<string>;

    constructor(meetCache: MeetCache) {
        this._meetCache = meetCache;

        this._usersObservable = new MxObservable('meet.users');
        this._rostersObservable = new MxObservable('meet.rosters');
        this._commentsObservable = new MxObservable('meet.comments');
        this._pagesObservable = new MxObservable('meet.pages');
        this._resourcesObservable = new MxObservable('meet.resources');
        this._audioConfObservable = new MxObservable('meet.audioConf');
        this._dsConfObservable = new MxObservable('meet.dsConf');
        this._telephonyConfObservable = new MxObservable('meet.telephonyConf');
        this._videoStateObservable = new MxObservable('meet.videoState');
        this._dsStateObservable = new MxObservable('meet.dsState');
        this._sessionStatusObservable = new MxObservable('meet.sessionStatus');
        this._recordingStatusObservable = new MxObservable('meet.recordingStatus');
        this._isAudioCallObservable = new MxObservable('meet.isAudioCall');
        this._sharingPageObservable = new MxObservable('meet.sharingPage');
        this._actionVideoStateObservable = new MxObservable('meet.actionVideoState');
        this._actionLaserPointerObservable = new MxObservable('meet.actionLaserPointer');
        this._sessionErrorObservable = new MxObservable('meet.sessionError');
        this._activeSpeakerObservable = new MxObservable('meet.activeSpeaker');

        mxEvent.on(eventType.MEET_SESSION_ERROR, (err) => {
            this._sessionErrorObservable.publish(err);
        })
    }

    subscribeUsers(cb: MxCallback<BoardUser[]>): MxSubscription {
        return this._usersObservable.subscribe(cb);
    }

    subscribeRosters(cb: MxCallback<ActionUserRoster[]>): MxSubscription {
        return this._rostersObservable.subscribe(cb);
    }

    subscribeComments(cb: MxCallback<BoardComment[]>): MxSubscription {
        return this._commentsObservable.subscribe(cb);
    }

    subscribePages(cb: MxCallback<BoardPage[]>): MxSubscription {
        return this._pagesObservable.subscribe(cb);
    }

    subscribeResources(cb: MxCallback<BoardResource[]>): MxSubscription {
        return this._resourcesObservable.subscribe(cb);
    }

    subscribeAudioConf(cb: MxCallback<AudioConf>): MxSubscription {
        return this._audioConfObservable.subscribe(cb);
    }

    subscribeDSConf(cb: MxCallback<DesktopShareConf>): MxSubscription {
        return this._dsConfObservable.subscribe(cb);
    }

    subscribeTelephonyConf(cb: MxCallback<TelephonyConf>): MxSubscription {
        return this._telephonyConfObservable.subscribe(cb);
    }

    subscribeVideoState(cb: MxCallback<SessionVideoState>): MxSubscription {
        return this._videoStateObservable.subscribe(cb);
    }

    subscribeDSState(cb: MxCallback<DesktopShareState>): MxSubscription {
        return this._dsStateObservable.subscribe(cb);
    }

    subscribeSessionStatus(cb: MxCallback<SessionStatus>): MxSubscription {
        return this._sessionStatusObservable.subscribe(cb);
    }

    subscribeRecordingStatus(cb: MxCallback<SessionRecordingStatus>): MxSubscription {
        return this._recordingStatusObservable.subscribe(cb);
    }

    subscribeIsAudioCall(cb: MxCallback<boolean>): MxSubscription {
        return this._isAudioCallObservable.subscribe(cb);
    }

    subscribeSharingPage(cb: MxCallback<ActionPageSwitch>): MxSubscription {
        return this._sharingPageObservable.subscribe(cb);
    }

    subscribeActionVideoState(cb: MxCallback<ActionVideoState>): MxSubscription {
        return this._actionVideoStateObservable.subscribe(cb);
    }

    subscribeLaserPointer(cb: MxCallback<ActionLaserPointer>): MxSubscription {
        return this._actionLaserPointerObservable.subscribe(cb);
    }

    subscribeSessionError(cb: MxCallback<MxError>): MxSubscription {
        return this._sessionErrorObservable.subscribe(cb);
    }

    subscribeActiveSpeaker(cb: MxCallback<string>): MxSubscription {
        return this._activeSpeakerObservable.subscribe(cb);
    }

    onObjectUpdate(board: Board, session: ActionObject): void {
        if (board) {
            if (board.users && board.users.length > 0) {
                this._usersObservable.publish(board.users);
            }
    
            if (board.comments && board.comments.length > 0) {
                this._commentsObservable.publish(board.comments);
            }
    
            if (board.pages && board.pages.length > 0) {
                let fullPages = this._meetCache.getPages(board.pages.map(page => page.sequence));
                this._pagesObservable.publish(fullPages);
            }

            if (board.resources && board.resources.length > 0) {
                this._resourcesObservable.publish(board.resources);
            }

            if (board.tags && board.tags.length > 0) {
                this._isAudioCallObservable.publish(this._meetCache.isAudioCall());
            }
        }

        if (session) {
            if (session.user_roster && session.user_roster.length > 0) {
                this._rostersObservable.publish(session.user_roster);
            }
    
            if (session.audio_conf) {
                this._audioConfObservable.publish(session.audio_conf);
            }
    
            if (session.ds_conf) {
                this._dsConfObservable.publish(session.ds_conf);
            }
    
            if (session.telephony_conf) {
                this._telephonyConfObservable.publish(session.telephony_conf);
            }
    
            if (session.session_video_state && session.session_video_state.length > 0) {
                this._videoStateObservable.publish(session.session_video_state.slice(-1)[0]);
            }
            
            if (session.ds_state && session.ds_state.length > 0) {
                this._dsStateObservable.publish(session.ds_state.slice(-1)[0]);
            }
    
            if (session.recording_state && session.recording_state.length > 0) {
                this._recordingStatusObservable.publish(session.recording_state.slice(-1)[0].status);
            }

            if (session.video_state && session.video_state.length > 0) {
                this._actionVideoStateObservable.publish(session.video_state.slice(-1)[0]);
            }

            if (session.laser_pointer) {
                this._actionLaserPointerObservable.publish(session.laser_pointer);
            }

            if (session.page_switch) {
                this._sharingPageObservable.publish(session.page_switch);
            }

            if (session.active_speaker_id !== undefined) {
                this._activeSpeakerObservable.publish(session.active_speaker_id);
            }

            if (session.session_status) {
                this._sessionStatusObservable.publish(session.session_status);
            }else if (session.is_deleted) {
                this._sessionStatusObservable.publish(SessionStatus.SESSION_ENDED);
            }else if (session.is_expired) {
                this._sessionStatusObservable.publish(SessionStatus.SESSION_EXPIRED);
            }

        }
    }
}