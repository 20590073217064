import {MxLogger} from "@commonUtils/MxLogger";
import {ObjectUtils} from "@commonUtils/object";
import {BrowserUtils} from "@commonUtils/browser";

export function isInIframe() {
  let parentWindow = window.parent;
  let inIframe = parentWindow && parentWindow !== window;
  if (inIframe) {
    if (BrowserUtils.isTAEnv && parentWindow === window.top) {
      //is cypress TA env then ignore
      return false
    }
  }
  return inIframe;
}

export function isString(obj: any){
  return typeof(obj) ==='string'
}
export interface IPCMessage {
  action: string
  error?: string
  type: string
  params?: any[]
  id: string
  reject?: any
  resolve?: any,
  timeoutid?: any,
  isValue?: boolean,
  isReplay?: boolean,
  ircId:string
}
let inIframe = isInIframe()
const logger =  MxLogger.create('IRC')

export function formatIPCMessage(method: string, params: any = [], id?: string, errorMessage?: string) {
  if (!Array.isArray(params)) {
    params = [params]
  }
  return {
    action: 'ipcMessage',
    error: errorMessage,
    type: method,
    params: params,
    id: id || (+Date.now() + Math.random() * 100 + Math.random()),
    ircId:''
  } as IPCMessage;
}


export class IframeRemoteCall {

  private _selfWin: any
  private _otherWin: any
  private _requestCache: Map<string,IPCMessage>
  private _variables: any
  private destroyed: boolean
  private _apiList: any
  private _variablesRequest: any
  // private _clients: Window[]
  private __onMessageHandler__:any
  private _id:string
  private isService:boolean
  //_connectId is client ircId
  public connectId:string
  // is for share socket data to iframe
  private _clients:any[]
  public ready:Promise<any>
  private action:string

  constructor(selfWin: any, otherWin?: any, action = 'ipcMessage') {
    this._selfWin = selfWin
    this._otherWin = otherWin
    this._requestCache = new Map()
    this._variables = new Map()
    this._variablesRequest = new Map()
    this._apiList = {}
    this._clients =[];
    this.action = action
    this.destroyed = false;
    selfWin.addEventListener('message', this._onMessageHandler());
    //init system method
    logger.debug('init IRC')
    return this;
  }

  _send(win: any, message: IPCMessage, origin?: string) {
    logger.debug( 'send', message)
    try {
      message.action = this.action
      win.postMessage(JSON.stringify(message), origin || '*');
    }catch (e) {
      console.warn('[IRC] source window is required');
    }
  }

  _sendValue(win: any, key: any, value: any) {
    var message = formatIPCMessage(key, value);
    message.isValue = true;
    message.action = this.action

    this._send(win, message);
  }

  _getValue(win: Window, key: string) {
    return this._remoteCall(win, '$irc.get', [key])
  }

  _getLocalValue(name: string) {
    return this._variables[name];
  }

  _remoteCall(win: any, method: any, params?: any[]) {
    var message: IPCMessage = formatIPCMessage(method, params);
    this._send(win, message);
    message.timeoutid = method !== 'websdkConfig' && this._waitTimeout(message.id);
    this._requestCache.set(message.id, message);
    return new Promise(function (resolve, reject) {
      message.resolve = resolve;
      message.reject = reject;

    });
  }

  _replay(win: any, request: IPCMessage, result: any, errorMessage?: string) {
    var message: IPCMessage = formatIPCMessage(request.type, [result], request.id, errorMessage);
    message.isReplay = true;
    this._send(win, message);
  }

  _waitTimeout(id: string): any {
    let _requestCache = this._requestCache;
    return setTimeout(() => {
      var message =_requestCache.get(id);
      logger.debug('RequestTimeout:',message)
      _requestCache.delete(id)
      if (message && message.reject) {
        message.reject(`Type: ${message.type} ,relevant process did not respond in 30 seconds.`);
      }
    }, 30 * 1000);
  }

  _onMessageHandler() {
    if (!this.__onMessageHandler__) {
      this.__onMessageHandler__ = (event: any) => {
        let requestMessage: IPCMessage;
        if (event.data) {
          try {
            if(!isString(event.data)){
              return;
            }
            requestMessage = JSON.parse(event.data);
            if(this.connectId && requestMessage.ircId && this.connectId !== requestMessage.ircId){
              return ;
            }
            if (requestMessage && requestMessage.action === this.action) {
              logger.debug( 'onMessage', event.data);
              //data.params array []
              if (requestMessage.isReplay) {
                var res: IPCMessage = requestMessage;
                requestMessage = this._requestCache.get(requestMessage.id);
                if (!requestMessage) {
                  return;
                }
                if (res.error) {
                  requestMessage.reject(res.error);
                } else {
                  requestMessage.resolve(res.params[0]);
                }
                if (requestMessage.timeoutid) {
                  clearTimeout(requestMessage.timeoutid);
                }
                requestMessage.reject = requestMessage.resolve = null;
                this._requestCache.delete(requestMessage.id);
              } else if (requestMessage.isValue) {
                var nowVal = this._variables.get(requestMessage.type);
                var newVal = requestMessage.params[0];
                if (nowVal !== newVal) {
                  this._variables.set(requestMessage.type, newVal);
                  let resolve = this._variablesRequest.get(requestMessage.type);
                  if (resolve) {
                    resolve(newVal);
                    this._variablesRequest.delete(requestMessage.type)
                  }

                }
                //this._notifyWatch(requestMessage);
              } else {

                var fn = this._apiList[requestMessage.type];
                let win = event.source || this._otherWin ;
                if (fn) {
                  let params = requestMessage.params||[];
                  if(requestMessage.type == '$ping'){
                    params =[win].concat(params)
                  }
                  let isEvent = (requestMessage.type.indexOf('event.')===0)
                  fn.apply({sourceWindow: win}, params).then((result: any) => {
                    if(!isEvent)
                    {
                      this._replay(win, requestMessage, result);
                    }
                  }, (event: any) => {
                    if(!isEvent) {
                      if (event.name) {
                        // customized error object
                        this._replay(win, requestMessage, null, event);
                      } else {
                        this._replay(win, requestMessage, null, event.message);
                      }
                    }
                  });
                } else {
                  let msg = 'Not define the IPC call [' + requestMessage.type + ']'
                  this._replay(win, requestMessage, null, msg);
                  logger.debug(msg);
                }
              }
            }
          } catch (e) {

          }
        }
      }
      return this.__onMessageHandler__
    }
  }

  /**
   * call a remote method
   * @param {String} method  the name of the method
   * @param {Array} params  the passed parameters
   * @returns {Promise}
   */
  call(method: string, ...params: any[]) {
    if (this.destroyed) {
      return Promise.reject('')
    }
      let i = params.length;
      for (; i--; i > -1) {
          if (!ObjectUtils.isDefine(params[i])) {
              params.pop()
          } else {
              break;
          }
      }
    logger.debug( 'call', method, params)
    if (this._otherWin) {
      return this._remoteCall(this._otherWin, method, params);
    }
    logger.debug('call',method ,'failed')
    return Promise.resolve({});
  }

  /**
   * register a sync call
   * @param name the function name
   * @param fn  the function should return a Promise object
   * @param scope Specifies the context of the function
   */
  registerCall(name: string, fn: any, scope?: any) {
    logger.debug( 'registerCall', name)
    var asyncFn = (...args: string[]) => {
      var sourceWindow = this['sourceWindow']
      return new Promise(function (resolve) {
        scope = scope || {};
        scope.sourceWindow = sourceWindow
        resolve(fn.apply(scope, args));
      });
    };
    this.registerAsyncCall(name, asyncFn);
  }

  registerAsyncCall(name: string, fn: any) {
    this._apiList[name] = fn;
  }

  /**
   * get a variable from other window
   * @param name the name of the variable
   * @returns {Promise}
   */
  get(name: string) {
    let val =  this._variables.get(name);
    logger.debug( 'get', name, val);
    return val;
  }

  /**
   * set a variable to other window
   * @param name  the name of the variable
   * @param value
   */
  set(name: string, value: any) {
    logger.debug( 'set', name, value)
    this._variables.set(name, value);
    if (this._otherWin) {
      this._sendValue(this._otherWin, name, value);
    }
  }

  addClient(win:Window){
    if(this._clients.indexOf(win)<0) {
      this._clients.push(win);
    }
  }
  callClient(method:string,params:any){
    let message = formatIPCMessage(method,params);
    this._clients.forEach((win)=>{
      this._send(win,message);
    })
  }
  values() {
    let result = {};
    this._variables && this._variables.forEach((v: string, k: any) => {
      result[k] = v;
    });
    return result;
  }
  reset() {
    this._requestCache.clear();
    this._variablesRequest.clear();
    this._otherWin = null;
    this._clients = [];
    this._apiList = {}
  }
  destroy() {
    this._selfWin.removeEventListener('message', this._onMessageHandler());
    this._variables = null;
    this._apiList = null;
    this._selfWin = null;
    this._otherWin = null;
    this._requestCache = null;
    this.destroyed = true;
  }
}
