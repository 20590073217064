import {FunctionUtil, MxConsts, ObjectUtils} from "@commonUtils";
import {FileController} from "@controller/files/src/fileController";
import {UserController} from "@controller/user/src/userController";
import {MxISDK} from "isdk"

const ErrorCode = MxConsts.ErrorCode

const config = () => {
	return {
		accessToken: '',
		sessionId: '',
		clientVersion: ''
	}
}

class ClientError extends Error {
	protected data: any;
	public code: Number;

	constructor(errorCode: Number, data?: any, message?: string) {
		super(message || `client error: [code] ${errorCode}`);
		this.code = errorCode;
		this.data = data
	}
}

function transformServerUploadError(response: any) {
	let code: Number;
	switch (response.detail_code) {
		case 'EXCEED_UPLOAD_CLIENT_BODY_MAX':
			code = ErrorCode.ExceedClientBodyMax;
			break;
		case 'EXCEED_USER_CLOUD_MAX':
			code = ErrorCode.ExceedUserCloudMax;
			break;
		case 'ERROR_VIRUS_DETECTED':
			code = ErrorCode.UploadDetectedVirus;
			break;
		case 'ERROR_FILE_TYPE_NOT_SUPPORTED':
			code = ErrorCode.FileTypeNotSupport;
			break;
		case 'EXCEED_BOARD_PAGES_MAX':
			code = ErrorCode.ExceedBoardPageMax;
			break;
		default:
			code = ErrorCode.UnKnownError
	}
	if (response.code === 'RESPONSE_ERROR_CONFLICT') {
		code = ErrorCode.InvalidRequest;
	}
	return new ClientError(code, response);
}

/**
 * Created by colin on 2018/12/27
 */

const sizeType = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
const successStatus = [200, 201, 202, 408];
const UploadStatus = {
	PENDING: 1,
	INPROGRESS: 2,
	UPLOADED: 3,
	ERROR: 4,
	CANCELED: 5
}

interface UploadOption {
	maxSize?: string;
}

function getAvailableName(file,existFiels:object[], count=1):string {
	let fileName = file.name;
	let suffix = '';
	let name;
	let temp = fileName.split('.');
	if(temp.length > 1){
		suffix = temp.pop();
		name = temp.join('.');
		fileName = `${fileName}_${count}.${suffix}`
	}else{
		name = fileName;
		fileName = `${fileName}_${count}`
	}

	let sameFile = existFiels.find((existfile:any)=>{return fileName === existfile.name});
	if(sameFile){
			return getAvailableName(file,existFiels,count+1)
	}
	return fileName;
}
interface AvailableFile {
	availabName:string,
	file:object,
	hasMySameFile:boolean,
	createdTime?:number
}
export class UploadController {


	private static isSizeGreaterThan(size: number, maxSize: string) {
		const matchs = maxSize.match(/(bytes|kB|MB|GB|TB|PB)/)
		let type, typeIndex;
		if (matchs.length) {
			type = matchs[0];
			typeIndex = sizeType.indexOf(type);
			const e = Math.floor(Math.log(size) / Math.log(1024));
			if (e > typeIndex) {
				return true;
			} else if (e === typeIndex) {
				const selfSize = (size / Math.pow(1024, e));
				if (selfSize > parseFloat(maxSize.replace(type, ''))) {
					return true;
				} else {
					return false;
				}
			}
			return false;
		} else {
			return false;
		}
	}

	private static readablizeBytes(bytes: number) {
		const e = Math.floor(Math.log(bytes) / Math.log(1024));
		if(e <= 2) {
			return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + sizeType[e];
		}else{
			return (bytes / (1024 * 1024)).toFixed(2) + ' ' + sizeType[2]
		}
	}
	static uploaderFactory(id: string, url: string, file: any, notifyUpdate: Function, opts?: UploadOption) {
		let xhr: XMLHttpRequest;
		const currentStatus = UploadStatus.PENDING
		if (!opts) {
			opts = {};
		}
		let headers: any = {};
		let cfg = config()
		// if (cfg.accessToken) {
		// 	headers.Authorization = 'Bearer ' + cfg.accessToken;
		// }
		/*if (cfg.sessionId) {
			headers['X-Session-Id'] = cfg.sessionId;
		}*/
        headers['Client-Version'] = MxISDK.getISDKConfig().clientVersion;
		let destroy: Function;
		let onProcess = (e: any) => {
			if (e.lengthComputable) {
				const percentage = Math.round((e.loaded * 100) / e.total);
				if (currentStatus !== UploadStatus.ERROR) {
					notifyUpdate({
						id: id,
						status: UploadStatus.INPROGRESS,
						file: file,
						percentage: percentage,
						uploadedSize: this.readablizeBytes(e.loaded),
						totalSize: this.readablizeBytes(e.total),
					});
				}
			}
		}
		let onLoad = () => {
			if (xhr.readyState >= 4) {
				let response
				if (xhr.responseText) {
					try {
						response = JSON.parse(xhr.responseText);
					} catch (e) {
					}
				}

				if (successStatus.indexOf(xhr.status) > -1) {
					notifyUpdate({
						id: id,
						status: UploadStatus.UPLOADED,
						file: file,
						percentage: 100,
						response: response
					});
				} else {
					let error;
					if (response) {
						error = transformServerUploadError(response);
					} else {
						error = new ClientError(ErrorCode.UnKnownError, xhr)
					}
					notifyUpdate({
						id: id,
						status: UploadStatus.ERROR,
						error: error,
						file: file
					});
				}
			}
			destroy()
		}
		//todo: sometimes server will return timeout and the file will upload success  after a while
		let onError = () => {
			notifyUpdate({
				id: id,
				status: UploadStatus.ERROR,
				file: file
			})
			destroy()
		}
		let onAbort = () => {
			notifyUpdate({
				id: id,
				status: UploadStatus.CANCELED,
				file: file
			})
			destroy()
		}
		destroy = () => {
			if (xhr) {
				xhr.upload.removeEventListener('progress', onProcess);
				xhr.removeEventListener('load', onLoad);
				xhr.removeEventListener('error', onError);
				xhr.removeEventListener('abort', onAbort);
				xhr = null;
			}
			onProcess = null;
			onError = null;
			onAbort = null;
			onLoad = null;
			notifyUpdate = null;
		}
		const isSizeGreaterThan = this.isSizeGreaterThan
		return {
			send() {
				xhr = new XMLHttpRequest();

				if (opts.maxSize) {
					if (isSizeGreaterThan(file.size, opts.maxSize)) {
						notifyUpdate({
							id: id,
							status: UploadStatus.ERROR,
							file: file,
							error: new ClientError(ErrorCode.ExceedFileMaxSize)
						})
					}
				}
				xhr.open('POST', url, true);
				Object.keys(headers).forEach((key) => {
					xhr.setRequestHeader(key, headers[key]);
				});
				xhr.upload.addEventListener('progress', onProcess, false);
				xhr.addEventListener('load', onLoad, false);
				xhr.addEventListener('error', onError, false);
				xhr.addEventListener('abort', onAbort, false);
				xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				xhr.setRequestHeader('content-type', file.type);
				//var fileData = new FormData();
				//fileData.append('file', this.file);
				xhr.send(file);
			},
			cancel() {
				xhr && xhr.abort();
			}
		};
	}
	static getBoardFoldersFiles(boardId:string,folderSpath?:string):Promise<any>{
		const fileController = FileController.getInstance(boardId);
		return fileController.getBoardFoldersFiles(folderSpath)
	}
	static transformServerUploadError (response: any) {
		return transformServerUploadError(response)
	}
	static createComment(boardId: string, comment: string) {
		let binderInstance = MxISDK.getCurrentUser().getInstantBoard(boardId)
		return binderInstance.createComment({
			text: comment,
			client_uuid: FunctionUtil.uuid()
		})
	}
}
export {
	UploadStatus
}
